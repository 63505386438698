/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, isValidElement, useMemo, useRef } from 'react';
// import {useHistory } from 'react-router-dom'; 
import { Tooltip, Label, FormGroup, Progress, TabContent, TabPane, Modal, Button, Input, Collapse, Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Table, Row, CardFooter, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, InputGroup, InputGroupAddon, UncontrolledTooltip, UncontrolledCollapse as UncontrolledCollapseRS } from "reactstrap";
import reactCSS from 'reactcss';
import classnames from "classnames";
import Card_Layout from '../Card_Layout';
import { positions, useAlert } from 'react-alert'
import Generic_Dropdown from '../Generic_Dropdown/';
import CompositeDropdown from '../CompositeDropdown/';
import { Typeahead } from 'react-bootstrap-typeahead';
// import Select from 'react-select'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { COLORS } from '../../utils/color';
import sort_by from '../../utils/sort_by';
// import schedular_properties_json from '../../utils/schedular_properties_json.js';
import filter_operators_json from '../../utils/filter_operators_json.js';
import AFW_Structure from '../AFW_Structure/';
import camJsonData from '../../models/core_jsons/cam_structure.json';
import Multiselect from 'multiselect-react-dropdown';
import sort_by_text from '../../utils/sort_by_text.js'
import timeSince from '../../utils/time_utils.js'
import './modal_css.css';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import DragDropComponent from '../DragDropCompoment';
import Switch from "react-switch";
import repopulate_panel from '../../utils/panel_repopulation';
import Multiselect1 from '../Multiselect';
// import ProgressBar from 'react-bootstrap/ProgressBar';
// import 'bootstrap/dist/css/bootstrap.min.css';
import custom_sort from '../../utils/custom_sort';
import desc_custom_sort from '../../utils/desc_custom_sort';
import { IModalBody, IModalFooter, IModalHeader } from '../IModal';
import INavBar, { UncontrolledINavbar } from '../INavBar';
import multiFieldSort from '../../utils/multiFieldSort';
import generic_sort from '../../utils/generic_sort';
import repopulate_panel_pop_up from '../../utils/panel_pop_up_repopulation';
import { ITD, ITH, ITable, ITableBody, ITableHeader, ITableRow } from '../ITable';
import { Checkbox, Tooltip as AntdTooltip, Radio, Dropdown as AntDropdown, Steps, DatePicker } from 'antd';
import { ICollpsable, UncontrolledCollapse } from '../ICollpsable';
import { SidebarLayout } from '../ISideBar';
import { send_analytics } from '../../utils/send_analytics';
import hasNoPrefixPairs from '../../utils/hasNoPrefixPairs';
import { generate_2d_snapshot_of_composite, generate_2d_snapshot_of_layer, generate_3d_snapshot_of_composite, generate_3d_snapshot_of_layer, updateWallHatchPatterns } from '../../utils/hatching';
import ISearch from '../ISearch';
import build_path from '../../utils/build_path';
import formatDateTime from '../../utils/format_time';
import get_image_url_from_FS from '../../utils/get_image_url_from_FS.js';
import group_by_json from '../../utils/group_by_json.js';
import {useTranslation} from 'react-i18next'
import UploadImage from '../UploadImage/index.js';
import general_fetch from '../../utils/fetch';
import debug_log from '../../utils/debug_log.js';
import Create_card from '../Create_card/index.js';
import { Share_invite_modal, Upload3dModelModal } from '../CommonModals/index.js';
import Upload3dModel from '../Upload3dModel/index.js';
import upload_asset from '../../utils/upload_3d_model.js';
import { decimate_model, postUploadImageExtractionfn } from '../../utils/process3dModel.js';
import TriggerTooltip from '../TriggerTooltip/index.js';
import sleep from '../../utils/sleep';
import useStateCallback from '../../utils/useStateCallback.js';
import ImageCarousel from '../ImageCarousel/index.js';
// import debug_log from '../../utils/debug_log.js';
import IImg from '../IImg/index.js';
import { render } from 'react-dom';
import Icons from '../Icons/index.js';
import { dateSort } from '../../utils/sort.js';
import { handleError, logToSentry } from '../../utils/errors.js';
import dayjs from 'dayjs';
import {ViewSelector} from '../Create_card/index.js';

const custom_field_ids_cutlist = ['custom_field1', 'custom_field2', 'custom_field3'];
const custom_cutlist_variables = ['core_material_sku_name', 'core_material_model_no', 'internal_finish_sku_name', 'internal_finish_model_no', 'external_finish_sku_name', 'external_finish_model_no'];

const styles_modals= {
	input_styles:{
		padding: 0,
		// height: '28px',
		fontSize: '12px',
		textAlign: 'left',
		borderRadius:'0',
		width:'100%'
	},

	input_styles_2:{
		padding: '0px 8px',
		// height: '28px',
		fontSize: '12px',
		textAlign: 'left',
		borderRadius:'0',
		width:'100%',
		height:'24px'
	},

	input_styles_3:{
		padding: '0px 8px',
		// height: '28px',
		fontSize: '12px',
		textAlign: 'left',
		borderRadius:'0',
		width:'100%'
	},

	input_styles_none:{
		padding: 0,
		// height: '24px',
		fontSize: '12px',
		textAlign: 'left',
		borderRadius:'0',
		width:'100%',
		borderRadius:'2px',
		border:'none',
		boxShadow:'none',
		// height:'auto'
		// fontSize:'14px'
	},

	input_styles_none_2:{
		padding: '0px 8px',
		// height: '24px',
		fontSize: '12px',
		textAlign: 'left',
		borderRadius:'0',
		width:'100%',
		borderRadius:'2px',
		border:'none',
		boxShadow:'none',
		// height:'auto'
		// fontSize:'14px'
	}

}

const dummy_tick = (t) => (new Promise((res,rej) => {
    var timer = setTimeout(() => {
        res();
        clearTimeout(timer);
    },t)
})); 

const _deref = (a) => (JSON.parse(JSON.stringify(a)));


const styles={
	td_style:{
		width:"100%",
		minHeight:'50px',
		heigth:'auto',
		overflowWrap: 'anywhere',
		display:'flex',
		alignItems:'center',
		padding:"0px 5px"
	},
	th_style:{
		width:"100%",
		padding:"0px 5px"		
	}
}

const Automation_History = ({  modal_type, handle_close,  modal_json, set_page_loader, handle_ui_response, update_view }) => {
	const [tabs , set_tabs] = useState(1);
	const [ data, set_data ] = useState({});

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};


	useEffect(() => {
		if(modal_type == "automation_history" && modal_json){
			set_data(JSON.parse(JSON.stringify(modal_json)));
		}
	},[modal_type, modal_json])

	const readable = (str) => {
		return str.split('_').map(o => (o.length?o[0].toUpperCase()+o.substr(1):o)).join(" ")
	}

	const download_csv = (key) => {
		var headers = [Object.keys(data[key][0]).map(o => readable(o))];
		var rows = headers.concat(data[key].map(o => Object.keys(o).map(p => ("\"" + o[p] + "\"") )));
		
		var csvContent = "data:text/csv;charset=utf-8,";
		rows.map(o => {
			let row = o.join(",");
			csvContent += row + "\r\n";
		});

		var encodedUri = encodeURI(csvContent);
		var link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", key+".csv");
		document.body.appendChild(link); // Required for FF
		link.click(); // This will download the data file named "my_data.csv".
		document.body.removeChild(link); // Required for FF
	}

	return(
		<Modal className="modal-dialog-centered" style={{ maxWidth: '90vw' }} isOpen={modal_type == "automation_history"}>
			<IModalHeader toggle={handle_close}>Automation History</IModalHeader>
			<IModalBody style={{ height: '600px' }}>
				<UncontrolledINavbar tabs={Object.keys(data).map((o) => (readable(o)))} set_active_tab={set_tabs}></UncontrolledINavbar>
				<TabContent activeTab={"tabs" + tabs} style={{ height: 'calc( 100% - 80px )' }}>
					{
						Object.keys(data).map((o, idx) => (
							<TabPane tabId={"tabs" + idx} style={{ height: '100%', overflow: 'auto' }}>
								{data[o].length ? (
									<React.Fragment>
										<div className='justify-content-end mb-3'>
											<Button onClick={() => download_csv(o)} className='secondary_button_default' type="button">Download CSV</Button>
										</div>
										<ITable rowSeparated={true} style_container={{ border: '1px solid var(--light-border-color)', maxHeight: 'calc( 100% - 60px )', overflow: 'auto' }}>
											<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px' }}>
													<ITH>S.No</ITH>
													{Object.keys(data[o][0]).map(p => (
														<ITH><span>{readable(p)}</span></ITH>
													))}
											</ITableHeader>
											<ITableBody >
												{data[o].map((unit, idx) => (
													<ITableRow key={idx}>
														<ITD><span>{idx + 1}</span></ITD>
														{Object.keys(unit).map(p => (
															<ITD><span>{readable(unit[p])}</span></ITD>
														))}
													</ITableRow>
												))}
											</ITableBody>
										</ITable>
									</React.Fragment>
								) : 'NA'}
							</TabPane>
						))
					}
				</TabContent>
			</IModalBody>
		</Modal>
	)
}

const Copy_Paste_Modal = ({  modal_type, handle_close,  modal_json, set_page_loader, handle_ui_response, update_view }) => {
	const [ buildings, set_buildings ] = useState([]);
	const [ active_building_id, set_active_building_id ] = useState("");
	const [ active_floors, set_active_floors ] = useState([]);
	const [ floors, set_floors ] = useState([]);

	const alert = useAlert();

	const paste_elements = async () => {
		if(active_floors.length){
			var resp = await window.Promisify(window.Module.paste_clipboard_objects_to_floor_onclick(JSON.stringify(active_floors.map(o => o.id))))
			handle_ui_response(resp);
			handle_close();
		}else{
			alert.error("Please select a floor");
		}
	}

	useEffect(() => {
		if(modal_type == "copy_paste_different_floor_modal" && modal_json && modal_json.buildings && modal_json.buildings.length && modal_json.buildings[0].floors){
			set_buildings(JSON.parse(JSON.stringify(modal_json.buildings)));
		}
	},[modal_type, modal_json]);

	useEffect(() => {
		reset_default_selections();
	},[buildings])

	const reset_default_selections = (building_id) => {
		var selected_building
		if(building_id){
			selected_building = buildings.find(o => o.id == building_id)
		}else{
			selected_building = buildings[0]
		}

		if(selected_building && selected_building.floors.length){
			set_active_building_id(selected_building.id);
			set_active_floors([selected_building.floors[0]]);
			set_floors(JSON.parse(JSON.stringify(selected_building.floors)));
		}
	}

	const onselect_floor_ids = (selectedList) => {
		console.log(selectedList);
		set_active_floors(selectedList)
	}


	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="copy_paste_different_floor_modal"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Copy paste to a different floor</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody id="copy_paste_main_body" style = {{backgroundColor: COLORS.white, overflowY: "scroll" , height:300}} className="px-lg-5 py-lg-5">
						<Row>
							<Col xs={5}>
								<Input style={{width: 200}} value={active_building_id} onChange={(e) => {set_active_building_id(e.target.value);reset_default_selections(e.target.value)}}  type="select">
									{buildings.map((o, idx) => (
										<option key={idx} value={o.id}>{o.name}</option>
									))}
								</Input>
							</Col>
							<Col xs={5}>
								<Multiselect
									options={floors} 
									selectedValues={active_floors} 
									onSelect={onselect_floor_ids} 
									onRemove={onselect_floor_ids} 
									displayValue="name" // Property name 
									showCheckbox
									closeOnSelect={false}
									showArrow={true}
								/>
								{/* <Input style={{width: 200}} value={active_floors} onChange={(e) => {set_active_floors(e.target.value);}}  type="select">
									{floors.map((o, idx) => (
										<option key={idx} value={o.id}>{o.name}</option>
									))}
								</Input> */}
							</Col>
							<Col xs={2}>
								<Button onClick={paste_elements} color={COLORS.mainpage_locale} type="button">Paste</Button>&nbsp;
							</Col>
						</Row>
				</CardBody>
			</Card>
		</Modal>
	)  
}

const Comments_Modal = ({  modal_type, handle_close,  modal_json, set_page_loader, handle_ui_response, update_view }) => {
	const create_comment = (thread_id) => {
		var element = document.getElementById(thread_id);

		if(element){
			window.Module.create_comment(element.value, thread_id)
		}
	}

	useEffect(() => {
		if(modal_type == "comments_modal" && modal_json){
			var scrollDiv = document.getElementById("comments_main_body");
			if(scrollDiv){
				scrollDiv.scrollTop = scrollDiv.scrollHeight;
			}
		}
	},[modal_type]);

	useEffect(() => {
		if(modal_type == "comments_modal" && modal_json){
			for(var i=0;i<modal_json.length;i++){
				var element = document.getElementById(modal_json[i].id);
				if(element){
					element.value = "";
				}
			}

			var element = document.getElementById("new_thread");
			if(element){
				element.value = "";
			}
		}
	},[modal_json, modal_type]);

	const keydown_handle_enter = (e) => {
		var key = e.key;
		var shiftKey = e.shiftKey;
		var id = e.target.id;

		if(key == "Enter" && !shiftKey){
			e.preventDefault();
			create_comment(id);
			return false;
		}
	}

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="comments_modal"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Comments</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody id="comments_main_body" style = {{backgroundColor: COLORS.white, overflowY: "scroll" }} className="px-lg-5 py-lg-5">
						{modal_type=="comments_modal" && modal_json && modal_json.length ? (modal_json.map((thread,thread_idx) => 
							<Card style={{marginTop:"20px"}}>
								<CardBody style={{paddingBottom:10,boxShadow:"0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%)",borderRadius:10}}>
									{thread.comments.map((comment,comment_idx) => 
										<Row>
											<Col xs={12} >
												<b>{(comment.user_display?comment.user_display:comment.user_id) + ": "}</b><div style={{whiteSpace: "pre-line",verticalAlign:"top",display:"inline-block"}}>{comment.comment_text}</div>
											</Col>
										</Row>
									)}
									<Row style={{marginTop:"10px"}}>
										<Col xs={11}>
											<Input onKeyDown={keydown_handle_enter} style={{borderRadius:20, height: 38}} onChange={(e) => {e.target.style.height = "1px";e.target.style.height = (2+e.target.scrollHeight)+"px";}} placeholder="Reply" id={thread.id} type='textarea' />
										</Col>
										<Col xs={1} style={{paddingLeft:0}}>
											<Button style={{paddingLeft:0}} onClick={() => {create_comment(thread.id)}} color={COLORS.button_primary} type="button">Send</Button>&nbsp;
										</Col>
									</Row>
								</CardBody>
							</Card>
						)):''}

						<Card style={{marginTop:"20px"}}>
							<CardBody style={{paddingBottom:10,boxShadow:"0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%)",borderRadius:10}}>
								<Row style={{marginTop:"10px"}}>
									<Col xs={11}>
										<Input onKeyDown={keydown_handle_enter} style={{borderRadius:20, height: 38}} onChange={(e) => {e.target.style.height = "1px";e.target.style.height = (2+e.target.scrollHeight)+"px";}} placeholder="New Thread" id={"new_thread"} type='textarea' />
									</Col>
									<Col xs={1} style={{paddingLeft:0}}>
										<Button style={{paddingLeft:0}} onClick={() => {create_comment("new_thread")}} color={COLORS.button_primary} type="button">Send</Button>&nbsp;
									</Col>
								</Row>
							</CardBody>
						</Card>
				</CardBody>
			</Card>
		</Modal>
	)  
}


export const Upload3dModelToDesign = ({ modal_type, modal_json, handle_close, set_page_loader, handle_ui_response, update_view}) => {

	const alert = useAlert();

	const [sku_name, set_sku_name] = useState("");
	const [sku_placement, set_sku_placement] = useState("floor_base");

	const [step, set_step] = useState(1);
	const [publish_first, set_publish_first] = useState(false);

	const [primary_file, set_primary_file] = useState('');
	const [mtl_file, set_mtl_file] = useState("");
	const [additional_files, set_additional_files] = useState([]);
	const [primary_file_type, set_primary_file_type] = useState('');
	const [mtl_text, set_mtl_text] = useState(null)
	const [expected_texture_files, set_expected_texture_files] = useState(null)
	const [model_details, set_model_details] = useState({glb_src: "", id: ""});

	const [group_id, set_group_id] = useState("");
	const [catalogue_mode, set_catalogue_mode] = useState('user');
	const [ meta_data, set_meta_data ] = useState({});

	const [modal_json_copy, set_modal_json_copy] = useState();

	const set_model_src_after_upload = (resp) => {
		for(let i in resp){
			model_details[i] = resp[i];
		}
	}

	const upload_3d_modal_to_design = async() => {
		try{
			let analytics_data = {}
			analytics_data.category = "Import 3D Model Import Button Clicked"
			analytics_data.sku = sku_name
			analytics_data.placement_type = sku_placement
			analytics_data.file_size_mb = (primary_file && primary_file.size)/(1024 * 1024)
			analytics_data["3D_file_format"] = primary_file_type;
			send_analytics(analytics_data)
			await upload_asset({alert, set_page_loader, primary_file, primary_file_type, mtl_file, additional_files, expected_texture_files, callbackFnOn3DModelUpload:set_model_src_after_upload}); 
			
			await sleep(10)
			set_page_loader({text: "Processing your 3D Model", show: true})

			let { top_colour_image, top_grayscale_image, ...dim } = await postUploadImageExtractionfn({glb_src: model_details.glb_src ? window.Module.API.server_path + '/' + model_details.glb_src : null, server_path: window.Module.API.server_path});

			let glb = await decimate_model(model_details.glb_src ? window.Module.API.server_path + '/' + model_details.glb_src : null, 500000, set_page_loader)

			await sleep(10)
			set_page_loader({text: "Importing your 3D Model to design", show: true})

			if(glb !== -1){
				const gltfBlob = new Blob([glb], { type: 'application/octet-stream' });

				// // Create a download link
				// const downloadLink = document.createElement('a');
				// downloadLink.href = URL.createObjectURL(gltfBlob);
				let rand = Math.floor(Math.random()*1000000)
				// downloadLink.download = `exported_model${rand}.glb`;

				// // Trigger the download
				// downloadLink.click();

				// Clean up
				// URL.revokeObjectURL(downloadLink.href);
				let data = new FormData()
				data.append('file', gltfBlob, `decimate_model${rand}.glb`)
				data.append('high', false);
				data.append('format', 'glb')
				
				try{
					var resp = await general_fetch({ url: 'model/upload_asset', body: data, is_form_data: true})
					debug_log(` resp from asset upload decimated --> ${resp.data}`);
					
					set_model_src_after_upload(resp && resp.data)  
				}catch(err){
					console.error("Error while uploading 3d model - ", err)
				}
			}

			let top_view_color_id,top_view_gray_scale_id, top_view_color_image,top_view_gray_scale_image
			// var top_view_color_file = new File(window.dataURItoBlob(top_colour_image), "top_view_color")
			// var top_view_greyscale_file = new File(window.dataURItoBlob(top_grayscale_image), "top_view_grayscale")

			var top_view_color_file = window.dataURItoBlob(top_colour_image)
			var top_view_greyscale_file = window.dataURItoBlob(top_grayscale_image)


			try{
				let data = new FormData();
				data.append('upl', top_view_color_file)
				// set_page_loader({
				// 	show: true,
				// 	text: 'Please wait.. Uploading Display Image'
				// })
				let response = await general_fetch({url: '/image/add', body:data, is_form_data:true})
				top_view_color_id = response.id
				top_view_color_image = response.image

			}catch(err){
				console.error("Error while uploading top view color image - ", err)
			}

			try{
				let data = new FormData();
				data.append('upl', top_view_greyscale_file)
				// set_page_loader({
				// 	show: true,
				// 	text: 'Please wait.. Uploading Display Image'
				// })
				let response = await general_fetch({url: '/image/add', body:data, is_form_data:true})
				top_view_gray_scale_id = response.id
				top_view_gray_scale_image = response.image
			}catch(err){
				console.error("Error while uploading top view grayscale image - ", err)
			}

			let body = {
				data: {...model_details},
				placement_id: sku_placement,
				top_view_colour_id: top_view_color_id,
				top_view_grayscale_id: top_view_gray_scale_id,
				top_view_colour_image: top_view_color_image,
				top_view_grayscale_image: top_view_gray_scale_image
			}

			window.Module[modal_json.route_save_template](JSON.stringify(body));
			
			onclick_close_upload_3d_modal()
			set_page_loader({text: "Please Wait...", show: false})
		}catch(err){
			if(typeof(err) === "string"){
				if(err){
					alert.error(err)
				}
			}else{
				alert.error("Encountered an error while trying to process your 3d model.")
			}
			set_page_loader({text: "Please Wait...", show: false})
		}
	}

	const upload_3d_modal_to_catalogue = async() => {
		let analytics_data = {}
		analytics_data.category = "Import 3D Model Publish Button Clicked"
		analytics_data.division_name = meta_data.division
		analytics_data.category_name = meta_data.category
		analytics_data.subcategory_name = meta_data.subcategory
		analytics_data.group_name = meta_data.group
		analytics_data.sku_name = sku_name
		analytics_data["3D_file_format"] = primary_file_type;
		// To add meta data
		send_analytics(analytics_data)
		if(!group_id){
			alert.error("No Group is selected")
			return
		}

		try{
			await upload_asset({alert, set_page_loader, primary_file, primary_file_type, mtl_file, additional_files, expected_texture_files, callbackFnOn3DModelUpload:set_model_src_after_upload}); 
			await sleep(10)
			set_page_loader({text: "Adding 3D Model to Catalog", show: true})
			let add_sku_resp = {
				add_sku: {
					name: sku_name,
					placement_id: sku_placement,
					sku_group_id: group_id,
					business_unit_id: window.Module.get_active_user().business_unit_id,
					model_id: model_details.id,
					glb_src: model_details.glb_src,
					call_core_function: modal_json.route_update_template,
					owned_by_designer: catalogue_mode === 'org' ? false : true,
					no_toast : true
				}
			}

			await handle_ui_response(JSON.stringify(add_sku_resp));

			// window.Module[modal_json.route_update_template](JSON.stringify(body));
			set_page_loader({text: "Please Wait...", show: false})
			
			onclick_close_upload_3d_modal()
		}catch(err){
			if(err && typeof(err) === "string"){
				alert.error(err)
			}else{
				alert.error("Encountered an error while trying to process your 3d model.")
			}
		}
	}

	const onclick_close_upload_3d_modal = () => {
		let analytics_data = {}
		analytics_data.category = "Import 3D Model Modal Closed"
		send_analytics(analytics_data)
		
		handle_close()
		set_primary_file('')
		set_mtl_file('')
		set_mtl_text(null)
		set_additional_files([])
		set_primary_file_type('')
		set_sku_name("")
		set_sku_placement("floor_base")

		set_group_id("")
		set_catalogue_mode("user")
		set_step(1)
	}

	useEffect(() => {
		if(modal_json && modal_type === "upload_3d_model"){
			set_modal_json_copy(modal_json)
		}
	}, [modal_json, modal_type]);

	// useEffect(() => {
	// 	if(modal_json_copy){
	// 		modal_json_copy.default_sku_name = sku_name
	// 	}
	// }, [sku_name]);
	

	return (
		<Modal centered size="md" isOpen={modal_type === "upload_3d_model"} toggle={onclick_close_upload_3d_modal}>
			<IModalHeader toggle={onclick_close_upload_3d_modal}>Import 3D Model</IModalHeader>
			<IModalBody style={{ minHeight: '400px', maxHeight: '600px', overflow: 'auto', padding: '24px' }}>
				{
					step === 1 ?
					<React.Fragment>
					<div className='flex_property mb-3'>
						<div style={{flex: 1}}>SKU Name</div>
						<Input style={{flex: 3, ...styles_modals.input_styles_3}} value={sku_name} onChange={(e) => {set_sku_name(e.target.value)}} type='text' placeholder='SKU Name'/>
					</div>

					<div className='flex_property mb-3'>
						<div style={{flex: 1}}>Placement</div>
						<Input style={{flex: 3, ...styles_modals.input_styles_3}} value={sku_placement} onChange={(e) => {set_sku_placement(e.target.value)}} type='select'>
							<option value={"floor_base"}>Floor Base</option>
							<option value={"ceiling"}>Ceiling</option>
							<option value={"wall"}>Wall</option>
						</Input>
					</div>

					<div>
						<Upload3dModel primary_file={primary_file} set_primary_file={set_primary_file} mtl_file={mtl_file} set_mtl_file={set_mtl_file} additional_files={additional_files} set_additional_files={set_additional_files} primary_file_type={primary_file_type} set_primary_file_type={set_primary_file_type} mtl_text={mtl_text} set_mtl_text={set_mtl_text} expected_texture_files={expected_texture_files} set_expected_texture_files={set_expected_texture_files} callbackOnClickUpload3DModel={() => {let analytics_data = {}; analytics_data.category = "Import 3D Model File Picker Opened"; send_analytics(analytics_data)}}/>
					</div>
					</React.Fragment>
					: ''
				}
				<div style={{display: step == 2 ? 'block' : 'none'}}>
					<PublishComponent modal_json={modal_json_copy} modal_type={"user_sku_creation"} set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} update_view={update_view} showSubmit={false} callbackForGroupId={set_group_id} callbackForCatalogMode={set_catalogue_mode} callbackForSkuName={set_sku_name} callbackForMetaData={set_meta_data}/>
				</div>

			</IModalBody>
			<IModalFooter>
				{
					step === 1 ?
						<React.Fragment>
							<Checkbox className='mr-auto' checked={publish_first} onChange={(e) => set_publish_first(e.target.checked)}>Publish to catalogue first</Checkbox>
							{
								publish_first ?
									<Button 
										className='primary_button_default' 
										disabled={((primary_file && primary_file_type != 'obj') || (primary_file && mtl_file && primary_file_type === 'obj')) && sku_name && sku_placement ? false : true}
										onClick={() => {
											let analytics_data = {}
											analytics_data.category = "Import 3D Model Next Button Clicked"
											analytics_data.sku = sku_name
											analytics_data.placement_type = sku_placement
											analytics_data.file_size_mb = (primary_file && primary_file.size)/(1024 * 1024)
											send_analytics(analytics_data)
											set_step(2);
											if(modal_json_copy){
												modal_json_copy.default_sku_name = sku_name
												set_modal_json_copy(JSON.parse(JSON.stringify(modal_json_copy)))
											}}}>
										Next
									</Button>
									:
									<Button
										disabled={((primary_file && primary_file_type != 'obj') || (primary_file && mtl_file && primary_file_type === 'obj')) && sku_name && sku_placement ? false : true}
										onClick={((primary_file && primary_file_type != 'obj') || (primary_file && mtl_file && primary_file_type === 'obj')) && sku_name && sku_placement ? upload_3d_modal_to_design : null}
										className='primary_button_default' type="button" >
										Import
									</Button>
							}
						</React.Fragment>
						:
						<React.Fragment>
							<Button className='secondary_button_default mr-auto' onClick={() => set_step(1)}>Back</Button>
							<Button className='primary_button_default' disabled={!group_id} onClick={upload_3d_modal_to_catalogue}>Publish and Import</Button>
						</React.Fragment>
						
					// publish_first ?
					// 	step === 1 ?
					// 		<Button className='primary_button_default' onClick={() => set_step(2)}>Next</Button>
					// 		:
					// 		<Button className='primary_button_default'>Publish and Import</Button>
					// 	:
					// 	<React.Fragment>
					// 		<Button
					// 			disabled={((primary_file && primary_file_type != 'obj') || (primary_file && mtl_file && primary_file_type === 'obj')) && sku_name && sku_placement ? false : true}
					// 			onClick={((primary_file && primary_file_type != 'obj') || (primary_file && mtl_file && primary_file_type === 'obj')) && sku_name && sku_placement ? upload_3d_modal_to_design : null}
					// 			className='primary_button_default' type="button" >
					// 			Import
					// 		</Button>
					// 	</React.Fragment>
				}
			</IModalFooter>
		</Modal>
	);
}

const FillerEmptyState = ({ message }) => {
    return (
        <div className='filler_empty_state'>
            {message}
        </div>
    )
}

const SheetCard = ({ sheet, selected_sheets, set_selected_sheets }) => {
	const [img_src, set_img_src] = useState('');
	const [prev_img_src, set_prev_img_src] = useState('');

	useEffect(() => {
		let url = (get_image_url_from_FS(sheet.image));
		set_prev_img_src(img_src);
		set_img_src(url)
	}, [sheet]);

	useEffect(() => {
		if(prev_img_src){
			URL.revokeObjectURL(prev_img_src)
		}
	}, [prev_img_src]);

	useEffect(() => {
		return () => {
			if(img_src){
				URL.revokeObjectURL(img_src)
			}
		}
	}, []);

	const handleCheckboxChange = () => {
		let current_selected_sheets = JSON.parse(JSON.stringify(selected_sheets))
		if(current_selected_sheets.includes(sheet.id))
			current_selected_sheets = current_selected_sheets.filter(item => item != sheet.id)
		else
			current_selected_sheets.push(sheet.id)
		set_selected_sheets(current_selected_sheets);
	}

	const handleSheetClick = (e) => {
		let sheet_checkbox = document.getElementById('sheet_card_item ' + sheet.id);
		if(sheet_checkbox){
			sheet_checkbox.click();
		}
	}

	return (
		<div onClick={handleSheetClick} className={`sheet_card_item ${selected_sheets.includes(sheet.id) ? 'selected' : ''}`} key={"key_" + sheet.id} id={sheet.id + "_button"} style={{ cursor: 'pointer', height: '100px', display: 'flex', gap: '8px', padding: '8px', backgroundColor: 'white', outlineOffset: '-1.6px' }}>
			<input 
				id = {'sheet_card_item ' + sheet.id}
				type='checkbox'
				style={{position: 'absolute', top: '10px', left: '10px', opacity: 0, cursor: 'pointer'}}
				checked={selected_sheets && selected_sheets.length && selected_sheets.includes(sheet.id)} 
				onChange={handleCheckboxChange} 
				onClick={e => e.stopPropagation()}
			/>
			<div style={{ paddingRight: '2px' }}>
				<img height='84px' width='120px' style={{ border: '1px solid ' + COLORS.gray9 }} title={sheet.name} src={img_src} />
				{/* <div style={{height: '84px', width: '120px', background: '#D9D9D9', border: '1px solid ' + COLORS.gray9}} /> */}
			</div>
			<div title={sheet.name} className={'lines1_elipsis'} id={sheet.id} style={{display: 'flex', alignSelf: 'center', justifyContent: 'space-between', fontSize: '12px', textAlign: 'left'}} >
					{sheet.name}
			</div>
		</div>
	)
}



const DownloadSheets = ({ modal_type, modal_json, handle_close, update_view, handle_ui_response, set_page_loader }) => {
	
	const [selected_sheets, set_selected_sheets ] = useState([]);
	const [all_sheets, set_all_sheets] = useState([]);

	const alert = useAlert();

	useEffect(() => {
		if(modal_type === 'download_sheets' && modal_json){ 
			set_all_sheets(modal_json.sheets && modal_json.sheets.length ? modal_json.sheets : [])
		}
	}, [modal_type, modal_json]);
	
	const onclick_handle_close = () => {
		set_selected_sheets([])
		set_all_sheets([])
		handle_close();
	}

	const selectAllSheets = () => {
		let all_sheets_id = all_sheets && all_sheets.length ? JSON.parse(JSON.stringify(all_sheets.map(item => item.id))) : []
		set_selected_sheets(all_sheets_id);
	}

	const clearSelection = () => {
		set_selected_sheets([]);
	}

	const handleSubmit = () => {
		if(modal_json.route){
			set_page_loader({
				show: true,
				text: 'Downloading selected sheets'
			}, async() => {
				try{
					var ui_response = await window.Promisify(window.Module[modal_json.route](JSON.stringify(selected_sheets))); 
					handle_ui_response(ui_response);
					onclick_handle_close();
				} catch(err){
					console.error(err);
					window.sentry_capture_exception("", err)
					alert.error('Download failed. Please try again')
				}
				set_page_loader({
					show: false,
					text: 'Downloading selected sheets'
				});	
			})
		}
	}

	return(
		<Modal centered size={'lg'} isOpen={modal_type === 'download_sheets'}>
			<IModalHeader toggle={onclick_handle_close}>
				{modal_json && modal_json.heading ? modal_json.heading : 'Download PDF of selected sheets'}
			</IModalHeader>
			<IModalBody>
				<div className='inf-p-2'>
					<div className='inf-p-2' style={{display: 'flex', justifyContent: 'space-between', marginBottom: '16px'}}>
						<div className='inf-flex inf-items-center'>
							<div style={{paddingRight: '8px'}}>{selected_sheets.length} Sheets Selected</div>
							<div style={{color: '#A7A8B2'}}>(click on sheets to select / deselect)</div>
						</div>

						<div className='inf-flex inf-items-center'>
							<div onClick={selectAllSheets} style={{paddingRight: '8px', color: '#0078FF', textDecoration: 'underline', textDecorationColor: '#0078FF', cursor: 'pointer'}}>Select all</div>
							<div onClick={clearSelection} style={{color: '#0078FF', textDecoration: 'underline', textDecorationColor: '#0078FF', cursor: 'pointer'}}>Clear selection</div>
						</div>
					</div>
					<div style={{height: '400px', overflow: 'auto'}}>
						{
								all_sheets && all_sheets.length ?
								<div className='inf-p-2' style={{ display: 'grid', gap: '16px', gridTemplateColumns: `repeat(auto-fill, minmax(200px, 1fr))` }}>
									{
										all_sheets.map((list_item, idx) => (
											<SheetCard key={idx} sheet={list_item} selected_sheets={selected_sheets} set_selected_sheets={set_selected_sheets} />
										))
									}
								</div>
								:
								<FillerEmptyState message={"No Sheets available to select"} />
						}
					</div>
					{/* </div> */}
				</div>
			</IModalBody>
			<IModalFooter>
				<Button onClick={selected_sheets.length?handleSubmit:null} style={{opacity: selected_sheets.length?1:0.5}} className='primary_button_default'>Download {selected_sheets.length?selected_sheets.length:''} selected sheets</Button>
			</IModalFooter>
		</Modal>
	)
}

const PublishComponent = ({modal_json, modal_type, set_page_loader, handle_ui_response, update_view, handle_close, showSubmit = true, callbackForGroupId, callbackForSkuName, callbackForCatalogMode, callbackForMetaData}) => {
	const alert = useAlert();
	const {t} = useTranslation()
	const [ divisions, set_divisions ] = useState([]);
	const [ categories, set_categories ] = useState([]);
	const [ sub_categories, set_sub_categories ] = useState([]);
	const [groups, set_groups] = useState([]);
	const [skus, set_skus] = useState([]);
	const [ active_division_id, set_active_division_id ] = useState('');
	const [ active_category_id, set_active_category_id ] = useState("");
	const [ active_sub_category_id, set_active_sub_category_id ] = useState("");
	const [active_group_id, set_active_group_id] = useState("");
	const [active_sku_id, set_active_sku_id] = useState("");
	const [ sku_name, set_sku_name ] = useState("");
	const [modal_json_copy, set_modal_json_copy] = useState([]);
	const [is_create_open, set_is_create_open] = useState(false);
	const [mode, set_mode] = useState('category');
	const [sku_mode, set_sku_mode] = useState('new');
	const [catalogue_mode, set_catalogue_mode] = useState('user');
	const [ meta_data, set_meta_data ] = useState({division: '', category: '', subcategory: '', group: ''});

	// const [model_id, set_model_id] = useState('');
	// const [is_upload_open, set_is_upload_open] = useState(false);
	// const toggle_upload = () => set_is_upload_open(!is_upload_open)

	useEffect(() => {
		if((modal_type === "publish_template" || modal_type === "user_sku_creation") && modal_json){
			if(showSubmit && modal_type === "user_sku_creation"){
				let analytics_data = {}
				analytics_data.category = "Publish 3D Model Modal Opened"
				send_analytics(analytics_data)
			}
		// if((modal_type == "publish_sheet" || modal_type == "publish_schedule") && modal_json){
			update_from_modal_json(modal_json)
			// if(modal_json.categories && modal_json.categories.length>0){
			// 	update_sub_categories(modal_json.categories[0].id, modal_json.categories[0]);
			// }
			update_divisions()
			set_sku_name(modal_json.default_sku_name ? modal_json.default_sku_name : 'Template')
		}
	},[modal_json, modal_type]);

	const update_from_modal_json = async (modal_json) => {
		set_modal_json_copy(modal_json)
		// if(modal_json.categories && modal_json.categories.length>0){
		// 	set_categories(modal_json.categories);
		// }else{
		// 	set_categories([]);
		// 	set_sub_categories([]);
		// 	set_groups([])
		// 	set_active_category_id("");	
		// 	set_active_sub_category_id("")
		// 	set_active_group_id("")
		// }
	}

	const update_divisions = () => {
		if(modal_json.divisions && modal_json.divisions.length > 0){
			set_divisions(modal_json.divisions)
			update_categories(modal_json.divisions[0].id, modal_json.divisions[0])
		}else{
			set_divisions([])
			set_categories([]);
			set_sub_categories([]);
			set_groups([])
			set_active_division_id("")
			set_active_category_id("");	
			set_active_sub_category_id("")
			set_active_group_id("")
		}
	}
	
	const update_categories = async (division_id, division, mode) => {
		if(division){
			meta_data.division = division.name
		}
		set_active_division_id(division_id)
		if(division && division.categories){
			var cat_list = division.categories
			set_categories(cat_list);
			if(cat_list.length>0){
				update_sub_categories(cat_list[0].id, mode)
			}else{
				update_sub_categories("")
			}
		}else{
			set_categories([]);
			update_sub_categories("")
		}
	}

	const update_sub_categories = async (cat_id, category, mode) => {
		if(category){
			meta_data.category = category.name
		}
		set_active_category_id(cat_id);
		var sub_cats = JSON.parse(window.Module["get_subcategory_tree_filtered"](cat_id, (mode ? mode : catalogue_mode) === "user" && modal_type !== "publish_template" ? true : false));
		if(sub_cats){
			sub_cats = sub_cats.sub_categories;
		}
		if(sub_cats){
			set_sub_categories(sub_cats);
			if(sub_cats.length>0){
				update_groups(sub_cats[0].id, sub_cats[0], mode)
			}else{
				update_groups("",null,mode)
			}
		}else{
			set_sub_categories([]);
			update_groups("",null)
		}
	}

	const update_groups = async (sub_cat_id, sub_cat, mode) => {
		if(sub_cat){
			meta_data.subcategory = sub_cat.name
		}
		set_active_sub_category_id(sub_cat_id)
		let groups_list = JSON.parse(window.Module["get_groups_for_subcategory"](sub_cat_id, (mode ? mode : catalogue_mode) === "user" && modal_type !== "publish_template" ? true : false));
		if(groups_list){
			groups_list = groups_list.sku_group;
		}
		if(groups_list){
			// var groups_list = sub_cat.sku_group
			set_groups(groups_list);
			if(groups_list.length>0){
				// set_active_group_id(groups_list[0].id)
				update_skus(groups_list[0].id, groups_list[0])
			}else{
				// set_active_group_id("")
				update_skus("",null)
			}
		}else{
			set_groups([]);
			// set_active_group_id("")
			update_skus("", null)
		}
	}

	const update_skus = async (group_id, group) => {
		if(group){
			meta_data.group = group.name
		}
		set_active_group_id(group_id)
		if(group && group.sku){
			var skus_list = group.sku
			set_skus(skus_list);
			if(skus_list.length>0){
				set_active_sku_id(skus_list[0].id)
			}else{
				set_active_sku_id("")
			}
		}else{
			set_skus([]);
			set_active_sku_id("")
		}
	}

	const submit = async () => {
		if(active_group_id){
			if(sku_name){
				set_page_loader({text: modal_type == "user_sku_creation" ? "Adding 3D Model to Catalog" : "Publishing to Catalog", show: true})
				await sleep(50)
				let o;
				if(modal_type === "publish_template"){
					if(sku_mode == 'new'){
						o = await window.Promisify(window.Module[modal_json_copy.route_save_template](sku_name,active_group_id, modal_json_copy.model_id ? modal_json_copy.model_id : '', active_category_id, ''))
					}else{
						o = await window.Promisify(window.Module[modal_json_copy.route_update_template](active_sku_id, modal_json_copy.model_id ? modal_json_copy.model_id : '', ''))
					}
				}else if(modal_type === "user_sku_creation"){
					o = await window.Promisify(window.Module[modal_json_copy.route_save_template](sku_name, active_group_id, modal_json_copy.model_id ? modal_json_copy.model_id : '', catalogue_mode, active_category_id, ''))
				}
				set_page_loader({text: "Please Wait...", show: false})
				handle_close();	
				await handle_ui_response(o);
				await update_view();
				if(showSubmit && modal_type === "user_sku_creation"){
					let analytics_data = {}
					analytics_data.category = "Publish 3D Model Modal Submitted"
					analytics_data.division_name = meta_data.division
					analytics_data.category_name = meta_data.category
					analytics_data.subcategory_name = meta_data.subcategory
					analytics_data.group_name = meta_data.group
					analytics_data.sku_name = sku_name
					send_analytics(analytics_data)
				}
			}else{
				alert.error("Sku name cannot be empty");
			}
		}else{
			alert.error("A category, sub category, group needs to be selected");
		}
		
	}

	useEffect(() => {
		if(callbackForGroupId){
			callbackForGroupId(active_group_id)
		}
	}, [active_group_id]);

	useEffect(() => {
		if(callbackForCatalogMode){
			callbackForCatalogMode(catalogue_mode)
		}
	}, [catalogue_mode]);

	useEffect(() => {
		if (callbackForSkuName) {
			callbackForSkuName(sku_name)
		}
	}, [sku_name]);

	useEffect(() => {
		if(callbackForMetaData){
			callbackForMetaData(meta_data)
		}
		
	}, [active_division_id, active_category_id, active_sub_category_id, active_group_id]);

	const toggle = () => {set_is_create_open(!is_create_open)}
	
	const AddNewOption = () => {
		const [name, set_name] = useState('');
		const add_new_component = async () => {
			if(name != ''){
				try{
					let final_json = {}
					final_json.name = name;
					final_json.mode = mode;
					if(mode == 'category'){
						// Nothing
					}else if(mode == 'sub category'){
						final_json.category_id = active_category_id
					}else if(mode == 'group'){
						final_json.sub_category_id = active_sub_category_id
					}
					final_json.sku_division_id = active_division_id ? active_division_id : 'template'

					final_json.sku_category_type_id = modal_json_copy && modal_json_copy.category_type ? modal_json_copy.category_type : 'sheet_template'

					final_json.catalogue_mode = modal_type === "user_sku_creation" ? catalogue_mode : 'org'

					var o = await window.Promisify(window.Module.add_component_to_catalouge(JSON.stringify(final_json)));
					o = JSON.parse(o)
					// alert.success("New ",mode," Created");
					if(mode == 'category'){
						let copy_json = JSON.parse(JSON.stringify(modal_json_copy))
						if(!copy_json.categories){
							copy_json.categories =[]
						}
						let cat_copy = [];
						if(categories){
							cat_copy = JSON.parse(JSON.stringify(categories));
						}
						cat_copy.push(o)
						copy_json.categories.push(o)
						set_categories(cat_copy)
						update_from_modal_json(copy_json)
						update_sub_categories(o.id,o)
					}else if(mode == 'sub category'){
						// let copy_json = JSON.parse(JSON.stringify(modal_json_copy))
						// let category_index = copy_json.categories.findIndex(x=>x.id == active_category_id)
						// if(category_index != -1){
						// 	if(!copy_json.categories[category_index].sub_categories){
						// 		copy_json.categories[category_index].sub_categories = []
						// 	}
							// copy_json.categories[category_index].sub_categories.push(o)
							// update_from_modal_json(copy_json)
							let sub_cat_copy = [];
							if(sub_categories){
								sub_cat_copy = JSON.parse(JSON.stringify(sub_categories));
							}
							sub_cat_copy.push(o)
							set_sub_categories(sub_cat_copy)
							update_groups(o.id,o)
						// }
					}else if(mode == 'group'){
						// let copy_json = JSON.parse(JSON.stringify(modal_json_copy))
						// let category_index = copy_json.categories.findIndex(x=>x.id == active_category_id)
						// if(category_index != -1){
						// 	let sub_category_index = copy_json.categories[category_index].sub_categories.findIndex(x=>x.id == active_sub_category_id)
						// 	if(sub_category_index != -1){
						// 		if(!copy_json.categories[category_index].sub_categories[sub_category_index].sku_group){
						// 			copy_json.categories[category_index].sub_categories[sub_category_index].sku_group = []
						// 		}
						// 		copy_json.categories[category_index].sub_categories[sub_category_index].sku_group.push(o);
								// update_from_modal_json(copy_json)
								let grp_copy = [];
								if(groups){
									grp_copy = JSON.parse(JSON.stringify(groups));
								}
								grp_copy.push(o)
								set_groups(grp_copy)
								set_active_group_id(o.id)
							// }
						// }
					}
					toggle();
				}catch(error){
					if(typeof(error) === "string"){
						alert.error(error);
					}else{
						alert.error("Something went wrong. Please try again in sometime.")
					}
				}
			}else{
				alert.error("Please Enter valid Name");
			}
		}
		
		return(
			<Modal className='modal-dialog-centered' isOpen={is_create_open} toggle={toggle} size='md'>
				<ModalHeader style={{height:'60px', textTransform:'capitalize'}} toggle={toggle}>Add New {mode}</ModalHeader>
				<ModalBody style={{fontSize:'14px'}}>
					<div className='flex_property js-sb'>
						<span style={{width:'120px' , display:'flex', alignItems:'center', textTransform:'capitalize'}}>{mode} Name</span>
						<Input style={{width:'280px'}} type='text' placeholder={mode + ' name'} value={name} onChange={(e) => set_name(e.target.value)}></Input>
					</div>
				</ModalBody>
				<ModalFooter style={{justifyContent:'end', border:'0px'}}> 
					<Button style={{color:'white', border:'0px', padding:'8px 24px', borderRadius:'6px', fontSize:'14px', textTransform:'capitalize'}} onClick={add_new_component} className='primary_button_default'>Add</Button>
				</ModalFooter>
			</Modal>
		)
	}

	return(
		<React.Fragment>
			{/* <Upload3dModelModal open={is_upload_open} handleclose={toggle_upload} set_page_loader={set_page_loader} callbackFnOn3DModelUpload={(resp) => set_model_id(resp.id)}/> */}
			<AddNewOption/>
					{
						modal_type == "user_sku_creation" ?
						modal_json && modal_json.org_allowed ?
							<React.Fragment>
								<Radio.Group onChange={(e) => { set_catalogue_mode(e.target.value); update_sub_categories(active_category_id, undefined , e.target.value) }} value={catalogue_mode} className='infurnia-radio mb-3' style={{ width: '100%', display: 'flex' }}>
									<Radio.Button value="user">User Catalogue</Radio.Button>
									<Radio.Button value="org">Org Catalogue</Radio.Button>
								</Radio.Group>
								<div className='mb-3' style={{color: 'var(--inf-theme-gray-400)'}}>{catalogue_mode === "user" ? "Models published in User Catalogue will only be available to you." : "Models published in Org Catalogue will be available to all members in your Organisation."}</div>
							</React.Fragment>
							:
							<div className='mb-3' style={{color: 'var(--inf-theme-gray-400)'}}>By publishing to user catalogue, 3d models will be available to you in all designs, it wont be seen by other in the Org.</div>

						:
						<Radio.Group onChange={(e) => { set_sku_mode(e.target.value) }} value={sku_mode} className='infurnia-radio mb-3' style={{ width: '100%', display: 'flex' }}>
							<Radio.Button style={{minWidth: '0px'}} value="new"><div className='lines1_elipsis' title={`Add new ${modal_json && modal_json.default_sku_name ? modal_json.default_sku_name : 'Template'}`}>Add new {modal_json && modal_json.default_sku_name ? modal_json.default_sku_name : 'Template'}</div></Radio.Button>
							<Radio.Button style={{minWidth: '0px'}} value="existing"><div className='lines1_elipsis' title={`Update existing ${modal_json && modal_json.default_sku_name ? modal_json.default_sku_name : 'Template'}`}>Update existing {modal_json && modal_json.default_sku_name ? modal_json.default_sku_name : 'Template'}</div></Radio.Button>
						</Radio.Group>
					}
					{
						modal_type === "user_sku_creation" ?
							<div className='mb-3'>
								<div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
									{t(`Division`)}
									{
										modal_type === "user_sku_creation" ?
											<TriggerTooltip
												placement="bottomLeft"
												overlayStyle={{ maxWidth: '400px' }}
												arrow={{ pointAtCenter: true }}
												title={
													<div>
														<div>4 division in which 3D models can be saved</div>
														<div style={{ display: 'flex' }}><div className='inf-mr-2' style={{minWidth: '100px', maxWidth: '100px', fontWeight: 'bold'}}>Furniture:</div> has modular and furnish items (cabinet, wardrobe)</div>
														<div style={{ display: 'flex' }}><div className='inf-mr-2' style={{minWidth: '100px', maxWidth: '100px', fontWeight: 'bold'}}>Hardware:</div> has all parts and components (basket, edgebands)</div>
														<div style={{ display: 'flex' }}><div className='inf-mr-2' style={{minWidth: '100px', maxWidth: '100px', fontWeight: 'bold'}}>Accessories:</div> has all decors, and appliances  (lights, plant)</div>
														<div style={{ display: 'flex' }}><div className='inf-mr-2' style={{minWidth: '100px', maxWidth: '100px', fontWeight: 'bold'}}>Building:</div> has all architecture items (door, window)</div>
													</div>
												}
											/>
											: ''
									}
								</div>
								<div className='flex_property input_container_publish_sheet'>
									<Input type='select' style={{ ...styles_modals.input_styles_none, height: "30px", textTransform: 'capitalize' }} value={active_division_id} onChange={(e) => update_categories(e.target.value, divisions.filter(x => x.id == e.target.value)[0])} >
										{divisions.map((o, idx) => (
											<option key={idx} value={o.id}>{o.name}</option>
										))}
									</Input>
									{/* <div className='add_option_container_publish' style={{ display: (modal_type === "publish_template" && sku_mode == 'new') || (modal_type === "user_sku_creation" && catalogue_mode != "user") ? 'flex' : 'none' }}>
										<div className='add_option_publish' onClick={() => { set_mode('category'); toggle() }}>
											<i style={{ fontSize: '12px', color: '#1070CA' }} className='fa fa-plus' />
										</div>
									</div> */}
								</div>
							</div>
							: ''
					}
					
					<div className='mb-3'>
						<div style={{display: 'flex', alignItems: 'center', marginBottom: '4px'}}>
							{t(`Category`)} 
							{
								modal_type === "user_sku_creation" ?
									<TriggerTooltip
										placement="bottomLeft"
										overlayStyle={{maxWidth: '300px'}} 
										arrow={{pointAtCenter: true}}
										title={
											<div>
												<div style={{display: 'inline-block'}}><b>Category is a wider bucket</b> under division. </div>
												<div>For eg: Chair is a category and has sub categories like office chairs, home chairs, bench, etc.</div>
											</div>
										}
									/>
								:''
							}
						</div>
						<div className='flex_property input_container_publish_sheet'>
							<Input type='select' style={{ ...styles_modals.input_styles_none, height: "30px" }} value={active_category_id} onChange={(e) => update_sub_categories(e.target.value, categories.filter(x => x.id == e.target.value)[0])} >
								{categories.map((o, idx) => (
									<option key={idx} value={o.id}>{o.name}</option>
								))}
							</Input>
							<div className='add_option_container_publish' style={{display:(modal_type === "publish_template" && sku_mode == 'new') || (modal_type === "user_sku_creation" && catalogue_mode != "user") ? 'flex' : 'none'}}>
								<div className='add_option_publish' onClick={() => { set_mode('category'); toggle() }}>
									<i style={{ fontSize: '12px', color: '#1070CA' }} className='fa fa-plus' />
								</div>
							</div>
						</div>
					</div>
					<div className='mb-3'>
						<div style={{display: 'flex', alignItems: 'center', marginBottom: '4px'}}>
							{t(`Sub Category`)} 
							{
								modal_type === "user_sku_creation" ?
									<TriggerTooltip
										placement="bottomLeft"
										overlayStyle={{maxWidth: '400px'}} 
										arrow={{pointAtCenter: true}}
										title={
											<div>
												<div style={{display: 'inline-block'}}><b>Sub-category is sub division with in Category, It contain groups.</b></div>
												<div>For eg: Chair is a category and has sub category like office chairs, home chairs, bench, etc.</div>
											</div>
										}
									/>
								:''
							}
						</div>
						<div className='flex_property input_container_publish_sheet'>
							<Input type='select' style={{ ...styles_modals.input_styles_none, height: "30px" }} value={active_sub_category_id} onChange={(e) => update_groups(e.target.value, sub_categories.filter(x => x.id == e.target.value)[0])} >
								{sub_categories.map((o, idx) => (
									<option key={idx} value={o.id}>{o.name}</option>
								))}
							</Input>
							<div className='add_option_container_publish' style={{display:(modal_type === "publish_template" && sku_mode == 'new') || (modal_type === "user_sku_creation" && catalogue_mode != "user") ? 'flex' : 'none'}}>
								<div className='add_option_publish' onClick={active_category_id != '' ? () => { set_mode('sub category'); toggle() } : () => alert.error('Please select active category')}>
									<i style={{ fontSize: '12px', color: '#1070CA' }} className='fa fa-plus' />
								</div>
							</div>
						</div>
					</div>
					<div className='mb-3'>
						<div style={{display: 'flex', alignItems: 'center', marginBottom: '4px'}}>
							{t(`Group`)} 
							{
								modal_type === "user_sku_creation" ?
									<TriggerTooltip
										placement="bottomLeft"
										overlayStyle={{maxWidth: '300px'}} 
										arrow={{pointAtCenter: true}}
										title={
											<div>
												<div style={{display: 'inline-block'}}><b>Group is a Collection of SKUs.</b></div>
												<div>Has multiple variants of same SKUs</div>
												<div>Eg: Different size of same SKUs in one group</div>
											</div>
										}
									/>
								:''
							}
						</div>
						<div className='flex_property input_container_publish_sheet'>
							<Input type='select' style={{ ...styles_modals.input_styles_none, height: "30px" }} value={active_group_id} onChange={(e) => /*set_active_group_id(e.target.value)*/ update_skus(e.target.value, groups.filter(x => x.id == e.target.value)[0])} >
								{groups.map((o, idx) => (
									<option key={idx} value={o.id}>{o.name}</option>
								))}
							</Input>
							<div className='add_option_container_publish' style={{display:sku_mode == 'new' ? 'flex' : 'none'}}>
								<div className='add_option_publish' onClick={active_sub_category_id != '' && active_category_id != '' ? () => { set_mode('group'); toggle() } : () => alert.error('Please select active category and subcategory')}>
									<i style={{ fontSize: '12px', color: '#1070CA' }} className='fa fa-plus' />
								</div>
							</div>
						</div>
					</div>
					<div className={modal_type == "user_sku_creation" ? 'mb-3' : 'mb-4'}>
						<div style={{display: 'flex', alignItems: 'center', marginBottom: '4px'}}>
							{t(`SKU`)} 
							{
								modal_type === "user_sku_creation" ?
									<TriggerTooltip
										placement="bottomLeft"
										overlayStyle={{maxWidth: '300px'}} 
										arrow={{pointAtCenter: true}}
										title={
											<div>
												<div style={{display: 'inline-block'}}><b>SKU is an</b> element which can be used in design.</div>
												<div>It has price and other properties.</div>
											</div>
										}
									/>
								:''
							}
						</div>
						{
							sku_mode === 'new' || modal_type == "user_sku_creation" ?
								<Input onChange={e => set_sku_name(e.target.value)} value={sku_name} type='text' style={{...styles_modals.input_styles_2, height: '32px'}}/>
								:
								<Input type='select' value={active_sku_id} onChange={(e) => set_active_sku_id(e.target.value)} style={{...styles_modals.input_styles_2, height: '32px'}} >
									{skus.map((o, idx) => (
										<option key={idx} value={o.id}>{o.name}</option>
									))}
								</Input>
						}
					</div>
					{/* {
						modal_type == "user_sku_creation" ? 
						<div className='flex_property mb-4'><div className='inf-mr-4'>3D Model</div><Button className='secondary_button_default' onClick={toggle_upload}>Upload</Button></div>
						: ""
					} */}
					{
						showSubmit ?
							<div className='flex_center mb-3'>
								<Button onClick={submit} className='primary_button_default'>{sku_mode === 'new' ? `Publish ${modal_json && modal_json.default_sku_name ? modal_json && modal_json.default_sku_name : 'Template'}` : `Update ${modal_json && modal_json.default_sku_name ? modal_json && modal_json.default_sku_name : 'Template'}`}</Button>
							</div>
							: ''
					}
		</React.Fragment>
	) 
}


const Publish_Sheet = ({  modal_type, handle_close,  modal_json, set_page_loader, handle_ui_response, update_view }) => {
	const onclick_handle_close = () => {
		if(modal_type === 'user_sku_creation'){
			let analytics_data = {}
			analytics_data.category = "Publish 3D Model Modal Closed"
			send_analytics(analytics_data)
		}
		handle_close()
	}
	return(
		<React.Fragment>
			<Modal className="modal-dialog-centered" size="md" isOpen={modal_type=="publish_template" || modal_type == "user_sku_creation"}>
				<IModalHeader toggle={onclick_handle_close}>{modal_type=="publish_template" ? `Publish ${modal_json && modal_json.default_sku_name ? modal_json.default_sku_name : 'Template'}` : (modal_json && modal_json.org_allowed ? "Publishing 3D Model to catalogue" : "Save 3D model in User catalogue")}</IModalHeader>
				<IModalBody className="DMSans px-5">
					<PublishComponent modal_json={modal_json} modal_type={modal_type} set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} update_view={update_view} handle_close={onclick_handle_close}/>
				</IModalBody>
			</Modal>
		</React.Fragment>
	) 
}

const Edit_elevation_view_Modal = ({ modal_type, handle_close, modal_json }) => {

    const [ updated_elevation_view_name, set_updated_elevation_view_name ]=useState('')

    const onclick_handle_close = () => {
        set_updated_elevation_view_name('')
        handle_close()
    }

    const submit_values = () => {
		window.Module.updateElevationCameraNameById(modal_json.id,updated_elevation_view_name);
        onclick_handle_close()
    }

    useEffect(() => {
        if(modal_type === "edit_elevation_view_name" && modal_json){
            set_updated_elevation_view_name(modal_json.name);
        }
    },[modal_json, modal_type])

    return(
        <Modal className="modal-dialog-centered" size="md" isOpen={modal_type === "edit_elevation_view_name"} toggle={onclick_handle_close}>
            <Card className="bg-secondary shadow border-0">
                <Row>
                    <Col style={{height:'54px', width:'100%', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between', backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}}>
                        <span>Edit Elevation View Name</span>
                        <span style={{cursor:'pointer'}} onClick={onclick_handle_close}>×</span>
                    </Col>
                </Row>
                <Row>
                    <Col style={{height:'186px', width:'100%', backgroundColor: COLORS.white, padding:'20px 24px'}}>
                        <Row>
                            <Col style={{width:'100%', display:'flex', alignItems:'center', height:'28px', marginBottom:'10px'}}>
                                <span style={{flex:2, fontSize:'14px'}}>Elevation View Name*</span>
                                <Input style={{flex:3, height:"25px", border:0, borderBottom:'1px solid #ced4da'}}  outlined='false' type="text" value={updated_elevation_view_name} onChange={(e) => set_updated_elevation_view_name(e.target.value) } />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign:'center',marginTop:'30px'}}>
                                <Button onClick={submit_values} style={{backgroundColor:COLORS.mainpage_locale, padding:'8px 30px',boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)', border:0}} type="button" >Update</Button>
                            </Col>  
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Modal>
        
    )    
}

const Shortcut_List = ({  modal_type, handle_close,  modal_json }) => {
	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="shortcut_list"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Keyboard Shortcuts</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white }} className="px-lg-5 py-lg-5">
					<Row>
						{modal_json && modal_json.length && modal_json.map((o,idx) => 
							<Col xs={6} style={{textAlign:"left"}}>
								<div>{o.key} - {o.description}</div>
							</Col>
						)}
					</Row>
				</CardBody>
			</Card>
		</Modal>
	)  
}

const Generic_input = ({  modal_type, handle_close,  modal_json }) => {
	const [ value, set_value ] = useState('');

	useEffect(() => {
		if(modal_type == "generic_input" && modal_json){
			set_value(modal_json.default_value);
		}
	},[modal_json, modal_type])

	const submit = () => {
		window.Module[modal_json.route](value);
		handle_close();
	}

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={modal_type=="generic_input"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>{modal_json.title}</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white }} className="px-lg-5 py-lg-5">
					<Row>
						<Col>
							<div>{modal_json.message}</div>
						</Col>  
					</Row>
					<br/>
					<Row>
						<Col>
							<Input onChange={e => set_value(e.target.value)} value={value} type='text' />
						</Col>  
					</Row>
					<div className="text-right">
						<Button onClick={submit} className="mt-4" color={COLORS.button_primary} type="button">Submit</Button>&nbsp;
					</div>
				</CardBody>
			</Card>
		</Modal>
	)    
}

const Background_Scale_Input = ({ modal_type, handle_close,  modal_json, metric_system, approximation_ft, approximation_mm, approxMM, feetTomm, mmToFeetParts }) => {

	const [ value, set_value ] = useState('')
	const [feet, set_feet] = useState(0)
	const [inches, set_inches] = useState(0)
	const [fraction_number, set_fraction_number] = useState(0)

	useEffect(() => {
		if(modal_type == "background_scale_input" && modal_json){
			set_value(modal_json.default_value);
		}
	},[modal_json, modal_type])

	
	const submit = () => {
		if(metric_system == 'mm'){
			window.Module[modal_json.route](String(approxMM(Number(value))));
		}else{
			window.Module[modal_json.route](String(feetTomm(Number(feet), Number(inches), Number(fraction_number))))
		}
		handle_close();
	}


	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={modal_type=="background_scale_input"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>{modal_json.title}</span><span><Button onClick={handle_close}>x</Button></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white }} className="px-lg-5 py-lg-5">
					<Row>
						<Col>
							<div>{modal_json.message}</div>
						</Col>  
					</Row>
					<br/>
					<Row>
						<Col>
						{
							metric_system === 'mm' ?
							<span style={{display:'flex', alignItems:'center'}}>
								<Input onChange={e => set_value(e.target.value)} value={value} type='text' style={{marginRight:'5px'}}/>
								(mm)
							</span>
							:
							<Row style={{}}>
								<Col style={{display:'flex', alignItems:'center', flexDirection:'column'}} sm={4}>
									<Input type="text" style={styles_modals.input_styles} placeholder="0" onChange={(e) => {set_feet(e.target.value)}} value={feet}/>
									<span style={{fontSize:13, textAlign:'left', cursor:"default", marginLeft:'5px'}}>ft</span>
								</Col>
								<Col style={{display:'flex', alignItems:'center', flexDirection:'column'}} sm={4}>
									<Input type="text" style={styles_modals.input_styles} placeholder="0" onChange={(e) => {set_inches(e.target.value)}} value={inches}/>
									<span style={{fontSize:13, textAlign:'left', cursor:"default", marginLeft:'5px'}}>in</span>
								</Col>
								<Col style={{display:'flex', alignItems:'center', flexDirection:'column'}} sm={4}>
									<Input type="text" style={styles_modals.input_styles} placeholder="0" onChange={(e) => {set_fraction_number(e.target.value)}} value={fraction_number}/>
									<span style={{fontSize:13, textAlign:'left', cursor:"default", marginLeft:'5px'}}>{approximation_ft=='1/8_inch'?'¹/₈"':'¹/₁₆"'}</span>
								</Col>
							</Row>
						}
						</Col>  
					</Row>
					<div className="text-right">
						<Button onClick={submit} className="mt-4" color={COLORS.button_primary} type="button">Submit</Button>&nbsp;
					</div>
				</CardBody>
			</Card>
		</Modal>
	)    
}

const Confirm_Modal = ({  modal_type, handle_close,  modal_json , handle_ui_response, update_view}) => {
	const alert = useAlert()
	const submit = async () => {
		try{
			var resp = await window.Promisify(window.Module[modal_json.route](JSON.stringify(modal_json)));
			handle_ui_response(resp);
			if(modal_json.update_panel_json){
				repopulate_panel();
				repopulate_panel_pop_up();
			}
			handle_close();
		}catch(err){
			alert.error("Failed to accept. Try again in sometime.")
			console.error(`Failed to accept. modal json - ${JSON.stringify(modal_json)}, error - `, err)
			handleError(err)
		}
	}

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={modal_type=="confirm_modal"}>
			<IModalBody style={{backgroundColor:'#FFFFFF', borderRadius: '4px', padding:'36px 16px'}}>
				<div style={{textAlign:'center'}}>{modal_json?modal_json.message:''}</div>
				<div className='flex_center' style={{paddingTop:"24px", textAlign:'center'}}>
					<Button onClick={handle_close} style={{height:'30px', marginRight:'10px', backgroundColor:'#183E62', backgroundImage:'unset'}} className='secondary_button_default' >Cancel</Button>
					<Button onClick={submit} style={{height:'30px',backgroundColor:'#183E62', backgroundImage:'unset',}} className='primary_button_default' >Accept</Button>
				</div>
			</IModalBody>
		</Modal>
	)    
}

const Two_Button_Modal = ({  modal_type, handle_close,  modal_json , handle_ui_response,update_view}) => {
	const [ footer_text, set_footer_text ] = useState('')
	
	const submit_left = async () => {
		if(window.debug_mode){
			window.add_debug_log(modal_json.left_button_route + "(\"\");");
		}
		var resp = await window.Promisify(window.Module[modal_json.left_button_route](""));
		handle_ui_response(resp);
		if(modal_json.update_panel_json){
			repopulate_panel();
			repopulate_panel_pop_up();
		}
		handle_close();
	}
	const submit_right = async () => {
		if(window.debug_mode){
			window.add_debug_log(modal_json.right_button_route + "(\"\");");
		}
		var resp = await window.Promisify(window.Module[modal_json.right_button_route](""));
		handle_ui_response(resp);
		if(modal_json.update_panel_json){
			repopulate_panel();
			repopulate_panel_pop_up();
		}
		handle_close();
	}

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={modal_type=="two_button_modal"}>
			<IModalBody style={{backgroundColor:'#FFFFFF', borderRadius: '4px', padding:'45px 10px 20px'}}>
				<div style={{textAlign:'center'}}>{modal_json?modal_json.message:''}</div>
				<div className='flex_center' style={{paddingTop:"24px", textAlign:'center'}}>
					<Button onMouseEnter={() => {set_footer_text(modal_json.left_hover_text)}} onMouseLeave={() => {set_footer_text("")}} onClick={submit_left}  className='primary_button_default mr-3' >{modal_json.left_button_name}</Button>
					<Button onMouseEnter={() => {set_footer_text(modal_json.right_hover_text)}} onMouseLeave={() => {set_footer_text("")}} onClick={submit_right}  className='secondary_button_default' >{modal_json.right_button_name}</Button>
				</div>
				{
						<div className='mt-3' style={{color: COLORS.gray5, fontStyle: 'italic', visibility: footer_text ? "visible" : "hidden", textAlign: 'center'}}>
							{footer_text ? footer_text : "No Footer"}
						</div>
				}
			</IModalBody>
		</Modal>
	)    
}

const InfoModal = ({  open, handle_close,  message , submit}) => {
	const submit_fn = async () => {
		submit()
		handle_close();
	}

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open}>
			<IModalBody style={{backgroundColor:'#FFFFFF', borderRadius: '4px', padding:'36px 16px'}}>
				<div style={{textAlign:'center'}}>{message ? message :''}</div>
				<div style={{paddingTop:"24px", textAlign:'center'}}>
					<Button onClick={handle_close} style={{height:'30px', marginRight:'10px', backgroundColor:'#183E62', backgroundImage:'unset'}} className='secondary_button_default' >Cancel</Button>
					<Button onClick={submit_fn} style={{height:'30px',backgroundColor:'#183E62', backgroundImage:'unset',}} className='primary_button_default' >Accept</Button>
				</div>
			</IModalBody>
		</Modal>
	)    
}

const Seller_Preview_Modal = ({  modal_type, handle_close,  modal_json , handle_ui_response}) => {
	const [ update_sku_display_image, set_update_sku_display_image ] = useState(false);
	const alert = useAlert()
	const submit = async () => {
		try{
			if(update_sku_display_image){
				var form_data = new FormData();
				var blob = new Blob([window.FS.root.contents["preview.png"]["contents"].slice(0,window.FS.root.contents["preview.png"]["usedBytes"])], {type: "image/png"});
				var file_json = new File([blob], modal_json.sku_id + "_autogenerated_preview.png");
				form_data.append('sku_id', modal_json.sku_id)
				form_data.append("upl",file_json);
				var resp = await fetch(window.Module.API.server_path + "/sku/update",{
					method:"POST",
					body:form_data,
					credentials: "include",
					headers:{'X-Request-Id':window.Module.generateRandomHex32String()}
				});

				var resp_json = await resp.json();
				if(resp_json && resp_json.response_code == 1){

				}else{
					console.log("FAILED TO UPDATE SKU THUMBNAIL");
					alert.error("Could not update the sku thumbnail. Please try again later");
				}
			}
			var resp = await window.Promisify(window.Module[modal_json.route](modal_json.sku_id));
			handle_ui_response(resp);
			handle_close();
			if(window.reload_after){
				window.location.reload(true)
				window.reload_after = false;
			}
		}catch(err){
			alert.error("Failed to save sku. Try again in sometime.")
			console.error(`Failed to save sku. modal json - ${JSON.stringify(modal_json)}, error - `, err)
			handleError(err)
		}
	}

	const get_url = () => {
		if(modal_json && modal_json.src=="preview.png" && window.canvas_2d.images["preview.png"]){
			return window.canvas_2d.images["preview.png"].toDataURL()
		}
		return '';
	}
	
	const shift_component_origin = async () => {
		if(modal_json.origin_shifting_possible){
			var resp = window.Module[modal_json.origin_shift_route](modal_json.sku_id);
			handle_ui_response(resp);
		}
	}

	useEffect(() => {
		if(modal_type == "seller_save_preview" && modal_json){
			set_update_sku_display_image(false);	
		}
	},[modal_type, modal_json])

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={modal_type=="seller_save_preview"}>
			<IModalHeader toggle={handle_close}>Save Confirmation</IModalHeader>
			<IModalBody className='flex_center' style={{flexDirection:'column'}}>
				<div className='mb-4'>{modal_json?modal_json.message:''}</div>
				<img className='mb-4' src={get_url()} height='180px'/>
				<Checkbox title='Set preview as SKU thumbnail' checked={update_sku_display_image}  onClick={() => set_update_sku_display_image(!update_sku_display_image)}> Set preview as SKU thumbnail </Checkbox>
				<br />
				{modal_json && modal_json.needs_origin_shifting_message?(
					<div>
						{modal_json.needs_origin_shifting_message}
						<br />
						<Button id="shift_component_origin_button" onClick={shift_component_origin} className="primary_button_default" style={{opacity: modal_json.origin_shifting_possible?1:0.5}} type="button">Shift Origin</Button>
						{!(modal_json.origin_shifting_possible)?(<UncontrolledTooltip placement="top" target="shift_component_origin_button" >
							{modal_json.origin_shifting_disabled_message}
						</UncontrolledTooltip>):''}
					</div>
				):''}
			</IModalBody>
			<IModalFooter>
				<Button onClick={handle_close} className='secondary_button_default' >Go Back</Button>
				<Button onClick={submit} className='primary_button_default' >{window.reload_after ? 'Save & Refresh' : 'Save'}</Button>
			</IModalFooter>
		</Modal>
	)    
}

var fixed_length_submitted = false;

const Fixed_Length_Input = ({  modal_type, handle_close, interface_mode, handle_ui_response,  modal_json, metric_system, approximation_ft , update_view, perspective}) => {
	const [ value, set_value ] = useState('');
	const [ textbox_style, set_textbox_style] = useState({left:0, top:0, width: 50, height: 20});


	useEffect(() => {
		if(modal_type == "fixed_length" && modal_json){
			fixed_length_submitted = false;
			set_text_position();
			if(modal_json.selectInput){
				setTimeout(() => {
					var input = document.getElementById("fixed_length_input_box");
					if(input){
						input.select();
					}
				},0)
			}
		}
	},[modal_json, modal_type])

	const numberListValidity = (units) => {
		if(modal_json.is_angle){
			units = "mm";
		}
		var current_string = document.getElementById("fixed_length_input_box").value;
		var listOfStrings = current_string.split(",");
		var applyFixedLength = true;
		for(var j = 0; j<listOfStrings.length ; j++){
			if (units == 'mm'){
				if(!isFinite(listOfStrings[j])){
					applyFixedLength = false;
				}
			}else if (units == 'ft'){
				var ft_part =listOfStrings[j].split("'")[0];

				var only_inches = false;
				var num = listOfStrings[j];

				if (!num.includes("'") && num.includes('"')){
					//only inches
					only_inches = true;
				}
				
				ft_part = ft_part.trim();
				
				if(!isFinite(ft_part) && !only_inches){
					applyFixedLength = false;
				}

				if (listOfStrings[j].split("'").length > 1 || only_inches){
					var remain = "";
					if (!only_inches){
						remain = listOfStrings[j].split("'")[1];
						remain = remain.trim();
					}else{
						remain = num;
					}

					var fraction = remain;

					var space_splitted = remain.split(" ");
					space_splitted[0] = space_splitted[0].trim();
					if (space_splitted.length > 2){
						applyFixedLength = false;
					}else if (space_splitted.length == 2){
						if (!isFinite(space_splitted[0]) ){
							applyFixedLength = false;
						}

						fraction = space_splitted[1].trim();
					}else if (space_splitted.length == 1){
						fraction = space_splitted[0];
					}	


					var splitter = "/";

					if(fraction && fraction.includes(splitter) ){
						var fraction_numer = fraction.split("/")[0];
						var fraction_denom = (fraction.split("/")[1]).split('"')[0];

						if (!isFinite(fraction_numer) || !isFinite(fraction_denom)){
							applyFixedLength = false;
						}
						
					}else if (fraction){
						if (!isFinite(fraction.split('"')[0]) || space_splitted.length>1){
							applyFixedLength = false;
						}
					}
				}
			}			
		}
		return applyFixedLength;
	}

	const submit = async () => {
		if(!fixed_length_submitted){
			if(numberListValidity(metric_system)){
				fixed_length_submitted = true;
				var units = "mm";
				if(!modal_json.is_angle){
					units = metric_system
				}
				var parsed_value = window.parse_dimension(document.getElementById("fixed_length_input_box").value, units , approximation_ft,"Enter");
				var ui_response = await window.Promisify(window.Module[modal_json.route](String(parsed_value)));

				if(window.debug_mode){
					window.add_debug_log(modal_json.route + "(\"" + String(parsed_value).replace(/"/g, '\\"') + "\");");
				}
				handle_ui_response(ui_response);
			}else{
				handle_ui_response(JSON.stringify({toast:{message:"Invalid Input",type:"error"}}))
			}
		}
	}

	const submit_without_closing_modal = async () => {
		if(!fixed_length_submitted){
			
			if(numberListValidity()){
				fixed_length_submitted = true;
				var units = "mm";
				if(!modal_json.is_angle){
					units = metric_system
				}
				var parsed_value = window.parse_dimension(document.getElementById("fixed_length_input_box").value, units, approximation_ft,"Other");
				var ui_response = await window.Promisify(window.Module[modal_json.route](String(parsed_value)));
				if(window.debug_mode){
					window.add_debug_log(modal_json.route + "(\"" + String(parsed_value).replace(/"/g, '\\"') + "\");");
					window.add_debug_log("keydown_events(\"Alt\",false,false,false,true);");
				}
				window.Module.keydown_events("Alt",false,false,false,true);
				handle_close();
			}else{
				handle_ui_response(JSON.stringify({toast:{message:"Invalid Input",type:"error"}}))
			}
		}
	}
	
	const set_text_position = () =>{
		var offset = {left:0, top: 0}
		if(window.$('#canvas_container_div').offset()){
			offset = window.$('#canvas_container_div').offset();
		}
		if(window.$('#webgl_main_canvas').offset()){
			offset = window.$('#webgl_main_canvas').offset();
		}

		var textdim = {width:100,height:20};
		var text_box = document.getElementById("fixed_length_input_box");
		if(!text_box || !modal_json){
			return;
		}
		set_textbox_style({
			left: (offset.left + modal_json.x + (modal_json.offsetX?modal_json.offsetX:(-textdim.width/2))),
			top: (offset.top + modal_json.y + (modal_json.offsetY?modal_json.offsetY:(-textdim.height/2))),
			width: textdim.width,
			height: textdim.height
		})
		set_value(modal_json.default_value);

		window.$(text_box).off();
		text_box.blur();
		window.$(text_box).keydown(function(e){
			if(e.key == 'Escape'){
				window.$(text_box).off();
				if(window.two_interface && window.two_interface.em_interface && window.two_interface.em_interface.reset_fixed_length){
					window.two_interface.em_interface.reset_fixed_length();
				}
				if(window.three_interface && window.three_interface.em_interface && window.three_interface.em_interface.reset_fixed_length){
					window.three_interface.em_interface.reset_fixed_length();
				}
				handle_close();
			}
		})
		window.$(text_box).blur(submit);
		window.$(text_box).keyup(function(e){
			if(e.key == 'Alt'){
				if(window.Module.fixed_length_alt_allowed()){
					submit_without_closing_modal()	
				}
			}
			if(e.key == 'Enter'){
				return submit()
			}
		})

		window.$(text_box).focus();
	}

	return(
		<Input style={{display:modal_type=="fixed_length"?"block":"none",fontSize:'16px',position:'fixed',zIndex:10000,...textbox_style,width:modal_json && modal_json.width?modal_json.width:textbox_style.width,height:modal_json && modal_json.height?modal_json.height:textbox_style.height}} id="fixed_length_input_box" onChange={(e) => set_value(e.target.value)} value={value} type="text"></Input>
	) 

}

// const Share_invite_modal = ({open, users, handle_close,invite_users_onclick}) => {
// 	const [selected_users, set_selected_users] = useState([])
// 	const [role, set_role] = useState("write")
// 	const alert = useAlert();
//     const {t} = useTranslation()

// 	useEffect(() => {
// 		if(open){
// 			set_selected_users([]);
// 		}
// 	}, [open])

// 	const submit_values = () => {
// 		var user_data = []
// 		for(var i=0;i<selected_users.length;i++){
// 			user_data.push({
// 				id: selected_users[i].id,
// 				role: role,
// 				is_selected: true,
// 				label: selected_users[i].label
// 			})
// 		}

// 		invite_users_onclick(user_data);
// 	}

// 	const handleOnChange = (selectedOptions) => {
// 		set_selected_users(selectedOptions);
// 	};

// 	return(
// 		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={handle_close}>
// 			<IModalHeader toggle={handle_close}>Invite Designer</IModalHeader>
// 			<IModalBody>
// 				<div className='flex_property mb-3'>
// 					<div className='mr-3'>{t(`Email`)}</div>
// 					<Typeahead
// 						multiple
// 						id="share_branch_typeahead"
// 						onChange={(selected) => {
// 							set_selected_users(selected);
// 							// console.log(selected)
// 						}}
// 						options={users}
// 						selected={selected_users}
// 						style={{...styles_modals.input_styles}}
// 					/>
// 					{/* <Select
// 						isMulti
// 						id="share_branch_typeahead"
// 						onChange={handleOnChange}
// 						options={users}
// 						value={selected_users}
// 					/> */}
// 				</div>
// 				<div className='flex_property justify-content-between'>
// 					<div className='flex_property'>
// 						<div className='mr-3'>{t(`Access`)}</div>
// 						<Input style={{ ...styles_modals.input_styles, padding: '4px', width: 'fit-content' }} value={role} onChange={(e) => { set_role(e.target.value) }} type="select">
// 							<option value={"read"}>View</option>
// 							<option value={"write"}>Edit</option>
// 						</Input>
// 					</div>
// 					<Button onClick={submit_values} className="primary_button_default" type="button">Invite</Button>
// 				</div>
// 			</IModalBody>
// 			{/* <Card className="bg-secondary shadow border-0">
// 				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
// 					<div>
// 						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Invite Designer</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
// 					</div>
// 				</CardHeader>
// 				<CardBody style = {{backgroundColor: COLORS.white }} className="px-lg-5 py-lg-5">
// 					<Row style={{marginBottom: '10px'}}>
// 						<Col xs={3} className="align_center" style={{fontSize:'14px'}} >
// 							Email
// 						</Col>
// 						<Col xs={9}>
// 							<div></div>
// 							<Typeahead
// 								multiple
// 								id="share_branch_typeahead"
// 								onChange={(selected) => {
// 									set_selected_users(selected);
// 									// console.log(selected)
// 								}}
// 								options={users}
// 								selected={selected_users}
// 							/>
// 						</Col>  
// 					</Row>
// 					<Row>
// 						<Col xs={6} style={{textAlign:'center',fontSize:'14px',display:'flex',alignItems:'end'}}>
// 							<div style={{paddingRight:10,marginBottom:6}}>Access</div>
// 							<Input style={{fontSize:'14px',textAlign:"center",padding:0,width:'50%'}} value={role} onChange={(e) => {set_role(e.target.value)}}  type="select">
// 								<option value={"read"}>View</option>
// 								<option value={"write"}>Edit</option>
// 							</Input>
// 						</Col>
// 						<Col xs={6} style={{textAlign:'right'}}>
// 							<Button onClick={submit_values} className="mt-4 blue_button" type="button">Invite</Button>&nbsp;
// 						</Col>
// 					</Row>
// 				</CardBody>
// 			</Card> */}
// 		</Modal>
// 	)    
// }

const Share_Branch_Modal_New = ({  org_key, modal_type, handle_close,  modal_json, handle_ui_response }) => {
	const alert = useAlert();
	const [ share_link, set_share_link ] = useState('');
	const [ current_sharing, set_current_sharing] = useState(false);
	const [ share_collapse_open, set_share_collapse_open] = useState(false);
	const [selected_users, set_selected_users] = useState([])
	const [open_invite , set_open_invite] = useState(false);
	const [users, set_users] = useState([])

	const random_colors = ['#dd51c0', '#7fe11b', '#681b81', '#c2b5d0', '#5a5e23', '#376911', '#9c6ba5', '#5c96dd', '#347907', '#ddbb2', '#583fa0', '#453d21', '#a93626', '#77cdf', '#ae995', '#b5b3bc', '#eabf6e', '#4cb8a5', '#7a49fd', '#ade45e', '#2f1167', '#fd054a', '#fa953d', '#d0a9ce', '#51076a', '#ef1597'];
	const alphabets = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z"];
    const {t} = useTranslation()

	useEffect(() => {
		if(modal_type == "share_branch_modal_new" && modal_json && modal_json.share_link){
			var new_share_link = modal_json.share_link;
			if(org_key){
				var split = modal_json.share_link.split("share");
				split[0] += org_key + "/share"
				new_share_link = split[0] + split[1];
			}

			set_share_link(new_share_link);
			update_current_sharing();

			var users_deref = JSON.parse(JSON.stringify(modal_json.users));
			set_users(users_deref)
			var filtered_users = users_deref.filter(o=> o.is_selected && o.is_selected==true);
			set_selected_users(filtered_users);

		}
	},[modal_type, modal_json])

	const update_current_sharing = async () => {
		var resp = await window.Promisify(window.Module.get_public_view_status_of_branch(""));
		resp = JSON.parse(resp);
		set_current_sharing(resp);
	}

	const set_active_branch_link_sharing = async () => {
		var resp = await window.Promisify(window.Module.set_active_branch_link_sharing(!current_sharing, ""));
		update_current_sharing();
	}

	const copy_link = () => {
		// var copyText = document.getElementById("share_link_input");
		// copyText.select();
		// copyText.setSelectionRange(0, 99999); /* For mobile devices */
		// document.execCommand("copy");

		// alert.success("Copied to clipboard!");
		try{
			navigator.clipboard.writeText(share_link)
			alert.success("Copied to clipboard!");
		}catch(err){
			console.error("Error in Share Design ", err)
			alert.error("Could not copy link")
		}
	}

	const get_bg_color = (name) => {
		if(name && name.trim()){
			var ct = name.trim()[0].toLowerCase();
			var index = alphabets.indexOf(ct);
			if(index>-1 && index<25){
				return random_colors[index];
			}
		}

		return "rgba(0, 120, 255, 1)";
	}

	const open_invite_modal = () => {
		set_open_invite(true)
	}

	const close_invite_modal = () => {
		set_open_invite(false)
	}

	const submit_values = async(final_users, user_data_not_in_org) => {
		var user_data = []
		for (var i = 0; i < final_users.length; i++) {
			user_data.push({
				user_id: final_users[i].id,
				role: final_users[i].role
			});
		}

		await window.Promisify(window.Module[modal_json.share_branch_onclick](modal_json.branch_id, JSON.stringify({user_data, user_data_not_in_org})))
		handle_ui_response(JSON.stringify({update_modal_json: true}))
	}

	const change_role = (user_id, new_role) => {
		var final_users = _deref(selected_users);
		if(new_role == "remove_access"){
			final_users = final_users.filter(o => o.id != user_id);
		}else{
			if(final_users.find(o => o.id == user_id)){
				final_users.find(o => o.id == user_id).role = new_role;
			}
		}

		console.log(final_users);
		set_selected_users(final_users);
		submit_values(final_users);
	}

	const invite_users_onclick = (new_user_data, user_data_not_in_org) => {
		var final_users = _deref(selected_users);
		var final_users_in_org = _deref(selected_users);

		for (var i = 0; i < new_user_data.length; i++) {
			var user_find = final_users.find(o => o.id == new_user_data[i].id);
			if (user_find) {
				if (user_find.role != new_user_data[i].role) {
					user_find.role = new_user_data[i].role;
				}
			} else {
				final_users.push(new_user_data[i]);
			}

			user_find = final_users_in_org.find(o => o.id == new_user_data[i].id);
			if (user_find) {
				if (user_find.role != new_user_data[i].role) {
					user_find.role = new_user_data[i].role;
				}
			} else {
				final_users_in_org.push(new_user_data[i]);
			}
		}

		for (var i = 0; i < user_data_not_in_org.length; i++) {
			final_users.push(user_data_not_in_org[i]);
		}

		console.log("final users", final_users);
		set_selected_users(final_users);
		submit_values(final_users_in_org, user_data_not_in_org);
		close_invite_modal()
	}

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={modal_type=="share_branch_modal_new"}>
			<Share_invite_modal open={open_invite} handle_close={close_invite_modal} users={users} invite_users_onclick={invite_users_onclick} />
			{/* <Card className="bg-secondary shadow border-0"> */}
				{/* <CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Share Design{modal_json&&modal_json.branch_name?("(" + modal_json.branch_name + ")"):""}</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader> */}
				<IModalHeader toggle={handle_close}>Share Design</IModalHeader>
				<IModalBody>
					<div style={{backgroundColor:'white', borderRadius: '10px', padding:'12px'}}>
						<div className='flex_property justify-content-between mb-3'>
							<div>
								Design sharing (common view access)
							</div>
							<div className='flex_property'>
								<div style={{ fontSize: '12px' }} className='mr-3'>
									{current_sharing ? 'Enabled' : 'Disabled'}
								</div>
								<Switch
									onChange={set_active_branch_link_sharing}
									checked={current_sharing}
									uncheckedIcon={false}
									checkedIcon={false}
									offColor='#e6e6e6'
									offHandleColor='#808080'
									onColor='#00924f'
									onfHandleColor='#b3d1ff'
									height={15}
									width={30}
								/>
							</div>
						</div>
						{
							current_sharing ? (
								<div className='flex_property'>
									<Input style={{ fontSize: '12px' }} id="share_link_input" value={share_link} readOnly="true" type="text"></Input>
									<img style={{cursor:'pointer'}} className='ml-3' src='/resources/icons/copy.svg' height={'24px'} onClick={copy_link}/>
								</div>
							) : ''
						}
					</div>
					<hr />
					<div className='flex_property justify-content-between'>
						<div className='flex_property' style={{cursor:'pointer'}} onClick={() => {set_share_collapse_open(!share_collapse_open)}}>
							<div className='mr-3'>{t(`People with Access`)}</div>
							<i className={share_collapse_open?'fa fa-caret-up':'fa fa-caret-down'}/>
						</div>
						<Button onClick={open_invite_modal} className='primary_button_default' type="button">Invite</Button>
					</div>
					<Collapse isOpen={share_collapse_open} className="py-3" style={{ width: "100%"}}>
						<div style={{ borderRadius: 10, backgroundColor: COLORS.white, maxHeight: '400px', overflow:'auto'  }} className="p-3">
							{selected_users.map(o => (
								// <Row style={{marginBottom:5}}>
								// 	<Col xs={2} className="align_center" style={{padding:0}}>
								// 		<div style={{borderRadius:'50%', height:'28px', width:'28px', margin:'2px 0px', color:'white', backgroundColor:get_bg_color(o.label), border:'1px solid white', justifyContent:'center', display:'flex', alignItems:'center',marginLeft:'8px',marginRight:'8px', textTransform:'uppercase', fontWeight: 500}}>{o.label && o.label.trim() ? o.label.trim()[0] : ':)'}</div>
								// 	</Col>
								// 	<Col xs={6} className="align_center" style={{padding:0}}>
								// 		{o.label}
								// 	</Col>
								// 	<Col xs={4} className="align_center" style={{padding:0,paddingRight:5}}>
								// <Input value={o.role} onChange={(e) => {change_role(o.id, e.target.value)}}  style={{padding:'5px'}}type="select">
								// 	<option value={"read"}>Can View</option>
								// 	<option value={"write"}>Can Edit</option>
								// 	<option value={"remove_access"} style={{color:'red'}}>Remove Access</option>
								// </Input>
								// 	</Col>
								// </Row>
								<div className='flex_property justify-content-between my-2'>
									<div className='flex_property lines1_elipsis' style={{flex: 1}}>
										<div style={{ borderRadius: '50%', height: '32px', width: '32px', color: 'white', backgroundColor: get_bg_color(o.label), border: '1px solid white', textTransform: 'uppercase', fontWeight: 500 }} className='flex_center mr-3'>{o.label && o.label.trim() ? o.label.trim()[0] : ':)'}</div>
										<div className='mr-3'>{o.label}</div>
									</div>
									<Input value={o.role} onChange={(e) => { change_role(o.id, e.target.value) }} style={{ ...styles_modals.input_styles, width:'fit-content', padding:'4px' }} type="select">
										<option value={"read"}>Can View</option>
										<option value={"write"}>Can Edit</option>
										<option value={"remove_access"} style={{ color: 'red' }}>Remove Access</option>
									</Input>
								</div>
							))}
							{ }
						</div>
					</Collapse>
				</IModalBody>
				{/* <CardBody style = {{backgroundColor: COLORS.gray3 }} className="px-lg-4 py-lg-4"> */}
					{/* <Card style={{borderRadius:10}}> */}
						{/* <CardBody style = {{borderRadius:10,backgroundColor: COLORS.white }} className="px-lg-3 py-lg-3">
							<Row style={{marginBottom:'5px'}}>
								<Col xs={9} style={{fontSize:'14px'}}>
									Design sharing (common view access)
								</Col>  
								<Col xs={2} className="align_center">
									<div style={{fontSize:'11px'}}>
										{current_sharing?'Enabled':'Disabled'}
									</div>
								</Col>  
								<Col xs={1} className="align_center" style={{padding:0}}>									
									<Switch 
										onChange={set_active_branch_link_sharing} 
										checked={current_sharing}
										uncheckedIcon={false}
										checkedIcon={false}
										offColor='#e6e6e6'
										offHandleColor='#808080'
										onColor='#00924f'
										onfHandleColor='#b3d1ff'
										height={15}
										width={30}	
									/>
								</Col>
							</Row>
							{current_sharing?(<Row>
								<Col xs={11} >
									<Input style={{fontSize:'12px'}} id="share_link_input" value={share_link} readOnly="true" type="text"></Input>
								</Col>  
								<Col xs={1} className="align_center" style={{padding:0}}>
									<span><i style={{cursor:'pointer',fontSize:'20px'}} onClick={copy_link} className="fa fa-clipboard"></i></span>
								</Col>
							</Row>):''}
						</CardBody> */}
					{/* </Card> */}
					{/* <hr /> */}
					{/* <Row>
						<Col xs={9} style={{cursor:'pointer',fontSize:'12px'}} onClick={() => {set_share_collapse_open(!share_collapse_open)}}>
							People with access <span><i className={share_collapse_open?'fa fa-caret-up':'fa fa-caret-down'}/></span>
						</Col>
						<Col xs={3}>
							<Button onClick={open_invite_modal} className='white_button' type="button" style={{backgroundImage:"unset",backgroundColor:"white",border:"solid 1px " + COLORS.gray5}}>Invite</Button>
						</Col>
						<Collapse isOpen={share_collapse_open} className="px-lg-3 py-lg-3" style={{width:"100%"}}>
							<Card style={{borderRadius:10}}>
								<CardBody style = {{borderRadius:10,backgroundColor: COLORS.white }} className="px-lg-3 py-lg-3">
									{selected_users.map(o => (
										<Row style={{marginBottom:5}}>
											<Col xs={2} className="align_center" style={{padding:0}}>
												<div style={{borderRadius:'50%', height:'28px', width:'28px', margin:'2px 0px', color:'white', backgroundColor:get_bg_color(o.label), border:'1px solid white', justifyContent:'center', display:'flex', alignItems:'center',marginLeft:'8px',marginRight:'8px', textTransform:'uppercase', fontWeight: 500}}>{o.label && o.label.trim() ? o.label.trim()[0] : ':)'}</div>
											</Col>
											<Col xs={6} className="align_center" style={{padding:0}}>
												{o.label}
											</Col>
											<Col xs={4} className="align_center" style={{padding:0,paddingRight:5}}>
												<Input value={o.role} onChange={(e) => {change_role(o.id, e.target.value)}}  style={{padding:'5px'}}type="select">
													<option value={"read"}>Can View</option>
													<option value={"write"}>Can Edit</option>
													<option value={"remove_access"} style={{color:'red'}}>Remove Access</option>
												</Input>
											</Col>
										</Row>
									))}
									{}
								</CardBody>
							</Card>
						</Collapse>
					</Row> */}
				{/* </CardBody> */}
			{/* </Card> */}
		</Modal>
	)  

}


const Remote_View = ({  org_key, modal_type, handle_close,  modal_json }) => {
	const alert = useAlert();
	const [ share_link, set_share_link ] = useState('');
	const [ current_sharing, set_current_sharing] = useState(false);

	useEffect(() => {
		if(modal_type == "remote_view" && modal_json && modal_json.share_link){
			var new_share_link = modal_json.share_link;
			if(org_key){
				var split = modal_json.share_link.split("share");
				split[0] += org_key + "/share"
				new_share_link = split[0] + split[1];
			}

			set_share_link(new_share_link);
			update_current_sharing();
		}
	},[modal_type, modal_json])

	const update_current_sharing = async () => {
		var resp = await window.Promisify(window.Module.get_public_view_status_of_branch(""));
		resp = JSON.parse(resp);
		set_current_sharing(resp);
	}

	const set_active_branch_link_sharing = async () => {
		var resp = await window.Promisify(window.Module.set_active_branch_link_sharing(!current_sharing, ""));
		update_current_sharing();
	}

	const copy_link = () => {
		var copyText = document.getElementById("share_link_input");
		copyText.select();
		copyText.setSelectionRange(0, 99999); /* For mobile devices */
		document.execCommand("copy");

		alert.success("Copied to clipboard!");
	}

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={modal_type=="remote_view"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Remote View Link</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white }} className="px-lg-5 py-lg-5">
					<Row>
						<Col xs={10}>
							{current_sharing?'Disable Link Sharing':'Enable Link Sharing'}
						</Col>  
						<Col xs={2}>
						<Switch 
							onChange={set_active_branch_link_sharing} 
							checked={current_sharing}
							uncheckedIcon={false}
							checkedIcon={false}
							offColor='#e6e6e6'
							offHandleColor='#808080'
							onColor='#0066ff'
							onfHandleColor='#b3d1ff'
							height={15}
							width={30}	
						/>
						</Col>
					</Row>
					{current_sharing?(<Row>
						<Col xs={10}>
							<Input id="share_link_input" value={share_link} readOnly="true" type="text"></Input>
						</Col>  
						<Col xs={2}>
							<span><i style={{cursor:'pointer'}} onClick={copy_link} className="fa fa-clipboard" width = '40px'></i></span>
						</Col>
					</Row>):''}
				</CardBody>
			</Card>
		</Modal>
	)    
}

var presentation_viewport_interface = {
	top : 25,
	left : 25,
	width : 50,
	height : 50,
	initialized: false,
	controller: {
		mouseDown: false,
		hovered_line: null
	}
}

const Add_to_presentation_modal = ({  modal_type, handle_close,  modal_json, update_modal_json }) => {
	useEffect(() => {
		if(modal_type == "pd_modal"){
			initialize_presentation_viewport_interface();
		}
	},[modal_type]);


	const apply_pd_modal = () => {
		window.Module.set_presentation_viewport_to_add(presentation_viewport_interface.left, presentation_viewport_interface.top, presentation_viewport_interface.width, presentation_viewport_interface.height);
		update_modal_json();		
	}
	
	const utils_closestPointOnLineSegment = (x, y, x1, y1, x2, y2) => {
		// thanks, http://stackoverflow.com/a/6853926
		var A = x - x1;
		var B = y - y1;
		var C = x2 - x1;
		var D = y2 - y1;
	
		var dot = A * C + B * D;
		var len_sq = C * C + D * D;
		var param = dot / len_sq;
	
		var xx, yy;
	
		if (param < 0 || (x1 == x2 && y1 == y2)) {
			xx = x1;
			yy = y1;
		} else if (param > 1) {
			xx = x2;
			yy = y2;
		} else {
			xx = x1 + param * C;
			yy = y1 + param * D;
		}
	
		return {
			x: xx,
			y: yy
		}
	}

	const utils_pointDistanceFromLineSegment = ( x, y, x1, y1, x2, y2 ) => {

		var point = utils_closestPointOnLineSegment(x, y, x1, y1, x2, y2);
		var dx = x - point.x;
		var dy = y - point.y;
		return Math.sqrt(dx * dx + dy * dy);
	}
	
	const initialize_presentation_viewport_interface = () => {
		
		if(presentation_viewport_interface.initialized){
			return
		}
	
		document.getElementById('presentation_viewport_outer').addEventListener('mouseup',function(event){
			
			presentation_viewport_interface.controller.mouseDown = false
		})
	
		document.getElementById('presentation_viewport_outer').addEventListener('mousedown',function(event){
			
			presentation_viewport_interface.controller.mouseDown = true
		})
		
		document.getElementById('presentation_viewport_outer').addEventListener('mousemove',function(event){
			
			var x,y;
			if(event.type == "touchmove"){
				x = event.touches[0].pageX;
				y = event.touches[0].pageY;
			}else{
				x = event.pageX;
				y = event.pageY;
			}

			var relative_mouse_x = (x - document.getElementById('presentation_viewport_outer').getBoundingClientRect().left)
			var relative_mouse_y = (y - document.getElementById('presentation_viewport_outer').getBoundingClientRect().top)
	
			var width = document.getElementById('presentation_viewport_outer').clientWidth;
			var height = document.getElementById('presentation_viewport_outer').clientHeight;
	
			var percent_x = (relative_mouse_x/width)*100
			var percent_y = (relative_mouse_y/height)*100
	
			var viewport = presentation_viewport_interface
	
			var top_line = [{x:viewport.left,y:viewport.top},{x:(viewport.left)+(viewport.width),y:viewport.top}]
			var right_line = [{x:(viewport.left)+(viewport.width),y:(viewport.top)+(viewport.height)},{x:(viewport.left)+(viewport.width),y:viewport.top}]
			var bottom_line = [{x:(viewport.left)+(viewport.width),y:(viewport.top)+(viewport.height)},{x:viewport.left,y:(viewport.top)+(viewport.height)}]
			var left_line = [{x:viewport.left,y:viewport.top},{x:viewport.left,y:(viewport.top)+(viewport.height)}]
	
			var hovered_line ='';
	
			if(!viewport.controller.mouseDown){
				if(utils_pointDistanceFromLineSegment(percent_x,percent_y,top_line[0].x,top_line[0].y,top_line[1].x,top_line[1].y)<5){
					hovered_line = 'top'
				}else if(utils_pointDistanceFromLineSegment(percent_x,percent_y,right_line[0].x,right_line[0].y,right_line[1].x,right_line[1].y)<5){
					hovered_line = 'right'
				}else if(utils_pointDistanceFromLineSegment(percent_x,percent_y,bottom_line[0].x,bottom_line[0].y,bottom_line[1].x,bottom_line[1].y)<5){
					hovered_line = 'bottom'
				}else if(utils_pointDistanceFromLineSegment(percent_x,percent_y,left_line[0].x,left_line[0].y,left_line[1].x,left_line[1].y)<5){
					hovered_line = 'left'
				}
	
				viewport.controller.hovered_line = hovered_line;
			}else{
				hovered_line = viewport.controller.hovered_line
			}
	
			if(hovered_line == 'top'){
				document.getElementById('presentation_viewport').style.borderTop = '2px dotted #59f9e7';
			}else{
				document.getElementById('presentation_viewport').style.borderTop = '2px dotted black';
			}
	
			if(hovered_line == 'right'){
				document.getElementById('presentation_viewport').style.borderRight = '2px dotted #59f9e7';
			}else{
				document.getElementById('presentation_viewport').style.borderRight = '2px dotted black';
			}
	
			if(hovered_line == 'bottom'){
				document.getElementById('presentation_viewport').style.borderBottom = '2px dotted #59f9e7';
			}else{
				document.getElementById('presentation_viewport').style.borderBottom = '2px dotted black';
			}
	
			if(hovered_line == 'left'){
				document.getElementById('presentation_viewport').style.borderLeft = '2px dotted #59f9e7';
			}else{
				document.getElementById('presentation_viewport').style.borderLeft = '2px dotted black';
			}
	
			if(hovered_line == 'top'){
				document.getElementById('presentation_viewport_outer').style.cursor = 'n-resize'
			}else if(hovered_line == 'right'){
				document.getElementById('presentation_viewport_outer').style.cursor = 'e-resize'
			}else if(hovered_line == 'bottom'){
				document.getElementById('presentation_viewport_outer').style.cursor = 's-resize'
			}else if(hovered_line == 'left'){
				document.getElementById('presentation_viewport_outer').style.cursor = 'w-resize'
			}
	
			if(viewport.controller.mouseDown && hovered_line){
				if(percent_x<3){
					percent_x = 0
				}
	
				if(percent_x>97){
					percent_x = 100
				}
	
				if(percent_y<3){
					percent_y = 0
				}
	
				if(percent_y>97){
					percent_y = 100
				}
	
				if(hovered_line == 'top'){
					var diff = viewport.top - percent_y
					viewport.top = percent_y
					viewport.height += diff
				}else if(hovered_line == 'right'){
					var diff = percent_x - (viewport.left + viewport.width )
					viewport.width += diff
				}else if(hovered_line == 'bottom'){
					var diff = percent_y - (viewport.top + viewport.height )
					viewport.height += diff
				}else if(hovered_line == 'left'){
					var diff = viewport.left - percent_x
					viewport.left = percent_x
					viewport.width += diff
				}
	
				if(viewport.width < 10){
					viewport.width = 10
				}
	
				if(viewport.height < 10){
					viewport.height = 10
				}
	
				document.getElementById('presentation_viewport').style.top = viewport.top+'%';
				document.getElementById('presentation_viewport').style.left = viewport.left+'%';
				document.getElementById('presentation_viewport').style.width = viewport.width+'%';
				document.getElementById('presentation_viewport').style.height = viewport.height+'%';
			}
		})
	
		presentation_viewport_interface.initialized = true;
		document.getElementById('presentation_viewport').style.top = 25+'%';
		document.getElementById('presentation_viewport').style.left = 25+'%';
		document.getElementById('presentation_viewport').style.width = 50+'%';
		document.getElementById('presentation_viewport').style.height = 50+'%';
	}


	return(
		<div style= {{display: modal_type=='pd_modal'?'block':'none', position:'absolute',top:'92px', left:'71px', height:'calc(100% - 120px)', width:'calc(100% - 71px'}}>
			<div id="presentation_viewport_outer" style={{position: 'absolute',left: 0,top: 0,height: '100%',width: '100%',zIndex: '1000',}}>
				<div className='btn-group' style={{borderRadius: '5px',position: 'absolute',top: '80%',left: 'calc(50% - 80px)',zIndex: '10000',border:'solid 1px black'}}>
					<button onClick={apply_pd_modal} className='btn btn-sm btn-default' style={{fontWeight: 'bold',color:'#3593d2',backgroundColor:'white',borderRight: 'solid 1px black'}}>Apply</button>
					<button onClick={handle_close} className='btn btn-sm btn-default' style={{fontWeight: 'bold',color:'black',backgroundColor:'white',}}>Cancel</button>
				</div>
				<div id="presentation_viewport" style={{background: 'transparent',position: 'absolute',boxShadow: '0 0 0 99999px rgba(0, 0, 0, 0.2)',borderTop: '2px dotted black',borderRight: '2px dotted black',borderBottom: ' 2px dotted black',borderLeft: '2px dotted black',}}>
					
				</div>
			</div>
		</div>
	)    
}

const Layer_delete_modal = ({open, update_modal_json, modal_json}) => {

	const shift_and_delete = () => {
		if(modal_json && modal_json.delete_shift){
			window.Module.reassign_and_delete_render_layer(modal_json.delete_shift.layer_id,document.getElementById("delete_shift_select").value);
			update_modal_json();
		}
	}

	const close_modal = () => {
		if(modal_json && modal_json.delete_shift){
			window.Module.toolbar_router("layersets_modal");
			update_modal_json();
		}
	}


	return(
		<Modal className="modal-dialog-centered" size="sm" isOpen={open} toggle={close_modal}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h6 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Confirm</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={close_modal}>×</span></span></h6>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white, padding:'20px' }} >
					<Row>
						<Col style={{marginBottom:'15px'}}>
							{modal_json && modal_json.delete_shift?("* "+ modal_json.delete_shift.number_of_matches +" elements are attached to this layer. Please select a replacement layer for them."):''}	
						</Col>
					</Row>
					<Row>
						<Col>
							<Input id="delete_shift_select" type='select'>
							{
								modal_json && modal_json.delete_shift && modal_json.render_layers.map((entry, idx) => {
									return modal_json.delete_shift.layer_id==entry.id?"":(
										<option key={idx} value={entry.id}>{entry.name}</option>
									)
								})
							}
							</Input> 
						</Col>
					</Row>
					<Row>
						<Col style={{textAlign:'center', marginTop:'15px'}}>
							<Button onClick={shift_and_delete} style={{backgroundColor:'COLORS.mainpage_locale',}} >Replace and Delete</Button>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Modal>
	);
}

const Create_Layer_modal = ({modal_type, modal_json, modal_mode, handle_close, update_modal_json}) => {
	const alert = useAlert();

	const [new_layer_name, set_new_layer_name] = useState('');
	const [new_layer_line_color, set_new_layer_line_color] = useState('#000000');
	const [new_layer_line_type, set_new_layer_line_type] = useState('solid');
	const [new_layer_line_thickness, set_new_layer_line_thickness] = useState(5);
	const [new_line_thickness_type, set_new_line_thickness_type] = useState('mm');

	const close_add_layer_modal = () => {
		handle_close()
		set_new_layer_name('')
	}

	const submit_onclick = () => {
		if(new_layer_name.length){
			window.Module.add_new_layer_onclick(new_layer_name, modal_mode, new_layer_line_color, new_layer_line_thickness, new_line_thickness_type, new_layer_line_type, modal_json && modal_json.active_layerset && modal_json.active_layerset.view_id ? modal_json.active_layerset.view_id : "");
			if(update_modal_json)update_modal_json();
			close_add_layer_modal();
		}else{
			alert.error("Name cannot be empty!");
		}
	}

	// useEffect(() => {
	// 	// console.log("modal_mode", modal_mode);
	// }, [modal_mode]);

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=='create_layer_modal'} toggle={close_add_layer_modal} style={{marginLeft:modal_mode=='panel'?'280px':''}}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h6 style={{display:'flex', justifyContent:'space-between', cursor:'pointer', alignItems:'center', margin:'0px'}}><span>Add New Layer</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={close_add_layer_modal}>×</span></span></h6>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.gray2, padding:'20px 40px', fontSize:'14px'}} >
					<Row>
						<Col>
							<span>Enter the new Layer name</span>
						</Col>
					</Row>
					<Row style={{padding:'8px 0px'}}>
						<Col>
							<Input placeholder='Layer name' type='text' value={new_layer_name} style={{fontSize:'14px'}} onChange={(e) => set_new_layer_name(e.target.value)}/>
						</Col>
					</Row>
					<Row style={{padding:'8px 0px'}}>
						<Col style={{display:'flex'}}>
							<div style={{flex:5}}>Line Color <input type='color' defaultValue={new_layer_line_color} onBlur={(e) => set_new_layer_line_color(e.target.value)} style={{width:'80px', height:'24px', marginLeft:'8px', cursor:'pointer'}}/></div>
							<div style={{flex:5}}>Line Type 
								<select defaultValue={new_layer_line_type} onBlur={(e) => set_new_layer_line_type(e.target.value)} style={{ width: 'fit-content', height: '24px', marginLeft:'8px', cursor: 'pointer', backgroundColor: 'white', outline: 'none' }}>
									<option value={'solid'}>Solid</option>
									<option value={'dotted'}>Dotted</option>
									<option value={'dashed'}>Dashed</option>
								</select></div>
							<div style={{flex:5, display:'flex', alignItems:'center'}}>
								Line Thickness <input type='number' defaultValue={new_layer_line_thickness} onBlur={(e) => set_new_layer_line_thickness(e.target.value)} onKeyDown={(e) => {if(e.key==='Enter'){set_new_layer_line_thickness(e.target.value); e.target.blur()}}} style={{width:'80px', height:'24px', fontSize:'12px', marginLeft:'8px'}}/>
								<select defaultValue={new_line_thickness_type} onBlur={(e) => set_new_line_thickness_type(e.target.value)} style={{ ...styles_modals.input_styles, width: 'fit-content', height: '24px', cursor: 'pointer', backgroundColor: 'white', borderLeft: 'none', outline: 'none' }}>
									<option value={'mm'}>mm</option>
									<option value={'pt'}>pt</option>
								</select>
							</div>
						</Col>
					</Row>
					<Row style={{color:'#A7A8B2', fontSize:'12px'}}>
						<Col>
							Layer will be created with visibility on
						</Col>
					</Row>
				</CardBody>
				<IModalFooter>
					<Button onClick={submit_onclick} className='primary_button_default'>Add Layer</Button>
				</IModalFooter>
			</Card>
		</Modal>
	);
}

const Create_Copy_LayerSet_modal = ({modal_type, modal_mode, handle_close, layerset_mode, given_layerset, update_modal_json}) => {
	const alert = useAlert();

	const [new_layer_set_name, set_new_layer_set_name] = useState('');
	
	const onclick_handleclose = () => {
		handle_close()
		set_new_layer_set_name('')
	}
	useEffect(() => {
		// console.log('modal_mode changed', modal_mode)
	}, [modal_mode]);

	const submit_onclick = () => {
		if(new_layer_set_name.length){
			if(layerset_mode == 'create'){
				window.Module.add_new_layerset_onclick(new_layer_set_name, false, '', modal_mode)
				// console.log('modal_mode changed', new_layer_set_name, false, '', modal_mode)
			}else if(layerset_mode == 'copy'){
				window.Module.add_new_layerset_onclick(new_layer_set_name, true, given_layerset.id, modal_mode)
			}else if(layerset_mode == 'rename'){
				window.Module.rename_layerset_onclick(given_layerset.id,new_layer_set_name,modal_mode)
			}

			if(update_modal_json)update_modal_json();
			onclick_handleclose()
		}else{
			alert.error("Name cannot be empty!");
		}
	}

	useEffect(() => {
		if(modal_type=='create_layerset_modal' && layerset_mode == 'copy' && given_layerset){
			set_new_layer_set_name('Copy of '+given_layerset.name)
		}else if(modal_type=='create_layerset_modal' && layerset_mode == 'rename' && given_layerset){
			set_new_layer_set_name(given_layerset.name)
		}
	}, [layerset_mode, modal_type, given_layerset]);

	useEffect(() => {
		// console.log('given_layerset', given_layerset, layerset_mode, given_layerset?true:false)
	}, [given_layerset]);

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={modal_type=='create_layerset_modal'} toggle={onclick_handleclose} style={{marginLeft:modal_mode=='panel'?'280px':''}}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h6 style={{display:'flex', justifyContent:'space-between', cursor:'pointer', alignItems:'center', margin:'0px'}}><span>{layerset_mode == 'create'?'Creating New Layer Set':(layerset_mode=='copy'?('Copy of '+ given_layerset?given_layerset.name:''):(layerset_mode=='rename'?'Rename Layerset':''))}</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={onclick_handleclose}>×</span></span></h6>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.gray2, padding:'20px', fontSize:'14px', overflow:'auto'}} >
					<Row>
						<Col>
							<span>Enter the new Layer set name</span>
						</Col>
					</Row>
					<Row style={{padding:'8px 0px'}}>
						<Col>
							<Input placeholder='Layer set name' type='text' value={new_layer_set_name} style={{fontSize:'14px', border: '1px solid ' + COLORS.gray9}} onChange={(e) => set_new_layer_set_name(e.target.value)}/>
						</Col>
					</Row>
					<Row style={{color:'#A7A8B2', fontSize:'12px'}}>
						<Col>
							{layerset_mode == 'create' ? 'Duplicate layer set will be created' :''}
						</Col>
					</Row>
				</CardBody>
				<CardFooter className='global_modal_footer' style={{backgroundColor:'white', display:'flex', justifyContent:'flex-end'}}>
					<Button onClick={submit_onclick} className='blue_button'>{layerset_mode == 'create' ?"Create":(layerset_mode=='copy'?"Copy":'Rename')}</Button>
				</CardFooter>
			</Card>
		</Modal>
	);
}

const View_all_layers_modal_row = ({single_layer, modal_json, update_layer_default_properties, get_add_layersets_from_templates, update_modal_json, layers_type, idx}) => {
	const [layer_color, set_layer_color] = useState('#000000');
	const [layer_thickness, set_layer_thickness] = useState(1)

	useEffect(() => {
		set_layer_color(single_layer.default_line_color)
	}, [])

	useEffect(() => {
		set_layer_thickness(single_layer.default_line_thickness)
	}, [])

	return (
		<tr className='tr_style'>
			<td className='td_style flex_property' style={{flex:'1'}}><img style={{height:'16px'}} src={single_layer.default_visibility && modal_json ? modal_json.show_content : modal_json.hide_content} onClick={() => update_layer_default_properties(single_layer.id, single_layer.default_line_color, single_layer.default_line_thickness, single_layer.default_line_thickness_type, !(single_layer.default_visibility), single_layer.default_line_type)}/></td>
			{/* <td className='td_style flex_property' style={{flex:'1'}}></td> */}
			<td className='td_style flex_property' style={{flex:'5'}}>
			{
				layers_type == 'user_created' ?
				<div className='createcard_input_container'><input style={{border: 'none', outline: 'none'}} type='text' defaultValue={single_layer.name} onClick={(e) => {e.target.focus(); e.target.setSelectionRange(0, e.target.value.length);}} onBlur={(e) => {window.Module.rename_layer_onclick(single_layer.id, e.target.value); get_add_layersets_from_templates(); update_modal_json()}} onKeyDown={(e) => {if(e.key === 'Enter'){window.Module.rename_layer_onclick(single_layer.id, e.target.value); get_add_layersets_from_templates(); update_modal_json();e.target.blur()}}} /></div> :<span>{single_layer.name}</span>
			}
			</td>
			<td className='td_style flex_property' style={{flex:'6'}}>
				{/* <div style={{height:'20px', width:'20px', border:'1px solid', cursor:'pointer', backgroundColor:single_layer.default_line_color, marginLeft:'8px'}} onClick={() => document.getElementById('color'+idx).click()}></div> */}
				<div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', gap: '5px'}}>
				<input className='color_input_layer' type='color' id={'color'+idx} value={layer_color} onChange={(e) => set_layer_color(e.target.value)} onBlur={(e) => {update_layer_default_properties(single_layer.id, e.target.value, single_layer.default_line_thickness, single_layer.default_line_thickness_type, single_layer.default_visibility, single_layer.default_line_type); update_modal_json()}} style={{cursor:'pointer', border: '1px solid ' + COLORS.gray9}}/>
				<div className='layer_color_item'>{layer_color ? layer_color.toUpperCase() : ''}</div> 
				</div>
			</td>
			<td className='td_style flex_property' style={{flex:'6'}}>
				{/* <div style={{height:'20px', width:'20px', border:'1px solid', cursor:'pointer', backgroundColor:single_layer.default_line_color}} onClick={() => document.getElementById('color'+idx).click()}></div> */}
				<select id={'line_type'+idx} defaultValue={single_layer.default_line_type} onBlur={(e) => {update_layer_default_properties(single_layer.id, single_layer.default_line_color, single_layer.default_line_thickness, single_layer.default_line_thickness_type, single_layer.default_visibility, e.target.value); update_modal_json()}} style={{cursor:'pointer', outline: 'none', background: 'white',  border: 'none', height: '100%', width: '100%'}}>
					<option value={'solid'}>Solid</option>
					<option value={'dotted'}>Dotted</option>
					<option value={'dashed'}>Dashed</option>
				</select>
			</td>
			<td className='td_style flex_property' style={{flex:'6'}}>
				<div style={{display: 'flex', alignItems: 'center', width: '64%', justifyContent: 'space-between'}}>
					<div className='mr-2' style={{ width: '40px', height: `${layer_thickness?layer_thickness:1}px`, minHeight: '1px', maxHeight: '16px', backgroundColor: '#676878' }}></div>
					<input type='number' value={layer_thickness} onChange={(e) => set_layer_thickness(e.target.value)} onBlur={(e) => {update_layer_default_properties(single_layer.id, single_layer.default_line_color, e.target.value, single_layer.default_line_thickness_type, single_layer.default_visibility, single_layer.default_line_type);update_modal_json()}} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}}} style={{height:'26px', width:'45px', border: '1px solid ' + COLORS.gray9, fontSize:'12px', outline: 'none', marginRight: '4px'}}/>
					<select defaultValue={single_layer.default_line_thickness_type ? single_layer.default_line_thickness_type : 'mm'} onBlur={(e) => {update_layer_default_properties(single_layer.id, single_layer.default_line_color, single_layer.default_line_thickness, e.target.value, single_layer.default_visibility, single_layer.default_line_type);update_modal_json()}} onChange={(e) => e.target.blur()} style={{ ...styles_modals.input_styles, border: '1px solid ' + COLORS.gray9, width: 'fit-content', height: '24px', cursor: 'pointer', backgroundColor: 'white', borderRight:'none', borderTop: 'none', borderBottom: 'none', marginLeft: '5px', paddingLeft: '6px', outline: 'none', background: 'none'}}>
						<option value={'mm'}>mm</option>
						<option value={'pt'}>pt</option>
					</select>
				</div>
			</td>
			{
				layers_type == 'user_created' ? 
				<td style={{flex:'2'}}>
					{/* <td className='td_style flex_property' style={{flex:'1'}}><i className='fa fa-pencil'/></td>
					<td className='td_style flex_property' style={{flex:'1'}}><i className='fa fa-trash'/></td> */}
					{/* <img src='/resources/nav_icons_new/edit.svg' style={{flex:'1', height:'16px', width:'16px', cursor:'pointer'}}/> */}
					<img src='/resources/nav_icons_new/delete 16px.svg' style={{height:'16px', width:'16px', cursor:'pointer', marginLeft:'8px'}} onClick={() => {window.Module.delete_layer_onclick(single_layer.id); get_add_layersets_from_templates()}}/>
				</td>:''
			}
		</tr>
	)
	
}

const View_all_layers_modal = ({open, handle_close, all_layers, modal_json, update_layer_default_properties, get_add_layersets_from_templates, update_modal_json}) => {

	const [	tabs , set_tabs ] = useState(1)

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	useEffect(() => {
		// console.log('system and user layers 3', all_layers)
	}, [all_layers]);

	const Layers_table = ({layers_type, layers_array}) => {

		useEffect(() => {
			// console.log('layers_type', layers_type)
		}, [layers_type]);
		return(
			<table style={{height:'100%', overflow:'auto'}} className="text-left flex_property table_style">
				<thead style={{width:"100%"}}>
					<tr className='tr_style'>
						<th className='th_style flex_property' style={{flex:'1'}}><img height='16px' src={modal_json ? modal_json.show_content : '/resources/nav_icons_new/view layer.svg'} /></th>
						{/* <th className='th_style flex_property' style={{flex:'1'}}></th> */}
						<th className='th_style flex_property' style={{flex:'5'}}>Layers</th>
						<th className='th_style flex_property' style={{flex:'6'}}>Color</th>
						<th className='th_style flex_property' style={{flex:'6'}}>Line type</th>
						<th className='th_style flex_property' style={{flex:'6'}}>Line thickness</th>
						{
							layers_type == 'user_created' ? <th className='th_style flex_property' style={{flex:'2'}}>Actions</th>:""
						}
					</tr>
				</thead>
				<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)"}}>
				{
					layers_array && layers_array.length ? layers_array.map((single_layer, idx) => (
						<View_all_layers_modal_row single_layer={single_layer} modal_json={modal_json} update_modal_json={update_modal_json} update_layer_default_properties={update_layer_default_properties} get_add_layersets_from_templates={get_add_layersets_from_templates} layers_type={layers_type} idx={idx} />
					)):""
				}
				</tbody>
			</table>
		)
	}

	return (
		<Modal className="modal-dialog-centered" size="xl" isOpen={open}>
			<CardHeader className='global_modal_header'>
				<span>Default Layer set</span>
				<i className='fa fa-times' onClick={handle_close} style={{cursor:'pointer'}}/>
			</CardHeader>
			<CardBody style={{height:'420px', overflow:'auto'}}>
				<Nav 
				style={{display:'flex', alignItems:'center', fontSize:'12px'}}
				className="flex-md-row"
				id="tabs-icons-text" 
				pills
				role="tablist"
				>
					<NavItem>
						<NavLink
							aria-selected={tabs === 1}
							style = {{}}
							className={classnames("mb-sm-3 mb-md-0", {
								active: tabs === 1
							})}
							onClick={e => toggleNavs(e, 1)}
							href="#pablo"
							role="tab"
						>
							<span>{'System Layers'}</span>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							aria-selected={tabs === 2}
							className={classnames("mb-sm-3 mb-md-0", {
								active: tabs === 2
							})}
							onClick={e => toggleNavs(e, 2)}
							href="#pablo"
							role="tab"
						>
							<span>{'User Created'}</span>
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={"tabs"+tabs}>
					<TabPane tabId="tabs1" style={{fontSize:'12px', padding:'8px 0px'}}>
						<div className='flex_center' style={{justifyContent:'space-between', backgroundColor:COLORS.gray3, height:'32px', padding:'0px 8px'}}>
							<span>System Layers</span>
							<span style={{color:COLORS.gray5}}>{all_layers && all_layers.system_layers && all_layers.system_layers.length ? all_layers.system_layers.length : '0'} Layers</span>
						</div>
						<Layers_table layers_type='system' layers_array={all_layers ? all_layers.system_layers : []}/>
					</TabPane>
					<TabPane tabId="tabs2" style={{fontSize:'12px', padding:'8px 0px'}}>
						<div className='flex_center' style={{justifyContent:'space-between', backgroundColor:COLORS.gray3, height:'32px', padding:'0px 8px'}}>
							<span>User Created Layers</span>
							<span style={{color:COLORS.gray5}}>{all_layers && all_layers.user_layers && all_layers.user_layers.length ? all_layers.user_layers.length : '0'} Layers</span>
						</div>
						<Layers_table layers_type='user_created' layers_array={all_layers ? all_layers.user_layers : []}/>
					</TabPane>
				</TabContent>
			</CardBody>
			<CardFooter className='global_modal_footer'>

			</CardFooter>
		</Modal>
	)
}

const Layer_set_modal_row = ({modal_json, update_modal_json, layer}) => {

	const [layer_color, set_layer_color] = useState('#000000');
	const [layer_thickness, set_layer_thickness] = useState(1)
	const [layer_line_type, set_layer_line_type] = useState('solid');
	const [layer_line_thickness_type, set_layer_line_thickness_type] = useState('mm');
	

	useEffect(() => {
		if(modal_json && modal_json['active_layerset']){
			set_layer_color(modal_json['active_layerset']['layer_line_colors'] && modal_json['active_layerset']['layer_line_colors'][layer.id] ? modal_json['active_layerset']['layer_line_colors'][layer.id] : '#000000')
			set_layer_line_type(modal_json['active_layerset']['layer_line_type'] && modal_json['active_layerset']['layer_line_type'] && modal_json['active_layerset']['layer_line_type'][layer.id] ? modal_json['active_layerset']['layer_line_type'][layer.id] : 'solid')
			set_layer_thickness(modal_json['active_layerset']['layer_line_thickness'] && modal_json['active_layerset']['layer_line_thickness'][layer.id] ? modal_json['active_layerset']['layer_line_thickness'][layer.id] : 0)
			set_layer_line_thickness_type(modal_json['active_layerset']['layer_line_thickness_type'] && modal_json['active_layerset']['layer_line_thickness_type'][layer.id] ? modal_json['active_layerset']['layer_line_thickness_type'][layer.id] : 'mm')
		}
	}, [modal_json])


	const toggle_layer_visibility = (id) => {
		window.Module.toggle_layer_visibility_onclick(id, 'modal', modal_json && modal_json.active_layerset && modal_json.active_layerset.view_id ? modal_json.active_layerset.view_id : "");
		update_modal_json();
	}

	const resetToDefaultLayer = async(id) => {
		try {
			window.Module.reset_default_layers_onclick(id);
			update_modal_json();
		}
		catch(err){
			console.log(err)
		}
	}

	const update_layerset_layer_properties = (layer_id, color, thickness, thickness_type, line_type) => {
		if(modal_json){
			window.Module.update_layerset_layer_properties(layer_id, color, Number(thickness), thickness_type, line_type, "modal", modal_json && modal_json.active_layerset && modal_json.active_layerset.view_id ? modal_json.active_layerset.view_id : "");
			// window.Module.update_layerset_layer_properties(layer_id, color, Number(thickness));
			update_modal_json();
		}
	}

	return (
		<ITableRow className={modal_json && modal_json['active_layerset'] && modal_json['active_layerset']['overridden'] && modal_json['active_layerset']['overridden'][layer.id] ? ' overriden_layer_item' : ' normal_layer_item'}>
			<ITD style={{marginLeft:'8px', cursor:'pointer'}}><img height='16px' onClick={() => {toggle_layer_visibility(layer.id)}} src={modal_json && modal_json['active_layerset'] && modal_json['active_layerset']['visible'] && modal_json['active_layerset']['visible'][layer.id] ? modal_json.show_content: modal_json.hide_content} /> </ITD>
			<ITD> {layer.name}</ITD>
			<ITD>
				{/* <input id={modal_json.active_layerset.id +layer.id+'color'} key={modal_json.active_layerset.id +layer.id} type='color' defaultValue={modal_json && modal_json['active_layerset']['layer_line_colors'][layer.id] ? modal_json['active_layerset']['layer_line_colors'][layer.id] : 'black'} onBlur={(e) => update_layerset_layer_properties(layer.id, e.target.value, modal_json['active_layerset']['layer_line_thickness'][layer.id], modal_json['active_layerset']['layer_line_thickness_type'][layer.id], modal_json['active_layerset']['layer_line_type'][layer.id])} style={{width:'80px', height:'24px', cursor:'pointer', visibility: 'hidden'}}/>  */}
				<div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', gap: '5px'}}>
				<input className='color_input_layer' id={modal_json.active_layerset.id +layer.id+'color'} key={modal_json.active_layerset.id +layer.id} type='color' value={layer_color} onChange={(e) => {set_layer_color(e.target.value); }} onBlur={(e) => {update_layerset_layer_properties(layer.id, e.target.value, modal_json['active_layerset']['layer_line_thickness'][layer.id], modal_json['active_layerset']['layer_line_thickness_type'][layer.id], modal_json['active_layerset']['layer_line_type'][layer.id])}} style={{cursor:'pointer'}}/> 
				<div className='layer_color_item'>{layer_color ? layer_color.toUpperCase() : ''}</div> 
				</div>
			</ITD>
			<ITD style={{}}>
				<Input className='single_ss_hov' key={modal_json.active_layerset.id +layer.id + '_line_type'} type='select' value={layer_line_type} onChange={(e) => {set_layer_line_type(e.target.value); e.target.blur()}} onBlur={(e) => update_layerset_layer_properties(layer.id, modal_json['active_layerset']['layer_line_colors'][layer.id], modal_json['active_layerset']['layer_line_thickness'][layer.id], modal_json['active_layerset']['layer_line_thickness_type'][layer.id], e.target.value)} style={{...styles_modals.input_styles, background: 'none', width:'80px', height:'24px', cursor:'pointer', border: 'none', height: '100%', width: '100%'}}>
					<option value={'solid'}>Solid</option>
					<option value={'dotted'}>Dotted</option>
					<option value={'dashed'}>Dashed</option>
				</Input>
			</ITD>
			<ITD style={{}}>
				<div style={{display: 'flex', alignItems: 'center', width: '61%', justifyContent: 'space-between'}}>
				<div className='mr-2' style={{ width: '40px', height: `${layer_thickness?layer_thickness:1}px`, minHeight: '1px', maxHeight: '16px', backgroundColor: '#676878' }}></div>
				<input
					className='styled-number-input'
					key={modal_json.active_layerset.id + layer.id + "_lt"}
					type='number'
					value={layer_thickness}
					onChange={(e) => set_layer_thickness(e.target.value)}
					onBlur={(e) => { update_layerset_layer_properties(layer.id, modal_json['active_layerset']['layer_line_colors'][layer.id], e.target.value, modal_json['active_layerset']['layer_line_thickness_type'][layer.id], modal_json['active_layerset']['layer_line_type'][layer.id]) }}
					onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } }}
					style={{ width: '45px', height: '26px', fontSize: '12px', outline: 'none', border: '1px solid ' + COLORS.gray9}}
				/>
				<select key={modal_json.active_layerset.id + layer.id} value={layer_line_thickness_type} onChange={(e) => {set_layer_line_thickness_type(e.target.value); e.target.blur()}} onBlur={(e) => update_layerset_layer_properties(layer.id, modal_json['active_layerset']['layer_line_colors'][layer.id], modal_json['active_layerset']['layer_line_thickness'][layer.id], e.target.value, modal_json['active_layerset']['layer_line_type'][layer.id])} style={{ ...styles_modals.input_styles, border: '1px solid ' + COLORS.gray9, width: 'fit-content', height: '24px', cursor: 'pointer', backgroundColor:'white', borderRight:'none', borderTop: 'none', borderBottom: 'none', marginLeft: '5px', paddingLeft: '10px', outline: 'none', background: 'none' }}>
					<option value={'mm'}>mm</option>
					<option value={'pt'}>pt</option>
				</select>
				</div>
			</ITD>
			{/* <ITD className={modal_json && modal_json['active_layerset'] && modal_json['active_layerset']['overridden'] && modal_json['active_layerset']['overridden'][layer.id] ? 'layer_overriden_item' : 'layer_default_item'}>
			<div style={{height: '100%', width: '100%', cursor: modal_json && modal_json['active_layerset'] && modal_json['active_layerset']['overridden'] && modal_json['active_layerset']['overridden'][layer.id] ? 'pointer' : ''}} onClick={modal_json && modal_json['active_layerset'] && modal_json['active_layerset']['overridden'] && modal_json['active_layerset']['overridden'][layer.id] ? (e) => {resetToDefaultLayer(layer.id)} : null}>
				{modal_json && modal_json['active_layerset'] && modal_json['active_layerset']['overridden'] && modal_json['active_layerset']['overridden'][layer.id] ? 'Reset to Default' : 'Same as default layer'}
			</div>
			</ITD> */}
		</ITableRow>
	)
}

const Layer_set_modal = ({  modal_type, update_modal_json, handle_close,  modal_json }) => {
	const alert = useAlert();
	const [ show_add_layer_modal, set_show_add_layer_modal ] = useState(false);
	const [ show_create_layerset_modal, set_show_create_layerset_modal ] = useState(false);
	const [show_layers_in_design, set_show_layers_in_design] = useState(true);
	const [show_layers_not_in_design, set_show_layers_not_in_design] = useState(false);
	const [layer_set_create_copy_mode, set_layer_set_create_copy_mode] = useState('create');
	const [selected_layer_set_for_copy, set_selected_layer_set_for_copy] = useState(null);
	const [all_layers, set_all_layers] = useState();
	const [view_all_layers_modal, set_view_all_layers_modal] = useState(false)
    const {t} = useTranslation()

	const close_show_add_layer_modal = () => {
		set_show_add_layer_modal('');
		update_modal_json();
	}
	
	const open_add_layer_modal = () => {
		set_show_add_layer_modal('create_layer_modal')
	}

	const switch_layer_set = (id) => {
		window.Module.switch_layerset_onclick(id, 'modal');
		update_modal_json();
	}

	const delete_layerset_onclick = (layerset) => {
		if(layerset.id){
			window.Module.delete_layerset_onclick(layerset.id, 'modal');
			update_modal_json();
		}else{
			alert.error("Cannot delete the default layerset");
		}
	}

	const delete_render_layer = (layer) => {
		if(!layer.delete_lock){
			window.Module.delete_render_layer_onclick(layer.id);
			update_modal_json();
		}else{
			alert.error("Cannot delete the default layer");
		}
	}

	const toggle_layer_visibility = (id) => {
		window.Module.toggle_layer_visibility_onclick(id, 'modal', "");
		update_modal_json();
	}

	const rename_layerset = (name) => {
		if(modal_json && modal_json.active_layerset){
			window.Module.rename_layerset_onclick(modal_json.active_layerset.id,name);
			update_modal_json();
		}
	}

	const add_layer_set = (new_layer_set_name, copy, layer_set_id) => {
		if(modal_json ){
			window.Module.add_new_layerset_onclick(new_layer_set_name, copy, layer_set_id, 'modal');
			update_modal_json();
		}
	}

	const update_layerset_layer_properties = (layer_id, color, thickness, thickness_type, line_type) => {
		if(modal_json ){
			window.Module.update_layerset_layer_properties(layer_id, color, Number(thickness), thickness_type, line_type, "modal", "");
			// window.Module.update_layerset_layer_properties(layer_id, color, Number(thickness));
			update_modal_json();
		}
	}

	const update_layer_default_properties = (layer_id, color, thickness, thickness_type, visibility, line_type) => {
		if(modal_json){
			window.Module.update_layer_default_properties(layer_id, color, Number(thickness), String(thickness_type), Boolean(visibility), line_type);
			get_add_layersets_from_templates()
			// update_modal_json()
		}
	}

	const get_add_layersets_from_templates = () => {
		set_all_layers(JSON.parse(window.Module.get_add_layersets_from_templates('edit_layer_properties')));
	}

	return(
		<Modal className="modal-dialog-centered" style={{maxWidth: '80%'}} isOpen={modal_type=="layer_set_modal"}>
			<Create_Layer_modal modal_type={show_add_layer_modal} modal_json={modal_json} modal_mode='modal' handle_close={close_show_add_layer_modal} update_modal_json={update_modal_json}/>
			<Create_Copy_LayerSet_modal modal_type={show_create_layerset_modal} modal_mode='modal' layerset_mode={layer_set_create_copy_mode} handle_close={() => set_show_create_layerset_modal(false)} add_layer_set={add_layer_set} given_layerset={selected_layer_set_for_copy} update_modal_json={update_modal_json}/>
			<Layer_delete_modal open={modal_json && modal_json.delete_shift?true:false} update_modal_json={update_modal_json} modal_json={modal_json} />
			<View_all_layers_modal open={view_all_layers_modal} handle_close={() => set_view_all_layers_modal(false)} all_layers={all_layers} modal_json={modal_json} update_layer_default_properties={update_layer_default_properties} get_add_layersets_from_templates={get_add_layersets_from_templates} update_modal_json={update_modal_json}/> 

			<CardHeader className='global_modal_header'><span>Global Layer Manager</span><i style={{cursor:'pointer'}} className='fa fa-times' onClick={handle_close}/></CardHeader>
			<ModalBody style={{height:'520px'}}>
				<div style={{width:'100%', height:'100%', display:'flex', backgroundColor:COLORS.gray2}}>
					{/* <div style={{display:'flex', flex:1, flexDirection:'column', height:'95%', backgroundColor:'white', marginLeft:'-16px', position:'relative'}}>
						<div style={{display:'flex', justifyContent:'space-between', height:'40px', alignItems:'center', fontSize:'14px', padding:'0px 16px', backgroundColor:COLORS.gray3}}>
							<span>Layer Sets</span>
							<span className='special_text' onClick={() => {set_show_create_layerset_modal('create_layerset_modal'); set_layer_set_create_copy_mode('create')}}>New Layer Set</span>
						</div>
						<div style={{height: '480px', overflow:'auto'}}>
							{
								modal_json  .map((layer_set,idx) => (
									<div className={layer_set.id == modal_json.active_layerset.id ? 'layerset_row active_layerset' : 'layerset_row'} style={{display:'flex', justifyContent:'space-between', height:'36px', alignItems:'center', fontSize:'12px', cursor:'pointer'}} onClick={() => switch_layer_set(layer_set.id)}>
										<div title={layer_set.name}>{layer_set.name.length <= 30 ? layer_set.name : layer_set.name.substring(0,30)+'...'}</div>
										<div className='invisible_icon'>
											<img style={{marginRight:'8px'}} src={layer_set.copy_content} height='16px' onClick={(e) => {e.stopPropagation();set_layer_set_create_copy_mode('copy');set_selected_layer_set_for_copy(layer_set); set_show_create_layerset_modal('create_layerset_modal')}}/>
											<img src={layer_set.delete_content} height='16px' onClick={(e) => {e.stopPropagation();delete_layerset_onclick(layer_set)}}/>
										</div>
									</div>
								))
							}
						</div>
						<div className='special_text flex_center' style={{position:'absolute', bottom:'-24px', fontSize:'14px', width:'100%', height:'24px', backgroundColor:'white'}}>
							<span onClick={() => {set_view_all_layers_modal(true); get_add_layersets_from_templates()}}>View all layers</span>
						</div>
					</div> */}
					<div style={{flex:3, height:'100%', padding:'0px 16px'}}>
						<div style={{display:'flex', justifyContent:'space-between', height:'40px', alignItems:'center', fontSize:'14px'}}>
							
							<div className='flex_property'>
								{'Global Layer Properties'}
							</div>
							{/* <div className='special_text flex_center'>
								<span onClick={() => {set_view_all_layers_modal(true); get_add_layersets_from_templates()}}>View Default Layer Properties</span>
							</div> */}
						</div>
						<div className='special_text' style={{textAlign: 'right', fontSize: '14px'}} onClick={open_add_layer_modal}>
								+ Add new layer
						</div>
						<div style={{height:'420px', backgroundColor:'#F5F6F8', fontSize:'12px'}}>
							{/* <div style={{display:'flex', alignItems:'center', height:'32px'}}>
								<div style={{flex: 1, marginLeft:'8px'}}></div>
								<div style={{flex: 4, color:COLORS.gray6}}>Layer Name</div>
								<div style={{flex: 15, color:COLORS.gray6}}>Properties</div>
							</div> */}
							<div style={{height:'420px', overflow:'auto'}}>
								{/* {console.log('modal json updated hhhhh', modal_json, modal_json && modal_json['active_layerset']['layer_line_colors']['lights'])} */}
								<div style={{backgroundColor:COLORS.gray3, color:COLORS.gray6, height:'32px', display:'flex', alignItems:'center', paddingLeft:'8px', fontSize:'14px', cursor:'pointer', justifyContent: 'space-between'}} onClick={() => {set_show_layers_in_design(!show_layers_in_design)}} >
									<span>Layers in Design <i style={{color:COLORS.gray6, marginLeft:'8px'}} className={show_layers_in_design?'fa fa-angle-up':'fa fa-angle-down'}/></span>
									<span style={{paddingRight: '8px'}}>{((modal_json && modal_json.layers_in_design && modal_json.layers_in_design.length) ? modal_json.layers_in_design.length : '0') + ' Layers'}</span>
								</div>
								{
									show_layers_in_design?
									<div style={{}}>
									<ITable rowSeparated={true} coloumnSeparated={true} style_container={{background: 'white', border: '1px solid var(--light-border-color)'}}>
									<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px' }}>
													<ITH><img height='16px' src={modal_json ? modal_json.show_content : '/resources/nav_icons_new/view layer.svg'} /></ITH>
													<ITH>Layer name</ITH>
													<ITH>Color</ITH>
													<ITH>Line type</ITH>
													<ITH>Line thickness</ITH>
													{/* <ITH>Overridden layers</ITH> */}
									</ITableHeader>
									<ITableBody>
										{modal_json && modal_json.layers_in_design && modal_json.layers_in_design.length && modal_json.layers_in_design.map((layer, idx) => (
											// <div className='layer-table-grid-container' style={{display:'flex', alignItems:'center', height:'32px', color:COLORS.gray6}}>
												<Layer_set_modal_row modal_json={modal_json} update_modal_json={update_modal_json} layer={layer}/>
										))}
									</ITableBody>
									</ITable>
									</div> : ''
								}
								<div style={{backgroundColor:COLORS.gray3, color:COLORS.gray6, height:'32px', display:'flex', justifyContent: 'space-between', alignItems:'center', paddingLeft:'8px', fontSize:'14px', cursor:'pointer'}} onClick={() => {set_show_layers_not_in_design(!show_layers_not_in_design)}}>
									<span>Layers not in Design <i style={{color:COLORS.gray6, marginLeft:'8px'}} className={show_layers_not_in_design?'fa fa-angle-up':'fa fa-angle-down'}/></span>
									<span style={{paddingRight: '8px'}}>{((modal_json && modal_json.layers_not_in_design && modal_json.layers_not_in_design.length) ? modal_json.layers_not_in_design.length : '0') + ' Layers'}</span>
								</div>
								{show_layers_not_in_design?
									<ITable rowSeparated={true} coloumnSeparated={true} style_container={{background: 'white', border: '1px solid var(--light-border-color)'}}>
									<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px' }}>
													<ITH><img height='16px' src={modal_json ? modal_json.show_content : '/resources/nav_icons_new/view layer.svg'} /></ITH>
													<ITH>Layer name</ITH>
													<ITH>Color</ITH>
													<ITH>Line type</ITH>
													<ITH>Line thickness</ITH>
													{/* <ITH>Overridden layers</ITH> */}
									</ITableHeader>
									<ITableBody>
										{modal_json && modal_json.layers_not_in_design && modal_json.layers_not_in_design.length && modal_json.layers_not_in_design.map((layer, idx) => (
											// <div style={{display:'flex', alignItems:'center', height:'32px', color:COLORS.gray6}}>
											<Layer_set_modal_row modal_json={modal_json} update_modal_json={update_modal_json} layer={layer}/>
											// </div>
										))}
									</ITableBody>
									</ITable>:<div style={{backgroundColor: '#F5F6F8'}}></div>}
							</div>
						</div>
					</div>
				</div>
			</ModalBody>
		</Modal>
	)    
}

const SubcomponentPricingDetailsUpdateModal = ({modal_type, modal_json, handle_close, set_page_loader}) => {
	const [modal_json_restructured, set_modal_json_restructured] = useState({});
	const [sales_channel_details, set_sales_channel_details] = useState([]);
	const [selected_sc_id, set_selected_sc_id] = useState('');
    const {t} = useTranslation()
	
	const onclick_handle_close = () => {
		handle_close();
		set_modal_json_restructured({});
		set_sales_channel_details([]);
		set_selected_sc_id('');
	}

	const reRenderModal = () => {
		set_modal_json_restructured(JSON.parse(JSON.stringify(modal_json_restructured)))
	}

	const handleOnChange = (item, key, value, reRender) => {
		item[key] = value;
		if(reRender){
			reRenderModal()
		}
	}

	const is_indeterminate = (division) => {
		let checked_count = modal_json_restructured && selected_sc_id && modal_json_restructured[selected_sc_id][division] && modal_json_restructured[selected_sc_id][division].length > 0 && modal_json_restructured[selected_sc_id][division].filter(unit => unit.checked).length
		if(modal_json_restructured && selected_sc_id && modal_json_restructured[selected_sc_id][division] && checked_count && (checked_count <  modal_json_restructured[selected_sc_id][division].length)){
			return true
		}

		return false
	}

	const checkAll = (division) => {
		let checked_count = modal_json_restructured && selected_sc_id && modal_json_restructured[selected_sc_id][division] && modal_json_restructured[selected_sc_id][division].length > 0 && modal_json_restructured[selected_sc_id][division].filter(unit => unit.checked).length
		if(modal_json_restructured && selected_sc_id && modal_json_restructured[selected_sc_id][division] && checked_count && (checked_count === modal_json_restructured[selected_sc_id][division].length)){
			return true
		}

		return false
	}

	const onCheckAllChange = (event, division) => {
		let temp = modal_json_restructured && selected_sc_id && modal_json_restructured[selected_sc_id][division] && modal_json_restructured[selected_sc_id][division].length > 0 && modal_json_restructured[selected_sc_id][division].map(unit => {
			if(event.target.checked){
				unit.checked = true
			}else{
				unit.checked = false
			}
		})
		reRenderModal()
	}

	const update_subcomponent_prices = () => {
		console.log(modal_json_restructured)
		set_page_loader({
			show: true,
			text: 'Please wait'
		}); 
		// handle_close();
		setTimeout(async() => {
			console.log(modal_json_restructured)
			var resp = await window.Promisify(window.Module.update_subcomponent_prices(JSON.stringify(modal_json_restructured)));
			onclick_handle_close();
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	useEffect(() => {
		if(modal_json && modal_type == "subcomponent_pricing"){
			let sc = Object.keys(modal_json);
			let restructured_json = {};
			let sc_details = [];
			if(sc && sc.length){
				set_selected_sc_id(sc[0])
			}
			sc && sc.length && sc.map(x => {
				sc_details.push({id: x, name: modal_json[x].name});
				modal_json[x].subcomponents.map(subcomponent => {
					if(!subcomponent.selected_option && subcomponent.options && subcomponent.options.length){
						subcomponent.selected_option = subcomponent.options[0].id
					}
				})
				let subcomponents = group_by_json(modal_json[x].subcomponents, "division");
				restructured_json[x] = subcomponents;
			})
			set_sales_channel_details(sc_details);
			set_modal_json_restructured(restructured_json);
		}
	}, [modal_json, modal_type]);

	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type == "subcomponent_pricing"}>
			<IModalHeader toggle={onclick_handle_close}>Subcomponent Prices</IModalHeader>
			<IModalBody style={{height: '600px', overflow: 'auto'}}>
				<div className='flex_property mb-4 px-4 py-2' style={{margin: '-16px', background: 'white', position: 'sticky', top: '-16px', zIndex: 2}}>
					<div className='mr-3'>{t(`Sales Channel of parent SKU`)}</div>
					<Input style={{width: 'fit-content', fontSize: '12px'}} value={selected_sc_id} type='select' onChange={(e) => set_selected_sc_id(e.target.value)}>
						{
							sales_channel_details && sales_channel_details.length ? sales_channel_details.map((option, index) => (
								<option key={index} value={option.id}>{option.name}</option>
							))
							:''
						}
					</Input>
				</div>
				{
					modal_json_restructured && selected_sc_id && modal_json_restructured[selected_sc_id] && Object.keys(modal_json_restructured[selected_sc_id]) ? Object.keys(modal_json_restructured[selected_sc_id]).map((division, idx) => (
						<UncontrolledCollapse header={<div style={{textTransform: 'capitalize', fontSize: '16px', fontWeight: 400}}>{`${division} (${modal_json_restructured[selected_sc_id][division].length})`}</div>} key={idx} className={'mb-3'} style_header={{background: '#E6E9EE'}}>
							<ITable bordered={true} coloumnSeparated={true} rowSeparated={true}>
								<ITableHeader>
									{/* <ITH>
										<Checkbox className='mr-2' indeterminate={is_indeterminate(division)} checked={checkAll(division)} onClick={(e) => {onCheckAllChange(e, division)}}></Checkbox>
									</ITH> */}
									<ITH>Sub Component SKU</ITH>
									<ITH>SubComponent's Sales channel</ITH>
								</ITableHeader>
								<ITableBody>
									{
										modal_json_restructured[selected_sc_id][division].map(subcomponent => (
											<ITableRow>
												{/* <ITD>
													<Checkbox checked={subcomponent.checked} onChange={() => handleOnChange(subcomponent, 'checked', !subcomponent.checked, 1)} />
												</ITD> */}
												<ITD>{subcomponent.name}</ITD>
												<ITD>
													<Input value={subcomponent.selected_option} disabled={subcomponent.deprecated} type='select' onChange={(e) => handleOnChange(subcomponent, 'selected_option', e.target.value, 1)} style={{fontSize: '12px'}}>
														{
															subcomponent.options && subcomponent.options.map((option, index) => (
																<option key={index} value={option.id}>{option.name}</option>
															))
														}
													</Input>
												</ITD>
											</ITableRow>
										))
									}
								</ITableBody>

							</ITable>
						</UncontrolledCollapse>
					))
					:''
				}
			</IModalBody>
			<IModalFooter>
				<Button className='secondary_button_default' onClick={onclick_handle_close}>Close</Button>
				<Button className='primary_button_default' onClick={update_subcomponent_prices}>Update</Button>
			</IModalFooter>
		</Modal>
	)
}

const Refresh_admin_cache_modal = ({modal_type, modal_json, handle_close, update_modal_json, handle_ui_response,set_page_loader}) => {
	const [cache_details, set_cache_details] = useState({});

	const onclick_handle_close = () => {
		set_cache_details({})
		handle_close()
	}
	useEffect(() => {
		if(modal_type == "refresh_admin_cache" && modal_json){
			set_cache_details(JSON.parse(JSON.stringify(modal_json))); 
		}
	},[modal_json, modal_type])

	const onclick_apply = () => {
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {
			window.Module.refresh_admin_cache_apply(JSON.stringify(cache_details));
			handle_close();
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}
	const checkbox_onchange = (id) => {
		var old_cache_details = JSON.parse(JSON.stringify(cache_details));

		var field = old_cache_details.cache_data.find(o => o.id == id);

		if(field){
			field.checked = !field.checked;
		}

		set_cache_details(old_cache_details);
	}

	return(
		// <Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="refresh_admin_cache"}>
		// 	<Card className="bg-secondary shadow border-0">
		// 		<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
		// 			<div>
		// 				<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Refresh Cache Data</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
		// 			</div>
		// 		</CardHeader>
		// 		<CardBody style={{backgroundColor: COLORS.white}}>
		// 			<div style={{paddingLeft:20,textAlign:"left"}}>
		// 				{cache_details && cache_details.cache_data && cache_details.cache_data.length && cache_details.cache_data.map((item,idx) => (							
		// 					<div key={idx} style={{height:'50px', padding:'0px 20px', display:'flex', alignItems:'center', backgroundColor: '#FAFAFA', boxShadow: 'inset 0 -1px 0 0 #E4E7EB'}}>
		// 						<Input checked={item.checked} onChange={() => {checkbox_onchange(item.id)}} type='checkbox' style={{cursor:'pointer', position:'unset', margin:0, marginRight:'15px'}} />
		// 						<div style={{textTransform:'capitalize'}}>{item.display_name}</div>
		// 					</div>
		// 				))}
		// 			</div>
		// 		</CardBody>
		// 		<CardFooter style={{backgroundColor:'white', display:'flex', justifyContent:'flex-end'}}>
		// 			<Button onClick={onclick_apply} className='blue_button'>Apply</Button>
		// 		</CardFooter>
		// 	</Card>
		// </Modal>
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type == "refresh_admin_cache"}>
			<IModalHeader toggle={onclick_handle_close}>Refresh Cache Data</IModalHeader>
			<IModalBody style={{maxHeight: '600px', overflow: 'auto'}}>
				{cache_details && cache_details.cache_data && cache_details.cache_data.length && cache_details.cache_data.map((item, idx) => (
					<div key={idx} onClick={() => { checkbox_onchange(item.id) }} className='cp flex_property p-2' style={{minHeight: '52px', borderBottom: '1px solid var(--light-border-color)', backgroundColor: 'white'}}>
						<Checkbox checked={item.checked} className='mr-2'/>
						<div style={{ textTransform: 'capitalize' }}>{item.display_name}</div>
					</div>
				))}
			</IModalBody>
			<IModalFooter>
				<Button onClick={onclick_apply} className='primary_button_default'>Apply</Button>
			</IModalFooter>
		</Modal>
	)
}

const SingleRowCamOutputs = ({ item, checkbox_onchange }) => {
	return (
		<React.Fragment>
			<div className='single_row_parent' onClick={() => { checkbox_onchange(item) }} style={{  }}>
				<div className='flex_property'>
					<Checkbox checked={item.checked} className='mx-3'/>
					<div style={{ textTransform: 'capitalize' }}>{item.display_name}</div>
				</div>
			</div>
			{
				item.checked && item.includes && item.includes.length ?
					<div className='single_row_child' style={{background:'#FFFFFF'}}>
						{
							item.includes ? item.includes.map((sub_item, idx) => (
								<SingleRowCamOutputs key={idx} item={sub_item} checkbox_onchange={checkbox_onchange} />
							))
								: ''

						}
					</div>
					: ''
			}
		</React.Fragment>
	)
}

const Download_CAM_Outputs_Modal = ({modal_type, modal_json, handle_close, update_modal_json, handle_ui_response,set_page_loader}) => {
	const [download_details, set_download_details] = useState({});
	const [selected_category, set_selected_category] = useState([]);
	const alert =  useAlert()

	const on_click_handleclose = () => {
		set_download_details({})
		set_selected_category([])
		handle_close()
	}
	useEffect(() => {
		if(modal_type == "download_cam_outputs_modal" && modal_json){
			set_download_details(JSON.parse(JSON.stringify(modal_json))); 
		}
	},[modal_json, modal_type])

	const onclick_apply = () => {
		set_page_loader({
			show: true,
			text: 'Compressing & Downloading Files...'
		}, async() => {
			var ui_response = await window.Promisify(window.Module.design_cam_outputs_download(JSON.stringify(download_details)));
			handle_ui_response(ui_response);
			update_modal_json();
			
			set_page_loader({
				show: false,
				text: 'Compressing & Downloading Files...'
			});	
		})
	}
	
	// CAM Settings to be opened from Download cam outputs
	const open_cam_settings = async() => {
		let resp = await window.Promisify(window.Module.open_cam_settings_modal(""));
		handle_ui_response(resp)

	}

	const checkbox_onchange = (item) => {

		if(item){
			item.checked = !item.checked;
		}

		set_download_details(JSON.parse(JSON.stringify(download_details)));
	}

	const onselect_category_checkbox = (selectedList) => {
		// console.log(modal_json_modified)
		set_selected_category(selectedList)
	}

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type == "download_cam_outputs_modal"}>
			<IModalHeader toggle={on_click_handleclose}>
				Download CAM Outputs (zip)
			</IModalHeader>
			<IModalBody style={{maxHeight: '560px', overflow: 'auto'}}>
				<div style={{textAlign: "left"}}>
					{download_details && download_details.download_types && download_details.download_types.length && download_details.download_types.map((item, idx) => (
						<SingleRowCamOutputs key={idx} item={item} checkbox_onchange={checkbox_onchange}/>
					))}
					{/* <div style={{ height: '52px', padding: '0px 20px', display: 'flex', alignItems: 'center', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 -1px 0 0 #E4E7EB' }}>
						<div className='flex_property'>
							<Checkbox checked={download_details.fullfillment_tag} onClick={() => { toggle_fullfillment_tag() }} style={{ marginRight: '16px' }} />
							<div style={{ textTransform: 'capitalize' }}>{'include fulfillment tag'}</div>
						</div>
					</div> */}
				</div>
			</IModalBody>
			<IModalFooter>
				{/* <div className='flex_property' style={{ fontSize: '12px' }}>
					<Checkbox checked={download_details.fullfillment_tag} onClick={() => { toggle_fullfillment_tag() }} style={{ marginRight: '16px' }} />
					<div style={{ textTransform: 'capitalize', fontWeight: 500}}>{'include fulfillment tag'}</div>
				</div> */}
				<Button onClick={open_cam_settings} className='secondary_button_default' style={{display: 'inline-block'}}><img src='/resources/toolbar_icons_new/settings.svg' height={'16px'}/> CAM Settings</Button>
				<Button onClick={onclick_apply} disabled={ !(download_details && download_details.download_types && download_details.download_types.filter(x => x.checked).length)  } className='primary_button_default'>Download</Button>
			</IModalFooter>
		</Modal>
	)
}
const default_cam_structure = camJsonData;

const formatCamInputBeforeSubmit = (value) => {
    if(typeof(value) === 'string'){
        return value.trim()
    }

    return value
}


const invalidCamInput = (x) => {
	x = formatCamInputBeforeSubmit(x)
    if (!x && x !== 0 && x!== false) {
        return true
    }
    return false
}

const get_pref_value = (preference, value) => {

    if (preference.type === 'number' && value !== '') {
        return Number(value)
    } else if (preference.type === 'bool') {
        return value === 'true'
    }

    return value
}

const toJSON = (str) => {
    if(typeof(str) === "string"){
        try {
            let json_str = JSON.parse(str);
            return json_str;
        } catch (err) {
            return [];
        }
    }

    return str
}

const get_top_level_pref_value = (value) => {
    return value
    
}

const is_depth_per_pass_visible = (camPreferences) => {
    if(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"]){
        if(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"].final_value){
            if(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"].final_value === "depth_per_pass"){
                return true
            }
            return false
        }else{
            if(get_top_level_pref_value(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"].value) === "depth_per_pass"){
                return true
            }
            return false
        }
    }

    return true
}

const is_number_of_steps_visible = (camPreferences) => {
    if(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"]){
        if(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"].final_value){
            if(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"].final_value === "number_of_steps"){
                return true
            }
            return false
        }else{
            if(get_top_level_pref_value(camPreferences["cam_preferences~pocket_preferences~homag_pocket~step_depth_type"].value) === "number_of_steps"){
                return true
            }
            return false
        }
    }

    return true
}

const is_final_pass_depth_visible = (camPreferences) => {
    if(camPreferences["kdt_nc.router.final_pass"] !== undefined){
            return camPreferences["kdt_nc.router.final_pass"]
    }

    return true
}

const visibilityModule = {
    "cam_preferences~pocket_preferences~homag_pocket~depth_per_pass": is_depth_per_pass_visible,
    "cam_preferences~pocket_preferences~homag_pocket~number_of_steps": is_number_of_steps_visible,
    "kdt_nc.router.final_pass_depth": is_final_pass_depth_visible
}
// Note - 
// Visibility Route for preferences will be preference_full_name. For settings we can define a route.
// If a and b depend on value of c. then a, b, c should all be part of same parent.
// c should have the flag is_dependents - true. informing that a rerender is required on parent level to reflect the changes.
// Since the code is optimized for re-renders if the flag is not present the parent will not rerender and hence no changes will be seen.

const isPrefVisible = (pref, camPreferences, route) => {
    let visiblity_route;
    
    if(pref && pref.visiblity_route){
        visiblity_route = pref.visiblity_route;
    }else if(pref && pref.preference_full_name){
        visiblity_route = pref.preference_full_name
    }

    if(route){
        visiblity_route = route
    }

    if(visiblity_route && camPreferences && visibilityModule[visiblity_route]){
        return visibilityModule[visiblity_route](camPreferences)
    }
    return true
}


const ParentPreference = ({ parentPreference, full_name = '', isCollapsable = true, camSettings, camPreferences, defaultState=false }) => {
	const [dummy_state, set_dummy_state] = useState({});

    const reRender = () => {
        set_dummy_state(JSON.parse(JSON.stringify(dummy_state)))
    }

    return (
        parentPreference ?
            isCollapsable ?
                <UncontrolledCollapse header={parentPreference.name} defaultState={defaultState} style_header={{ fontSize: '14px', backgroundColor: '#E6E9EE' }} className='mb-3'>
                    {
                        parentPreference ? parentPreference.children.map((preference) => (
                            preference.type !== "parent" ?
                                <LeafPreference leafPreference={preference} full_name={full_name ? full_name + '.' + preference.id : preference.id} camSettings={camSettings} camPreferences={camPreferences} reRender={reRender}/>
                                :
                                <ParentPreference parentPreference={preference} full_name={full_name ? full_name + '.' + preference.id : preference.id} camSettings={camSettings} camPreferences={camPreferences} />
                        ))
                            : ''
                    }
                </UncontrolledCollapse>
                :
                parentPreference ? parentPreference.children.map((preference) => (
                    preference.type !== "parent" ?
                        <LeafPreference leafPreference={preference} full_name={full_name ? full_name + '.' + preference.id : preference.id} camSettings={camSettings} camPreferences={camPreferences} reRender={reRender}/>
                        :
                        <ParentPreference parentPreference={preference} full_name={full_name ? full_name + '.' + preference.id : preference.id} camSettings={camSettings} camPreferences={camPreferences} />
                ))
                    : ''
            : ''

    )
}

const LeafPreferenceSingleRowAdvancedModal = ({open, handle_close, fields, camSettings, index, unit, full_name}) => {
	const [unit_copy, set_unit_copy] = useState({});
	useEffect(() => {
        let temp = JSON.parse(JSON.stringify(unit))
        
		for(let i in fields){
            let col = fields[i]
            if(invalidCamInput(unit[col.id])){
                let default_value = col.default_value
                temp[col.id] = default_value
                camSettings[full_name][index][col.id] = default_value
            }
        }

        set_unit_copy(temp)
    }, [unit]);

	const handleChange = (event, col, idx) => {
        let temp = JSON.parse(JSON.stringify(unit_copy))
        temp[col.id] = event.target.value;
        set_unit_copy(temp)
        // todo - change the below line
        // leafPreference.tool_list[idx][col.id] = (col.type === 'number' && event.target.value !== '') ? Number(event.target.value) : event.target.value
        camSettings[full_name][idx][col.id] = (col.type === 'number' && event.target.value !== '') ? Number(event.target.value) : event.target.value
    }

	return (
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={handle_close}>
			<IModalHeader toggle={handle_close}>Advanced Properties</IModalHeader>
			<IModalBody>
				{fields.map(col => (
					<div className='flex_property justify-content-between'>
						<div className='flex_property' style={{whiteSpace:'nowrap', paddingRight:'16px'}}>
						{col.name}
						</div>
						{col.type === 'number' || col.type === 'text' ? <Input className={``} type={col.type} value={unit_copy[col.id]} onChange={(e) => handleChange(e, col, index)}></Input> : ''}
							{col.type === 'select' ? <Input className={``} type={col.type} value={unit_copy[col.id]} onChange={(e) => handleChange(e, col, index)}>
							{
								col.value_options && col.value_options.length ? col.value_options.map((option) => (
									<option key={option.id} value={option.id}>{option.name}</option>
								))
									: ''
							}
						</Input> : ''}
					</div>
				))}
			</IModalBody>
		</Modal>
	)
}

const LeafPreferenceSingleRow = ({ leafPreferenceCopy, leafPreference, unit, delete_row, index, full_name, camSettings }) => {
	const [unit_copy, set_unit_copy] = useState({});
	const [open_advanced, set_open_advanced] = useState(false);
	const [advanced_fields, set_advanced_fields] = useState([]);

	const open_advanced_modal = (fields) => {
		set_advanced_fields(fields)
		set_open_advanced(true)
	}

	const close_advanced_modal = () => {
		set_open_advanced(false)
	}

    const handleChange = (event, col, idx) => {
        let temp = JSON.parse(JSON.stringify(unit_copy))
        temp[col.id] = event.target.value;
        set_unit_copy(temp)
        // todo - change the below line
        // leafPreference.tool_list[idx][col.id] = (col.type === 'number' && event.target.value !== '') ? Number(event.target.value) : event.target.value
        camSettings[full_name][idx][col.id] = (col.type === 'number' && event.target.value !== '') ? Number(event.target.value) : event.target.value
    }

    useEffect(() => {
        let temp = JSON.parse(JSON.stringify(unit))
        
		for(let i in leafPreference.fields){
            let col = leafPreference.fields[i]
            if(invalidCamInput(unit[col.id])){
                let default_value = col.default_value
                temp[col.id] = default_value
                camSettings[full_name][index][col.id] = default_value
            }
        }

        set_unit_copy(temp)
    }, [unit]);

    // useEffect(() => {
    // 	let validity = true;
    // 	// todo check for validity
    // 	if(leafPreference.tool_list){
    // 		for(let row in leafPreference.tool_list){
    // 			if(leafPreference.fields && leafPreference.fields.length){
    // 				for(let i in leafPreference.fields){
    // 					let field = leafPreference.fields[i]
    // 						if( field.mandatory && invalidInput(row[field.id])){
    // 							validity = false;
    // 							break;
    // 						}
    // 				}
    // 				if(!validity){
    // 					break;
    // 				}
    // 			}
    // 		}
    // 	}

    // 	is_valid[full_name] = validity
    // }, [unit_copy]);

    return (
        <React.Fragment>
			<LeafPreferenceSingleRowAdvancedModal open={open_advanced} handle_close={close_advanced_modal} fields={advanced_fields} unit={unit} camSettings={camSettings} index={index} full_name={full_name}/>
            {
                leafPreference["fields"] && leafPreference["fields"].length > 0 &&
                leafPreference["fields"].map((col, idx) =>
                    <ITD key={idx}>
                        {/* {col.type === 'checkbox' ? <Checkbox_input_for_restore_modal unit={unit} col={col} reRenderModal={reRenderModal} /> : ''} */}
                        {col.type === 'number' || col.type === 'text' ? <Input className={`${col.mandatory && invalidCamInput(unit_copy[col.id]) ? 'invalid_input_cam' : ''}`} type={col.type} value={unit_copy[col.id]} onChange={(e) => handleChange(e, col, index)}></Input> : ''}
                        {/* {col.type === 'image' ? <img style={{ color: '#FFFFFF', maxHeight: '100%', maxWidth: '100%', height: "auto", width: '130px' }} title={unit[col.id] ? unit[col.id] : ''} src={unit[col.id] ? unit[col.id] : "-"} /> : ''}
						{col.type === 'multi_data' ?
							<React.Fragment>
								{
									unit[col.id] && unit[col.id].length && unit[col.id].map((x, idx) => (
										<div key={idx}>{x}</div>
									))
								}
							</React.Fragment>
							: ''
						} */}
                        {col.type === 'select' ? <Input className={`${col.mandatory && invalidCamInput(unit_copy[col.id]) ? 'invalid_input_cam' : ''}`} type={col.type} value={unit_copy[col.id]} onChange={(e) => handleChange(e, col, index)}>
                            {
                                col.value_options && col.value_options.length ? col.value_options.map((option) => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))
                                    : ''
                            }
                        </Input> : ''}
						{col.type === 'parent' ? <ITD><i style={{ color: 'blue', cursor: 'pointer' }} className='fa fa-edit pl-2 pr-2 pt-2 pb-2 mr-1' onClick={() => {open_advanced_modal(col.children)}}></i></ITD> : ''}
                    </ITD>
                )
            }
            <ITD><i style={{ color: 'red', cursor: 'pointer' }} className='fa fa-trash' onClick={delete_row}></i></ITD>
        </React.Fragment>
    )
}

const LeafPreferenceInput = ({ preference, camSettings, full_name, camPreferences, reRender}) => {
    const [value, set_value] = useState('');

    const handleChange = (e) => {
        set_value(e.target.value)
        if(preference.preference_full_name){
            camPreferences[preference.preference_full_name]["final_value"] = get_pref_value(preference, e.target.value)
        }else{
            camSettings[full_name] = get_pref_value(preference, e.target.value)
        }

        if(preference.has_dependents && reRender){
            // ReRender the page
            reRender()

        }
    }

    useEffect(() => {
        if (camSettings) {
            if(preference.preference_full_name){
                if(camPreferences){
                    let cam_pref = camPreferences[preference.preference_full_name]
                    if(cam_pref){
                        set_value(get_top_level_pref_value(cam_pref.value))
                    }
                }
            }
            else{
                if (!invalidCamInput(camSettings[full_name]) && value != camSettings[full_name]) {
                    set_value(camSettings[full_name])
                } else if (invalidCamInput(camSettings[full_name] && !invalidCamInput(preference.default_value))) {
                    camSettings[full_name] = preference.default_value
                    set_value(get_pref_value(preference, preference.default_value))
					reRender()
                }
            }
        }
    }, [camSettings, camPreferences]);

    return (
        isPrefVisible(preference, preference.preference_full_name ? camPreferences : camSettings, preference.preference_full_name ? "" : full_name) ? 
        <React.Fragment>
            {
                preference.type === 'number' || preference.type === "text" ?

                    <div className='mb-2 flex_property' style={{padding: '0px 20px'}} >
                        <div style={{ fontSize: '14px', textAlign: 'left', fontWeight: 500, flex: 2 }}>{preference.name}</div>
                        <Input className={`${preference.mandatory && invalidCamInput(value) ? 'invalid_input_cam' : ''}`} style={{ flex: 1, minWidth: '40px' }} type={preference.type} value={value} onChange={handleChange} />
                    </div>
                    :
                    preference.type === 'select' ?
                        <div className='mb-2 flex_property' style={{padding: '0px 20px'}} >
                            <div style={{ fontSize: '14px', textAlign: 'left', fontWeight: 500, flex: 2 }}>{preference.name}</div>
                            <Input className={`${preference.mandatory && invalidCamInput(value) ? 'invalid_input_cam' : ''}`} style={{ flex: 1, minWidth: '40px' }} type={preference.type} value={value} onChange={handleChange}>
                                {
                                    preference.preference_full_name && camPreferences && camPreferences[preference.preference_full_name] && camPreferences[preference.preference_full_name].value_options ?
                                        camPreferences[preference.preference_full_name].value_options.map(option => (
                                            <option key={option} value={option}>{option}</option>
                                        ))
                                        :
                                        preference.value_options.map(option =>
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        )
                                }
                            </Input>
                        </div>
                        :
                        preference.type === 'bool' ?
                            <div className='mb-2 flex_property' style={{padding: '0px 20px'}} >
                                <div style={{ fontSize: '14px', textAlign: 'left', fontWeight: 500, flex: 2 }}>{preference.name}</div>
                                <Input className={`${preference.mandatory && invalidCamInput(value) ? 'invalid_input_cam' : ''}`} style={{ flex: 1, minWidth: '40px' }} type={'select'} value={value === 'true' || value === true ? 'true' : 'false'} onChange={handleChange}>
                                    <option value={'true'}>True</option>
                                    <option value={'false'}>False</option>
                                </Input>
                            </div>
                            : ''
            }

        </React.Fragment>
		:''
    );
}


const LeafPreference = ({ leafPreference, full_name, camSettings, camPreferences, reRender }) => {
    const [leafPreferenceCopy, setLeafPreferenceCopy] = useState({});
    const {t} = useTranslation()

    const reRenderLeafPreferenceCopy = () => {
        setLeafPreferenceCopy(JSON.parse(JSON.stringify(leafPreference)))
    }

    const add_row = () => {
        let sample_row = {}
        if (leafPreference.fields && leafPreference.fields.length) {
            for (let i in leafPreference.fields) {
                let field = leafPreference.fields[i]
                sample_row[field.id] = field.default_value ? field.type === 'number' ? Number(field.default_value) : field.default_value : ''
            }
        }
        if (camSettings[full_name] && Array.isArray(camSettings[full_name])) {
            camSettings[full_name].push(sample_row)
        } else if(camSettings[full_name] && typeof(camSettings[full_name] === 'string')) {
            camSettings[full_name] = toJSON(camSettings[full_name])
            camSettings[full_name].push(sample_row)
        }else{
            camSettings[full_name] = [sample_row]
        }
        reRenderLeafPreferenceCopy()
    }

    const delete_row = (idx) => {
        if (camSettings[full_name] && Array.isArray(camSettings[full_name])) {
            camSettings[full_name].splice(idx, 1)
        } else if(camSettings[full_name] && typeof(camSettings[full_name] === 'string')) {
            camSettings[full_name] = toJSON(camSettings[full_name])
            camSettings[full_name].splice(idx, 1)
        }
        reRenderLeafPreferenceCopy()
    }

    useEffect(() => {
        reRenderLeafPreferenceCopy()
    }, [leafPreference]);


    return (
        <div className='mb-3'>
            {
                leafPreference.type === "table" ?
                    <React.Fragment>
                        <div className='mb-2' style={{ fontSize: '14px', textAlign: 'left', fontWeight: 500, textTransform: 'capitalize' }}>{leafPreference.name}</div>
                        <ITable coloumnSeparated={true} rowSeparated={true} hover={true} style_container={{ border: '1px solid var(--light-border-color)', maxHeight: '568px', overflow: 'auto', whiteSpace: 'nowrap' }}>
                            <ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px', zIndex: 1 }}>
                                {
                                    leafPreference.fields ? leafPreference.fields.map((col, idx) =>
                                        <ITH key={idx}><div className='flex_property'> {/*col.type === 'checkbox' ? <Checkbox className='mr-2' indeterminate={is_indeterminate(col.id)} checked={checkAll(col.id)} onClick={(e) => { onCheckAllChange(e, col.id) }}></Checkbox> : ''*/} <div>{col.name}{col.mandatory ? '*' : ''}</div></div></ITH>
                                    )
                                        : ''
                                }
                                <ITH>Actions</ITH>
                            </ITableHeader>
                            <ITableBody>
                                {
                                    camSettings && camSettings[full_name] && toJSON(camSettings[full_name]).map((unit, idx) => (
                                        <ITableRow key={idx}>
                                            <LeafPreferenceSingleRow leafPreferenceCopy={leafPreferenceCopy} leafPreference={leafPreference} unit={unit} delete_row={() => delete_row(idx)} index={idx} full_name={full_name} camSettings={camSettings} />
                                        </ITableRow>
                                    ))
                                }
                            </ITableBody>
                        </ITable>
                        <div className='mt-4 ml-3' style={{ display: 'flex', color: '#2688F7', fontWeight: '700', fontSize: '14px', textAlign: 'left' }}>
                            <div onClick={add_row} style={{ cursor: 'pointer' }}>{t(`+ Add Row`)}</div>
                        </div>
                    </React.Fragment>
                    :
                    <LeafPreferenceInput preference={leafPreference} camSettings={camSettings} full_name={full_name} camPreferences={camPreferences} reRender={reRender}/>

            }

        </div>
    )

}


function parseValidationRules(input) {
	const camValidation = {};

	function parseNode(node, prefix) {
		const nodeId = prefix ? prefix + '.' + node.id : node.id;
		if (node.type === 'parent' && node.children) {
			node.children.forEach(child => parseNode(child, nodeId));
		} else if (node.type === 'table' && node.fields) {
			let mandatoryFields = []
			for(let f in node.fields){
				if(node.fields[f].mandatory){
					mandatoryFields.push(node.fields[f].id)
				} else if (node.fields[f].type === 'parent'){
					for(let c in node.fields[f].children){
						if(node.fields[f].children[c].mandatory){
							mandatoryFields.push(node.fields[f].children[c].id)
						}
					}
				}
			}
			// const mandatoryFields = node.fields
			// 	.filter(field => field.mandatory)
			// 	.map(field => field.id);
			camValidation[nodeId] = {
				type: 'table',
				mandatory_fields: mandatoryFields
			};
		} else {
			camValidation[nodeId] = {
				mandatory: node.mandatory
			};
		}
	}
	for(let i in input){
		parseNode(input[i], '');
	}
	// console.log("cam vailidation json", camValidation);

	return camValidation;
}

const camValidation = parseValidationRules(default_cam_structure)

const CamSettingsModal = ({ modal_type, modal_json, handle_close, set_page_loader, handle_ui_response, submit_for_delete_confirm }) => {
	const [camSettings, setCamSettings] = useState(null);
	const [camPreferences, setCamPreferences] = useState(null);

	const alert = useAlert()

	const refreshCam = async () => {
		try{
			let resp = await window.Promisify(window.Module["refresh_cam_settings_from_admin"](''))
			handle_ui_response(resp)
		}catch(err){
			console.log(err)
		}
	}

	const updateCam = async () => {
		

		let isValidCamPref = true
        let design_preference_id_and_values = {}
        for(let i in camPreferences){
            let pref = camPreferences[i]
            if(isPrefVisible(null, camPreferences, i) && typeof(pref.final_value) != 'undefined'){
                if(invalidCamInput(pref.final_value)){
                    isValidCamPref = false;
                    break;
                }
                design_preference_id_and_values[i] = formatCamInputBeforeSubmit(pref.final_value);
            }
        }

		if (isCamPrefValid(camSettings) && isValidCamPref) {
			const update_data = {}
			update_data.cam_settings = camSettings
			update_data.cam_preferences = design_preference_id_and_values
			window.add_debug_log("cam preferences in update", update_data, design_preference_id_and_values)
			set_page_loader({
				show: true,
				text: 'Please wait'
			});

			try {
				if(modal_json.route){
					await window.Promisify(window.Module[modal_json.route](JSON.stringify(update_data)))
					handle_close()
					alert.success('Cam Settings updated successfully')
				}
			} catch (err) {
				console.log(err)
			}

			set_page_loader({
				show: false,
				text: 'Please wait'
			});
		}
		else{
			alert.error("Please fill all the mandatory fields")
		}
	}

	useEffect(() => {
		if (modal_json && modal_json.cam_settings) {
			setCamSettings(modal_json.cam_settings)
			setCamPreferences(modal_json.cam_preferences)
		} else {
			setCamSettings({})
			setCamPreferences({})
		}
	}, [modal_json]);

	const isCamPrefValid = (preference, full_name = '') => {
		// if(preference.tool_list){
		// 	let leafPreference = preference
		// 	let validity = true;
		// 	// todo check for validity
		// 	if (leafPreference.tool_list.length) {
		// 		for (let j in leafPreference.tool_list) {
		// 			let row = leafPreference.tool_list[j]
		// 			if (default_cam_structure2[full_name] && default_cam_structure2[full_name].length) {
		// 				for (let i in default_cam_structure2[full_name]) {
		// 					let field = default_cam_structure2[full_name][i]
		// 					if (field.mandatory && invalidInput(row[field.id])) {
		// 						validity = false;
		// 						break;
		// 					}
		// 				}
		// 				if (!validity) {
		// 					break;
		// 				}
		// 			}
		// 		}
		// 	}

		// 	return validity
		// }else{
		// 	let validity = preference && Object.keys(preference) ? Object.keys(preference).map((childPref) => (isCamPrefValid(preference[childPref], full_name ? full_name+ '>' + childPref : childPref))) : [true]
		// 	return !validity.includes(false)
		// }
		if(preference && Object.keys(preference)){
			for(let full_name in preference){
				if(camValidation[full_name] && camValidation[full_name].type == 'table'){
					let mandatory_fields = camValidation[full_name].mandatory_fields
                    let table_data = toJSON(preference[full_name]);
					for(let i in table_data){
						let pref = table_data[i]
						for(let field in table_data[i]){
                            table_data[i][field] = formatCamInputBeforeSubmit(table_data[i][field]);
                        }
						for(let j in mandatory_fields){
							let mandatory_field = mandatory_fields[j]
							if(invalidCamInput(pref[mandatory_field])){
								return false
							}
						}
					}
				}else{
					preference[full_name] = formatCamInputBeforeSubmit(preference[full_name])
					if(camValidation[full_name] && camValidation[full_name].mandatory && invalidCamInput(preference[full_name])){
						return false
					}
				}
			}
		}
		return true
	}

	return (
		<Modal className="modal-dialog-centered" isOpen={modal_type == "cam_settings"} style={{ maxWidth: '90%' }}>
			<IModalHeader toggle={handle_close}>CAM Settings</IModalHeader>
			<IModalBody style={{height: '600px', overflow: 'auto'}}>
				{
					default_cam_structure && default_cam_structure.length ? default_cam_structure.map((preference) => (
						preference.type === 'parent' ? 
						<ParentPreference parentPreference={preference} full_name={preference.id} camSettings={camSettings} camPreferences={camPreferences}/>
						:
						<LeafPreference leafPreference={preference} full_name={preference.id} camSettings={camSettings} camPreferences={camPreferences}/>
					))
					:''
				}
			</IModalBody>
			<IModalFooter>
				<Button className='secondary_button_default mr-auto' onClick={() => submit_for_delete_confirm(refreshCam, () => {}, "CAM settings will be fetched from the Admin Portal and override the existing settings of the design. Do you want to continue?", {name: "Yes", type: 'primary_button_default'})}>Refresh from Admin</Button>
				<Button className='secondary_button_default' onClick={handle_close}>Cancel</Button>
				<Button className='primary_button_default' onClick={updateCam}>Apply</Button>
			</IModalFooter>
		</Modal>
	);
}

const MissingToolsModal = ({modal_type, handle_close, modal_json, handle_ui_response}) => {
	const [ dummy_modal_json_map, set_dummy_modal_json_map ] = useState({});
	const [camSettings, setCamSettings] = useState(null);
	const [camPreferences, setCamPreferences] = useState(null);
	const [cam_settings_json, set_cam_settings_json] = useState();
	const [tabs, set_tabs] = useState([]);
	const [activeTab, setActiveTab] = useState('');
	const [ activeSettingId, setActiveSettingId ] = useState('');
	const [dummy, set_dummy] = useState({});

	const reRenderModal = () => set_dummy({})

	const onChangeActiveTab = (activeTabId, activeTab) => {
		setActiveTab(activeTabId)
		setActiveSettingId(activeTab.settings_id)
	}

	const is_indeterminate = (col_id) => {
		let checked_count = dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].units && dummy_modal_json_map[activeTab].units.length > 0 && dummy_modal_json_map[activeTab].units.filter(unit => unit[col_id]).length
		if(dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].units && checked_count && (checked_count <  dummy_modal_json_map[activeTab].units.length)){
			return true
		}

		return false
	}

	const checkAll = (col_id) => {
		let checked_count = dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].units && dummy_modal_json_map[activeTab].units.length > 0 && dummy_modal_json_map[activeTab].units.filter(unit => unit[col_id]).length
		if(dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].units && checked_count && (checked_count === dummy_modal_json_map[activeTab].units.length)){
			return true
		}

		return false
	}

	const onCheckAllChange = (event, col_id) => {
		let temp = dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].units && dummy_modal_json_map[activeTab].units.length > 0 && dummy_modal_json_map[activeTab].units.map(unit => {
			if(event.target.checked){
				unit[col_id] = true
			}else{
				unit[col_id] = false
			}
		})

		set_dummy_modal_json_map(JSON.parse(JSON.stringify(dummy_modal_json_map)))
		// reRenderModal()
	}

	const handleButtonSubmit = async(e, button) => {
		e.preventDefault();
		// Your form submission logic  here
		// console.log('Form Data:', formData);
		if(button && button.route){
			let resp = await window.Promisify(window.Module[button.route](JSON.stringify(modal_json)))
			handle_ui_response(resp)

		}
	};

	const updateCam = async () => {
		let isValidCamPref = true
        let design_preference_id_and_values = {}
        for(let i in camPreferences){
            let pref = camPreferences[i]
            if(isPrefVisible(null, camPreferences, i) && typeof(pref.final_value) != 'undefined'){
                if(invalidCamInput(pref.final_value)){
                    isValidCamPref = false;
                    break;
                }
                design_preference_id_and_values[i] = formatCamInputBeforeSubmit(pref.final_value);
            }
        }

		if (isCamPrefValid(camSettings) && isValidCamPref) {
			const update_data = {}
			update_data.cam_settings = camSettings
			update_data.cam_preferences = design_preference_id_and_values
			window.add_debug_log("cam preferences in update", update_data, design_preference_id_and_values)
			// set_page_loader({
			// 	show: true,
			// 	text: 'Please wait'
			// });

			try {
				if(cam_settings_json.route){
					await window.Promisify(window.Module[cam_settings_json.route](JSON.stringify(update_data)))
					onclick_handleclose()
					alert.success('Cam Settings updated successfully')
				}
			} catch (err) {
				console.log(err)
			}

			// set_page_loader({
			// 	show: false,
			// 	text: 'Please wait'
			// });
		}
		else{
			alert.error("Please fill all the mandatory fields")
		}
	}

	useEffect(() => {
		if (cam_settings_json && cam_settings_json.cam_settings) {
			setCamSettings(cam_settings_json.cam_settings)
			setCamPreferences(cam_settings_json.cam_preferences)
		} else {
			setCamSettings({})
			setCamPreferences({})
		}
	}, [cam_settings_json]);

	useEffect(() => {
		set_cam_settings_json(JSON.parse(window.Module.get_cam_settings_modal_data("")))
	}, []);

	useEffect(() => {
		if(modal_json && modal_json.tabs){
			let tab_to_table_map = {}
			let tabs_info = modal_json.tabs.map((tab) => {
				if(tab.id){
					tab_to_table_map[tab.id] = tab
				}
				return {id: tab.id, name: tab.name, settings_id: tab.settings_id, show_tab: tab.show_tab}
			})
			if(tabs_info && tabs_info.length){
				setActiveTab(tabs_info[0].id)
				setActiveSettingId(tabs_info[0].settings_id)
			}
			set_tabs(tabs_info)
			set_dummy_modal_json_map(tab_to_table_map)
		}
	}, [modal_json]);

	const isCamPrefValid = (preference, full_name = '') => {
		if(preference && Object.keys(preference)){
			for(let full_name in preference){
				if(camValidation[full_name] && camValidation[full_name].type == 'table'){
					let mandatory_fields = camValidation[full_name].mandatory_fields
                    let table_data = toJSON(preference[full_name]);
					for(let i in table_data){
						let pref = table_data[i]
						for(let field in table_data[i]){
                            table_data[i][field] = formatCamInputBeforeSubmit(table_data[i][field]);
                        }
						for(let j in mandatory_fields){
							let mandatory_field = mandatory_fields[j]
							if(invalidCamInput(pref[mandatory_field])){
								return false
							}
						}
					}
				}else{
					preference[full_name] = formatCamInputBeforeSubmit(preference[full_name])
					if(camValidation[full_name] && camValidation[full_name].mandatory && invalidCamInput(preference[full_name])){
						return false
					}
				}
			}
		}
		return true
	}

	const onclick_handleclose = () => {
		handle_close()
	}
	
	return (
		<Modal isOpen={modal_type === "missing_tools_modal"} toggle={onclick_handleclose} size="xl">
			<IModalHeader toggle={onclick_handleclose}>{modal_json && modal_json.modal_heading ? modal_json.modal_heading : 'Update the missing tools'}</IModalHeader>
			<IModalBody style={{ height: '500px', overflow: 'auto', display: 'flex'}}>
				<div className="px-2" style={{display: 'flex', flexDirection: 'column', minWidth: '0px', flex: 1}}>
					<INavBar className='mb-3' tabs={tabs} activeTab={activeTab} handleTabClick={onChangeActiveTab} isTabVisible={(tab) => {return tab && tab.show_tab}}/>
					{/* <div className="mb-4 text-inf_theme_gray_700 text-base">Following tools are missing in the board:</div> */}
					{/* {
						modal_json && modal_json.length ? modal_json.map(item => (
							<div className="mb-2">{item}</div>
						)):''
					} */}
					<ITable coloumnSeparated={true} rowSeparated={true} hover={true} style_container={{ border: '1px solid var(--light-border-color)', flex: 1, minHeight: '0px', overflow: 'auto', whiteSpace: 'nowrap' }}>
						<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px', zIndex: 1 }}>
							{
								dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].col && dummy_modal_json_map[activeTab].col.length > 0 ?
									dummy_modal_json_map[activeTab].col.map((col, idx) =>
										<ITH key={idx} style={{ textTransform: "capitalize" }}><div style={{display: 'flex', alignItems: 'center'}}>{col.type === 'checkbox' ? <Checkbox className='mr-2' indeterminate={is_indeterminate(col.id)} checked={checkAll(col.id)} onClick={(e) => { onCheckAllChange(e, col.id) }}></Checkbox> : ''}<div>{col.name}</div></div></ITH>
									)
									: <ITH>No Items</ITH>
							}
						</ITableHeader>
						<ITableBody>
							{
								dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].units && dummy_modal_json_map[activeTab].units.length > 0 && dummy_modal_json_map[activeTab].units.map((unit, idx) => (
									<ITableRow key={idx}>
										{
											dummy_modal_json_map[activeTab].col && dummy_modal_json_map[activeTab].col.length > 0 &&
											dummy_modal_json_map[activeTab].col.map((col, idx) =>
												<ITD key={idx}>
													{col.type === 'checkbox' ? <Checkbox_input_for_restore_modal unit={unit} col={col} reRenderModal={reRenderModal} /> : ''}
													{col.type === 'number' || col.type === 'text' ? unit[col.id] : ''}
													{col.type === 'image' ? <img style={{ color: COLORS.white, maxHeight: '100%', maxWidth: '100%', height: "auto", width: '130px' }} title={unit[col.id] ? unit[col.id] : ''} src={unit[col.id] ? unit[col.id] : "-"} /> : ''}
													{col.type === 'multi_data' ?
														<React.Fragment>
															{
																unit[col.id] && unit[col.id].length && unit[col.id].map((x, idx) => (
																	<div key={idx}>{x}</div>
																))
															}
														</React.Fragment>
														: ''
													}
												</ITD>
											)
										}
									</ITableRow>
								))
							}
						</ITableBody>
					</ITable>
				</div>
				<Card style={{flex: 1, minWidth: '0px', overflow: 'auto', borderRadius: '4px'}}>
				{ 
					default_cam_structure && default_cam_structure.length ? default_cam_structure.map((preference) => (
						preference.id == /* "kdt_nc" */ activeSettingId?
						<>
						{
							preference.type === 'parent' ? 
							<ParentPreference parentPreference={preference} full_name={preference.id} camSettings={camSettings} camPreferences={camPreferences} defaultState={true}/>
							:
							<LeafPreference leafPreference={preference} full_name={preference.id} camSettings={camSettings} camPreferences={camPreferences}/>
						}
						</>:''
					))
					:''
				}
				</Card>
			</IModalBody>
			<IModalFooter>
				<Button className='secondary_button_default' onClick={onclick_handleclose}>Close</Button>
				{
					modal_json && modal_json.action_buttons && modal_json.action_buttons.map(button => (
						<Button className={(button.type == undefined || button.type == '' || button.type == 'primary_button_default')? 'primary_button_default' : button.type} onClick={(e) => handleButtonSubmit(e, button)}>{button.name}</Button>
					 ))
				}
				<Button className='primary_button_default' onClick={updateCam}>Update CAM Settings</Button>
			</IModalFooter>
		</Modal>
	)
}

const Snap_Settings = ({  modal_type, handle_close,  modal_json, handle_ui_response, update_modal_json }) => {
	const [ snap_settings, set_snap_settings ] = useState({});

	useEffect(() => {
		if(modal_type == "snap_settings" && modal_json){
			set_snap_settings(JSON.parse(JSON.stringify(modal_json))); 
		}
	},[modal_json, modal_type])

	const submit = async () => {
		var resp = await window.Promisify(window.Module["apply_snap_settings"](JSON.stringify(snap_settings)));
		handle_ui_response(resp);
	}

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="snap_settings"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Snap Settings</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style={{backgroundColor: COLORS.white}}>
					<Row style={{paddingLeft:20,textAlign:"left"}}>
						<Col xs={1} >
							<Input checked={snap_settings&&snap_settings.enabled} onChange={(e) => {set_snap_settings({...snap_settings, enabled: e.target.checked})}} type="checkbox"/>
						</Col>
						<Col xs={11} >
							Snapping Enabled by default (shift key inverts default state)
						</Col>
						<br />
						<br />
						<Col xs={12} style={{fontWeight:"bold"}}  >
							Object Snapping
						</Col>
						<br />
						<Col xs={1} >
							<Input checked={snap_settings&&snap_settings.endpoints} onChange={(e) => {set_snap_settings({...snap_settings, endpoints: e.target.checked})}} type="checkbox"/>
						</Col>
						<Col xs={5}  >
							Endpoints
						</Col>
						<Col xs={1} >
							<Input checked={snap_settings&&snap_settings.auto_rotate} onChange={(e) => {set_snap_settings({...snap_settings, auto_rotate: e.target.checked})}} type="checkbox"/>
						</Col>
						<Col xs={5}  >
							Auto Rotate furniture along walls
						</Col>
						<Col xs={1} >
							<Input checked={snap_settings&&snap_settings.horizontal} onChange={(e) => {set_snap_settings({...snap_settings, horizontal: e.target.checked})}} type="checkbox"/>
						</Col>
						<Col xs={5}  >
							Horizontal
						</Col>
						<Col xs={1} >
							<Input checked={snap_settings&&snap_settings.vertical} onChange={(e) => {set_snap_settings({...snap_settings, vertical: e.target.checked})}} type="checkbox"/>
						</Col>
						<Col xs={5}  >
							Vertical
						</Col>
						<Col xs={1} >
							<Input checked={snap_settings&&snap_settings.intersections} onChange={(e) => {set_snap_settings({...snap_settings, intersections: e.target.checked})}} type="checkbox"/>
						</Col>
						<Col xs={5}  >
							Intersections
						</Col>
						<Col xs={1} >
							<Input checked={snap_settings&&snap_settings.midpoints} onChange={(e) => {set_snap_settings({...snap_settings, midpoints: e.target.checked})}} type="checkbox"/>
						</Col>
						<Col xs={5}  >
							Midpoints
						</Col>
						<Col xs={1} >
							<Input checked={snap_settings&&snap_settings.centers} onChange={(e) => {set_snap_settings({...snap_settings, centers: e.target.checked})}} type="checkbox"/>
						</Col>
						<Col xs={5}  >
							Centers (Arcs)
						</Col>
						<Col xs={1} >
							<Input checked={snap_settings&&snap_settings.nearest} onChange={(e) => {set_snap_settings({...snap_settings, nearest: e.target.checked})}} type="checkbox"/>
						</Col>
						<Col xs={5}  >
							Nearest point on line/arc
						</Col>
						<Col xs={1} >
							<Input checked={snap_settings&&snap_settings.perpendicular} onChange={(e) => {set_snap_settings({...snap_settings, perpendicular: e.target.checked})}} type="checkbox"/>
						</Col>
						<Col xs={5}  >
							Perpendicular
						</Col>
					</Row>
					<Row style={{marginTop:'10px'}}>
						<Col xs={6}  >
							Snap search distance (mm)
						</Col>
						<Col xs={6} >
							<Input value={snap_settings&&snap_settings.snap_search_distance!=undefined?snap_settings.snap_search_distance:100} onChange={(e) => {set_snap_settings({...snap_settings, snap_search_distance: Number(e.target.value)})}} type="number"/>
						</Col>
					</Row>
					<Row style={{marginTop:'10px'}}>
						<Col xs={6}  >
							Wall Elevation View valid distance (mm)
						</Col>
						<Col xs={6} >
							<Input value={snap_settings&&snap_settings.wall_elevation_view_valid_distance!=undefined?snap_settings.wall_elevation_view_valid_distance:100} onChange={(e) => {set_snap_settings({...snap_settings, wall_elevation_view_valid_distance: Number(e.target.value)})}} type="number"/>
						</Col>
					</Row>
					<Row style={{marginTop:'20px'}}>
						<Col xs={12} style={{textAlign:"center"}} >
							<Button onClick={submit} className='primary_button_default' type="button">Apply</Button>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Modal>
	) 
}

const Merge_Conflict_modal = ({  modal_type, handle_close,  modal_json, handle_ui_response, update_modal_json }) => {
	const [ conflicting_names, set_conflicting_names ] = useState([]);
	
	useEffect(() => {
		if(modal_type=="merge_conflict" && modal_json && modal_json.conflicting_components_names){
			set_conflicting_names(modal_json.conflicting_components_names);
		}
	},[modal_json, modal_type]);

	const handle_merge = (strategy) => {
		window.Module.resolve_conflicts(strategy,JSON.stringify(modal_json));

		update_modal_json();
	}

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="merge_conflict"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Merge Conflict</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style={{backgroundColor: COLORS.white}}>
					<Row style={{marginBottom:30,fontWeight:'bold'}}>
						<Col xs={12} >
							There are merge conflicts in the following components. Please select a merge strategy.
						</Col>
					</Row>
					<Row style={{marginBottom:30,fontWeight:'bold'}}>
						{conflicting_names.map((o,idx) => (
							<Col key={idx} xs={12} style={{textAlign:'center'}} >
								{o.count + "x " + o.name}
							</Col>
						))}
					</Row>
					<Row>
						<Col xs={6} style={{textAlign:'center'}}>
							<Button onClick={() => handle_merge("ours")} style={{float: 'right'}} className="btn btn-default">{"Prioritize " + (modal_json && modal_json.to_branch_name?modal_json.to_branch_name:"into")}</Button>
						</Col>
						<Col xs={6} style={{textAlign:'center'}}>
							<Button onClick={() => handle_merge("theirs")} style={{float: 'left'}} className="btn btn-default">{"Prioritize " + (modal_json && modal_json.from_branch_name?modal_json.from_branch_name:"from")}</Button>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Modal>
	)    
}


const Add_tile = ({  modal_type, handle_close,  modal_json }) => {
	const add_tile_onclick = (x) => {
		if(window.debug_mode) {
			window.add_debug_log("set_tile_pattern(\"" + x.id + "\");");
		}
		window.Module.set_tile_pattern(x.id);
		handle_close();
	}
	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="add_tile"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Tile Patterns</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style={{backgroundColor: COLORS.white}}>
					<Row style={{margin:0}}>
						{
							modal_json && modal_json.length && modal_json.map((x,idx) => 	
								<Col key={idx} xs={4} style={{padding:'15px', cursor:'pointer'}} onClick={() => {add_tile_onclick(x)}}>
									<img style={{height:'100%', width:'100%', border:'1px solid black'}} src={x.url? x.url:''}/>
								</Col>  
							)
						}  
					</Row>
				</CardBody>
			</Card>
		</Modal>
	)    
}

const Change_tile_size = ({ modal_type, handle_close,  modal_json }) => {
	const [ height, set_height ] = useState(10);
	const [ width, set_width ] = useState(10);
	const [ depth, set_depth ] = useState(10);
	const [ value, set_value ] = useState(false);
	const [ url, set_url ] = useState(false);

	useEffect(() => {
		if(modal_type == "change_tile_size" && modal_json){
			set_depth(modal_json.depth ? modal_json.depth:0)
			set_width(modal_json.width ? modal_json.width:0)
			set_height(modal_json.height ? modal_json.height:0)

			if(modal_json.tile_pattern){	
				if(modal_json.tile_pattern=='linear'){
					set_url('/resources/images/tiling_patterns/linear_size.png')
				}
				if(modal_json.tile_pattern=='checker'){
					set_url('/resources/images/tiling_patterns/checker_size.png')
				}
				if(modal_json.tile_pattern=='brick_bond'){
					set_url('/resources/images/tiling_patterns/brick_bond_size.png')
				}
				if(modal_json.tile_pattern=='basket_weave_2'){
					set_url('/resources/images/tiling_patterns/basket_weave_2_size.png')
				}
				if(modal_json.tile_pattern=='basket_weave_3'){
					set_url('/resources/images/tiling_patterns/basket_weave_3_size.png')
				}
				if(modal_json.tile_pattern=='herringbone'){
					set_url('/resources/images/tiling_patterns/herringbone_size.png')
				}
				if(modal_json.tile_pattern=='hexagon'){
					set_url('/resources/images/tiling_patterns/hexagon_size.png')
				}
				if(modal_json.tile_pattern=='diamond'){
					set_url('/resources/images/tiling_patterns/diamond_size.png')
				}
			}
		}
	},[modal_json, modal_type])

	useEffect(() => {

		if(modal_json && modal_json.tile_pattern && value && height>=0 && width>=0 && depth>0){
			if(modal_json.tile_pattern=='basket_weave_2' || modal_json.tile_pattern=='herringbone'){
				set_value(false)
				set_width(2*height)
			}
			if(modal_json.tile_pattern=='basket_weave_3'){
				set_value(false)
				set_width(3*height)
			}
		}	

	},[height])

	useEffect(() => {

		if(modal_json && modal_json.tile_pattern && value && height>=0 && width>=0 && depth>0){
			if(modal_json.tile_pattern=='basket_weave_2' || modal_json.tile_pattern=='herringbone'){
				set_value(false)
				set_height(width/2)
			}
			if(modal_json.tile_pattern=='basket_weave_3'){
				set_value(false)
				set_height(width/3)
			}
		}	
		
	},[width])

	const set_tile_sizes = () => {

		if(window.debug_mode) {
			window.add_debug_log("set_tile_size(" + Number(document.getElementById("tile_size_height").value) + ", " + Number(document.getElementById("tile_size_width").value) + ");");
		}
		window.Module.set_tile_size(
			Number(document.getElementById("tile_size_height").value),
			Number(document.getElementById("tile_size_width").value)
		);

		handle_close();
	}

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="change_tile_size"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Tile Size</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white }} className="px-lg-5 py-lg-5">
					<Row>
						<Col>
							<div style={{textAlign:'center'}}>
								<img style={{width:'200px', height:'200px'}} src={url?url:''} />
							</div>
							<Row style={{padding:'40px 0px 0px', margin:0}}>
								<Col xs={3}>
									<span style={{color:'#BDBDBD'}}>Width (mm)</span>
									<input id="tile_size_width" onChange={(e) => {set_width(e.target.value), set_value(true)}} value={width} style={{height:'25px', width:'100%'}} type='text' />
								</Col>
								<Col xs={3}>
									<span style={{color:'#BDBDBD'}}>Height (mm)</span>
									<input id="tile_size_height" onChange={(e) => {set_height(e.target.value), set_value(true)}} value={height} style={{height:'25px', width:'100%'}} type='text' />
								</Col>
								{/* <Col xs={3}>
									<span style={{color:'#BDBDBD'}}> Depth (mm)</span>
									<input id="tile_size_depth" onChange={(e) => set_depth(e.target.value)} value={depth} style={{height:'25px', width:'100%'}} type='text' />
								</Col> */}
								<Col xs={3} style={{ display:'flex', alignItems:'center', justifyContent:"center"}}>
									<b className='layer_modal_button_hover' style={{cursor:"pointer", padding:'5px 10px'}} onClick={set_tile_sizes}>SET</b>
								</Col>
							</Row>
						</Col>  
					</Row>
				</CardBody>
			</Card>
		</Modal>
	)    
}

const Create_branch_modal = ({open, handle_close,create_branch_onclick}) => {
    const {t} = useTranslation()
	const submit_values = () => {
		create_branch_onclick(document.getElementById("create_branch_name").value,"");
	}

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open}>
				{/* <CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Create Branch</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader> */}
				<IModalHeader toggle={handle_close}>Create Branch</IModalHeader>
				{/* <CardBody style = {{backgroundColor: COLORS.white }} className="px-lg-5 py-lg-5">
					<Row>
						<Col>
							<div>Name</div>
							<Input id="create_branch_name" type='text' />
						</Col>  
					</Row>
					<div className="text-right" style={{marginTop:"15px"}}>
						<Button onClick={submit_values} className='blue_button' type="button">Create Branch</Button>&nbsp;
					</div>
				</CardBody> */}
				<IModalBody>
					<div className='flex_property'>
						<div className='mr-3'>{t(`Name`)}</div>
						<Input id="create_branch_name" type='text' />
					</div>
				</IModalBody>
				<IModalFooter>
					<Button onClick={submit_values} className='primary_button_default' type="button">Create Branch</Button>&nbsp;
				</IModalFooter>
		</Modal>
	)    
}

const Share_branch_modal = ({open, handle_close,share_branch_onclick, branch_id_share_branch}) => {
	const [selected_users, set_selected_users] = useState([])
	const [users, set_users] = useState([])
	const [role, set_role] = useState("write")
	const alert = useAlert();
    const {t} = useTranslation()

	useEffect(() => {
		if(open){
			window.Promisify(window.Module.get_share_users(branch_id_share_branch))
			.then(users_string => {
				set_users(JSON.parse(users_string))
				set_selected_users(JSON.parse(users_string).filter(o=> o.is_selected && o.is_selected==true))
			})
		}
	}, [open])

	const submit_values = () => {
		var user_data = []
		for(var i=0;i<selected_users.length;i++){
			user_data.push({
				user_id: selected_users[i].id,
				role: role
			})
		}

		// if(user_data.length>0){
			share_branch_onclick(user_data);
		// }else{
			// alert.error("Select atleast one user to share to")
		// }
	}

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Share Branch</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white }} className="px-lg-5 py-lg-5">
					<Row>
						<Col xs={12}>
							<div>{t(`Name`)}</div>
							<Typeahead
								multiple
								id="share_branch_typeahead"
								onChange={(selected) => {
									set_selected_users(selected);
									// console.log(selected)
								}}
								options={users}
								selected={selected_users}
							/>
						</Col>  
					</Row>
					<div className="text-right">
						<Button onClick={submit_values} className="mt-4" color={COLORS.button_primary} type="button">Share Branch</Button>&nbsp;
					</div>
				</CardBody>
			</Card>
		</Modal>
	)    
}


const Design_Revert_Modal = ({  org_key, modal_type, handle_close,  modal_json, update_modal_json,handle_ui_response, set_page_loader, submit_for_delete_confirm}) => {
	const alert = useAlert();

	useEffect(() => {
		if(modal_type == "design_revert_modal" && modal_json){
		}
	}, [modal_json, modal_type])

	const get_check_url = (id) => {
		var url = window.Module.API.react_app_path;
		(org_key&&window.location.origin.includes("infurnia.com")?(url = url+'/'+org_key):null);
		url += ("/design-version/" + id);

		return url;
	}

	const revert_design = (version) => {
		submit_for_delete_confirm(async ()=>{
			var resp = await window.Promisify(window.Module.design_revert(version.id));

			if(resp == "success"){
				alert.success("Design reverted successfully. Reloading");
				setTimeout(() => {
					window.location.reload();
				},500)
			}else{
				alert.error("Could not revert design. Please check if you have design revert access and try again.")
			}

		}, ()=>{}, 'Are you sure you want to revert to \"' + version.name + ' (' + timeSince(version.updated_at) + ')\"', {name: "Revert"})
	}
	

	return(
		<Modal className="modal-dialog-centered" size="lg" style={{overflow:'auto'}} isOpen={modal_type=="design_revert_modal"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Design Revert</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white, fontSize:'13px', maxHeight:'85vh', overflowY:'auto' }}  className="px-lg-4 py-lg-4">
				<table style={{width:'100%'}}>
						<thead>
							<tr style={{height:'35px', fontSize:'13px', width:'100%', display:'flex' , alignItems:'center', borderBottom:'1px solid black'}}>
								<th style={{paddingLeft:'10px', flex:7}}><span>Version Name</span></th>
								<th style={{flex:3}}><span>Timeline</span></th>
								<th style={{flex:1}}></th>
								<th style={{flex:1}}></th>
							</tr>
						</thead>
						<tbody style={{display:'table', minHeight:'280px', maxHeight:'500px', overflow:'auto', width:'100%'}}>
							{
								modal_json.versions && modal_json.versions.map((version,idx) => (
								<tr key={idx} style={{backgroundColor:'none',height:'35px', fontSize:'13px', width:'100%', display:'flex', alignItems:'center', display:'flex', borderBottom:'1px solid black'}}>
									<td style={{paddingLeft:'10px',flex:7}}>{version.name}</td>
									<td style={{flex:3}}>{timeSince(version.updated_at)}</td>
									<td style={{flex:1}}>
										<a href={get_check_url(version.id)} style={{maxHeight:'100%', maxWidth:'100%',margin:'auto'}} target='black'>Check</a>
									</td>
									<td style={{flex:1}}>
										<a type="button" style={{color:"#007BFF" ,cursor:'pointer'}} onClick={() => revert_design(version)}>Revert</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</CardBody>
			</Card>
		</Modal>
	)    
}

const Confirm_Modal_Internal = ({  open, handle_close, heading, message, primary_button_text='Accept', secondary_button_text='Cancel', primary_button_on_click, secondary_button_on_click}) => {

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open}>
			<IModalHeader toggle={handle_close}>{heading}</IModalHeader>
			<IModalBody style={{backgroundColor:'#FFFFFF', borderBottomRightRadius: '4px', borderBottomLeftRadius: '4px', padding:'36px 16px'}}>
				<div style={{textAlign:'center'}}>{message?message:''}</div>
				<div style={{paddingTop:"24px", display:'flex', justifyContent:'center'}}>
					{secondary_button_on_click ? <Button onClick={secondary_button_on_click} className='secondary_button_default mr-3' >{secondary_button_text}</Button> : ''}
					{primary_button_on_click ? <Button onClick={primary_button_on_click} className='primary_button_default' >{primary_button_text}</Button> : ''}
				</div>
			</IModalBody>
		</Modal>
	)    
}

const VersionNameChange = (open, handle_close, version_id) => {
	const [name, set_name] = useState('');
    const {t} = useTranslation()

	const submit_values = () => {

	}
	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open}>
			<IModalHeader toggle={handle_close}>Invite Designer</IModalHeader>
			<IModalBody>
				<div className='flex_property mb-3'>
					<div className='mr-3'>{t(`Name`)}</div>
					<Input
						onChange={(e) => {
							set_name(e.target.value);
							// console.log(selected)
						}}
						style={{...styles_modals.input_styles}}
					/>
					{/* <Select
						isMulti
						id="share_branch_typeahead"
						onChange={handleOnChange}
						options={users}
						value={selected_users}
					/> */}
				</div>
				<div className='flex_property justify-content-between'>
					{/* <div className='flex_property'>
						<div className='mr-3'>Access</div>
						<Input style={{ ...styles_modals.input_styles, padding: '4px', width: 'fit-content' }} value={role} onChange={(e) => { set_role(e.target.value) }} type="select">
							<option value={"read"}>View</option>
							<option value={"write"}>Edit</option>
						</Input>
					</div> */}
					<Button onClick={submit_values} className="primary_button_default" type="button">Rename</Button>
				</div>
			</IModalBody>
		</Modal>
	)
}

const VersionRow = ({version, active_version_id, get_version_dropdown_options_tab_2, set_page_loader, handle_ui_response, index}) => {
	
	const [edit_mode, set_edit_mode] = useState(false);
	const [version_name, set_version_name] = useState("");

	const open_editing_mode = () => {
		set_edit_mode(true)
	}

	useEffect(() => {
		if(edit_mode && document.getElementById('version_name_input')){
			document.getElementById('version_name_input').focus()
		}
	}, [edit_mode]);

	const close_editing_mode = () => {
		set_edit_mode(false)
	}

	const rename_version = async (version_id,label) => {
		set_page_loader({
            show: true,
            text: 'Please Wait'
        }, async() => {
			var resp = await window.Promisify(window.Module.update_version_label(version_id,label));
			handle_ui_response(resp)
			set_page_loader({
				show: false,
				text: 'Please Wait'
			});
		});
	}

	useEffect(() => {
		if(version){
			set_version_name(version.tag);
		}
	}, [version]);

	return(
		<tr className='show_on_hover' style={{ backgroundColor: active_version_id == version.id ? '#ccf4e3' : 'none', height: '36px', fontSize: '14px', width: '100%', display: 'flex', alignItems: 'center', display: 'flex', borderBottom: '1px solid black' }}>
			<td style={{ paddingLeft: '10px', flex: 8 }}>
				{
					edit_mode ?
						<div style={{background:'white', border: '1px solid #c5c7cf', padding:'0px 12px', width: '90%'}}>
							<Input id='version_name_input' style={{ ...styles_modals.input_styles_none }} value={version_name} onChange={(e) => set_version_name(e.target.value)} onBlur={(e) => {if(e.target.value != version.tag){rename_version(version.id,e.target.value)} close_editing_mode()}} onKeyDown={(e) => { if (e.key === 'Enter') {e.target.blur() } else if (e.key == 'Escape') {  set_version_name(version.tag); e.target.blur() } }}/>
						</div>
						:
						<div className='flex_property'>
							<img className='mr-3 invisible_icon' style={{ cursor: 'pointer' }} src='/resources/icons/pencil.svg' height={'16px'} onClick={open_editing_mode}/>
							<div style={{ flex: 1 }}>{version.tag ? version.tag : '-'}</div>
						</div>
				}
			</td>
			<td style={{ paddingLeft: '10px', flex: 8 }}>{version.name}</td>
			<td style={{ flex: 3 }}>{timeSince(version.created_at)}</td>
			<td style={{ flex: 1 }}>
				<Generic_Dropdown
					display_jsx={(<img src='/resources/nav_icons_new/settings 16px.svg' />)}
					// display_jsx={(<i style={{fontSize:'25px', color:'#676878'}} className='fa fa-cog'/>)} 
					options={get_version_dropdown_options_tab_2(version,index)}
				/>
			</td>
		</tr>
	)
}

const Version_modal = ({  org_key, modal_type, handle_close,  modal_json, update_modal_json,handle_ui_response, set_page_loader}) => {
	const [tabs , set_tabs] = useState(1);
	const [open_create , set_open_create] = useState(false);
	const [open_share , set_open_share] = useState(false);
	const [version_id_create_branch , set_version_id_create_branch ] = useState("");
	const [version_id_restore_branch, set_version_id_restore_branch] = useState("");
	const [branch_id_share_branch , set_branch_id_share_branch ] = useState("");
	const [open_restore, set_open_restore] = useState(false);
	const [acive_branch_versions, set_acive_branch_versions] = useState([]);
	const toggle_restore = () => set_open_restore(!open_restore)

	const [updated_at_filter, set_updated_at_filter] = useState(-1);
	const toggle_updated_at = () => {if(updated_at_filter == 1){set_updated_at_filter(-1)}else{set_updated_at_filter(1)}}
	const [name_filter, set_name_filter] = useState(1);
	const toggle_name_filter = () => {if(name_filter == 1){set_name_filter(-1)}else{set_name_filter(1)}}
    const {t} = useTranslation()

	const tabs_list = [
		{
			id: 1,
			name: "All Branches"
		},
		{
			id: 2,
			name: "Active Branch"
		}
	]

	useEffect(() => {
		if(modal_type == "version_modal" && modal_json && modal_json.node_connections){
			setTimeout(() => {
				versioningGraph(35,35,modal_json.node_connections)
			},20);
			set_acive_branch_versions(modal_json.active_branch && modal_json.active_branch.design_version ? modal_json.active_branch.design_version : [])
		}
	}, [modal_json, modal_type])

	const onclick_sorting_arrows = (name, order, type) => {
		console.log("sorted versions", acive_branch_versions.sort((a,b) => generic_sort(a,b,name,order, type)))
		set_acive_branch_versions(JSON.parse(JSON.stringify(acive_branch_versions.sort((a,b) => generic_sort(a,b,name,order, type)))))
	}

	useEffect(() => {
		console.log("versions updated order", acive_branch_versions)
	}, [acive_branch_versions]);
	
	const toggleNavs = (index) => {
		// e.preventDefault();
		set_tabs(index)
	};

	const open_create_modal = () => {
		set_open_create(true)
	}

	const close_create_modal = () => {
		set_open_create(false)
	}

	const open_share_modal = () => {
		set_open_share(true)
	}

	const close_share_modal = () => {
		set_open_share(false)
	}

	const get_branch_name_by_branch_id = (branch_id) => {
		if(modal_json.design_branches){
			var branch = modal_json.design_branches.find(function(o){return o.id == branch_id})
			if(branch){
				return branch.name
			}else{
				return ''
			}
		}
	}

	// const get_branch_label_by_branch_id = (branch_id) => {
	// 	if(modal_json.design_branches){
	// 		var branch = modal_json.design_branches.find(function(o){return o.id == branch_id})
	// 		if(branch){
	// 			return branch.label ? branch.label : ''
	// 		}else{
	// 			return ''
	// 		}
	// 	}
	// }


	const versioningGraph = (row_height,col_width,data) =>{
		try{
			if(data.nodes.length == 0){
				return
			}
			
			var canvas = document.getElementById('versioning_canvas');
	
			if(!canvas){
				return
			}
			var context = canvas.getContext('2d');
	
			canvas.width = (window._.maxBy(data.nodes,function(o){
				return o.col
			}).col+1)*col_width;
			canvas.height = (window._.maxBy(data.nodes,function(o){
				return o.row
			}).row+1)*row_height;
			context.clearRect(0, 0, canvas.width, canvas.height);
	
			for (var i = 0; i < data.connections.length; i++) {
				context.strokeStyle = data.connections[i][3]||'#4d4d4d'
				if(data.connections[i][2]){
					context.setLineDash([4,2])
				}else{
					context.setLineDash([])
				}
				context.lineWidth = 2
	
				var index1 = data.connections[i][0]; var index2 = data.connections[i][1];
				var point1 = {x:(data.nodes[index1].col + 0.5)*col_width,y:canvas.height-(data.nodes[index1].row + 0.5)*row_height}
				var point2 = {x:(data.nodes[index2].col + 0.5)*col_width,y:canvas.height-(data.nodes[index2].row + 0.5)*row_height}
				  
				context.beginPath();      
				context.moveTo(point1.x, point1.y);  
	
				var point_middle
				if(point1.y > point2.y){
					point_middle = {x:point2.x,y:point1.y-(row_height)}
					var cp1 = {x: point1.x,y: point1.y - (row_height)}
					var cp2 = {x: point_middle.x,y: point_middle.y + (row_height)};
					context.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, point_middle.x, point_middle.y);
					context.lineTo(point2.x, point2.y);
				}else{
					point_middle = {x:point1.x,y:point2.y-(row_height)}
					var cp1 = {x: point2.x,y: point2.y - (row_height)}
					var cp2 = {x: point_middle.x,y: point_middle.y + (row_height)}
					context.lineTo(point_middle.x, point_middle.y);
					context.bezierCurveTo(cp2.x, cp2.y, cp1.x, cp1.y, point2.x, point2.y);
				}
	
				context.stroke();
			}
	
			context.setLineDash([])
	
			var master_color = '#000000'
	
			for (var i = 0; i < data.nodes.length; i++) {
				var strokeColor = data.nodes[i].color?data.nodes[i].color:'#89b1d3'
				var fillColor = strokeColor
				if(data.nodes[i].col==0){
					master_color = strokeColor
				}
	
				var max_node = window._.maxBy(data.nodes,function(o){
					return o.col==data.nodes[i].col?o.row:-1
				})
	
				if(data.nodes[i] == max_node){
					fillColor = '#ffffff'
				}
	
				drawCircle(
					(data.nodes[i].col + 0.5)*col_width,canvas.height-(data.nodes[i].row + 0.5)*row_height,
					5,true, fillColor,true,strokeColor,1
				)
			}
	
	
			context.strokeStyle = master_color
			context.lineWidth = 2
			context.beginPath();
			context.moveTo(0.5*col_width,canvas.height-(0.5*row_height));
			context.lineTo(0.5*col_width,canvas.height-(0.1*row_height));
			context.moveTo(0.3*col_width,canvas.height-(0.1*row_height));
			context.lineTo(0.7*col_width,canvas.height-(0.1*row_height));
			context.stroke()
			context.lineWidth = 1
	
	
	
			function drawCircle(centerX, centerY, radius,fill, fillColor,stroke, strokeColor, strokeWidth) {
				context.beginPath();
				context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
				context.fillStyle = fillColor;
				if(fill){
					context.fill();
				}
				if(stroke){
					context.lineWidth = strokeWidth;
					context.strokeStyle = strokeColor;
					context.stroke();
				}
			}
		}catch(err){
			// console.error(err);
		}
	}

	const create_branch_default = () => {
		set_version_id_create_branch("");
		open_create_modal();
	}
	
	const create_branch_from_dropdown = (id) => {
		var active_design = window.Module.get_active_design();
		if(active_design.locked){
			handle_ui_response(JSON.stringify({"toast":{"message":"Cannot Create Branch for locked design","type":"info"}}))
		}else{
		var version_id = id.split("create_")[1];
		set_version_id_create_branch(version_id);
		open_create_modal();
		}
		active_design.delete();
	}

	const restore_branch_from_dropdown = (id) => {
		var active_design = window.Module.get_active_design();
		if(active_design.locked){
			handle_ui_response(JSON.stringify({"toast":{"message":"Cannot Create Branch for locked design","type":"info"}}))
		}else{
		var version_id = id.split("restore_")[1];
		set_version_id_restore_branch(version_id);
		toggle_restore();
		}
		active_design.delete();
	}

	const create_branch_onclick = async (branch_name, branch_comment) => {
		set_page_loader({
            show: true,
            text: 'Please Wait'
        },async () => {
			//await dummy_tick(0);
			var ui_response = await window.Promisify(window.Module.create_branch(version_id_create_branch, branch_name, branch_comment));
			var parsed_response = JSON.parse(ui_response);
			console.log(parsed_response)
			if(parsed_response.constructor.name == "String"){
				var url = window.Module.API.react_app_path;
				(org_key&&window.location.origin.includes("infurnia.com")?(url = url+'/'+org_key):null);
				url += ("/design/" + parsed_response);
				window.location.href = url;
			}else if(parsed_response.toast){
				handle_ui_response(ui_response);
			}

			close_create_modal();
			update_modal_json();
            set_page_loader({
                show: false,
                text: 'Please Wait'
            })
		})
	}

	const share_branch_from_dropdown = (id) => {
		var branch_id = id.split("share_")[1];
		set_branch_id_share_branch(branch_id);
		window.Module.open_share_branch_modal(branch_id);
		update_modal_json();
		// open_share_modal();
	}

	const share_branch_onclick = async (user_data) => {
		set_page_loader({
            show: true,
            text: 'Please Wait'
        },async () => {
			//await dummy_tick(0);
            var ui_response = await window.Promisify(window.Module.share_branch(branch_id_share_branch, JSON.stringify(user_data)));
			handle_ui_response(ui_response);
			update_modal_json();
            set_page_loader({
                show: false,
                text: 'Please Wait'
            })
        })
	}

	const switch_branch_onclick = async (id) => {
		set_page_loader({
            show: true,
            text: 'Please Wait'
        },async () => {
			//await dummy_tick(0);
			var branch_id = id.split("switch_")[1];
			var ui_response = await window.Promisify(window.Module.checkout_branch(branch_id));

			var parsed_response = JSON.parse(ui_response);
			console.log(parsed_response)
			if(parsed_response.constructor.name == "String"){
				var url = window.Module.API.react_app_path;
				(org_key&&window.location.origin.includes("infurnia.com")?(url = url+'/'+org_key):null);
				url += ("/design/" + parsed_response);
				window.location.href = url;
			}else if(parsed_response.toast){
				handle_ui_response(ui_response);
			}

			update_modal_json();
            set_page_loader({
                show: false,
                text: 'Please Wait'
            })
		})
		
	}

	const merge_branch_onclick = (id) => {
		set_page_loader({
            show: true,
            text: 'Please Wait'
        },async () => {
			//await dummy_tick(0);
			var version_id = id.split("merge_")[1];
			var ui_response = await window.Promisify(window.Module.merge_into_active_branch(version_id));
			// handle_ui_response(ui_response);
			update_modal_json();
            set_page_loader({
                show: false,
                text: 'Please Wait'
            })
		})
	}

	const get_version_dropdown_options_tab_1 = (version) => {
		var options = []
		if(window.Module.check_permission(version.design_branch_id)=='write'){
			options.push({id:"share_"+version.design_branch_id,name:"Share",onClick:share_branch_from_dropdown})
		}
		
		options.push({id:"create_"+version.id,name:"Create a branch from here",onClick:create_branch_from_dropdown});

		if(modal_json.active_branch && version.design_branch_id != modal_json.active_branch.id && window.Module.check_permission(version.design_branch_id)=='write'){
			options.push({id:"switch_"+version.design_branch_id,name:"Switch to this branch",onClick:switch_branch_onclick})
		}

		if(/*window.Module && window.Module.API.application_env != "production" && */modal_json.active_branch && version.design_branch_id != modal_json.active_branch.id){
			options.push({id:"merge_" + version.id, name:"Merge into active branch", onClick:merge_branch_onclick})
			//merge into active branch
		}

		return options;
	}

	const get_version_dropdown_options_tab_2 = (version, index) => {
		var options = []
		// options.push({id:"rename_"+version.id,name:"Rename this Version",onClick:create_branch_from_dropdown})
		if(index!=0){
			options.push({id:"restore_"+version.id,name:"Restore this Version",onClick:restore_branch_from_dropdown})
		}
		options.push({id:"create_"+version.id,name:"Create a branch from here",onClick:create_branch_from_dropdown})
		return options;
	} 

	const revert_version = async() => {
		var resp = await window.Promisify(window.Module.design_revert(version_id_restore_branch));

		if (resp == "success") {
			handle_ui_response(JSON.stringify({"toast":{"message":"Design reverted successfully. Reloading","type":"info"}}))
			setTimeout(() => {
				window.location.reload();
			}, 500)
		} else {
			handle_ui_response(JSON.stringify({"toast":{"message":"Could not revert design. Please check if you have design revert access and try again.","type":"error"}}))
		}
		toggle_restore()
	}

	return(
		<Modal className="modal-dialog-centered" style={{height:'400px'}} size="lg" isOpen={modal_type=="version_modal"}>
			<Create_branch_modal create_branch_onclick={create_branch_onclick} open={open_create} handle_close={close_create_modal}/>
			<Share_branch_modal share_branch_onclick={share_branch_onclick} open={open_share} handle_close={close_share_modal} branch_id_share_branch={branch_id_share_branch}/>
			{/* <Confirm_Modal_Internal open={open_restore} handle_close={toggle_restore} heading={"Restore this Version?"} message={`Your current design will restore to version ${version_id_restore_branch}`} primary_button_on_click={revert_version} secondary_button_on_click={toggle_restore} primary_button_text='Restore' /> */}
			<Confirm_Modal_Internal open={open_restore} handle_close={toggle_restore} heading={"Restore this Version?"} message={`Are you sure you want to restore this version. It is an irrevertible action.`} primary_button_on_click={revert_version} secondary_button_on_click={toggle_restore} primary_button_text='Restore' />
			{/* <Row style={{margin:0, height:'40px', border:'1px solid #d9d9d9'}}>
				<Col xs={6} style={{display:'flex', alignItems:'center', fontSize:'18px'}}>
					<span>Versions & History</span>
				</Col>
				<Col xs={4} style={{display:'flex', alignItems:'center', fontSize:'12px',color:'#4caf50', fontWeight:'500'}}>
					<span onClick={create_branch_default} style={{cursor:'pointer'}}>CREATE BRANCH</span>
				</Col>
				<Col xs={2} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
					<span onClick={handle_close}><i style={{fontSize:'16px',cursor:'pointer'}} className='fa fa-times'/></span>
				</Col>
			</Row> */}
			<IModalHeader toggle={handle_close} style_children={{display: 'flex', alignItems:'center', flex: 1}}>
				<div style={{flex: 1}}>{t(`Versions & History`)}</div>
				<div onClick={create_branch_default} style={{cursor:'pointer', fontSize:'12px',color:COLORS.blue6, fontWeight:'500', flex: 1}}>
					<div style={{width: 'fit-content'}}>{t(`CREATE BRANCH`)}</div>
				</div>
			</IModalHeader>
				
			{/* <div className="nav-wrapper">
				<Nav 
					style={{display:'flex', justifyContent:'space-evenly', padding:"10px 0px"}}
					className="flex-md-row"
					id="tabs-icons-text" 
					pills
					role="tablist"
				>
					<NavItem>
						<NavLink
							aria-selected={tabs === 1}
							className={classnames("mb-sm-3 mb-md-0 tab_pills", {
								active: tabs === 1
							})}
							onClick={e => toggleNavs(e, 1)}
							role="tab"
							style={{cursor:'pointer', fontSize:'14px'}}
						>
							All Branches
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							aria-selected={tabs === 2}
							className={classnames("mb-sm-3 mb-md-0 tab_pills", {
									active: tabs === 2
							})}
							onClick={e => toggleNavs(e, 2)}
							role="tab"
							style={{cursor:'pointer', fontSize:'14px'}}
						>
							Active Branch
						</NavLink>
					</NavItem>
				</Nav>
			</div> */}
			<IModalBody>
				<INavBar tabs={tabs_list} activeTab={tabs} handleTabClick={(id) => toggleNavs(id)} />
				<TabContent activeTab={"tabs" + tabs}>

					<TabPane tabId="tabs1" style={{position:'relative'}}>
					{/* <Row>
						<Col xs={12}> */}
							<canvas id="versioning_canvas" style={{position: "absolute",left:'20px',top:35}}></canvas>
							<table style={{width:'100%'}}>
								<thead>
									<tr style={{height:'35px', fontSize:'13px', width:'100%', display:'flex' , alignItems:'center', borderBottom:'1px solid black'}}>
										<th style={{width:(20+(modal_json.node_connections?modal_json.node_connections.version_table_width_gap:0))+'px'}}></th>
										<th style={{paddingLeft:'10px', flex:5}}><span>Branch Name</span></th>
										{/* <th style={{paddingLeft:'10px', flex:5}}><span>Actions</span></th> */}
										<th style={{flex:5}}><span>Timeline</span></th>
										<th style={{flex:2}}></th>
									</tr>
								</thead>
								<tbody style={{display:'block', height: '400px', overflow:'auto', marginLeft:(20+(modal_json.node_connections?modal_json.node_connections.version_table_width_gap:0))+'px', width:"calc(100% - " +(20+(modal_json.node_connections?modal_json.node_connections.version_table_width_gap:0)) + "px)"}}>
									{
										modal_json && modal_json.useful_versions && modal_json.useful_versions.length>0 && modal_json.useful_versions.map((version,idx) => (
										<tr key={idx} style={{backgroundColor:(modal_json.active_version && modal_json.active_version.id)==version.id?'#ccf4e3':'none', height:'35px', fontSize:'13px', width:'100%', display:'flex', alignItems:'center', display:'flex', borderBottom:'1px solid black'}}>
											<td ></td>
											{/* <td style={{paddingLeft:'10px',flex:5}}>{get_branch_label_by_branch_id(version.design_branch_id)}</td> */}
											<td style={{paddingLeft:'10px',flex:5}}>{get_branch_name_by_branch_id(version.design_branch_id)}</td>
											<td style={{flex:5}}>{timeSince(version.updated_at)}</td>
											<td style={{flex:2, textAlign:'center'}}>
												<Generic_Dropdown
													display_jsx={(<img src='/resources/nav_icons_new/settings 16px.svg'/>)} 
													// display_jsx={(<i style={{fontSize:'25px', color:'#676878'}} className='fa fa-cog'/>)} 
													options={get_version_dropdown_options_tab_1(version)} 
												/>
											</td>
										</tr>
									))}
								</tbody>
							</table>
							{/* </Col>
						</Row> */}
					</TabPane>

					<TabPane className = "full-height" tabId="tabs2">
						<table style={{width:'100%'}}>
							<thead>
								<tr style={{height:'36px', fontSize:'14px', width:'100%', display:'flex' , alignItems:'center', borderBottom:'1px solid black'}}>
									<th style={{paddingLeft:'10px', flex:8, display:'flex', alignItems: 'center'}}>
										<span className='mr-3'>Version Name</span>
										{/* <span style={{ display: 'flex', flexDirection: 'column', fontSize: '14px' }}>
											<i title='' onClick={() => onclick_sorting_arrows('tag', 1)} style={{ height: '10px' }} className='fa fa-caret-up' />
											<i title='' onClick={() => onclick_sorting_arrows('tag', -1)} className='fa fa-caret-down' />
										</span> */}
										<i title='' style={{cursor:'pointer'}} onClick={() => {onclick_sorting_arrows('tag', name_filter, 'date'); toggle_name_filter() }} className='fa fa-sort' />
									</th>
									<th style={{paddingLeft:'10px', flex:8}}><span>Actions</span></th>
									<th style={{flex:3, display:'flex', alignItems:'center'}}>
										<span className='mr-3'>Timeline</span>
										{/* <span style={{ display: 'flex', flexDirection: 'column', fontSize: '14px' }}>
											<i title='' onClick={() => onclick_sorting_arrows('created_at', 1, 'date')} style={{ height: '10px' }} className='fa fa-caret-up' />
											<i title='' onClick={() => onclick_sorting_arrows('created_at', -1, 'date')} className='fa fa-caret-down' />
										</span> */}
										<i title='' style={{cursor:'pointer'}} onClick={() => {onclick_sorting_arrows('created_at', updated_at_filter, 'date'); toggle_updated_at() }} className='fa fa-sort' />
									</th>
									<th style={{flex:1}}></th>
								</tr>
							</thead>
							<tbody style={{display:'block', height: '400px', overflow:'auto', width:'100%'}}>
								{
									acive_branch_versions && acive_branch_versions.map((version,idx) => (
									// <tr key={idx} style={{backgroundColor:modal_json.active_version.id==version.id?'#ceeaf2':'none',height:'35px', fontSize:'13px', width:'100%', display:'flex', alignItems:'center', display:'flex', borderBottom:'1px solid black'}}>
									// 	<td style={{paddingLeft:'10px',flex:8}}>{version.label ? version.label : ''}</td>
									// 	<td style={{paddingLeft:'10px',flex:8}}>{version.name}</td>
									// 	<td style={{flex:3}}>{timeSince(version.updated_at)}</td>
									// 	<td style={{flex:1}}>
									// 		<Generic_Dropdown
									// 			display_jsx={(<img src='/resources/nav_icons_new/settings 16px.svg'/>)} 
									// 			// display_jsx={(<i style={{fontSize:'25px', color:'#676878'}} className='fa fa-cog'/>)} 
									// 			options={get_version_dropdown_options_tab_2(version)} 
									// 		/>
									// 	</td>
									// </tr>
									<VersionRow key={idx} version={version} active_version_id={modal_json && modal_json.active_version && modal_json.active_version.id} get_version_dropdown_options_tab_2={get_version_dropdown_options_tab_2} set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} index = {acive_branch_versions.length-idx-1}/>
								))}
							</tbody>
						</table>
					</TabPane>

				</TabContent>
			</IModalBody>
				
		</Modal>
	)    
}

const Core_boundary = ({onclick_fn_up, onclick_fn_down}) => {
	return(
		<tr style={{backgroundColor:'#cccccc'}}>
			<td>
				<div style={{display: 'flex', flexDirection:'column'}}>
					<i onClick={onclick_fn_up} style={{cursor:'pointer', fontSize:13}} data-toggle="tooltip" data-placement="bottom" title="Toggle Fullscreen" className="fa fa-chevron-up"></i>
					<i onClick={onclick_fn_down} style={{cursor:'pointer', fontSize:13}} data-toggle="tooltip" data-placement="bottom" title="Toggle Fullscreen" className="fa fa-chevron-down"></i>
				</div>
			</td>
			<td><b>Core Boundary</b></td>
			<td></td>
			<td></td>
			<td></td>
			<td></td>
		</tr>
	);
}

const LayerRow = ({layer, change_layer_name, change_layer_priority, change_layer_thickness, onclick_fn_up, onclick_fn_down, onclick_trash, open_hatching_modal}) => {
	return(
		<tr style={{width:47}}>
			<td>
				<div style={{display: 'flex', flexDirection:'column'}}>
					<i onClick={() => onclick_fn_up(layer.id)} style={{cursor:'pointer', fontSize:13}} data-toggle="tooltip" data-placement="bottom" title="Toggle Fullscreen" className="fa fa-chevron-up"></i>
					<i onClick={() => onclick_fn_down(layer.id)} style={{cursor:'pointer', fontSize:13}} data-toggle="tooltip" data-placement="bottom" title="Toggle Fullscreen" className="fa fa-chevron-down"></i>
				</div>
			</td>
			<td>
				<Input value={layer?layer.name:''} onChange={(e) => change_layer_name(layer.id, e.target.value)} style={{height:24, width:166, borderRadius:0, borderColor:'black'}} type="text"/>
			</td>
			<td>
				<div style={{display:'flex', flexDirection:'row'}}>
					<img onClick={() => open_hatching_modal(layer)} src={layer && layer.cut_snapshot?layer.cut_snapshot:''} style={{height:'30px', width:'30px', border:'1px solid black', cursor:'pointer'}}/> &nbsp;
					<img src={layer && layer.surface_snapshot?layer.surface_snapshot:''} style={{height:'30px', width:'30px', border:'1px solid black'}}/> &nbsp;
				</div>
			</td>
			<td>
				<Input value={layer?layer.priority:0} onChange={(e) => change_layer_priority(layer.id, e.target.value)} min="0" max="20" type="range" style={{background: 'linear-gradient(to right, rgb(49, 68, 86) 0%, rgb(49, 68, 86) 10%, rgb(255, 255, 255) 10%, white 100%)'}}/>
			</td>
			<td>
				<Row>
					<Col xs={6}><Input value={layer?layer.thickness:0} onChange={(e) => change_layer_thickness(layer.id, e.target.value)} style={{height:24, width:50, borderRadius:0, borderColor:'black'}} type="text"/></Col>
					<Col xs={4} style={{paddingLeft:0}}><span>mm</span></Col>
				</Row>
			</td>
			<td>
				<i onClick={() => onclick_trash(layer.id)} style={{cursor:'pointer'}} data-toggle="tooltip" data-placement="bottom" title="Toggle Fullscreen" className="fa fa-trash"></i>
			</td>
		</tr>
	);
}

const Layer_Hatch_Modal = ({  layer, hatch_patterns, open, handle_close, on_click }) => {
	const [ fill_color, set_fill_color ] = useState("#ffffff");
	const [ stroke_color, set_stroke_color ] = useState("#000000");
	const [ scale, set_scale ] = useState(1);
	const [ pattern, set_pattern ] = useState("");

	const alert = useAlert()

	useEffect(() => {
		if(open && layer){
			set_stroke_color(layer.hatchingStrokeColor)
			set_fill_color(layer.hatchingFillColor)
			set_pattern(layer.hatchingPattern)
			set_scale(layer.hatchingScale ? layer.hatchingScale : 1)
		}
	}, [open])
	
	const submit_values = () => {
		if(scale > 0){
			on_click(layer,stroke_color,fill_color,pattern, scale)
		}else{
			alert.error('Scale needs to be a positive value greater than 0')
		}
	}

	return(
		// <Modal className="modal-dialog-centered" size="lg" isOpen={open}>
		// 	<Card className="bg-secondary shadow border-0">
		// 		<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
		// 			<div>
		// 				<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Hatching Patterns</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
		// 			</div>
		// 		</CardHeader>
		// 		<CardBody style={{backgroundColor: COLORS.white}}>
		// 			<Row style={{margin:0}}>
		// 				<Col xs={4}>
		// 					Fill Color
		// 					<Input value={fill_color} onChange={(e) => {set_fill_color(e.target.value)}} style={{ padding: '0px', height: '30px', width: '30px', marginLeft:'5px'}} type="color" />	
		// 				</Col>  
		// 				<Col xs={4}>
		// 					Stroke Color
		// 					<Input value={stroke_color} onChange={(e) => {set_stroke_color(e.target.value)}} style={{ padding: '0px', height: '30px', width: '30px', marginLeft:'5px'}} type="color" />	
		// 				</Col>  
		// 				<Col xs={12}>
		// 					{hatch_patterns.map((item,idx) => 
		// 						<img key={idx} src={item.src} onClick={(e) => set_pattern(item.name)} style={{cursor:'pointer', marginTop:10,marginRight:10,width:100, border: item.name==pattern?'solid 2px #314456':'solid 1px black', boxShadow: item.name==pattern?'0 0 15px #314456':'none'}} />
		// 					)}
		// 				</Col>
		// 			</Row>
		// 			<Row>
		// 				<Col style={{display:'flex', justifyContent:'center'}} >
		// 					<Button onClick={submit_values} style={{ margin:'auto', fontSize:'14px', backgroundColor:'inherit' , color:COLORS.mainpage_locale, border:0, margin:'10px 0px', padding:'8px 30px' }} type="button">APPLY</Button>
		// 				</Col>
		// 			</Row>
		// 		</CardBody>
		// 	</Card>
		// </Modal>
		
		<Modal className="modal-dialog-centered" size="lg" isOpen={open}>
			<IModalHeader toggle={handle_close}>Hatching Patterns</IModalHeader>
			<IModalBody>
				<div className='flex_property mb-3 justify-content-between'>
					<div className='flex_property'>
						Fill Color
						<Input value={fill_color} onChange={(e) => { set_fill_color(e.target.value) }} style={{ padding: '0px', height: '32px', width: '32px', marginLeft: '5px', cursor: 'pointer' }} type="color" />

					</div>
					<div className='flex_property' xs={4}>
						Stroke Color
						<Input value={stroke_color} onChange={(e) => { set_stroke_color(e.target.value) }} style={{ padding: '0px', height: '32px', width: '32px', marginLeft: '5px', cursor: 'pointer' }} type="color" />
					</div>
					<div className='flex_property' xs={4}>
						Scale
						<Input value={String(scale)} onPaste={(e) => e.preventDefault()} onChange={(e) => { if(e.target.value >= 0){set_scale(Number(e.target.value))} }} style={{ padding: '0px 12px', height: '32px', width: '80px', marginLeft: '5px' }} type="number" />
					</div>
				</div>
				<div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
					{
						hatch_patterns.map((item, idx) =>
							<img key={idx} src={item.src} onClick={(e) => set_pattern(item.name)} style={{ cursor: 'pointer', width: 100, border: item.name == pattern ? 'solid 2px #314456' : 'solid 1px black', boxShadow: item.name == pattern ? '0 0 15px #314456' : 'none' }} />
						)}
				</div>
			</IModalBody>
			<IModalFooter>
				<Button onClick={submit_values} className='primary_button_default'>Apply</Button>
			</IModalFooter>
		</Modal>
	)    
}

const Add_Layer_modal = ({open, default_name, handle_close, on_click}) => {
	const [ name, set_name ] = useState("");
	useEffect(() => {
		if(open){
			set_name(default_name)
		}
	}, [open])

	return(
		<Modal className="modal-dialog-centered" size="sm" isOpen={open} toggle={handle_close}>
			<IModalHeader toggle={handle_close}>Rename</IModalHeader>
			<IModalBody>
				<Input placeholder='name' type='text' value={name} onChange={(e) => set_name(e.target.value)} />
			</IModalBody>
			<IModalFooter>
				<Button onClick={() => on_click(name)} className='primary_button_default' >Apply</Button>
			</IModalFooter>
		</Modal>
	);
}

const Composite_delete_modal = ({open, default_name, handle_close, on_click,modal_json,current_composite_id,wall_count}) => {
	const [ name, set_name ] = useState("");
	const [ new_composite_id, set_new_composite_id ] = useState("");
	const [ display_filter_list, set_display_filter_list ] = useState([]);
	useEffect(() => {
		if(open){
			set_name(default_name)
		}
	}, [open])

	useEffect(() => {
		if(current_composite_id){
			filter_current_modal_json()
		}
	}, [current_composite_id])

	useEffect(() => {
		if(display_filter_list && display_filter_list.length){
			set_new_composite_id(display_filter_list[0].id)
		}
	}, [display_filter_list])


	const filter_current_modal_json = () => {
		var x_modal_json =  modal_json && modal_json.composites && modal_json.composites.length?JSON.parse(JSON.stringify(modal_json.composites)):[]
		var x_filter_list = x_modal_json.filter(x => !(x.id===current_composite_id))
		set_display_filter_list(x_filter_list);
	}

	return(
		<Modal className="modal-dialog-centered" size="sm" isOpen={open} toggle={handle_close}>
			<IModalHeader toggle={handle_close}>Confirm</IModalHeader>
			<IModalBody>
				{
					wall_count ?
						<React.Fragment>
							<div style={{ marginBottom: '15px' }}>
								( {wall_count} walls are attached to this composite. Please select a replacement composite for them.)
							</div>
							<div>
								<Input id="delete_shift_select" type='select' value={new_composite_id} onChange={(e) => set_new_composite_id(e.target.value)}>
									{
										display_filter_list && display_filter_list.length ? display_filter_list.map((entry, idx) => (
											<option key={idx} value={entry.id}>{entry.name}</option>

										)) : ''
									}
								</Input>
							</div>
						</React.Fragment>
						:
						<div>
							Are you sure you want to delete the composite
						</div>
				}
			</IModalBody>
			<IModalFooter>
				<Button onClick={handle_close} className='secondary_button_default'>{'Cancel'}</Button>
				<Button onClick={() => on_click(new_composite_id)} className='red_button_default'>{wall_count ? "Replace and Delete" : "Delete"}</Button>
			</IModalFooter>
		</Modal>
	);
}

const options_direction_horizontal_section_modal = [{ name: 'Looking up', value: 'up', src: '/resources/icons/plan_view_looking_up.svg' }, { name: 'Looking down', value: 'down',  src: '/resources/icons/plan_view_looking_down.svg' }]
const HorizontalSectionModal = ({modal_type, modal_json, handle_close}) => {
	const [plan_name, set_plan_name] = useState('');
	const [visibility_range_from_value, set_visibility_range_from_value] = useState(1500);
	const [visibility_range_from_level, set_visibility_range_from_level] = useState('no_ref_level');
	const [visibility_range_to_value, set_visibility_range_to_value] = useState(1500);
	const [visibility_range_to_level, set_visibility_range_to_level] = useState('no_ref_level');
	const [direction, set_direction] = useState('down');

	const alert = useAlert()

	const onclick_handle_close = () => {
		handle_close()
		set_plan_name('')
		set_visibility_range_from_level('no_ref_level')
		set_visibility_range_from_value(1500)
		set_visibility_range_to_level('no_ref_level')
		set_visibility_range_to_value(0)
		set_direction('down')
	}

	const submitValues = () => {
		if(!plan_name){
			alert.error("Plan Name cannot be empty.")
			return
		}

		try{
			let final_json = {}
			final_json.name = plan_name;
			if(!visibility_range_from_level || visibility_range_from_level === 'no_ref_level'){
				final_json.from = Number(visibility_range_from_value)
			}else{
				final_json.from = {
					ref_level: visibility_range_from_level,
					offset: Number(visibility_range_from_value)
				}
			}
			// if(!visibility_range_to_level || visibility_range_to_level === 'no_ref_level'){
			final_json.to = Number(visibility_range_to_value)
			// }else{
			// 	final_json.to = {
			// 		ref_level: visibility_range_to_level,
			// 		offset: Number(visibility_range_to_value)
			// 	}
			// }

			final_json.direction = direction
			
			if(modal_json && modal_json.route){
				if(window.debug_mode) {
					window.add_debug_log(modal_json.route + "(\"" + JSON.stringify(final_json).replace(/"/g, '\\"') + "\");");
				}
				window.Module[modal_json.route](JSON.stringify(final_json))
			}

			onclick_handle_close()
			
		}catch(err){
			console.log(err)
		}
	}

	useEffect(() => {
		if(modal_type === "horizontal_section_modal" && modal_json){
			set_plan_name(modal_json.default_name);
			// if(!visibility_range_from_level && modal_json.from_options && modal_json.from_options.length){
			// 	set_visibility_range_from_level(modal_json.from_options[0].value)
			// }
			// if(!visibility_range_to_level && modal_json.to_options && modal_json.to_options.length){
			// 	set_visibility_range_to_level(modal_json.to_options[0].value)
			// }
		}
		
	}, [modal_json, modal_type]);

	return(
		<Modal isOpen={modal_type === "horizontal_section_modal"} size='md' centered>
			<IModalHeader toggle={onclick_handle_close}>{modal_json && modal_json.title}</IModalHeader>
			<IModalBody>
				{/* Plan Name */}
				<div className='flex_property'>
					<div className='mr-3'>Plan Name</div>
					<Input value={plan_name} onChange={(e) => set_plan_name(e.target.value)} type='text' style={{maxWidth: '360px', fontSize: '12px'}}/>
				</div>

				<hr/>

				{/* From and To options Similar to Ref Level Input */}
				<div className='mb-2'>Visibility Range</div>
				<div className='mb-3'>
					<div className='flex_property mb-2' style={{flex: 1}}>
						<div className='mr-3' style={{minWidth: '120px'}}>Cut Plane Height</div>
						<Input className='mr-2' value={visibility_range_from_level} type='select' onChange={(e) => set_visibility_range_from_level(e.target.value)} style={{fontSize: '12px'}}>
							<option value={'no_ref_level'}>Amount from Global Origin</option>
							{
								modal_json && modal_json.from_options && modal_json.from_options.length ? modal_json.from_options.map((option) => (
									<option key={option.value} value={option.value}>{option.name}</option>
								))
								:''
							}
						</Input>
						<Input value={visibility_range_from_value} onChange={(e) => set_visibility_range_from_value(e.target.value)} type='number' style={{width: '120px', fontSize: '12px'}}/>

					</div>
					<div className='flex_property' style={{flex: 1}}>
						<div className='mr-3' style={{minWidth: '120px'}}>Cut Depth</div>
						<Input value={visibility_range_to_value} onChange={(e) => set_visibility_range_to_value(e.target.value)} type='number' style={{width: '120px', fontSize: '12px'}}/>
					</div>
				</div>
				<div className='mb-3 flex_property'>
					<div className='mr-3' style={{ minWidth: '120px' }}>Camera Direction</div>
					<UncontrolledDropdown style={{ flex: 1 }}>
						<DropdownToggle style={{ display: 'flex', flexFlow: 'row', backgroundColor: 'white', cursor: 'pointer', color: 'black', fontSize: '12px', padding: '4px 12px', border: '1px solid  var(--light-border-color)', alignItems: 'center', width: '100%', boxShadow: 'none' }}>
							<div style={{ display: 'flex', flexFlow: 'row', fontSize: '12px', alignItems: 'center', flex: 1, minWidth: 0, overflow: 'auto' }}>
								<img className='mr-3' width={'20px'} height={'20px'} src={options_direction_horizontal_section_modal.find(x => x.value == direction).src}></img>
								<div title={direction && options_direction_horizontal_section_modal && options_direction_horizontal_section_modal.find(x => x.value == direction) ? options_direction_horizontal_section_modal.find(x => x.value == direction).name : ''} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>{direction && options_direction_horizontal_section_modal && options_direction_horizontal_section_modal.find(x => x.value == direction) ? options_direction_horizontal_section_modal.find(x => x.value == direction).name : ''}</div>
							</div>
							<span><i className='fa fa-caret-down' /></span>
						</DropdownToggle>
						<DropdownMenu style={{ width: '100%' }}>
							{
								options_direction_horizontal_section_modal.map((option, idx) => (
									<DropdownItem title={option.name} style={{ listStyle: 'none', padding: '4px' }} onClick={() => { set_direction(option.value) }} key={idx}>
										<div className='flex_property'>
											<img className='mr-2' height={'20px'} width={'20px'} style={{ objectFit: 'cover' }} src={option.src} ></img>
											<div>{option.name}</div>
										</div>
									</DropdownItem>
								))
							}
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
				{/* <div style={{color: COLORS.gray5}}>
					View Type: Ceiling plan or floor plan is defined by the direction of "from" and "to" values
				</div> */}
			</IModalBody>
			<IModalFooter>
				<Button className='primary_button_default' onClick={submitValues}>Create</Button>
			</IModalFooter>
		</Modal>
	)
}

const Composites_modal = ({ modal_type, modal_json, handle_close, update_modal_json }) => {
	const alert = useAlert();
	const [ composites, set_composites ] = useState([]);
	const [ active_index, set_active_index ] = useState(0);
	const [ show_hatching_modal, set_show_hatching_modal ] = useState(false);
	const [ show_rename_modal, set_show_rename_modal ] = useState(false);
	const [ show_delete_modal, set_show_delete_modal ] = useState(false);
	const [ show_info_modal, set_show_info_modal ] = useState(false);
	const [submit, set_submit] = useState('');
	const [ info_modal_message, set_info_modal_message ] = useState('');
	const [ editing_hatching_layer, set_editing_hatching_layer ] = useState(null);

	const [isDirty, setIsDirty] = useState(false);

	const [ hatch_patterns, set_hatch_patterns ] = useState([]);
    const {t} = useTranslation()

	useEffect(() => {
		set_hatch_patterns(updateWallHatchPatterns());
	},[])

	useEffect(() => {
		if(modal_type == "composites" && modal_json && modal_json.composites && modal_json.composites.length){
			reset_composites(active_index < modal_json.composites.length ? active_index : 0);
		}
	},[modal_json, modal_type])

	const open_hatching_modal = (layer) => {
		set_editing_hatching_layer(JSON.parse(JSON.stringify(layer)));
		set_show_hatching_modal(true);
	}

	const close_hatching_modal = () => {
		set_show_hatching_modal(false);
	}

	const open_info_modal = () => {
		set_show_info_modal(true);
	}

	const close_info_modal = () => {
		set_show_info_modal(false);
	}

	const apply_main = () => {
		delete composites[active_index].cut_snapshot
		delete composites[active_index].surface_snapshot
		for(var i=0;i<composites[active_index].layers.length;i++){
			delete composites[active_index].layers[i].cut_snapshot
			delete composites[active_index].layers[i].surface_snapshot
		}

		window.Module.apply_layers_to_composite(JSON.stringify(composites[active_index]));
		update_modal_json();
	}

	const switch_active_index = (id) => {
		var index = window._.findIndex(composites,o => o.id == id);
		if(index != -1){
			// set_active_index(index);
			if (isDirty) {
				set_info_modal_message("You have some unapplied changes. Proceeding forward will reset the existing changes.")
				open_info_modal()
				set_submit({ func: 'switch', args: [index] })
			} else {
				reset_composites(index);
			}
		}
	}

	const reset_composites = async (index) => {
		setIsDirty(false)

		if(!index){
			index = 0;
		}

		set_active_index(index);
		var composites_json = JSON.parse(JSON.stringify(modal_json.composites));
		for(var i = 0; i < composites_json.length;i++){
			composites_json[i].layers = composites_json[i].layers.sort(sort_by)
			composites_json[i].cut_snapshot = generate_2d_snapshot_of_composite(composites_json[i]);
			composites_json[i].surface_snapshot = await generate_3d_snapshot_of_composite(composites_json[i]);
			for(var j=0;j<composites_json[i].layers.length;j++){
				composites_json[i].layers[j].cut_snapshot = generate_2d_snapshot_of_layer(composites_json[i].layers[j])
				composites_json[i].layers[j].surface_snapshot = await generate_3d_snapshot_of_layer(composites_json[i].layers[j])
			}
		}
		set_composites(composites_json);
	}

	const clone_composite_fn = () => {
		var ln = composites.length;
		window.Module.clone_composite(composites[active_index].id);
		update_modal_json();
		set_active_index(ln);
		setIsDirty(false)
	}

	const clone_composite = () => {
		if (isDirty) {
			set_info_modal_message("You have some unapplied changes. Proceeding forward will reset the existing changes.")
			open_info_modal()
			set_submit({func: 'clone', args: []})
		}else{
			clone_composite_fn()
		}
	}

	const handleSubmit = () => {
		if (submit.func === 'clone') {
			clone_composite_fn(...submit.args)
		}else if(submit.func === 'rename'){
			rename_composite_fn(...submit.args)
		}else if(submit.func === 'delete'){
			delete_composite_fn(...submit.args)
		}else if(submit.func === 'switch'){
			reset_composites(...submit.args)
		}
	}

	const delete_composite_fn = (new_id) => {
		var ln = composites.length;
		// window.Module.clone_composite(composites[active_index].id);
		// update_modal_json();
		if(ln>1){
			window.Module.reassign_and_delete_composite(composites[active_index].id,new_id);
			set_show_delete_modal(false);
			var composites_json = JSON.parse(JSON.stringify(composites));
			// composites_json[active_index].name = new_name;
			set_composites(composites_json);
			// if(active_index>0){
				// 	set_active_index(active_index-1);
				// }
		}else{
				alert.error("Cannot delete the last composite");
		}
		update_modal_json()
		setIsDirty(false)
	}

	const delete_composite = (new_id) => {
		if (isDirty) {
			set_info_modal_message("You have some unapplied changes. Proceeding forward will reset the existing changes.")
			open_info_modal()
			set_submit({ func: 'delete', args: [new_id] })
		} else {
			delete_composite_fn(new_id)
		}
	}

	const open_rename_modal = () => {
		set_show_rename_modal(true);
	}

	const close_rename_modal = () => {
		set_show_rename_modal(false);
	}

	const open_delete_modal = () => {
		var ln = composites.length;
		if(ln>1){
			set_show_delete_modal(true);
		}else{
			alert.error("Cannot delete the last composite");
		}
	}

	const close_delete_modal = () => {
		set_show_delete_modal(false);
	}

	const rename_composite_fn = (new_name) => {
		window.Module.rename_composite(new_name,composites[active_index].id);
		set_show_rename_modal(false);
		var composites_json = JSON.parse(JSON.stringify(composites));
		composites_json[active_index].name = new_name;
		set_composites(composites_json);
		update_modal_json()
		setIsDirty(false)
	}

	const rename_composite = (new_name) => {
		if (isDirty) {
			set_info_modal_message("You have some unapplied changes. Proceeding forward will reset the existing changes.")
			open_info_modal()
			set_submit({func: 'rename', args: [new_name]})
		}
		else {
			rename_composite_fn(new_name)
		}
	}

	const apply_hatching_details = async (layer,stroke_color,fill_color,pattern, scale) => {
		setIsDirty(true)
		var composites_json = JSON.parse(JSON.stringify(composites))
		var layers = composites_json[active_index].layers
		var layer_changed = null
		for(var i=0;i<layers.length;i++){
			if(layers[i].id == layer.id){
				layers[i].hatchingFillColor = fill_color
				layers[i].hatchingStrokeColor = stroke_color
				layers[i].hatchingPattern = pattern
				layers[i].hatchingScale = scale
				layers[i].cut_snapshot = generate_2d_snapshot_of_layer(layers[i])
				layer_changed = layers[i]
			}
		}

		if(layer_changed){
			composites_json[active_index].cut_snapshot = generate_2d_snapshot_of_composite(composites_json[active_index]);
			set_composites(composites_json);
		}
		
		close_hatching_modal();
	}

	const arrow_fn = (id,direction) => {
		setIsDirty(true)
		var composites_json = JSON.parse(JSON.stringify(composites));
		
		// cleaning up orders
		composites_json[active_index].layers = composites_json[active_index].layers.sort(sort_by)
		composites_json[active_index].layers.map((o,idx) => o.order = idx);

		if(composites_json[active_index].core_end > composites_json[active_index].layers.length){
            composites_json[active_index].core_end = composites_json[active_index].layers.length;
        }

        if(composites_json[active_index].core_end < 0){
            composites_json[active_index].core_end = 0;
        }

		if(composites_json[active_index].core_start > composites_json[active_index].layers.length){
            composites_json[active_index].core_start = composites_json[active_index].layers.length;
        }

        if(composites_json[active_index].core_start < 0){
            composites_json[active_index].core_start = 0;
        }

		if(id == "core_start"){
			if(direction == "up" && composites_json[active_index].core_start != 0){
				composites_json[active_index].core_start -= 1;
			}else if(direction == "down" && composites_json[active_index].core_start != composites_json[active_index].layers.length && composites_json[active_index].core_start != composites_json[active_index].core_end){
				composites_json[active_index].core_start += 1;
			}
			composites_json[active_index].layers = composites_json[active_index].layers.sort(sort_by)
			set_composites(composites_json);
		}else if(id == "core_end"){
			if(direction == "up" && composites_json[active_index].core_end != 0 && composites_json[active_index].core_start != composites_json[active_index].core_end){
				composites_json[active_index].core_end -= 1;
			}else if(direction == "down" && composites_json[active_index].core_end != composites_json[active_index].layers.length){
				composites_json[active_index].core_end += 1;
			}
			composites_json[active_index].layers = composites_json[active_index].layers.sort(sort_by)
			set_composites(composites_json);
		}else{
			var index = window._.findIndex(composites_json[active_index].layers, o => o.id == id);
			
			if(direction == "up"){
				if(index != 0 || (index == 0 && composites_json[active_index].core_start == 0)){
					if(index == 0 && composites_json[active_index].core_start == 0){
						if(composites_json[active_index].core_start == composites_json[active_index].core_end){
							arrow_fn("core_end","down");
						}else{
							arrow_fn("core_start","down");
						}
							
						return;
					}else if(composites_json[active_index].core_end == index){
						arrow_fn("core_end","down");
						return;
					}else if(composites_json[active_index].core_start == index){
						arrow_fn("core_start","down");
						return;
					}else{
						var temp = composites_json[active_index].layers[index - 1].order;
						composites_json[active_index].layers[index - 1].order = composites_json[active_index].layers[index].order;
						composites_json[active_index].layers[index].order = temp;	
					}
				}
			}else if(direction == "down"){
				var l = composites_json[active_index].layers.length;
				if(index < (l-1) || (index == l-1 && composites_json[active_index].core_end == (index+1))){
					if(index == l-1 && composites_json[active_index].core_end == (index+1)){
						if(composites_json[active_index].core_start == composites_json[active_index].core_end){
							arrow_fn("core_start","up");
						}else{
							arrow_fn("core_end","up");
						}
						return;
					}else if(composites_json[active_index].core_start == (index+1)){
						arrow_fn("core_start","up");
						return;
					}else if(composites_json[active_index].core_end == (index+1)){
						arrow_fn("core_end","up");
						return;
					}else{
						var temp = composites_json[active_index].layers[index + 1].order;
						composites_json[active_index].layers[index + 1].order = composites_json[active_index].layers[index].order;
						composites_json[active_index].layers[index].order = temp;
					}
				}
			} 


			composites_json[active_index].layers = composites_json[active_index].layers.sort(sort_by)
			set_composites(composites_json);
		}
	}

	const onclick_trash = async(id) => {
		setIsDirty(true)
		var composites_json = JSON.parse(JSON.stringify(composites));
		var index = window._.findIndex(composites_json[active_index].layers,o => o.id == id);

		if(index != -1){
			window._.pullAt(composites_json[active_index].layers,index)

			composites_json[active_index].cut_snapshot = generate_2d_snapshot_of_composite(composites_json[active_index]);
			composites_json[active_index].surface_snapshot = await generate_3d_snapshot_of_composite(composites_json[active_index]);

			set_composites(composites_json);
		}
	}

	const onclick_add_layer = async () => {
		setIsDirty(true)
		var new_order = 1;

		if(composites[active_index].layers.length != 0) {
			new_order = window._.maxBy(composites[active_index].layers, o => o.order).order + 1;
		}
		
		var new_layer = window.Module.add_layer_in_composites_modal(new_order, composites[active_index].layers.length);
		var composites_json = JSON.parse(JSON.stringify(composites));
		var new_layer = JSON.parse(new_layer);
		composites_json[active_index].layers.push(new_layer);

		composites_json[active_index].cut_snapshot = generate_2d_snapshot_of_composite(composites_json[active_index]);
		composites_json[active_index].surface_snapshot = await generate_3d_snapshot_of_composite(composites_json[active_index]);
		new_layer.cut_snapshot = generate_2d_snapshot_of_layer(new_layer)
		new_layer.surface_snapshot = await generate_3d_snapshot_of_layer(new_layer)

		set_composites(composites_json);
	}

	const change_layer_name = (id, val) => {
		setIsDirty(true)
		var composites_json = JSON.parse(JSON.stringify(composites));
		var layer = composites_json[active_index].layers.find(o => o.id == id);

		if(layer){
			layer.name = val
			set_composites(composites_json);
		}
	}

	const change_layer_priority = (id, val) => {
		setIsDirty(true)
		var composites_json = JSON.parse(JSON.stringify(composites));
		var layer = composites_json[active_index].layers.find(o => o.id == id);

		if(layer){
			layer.priority = Number(val)
			set_composites(composites_json);
		}
	}

	const change_layer_thickness = (id, val) => {
		setIsDirty(true)
		var composites_json = JSON.parse(JSON.stringify(composites));
		var layer = composites_json[active_index].layers.find(o => o.id == id);

		if(layer){
			layer.thickness = Number(val)
			set_composites(composites_json);
		}
	}



	const change_editing_composite = (id) => {
		set_active_index(Number(id.split('composite_')[1]))
	}

	const get_composites_dropdown_options = () => {
		var options = []
		
		for(var i=0;i<composites.length;i++){
			options.push({
				id: "composite_" + i,
				name:composites[i].name,
				onClick:change_editing_composite,
				jsx: (
					<div style={{fontSize:'25px',display:'flex',flexFlow:'row'}}>
						<img src = {composites[active_index]?composites[active_index].cut_snapshot:''} />
						<img src = {composites[active_index]?composites[active_index].surface_snapshot:''} />
						<div>{composites[active_index]?composites[active_index]:''}</div>
					</div>
				)
			})
		}

		return options;
	}

	return(

		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type=="composites"}>
			{/* <Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Composites</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close} >×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: '#f2f2f2', padding:'24px' }} className="px-lg-4 py-lg-4">
					<Row>
						<Col>
							<CompositeDropdown
								composites={composites}
								active_index={active_index}
								on_click={switch_active_index}
							/>
						</Col>  
					</Row>
					<Row>
						<Col xs={4} >
							<Button onClick={open_rename_modal} style={{ fontSize:'14px', backgroundColor:'inherit' , color:COLORS.mainpage_locale, border:0, margin:'10px 0px', padding:'8px 30px' }} type="button">RENAME</Button>
						</Col>  
						<Col xs={4} >
							<Button onClick={clone_composite} style={{ fontSize:'14px', backgroundColor:'inherit' , color:COLORS.mainpage_locale, border:0, margin:'10px 0px', padding:'8px 30px'}} type="button">CLONE</Button>
						</Col>
						<Col xs={4} >
							<Button onClick={open_delete_modal} style={{ fontSize:'14px', backgroundColor:'inherit' , color:COLORS.mainpage_locale, border:0, margin:'10px 0px', padding:'8px 30px'}} type="button">DELETE</Button>
						</Col>  
					</Row>
					<Row>
						<Col xs={10}>
							<div className="table-responsive" style={{backgroundColor: '#ffffff', border: 'solid 1px grey', borderRadius: '5px'}}>          
								<Table className="table table-bordered composite_table">
									<thead style={{fontSize:'13px'}}>
										<tr>
											<th></th>
											<th>Layer name</th>
											<th>Material &amp; Hatching</th>
											<th>Intersection Priority</th>
											<th>Thickness</th>
								  	</tr>
									</thead>
									<tbody>
										{
											composites && composites[active_index] && composites[active_index].layers.slice(0, composites[active_index].core_start).map((item,idx) =>(
												<LayerRow layer={item} key={idx+"table_row_upper_noncore"} open_hatching_modal={open_hatching_modal} onclick_fn_up={() => {arrow_fn(item.id,"up")}} onclick_fn_down={() => {arrow_fn(item.id,"down")}} onclick_trash={onclick_trash} change_layer_name={change_layer_name} change_layer_priority={change_layer_priority} change_layer_thickness={change_layer_thickness}/>
											))
										}
										<Core_boundary onclick_fn_down={() => {arrow_fn("core_start","down")}} onclick_fn_up={() => {arrow_fn("core_start","up")}}/>
										{
											composites && composites[active_index] && composites[active_index].layers.slice(composites[active_index].core_start, composites[active_index].core_end).map((item,idx) =>(
												<LayerRow layer={item} key={idx+"table_row_core"} open_hatching_modal={open_hatching_modal} onclick_fn_up={() => {arrow_fn(item.id,"up")}} onclick_fn_down={() => {arrow_fn(item.id,"down")}} onclick_trash={onclick_trash} change_layer_name={change_layer_name} change_layer_priority={change_layer_priority} change_layer_thickness={change_layer_thickness} />
											))	
										}
										<Core_boundary onclick_fn_down={() => {arrow_fn("core_end","down")}} onclick_fn_up={() => {arrow_fn("core_end","up")}} />
										{
											composites && composites[active_index] && composites[active_index].layers.slice(composites[active_index].core_end, composites[active_index].layers.length).map((item,idx) =>(
												<LayerRow layer={item} key={idx+"table_row_lower_noncore"} open_hatching_modal={open_hatching_modal} onclick_fn_up={() => {arrow_fn(item.id,"up")}} onclick_fn_down={() => {arrow_fn(item.id,"down")}} onclick_trash={onclick_trash} change_layer_name={change_layer_name} change_layer_priority={change_layer_priority} change_layer_thickness={change_layer_thickness} />
											))	
										}
									</tbody>
								</Table>
							</div>
						</Col>
						<Col xs={2} style={{display:'flex', flexDirection:'column', justifyContent:'space-around'}}>
							<div>
								<span style={{fontSize:'13px'}}>2D Hatching (cut)</span>
								<img src={composites && composites[active_index] && composites[active_index].cut_snapshot?composites[active_index].cut_snapshot:''} style={{height:'100px', width:'100px', border:'1px solid black'}}/>
							</div>

							<div>
								<span style={{fontSize:'13px'}}>3D Material (surface)</span>
								<img src={composites && composites[active_index] && composites[active_index].surface_snapshot?composites[active_index].surface_snapshot:''} style={{height:'100px', width:'100px', border:'1px solid black'}}/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs={2} >
							<Button onClick={onclick_add_layer} style={{ fontSize:'14px', backgroundColor:'inherit' , color:COLORS.mainpage_locale, border:0, fontSize:'12px' ,padding:'8px 30px' }} type="button"><b>ADD LAYER</b></Button>
						</Col>   
					</Row>
					<Row>
						<Col style={{display:'flex', justifyContent:'center'}} >
							<Button onClick={() => reset_composites(0)} style={{ fontSize:'14px', backgroundColor:'inherit' , color:COLORS.mainpage_locale, border:0, margin:'10px 0px', padding:'8px 30px' }} type="button"><b>RESET</b></Button>
							<Button onClick={apply_main} style={{ fontSize:'14px', backgroundColor:'inherit' , color:COLORS.mainpage_locale, border:0, margin:'10px 0px', padding:'8px 30px'}} type="button"><b>APPLY</b></Button>
						</Col>   
					</Row>
				</CardBody>
			</Card> */}
			<IModalHeader toggle={handle_close}>Composites</IModalHeader>
			<IModalBody>
				<div className='mb-3'>
					<CompositeDropdown
						composites={composites}
						active_index={active_index}
						on_click={switch_active_index}
					/>
				</div>
					
				<div className='flex_property mb-2'>
					<div style={{flex: 1, display: 'flex'}} >
						<div className='simple_white_button' onClick={open_rename_modal}>{t(`Rename`)}</div>
					</div>  
					<div style={{flex: 1, display: 'flex'}} >
						<div className='simple_white_button' onClick={clone_composite}>{t(`Clone`)}</div>
					</div>
					<div style={{flex: 1, display: 'flex'}} >
						<div className='simple_white_button' onClick={open_delete_modal}>{t(`Delete`)}</div>
					</div>  
				</div>

				<Row className='mb-3 flex_property'>
					<Col xs={10}>
						<div className="table-responsive" style={{backgroundColor: '#ffffff', border: 'solid 1px grey', borderRadius: '5px'}}>          
							<Table className="table table-bordered composite_table">
								<thead style={{fontSize:'13px'}}>
									<tr>
										<th></th>
										<th>Layer name</th>
										<th>Material &amp; Hatching</th>
										<th>Intersection Priority</th>
										<th>Thickness</th>
								</tr>
								</thead>
								<tbody>
									{
										composites && composites[active_index] && composites[active_index].layers.slice(0, composites[active_index].core_start).map((item,idx) =>(
											<LayerRow layer={item} key={idx+"table_row_upper_noncore"} open_hatching_modal={open_hatching_modal} onclick_fn_up={() => {arrow_fn(item.id,"up")}} onclick_fn_down={() => {arrow_fn(item.id,"down")}} onclick_trash={onclick_trash} change_layer_name={change_layer_name} change_layer_priority={change_layer_priority} change_layer_thickness={change_layer_thickness}/>
										))
									}
									<Core_boundary onclick_fn_down={() => {arrow_fn("core_start","down")}} onclick_fn_up={() => {arrow_fn("core_start","up")}}/>
									{
										composites && composites[active_index] && composites[active_index].layers.slice(composites[active_index].core_start, composites[active_index].core_end).map((item,idx) =>(
											<LayerRow layer={item} key={idx+"table_row_core"} open_hatching_modal={open_hatching_modal} onclick_fn_up={() => {arrow_fn(item.id,"up")}} onclick_fn_down={() => {arrow_fn(item.id,"down")}} onclick_trash={onclick_trash} change_layer_name={change_layer_name} change_layer_priority={change_layer_priority} change_layer_thickness={change_layer_thickness} />
										))	
									}
									<Core_boundary onclick_fn_down={() => {arrow_fn("core_end","down")}} onclick_fn_up={() => {arrow_fn("core_end","up")}} />
									{
										composites && composites[active_index] && composites[active_index].layers.slice(composites[active_index].core_end, composites[active_index].layers.length).map((item,idx) =>(
											<LayerRow layer={item} key={idx+"table_row_lower_noncore"} open_hatching_modal={open_hatching_modal} onclick_fn_up={() => {arrow_fn(item.id,"up")}} onclick_fn_down={() => {arrow_fn(item.id,"down")}} onclick_trash={onclick_trash} change_layer_name={change_layer_name} change_layer_priority={change_layer_priority} change_layer_thickness={change_layer_thickness} />
										))	
									}
								</tbody>
							</Table>
						</div>
					</Col>
					<Col xs={2} style={{display:'flex', flexDirection:'column', justifyContent:'space-around'}}>
						<div>
							<span style={{fontSize:'13px'}}>2D Hatching (cut)</span>
							<img src={composites && composites[active_index] && composites[active_index].cut_snapshot?composites[active_index].cut_snapshot:''} style={{height:'100px', width:'100px', border:'1px solid black'}}/>
						</div>

						<div>
							<span style={{fontSize:'13px'}}>3D Material (surface)</span>
							<img src={composites && composites[active_index] && composites[active_index].surface_snapshot?composites[active_index].surface_snapshot:''} style={{height:'100px', width:'100px', border:'1px solid black'}}/>
						</div>
					</Col>
				</Row>

				<div className='flex_property'>
					<div onClick={onclick_add_layer} className='simple_white_button'>{t(`Add Layer`)}</div>
				</div>
			</IModalBody>
			<IModalFooter>
				<Button onClick={() => reset_composites(0)} className='secondary_button_default'>Reset</Button>
				<Button onClick={apply_main} className='primary_button_default'>Apply</Button>
			</IModalFooter>
			<Layer_Hatch_Modal 
				layer={editing_hatching_layer}
				hatch_patterns={hatch_patterns}
				open={show_hatching_modal}
				handle_close={close_hatching_modal}
				on_click={apply_hatching_details} 
			/>
			<Add_Layer_modal
				open={show_rename_modal}
				default_name={composites&&composites[active_index]?composites[active_index].name:''}
				handle_close={close_rename_modal}
				on_click={rename_composite} 
			/>
			<Composite_delete_modal 
				open={show_delete_modal}
				default_name={composites&&composites[active_index]?composites[active_index].name:''}
				modal_json = {modal_json}
				current_composite_id = {composites&&composites[active_index]?composites[active_index].id:''}
				wall_count = {composites&&composites[active_index]?window.Module.count_elements_attached_to_composite(composites[active_index].id):0}
				handle_close={close_delete_modal}
				on_click={delete_composite}
			/>
			<InfoModal
				open={show_info_modal}
				handle_close={close_info_modal}
				message={info_modal_message}
				submit={handleSubmit}
			/>
		</Modal>
	)    
}

const AddParameter = ({open, toggle, addParameter, is_sku, all_category_types, update_view, get_cat_name, get_sku_details_from_id, get_sku_display_pic}) => {
	const [name, set_name] = useState('');
	const [value, set_value] = useState(0);
	const [min_value, set_min_value] = useState(-10000);
	const [max_value, set_max_value] = useState(10000);
	const [category_types, set_category_types] = useState('none');
	const [label, set_label] = useState('');
	const [description, set_description] = useState('');
    const {t} = useTranslation()

	const reset_parameters = () => {
		set_name('')
		set_value(0)
		set_min_value(-10000)
		set_max_value(10000)
		set_label('')
		set_description('')
		set_category_types('none')
	}

	window.set_sku_value = set_value

	const onClickCreate = () => {
		if(label && label.trim() && (!is_sku || (is_sku && value && category_types))){
			if(is_sku){
				addParameter((name ? name : label), description, label, value, [category_types])
			}else{
				addParameter((name ? name : label), description, label, (value ? Number(value) : 0),  (min_value ? Number(min_value) : 0), (max_value ? Number(max_value) : 0))
			}
		}
	}

	useEffect(() => {
		if(!open){
			reset_parameters()
		}
	}, [open]);
	return(
		<Modal className='modal-dialog-centered' isOpen={open} toggle={toggle}>
			<IModalHeader toggle={toggle}>Add Parameter</IModalHeader>
			<IModalBody>
				<div className='mb-3 flex_property justify-content-between'>
					<div className='mr-3'>{t(`Parameter Name*`)}</div>
					{
						is_sku ?
							<Input style={{ width: '250px' }} value={label} onChange={(e) => { set_label(e.target.value ? e.target.value : '') }} placeholder='Enter parameter name'></Input>
							:
							<Input style={{ width: '250px' }} value={label} onChange={(e) => { set_label(e.target.value ? e.target.value.replace(' ', '') : e.target.value) }} placeholder='Enter parameter name'></Input>
					}
				</div>
				{
					is_sku ? ''
						:
						<div className='mb-3 flex_property justify-content-between'>
							<div className='mr-3'>{t(`Display Name*`)}</div>
							<Input style={{ width: '250px' }} value={name} onChange={(e) => set_name(e.target.value)} placeholder={label ? label : 'Enter display value'}></Input>
						</div>
				}
				{
					is_sku ?
						<div className='mb-3 flex_property justify-content-between'>
							<div className='mr-3'>{t(`Category Types`)}</div>
							<Input style={{ width: '250px', textTransform: 'capitalize' }} value={category_types} onChange={(e) => {set_category_types(e.target.value); set_value('') }} placeholder='Select Category Types' type='select'>
								<option value={'none'}>None</option>
								{
									all_category_types && all_category_types.length ? all_category_types.map((cat_type, idx) => (
										<option key={idx} value={cat_type.id} style={{textTransform: 'capitalize'}}>{get_cat_name(cat_type.display_name)}</option>
									))
										: ''
								}
							</Input>
						</div>
						: ''
				}
				{
					is_sku ?
						<div className='mb-3 flex_property justify-content-between'>
							<div className='mr-3'>{t(`Parameter Value`)}</div>
							{/* <Input style={{ width: '250px' }} value={value} onChange={(e) => set_value(e.target.value)} placeholder='Enter parameter value' type='number'></Input> */}
							<div className='flex_property'>
								{value ? <img src={get_sku_display_pic(value)} height={'32px'} className='mr-2'></img> : ''}
								<div className='mr-2'>{value ? get_sku_details_from_id(value, 'sku_name') : ''}</div>
								<div className='secondary_button_default cp' onClick={() => {window.Module.open_sku_parameter_selector_carousel(JSON.stringify([category_types])); update_view()}}>{`${value ? 'Change' : 'Add'}`} SKU</div>
							</div>
						</div>
						:
						<div className='mb-3 flex_property justify-content-between'>
							<div className='mr-3'>{t(`Parameter Value`)}</div>
							<Input style={{ width: '250px' }} value={value} onChange={(e) => set_value(e.target.value)} placeholder='Enter parameter value' type='number'></Input>
						</div>
				}
				{
					is_sku ?
						'' 
					:
					<div className='mb-3 flex_property justify-content-between'>
						<div className='mr-3'>Parameter Min Value</div>
						<Input style={{ width: '250px' }} value={min_value} onChange={(e) => set_min_value(e.target.value)} placeholder='Enter parameter minimum value' type='number'></Input>
					</div>
				}
				{
					is_sku ?
						'' 
					:
					<div className='mb-3 flex_property justify-content-between'>
						<div className='mr-3'>Parameter Max Value</div>
						<Input style={{ width: '250px' }} value={max_value} onChange={(e) => set_max_value(e.target.value)} placeholder='Enter parameter maximum value' type='number'></Input>
					</div>
				}
				<div className='flex_property justify-content-between'>
					<div className='mr-3'>{t(`Description`)}</div>
					<Input style={{width:'250px'}} value={description} onChange={(e) => set_description(e.target.value)} placeholder='Enter Description'></Input>
				</div>
			</IModalBody>
			<IModalFooter>
				<Button className='primary_button_default' disabled={!(label && label.trim() && (!is_sku || (is_sku && value && category_types)))} onClick={onClickCreate}>Create</Button>
			</IModalFooter>
		</Modal>
	)
}

const AddExpression = ({open, toggle, addExpression}) => {
	const [value, set_value] = useState('');
	const [name, set_name] = useState('');
	const [description, set_description] = useState('');
	const [label, set_label] = useState('');
    const {t} = useTranslation()

	const reset_parameters = () => {
		set_value('')
		set_label('')
	}


	useEffect(() => {
		if(!open){
			reset_parameters()
		}
	}, [open]);

	return(
		<Modal className='modal-dialog-centered' isOpen={open} toggle={toggle}>
			<IModalHeader toggle={toggle}>Add Expression</IModalHeader>
			<IModalBody>
				<div className='mb-3 flex_property justify-content-between'>
					<div className='mr-3'>{t(`Expression Name*`)}</div>
					<Input style={{ width: '250px' }} value={label} onChange={(e) => { set_label(e.target.value ? e.target.value.replace(' ', '') : '') }} placeholder='Enter expression name'></Input>
				</div>

				<div className='mb-3 flex_property justify-content-between'>
					<div className='mr-3'>{t(`Expression Display Name*`)}</div>
					<Input style={{ width: '250px' }} value={name} onChange={(e) => { set_name(e.target.value ? e.target.value : '') }} placeholder={label ? label : 'Enter expression display name'}></Input>
				</div>
				
				<div className='mb-3 flex_property justify-content-between'>
					<div className='mr-3'>{t(`Expression`)}</div>
					<Input style={{width:'250px'}} value={value} onChange={(e) => set_value(e.target.value)} placeholder='Enter Expression'></Input>
				</div>

				<div className='flex_property justify-content-between'>
					<div className='mr-3'>{t(`Description`)}</div>
					<Input style={{width:'250px'}} value={description} onChange={(e) => set_description(e.target.value)} placeholder='Enter Description'></Input>
				</div>
			</IModalBody>
			<IModalFooter>
				<Button className='primary_button_default' disabled={!(label && label.trim() && value && value.trim())} onClick={() => {if(label && label.trim()){addExpression(name ? name : label, label, (value ? value : ''), description)}}}>Create</Button>
			</IModalFooter>
		</Modal>
	)
}

const Parameters_modal = ({modal_type, modal_json, handle_close, update_view, handle_ui_response}) => {
	const [parameters, set_parameters ] = useState([])
	const [sku_parameters, set_sku_parameters] = useState([]);
	const [expressions, set_expressions] = useState([]);
	const [all_category_types, set_all_category_types] = useState([]);
	const [active_label, set_active_label ] = useState("")
	const [add_new_parameter, set_add_new_parameter] = useState(false);
	const toggle_new_parameter = () => set_add_new_parameter(!add_new_parameter)
	const [add_new_expression, set_add_new_expression] = useState(false);
	const toggle_new_expression = () => set_add_new_expression(!add_new_expression)
	const [is_valid_json, set_is_valid_json] = useState(false);
	const [active_tab, set_active_tab] = useState(0);

	const [sku_name, set_sku_name] = useState("");
    const {t} = useTranslation()


	const alert = useAlert()

	const reRenderParameters = () => {
		var parsed_clone = JSON.parse(JSON.stringify(parameters));
		set_parameters(parsed_clone);
	}

	const reRenderSkuParameters = () => {
		var parsed_clone = JSON.parse(JSON.stringify(sku_parameters));
		set_sku_parameters(parsed_clone);
	}

	const reRenderExpressions = () => {
		var parsed_clone = JSON.parse(JSON.stringify(expressions));
		set_expressions(parsed_clone);
	}

	const apply_parameters_json = async () => {
		let resp = await window.Promisify(window.Module.onclick_parameters_modal(JSON.stringify({numeric_parameters:parameters, sku_parameters:sku_parameters, sku_parameter_expressions: expressions})));
		handle_ui_response(resp)
		// var resp_json = JSON.parse(resp)
		// if(!resp_json.toast){
		// console.log('parameters', parameters)
			handle_close();
		// }
	}

	const is_valid_paramters_json = () => { 
		// let parameters_length  = parameters.length
		// let unique_labels =  new Set(parameters.map(x => x.label))
		// let unique_labels_length =  unique_labels.size
		// // console.log('parameter length', parameters_length, unique_labels_length)
		// return unique_labels_length === parameters_length

		let labels = parameters.map(x => x.label)
		let expression_labels = expressions.map(x => x.label)
		let sku_labels = sku_parameters.map(x => x.label)

		return hasNoPrefixPairs(labels.concat(expression_labels, sku_labels).sort((a,b) => a.length < b.length ? -1 : 1))
	}

	const change_parameter = (parameter_name, parameter_type, parameter_value) => {
		var parsed_clone = JSON.parse(JSON.stringify(parameters));
		parsed_clone.map(o => {
			if(o.label == parameter_name){
				o[parameter_type] = parameter_value;
			}
		})
		set_parameters(parsed_clone);
	}

	const change_parameter_property = (parameter, parameter_type, parameter_value, reRender) => {
		// parsed_clone.map(o => {
		// 	if(o.label == parameter_name){
		// 		o[parameter_type] = parameter_value;
		// 	}
		// })
		parameter[parameter_type] = parameter_value
		// var parsed_clone = JSON.parse(JSON.stringify(parameters));
		// set_parameters(parsed_clone);
		reRender()
	}

	var add_parameter = (name, description, label, value, min_value, max_value) => {
		if (label) {
			var param_new = {
				name: name,
				description: description,
				type: "double",
				purpose: "distance",
				value: value,
				label: label,
				min_value: min_value,
				max_value: max_value,
				exposed: true
			}
			
			var parsed_clone = JSON.parse(JSON.stringify(parameters));
			// if(parsed_clone.find((p) => {
			// 	if(p.label.length > label.length){
			// 		return p.label.indexOf(label) === 0
			// 	}else{
			// 		return label.indexOf(p.label) === 0
			// 	}
			// })){
			// 	alert.error("Invalid Parameter. Given Parameter is either a prefix of existing parameter or there exists a parameter which is prefix of given parameter.")
			// 	return;
			// }

			if(parsed_clone.find((p) => {return p.label === label})){
				alert.error("Invalid Parameter. Given Parameter already exists")
				return;
			}
			
			parsed_clone.push(param_new);
			set_parameters(parsed_clone);
			toggle_new_parameter()
		} else {
			alert.error("Parameter Name is mandatory")
		}
	}

	var add_sku_parameter = (name, description, label, value, category_types) => {
		if (label) {
			var param_new = {
				name: name.trim(),
				description: description,
				type: "string",
				purpose: "sku",
				category_types: category_types,
				value: value,
				label: label.trim(),
				min_value: -1000000,
				max_value: 1000000,
				exposed: true
			}

			var parsed_clone = JSON.parse(JSON.stringify(sku_parameters));
			if(parsed_clone.find((p) => {
				// if(p.label.length > label.length){
				// 	return p.label.indexOf(label) === 0
				// }else{
				// 	return label.indexOf(p.label) === 0
				// }
				return p.label === label;
			})){
				// alert.error("Invalid Parameter. Given Parameter is either a prefix of existing parameter or there exists a parameter which is prefix of given parameter.")
				alert.error("Invalid Parameter. Given Parameter already exists")
				return;
			}
			parsed_clone.push(param_new);
			set_sku_parameters(parsed_clone);
			toggle_new_parameter()
		} else {
			alert.error("Parameter Name is mandatory")
		}
	}

	var add_expression = (name, label, value, description) => {
		if (label) {
			var param_new = {
				name: name.trim(),
				type: "string",
				value: value.trim(),
				label: label.trim(),
				description: description,
				exposed: true
			}

			var parsed_clone = JSON.parse(JSON.stringify(expressions));
			parsed_clone.push(param_new);
			set_expressions(parsed_clone);
			toggle_new_expression()
		} else {
			alert.error("Expression Name is mandatory")
		}
	}

	const remove_parameter = (idx) => {
		var parsed_clone = JSON.parse(JSON.stringify(parameters));
		// parsed_clone = parsed_clone.filter(o => {
		// 	return o.label != parameter_name
		// });
		// set_parameters(parsed_clone);
		parsed_clone.splice(idx, 1)
		set_parameters(parsed_clone);
	}

	const remove_sku_parameter = (idx) => {
		var parsed_clone = JSON.parse(JSON.stringify(sku_parameters));
		// parsed_clone = parsed_clone.filter(o => {
		// 	return o.label != sku_parameter_name
		// });
		// set_sku_parameters(parsed_clone);
		parsed_clone.splice(idx, 1)
		set_sku_parameters(parsed_clone);
	}

	const remove_expression = (idx) => {
		var parsed_clone = JSON.parse(JSON.stringify(expressions));
		parsed_clone.splice(idx, 1)
		set_expressions(parsed_clone);
	}
	
	const reset_material_json = () => {
		set_parameters(JSON.parse(JSON.stringify(modal_json && modal_json.numeric_parameters ? modal_json.numeric_parameters : [])));
		set_all_category_types(JSON.parse(JSON.stringify(modal_json && modal_json.all_category_types ? modal_json.all_category_types : [])));
		set_sku_parameters(JSON.parse(JSON.stringify(modal_json && modal_json.sku_parameters ? modal_json.sku_parameters : [])));
		set_expressions(JSON.parse(JSON.stringify(modal_json && modal_json.sku_parameter_expressions ? modal_json.sku_parameter_expressions : [])));
		set_active_label("");
	}

	const get_cat_name = (cat_id) => {
		let cat_name = ''
		let cat_id_split = cat_id.split('_')
		for(let i=0; i<cat_id_split.length - 1; i++){
			cat_name = (i > 0 ? cat_name + ' ' : cat_name) + cat_id_split[i]
		}

		return `${cat_name} ( ${cat_id_split[cat_id_split.length - 1]} )`
	}

	const get_sku_details_from_id = (sku_id, field) => {
		let sku_details = window.Module.get_sku_data_as_string(sku_id)
		sku_details = JSON.parse(sku_details)
		// console.log('sku details', sku_details)
		return sku_details[field]
	}

	const get_sku_display_pic = (sku_id) => {
		let assets_path = window.Module.API.get_assets_path()
		let img_path  = get_sku_details_from_id(sku_id, 'display_pic')
		// console.log('img path', img_path)
		return img_path ? build_path(assets_path, img_path.replace('.png', '_thumbnail.png').replace('.jpg', '_thumbnail.jpg').replace('.jpeg', '_thumbnail.jpeg')) : ''
	}


	useEffect(() => {
		if(modal_json && modal_type == "add_parameters_modal"){
			reset_material_json();
			set_sku_name(JSON.parse(window.Module.get_sku_details_from_core()).sku_name)
		}
	},[modal_json, modal_type])

	useEffect(() => {
		set_is_valid_json(is_valid_paramters_json())
	}, [parameters, expressions, sku_parameters]);
	

	return(
		<React.Fragment>
			<AddExpression open={add_new_expression} toggle={toggle_new_expression} addExpression={add_expression} />
			<AddParameter open={add_new_parameter} toggle={toggle_new_parameter} addParameter={active_tab == 0 ? add_parameter : add_sku_parameter} is_sku={active_tab == 1} all_category_types={all_category_types} update_view={update_view} get_cat_name={get_cat_name} get_sku_details_from_id={get_sku_details_from_id} get_sku_display_pic={get_sku_display_pic}/>
			<Modal className="modal-dialog-centered" size="xl" style={{ overflow: 'auto' }} isOpen={modal_type == "add_parameters_modal"}>
				<IModalHeader toggle={handle_close}>Parameters</IModalHeader>
				<IModalBody style={{height: '600px', overflow: 'auto'}}>
					<div className='mb-3 flex_property justify-content-between'>
						<div>User defined parameters of <b>{sku_name}</b></div>
						<Button className='secondary_button_default' onClick={active_tab == 2 ? toggle_new_expression : toggle_new_parameter}> {active_tab == 0 ? 'Add new parameter' : active_tab == 1 ? 'Add new sku parameter': 'Add new expression'}</Button>
					</div>
					<UncontrolledINavbar tabs={['Numeric Parameters', 'SKU Parameters', 'Expressions']} set_active_tab={set_active_tab}/>
					<TabContent activeTab={'tabs' + active_tab} >
						<TabPane tabId={'tabs0'}>
							<ITable bordered style={{ backgroundColor: 'white' }} style_container={{ maxWidth: '100%', overflow: 'auto', maxHeight: '500px' }}>
								<ITableHeader style={{ position: 'sticky', top: '0px', backgroundColor: 'white' }}>
									<ITH>Order</ITH>
									{/* <ITH>Status</ITH> */}
									<ITH>Parameter Variable</ITH>
									<ITH>Display Name</ITH>
									<ITH>Parameter Value</ITH>
									<ITH>Min Value</ITH>
									<ITH>Max Value</ITH>
									<ITH>Description</ITH>
									{/* <ITH>Places Used</ITH> */}
									<ITH>Actions</ITH>
								</ITableHeader>
								<ITableBody>
									{parameters.map((o, idx) => (
										<ITableRow>
											<ITD>{idx + 1}</ITD>
											{/* <ITD style={{textAlign: 'center'}}> */}
											{/* <i onClick={() => change_parameter(o.label, 'exposed', !o.exposed)} style={{cursor: 'pointer'}} className={o.exposed ? 'fa fa-check-square-o' : 'fa fa-square-o'}></i> */}
											{/* <Checkbox checked={o.exposed} onClick={() => change_parameter_property(o, 'exposed', !o.exposed)}/> */}
											{/* </ITD> */}
											{/* <ITD>{`${o.label} ${o.purpose == "distance" ? "(in mm)" : ""}`}</ITD> */}
											<ITD className={parameters.filter(x => { if (o.label.length > x.label.length) { return o.label.indexOf(x.label) == 0 } else { return x.label.indexOf(o.label) == 0 } }).length > 1 ? 'invalid_parameter_input' : ''}>
												{/* {`${o.label}`} */}
												<Input value={o.label} onChange={(e) => change_parameter_property(o, 'label', (e.target.value ? e.target.value.replace(' ', '') : e.target.value), reRenderParameters)} style={{ ...styles_modals.input_styles_none }} type='text' />
											</ITD>
											<ITD>
												{/* {`${o.name}`} */}
												<Input value={o.name} onChange={(e) => change_parameter_property(o, 'name', (e.target.value), reRenderParameters)} style={{ ...styles_modals.input_styles_none }} type='text' />
											</ITD>
											<ITD>
												<Input value={o.value} onChange={(e) => change_parameter_property(o, 'value', Number(e.target.value), reRenderParameters)} style={{ ...styles_modals.input_styles_none }} type='number' />
											</ITD>
											<ITD>
												<Input value={o.min_value} onChange={(e) => change_parameter_property(o, 'min_value', Number(e.target.value), reRenderParameters)} style={{ ...styles_modals.input_styles_none }} type='number' />
											</ITD>
											<ITD>
												<Input value={o.max_value} onChange={(e) => change_parameter_property(o, 'max_value', Number(e.target.value), reRenderParameters)} style={{ ...styles_modals.input_styles_none }} type='number' />
											</ITD>
											<ITD style={{ overflowX: 'auto' }}>
												<Input value={o.description} onChange={(e) => change_parameter_property(o, 'description', Number(e.target.value), reRenderParameters)} style={{ ...styles_modals.input_styles_none }} type='text' />
												{/* {o.description ? o.description : ''} */}
											</ITD>
											{/* <ITD>
										{o.count ? o.count : 0}
									</ITD> */}
											<ITD>
												{!(o.is_from_host)?(
													<div onClick={() => { remove_parameter(idx) }} className="" style={{ color: 'var(--red_default)', cursor: 'pointer' }}>{t(`Remove`)}</div>
												):(
													<div>{t(`Inherited from host`)}</div>
												)}
											</ITD>
										</ITableRow>
									))}
								</ITableBody>
							</ITable>
						</TabPane>
						<TabPane tabId={'tabs1'}>
							<ITable bordered style={{ backgroundColor: 'white' }} style_container={{ maxWidth: '100%', overflow: 'auto', maxHeight: '500px' }}>
								<ITableHeader style={{ position: 'sticky', top: '0px', backgroundColor: 'white' }}>
									<ITH>Order</ITH>
									{/* <ITH>Status</ITH> */}
									<ITH>Parameter Variable</ITH>
									{/* <ITH>Display Name</ITH> */}
									<ITH>Category Type</ITH>
									<ITH>Parameter Value</ITH>
									<ITH>Description</ITH>
									{/* <ITH>Places Used</ITH> */}
									<ITH>Actions</ITH>
								</ITableHeader>
								<ITableBody>
									{sku_parameters.map((o, idx) => (
										<ITableRow>
											<ITD>{idx + 1}</ITD>
											{/* <ITD style={{textAlign: 'center'}}> */}
											{/* <i onClick={() => change_parameter(o.label, 'exposed', !o.exposed)} style={{cursor: 'pointer'}} className={o.exposed ? 'fa fa-check-square-o' : 'fa fa-square-o'}></i> */}
											{/* <Checkbox checked={o.exposed} onClick={() => change_parameter_property(o, 'exposed', !o.exposed)}/> */}
											{/* </ITD> */}
											{/* <ITD>{`${o.label} ${o.purpose == "distance" ? "(in mm)" : ""}`}</ITD> */}
											<ITD className={sku_parameters.filter(x => { if (o.label.length > x.label.length) { return o.label.indexOf(x.label) == 0 } else { return x.label.indexOf(o.label) == 0 } }).length > 1 ? 'invalid_parameter_input' : ''}>
												{/* {`${o.label}`} */}
												<Input value={o.label} onBlur={(e) => { change_parameter_property(o, 'label', (e.target.value ? e.target.value.trim() : ''), reRenderSkuParameters); change_parameter_property(o, 'name', (e.target.value ? e.target.value.trim() : ''), reRenderSkuParameters) }} onChange={(e) => { change_parameter_property(o, 'label', (e.target.value ? e.target.value : ''), reRenderSkuParameters); change_parameter_property(o, 'name', (e.target.value ? e.target.value : ''), reRenderSkuParameters) }} style={{ ...styles_modals.input_styles_none }} type='text' />
											</ITD>
											{/* <ITD>
										// {`${o.name}`}
										<Input value={o.name} onChange={(e) => change_parameter_property(o, 'name', (e.target.value))} style={{ ...styles_modals.input_styles_none }} type='text' />
									</ITD> */}
											<ITD>
												{/* <Input value={o.category_types && o.category_types.length ? o.category_types[0] : 'none'} readOnly onChange={(e) => change_parameter_property(o, 'category_types', Number(e.target.value))} style={{ ...styles_modals.input_styles_none }} type='select'>
											<option value={'none'}>None</option>
											{
												all_category_types && all_category_types.length ? all_category_types.map((cat_type, idx) => (
													<option key={idx} value={cat_type.id}>{cat_type.display_name}</option>
												))
												:''
											}
										</Input> */}
												<Input value={o.category_types && o.category_types.length ? get_cat_name(o.category_types[0]) : 'none'} readOnly style={{ ...styles_modals.input_styles_none, textTransform: 'capitalize' }} type='text'></Input>

											</ITD>
											<ITD>
												{/* <Input value={o.value} style={{ ...styles_modals.input_styles_none }} type='text' /> */}
												<div className='flex_property'>
													<img src={get_sku_display_pic(o.value)} height={'32px'} className='mr-2'></img>
													<div>{get_sku_details_from_id(o.value, 'sku_name')}</div>
												</div>
												{/* <div onClick={() => {window.Module.open_sku_parameter_selector_carousel(JSON.stringify(o.category_types)); update_view()}}>Add SKU</div> */}
											</ITD>
											<ITD style={{ overflowX: 'auto' }}>
												<Input value={o.description} onChange={(e) => change_parameter_property(o, 'description', (e.target.value), reRenderSkuParameters)} style={{ ...styles_modals.input_styles_none }} type='text' />
												{/* {o.description ? o.description : ''} */}
											</ITD>
											{/* <ITD>
										{o.count ? o.count : 0}
									</ITD> */}
											<ITD>
												{!(o.is_from_host)?(
													<div onClick={() => { remove_sku_parameter(idx) }} className="" style={{ color: 'var(--red_default)', cursor: 'pointer' }}>{t(`Remove`)}</div>
												):(
													<div>{t(`Inherited from host`)}</div>
												)}
											</ITD>
										</ITableRow>
									))}
								</ITableBody>
							</ITable>
						</TabPane>
						<TabPane tabId={'tabs2'}>
							<ITable bordered style={{ backgroundColor: 'white' }} style_container={{ maxWidth: '100%', overflow: 'auto', maxHeight: '500px' }}>
								<ITableHeader style={{ position: 'sticky', top: '0px', backgroundColor: 'white' }}>
									<ITH>Order</ITH>
									<ITH>Parameter Variable</ITH>
									<ITH>Expression Name</ITH>
									<ITH>Parameter Expression</ITH>
									<ITH>Description</ITH>
									<ITH>Actions</ITH>
								</ITableHeader>
								<ITableBody>
									{expressions.map((o, idx) => (
										<ITableRow>
											<ITD>{idx + 1}</ITD>
											<ITD className={expressions.filter(x => { if (o.label.length > x.label.length) { return o.label.indexOf(x.label) == 0 } else { return x.label.indexOf(o.label) == 0 } }).length > 1 ? 'invalid_parameter_input' : ''}>
												<Input value={o.label} onBlur={(e) => { change_parameter_property(o, 'label', (e.target.value ? e.target.value.trim() : ''), reRenderExpressions)}} onChange={(e) => { change_parameter_property(o, 'label', (e.target.value ? e.target.value : ''), reRenderExpressions)}} style={{ ...styles_modals.input_styles_none }} type='text' />
											</ITD>
											<ITD>
												<Input value={o.name} onChange={(e) => change_parameter_property(o, 'name', (e.target.value), reRenderExpressions)} style={{ ...styles_modals.input_styles_none }} type='text' />
											</ITD>
											<ITD style={{ overflowX: 'auto' }}>
												<Input value={o.value} onChange={(e) => change_parameter_property(o, 'value', (e.target.value), reRenderExpressions)} style={{ ...styles_modals.input_styles_none }} type='text' />
											</ITD>
											<ITD style={{ overflowX: 'auto' }}>
												<Input value={o.description} onChange={(e) => change_parameter_property(o, 'description', (e.target.value), reRenderExpressions)} style={{ ...styles_modals.input_styles_none }} type='text' />
											</ITD>
											<ITD>
												<div onClick={() => { remove_expression(idx) }} className="" style={{ color: 'var(--red_default)', cursor: 'pointer' }}>{t(`Remove`)}</div>
											</ITD>
										</ITableRow>
									))}
								</ITableBody>
							</ITable>
						</TabPane>
					</TabContent>
				</IModalBody>
				<IModalFooter>
					<Button onClick={reset_material_json} className="secondary_button_default" type="button">Reset</Button>
					{/* <Tooltip title={is_valid_json ? '' : 'Ensure that any parameter is not a prefix of another parameter and no Parameter is empty'} mouseEnterDelay={0.5} placement='top'> */}
						{/* <div> */}
							<Button id='parameters_update_button' /*title={is_valid_json ? '' : 'Paramters not Unique. Please Modify your paramters and update'} disabled={!is_valid_json}*/ onClick={is_valid_json ? apply_parameters_json : null} className="primary_button_default" style={{opacity: is_valid_json?1:0.5}} type="button">Update</Button>
						{/* </div> */}
					{/* </Tooltip> */}
					<UncontrolledTooltip autohide={false} placement='top' target={'parameters_update_button'} style={{borderRadius:'8px', padding:'8px 12px', maxWidth:'400px'}}>
						<div>
							{is_valid_json ? 'Click to Update the Parameters' : 'Ensure that any parameter is not a prefix of another parameter and no Parameter is empty'}
						</div>
					</UncontrolledTooltip>
					
				</IModalFooter>
			</Modal>
		</React.Fragment>
	)
}
const GetUserNameModal = ({ modal_type, handle_close, modal_json, handle_ui_response }) => {
	const [name, set_name] = useState("");
    const {t} = useTranslation()

	const submit_name = async(user_name) => {
		let resp = await window.Promisify(window.Module.set_anonymous_user_name(user_name))
		handle_ui_response(resp)
		onclick_handleclose()
	}

	const onclick_handleclose = () => {
		// handle_close()
		set_name("")
	}

	return (
		<Modal className="modal-dialog-centered" size="md" isOpen={modal_type === 'user_name_input'}>
			{/* <CardHeader style={{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}}>
			<div>
				<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Create
						Branch</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}}
							onClick={handle_close}>×</span></span></h5>
			</div>
		</CardHeader> */}
			<IModalHeader toggle={() => submit_name('')}>User Name</IModalHeader>
			{/* <CardBody style={{backgroundColor: COLORS.white }} className="px-lg-5 py-lg-5">
			<Row>
				<Col>
				<div>Name</div>
				<Input id="create_branch_name" type='text' />
				</Col>
			</Row>
			<div className="text-right" style={{marginTop:"15px"}}>
				<Button onClick={submit_values} className='blue_button' type="button">Create Branch</Button>&nbsp;
			</div>
		</CardBody> */}
			<IModalBody>
				<div className='flex_property'>
					<div className='mr-3'>{t(`User Name`)}</div>
					<Input placeholder='Enter User Name' style={{...styles_modals.input_styles, padding:'0px 8px', flex: 1}} type='text' onKeyUp={(e) => {if(e.key == 'Enter'){submit_name(name)}}} value={name} onChange={(e) => set_name(e.target.value)}/>
				</div>
			</IModalBody>
			<IModalFooter>
				<Button onClick={() => submit_name(name)} className='primary_button_default' type="button">Submit</Button>&nbsp;
			</IModalFooter>
		</Modal>
	)
}

const Texture_Structure = ({modal_json, label, comp_type, change_property, parsed_properties, name='', style={}}) => {
	return (
		<>
			{
				comp_type == 1 ?
				<div style={{display: 'flex', gap: '4px', alignItems: 'center', ...style}}>
				<div style={{fontWeight: '400', fontFamily: 'DM SANS', color: '#676878'}}>{name}</div>
				<Input type="select" style={{height:'100%', fontSize:'12px', width:'25%', padding: 'unset', flex: 1}} value={parsed_properties[label]} onChange={e => {change_property(label, e.target.value)}}>
                    {(modal_json && modal_json["material_template"] && modal_json["material_template"]["properties"] && modal_json["material_template"]["properties"].find(item => item.label == label) && (modal_json["material_template"]["properties"].find(item => item.label == label))["value"] && (modal_json["material_template"]["properties"].find(item => item.label == label))["value"]["string_enum"]) && (modal_json["material_template"]["properties"].find(item => item.label == label))["value"]["string_enum"].map((option, index) => (
                        <option key={index} value={option.value} selected={option.value === parsed_properties[label]}>{option.label}</option>
                    ))}
                </Input> 
				</div> :
				comp_type == 2 ?
				<div style={{display: 'flex', gap: '4px', alignItems: 'center', ...style}}>
				<div style={{fontWeight: '400', fontFamily: 'DM SANS', color: '#676878'}}>{name}</div>
				<Input type="number" style={{height:'100%', fontSize:'12px', width:'25%', paddingRight: '2px', flex: 1}} value={parsed_properties[label]} onChange={e => {change_property(label, Number(e.target.value));}}/>
				</div>
				: ''
			}
		</>
	)
}

const Materials_modal = ({modal_type, modal_json, handle_close, update_view, set_page_loader, update_modal_json, handle_ui_response, submit_for_delete_confirm}) => {
	const alert = useAlert()
	const [material_name, set_material_name ] = useState(modal_json ? modal_json.name:'')
	const [material_template_id, set_material_template_id ] = useState(modal_json ? modal_json.name:'')
	const [material_template_name, set_material_template_name ] = useState(modal_json ? modal_json.name:'')
	const [template_properties, set_template_properties ] = useState(modal_json ? modal_json.template_properties:[])
	const [parsed_properties, set_parsed_properties ] = useState(modal_json ? modal_json.parsed_properties:{})
	const [modified_material_template_id, set_modified_material_template_id ] = useState('')
	const [tooltip_open, set_tooltip_open ] = useState(false);

	const [color_open, set_color_open] = useState('#000000');
	const [is_color_changed, set_is_color_changed] = useState(false);
	const [opacity_open, set_opacity_open] = useState(false);
	const [reflection_open, set_reflection_open] = useState(false);
	const [refraction_open, set_refraction_open] = useState(false);
	const [emmissivity_open, set_emmissivity_open] = useState(false);
	const [base_map, set_base_map] = useState(false)
	const [base_map_url, set_base_map_url] = useState(false)
	const [base_map_name, set_base_map_name] = useState(false)
	const [bump_map, set_bump_map] = useState(false);
	const [normal_map, set_normal_map] = useState(false);
	const [bump_map_name, set_bump_map_name] = useState(false);
	const [normal_map_name, set_normal_map_name] = useState(false)
	const [bump_map_url, set_bump_map_url] = useState(false)
	const [normal_map_url, set_normal_map_url] = useState(false)
	const [is_color, set_is_color] = useState(false)
	const toggle_color = () => set_color_open(!color_open)
	const toggle_opacity = () => set_opacity_open(!opacity_open)
	const toggle_reflection = () => set_reflection_open(!reflection_open)
	const toggle_refraction = () => set_refraction_open(!refraction_open)
	const toggle_emmissivity = () => set_emmissivity_open(!emmissivity_open)
	const [material_preview_src, set_material_preview_src] = useState(false);
	const [defaultPropertyState, setDefaultPropertyState] = useState({});
	const [initialized_defaults, set_initialized_defaults] = useState(false);
	const [ mode, set_mode ] = useState('image');
	const [ texture_initialized, set_texture_initialized ] = useState(false);
    const {t} = useTranslation()

	const grouped_properties = {
		"Base" : [
			"map",
			"texture_wrapping",
			"texture_width",
			"texture_height"
		],
		"Bump and Normal Map": [
			"bump_map",
			"normal_map",
			// "bump_scale",
			// "normal_map_strength"
		],
		"Reflection" : [
			"specular",
			"roughness",
			"metalness"
		],
		"Refraction" : [
			"transmission",
			"refractive_index",
		],
		// "Opacity" : [
		// 	"opacity",
		// ],
		"Emmissivity" : [
			"emissive_intensity",
		]
	}

	const properties_order = {
		"map": 0,
		"texture_wrapping" : 1,
		"texture_width" : 2,
		"texture_height": 3,

		"bump_map": 0,
		"normal_map": 1,

		"specular": 0,
		"roughness": 1,
		"metalness": 2,

		"transmission": 0,
		"refractive_index": 1,

		"emissive_intensity": 0,

	}

	const keysGroupedObject = Object.keys(grouped_properties)

	const ui_custom_styling = {
		"refractive_index": "number_with_select_preview"
	}

	const select_options_material_properties = {
		"refractive_index": [{name: 'Custom', value: ''},{name: 'Crystal', value: '2'},{name: 'Glass', value: '1.5'},{name: 'Ice', value: '1.309'},{name: 'Water', value: '1.325'}]
	}

	const custom_data = {
		"refractive_index": {
			name: "Refraction Index (IOR)",
			tooltip:
				<div>
					<div className='mb-2'><b>IOR: Index of Refraction</b></div>
					<div style={{color: "#A7A8B2"}}>
						Defines how much internal reflection the transparent material has.<br />
						EG: diamond has more IOR, than glass.
					</div>
				</div>
		}
	}
	// useEffect(() => {
	// 	console.log("KPP useffect1", parsed_properties.bump_map)
	// 	if(modal_type == "edit_material" && parsed_properties){
	// 		if(parsed_properties.bump_map)
	// 			set_bump_map_url(get_src_from_texture_id(parsed_properties.bump_map))
	// 		if(parsed_properties.normal_map)
	// 			set_normal_map_url(get_src_from_texture_id(parsed_properties.normal_map))
	// 	}
	// }, [modal_type, parsed_properties.bump_map])

	const update_base_map = async(base_map) => {
		if(base_map){
			set_base_map(base_map)
			set_base_map_name(base_map.name)
			try{
				set_page_loader({
					show:true,
					text:'Please wait, uploading texture...'
				})
				var fd = getFormData(base_map)
				var resp_texture = await general_fetch({full_url: window.Module.API.server_path + "/texture/add", body:fd, is_form_data:true });
				if(resp_texture && resp_texture.id){
					change_property("map", resp_texture.id, true, false)
					set_base_map_url(get_src_from_texture_id(resp_texture.id))
				}
			} catch(err){
				console.log(err)
			}
			set_page_loader({
				show:false,
				text:''
			})
		}else{
			set_base_map(null)
			set_base_map_name("")
			set_base_map_url("")
			change_property("map", null, true, false)
		}
	}

	const update_normal_map = async(normal_map) => {
		if(normal_map){
			set_normal_map(normal_map)
			set_normal_map_name(normal_map.name)
			try{
				set_page_loader({
					show:true,
					text:'Please wait, uploading texture...'
				})
				var fd = getFormData(normal_map)
				var resp_texture = await general_fetch({full_url: window.Module.API.server_path + "/texture/add", body:fd, is_form_data:true });
				if(resp_texture && resp_texture.id){
					change_property("normal_map", resp_texture.id, true, false)
					set_normal_map_url(get_src_from_texture_id(resp_texture.id))
				}
			} catch(err){
				console.log(err)
			}
			set_page_loader({
				show:false,
				text:''
			})
		}else{
			set_normal_map(null)
			set_normal_map_name("")
			set_normal_map_url("")
			change_property("normal_map", null, true, false)
		}
	}

	const update_bump_map = async(bump_map) => {
		if(bump_map){
			set_bump_map(bump_map)
			set_bump_map_name(bump_map.name)
			try{
				set_page_loader({
					show:true,
					text:'Please wait, uploading texture...'
				})
				var fd = getFormData(bump_map)
				var resp_texture = await general_fetch({full_url: window.Module.API.server_path + "/texture/add", body:fd, is_form_data:true });
				if(resp_texture && resp_texture.id){
					change_property("bump_map", resp_texture.id, true, false)
					set_bump_map_url(get_src_from_texture_id(resp_texture.id))
				}
			} catch(err){
				console.log(err)
			}
			set_page_loader({
				show:false,
				text:''
			})
		}else{
			set_bump_map(null)
			set_bump_map_name("")
			set_bump_map_url("")
			change_property("bump_map", null, true, false)
		}
	}
	

	const delete_texture = (type) => {
		change_property(type, null);
	}

	const getFormData = (texture_image) => {
		var fd = new FormData();	
		texture_image && fd.append('file', texture_image)
		fd.append('name', texture_image.name)
		return fd;
	}

	const apply_material_json_proxy = async() => {
		try{
			if(!modal_json.apply_button_text){
				logToSentry(`Apply Button text not provided in material modal, modal json - ${JSON.stringify(modal_json)}`, "Apply Button text not provided in material modal")
			}
			if((modal_json.apply_button_text && modal_json.apply_button_text.includes("Clone and apply")) && (base_map || (parsed_properties && parsed_properties["map"] && is_color) || (is_color_changed))){ // new base texture is uploaded (changed either from texture/color) (or) texture is changed to color (or) color is changed to another color
				submit_for_delete_confirm(apply_material_json, () => {}, <span><span style={{fontWeight: 'bold'}}>Base Texture / color</span> has been changed, Cloned material will have same pricing as the source material in all outputs, are you sure want to proceed?</span>, {"name": "Proceed", "type": "blue_button", "cancel_type": "white_button"})
			}
			else{
				apply_material_json()
			}
		}catch(err){
			alert.error("Something went wrong. Please try again")
			handleError(err)
		}
	}

	const switch_material = async (mat_id, json_item, is_panel_pop_up) => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        active_view.active_material_select_item_id = json_item.id;
        var onclick_params = {}
        onclick_params.panel_id = json_item.id
        onclick_params.sku_id = mat_id

		//Any other params you use from json_item needs to be handled for clone and apply as well

        var final_params = json_item.pass_params_to_carousel_onclick?JSON.stringify(onclick_params):mat_id;
        if(window.debug_mode){
            window.add_debug_log(json_item.route + "(\"" + final_params.replace(/"/g, '\\"') + "\");");
        }
        var resp = await window.Promisify(window.Module[json_item.route](final_params));
        handle_ui_response(resp);
        if(is_panel_pop_up){
            repopulate_panel_pop_up();
        }else{
            repopulate_panel();
        }
        update_view();

        active_design.delete();
        active_view.delete();
    }

	const apply_material_json = async() => {
		if(is_color){
			delete_texture("map");
		}
		else{
			if(!base_map && !base_map_url){
				alert.error('Either upload a texture or select a color for Base')
				return;
			}
			// if(base_map){
			// 	try{
			// 		set_page_loader({
			// 			show:true,
			// 			text:'Please wait, uploading texture...'
			// 		})
			// 		var fd = getFormData(base_map)
			// 		var resp_texture = await general_fetch({full_url: window.Module.API.server_path + "/texture/add", body:fd, is_form_data:true });
			// 		resp_texture && resp_texture.id && change_property("map", resp_texture.id)
			// 	} catch(err){
			// 		console.log(err)
			// 	}
			// 	set_page_loader({
			// 		show:false,
			// 		text:''
			// 	})
			// }

		}
		// if(bump_map){
		// 	try{
		// 	set_page_loader({
		// 		show:true,
		// 		text:'Please wait, uploading texture...'
		// 	})
		// 	var fd = getFormData(bump_map)
		// 	var resp_texture = await general_fetch({full_url: window.Module.API.server_path + "/texture/add", body:fd, is_form_data:true });
		// 	resp_texture && resp_texture.id && change_property("bump_map", resp_texture.id)
		// 	} catch(err){
		// 		console.log(err)
		// 	}
		// 	set_page_loader({
        //         show:false,
        //         text:''
        //     })
		// }
		// else 
		if(!bump_map_url)
			delete_texture("bump_map")
		// if(normal_map){
		// 	try{
		// 		set_page_loader({
		// 			show:true,
		// 			text:'Please wait, uploading texture...'
		// 		})
		// 		var fd = getFormData(normal_map)
		// 		var resp_texture = await general_fetch({full_url: window.Module.API.server_path + "/texture/add", body:fd, is_form_data:true });
		// 		resp_texture && resp_texture.id && change_property("normal_map", resp_texture.id)
		// 		} catch(err){
		// 			console.log(err)
		// 		}
		// 		set_page_loader({
		// 			show:false,
		// 			text:''
		// 		})
		// }
		// else 
		if(!normal_map_url)
			delete_texture("normal_map")
		var result = {
			name: material_name,
			parsed_properties: parsed_properties, 
			mode : mode
		}
		if(modal_json.clone_material){
			if(window.debug_mode) {
				window.add_debug_log("ID based logic, fix this:")
				window.add_debug_log("create_fe_material" + "(\"" + modal_json.old_id + "\", \"" + JSON.stringify(result).replace(/"/g, '\\"') + "\");");
			}

			var new_id = window.Module.create_fe_material(modal_json.old_id, JSON.stringify(result));

			if(modal_json.apply_data) {
				switch_material(new_id, modal_json.apply_data, modal_json.apply_data.is_panel_pop_up);
			}else {
				window.Module[modal_json.panel_route](new_id);
			}
			repopulate_panel();
		}else if(modal_json.new_material){
			result.modified_material_template_id = modified_material_template_id;

			if(window.debug_mode) {
				window.add_debug_log("create_new_material" + "(\"" + modal_json.old_id + "\", \"" + JSON.stringify(result).replace(/"/g, '\\"') + "\");");
			}
			var new_id = window.Module.create_new_material(modal_json.old_id, JSON.stringify(result));
			repopulate_panel();
		}else{

			if(window.debug_mode) {
				window.add_debug_log("edit_existing_material" + "(\"" + modal_json.old_id + "\", \"" + JSON.stringify(result).replace(/"/g, '\\"') + "\");");
			}
			window.Module.edit_existing_material(modal_json.old_id, JSON.stringify(result));
		}
		repopulate_panel_pop_up();
		
		// handle_close();
		onclick_handle_close()
	}

	const change_property = (property_name, property_value, update_preview = true, is_redraw = true) => {
		var parsed_clone = parsed_properties
		parsed_clone[property_name] = property_value;
		if(update_preview){
			if(mode && mode != "image"){
				update_material_preview(parsed_clone,is_redraw, mode === "cube");
			}
		}
		set_parsed_properties(JSON.parse(JSON.stringify(parsed_clone)));
	}

	const update_material_preview = (props, is_redraw, _is_cube) => {
		//add return in first line of this function to rollback material preview

		try{
			if ((!window.material_preview_context_already_created) && window.Module.create_material_preview_context("material_preview_canvas")) {
				window.Module.init_material_preview_texture(window.location.origin);
				window.material_preview_context_already_created = true;
			}
		} catch (err) {
			window.material_preview_context_already_created = true;
			console.error("Context not created", err)
		}

		var image_src = "";
		let bump_map_url = "";
		let normal_map_url = "";
		var color = {r:1,g:1,b:1};
		var metalness = 0;
		var roughness = 0;
		var specular = 0;
		let normal_map_strength = 0;
		let bump_map_strength = 0;
		if(props){
			if(props["map"]){
				image_src = get_src_from_texture_id(props["map"]);
			}
			if(props["bump_map"]){
				bump_map_url = get_src_from_texture_id(props["bump_map"]);
			}
			if(props["normal_map"]){
				normal_map_url = get_src_from_texture_id(props["normal_map"]);
			}
			if(props["color"]){
				color = props["color"];
			}
			if(props["metalness"]){
				metalness = props["metalness"];
			}
			if(props["roughness"]){
				roughness = props["roughness"];
			}
			if(props["specular"]){
				specular = props["specular"];
			}
			if(props["normal_map_strength"]){
				normal_map_strength = props["normal_map_strength"];
			}
			if(props["bump_scale"]){
				bump_map_strength = props["bump_scale"];
			}
		}
		
		if(is_redraw){
			try{
				window.Module.draw_material_preview(metalness, roughness, specular, color.r, color.g, color.b, normal_map_strength, bump_map_strength, _is_cube);
			}catch(err){
				console.error("cant update preview", err)
			}
		}else{
			try{
				set_texture_initialized(true)
				// window.Module.init_material_preview_texture(window.Module.API.react_app_path, image_src, color.r, color.g, color.b, metalness, roughness, specular);
				window.Module.prepare_texture(image_src ? image_src : '', normal_map_url ? normal_map_url : '', bump_map_url ? bump_map_url : '');
				window.Module.draw_material_preview(metalness, roughness, specular, color.r, color.g, color.b, normal_map_strength, bump_map_strength, _is_cube);
				window.Module.draw_material_preview(metalness, roughness, specular, color.r, color.g, color.b, normal_map_strength, bump_map_strength, _is_cube);
			}catch(err){
				console.error("cant init preview", err)
			}
		}

		if(document.getElementById('material_preview_canvas')){
			set_material_preview_src(document.getElementById('material_preview_canvas').toDataURL())
		}
	}
	
	const reset_material_json = () => {
		set_material_name(modal_json["name"]);
		set_material_template_id(modal_json["material_template"]["id"]);
		set_material_template_name(modal_json["material_template"]["name"]);
		set_template_properties(modal_json["material_template"]["properties"]);
		set_parsed_properties(modal_json["parsed_properties"] || {});
		set_bump_map_url(get_src_from_texture_id(modal_json["parsed_properties"]["bump_map"]))
		set_color_open(get_hex_from_rgb(modal_json["parsed_properties"]["color"]))
		set_normal_map_url(get_src_from_texture_id(modal_json["parsed_properties"]["normal_map"]))
		// set_bump_map_name(get_name_from_texture_id(modal_json["parsed_properties"]["bump_map"]))
		// set_normal_map_name(get_src_from_texture_id(modal_json["parsed_properties"]["normal_map"]))
		set_base_map_url(get_src_from_texture_id(modal_json["parsed_properties"]["map"]))
		if(modal_json["parsed_properties"]["map"])
			set_is_color(false)
		else
			set_is_color(true)
		// set_base_map_name(get_name_from_texture_id(modal_json["parsed_properties"]["map"]))

		console.log("template_properties = ", modal_json["material_template"]["properties"]);
		console.log("parsed_properties = ",);
		
		
		var props = modal_json["parsed_properties"];
		// update_material_preview(props);
	}

	const reset_to_store = () => {
		if(window['Module']){
			window.Module.reset_modified_properties_onclick(modal_json.old_id);
			repopulate_panel();
			repopulate_panel_pop_up();
			// handle_close();
			alert.info("Material properties successfully reset to default value.")
			update_view()
		}
	}

	const switch_material_template = (new_template_id) => {
		var new_template_data = JSON.parse(window.Module.get_parsed_properties_of_material_template(new_template_id));
		set_modified_material_template_id(new_template_id);
		set_material_template_id(new_template_data["material_template"]["id"]);
		set_material_template_name(new_template_data["material_template"]["name"]);
		set_template_properties(new_template_data["material_template"]["properties"]);

		var new_parsed_properties = new_template_data["parsed_properties"];
		var current_parsed_properties = _deref(parsed_properties);
		if(current_parsed_properties.map){
			new_parsed_properties.map = new_parsed_properties.map
		}
		if(current_parsed_properties.bump_map){
			new_parsed_properties.bump_map = new_parsed_properties.bump_map
		}
		if(current_parsed_properties.normal_map){
			new_parsed_properties.normal_map = new_parsed_properties.normal_map
		}
		if(current_parsed_properties.color){
			new_parsed_properties.color = new_parsed_properties.color
		}

		set_parsed_properties(new_parsed_properties);
	}

	const get_src_from_texture_id = (tex_id) => {
		if(window['Module'] && tex_id){
			return window.Module.get_src_from_texture_id(tex_id);
		}else{
			return "";
		}
	}

	const get_name_from_texture_id = (tex_id) => {
		if(window['Module'] && tex_id){
			return window.Module.get_name_from_texture_id(tex_id);
		}else{
			return "";
		}
	}

	const get_rgb_from_hex = (hex) => {
		if(window['Module']){
			return JSON.parse(window.Module.get_rgb_from_hex(hex));
		}else{
			return {r:0,g:0,b:0};
		}
	}

	const get_hex_from_rgb = (rgb) => {
		if(window['Module']){
			return window.Module.get_hex_from_rgb(JSON.stringify(rgb));
		}else{
			return "#000000";
		}
	}

	const toggle_info_tooltip = () => {
		set_tooltip_open(!tooltip_open);
	}

	const onclick_handle_close_textures = () => {
		set_bump_map(false)
		set_bump_map_url(false)
		set_bump_map_name(false)
		set_normal_map(false)
		set_normal_map_url(false)
		set_normal_map_name(false)
		set_base_map(false)
		set_base_map_url(false)
		set_base_map_name(false)
		set_color_open("#000000")
		set_is_color(false)
		set_is_color_changed(false)

	}

	const onclick_handle_close = () => {
		handle_close()
		onclick_handle_close_textures()
		set_initialized_defaults(false)
		setDefaultPropertyState({})
		set_mode('image')
		set_texture_initialized(false)
	}

	const getMapsPreview = () => {
		if(bump_map_url && normal_map_url)
			return "Both present"
		if(bump_map_url)
			return "Only bump"
		if(normal_map_url)
			return "Only normal"
		return "No maps"
	}

	const parse_property = (property) => {
		let properties_list = grouped_properties[property].sort((a,b) => properties_order[a] - properties_order[b]);
		let preview = ''
		let defaultState = false
		let initialized = false
		
		properties_list.map(x => {
			preview = preview + `${x.replace('_', ' ')}: ${typeof(parsed_properties[x]) == 'number' ? Math.round(parsed_properties[x]*100)/100 : parsed_properties[x]} `
			if(parsed_properties[x]){
				defaultState = true
			}
		})

		if(property.includes('Base') || property.includes('Reflection')){
			defaultState = true
		}else if(property.includes('Opacity')){
			defaultState = false
		}else if(property.includes('Refraction')){
			if(parsed_properties && parsed_properties.transmission){
				defaultState = true
			}else{
				defaultState = false
			}
		}

		if(property.includes("Base"))
			preview = `(${is_color ? 'Color' : 'Texture'})`;
		else
			preview = property.includes('Bump and Normal Map') ? `(${getMapsPreview()})` : `(${preview})`
		// console.log("KPP preview", property, preview)

		let header = <div className='flex_property'>{property} &nbsp; <div style={{textTransform:'capitalize', color: COLORS.gray9}}>{preview}</div></div>

		return {header, defaultState, initialized}


	}

	useEffect(() => {
		if(modal_type == "edit_material" && modal_json && modal_json["material_template"]){
			reset_material_json();
			set_texture_initialized(false)
			set_mode(modal_json.mode ? modal_json.mode : "image")
			if(['cube', 'sphere'].includes(modal_json.mode)){
				update_material_preview(modal_json['parsed_properties'], texture_initialized ? true : false, modal_json.mode === 'cube')
			}
		}
	},[modal_json, modal_type])

	useEffect(() => {
		//Setting up initial dropdown state
		if(modal_type == "edit_material" && parsed_properties && JSON.stringify(parsed_properties) != JSON.stringify({}) && !initialized_defaults){
			let temp_default_property_state = JSON.parse(JSON.stringify(defaultPropertyState))
			keysGroupedObject && keysGroupedObject.length ? keysGroupedObject.map((property, idx) => {
				let parsed_property = parse_property(property)
				// let header = parsed_property.header
				let defaultState = parsed_property.defaultState
				temp_default_property_state[property] = defaultState
			}) : ''
			// console.log(temp_default_property_state, "default state when modal gets opened")
			setDefaultPropertyState(temp_default_property_state)
			set_initialized_defaults(true)
		}
	}, [modal_type, parsed_properties]);

	return(
		// <Modal className="modal-dialog-centered" size="md" style={{overflow:'auto'}} isOpen={modal_type=="edit_material"}>
		// 	<Card className="bg-secondary shadow border-0">
		// 		<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
		// 			<div>
		// 				<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Edit Material</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
		// 			</div>
		// 		</CardHeader>
		// 		<CardBody style = {{backgroundColor: COLORS.white, fontSize:'13px', maxHeight:'85vh', overflowY:'auto' }}  className="px-lg-4 py-lg-4">
		// 			<Row style={{padding:'10px'}}>
		// 				<Col xs={4}>
		// 					<label>Name</label>
		// 				</Col>
		// 				<Col xs={8}>
		// 					<Input value={material_name} onChange={(e) => set_material_name(e.target.value)} outLined='false' style={{height:24, border: 0, borderBottom: '1px solid #ced4da', borderRadius: 0}}  type='text' />
		// 				</Col>  
		// 			</Row>
		// 			<Row style={{padding:'10px'}}>
		// 				<Col xs={4}>
		// 					<label>Material Template</label>
		// 				</Col>
		// 				<Col xs={8}>
		// 					<Input value={material_template_name} disabled style={{backgroundColor:'inherit', height:24, border: 0, borderBottom: '1px solid #ced4da', borderRadius: 0}}  type='text' />
		// 				</Col>  
		// 			</Row>
		// 			<Row style={{padding:'10px'}}>
		// 			{template_properties.sort((a,b) => ((a.label=="map"?0:(a.value.purpose=="image"?1:2)) - (b.label=="map"?0:(b.value.purpose=="image"?1:2)))).map((o, idx) => (
		// 				<React.Fragment>
		// 					{o.value.type == "string_enum" && parsed_properties[o.label]?(
		// 						<React.Fragment>
		// 							<Col style={{padding:10}} xs={3}>
		// 								<label>{o.value.label}</label>
		// 							</Col>
		// 							<Col style={{padding:10}} xs={3}>
		// 								<select className='common_input_style' value={parsed_properties[o.label]} onChange={(e) => change_property(o.label, e.target.value)} >
		// 									{o.value.string_enum && o.value.string_enum.map(o => (
		// 										<option value={o.value}>{o.label}</option>
		// 									))}
		// 								</select>
		// 							</Col> 
		// 						</React.Fragment>
		// 					):''}
		// 					{o.value.purpose == "image" && parsed_properties[o.label]?(
		// 						<React.Fragment>
		// 							<Col style={{padding:10}} xs={4}>
		// 								<label>{o.value.label}</label>
		// 							</Col>
		// 							<Col style={{padding:10}} xs={8}>
		// 								<img src={get_src_from_texture_id(parsed_properties[o.label])} style={{height:150, width:150 , border: '1px solid #ced4da', borderRadius: '5px'}} />
		// 							</Col> 
		// 						</React.Fragment>
		// 					):''}
		// 					{o.value.purpose == "distance"?(
		// 						<React.Fragment>
		// 							<Col style={{padding:10}} xs={3}>
		// 								<label>{o.value.label} (in mm)</label>
		// 							</Col>
		// 							<Col style={{padding:10}} xs={3}>
		// 								<Input value={parsed_properties[o.label]} onChange={(e) => change_property(o.label, Number(e.target.value))} style={{height:24, border: 0, borderBottom: '1px solid #ced4da', borderRadius: 0}}  type='number' />
		// 							</Col>  
		// 						</React.Fragment>
		// 					):''}
		// 					{o.value.purpose == "number"?(
		// 						<React.Fragment>
		// 							<Col style={{padding:10}} xs={3}>
		// 								<label>{o.value.label}</label>
		// 							</Col>
		// 							<Col style={{padding:10}} xs={3}>
		// 								<Input value={parsed_properties[o.label]} onChange={(e) => change_property(o.label, Number(e.target.value))} style={{height:24, border: 0, borderBottom: '1px solid #ced4da', borderRadius: 0}}  type='number' />
		// 							</Col>  
		// 						</React.Fragment>
		// 					):''}
		// 					{o.value.type == "color"?(
		// 						<React.Fragment>
		// 							<Col style={{padding:10}} xs={3}>
		// 								<label>{o.value.label}</label>
		// 							</Col>
		// 							<Col style={{padding:10}} xs={3}>
		// 								<Input value={get_hex_from_rgb(parsed_properties[o.label])} onChange={(e) => change_property(o.label, get_rgb_from_hex(e.target.value))} style={{height:24, border: 0, borderBottom: '1px solid #ced4da', borderRadius: 0, padding:0}}  type='color' />
		// 							</Col>  
		// 						</React.Fragment>
		// 					):''}
		// 				</React.Fragment>
		// 			))}
		// 			</Row>
		// 			<Row style={{padding:'10px'}}>
		// 				<Col style={{textAlign:'center'}}>
		// 					{/* <Button onClick={reset_material_json} className="mt-2" style={{color:COLORS.mainpage_locale, backgroundColor:'inherit', border:0}} type="button">Reset</Button>&nbsp; */}
		// 					{(modal_json && modal_json.modified_in_design)?(
		// 						// <label>This material's properties have been modified within the design. Click <a onClick={reset_to_store}>here</a> to reset it to the original</label>
		// 						<React.Fragment>
		// 							<Button onClick={reset_to_store} className="mt-2" style={{color:COLORS.mainpage_locale, backgroundColor:'inherit', border:0}} type="button">Reset &nbsp; <i onClick={(e) => {e.stopPropagation()}} id="reset_info_tooltip" className='fa fa-info-circle' ></i> </Button>
		// 							<Tooltip  placement="top" isOpen={tooltip_open} target="reset_info_tooltip" toggle={toggle_info_tooltip} >
		// 								This material's properties have been modified within the design. Click reset to revert to the original properties
		// 							</Tooltip>
		// 						</React.Fragment>
		// 					):''}
		// 					<Button onClick={apply_material_json} className="mt-2" style={{color:COLORS.mainpage_locale, backgroundColor:'inherit', border:0}} type="button">Apply</Button>		
		// 				</Col>  
		// 			</Row>
		// 		</CardBody>
		// 	</Card>
		// </Modal>
		<Modal className="modal-dialog-centered" size="md" style={{overflow:'auto', marginLeft: '350px'}} isOpen={modal_type=="edit_material"}>
			<IModalHeader toggle={onclick_handle_close} style={{backgroundColor:'#788394', color: 'white', border: 'none'}} custom_toggle_icon={'/resources/nav_icons_new/bottom panel close.svg'}>
				Material Editor
			</IModalHeader>
			<IModalBody style={{backgroundColor:'white', height:'600px', overflow:'auto', fontSize:'12px', display: 'flex', flexDirection: 'column'}}>
				{/* <ICollpsable header={parsed_properties && parsed_properties['map'] ? "Base (Texture)" : "Base (Color)"} toggle={toggle_color} isOpen={color_open}>


				</ICollpsable>
				<ICollpsable header={"Opacity"} toggle={toggle_opacity} isOpen={opacity_open}>


				</ICollpsable>
				<ICollpsable header={"Reflection"} toggle={toggle_reflection} isOpen={reflection_open}>


				</ICollpsable>
				<ICollpsable header={"Refraction"} toggle={toggle_refraction} isOpen={refraction_open}>


				</ICollpsable>
				<ICollpsable header={"Emmissivity"} toggle={toggle_emmissivity} isOpen={emmissivity_open}>


				</ICollpsable> */}
				<div className='flex_property'>
					<div className='' style={{flex: 1, alignSelf: 'baseline'}}>
						<div className='mb-2'  style={{flex: 1}}>
							<div>{t(`Name`)}</div>
		 					<Input value={material_name} onChange={(e) => set_material_name(e.target.value)} style={{...styles_modals.input_styles_2}}  type='text' />
						</div>
						<div className='mb-2'  style={{flex: 1}}>
							<div>{t(`Template`)}</div>
							{(modal_json && modal_json.new_material && modal_json.all_material_templates)?(
								<Input type='select' defaultValue={material_template_id} onChange={(e) => switch_material_template(e.target.value)} style={{...styles_modals.input_styles_2}}>
									{modal_json.all_material_templates && modal_json.all_material_templates.map(o => (
										<option value={o.id}>{o.name}</option>
									))}
								</Input>
							):(
								<Input value={material_template_name} readOnly type='text' style={{...styles_modals.input_styles_2}} />
							)}
						</div>
					</div>
					{/* comment below div to rollback material preview */}
					<div style={{position: 'relative', width:'320px', textAlign:'center', padding: '6px 0px 34px 0px', margin: "-16px -16px -16px 16px", backgroundColor: '#282828'}}>
						<div style={{textAlign:'left', display: 'flex', flexDirection: 'column', gap: '8px', position: 'absolute', right: '4px', padding: '4px', fontSize: '20px'}}>
							<Icons name={'image_placeholder'} style={{cursor: 'pointer', color: mode === "image" ? '#C5C7CF' : '#676878'}} onClick={() => {set_mode("image")}}/>
							<Icons name={'material_cube'} style={{cursor: 'pointer', color: mode === "cube" ? '#C5C7CF' : '#676878'}} onClick={() => {set_mode("cube"); update_material_preview(parsed_properties, texture_initialized ? true : false, true)}}/>
							<Icons name={'material_sphere'} style={{cursor: 'pointer', color: mode === "sphere" ? '#C5C7CF' : '#676878'}} onClick={() => {set_mode("sphere"); update_material_preview(parsed_properties, texture_initialized ? true : false, false)}}/>
						</div>
						<div style={{textAlign:'left', color: COLORS.gray6, position: 'absolute', bottom: '0px', left: '0px', fontSize: '9px', padding: '4px'}}>
							<div style={{fontWeight: 500}}>Preview only for:</div>
							{mode && mode != 'image' ? <div>Roughness, Metalness, Specular, Texture / colour, Bump & normal map</div> : <div>Texture image/ colour</div>}
						</div>
						<div className='flex_center'>
							{mode && mode != "image" && material_preview_src ? (<img src={material_preview_src} style={{width:200, height:200}} />) : is_color ? <div style={{width: '200px', height: '200px', background: color_open}} /> : base_map_url ? <img src={base_map_url} style={{width:200, height:200}} /> :  <div style={{width:200, height:200}} />}
						</div>
					</div>
				</div>
				<hr style={{marginLeft: '-16px', marginRight:'-16px'}}/>
				<div className='material_properties' style={{flex: 1, overflow: 'auto'}}>
				{
					keysGroupedObject && keysGroupedObject.length ? keysGroupedObject.map((property, idx) => {
						let parsed_property = parse_property(property)
						let header = parsed_property.header
						// let defaultState = parsed_property.defaultState 
						return( 
							((parsed_properties && parsed_properties['map'] && !property.includes('Color')) || (parsed_properties && !parsed_properties['map'] && !property.includes('Texture'))) ?
								<UncontrolledCollapse header={header} defaultState={defaultPropertyState && defaultPropertyState[property]} key={idx} className={'mb-3'} style_content_inner={{ display: 'flex', flexWrap: 'wrap' }}>
									{template_properties && template_properties.filter(x => grouped_properties[property].includes(x.label)).sort((a, b) => /*((a.label == "map" ? 0 : (a.value.purpose == "image" ? 1 : 2)) - (b.label == "map" ? 0 : (b.value.purpose == "image" ? 1 : 2)))*/ (properties_order[a.label] - properties_order[b.label])).map((o, idx) => (
										<React.Fragment>
											{o.value.type == "string_enum" && !(["texture_wrapping", "texture_width", "texture_height"].includes(o.label)) && parsed_properties[o.label] ? (
												<div className='mb-2 px-2' style={{ width: '33%' }}>

													<div className='lines1_elipsis' title={o.value.label}>{o.value.label}</div>

													<select className='common_input_style form-control' value={parsed_properties[o.label]} onChange={(e) => change_property(o.label, e.target.value)} style={{ ...styles_modals.input_styles_2, outline: 'none' }}>
														{o.value.string_enum && o.value.string_enum.map(o => (
															<option value={o.value}>{o.label}</option>
														))}
													</select>
												</div>
											) : ''}
											{o.value.purpose == "image" && o.label == "bump_map" ? (
												<div className='flex_property' style={{width: '100%', margin: '-10px 0', height: '100%'}}>
													<div style={{flex: 1, borderRight: '1px solid #E9ECF0', padding: '10px 20px 10px 0px'}}>
														<div className='pb-2' style={{width: '100%'}}>Bump Map</div>
														<div style={{display: 'flex'}}>
															<div className='pr-2'>
																{bump_map_url ? 
																<img style={{height: '62px', width: '62px', border: '1px solid var(--light-border-color)'}} src={bump_map_url}/> :
																<img style={{height: '62px', width: '62px', border: '1px solid var(--light-border-color)'}} src='/resources/toolbar_icons_new/empty_image.svg'/>}
															</div>
															<div style={{display: 'flex', flexDirection: 'column', gap: '25%'}}>
																<div className='pt-1 truncated_texture_name' style={{fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 500, lineHeight: '16px', letterSpacing: '0em', textAlign: 'left', color: '#C5C7CF'}}>{bump_map_name ? bump_map_name : '(.jpg, .png)'}</div>
																<div className='pl-1' style={{display: 'flex', gap: '8px', height: '1rem'}}>
																	{bump_map_url ? '' : <img height='100%' src='/resources/toolbar_icons_new/add.svg' onClick={(e) => { document.getElementById('bump_map_upload').click()}} style={{cursor: 'pointer'}}/>}
																	{bump_map_url ? <img height='100%' src='/resources/toolbar_icons_new/remove_icon_in_modal.svg' onClick={(e) => {/*set_bump_map(null); set_bump_map_name(false); set_bump_map_url(null)*/ update_bump_map(null)}} style={{cursor: 'pointer'}}/> : ''}
																	{bump_map_url ? <img height='100%' src='/resources/toolbar_icons_new/change.svg' onClick={(e) => {document.getElementById('bump_map_upload').click()}} style={{cursor: 'pointer'}}/> : ''}
																	<UploadImage selectedImage={bump_map} setSelectedImage={update_bump_map} inputId='bump_map_upload' isVisible={false}/>
																</div>
															</div>
														</div>
														<div className='flex_property mt-2' style={{gap: '16px'}}>
															<div style={{flex: 3}}>
																<div className='flex_property'>
																	<div style={{ borderRight: '2px solid #C5C7CF', height: '6px', borderRadius: '4px' }} />
																	<input
																		type="range"
																		min={0}
																		max={100}
																		step={'1'}
																		value={parsed_properties['bump_scale']}
																		// onChange={handleChange}
																		onChange={(e) => change_property('bump_scale', Number(e.target.value), false)}
																		onMouseUp={(e) => change_property('bump_scale', Number(e.target.value))}
																		className='slider'
																	/>
																	<div style={{ borderRight: '2px solid #C5C7CF', height: '6px', borderRadius: '4px' }} />
																</div>
																<div style={{ fontSize: '12px', display: 'flex', alignItems: 'end', justifyContent: 'space-between', width: '100%', marginTop: '4px', color: '#676878' }}>
																	<span>{0}</span>
																	<span>{100}</span>
																</div>
															</div>
															<Input value={parsed_properties['bump_scale']} step={'1'} onChange={(e) => { if (e.target.value >= (0) && e.target.value <= (100)) { change_property('bump_scale', Number(e.target.value)) } else { alert.error("Value Not in Range") } }} style={{ ...styles_modals.input_styles_2, flex: 1 }} type='number' />
														</div>
													</div>
													<div style={{flex: 1, padding: '10px 0px 10px 20px'}}>
														<div className='pb-2' style={{width: '100%'}}>Normal Map</div>
														<div style={{display: 'flex'}}>
															<div className='pr-2'>
																{normal_map_url ? 
																<img style={{height: '62px', width: '62px', border: '1px solid var(--light-border-color)'}} src={normal_map_url}/> :
																<img style={{height: '62px', width: '62px', border: '1px solid var(--light-border-color)'}} src='/resources/toolbar_icons_new/empty_image.svg'/>}
															</div>
															<div style={{display: 'flex', flexDirection: 'column', gap: '25%'}}>
																<div className='pt-1 truncated_texture_name' style={{fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 500, lineHeight: '16px', letterSpacing: '0em', textAlign: 'left', color: '#C5C7CF'}}>{normal_map_name ? normal_map_name : '(.jpg, .png)'}</div>
																<div className='pl-1' style={{display: 'flex', gap: '8px', height: '1rem'}}>
																	{normal_map_url ? '' : <img height='100%' src='/resources/toolbar_icons_new/add.svg' onClick={(e) => { document.getElementById('normal_map_upload').click()}} style={{cursor: 'pointer'}}/>}
																	{normal_map_url ? <img height='100%' src='/resources/toolbar_icons_new/remove_icon_in_modal.svg' onClick={(e) => {/*set_normal_map(null); set_normal_map_name(false); set_normal_map_url(null);*/ update_normal_map(null)}} style={{cursor: 'pointer'}}/> : ''}
																	{normal_map_url ? <img height='100%' src='/resources/toolbar_icons_new/change.svg' onClick={(e) => {document.getElementById('normal_map_upload').click()}} style={{cursor: 'pointer'}}/> : ''}
																	<UploadImage selectedImage={normal_map} setSelectedImage={update_normal_map} inputId='normal_map_upload' isVisible={false}/>
																</div>
															</div>
														</div>
														<div className='flex_property mt-2' style={{ gap: '16px' }}>
															<div style={{ flex: 3 }}>
																<div className='flex_property'>
																	<div style={{ borderRight: '2px solid #C5C7CF', height: '6px', borderRadius: '4px' }} />
																	<input
																		type="range"
																		min={0}
																		max={100}
																		step={'1'}
																		value={parsed_properties['normal_map_strength']}
																		// onChange={handleChange}
																		onChange={(e) => change_property('normal_map_strength', Number(e.target.value), false)}
																		onMouseUp={(e) => change_property('normal_map_strength', Number(e.target.value))}
																		className='slider'
																	/>
																	<div style={{ borderRight: '2px solid #C5C7CF', height: '6px', borderRadius: '4px' }} />
																</div>
																<div style={{ fontSize: '12px', display: 'flex', alignItems: 'end', justifyContent: 'space-between', width: '100%', marginTop: '4px', color: '#676878' }}>
																	<span>{0}</span>
																	<span>{100}</span>
																</div>
															</div>
															<Input value={parsed_properties['normal_map_strength']} step={'1'} onChange={(e) => { if (e.target.value >= (0) && e.target.value <= (100)) { change_property('normal_map_strength', Number(e.target.value)) } else { alert.error("Value Not in Range") } }} style={{ ...styles_modals.input_styles_2, flex: 1 }} type='number' />
														</div>
													</div>
												</div>
											) : ''}
											{["map", "color"].includes(o.label) ? (
												<div className='flex_property mb-2' style={{width: '100%', height: '100%', gap: '10px'}}>
													<div className='pr-2'>
														{
														is_color ? 
														<>
														<div style={{height: '62px', width: '62px', background: color_open, border: '1px solid var(--light-border-color)'}}/> 
														</>
														:
														base_map_url ? 
														<img style={{height: '62px', width: '62px', border: '1px solid var(--light-border-color)'}} src={base_map_url}/> :
														<img style={{height: '62px', width: '62px', border: '1px solid var(--light-border-color)'}} src='/resources/toolbar_icons_new/empty_image.svg'/>
														}
													</div>
													<div style={{display: 'flex', flexDirection: 'column', flex: 1, gap: '10px'}}>
														<div className='pt-1 flex_property' style={{width: '100%', justifyContent: 'space-between'}}>
															<Input type='select' value={is_color ? 'color' : 'texture'} onChange={(e) => { set_is_color(e.target.value == 'color');}} style={{ width: 'fit-content', fontSize: '12px', height: '25px', paddingTop: 'unset', paddingBottom: 'unset', paddingLeft: 'unset'}}>
																	<option value={'color'}>Color</option>
																	<option value={'texture'}>Texture</option>
															</Input>

																{base_map_url  && !is_color ?
															<div className={base_map_name?'truncated_texture_name2':''} style={{display: base_map_name?'':'none', fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 500, marginRight: '8px', lineHeight: '16px', letterSpacing: '0em', textAlign: 'left', color: '#C5C7CF'}}>
																{base_map_name}
																{/* <Input type='select' value={is_color ? 'color' : 'texture'} onChange={(e) => { set_is_color(e.target.value == 'color');}} style={{ width: 'fit-content', fontSize: '12px', marginRight: '32px', height: '25px', paddingTop: 'unset', paddingBottom: 'unset', paddingLeft: 'unset'}}>
																		<option value={'color'}>Color</option>
																		<option value={'texture'}>Texture</option>
																</Input> */}
															</div>
																: ''}
															
															<div className='pr-2' style={{display: 'flex', gap: '8px', height: '1rem'}}>
																{base_map_url && !is_color ? <img height='100%' src='/resources/toolbar_icons_new/remove_icon_in_modal.svg' onClick={(e) => {/*set_base_map(null); set_base_map_name(false); set_base_map_url(null);*/ update_base_map(null)}} style={{cursor: 'pointer'}}/> : ''}
																{base_map_url && !is_color ? <img height='100%' src='/resources/toolbar_icons_new/change.svg' onClick={(e) => {document.getElementById('base_map_upload').click()}} style={{cursor: 'pointer'}}/> : ''}
															</div>
														</div>
														<div style={{display: 'flex', gap: is_color ? '' : '25px', height: '21px', justifyContent: (base_map_url || is_color) ? '' : 'space-between'}}>
															{
															(!is_color) ?
															base_map_url ?
															<>
																<Texture_Structure change_property={change_property} modal_json={modal_json} label={"texture_wrapping"} comp_type={1} name={'Wrap:'} style={{minWidth: '40%'}} parsed_properties = {parsed_properties} />
																<Texture_Structure change_property={change_property} modal_json={modal_json} label={"texture_width"} comp_type={2}  name={'W:'} parsed_properties = {parsed_properties}/>
																<Texture_Structure change_property={change_property} modal_json={modal_json} label={"texture_height"} comp_type={2}  name={'H:'} parsed_properties = {parsed_properties}/>
															</> :
															<>
																<div style={{fontFamily: 'DM Sans', fontSize: '12px', fontWeight: 500, lineHeight: '16px', letterSpacing: '0em', textAlign: 'left', color: '#C5C7CF'}}>Upload square texture (.jpg, .png)</div>
																<div>
																	<img height='100%' src='/resources/toolbar_icons_new/add.svg' onClick={(e) => { document.getElementById('base_map_upload').click()}} style={{cursor: 'pointer'}}/>
																</div>
															</> :
															<>
																<input id='mcp_color_mat' className='mcp_color' type='color' value={color_open} onChange={(e) => {set_color_open(e.target.value); set_is_color_changed(true); change_property('color', get_rgb_from_hex(e.target.value))}} />
																<div className='color_hex_code' style={{color: '#676878', borderColor: '#777777'}} onClick={(e) => {document.getElementById('mcp_color_mat').click();}} onBlur={(e) => {document.getElementById('mcp_color_mat').blur()}} role="button" tabIndex={0}>
																	<span>{color_open && color_open.toUpperCase()}</span>
																	<i style={{paddingLeft: '5px'}} className={'fa fa-angle-down'}></i>
																</div>
															</>
															}

														</div> 
														<UploadImage selectedImage={base_map} setSelectedImage={update_base_map} inputId='base_map_upload' isVisible={false}/>
													</div>
												</div>
											) : ''}
											{/* {o.value.purpose == "image" && parsed_properties[o.label] && !(['bump_map', 'normal_map'].includes(o.label)) ? (
												<div className='flex_property mb-2 px-2' style={{width: '100%'}}>
													<div style={{width: '34%'}}>
														<label>{o.value.label}</label>
													</div>
													<div>
														<img src={get_src_from_texture_id(parsed_properties[o.label])} style={{ height: 100, width: 100, border: '1px solid #ced4da', borderRadius: '5px' }} />
													</div>
												</div>
											) :''} */}
											{o.value.purpose == "distance" && !(["texture_wrapping", "texture_width", "texture_height"].includes(o.label)) ? (
												<div className='mb-2 px-2' style={{ width: '33%' }}>

													<div className='lines1_elipsis' style={{}} title={o.value.label + ' (in mm)'}>{o.value.label} (in mm)</div>

													<Input value={parsed_properties[o.label]} onChange={(e) => change_property(o.label, Number(e.target.value))} style={{ ...styles_modals.input_styles_2 }} type='number' />
												</div>
											) : ''}
											{o.value.purpose == "number" ? (
												<div className='flex_property mb-2 px-2' style={{ width: '100%' }}>

													<div className='mr-3 flex_property' style={{ width: '35%', whiteSpace: 'break-spaces' }} title={custom_data[o.label] && custom_data[o.label].name ? custom_data[o.label].name : o.value.label}>
														<div className='lines1_elipsis' style={{flex: 1, minWidth: 0}}>{custom_data[o.label] && custom_data[o.label].name ? custom_data[o.label].name : o.value.label}</div>
														{
															custom_data[o.label] && custom_data[o.label].tooltip ?
																<TriggerTooltip title={custom_data[o.label].tooltip} />
																: ""
														}
													</div>
													{
														o.label === "refractive_index" && parsed_properties.transmission === 0 ? <div className='inf-py-1' style={{ color: COLORS.gray5 }}>{"To enable,  transmission must be > 0."}</div>
															:
															<React.Fragment>
																<div style={{ width: '35%' }} className='mr-3'>
																	{
																		ui_custom_styling[o.label] === "number_with_select_preview" ?
																			<Input type='select' value={parsed_properties[o.label] != null ? Math.round(parsed_properties[o.label] * 1000) / 1000 : ''} style={{ flex: 5, fontSize: '12px', marginRight: '15px', borderRadius: '0px', padding: '0px 8px', height: '24px' }} onChange={(e) => change_property(o.label, Number(e.target.value))}>
																				{
																					select_options_material_properties[o.label] && select_options_material_properties[o.label].length ? select_options_material_properties[o.label].map((option, idx) => (
																						<option value={option.value} key={idx}>{option.name}</option>
																					))
																						: ''
																				}
																			</Input>
																			:
																			<React.Fragment>
																				<div className='flex_property'>
																					<div style={{ borderRight: '2px solid #C5C7CF', height: '6px', borderRadius: '4px' }} />
																					<input
																						type="range"
																						min={o.value.min_value ? o.value.min_value : 0}
																						max={o.value.max_value ? o.value.max_value : 100}
																						step={(o && o.value && o.value.max_value && o.value.max_value < 2) ? '0.1' : '1'}
																						value={parsed_properties[o.label]}
																						// onChange={handleChange}
																						onChange={(e) => change_property(o.label, Number(e.target.value), false)}
																						onMouseUp={(e) => change_property(o.label, Number(e.target.value))}
																						className='slider'
																					/>
																					<div style={{ borderRight: '2px solid #C5C7CF', height: '6px', borderRadius: '4px' }} />
																				</div>
																				<div style={{ fontSize: '12px', display: 'flex', alignItems: 'end', justifyContent: 'space-between', width: '100%', marginTop: '4px', color: '#676878' }}>
																					<span>{o.value.min_value ? o.value.min_value : 0}</span>
																					<span>{o.value.max_value ? o.value.max_value : 100}</span>
																				</div>
																			</React.Fragment>
																	}
																</div>
																<Input value={parsed_properties[o.label]} step={(o.value.max_value < 2) ? '0.1' : '1'} onChange={(e) => { if (e.target.value >= (o.value.min_value ? o.value.min_value : 0) && e.target.value <= (o.value.max_value ? o.value.max_value : 100)) { change_property(o.label, Number(e.target.value)) } else { alert.error("Value Not in Range") } }} style={{ ...styles_modals.input_styles_2, flex: 1 }} type='number' />
															</React.Fragment>
													}
												</div>
											) : ''}
											{/* {o.value.type == "color" ? (
												<div className='flex_property mb-2 px-2' style={{ width: '100%' }}>

													<div className='lines1_elipsis mr-3' style={{ width: '30%' }} title={o.value.label}>{o.value.label}</div>

													<Input value={get_hex_from_rgb(parsed_properties[o.label])} onChange={(e) => change_property(o.label, get_rgb_from_hex(e.target.value))} style={{ ...styles_modals.input_styles_2, flex: 1, maxWidth: '90px' }} type='color' />
												</div>
											) : ''} */}
										</React.Fragment>
									))}
								</UncontrolledCollapse>
								: ''
						)
					}) : ''
				}
				</div>

			</IModalBody>
			<IModalFooter style={{justifyContent:(modal_json && modal_json.modified_in_design) ? 'space-between' : 'end'}}>
				{(modal_json && modal_json.modified_in_design) ? (
					<React.Fragment>
						<Button id='reset_info_tooltip' onClick={reset_to_store} className="secondary_button_default" type="button">Reset to Store Default</Button>
						<Tooltip placement="top" isOpen={tooltip_open} target="reset_info_tooltip" toggle={toggle_info_tooltip} >
							This material's properties have been modified within the design. Click reset to revert to the original properties
						</Tooltip>
					</React.Fragment>
				) : ''}
				<Button onClick={apply_material_json_proxy} className="primary_button_default" type="button">{modal_json && modal_json.apply_button_text ? modal_json.apply_button_text : "Apply"}</Button>	
			</IModalFooter>
			{/* <Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Edit Material</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white, fontSize:'13px', maxHeight:'85vh', overflowY:'auto' }}  className="px-lg-4 py-lg-4">
					<Row style={{padding:'10px'}}>
						<Col xs={12} style={{textAlign:'center'}}>
							{material_preview_src?(<img src={material_preview_src} style={{width:300, height:300}} />):''}
						</Col>
					</Row>
					<Row style={{padding:'10px'}}>
						<Col xs={4}>
							<label>Name</label>
						</Col>
						<Col xs={8}>
							<Input value={material_name} onChange={(e) => set_material_name(e.target.value)} outLined='false' style={{height:24, border: 0, borderBottom: '1px solid #ced4da', borderRadius: 0}}  type='text' />
						</Col>  
					</Row>
					<Row style={{padding:'10px'}}>
						<Col xs={4}>
							<label>Material Template</label>
						</Col>
						<Col xs={8}>
							<Input value={material_template_name} disabled style={{backgroundColor:'inherit', height:24, border: 0, borderBottom: '1px solid #ced4da', borderRadius: 0}}  type='text' />
						</Col>  
					</Row>
					<Row style={{padding:'10px'}}>
					{template_properties.sort((a,b) => ((a.label=="map"?0:(a.value.purpose=="image"?1:2)) - (b.label=="map"?0:(b.value.purpose=="image"?1:2)))).map((o, idx) => (
						<React.Fragment>
							{o.value.type == "string_enum" && parsed_properties[o.label]?(
								<React.Fragment>
									<Col style={{padding:10}} xs={3}>
										<label>{o.value.label}</label>
									</Col>
									<Col style={{padding:10}} xs={3}>
										<select className='common_input_style' value={parsed_properties[o.label]} onChange={(e) => change_property(o.label, e.target.value)} >
											{o.value.string_enum && o.value.string_enum.map(o => (
												<option value={o.value}>{o.label}</option>
											))}template_properties
										</select>
									</Col> 
								</React.Fragment>
							):''}
							{o.value.purpose == "image" && parsed_properties[o.label]?(
								<React.Fragment>
									<Col style={{padding:10}} xs={4}>
										<label>{o.value.label}</label>
									</Col>
									<Col style={{padding:10}} xs={8}>
										<img src={get_src_from_texture_id(parsed_properties[o.label])} style={{height:150, width:150 , border: '1px solid #ced4da', borderRadius: '5px'}} />
									</Col> 
								</React.Fragment>
							):''}
							{o.value.purpose == "distance"?(
								<React.Fragment>
									<Col style={{padding:10}} xs={3}>
										<label>{o.value.label} (in mm)</label>
									</Col>
									<Col style={{padding:10}} xs={3}>
										<Input value={parsed_properties[o.label]} onChange={(e) => change_property(o.label, Number(e.target.value))} style={{height:24, border: 0, borderBottom: '1px solid #ced4da', borderRadius: 0}}  type='number' />
									</Col>  
								</React.Fragment>
							):''}
							{o.value.purpose == "number"?(
								<React.Fragment>
									<Col style={{padding:10}} xs={3}>
										<label>{o.value.label}</label>
									</Col>
									<Col style={{padding:10}} xs={3}>
										<Input value={parsed_properties[o.label]} onChange={(e) => change_property(o.label, Number(e.target.value))} style={{height:24, border: 0, borderBottom: '1px solid #ced4da', borderRadius: 0}}  type='number' />
									</Col>  
								</React.Fragment>
							):''}
							{o.value.type == "color"?(
								<React.Fragment>
									<Col style={{padding:10}} xs={3}>
										<label>{o.value.label}</label>
									</Col>
									<Col style={{padding:10}} xs={3}>
										<Input value={get_hex_from_rgb(parsed_properties[o.label])} onChange={(e) => change_property(o.label, get_rgb_from_hex(e.target.value))} style={{height:24, border: 0, borderBottom: '1px solid #ced4da', borderRadius: 0, padding:0}}  type='color' />
									</Col>  
								</React.Fragment>
							):''}
						</React.Fragment>
					))}
					</Row>
					<Row style={{padding:'10px'}}>
						<Col style={{textAlign:'center'}}>
							{(modal_json && modal_json.modified_in_design)?(
								// <label>This material's properties have been modified within the design. Click <a onClick={reset_to_store}>here</a> to reset it to the original</label>
								<React.Fragment>
									<Button onClick={reset_to_store} className="mt-2" style={{color:COLORS.mainpage_locale, backgroundColor:'inherit', border:0}} type="button">Reset &nbsp; <i onClick={(e) => {e.stopPropagation()}} id="reset_info_tooltip" className='fa fa-info-circle' ></i> </Button>
									<Tooltip  placement="top" isOpen={tooltip_open} target="reset_info_tooltip" toggle={toggle_info_tooltip} >
										This material's properties have been modified within the design. Click reset to revert to the original properties
									</Tooltip>
								</React.Fragment>
							):''}
							<Button onClick={apply_material_json} className="mt-2" style={{color:COLORS.mainpage_locale, backgroundColor:'inherit', border:0}} type="button">Apply</Button>		
						</Col>  
					</Row>
				</CardBody>
			</Card> */}
		</Modal>
	)
}

const RenderPostProcessing = ({modal_type, modal_json, handle_close, handle_ui_response}) => {
	
	const [imageDataUrl, setImageDataUrl] = useState('');
	const [key, set_key] = useState('');
    const {t} = useTranslation()

	const postprocessingOfRender = async() => {
		let props_array = {}
		props_array.src = modal_json && modal_json.src
		if(modal_json_copy && modal_json_copy.properties){
			modal_json_copy.properties.map(x => x.props ? x.props.map(y => props_array[y.name] = y.value) : '')
		}
		// console.log("xxxxxxxxxxxxxxxxxx", props_array)
		let resp = await window.Promisify(window.Module.render_post_process(JSON.stringify(props_array)))
		handle_ui_response(resp)
	}

	const [modal_json_copy, set_modal_json_copy] = useState({});

	useEffect(() => {
		set_modal_json_copy(modal_json)
		let url
		if (modal_json && window.FS.root.contents[modal_json.src_after]) {
			// Assuming FS.root.contents['after.jpg'] is the image data
			const imageBlob = new Blob([window.FS.root.contents[modal_json.src_after].contents]);

			// Create a URL for the Blob
			url = (URL.createObjectURL(imageBlob));
			setImageDataUrl(url);
		}else{
			setImageDataUrl('')
		}
		set_key(Date.now())

		return () => {
			if(url){
				URL.revokeObjectURL(url)
			}
		}

	}, [modal_json]);

	const  download_image = async(src) => {
		// Replace 'example.txt' with the filename you want to download
		
		var filename = 'after.jpg';

		// Replace 'Hello, World!' with the content you want to save in the file

		// Create a Blob from the file content
		let resp = await fetch(src)

		var blob = await resp.blob()

		// Create a URL for the Blob
		var url = URL.createObjectURL(blob);

		// Create a temporary anchor element
		var downloadLink = document.createElement('a');
		downloadLink.href = url;
		// console.log(url);
		downloadLink.download = filename; // Set the filename for download

		// Append the anchor element to the body and click it
		document.body.appendChild(downloadLink);
		downloadLink.click();

		// Clean up by revoking the URL and removing the anchor element
		URL.revokeObjectURL(url);
		document.body.removeChild(downloadLink);
	}


	function downloadFile(url) {
		// Replace 'example.txt' with the filename you want to download
		var filename = 'after.jpg';

		var analytics_data = {}
		analytics_data.category = "Download Render Image (Post Processed)";
		send_analytics(analytics_data,true)

		// Replace 'Hello, World!' with the content you want to save in the file

		// Create a Blob from the file content
		// var blob = new Blob([window.FS.root.contents[modal_json.src_after].contents]);

		// Create a URL for the Blob
		// var url = URL.createObjectURL(blob);

		// Create a temporary anchor element
		var downloadLink = document.createElement('a');
		downloadLink.href = url;
		console.log(url);
		downloadLink.download = filename; // Set the filename for download

		// Append the anchor element to the body and click it
		document.body.appendChild(downloadLink);
		downloadLink.click();

		// Clean up by revoking the URL and removing the anchor element
		// URL.revokeObjectURL(url);
		document.body.removeChild(downloadLink);
	}

	const onclick_handle_close = () => {
		// URL.revokeObjectURL(imageDataUrl)
		setImageDataUrl('')
		handle_close()
	}

	const open_post_processing_modal = async() => {
		let resp = await window.Promisify(window.Module.open_post_processing_modal(modal_json.src))
		handle_ui_response(resp)
	}

	const generate_header = (property, idx) => {

		const reset_property = async() => {
			try{
				await open_post_processing_modal()				
				// postprocessingOfRender()
			}catch(err){
				console.error("Something went wrong. Could not reset")
			}
		}

		return <div style={{display: 'flex', alignItems: 'center', /*justifyContent: 'space-between', flex: 1*/}}>
			<div>{property.name}</div>
			{/* <div onClick={(e) => {e.stopPropagation(); reset_property()}} style={{color: COLORS.gray5, fontWeight: 400}}>Reset</div> */}
		</div>
	}

	// useEffect(() => {
	// 	console.log('modal json copy', modal_json_copy)
	// }, [modal_json_copy]);

	// useEffect(() => {
	// 	console.log('modal json copy key', key)
	// }, [key]);

	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type == "render_post_processing"}>
			<IModalHeader toggle={handle_close}>Post Processing</IModalHeader>
			<IModalBody style={{display: 'flex'}}>
				<div style={{width: '40%', alignSelf:'baseline', maxHeight: '600px', overflow: 'auto'}}>
					{
						modal_json_copy && modal_json_copy.properties && modal_json_copy.properties.length ? modal_json_copy.properties.map((property, idx) => (
							<UncontrolledCollapse header={generate_header(property, idx)} style_header={{backgroundColor: '#E6E9EE', }} style={{marginBottom: '12px'}} defaultState={true} key={idx}>
								<div className='flex_property' style={{ flexWrap: 'wrap', gap: '16px'}} key={key}>
									{
										property && property.props && property.props.length ? property.props.map((sub_property, index) => (
											<div style={{flexBasis: 'calc( 50% - 16px )'}} key={key + index}>
												<div className='mb-2'>{sub_property.name}</div>
												<div>
													<div className='flex_property'>
														<div style={{ borderRight: '3px solid #C5C7CF', height: '10px', borderRadius: '4px' }} />
														<input
															id={sub_property.name}
															type="range"
															min={sub_property.min ? sub_property.min : 0}
															max={sub_property.max ? sub_property.max : 100}
															step={1}
															defaultValue={sub_property.value}
															onChange={(e) => sub_property.value = Number(e.target.value)}
															onMouseUp={postprocessingOfRender}
															className='slider_post_processing'
														/>
														<div style={{ borderRight: '3px solid #C5C7CF', height: '10px', borderRadius: '4px' }} />
													</div>
													{/* <div style={{ fontSize: '12px', display: 'flex', alignItems: 'end', justifyContent: 'space-between', width: '100%', marginTop: '4px' }}>
													<span>{0}</span>
													<span>{100}</span>
												</div> */}
												</div>
											</div>
										)) : ''
									}
								</div>
							</UncontrolledCollapse>
						)) : ''
					}
				</div>
				<div style={{flexBasis: '1px', background:'#DBDCE1', margin:'0px 16px'}}></div>
				<div style={{width: '60%', maxHeight: '600px', overflow: 'auto', alignSelf: 'baseline'}}>
					<div className='mb-2'>{t(`Before`)}</div>
					<img src={modal_json && modal_json.src} width={'250px'} className='mb-4'></img>
					<div className='mb-2'>{t(`After`)}</div>
					<img src={imageDataUrl ? imageDataUrl : modal_json && modal_json.src ? modal_json.src : ''} width={'100%'}></img>
				</div>
			</IModalBody>
			<IModalFooter>
				<Button onClick={open_post_processing_modal} className='secondary_button_default' >Reset</Button>
				<Button disabled={!imageDataUrl} onClick={() => {imageDataUrl ? downloadFile(imageDataUrl) : download_image(modal_json && modal_json.src)}} className='primary_button_default' >Download</Button>
			</IModalFooter>
		</Modal>
	)
}
const Render = ({  modal_type, handle_close, set_page_loader, handle_ui_response }) => {

	const styles = {
		button_for_render:{
			backgroundColor:'#009688',
			color:'white',
			width:'100%',
			boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
		}
	}
	const alert = useAlert();
	const [render_quality, set_render_quality] = useState('')
	const [step, set_step] = useState(2)
	const [render_view, set_render_view] = useState('current')

	const quality_to_name = {
		medium: 'Medium',
		high: 'High',
		presentation: 'Presentation'
	}

	useEffect(() => {
		if(render_view){
			set_step(2)
		}
	},[render_view])

	useEffect(() => {
		set_step(2);
		set_render_view('current');
	},[modal_type])

	// useEffect(() => {
	// 	if(render_quality){
	// 		//backened call
	// 		alert("render_view : " + render_view +"  "+ "render_quality: " + render_quality)
	// 	}
	// },[render_quality])

	const final_on_click = async (quality) => {
		set_page_loader({
            show: true,
            text: 'Queueing your render request'
        },async () => {
			//await dummy_tick(0);
			setTimeout(() => {
				window.Promisify(window.Module.render_image(render_view,quality,window.camera.position.x,window.camera.position.y,window.camera.position.z,window.camera.rotation.x,window.camera.rotation.y,window.camera.rotation.z,window.camera.fov,window.camera.aspect))
				.then(response => {
					set_page_loader({
						show: false,
						text: ''
					})
					alert.success(`${quality_to_name[quality]} Render added to the queue. Check Gallery under the Render tab.`)
					handle_close();
				}).catch(error => {
					set_page_loader({
						show: false,
						text: ''
					})
					alert.error('Render Request Failed')
				});	
			}, 0);
		})
		
	}

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="render"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Render Image</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white , padding:'30px'}}>
						{
							step == 1 ?(
								<Row>
									<Col>
										<Button style={styles.button_for_render} onClick={() =>set_render_view('current')}>RENDER CURRENT VIEW</Button>
									</Col>
									<Col>
										<Button style={styles.button_for_render} onClick={() =>set_render_view('panorama')}>RENDER PANOROMIC VIEW</Button>
									</Col> 
									<Col>
										<Button style={styles.button_for_render} onClick={() =>set_render_view('stereo')}>RENDER VR VIEW</Button>
									</Col>   
								</Row>
							):(
								<Row>
									<Col>
										<Button style={styles.button_for_render} onClick={() =>final_on_click('medium')}>MEDIUM QUALITY</Button>
									</Col>
									<Col>
										<Button style={styles.button_for_render} onClick={() =>final_on_click('high')}>HIGH QUALITY</Button>
									</Col> 
									<Col>
										<Button style={styles.button_for_render} onClick={() =>final_on_click('presentation')}>PRESENTATION QUALITY</Button>
									</Col>   
								</Row>
							)
						} 
				</CardBody>
			</Card>
		</Modal>
	)    
}

const Render_Images_card = ({update_modal_json, render_id, src, button, timestamp_start, timestamp_end, progress, status, submit_for_delete_confirm}) => {

	const delete_render = () => {
		window.Promisify(window.Module.delete_render(render_id))
		.then(() => {
			update_modal_json();
		})
	}

	return(
		<div style={{width:'100%'}}>
			<Card style={{height:'160px', width:'100%', marginBottom:'20px', textAlign:'center' }}>
				<a href={src} style={{maxHeight:'100%', maxWidth:'100%',margin:'auto'}} target='black'>
					<img  style={{maxHeight:'100%', maxWidth:'100%', display:'pointer'}} src={src} crossOrigin="" />
				</a>
				
			</Card>
			<div style={{textAlign:'center', width:'100%', marginBottom:'10px' }}>
			{
				button ? (
					<Button onClick ={() => submit_for_delete_confirm(()=>delete_render(), ()=>{}, 'Do you want to delete this Render permanently?')} style={{textAlign:'center', backgroundColor:'#e60000', height:'23px', color:'white', padding:'0px 15px', fontSize:'10px', border:0, borderRadius:0}}>Delete</Button>
				):''
			}
			{
				status=="rendering" && progress != 100 && (
					<div><div style={{height:'20px', width:'100%', paddingBottom:'10px'}}><Progress color="success" value={progress?progress:"0"} /></div></div>
				)
			}
			</div>
		</div>	
	);
}
const Rendered_images = ({  update_modal_json, modal_type, modal_json , handle_close, submit_for_delete_confirm}) => {

	const [tabs , set_tabs] = useState(1);
	// const [ polling_interval, set_polling_interval ] = useState(null);

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	const polling_function = () => {
		var renders_that_need_poll = []
		if(modal_json){
			renders_that_need_poll = modal_json.filter(o => o.status == "rendering" && o.progress != 100);
		}
		if(modal_json && renders_that_need_poll.length>0 && modal_type == "rendered_images"){
			// console.log("trying to poll");
			renders_that_need_poll = renders_that_need_poll.map(o => o.id);
			// console.log(JSON.stringify(renders_that_need_poll))+
			window.Promisify( window.Module.monitorProgress( JSON.stringify(renders_that_need_poll) ) )
			.then(response => {
				update_modal_json();
			})
		}/*else{
			clearInterval(polling_interval);
			set_polling_interval(null);
			return;
		}*/
	}


	useEffect(() => {
		let polling_interval
		if(modal_type == "rendered_images" && modal_json){
			if(!polling_interval){
				polling_interval = (setInterval(polling_function,10000));
			}
		}else{
			if(polling_interval){
				clearInterval(polling_interval);
				// set_polling_interval(null);
			}
			// var x =modal_json && modal_json.length? modal_json.sort(time_sort):[]; 
		}

		return () => {
			if(polling_interval){
				clearInterval(polling_interval);
				// set_polling_interval(null);
			}
		}
	},[modal_type, modal_json])

	const modify_src = src => {
		if(!src){
			return '';
		}

		var new_src = src;
		new_src = new_src.split('render_images')[1];
		new_src = window.Module.API.rtx_render_server + "get_images"+ new_src;
		return new_src;
	}

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="rendered_images"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Rendered Images</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white, maxHeight:'500px', overflow:'auto', padding:'10px 20px 20px'}}>
					<div className="nav-wrapper">
						<Nav 
							style={{display:'flex', marginBottom:'20px', fontSize:'14px'}}
							className="flex-md-row"
							id="tabs-icons-text" 
							pills
							role="tablist"
						>
							<NavItem>
								<NavLink
									aria-selected={tabs === 1}
									className={classnames("mb-sm-3 mb-md-0 tab_pills", {
										active: tabs === 1
									})}
									onClick={e => toggleNavs(e, 1)}
									role="tab"
									style={{cursor:'pointer'}}
								>
									Medium
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									aria-selected={tabs === 2}
									className={classnames("mb-sm-3 mb-md-0 tab_pills", {
											active: tabs === 2
									})}
									onClick={e => toggleNavs(e, 2)}
									role="tab"
									style={{cursor:'pointer'}}
								>
									High
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									aria-selected={tabs === 3}
									className={classnames("mb-sm-3 mb-md-0 tab_pills", {
											active: tabs === 3
									})}
									onClick={e => toggleNavs(e, 3)}
									role="tab"
									style={{cursor:'pointer'}}
								>
									Presentation
								</NavLink>
							</NavItem>
						</Nav>
					</div>
					<TabContent activeTab={"tabs" + tabs}>

						<TabPane tabId="tabs1">
							<Row style={{maxHeight:'100%', maxWidth:'100%', margin:0 }}>
								{
									modal_json && modal_json.length ? modal_json.sort((a,b) => (new Date(b.created_at) - new Date(a.created_at))).filter(o => o.quality == "medium").map((item,idx) => (
										<Col key={idx} xs={4}>
											<Render_Images_card 
												update_modal_json={update_modal_json}
												render_id={item.id}
												src={item.status!="rendering"||item.progress==100?modify_src(item.src):'/resources/images/render_queue.jpg'} 
												button={item.status!="rendering"||item.progress==100} 
												timestamp_start={item.timestamp_start?item.timestamp_start:''} 
												timestamp_end={item.timestamp_end?item.timestamp_end:''}
												progress={item.progress ? item.progress :''}
												status={item.status}
												submit_for_delete_confirm={submit_for_delete_confirm}
											/>
										</Col>
									))
									: 
									<Col xs={4}>
										No Rendered Images to show.
									</Col>
								}	  
							</Row>
						</TabPane>
							
						<TabPane className = "full-height" tabId="tabs2">
							<Row style={{maxHeight:'100%', maxWidth:'100%', margin:0 }}>
								{
									modal_json && modal_json.length ? modal_json.sort((a,b) => (new Date(b.created_at) - new Date(a.created_at))).filter(o => o.quality == "high").map((item,idx) => (
										<Col key={idx} xs={4}>
											<Render_Images_card 
												update_modal_json={update_modal_json}
												render_id={item.id}
												src={item.status!="rendering"||item.progress==100?modify_src(item.src):'/resources/images/render_queue.jpg'} 
												button={item.status!="rendering"||item.progress==100} 
												timestamp_start={item.timestamp_start?item.timestamp_start:''} 
												timestamp_end={item.timestamp_end?item.timestamp_end:''}
												progress={item.progress ? item.progress :''}
												status={item.status}
												submit_for_delete_confirm={submit_for_delete_confirm}
											/>
										</Col>
									))
									: 
									<Col xs={4}>
										No Rendered Images to show.
									</Col>
								}	  
							</Row>
						</TabPane>
						<TabPane className = "full-height" tabId="tabs3">
							<Row style={{maxHeight:'100%', maxWidth:'100%', margin:0 }}>
								{
									modal_json && modal_json.length ? modal_json.sort((a,b) => (new Date(b.created_at) - new Date(a.created_at))).filter(o => o.quality == "presentation").map((item,idx) => (
										<Col key={idx} xs={4}>
											<Render_Images_card 
												update_modal_json={update_modal_json}
												render_id={item.id}
												src={item.status!="rendering"||item.progress==100?modify_src(item.src):'/resources/images/render_queue.jpg'} 
												button={item.status!="rendering"||item.progress==100} 
												timestamp_start={item.timestamp_start?item.timestamp_start:''} 
												timestamp_end={item.timestamp_end?item.timestamp_end:''}
												progress={item.progress ? item.progress :''}
												status={item.status}
												submit_for_delete_confirm={submit_for_delete_confirm}
											/>
										</Col>
									))
									: 
									<Col xs={4}>
										No Rendered Images to show.
									</Col>
								}	  
							</Row>
						</TabPane>

					</TabContent>

				</CardBody>
			</Card>
		</Modal>

		// <Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="rendered_images"}>
		// 	<IModalHeader toggle={handle_close}>Render Output</IModalHeader>
		// 	<IModalBody>
		// 	</IModalBody>
		// </Modal>
	)    
}

const Sheet_input_box = ({ modal_json, handle_close, modal_type, interface_mode}) => {

	const styles = {
		box_style: {
			zIndex:1000,
			padding:'0', 
			position:'absolute',
			overflow:'auto'
		},
		button_style: {
			padding:'0px 10px',
			backgroundImage: 'linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%)',
  			boxShadow: 'inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14)',
			color:'black',
			border:0
		},
		input_styles:{
			width:'100%'
		}
	}

	const [ input_data, set_input_data ] = useState('');

	useEffect(() => {
		if(modal_type == "sheet_input_box"){
			if(modal_json){
				set_input_data(modal_json.text)
			}
			const input = document.getElementById('sheet_table_cell_input');
			if(input){
				input.focus();
				input.select();
			}
		}
	},[modal_json, modal_type])

	const onClick_handleclose = (e) => {
		e.stopPropagation();
		set_input_data('')
		handle_close()
	}

	const onBlur_handle = (e) => {
		e.stopPropagation();
		if(!(e.relatedTarget && (e.relatedTarget.id == "sheet_table_cell_input_apply" || e.relatedTarget.id == "sheet_table_cell_input_cancel"))){
			onClick_handleclose(e);
		}
	}

	const submit = (e) => {
		try{
			e.stopPropagation();
			if(window.debug_mode){
				window.add_debug_log(modal_json.route + "(\"" + input_data + "\");" );
			}
			window.Module[modal_json.route](input_data);
			onClick_handleclose(e);
		}catch(err){
			alert.error("Failed to apply the value. Try again in sometime.")
			console.error(`Failed to apply the value. modal json - ${JSON.stringify(modal_json)}, input data - ${input_data} , error - `, err)
			handleError(err)
		}
	}

	const handle_sheet_input = (e) => {
		if(e.key == "Enter" && e.shiftKey){

		}else if(e.key == 'Enter'){
			submit(e);
		}else if(e.key=="Escape"){
			onClick_handleclose(e);
		}
	}

	const handle_sheet_keydown = (e) => {
		if(e.key == "Enter" && !e.shiftKey){
			e.preventDefault();
			e.stopPropagation();
			return false;
		}
	}

	const get_canvas_offset_left = () => {
		var cnvs = window.$('#webgl_main_canvas');
		return cnvs.offset().left
	}

	const get_canvas_offset_top = () => {
		var cnvs = window.$('#webgl_main_canvas');
		return cnvs.offset().top
	}


	return(	
		<>
		<div style={{height: '100vh', width: '100vw', position: 'fixed', background: 'rgba(0,0,0,0.1)', zIndex: 100}}></div>
		<div style={{
			...styles.box_style,
			display:modal_type == "sheet_input_box"?'block':'none',
			left: modal_json && modal_json.x?(get_canvas_offset_left() + modal_json.x):'calc((100% - 300px)/2)', 
			top: modal_json && modal_json.y?(get_canvas_offset_top() + modal_json.y):'calc((100% - 100px)/2)', 
		}}>
			<Input id="sheet_table_cell_input" style={{
				...styles.input_styles,
				fontSize : modal_json && modal_json.font_size ? `${modal_json.font_size}px` : "",
				height:modal_json && modal_json.height?modal_json.height:'100px', 
				lineHeight : 1,
				padding : 0,
				width: modal_json && modal_json.width?modal_json.width:'300px',
			}} onKeyDown={(e)=>handle_sheet_keydown(e)} onKeyUp={(e)=>handle_sheet_input(e)} onBlur = {onBlur_handle} onChange={e => set_input_data(e.target.value)} value={input_data} type={modal_json&&modal_json.input_type?modal_json.input_type:'text' } />
			<div style={{width:'100%', textAlign:'right', paddingTop:'10px'}}>
				<Button id="sheet_table_cell_input_apply" onClick={submit} style={{...styles.button_style, marginRight:'10px'}} type="button"><i className="fa fa-check"/></Button>
				<Button id="sheet_table_cell_input_cancel"  onClick={onClick_handleclose} style={{...styles.button_style,}} type="button"><i className="fa fa-times"/></Button>
			</div>
		</div>
		</>
	)    
}

const BOQ_grouping_body = ({schedule_selected_category_types, schedule_field_list, schedule_grouping_rules, set_schedule_grouping_rules, delete_schedule_grouping_category_type, index}) => {
    const {t} = useTranslation()

	useEffect(()=>{
	}, [schedule_grouping_rules])

	const update_grouping_category_type = (value) => {
		let a = [...schedule_grouping_rules]
		a[index].category_type = value
		set_schedule_grouping_rules(a)
	}

	const update_category_grouping_field = (idx, value) => {
		let a = [...schedule_grouping_rules]
		a[index].fields[idx] = value
		set_schedule_grouping_rules(a)
	}

	const delete_category_grouping_field = (idx) => {
		let a = [...schedule_grouping_rules]
		a[index].fields.splice(idx, 1)
		if(a[index].fields.length == 0){
			delete_schedule_grouping_category_type(index)
		} else {
			set_schedule_grouping_rules(a)
		}
	}

	const add_category_grouping_field = () => {
		let a = [...schedule_grouping_rules]
		if(schedule_field_list.length >0){
			a[index].fields.push(schedule_field_list[0].id)
			set_schedule_grouping_rules(a);
		}
	}
	return(
		<div className='p-2 mb-3' style={{background: 'white'}}>
			<div className='flex_property my-3'>
				<div className='mr-3'>
					{'Category:'}
				</div>
				<div className='mr-3' style={{ flex: 1 }}>
					<Input style={{ fontSize: '12px' }} type="select"
						value={schedule_grouping_rules[index].category_type}
						onChange={(e) => update_grouping_category_type(e.target.value)}
					>
						{
							schedule_selected_category_types && schedule_selected_category_types.length ? schedule_selected_category_types.map((item, idx) => (
								<option style={{ listStyle: 'none', textTransform: 'capitalize', color: 'black' }} key={idx} value={item.id}>
									{item.name ? item.name : ''}
								</option>
							)) : ""
						}
					</Input>
				</div>
				<div className='px-2'>
					<div className='red_button_default_ghost' style={{ cursor: 'pointer' }} onClick={() => delete_schedule_grouping_category_type(index)}>{t(`Delete Rule`)}</div>
				</div>
			</div>

			{
				schedule_grouping_rules[index] && schedule_grouping_rules[index].fields && schedule_grouping_rules[index].fields.length ? schedule_grouping_rules[index].fields.map((x, field_id) => (
					<div className='flex_property my-3 px-3' key={field_id}>
						<div className='mr-3'>
							{field_id == 0 ? 'Group by:' : 'AND:'}
						</div>
						<div className='mr-3' style={{ flex: 1 }}>
							<Input style={{ fontSize: '12px' }} type="select"
								value={x}
								onChange={(e) => update_category_grouping_field(field_id, e.target.value)}
							>
								{
									schedule_field_list && schedule_field_list.length ? schedule_field_list.map((item, idx) => (
										<option style={{ listStyle: 'none', textTransform: 'capitalize', color: 'black' }} key={idx} value={item.id}>
											{item.text ? item.text : ''}
										</option>
									)) : ""
								}
							</Input>
						</div>
						<div className='px-2'>
							<div className='red_button_default_ghost' style={{ cursor: 'pointer' }} onClick={() => delete_category_grouping_field(field_id)}>{t(`Delete Field`)}</div>
						</div>
					</div>
				)) : ""
			}
			<div className='flex_property'>
				<div className='px-3 py-2' onClick={add_category_grouping_field} style={{ color: '#007BFF', cursor: 'pointer' }}>{t(`Add Grouping Field`)}</div>
			</div>
		</div>
	)
}

const BOQ_properties_modal = ({modal_type, modal_json, handle_close, update_modal_json, handle_ui_response, set_page_loader, update_view}) =>{

	const alert = useAlert();
	const [schedular_properties_json_display, set_schedular_properties_json_display] = useState('');
	const [	tabs , set_tabs ] = useState(1)
	const [unselected_schedule_field_list, set_unselected_schedule_field_list] = useState([]);
	const [schedule_field_list, set_schedule_field_list] = useState([]);
	const [schedule_field_filter_list, set_schedule_field_filter_list] = useState([]);
	const [schedule_field_default_option, set_schedule_field_default_option] = useState('');
	const [schedule_field_sorting_list, set_schedule_field_sorting_list] = useState([]);
	const [filter_operators_json_filtered, set_filter_operators_json_filtered] = useState([]);
	const [schedule_grouping_rules, set_schedule_grouping_rules] = useState([]);
	const [possible_filter_values, set_possible_filter_values] = useState({});
	const [schedule_selected_category_types, set_schedule_selected_category_types] = useState([]);
	const [schedule_field_to_type_map, set_schedule_field_to_type_map] = useState({});
    const {t} = useTranslation()

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	const set_all = (x_selected) => {
		if(schedular_properties_json_display && schedular_properties_json_display.length){
			set_schedule_field_list(x_selected && x_selected.length?x_selected:[])
			set_unselected_schedule_field_list(schedular_properties_json_display.filter(x=>!x.is_selected).sort(sort_by_text))
		}else{
			set_schedule_field_list([])
			set_unselected_schedule_field_list([])
		}	
	}

	useEffect(()=>{
		console.log('printing tabs', tabs)
	}, [tabs])

	const onclick_unselected_property = (item) => {
		if(item){
			// var x = [...schedule_field_list];
			// x && x.push(item)
			// set_schedule_field_list(x)
			// var new_sedule_prop_list=[...schedular_properties_json_display]
			// if(schedular_properties_json_display && schedular_properties_json_display.length==0){
			// 	var new_sedule_prop_list = [...modal_json]
			// }
			// var x_remove =  schedular_properties_json_display && schedular_properties_json_display.filter(x => x!=item)
			// x_remove && x_remove.length ? set_schedular_properties_json_display(x_remove):set_schedular_properties_json_display('')
			item.is_selected=true;
			var x=[...schedule_field_list]
			x.push(item)
			set_all(x)
		}
	}

	useEffect(() =>{
		if((modal_type==="boq_properties" || modal_type === "edit_boq_properties") && modal_json){
			set_schedular_properties_json_display(modal_json.schedule_field && modal_json.schedule_field.length ? modal_json.schedule_field : [])
			set_schedule_field_filter_list(modal_json.filter_list && modal_json.filter_list.length ? modal_json.filter_list : [])
			set_schedule_field_sorting_list(modal_json.sorting_list && modal_json.sorting_list.length ? modal_json.sorting_list : [])
			set_schedule_grouping_rules(modal_json.boq_grouping_rules && modal_json.boq_grouping_rules.length ? modal_json.boq_grouping_rules : [])
			set_possible_filter_values(modal_json.possible_filter_values ? modal_json.possible_filter_values : {})
			let a = modal_json.category_types && modal_json.category_types.length ? modal_json.category_types : []
			a.push({ id: 'all', name: 'All' })
			set_schedule_selected_category_types(a)
			if(modal_json.schedule_field){
				let temp_map = {}
				for(let i in modal_json.schedule_field){
					let field = modal_json.schedule_field[i]
					temp_map[field.id] = field.type
				}
				set_schedule_field_to_type_map(temp_map)
			}
		}
	},[modal_json, modal_type])

	useEffect(() =>{
		if(schedular_properties_json_display && schedular_properties_json_display.length){
			var x = schedular_properties_json_display.filter(x=>x.is_selected)
			set_all(x)
		}
	},[schedular_properties_json_display])

	// useEffect(() =>{
	// 	if(schedule_field_filter_list && !schedule_field_filter_list.length){
	// 		add_new_schedule_field_filter_list()
	// 	}
	// 	if(schedule_field_sorting_list && !schedule_field_sorting_list.length){
	// 		add_new_schedule_field_sorting_list()
	// 	}
	// 	if(schedule_field_list && schedule_field_list.length){
	// 		set_schedule_field_default_option(schedule_field_list[0].id)
	// 		// console.log('schedule_field_list =====>', schedule_field_list)
	// 	}
	// },[schedule_field_list])

	// useEffect(() =>{
	// 	if(schedule_field_default_option){
	// 		if(schedule_field_filter_list && !schedule_field_filter_list.length){
	// 			add_new_schedule_field_filter_list()
	// 		}
	// 		if(schedule_field_sorting_list && !schedule_field_sorting_list.length){
	// 			add_new_schedule_field_sorting_list()
	// 		}
	// 		// console.log('schedule_field_default_option =====>', schedule_field_default_option)
	// 	}
	// },[schedule_field_default_option])

	const onclick_handleclose = () => {
		handle_close();
		set_schedule_field_list([])
		set_schedular_properties_json_display('')
		set_schedule_field_filter_list([])
		set_schedule_field_default_option('')
		set_schedule_field_sorting_list([])
		set_unselected_schedule_field_list([])
		set_schedule_grouping_rules([])
		set_schedule_selected_category_types([])
	}

	const onclick_schedule_apply = async() => {

		var final_output_json = {
			schedule_field:schedule_field_list,
			filter_list:schedule_field_filter_list,
			sorting_list:schedule_field_sorting_list,
			boq_grouping_rules:schedule_grouping_rules,
			selected_category_types:schedule_selected_category_types
		}
		// window.Module[modal_json.route](input_data);
		console.log("on click apply schedule", final_output_json)
		// if(modal_json.architecture){
		// 	await window.Module.print_civil_works_boq(JSON.stringify(final_output_json));
		// }else{
			await window.Module.get_bill_of_quantities(JSON.stringify(final_output_json),modal_type+(modal_json.architecture?"architecture":""));
		// }
		onclick_handleclose();
	}

	const onclick_schedule_save = () => {

		var final_output_json = {
			schedule_field:schedule_field_list,
			filter_list:schedule_field_filter_list,
			sorting_list:schedule_field_sorting_list,
			boq_grouping_rules:schedule_grouping_rules
		}
		// window.Module[modal_json.route](input_data);
		// console.log(final_output_json)
		set_page_loader({show: true, text: "Please Wait"})
		setTimeout(() => {
			try{
				window.Module.publish_bill_of_quantities(JSON.stringify(final_output_json),modal_type);
				set_page_loader({show: false, text: "Please Wait"})
			}catch(err){
				console.error("Error on Save as Template ",err)
				set_page_loader({show: false, text: "Please Wait"})
			}
		}, 0)

		// onclick_handleclose();
		update_view()
	}

	const onclick_schedule_cancel = () => {
		onclick_handleclose();
	}

	const update_schedule_field_filter_list = (index, key, value) =>{
		let a = [...schedule_field_filter_list];
		a[index][key] = value;
		if(key === 'field'){
			a[index]['value'] = possible_filter_values && value && possible_filter_values[value] && possible_filter_values[value].length ? possible_filter_values[value][0] : ''
		}
		set_schedule_field_filter_list(a);
		/*if(key==='field'){
			var value_details = schedule_field_list.find(x => x.id===value)
			var operators_json=[]
			if(value_details.type==='number'){
				operators_json = filter_operators_json.filter(x => x.type==='all' || x.type==='number')
			}
			if(value_details.type==='text'){
				operators_json = filter_operators_json.filter(x => x.type==='all')
			}
			set_filter_operators_json_filtered(operators_json)
		}*/
	}

	const update_filter_operators_json = (value) =>{
		// let a = [...schedule_field_filter_list];
		// a[index][key] = value;
		// set_schedule_field_filter_list(a);
		// if(key==='field'){
			var value_details = schedule_field_list.find(x => x.id===value)
			var operators_json=[]
			if(value_details && value_details.type==='number'){
				operators_json = filter_operators_json.filter(x => x.type==='all' || x.type==='number')
			}
			if(value_details && value_details.type==='text'){
				operators_json = filter_operators_json.filter(x => x.type==='all')
			}
			// set_filter_operators_json_filtered(operators_json)
			return operators_json
		// }
	}



	useEffect(() => {
		if(filter_operators_json && filter_operators_json.length){
			set_filter_operators_json_filtered(filter_operators_json)
		}
	},[filter_operators_json])

	const add_schedule_grouping_category_type = () => {
		let a = [...schedule_grouping_rules];
		if(schedule_selected_category_types.length >0){
			a.push({category_type:schedule_selected_category_types[schedule_selected_category_types.length-1].id, fields:[schedule_field_list[0].id]})
			set_schedule_grouping_rules(a)
		}
	}

	const delete_schedule_grouping_category_type = (index) => {
		let a = [...schedule_grouping_rules];
		a.splice(index,1)
		set_schedule_grouping_rules(a)
	}

	const add_new_schedule_field_filter_list = () => {
		let a = [...schedule_field_filter_list];
		if(schedule_field_list.length >0){
			a.push({field:schedule_field_list[0].id, operator:'', values:['']})
		} else {
			a.push({field:schedule_field_default_option, operator:'', value:['']})
		}
		set_schedule_field_filter_list(a);
	}

	const delete_schedule_field_filter_list = (index) => {
		let a = [...schedule_field_filter_list];
		a.splice(index,1)
		set_schedule_field_filter_list(a);
	}

	const update_schedule_field_sorting_list = (index, key, value) =>{
		console.log('key: ', key, '--value: ', value)
		let a = [...schedule_field_sorting_list];
		a[index][key] = value;
		set_schedule_field_sorting_list(a);
	}

	const add_new_schedule_field_sorting_list = () => {
		let a = [...schedule_field_sorting_list];
		if(schedule_field_list.length >0){
			a.push({field:schedule_field_list[0].id, is_asc:'none'})
		} else {
			a.push({field:schedule_field_default_option, is_asc:'none'})
		}
		set_schedule_field_sorting_list(a);
	}

	const delete_schedule_field_sorting_list = (index) => {
		let a = [...schedule_field_sorting_list];
		a.splice(index,1)
		set_schedule_field_sorting_list(a);
	}

	useEffect(() =>{
		if(schedule_field_filter_list && !schedule_field_filter_list.length && schedule_field_list.length){
			add_new_schedule_field_filter_list()
		}
	},[schedule_field_filter_list])

	useEffect(() =>{
		if(schedule_field_sorting_list && !schedule_field_sorting_list.length && schedule_field_list.length){
			add_new_schedule_field_sorting_list()
		}
	},[schedule_field_sorting_list])

	/*useEffect(()=>{
		if(schedule_field_list && schedule_field_list.length >0){
			set_schedule_field_default_option(schedule_field_list[0].id);
		} else if(schedule_field_list && !schedule_field_list.length){
			set_schedule_field_default_option('')
		}
	}, [schedule_field_list])*/

	const Boq_fields = ({ item, input_array, set_input_array }) => {

		const onclick_selected_property = () => {
			if(item){
				// var new_schedule_field_list = schedule_field_list && schedule_field_list.filter(x => x!=item)
				// new_schedule_field_list && new_schedule_field_list.length ? set_schedule_field_list(new_schedule_field_list):set_schedule_field_list('');
				// var new_sedule_prop_list=[...schedular_properties_json_display]
				// new_sedule_prop_list.push(item)
				// new_sedule_prop_list && new_sedule_prop_list.length && set_schedular_properties_json_display(new_sedule_prop_list)
				item.is_selected=false;
				var x=[...input_array]  //schedule_field_list
				x = x.filter(x=>x.id!=item.id)
				set_all(x)
			}
		}
	
		return(
			<div style={{fontSize:'12px',width:'100%', display:'flex', alignItems:'center',justifyContent:'space-between',listStyle: 'none', textTransform:'capitalize',color:'black', padding:'10px 0px', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}} >
				<div style={{width:'100%', display:'flex', alignItems:'center'}}>
					<div style={{marginRight:'15px', display:'flex', alignItems:'center'}}><i style={{marginRight:'1px'}} className='fa fa-ellipsis-v'/><i className='fa fa-ellipsis-v'/></div>
					<div>{item.text?item.text:''}</div>
				</div>
				<div><i style={{cursor:'pointer', marginLeft:'10px'}} onClick={() => onclick_selected_property()} className='fa fa-times'/></div>
			</div>
		)
	}

	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type === "boq_properties" || modal_type === "edit_boq_properties"}>
			<IModalHeader toggle={handle_close}>Edit Schedule</IModalHeader>
			<IModalBody style={{height: '600px', overflow: 'auto'}}>
				<UncontrolledINavbar tabs={schedule_field_list && schedule_field_list.length ? ['Fields', 'Filter', 'Sorting', 'Grouping'] : ['Fields']} set_active_tab={set_tabs}></UncontrolledINavbar>
				<TabContent style={{ height: 'calc(100% - 60px)', width: '100%', overflowY: 'auto' }} activeTab={"tabs" + tabs}>
					<TabPane tabId="tabs0" style={{ fontSize: '12px', height: '100%' }}>
						<div className='flex_property py-3' style={{ height: '100%' }}>
							<div className='p-3' style={{ borderRadius: '12px', border: '1px solid var(--light-border-color)', height: '100%', overflowY: 'auto', flex: 1, background:'white' }}>
								{
									unselected_schedule_field_list && unselected_schedule_field_list.length ? unselected_schedule_field_list.map((item, idx) => (
										<div onClick={() => onclick_unselected_property(item)} className='unselected_schedule_field_list_item' style={{ cursor: 'pointer', listStyle: 'none', textTransform: 'capitalize', color: 'black', padding: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: (item && item.hidden && item.hidden == true) ? 'none' : 'flex' }} key={idx} >
											{item.text ? item.text : ''}
										</div>
									)) : ""
								}
							</div>
							<div className='p-3' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
								<div style={{ textAlign: 'center', fontSize: '14px', whiteSpace: 'nowrap', fontWeight:700 }}>{`${schedule_field_list && schedule_field_list.length ? schedule_field_list.filter(x => x.is_selected && !(x.hidden && x.hidden == true)).length : 0} Selected Properties`}</div>
							</div>
							<div className='p-3' style={{ borderRadius: '12px', border: '1px solid var(--light-border-color)', height: '100%', overflowY: 'auto', flex: 1, background:'white' }}>
								{/* Written the Hidden logic inside DragDropComponent */}
								<DragDropComponent items_from_backened={schedule_field_list} set_items_from_backened={set_schedule_field_list} modal_name='boq_fields' Single_component={Boq_fields} />
								{/* {
									schedule_field_list && schedule_field_list.length ? schedule_field_list.map((item, idx) => (
										<div key={idx} onClick={() => onclick_selected_property(item)} className='layer_modal_button_hover' style={{ cursor: 'pointer', listStyle: 'none', textTransform: 'capitalize', color: 'black', padding: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} key={idx} >
											{item.text ? item.text : ''}
										</div>
									)) : ""
								} */}
							</div>
						</div>
					</TabPane>

					<TabPane tabId="tabs1" style={{ height: '100%' }}>
						<div className='px-3' style={{ maxHeight: 'calc( 100% - 40px )', overflowY: 'auto' }}>
							{
								schedule_field_filter_list.map((x, idx) => (
									<div className='flex_property my-3' key={idx}>
										<div className='mr-3'>
											{idx == 0 ? 'Filter by:' : 'then by:'}
										</div>
										<div className='mr-3' style={{flex: 1}}>
											<Input id={'box1' + idx} style={{ fontSize: '12px' }} type="select"
												value={schedule_field_filter_list[idx].field}
												onChange={(e) => update_schedule_field_filter_list(idx, 'field', e.target.value)}
											>
												{
													schedule_field_list && schedule_field_list.length ? schedule_field_list.filter(x => x.id != 'display_pic').map((item, idx) => (
														<option style={{ listStyle: 'none', textTransform: 'capitalize', color: 'black' }} key={idx} value={item.id}>
															{item.text ? item.text : ''}
														</option>
													)) : ""
												}
											</Input>
										</div>
										<div className='mr-3' style={{flex: 1}}>
											<Input id={'box2' + idx} style={{ fontSize: '12px' }} type="select"
												value={schedule_field_filter_list[idx].operator}
												onChange={(e) => update_schedule_field_filter_list(idx, 'operator', e.target.value)}
											>
												{
													filter_operators_json && filter_operators_json.length ? update_filter_operators_json(schedule_field_filter_list[idx].field).map((item, idx) => (
														// filter_operators_json_filtered && filter_operators_json_filtered.length ? filter_operators_json_filtered.map((item, idx)=>(
														<option style={{ listStyle: 'none', textTransform: 'capitalize', color: 'black' }} key={idx} value={item.id}>
															{item.text ? item.text : ''}
														</option>
													)) : ""
												}
											</Input>
										</div>
										<div className='mr-3' style={{flex: 1, minWidth: '40px'}}>
											{/* <Input id={'box3' + idx} style={{ fontSize: '12px' }} type="select"
												value={schedule_field_filter_list[idx].value}
												onChange={(e) => update_schedule_field_filter_list(idx, 'value', e.target.value)}
											>
												{
													possible_filter_values && schedule_field_filter_list && schedule_field_filter_list[idx] && possible_filter_values[schedule_field_filter_list[idx].field] && possible_filter_values[schedule_field_filter_list[idx].field].length ? possible_filter_values[schedule_field_filter_list[idx].field].map((item, idx) => (
														// filter_operators_json_filtered && filter_operators_json_filtered.length ? filter_operators_json_filtered.map((item, idx)=>(
														<option style={{ listStyle: 'none', textTransform: 'capitalize', color: 'black' }} key={idx} value={item}>
															{item ? item : ''}
														</option>
													)) : ""
												}
											</Input> */}
											{
												schedule_field_to_type_map[schedule_field_filter_list[idx].field] == 'number' ?
													<Input id={'box3' + idx} style={{ fontSize: '12px' }} type={'text'}
														value={schedule_field_filter_list[idx].values && schedule_field_filter_list[idx].values.length ? schedule_field_filter_list[idx].values[0] : ''}
														onChange={(e) => update_schedule_field_filter_list(idx, 'values', [e.target.value])}
													>
													</Input>
													:
													<Multiselect1
														style={{ backgroundColor: 'white', position: 'unset' }}
														options={possible_filter_values && schedule_field_filter_list && schedule_field_filter_list[idx] && possible_filter_values[schedule_field_filter_list[idx].field] && possible_filter_values[schedule_field_filter_list[idx].field].length ? possible_filter_values[schedule_field_filter_list[idx].field].map(x => { return { id: x, name: x } }) : []}
														selectedValues={schedule_field_filter_list[idx].values.map(x => {return {id: x}})}
														onSelect={(selectedList) => update_schedule_field_filter_list(idx, 'values', selectedList.map(x => x.id))}
														onRemove={(selectedList) => update_schedule_field_filter_list(idx, 'values', selectedList.map(x => x.id))}
														displayValue="name" // Property name
														id='select_type'
														placeholder='Select Type'>
													</Multiselect1>


											}
										</div>
										<div className='px-2'>
											<div className='red_button_default_ghost' style={{ visibility: idx ? 'visible' : 'hidden', cursor: 'pointer' }} onClick={() => delete_schedule_field_filter_list(idx)}>{t(`Delete`)}</div>
										</div>
									</div>
								))
							}
						</div>
						<div className='flex_property'>
							<div className='px-3 py-2' onClick={add_new_schedule_field_filter_list} style={{ color: '#007BFF', cursor: 'pointer' }}>{t(`Add +`)}</div>
						</div>
					</TabPane>

					<TabPane tabId="tabs2" style={{height: '100%'}}>
						<div className='px-3' style={{ maxHeight: 'calc( 100% - 40px )', overflowY: 'auto' }}>
							{
								schedule_field_sorting_list.map((x, idx) => (
									<div className='flex_property my-3' key={idx} >
										<div className='mr-3'>
											{idx == 0 ? 'Filter by:' : 'then by:'}
										</div>
										<div className='mr-3' style={{ flex: 1 }}>
											<Input style={{ fontSize: '12px' }} type="select"
												value={schedule_field_sorting_list[idx].field}
												onChange={(e) => update_schedule_field_sorting_list(idx, 'field', e.target.value)}
											>
												{
													schedule_field_list && schedule_field_list.length ? schedule_field_list.map((item, idx) => (
														<option style={{ listStyle: 'none', textTransform: 'capitalize', color: 'black' }} key={idx} value={item.id}>
															{item.text ? item.text : ''}
														</option>
													)) : ""
												}
											</Input>
										</div>
										<div className='mr-3' style={{ flex: 1 }}>
											<Input style={{ fontSize: '12px' }} type="select"
												value={schedule_field_sorting_list[idx].is_asc}
												onChange={(e) => update_schedule_field_sorting_list(idx, 'is_asc', e.target.value)}
											>
												<option value='none'>None</option>
												<option value='asc'>ASC</option>
												<option value='desc'>DESC</option>
											</Input>
										</div>
										<div className='px-2'>
											<div className='red_button_default_ghost' style={{ visibility: idx ? 'visible' : 'hidden', cursor: 'pointer' }} onClick={() => delete_schedule_field_sorting_list(idx)}>{t(`Delete`)}</div>
										</div>
									</div>
								))
							}
						</div>
						<div className='flex_property'>
							<div className='px-3 py-2' onClick={add_new_schedule_field_sorting_list} style={{ color: '#007BFF', cursor: 'pointer' }}>{t(`Add +`)}</div>
						</div>
					</TabPane>

					<TabPane tabId="tabs3" style={{height: '100%'}}>
						<div className='px-3' style={{ maxHeight: 'calc( 100% - 40px )', overflowY: 'auto' }}>
							{
								schedule_grouping_rules.map((x, idx) => (
									<BOQ_grouping_body
										schedule_selected_category_types={schedule_selected_category_types}
										schedule_field_list={schedule_field_list}
										index={idx}
										schedule_grouping_rules={schedule_grouping_rules}
										set_schedule_grouping_rules={set_schedule_grouping_rules}
										delete_schedule_grouping_category_type={delete_schedule_grouping_category_type}
									/>
								))
							}
						</div>
						<div className='flex_property'>
							<div className='px-3 py-2' onClick={add_schedule_grouping_category_type} style={{ color: '#007BFF', cursor: 'pointer' }}>{t(`Add New Rule`)}</div>
						</div>
					</TabPane>
				</TabContent>
			</IModalBody>
			<IModalFooter>
				<Button onClick={onclick_schedule_save} className='primary_button_default' style={{marginRight: 'auto'}}>Save as Template </Button>
				<Button onClick={onclick_schedule_cancel} className='secondary_button_default' >Cancel</Button>
				<Button onClick={onclick_schedule_apply} className='primary_button_default'>Apply</Button>
			</IModalFooter>
		</Modal>
	)
}
const Presentation_Select_Page = ({ modal_json, modal_type, handle_close, handle_ui_response, update_view, set_page_loader}) => {
	const [ selected_sheet, set_selected_sheet ] = useState({});
	// const [ current_view_img_url, set_current_view_img_url ] = useState('');
	const [ sheet_type, set_sheet_type ] = useState({value: ''});
	const [ step, set_step ] = useState(1);
	const [is_free_view, set_is_free_view] = useState(false);
	const [linked_view, set_linked_view] = useState('');
	const [items, set_items] = useState([]);
	const [ get_more_presentation_sheets_fn, set_get_more_presentation_sheets_fn ] = useState({in_execution: false});

	const alert = useAlert()

	const onclick_handle_close = () => {
		handle_close();
		var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        active_view.hide_carousel();
        update_view();
        active_design.delete();
        active_view.delete();
		set_selected_sheet({})
		set_step(1)
		// set_current_view_img_url('')
	}

	const handleScroll = async(e) => {
		let element = e.target;
		if (Math.abs(element.scrollTop + element.clientHeight - element.scrollHeight) < 10 && window.Module.more_presentation_sheets_available_to_load() && !get_more_presentation_sheets_fn.in_execution) {
			get_more_presentation_sheets_fn.in_execution = true
			set_page_loader({ show: true, text: "Loading more sheets ..." }, async() => {
				await sleep(10)
				try{
					let resp = await window.Promisify(window.Module.get_more_presentation_sheets("modal"))
					handle_ui_response(resp);
				}catch(err){
					handleError(err)
				}
				set_page_loader({ show: false, text: "Loading more sheets ..." })
				get_more_presentation_sheets_fn.in_execution = false
			})
		}
	}
	
	useEffect(() => {
		if(modal_type == "presentation_select_page" && modal_json && (modal_json.existing_sheets || modal_json.new_sheets)){
        	// var canvas = document.getElementById('webgl_main_canvas');
			// if(canvas) set_current_view_img_url(canvas.toDataURL())
			// is free view? 
			var active_design = window.Module.get_active_design();
        	var active_view = active_design.active_view;
			if(active_design.is_free_view(active_view)) {
				set_step(0)
				set_is_free_view(true);
				set_items([{key: '1', title: 'Create new view'}, {key: '2', title: 'Add to presentation'}])
			}
			active_design.delete();
			active_view.delete();

			if(modal_json && modal_json.existing_sheets && modal_json.existing_sheets.length){
				set_selected_sheet(modal_json.existing_sheets[0]);
				sheet_type.value = "existing"
			}else if(modal_json && modal_json.new_sheets && modal_json.new_sheets.length){
				set_selected_sheet(modal_json.new_sheets[0]);
				sheet_type.value = "new"
			}
		}
	}, [modal_type, modal_json])

	const add_to_selected_page = async () => {

			var CANVAS_WIDTH = document.getElementById('canvas_container_div').clientWidth;
			var CANVAS_HEIGHT = document.getElementById('canvas_container_div').clientHeight;

			let response;
			if(sheet_type.value === "new"){
				if(window.debug_mode){
					window.add_debug_log(modal_json.route_new + "(\"" + JSON.stringify({value: selected_sheet.value, screen_aspect: CANVAS_WIDTH/CANVAS_HEIGHT}).replace(/"/g, '\\"') + "\");");
				}
				response = await window.Promisify(window.Module[modal_json.route_new ](JSON.stringify({value: selected_sheet.value, screen_aspect: CANVAS_WIDTH/CANVAS_HEIGHT})));	
			}else{
				if(window.debug_mode){
					window.add_debug_log(modal_json.route_existing + "(\""+String(selected_sheet.value)+"\","+(CANVAS_WIDTH/CANVAS_HEIGHT)+");");
				}	
				response = window.Module[modal_json.route_existing](String(selected_sheet.value),CANVAS_WIDTH/CANVAS_HEIGHT, "");	
			}
			handle_ui_response(response);
	}

	const choose_from_store = async () => {

		set_step(2)

		var CANVAS_WIDTH = document.getElementById('canvas_container_div').clientWidth;
        var CANVAS_HEIGHT = document.getElementById('canvas_container_div').clientHeight;

		if(window.debug_mode){
			window.add_debug_log("choose_sheet_template_from_store(\"" + modal_json.route_new + "\",\"" + JSON.stringify({value: selected_sheet.value, screen_aspect: CANVAS_WIDTH/CANVAS_HEIGHT}).replace(/"/g, '\\"') + "\");");
		}
		var o = await window.Promisify(window.Module.choose_sheet_template_from_store(modal_json.route_new,JSON.stringify({value: selected_sheet.value, screen_aspect: CANVAS_WIDTH/CANVAS_HEIGHT})));
		handle_ui_response(o);
	}

	const onclick_go_back = () => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        active_view.hide_carousel();
        update_view();
        active_design.delete();
        active_view.delete();
		set_step(1)
    }

	const free_view_back = () => {
		set_step(0);
	}

	const add_to_presentation = async() => {
		try{
			window.Module.set_temp_view_name(linked_view);
			// handle_ui_response(resp);
			set_step(1);
		}catch(err){
			alert.error("Something went wrong");
			console.error(err);
		}
	}

	const add_to_new_page = () => {
		var CANVAS_WIDTH = document.getElementById('canvas_container_div').clientWidth;
        var CANVAS_HEIGHT = document.getElementById('canvas_container_div').clientHeight;

		if(window.debug_mode){
			window.add_debug_log("add_viewport_to_presentation(\"new\","+(CANVAS_WIDTH/CANVAS_HEIGHT)+");");
		}
		var response = window.Module.add_viewport_to_presentation("new",CANVAS_WIDTH/CANVAS_HEIGHT);	
		handle_ui_response(response);
	}
	
	return(
		<Modal centered={[0, 1].includes(step)} size="lg" isOpen={modal_type == "presentation_select_page"}>
			<IModalHeader style={{borderBottom: is_free_view ? 'none' : ''}} toggle={onclick_handle_close}>Add view to Presentation</IModalHeader>
			<div style={{width: '100%', borderBottom: '1px solid #C5C7CF', borderTop: '1px solid #C5C7CF', display: is_free_view ? 'flex' : 'none', justifyContent: 'center', gap: '24px', padding: '12px'}}>
				<Steps current={step}  items={items} colorPrimary={'#29C940'} style={{width: '50%'}}/>
			</div>
			<IModalBody style={{backgroundColor: step ? '' : 'unset'}}>
					<div>
						{/* <div style={{color: 'var(--inf-theme-gray-400)'}}>Preview</div> */}
						<IImg className='inf-mb-3' width={'100%'} style={{maxHeight: '320px', objectFit: 'contain', display: step ? '' : 'none'}} src={modal_json && modal_json.image_path} />
					</div>
				<div className='inf-p-4 flex_center' style={{ background: 'white', margin: '0px -16px -16px' }}>
					{
						step == 0 ? 
						<>
							<div style={{display: 'flex', flexDirection: 'column', gap: '32px', paddingTop: '8px', paddingBottom: '24px'}}>
								<div style={{color: '#A7A8B2', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
									<span>In order to add free-view to presentation you need to</span> 
									<span>create a new named view from it.</span>
								</div>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<div style={{flex: 1}}>View Name</div>
									<Input value={linked_view} onChange={(e) => set_linked_view(e.target.value)} id='new_linked_view_input' style={{...styles_modals.input_styles, padding: '8px', flex: 2}} placeholder='Enter view name' />
								</div>
							</div>
						</>
						: step === 1 ?
							<div>
								<div style={{ color: 'var(--inf-theme-gray-400)', fontSize: '12px' }}>Select destination sheet:</div>
								<UncontrolledDropdown style={{}}>
									<DropdownToggle style={{ background: 'white', color: COLORS.gray7, fontSize: '14px', padding: '4px', border: '1px solid  var(--dark-border-color)', display: 'flex', alignItems: 'center', width: '280px', boxShadow: 'none' }}>
										<div style={{ display: 'flex', flexFlow: 'row', fontSize: '14px', alignItems: 'center', flex: 1, minWidth: '0px', overflow: 'auto' }}>
											{selected_sheet && selected_sheet.icon ? <IImg className='mr-2' height={'56px'} style={{ maxWidth: '100px', objectFit: 'contain', border: '1px solid  var(--dark-border-color)' }} src={selected_sheet.icon} ></IImg> : ''}
											<div title={selected_sheet && selected_sheet.name ? selected_sheet.name : ''} className='lines1_elipsis' style={{ flex: 1, textAlign: 'left' }}>{selected_sheet && selected_sheet.name ? selected_sheet.name : ''}</div>
										</div>
										<span className='inf-p-2'><i className='fa fa-caret-down' /></span>
									</DropdownToggle>
									<DropdownMenu onScroll={handleScroll} style={{ width: '100%', maxHeight: '360px', overflow: 'auto' }}>
										<div style={{ color: 'var(--inf-theme-gray-400)', fontSize: '12px' }}>Add to new sheet</div>
										{
											modal_json && modal_json.new_sheets ? modal_json.new_sheets.map((option, idx) => (
												<DropdownItem title={option.name} style={{ listStyle: 'none', padding: '4px', fontSize: '14px', color: COLORS.gray7 }} onClick={() => { set_selected_sheet(option), sheet_type.value = "new" }} key={idx}>
													<div className='flex_property'>
														<IImg className='mr-2 inf-border' height={'56px'} style={{ maxWidth: '100px', objectFit: 'contain' }} src={option.icon} ></IImg>
														<div className='lines1_elipsis'>{option.name}</div>
													</div>
												</DropdownItem>
											))
												: ''
										}
										<DropdownItem divider />
										<div style={{ color: 'var(--inf-theme-gray-400)', fontSize: '12px' }}>Add to existing sheet</div>
										{
											modal_json && modal_json.existing_sheets ? modal_json.existing_sheets.map((option, idx) => (
												<DropdownItem title={option.name} style={{ listStyle: 'none', padding: '4px', fontSize: '14px', color: COLORS.gray7 }} onClick={() => { set_selected_sheet(option); sheet_type.value = "existing" }} key={idx}>
													<div className='flex_property'>
														<IImg className='mr-2 inf-border' height={'56px'} style={{ maxWidth: '100px', objectFit: 'contain' }} src={option.icon} ></IImg>
														<div className='lines1_elipsis'>{option.name}</div>
													</div>
												</DropdownItem>
											))
												: ''
										}
									</DropdownMenu>
								</UncontrolledDropdown>
							</div>
							:
							<div style={{ color: 'var(--inf-theme-gray-400)', fontSize: '14px' }}>Select the template in carousel to add to presentation</div>
						}
				</div>

			</IModalBody>
			<IModalFooter>
				{
					step == 0 ? 
					<Button onClick={linked_view ? add_to_presentation : ''} style={{opacity: linked_view ? 1 : 0.5, cursor: linked_view ? '' : 'not-allowed'}} className="primary_button_default">Next</Button>
					:
					step === 1 ?
						<div style={{width: '100%', display: 'flex', justifyContent: is_free_view ? 'space-between' : 'flex-end'}}>
							{is_free_view ? <Button style={{marginRight: 'auto'}} onClick={free_view_back} className="secondary_button_default">Back</Button> : ''}
							<div style={{display: 'flex', gap: '16px'}}>
								<Button onClick={choose_from_store} className="secondary_button_default">Use template</Button>
								<Button onClick={add_to_selected_page} className="primary_button_default">Add to selected sheet</Button>
							</div>
						</div>
						:
						<Button onClick={onclick_go_back} className="secondary_button_default mr-auto">Go Back</Button>
				}
			</IModalFooter>
		</Modal>
    )   
}

const Presentation_Select_View = ({ modal_json, modal_type, handle_close, handle_ui_response, update_view, set_page_loader}) => {
	// const [ current_view_img_url, set_current_view_img_url ] = useState('');
	// const [items, set_items] = useState([]);
	const [linked_view, set_linked_view] = useState([]);


	const alert = useAlert()

	const onclick_handle_close = () => {
		handle_close();
	}

	const update_linked_view_image = async(linked_view) => {
		try{
			var CANVAS_WIDTH = document.getElementById('canvas_container_div').clientWidth;
			var CANVAS_HEIGHT = document.getElementById('canvas_container_div').clientHeight;
			var resp = await window.Promisify(window.Module.clickImageOfView(JSON.stringify({view_id :linked_view.id, use_size_x:CANVAS_WIDTH, use_size_y: CANVAS_HEIGHT})));
			handle_ui_response(resp);
			update_view()
		} catch(err){
			console.log(err);
			alert.error('View could not be linked');
		}
	}
	
	useEffect(() => {
		if(modal_type == "presentation_select_view" && modal_json){
			update_view();
		}

	}, [modal_type, modal_json])

	useEffect(() => {
		if(linked_view && linked_view.id){
			update_linked_view_image(linked_view);
		}
	}, [linked_view])

	


	const add_to_selected_page = async () => {
		var CANVAS_WIDTH = document.getElementById('canvas_container_div').clientWidth;
		var CANVAS_HEIGHT = document.getElementById('canvas_container_div').clientHeight;

		let response;
		response = window.Module[modal_json.route_final](String(modal_json.sheet_id),CANVAS_WIDTH/CANVAS_HEIGHT, linked_view ? linked_view.id : "");	
		handle_ui_response(response);
	}
	
	return(
		<Modal centered="true" size="lg" isOpen={modal_type == "presentation_select_view"}>
			<IModalHeader style={{borderBottom: ''}} toggle={onclick_handle_close}>Add view to Presentation</IModalHeader>
			<IModalBody style={{backgroundColor: ''}}>
				<div>
					{/* <div style={{color: 'var(--inf-theme-gray-400)'}}>Preview</div> */}
					<IImg key={linked_view} className='inf-mb-3' width={'100%'} style={{maxHeight: '320px', objectFit: 'contain', display:''}} src={modal_json && modal_json.image_path} />
				</div>
				<div className='inf-p-4 flex_center' style={{ background: 'white', margin: '0px -16px -16px' }}>
					{
						<div>
							<div style={{ color: 'var(--inf-theme-gray-400)', fontSize: '12px' }}>Select view from design to add as viewport</div>
							<ViewSelector design_views={modal_json && modal_json.view_list} json_item={{}} common_onchange_route={""} handle_ui_response={handle_ui_response} update_view={update_view} linked_view_id={modal_json && modal_json.view_list.length && modal_json.view_list[0].id} callbackOnchangeViewID={set_linked_view}>
							</ViewSelector>
						</div>
					}
				</div>

			</IModalBody>
			<IModalFooter>
				{
					<div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
						<div style={{display: 'flex', gap: '16px'}}>
							<Button onClick={add_to_selected_page} className="primary_button_default">Add to current sheet</Button>
						</div>
					</div>
				}
			</IModalFooter>
		</Modal>
    )   
}

const Choose_Page_Template = ({ modal_json, modal_type,  handle_close, handle_ui_response, set_page_loader}) => {
    const {t} = useTranslation()

    const image_onclick_fn = async (item) => {
		if(window.debug_mode){
			window.add_debug_log(modal_json.route + "(\"" + JSON.stringify(item).replace(/"/g, '\\"') + "\");");
		}

		set_page_loader({show: true, text: "Please Wait. Adding page to presentation..."}, async() => {
			await sleep(10);	
			var response = await window.Promisify(window.Module[modal_json.route](JSON.stringify(item)));
			handle_ui_response(response);
			set_page_loader({show: false, text: "Please Wait"})
			handle_close();
		})
	}

	const choose_from_store = async () => {
		if(window.debug_mode){
			window.add_debug_log("choose_sheet_template_from_store(\"" + modal_json.route + "\",\"" + JSON.stringify(modal_json.options[0]).replace(/"/g, '\\"') + "\");");
		}
		var o = await window.Promisify(window.Module.choose_sheet_template_from_store(modal_json.route,JSON.stringify(modal_json.options[0])));
		handle_ui_response(o);
	}

	const get_image_src = (item_img_src) => {
		return item_img_src?(item_img_src.includes('resources/')?(item_img_src):(window.Module.API.server_path+item_img_src)) : '/resources/images/site_image.jpeg';
	}

	const is_carousel_open = () => {
		let active_design = window.Module.get_active_design();
		let active_view = active_design.active_view;
		let carousel_open = active_view.carousel_show;

		active_view.delete();
		active_design.delete();
		return carousel_open;
	}

    return(
        // <Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="page_template"} toggle={handle_close}>
        //     <Card className="bg-secondary shadow border-0">
        //         <CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >  
		// 			<div>
		// 				<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}>
		// 					<span>Choose a Template</span>
		// 					<span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span>
		// 				</h5>
		// 			</div>
        //         </CardHeader>
        //         <CardBody style = {{backgroundColor:COLORS.white }} className="text-center px-lg-2 py-lg-2">
        //             <Card_Layout image_onclick_fn={image_onclick_fn} list={modal_json&&modal_json.options?modal_json.options:''} custom_style={{border:"1px solid black"}} no_of_cards={6} is_template='true' />
		// 			<br />
		// 			(OR)
		// 			<br />
		// 			<Button onClick={choose_from_store} className="btn btn-default" type="button">Choose from catalogue</Button>
        //         </CardBody>
        //     </Card>
        // </Modal>
		<Modal centered={!is_carousel_open()} size="lg" isOpen={modal_type=="page_template"} toggle={handle_close}>
		<Card className="bg-secondary shadow border-0">
			<IModalHeader toggle={handle_close}>Choose a Template</IModalHeader>
			<IModalBody style={{maxHeight: '550px', overflow: 'auto'}}>
				<div className='presentation-template-card-container mb-3'>
					{
						modal_json && modal_json.options && modal_json.options.map(item => (
							<div className='presentation-template-card' onClick={() => image_onclick_fn(item)}>
								<img src={get_image_src(item.display_pic && item.display_pic.image)} width={'100%'}/>
							</div>
						))
					}
				</div>
				<div className='p-3 mb-3 flex_center' style={{fontWeight: 500}}>{t(`(OR)`)}</div>
				<div className='flex_center'>
					<Button onClick={choose_from_store} className="primary_button_default" type="button">Choose from catalogue</Button>
				</div>
			</IModalBody>
		</Card>
	</Modal>
    )    
}

const Edit_other_charges_modal = ({open,update_modal_json, handleclose, set_page_loader, other_charges_type_modal, other_charges_value_modal}) =>{

	const [ other_charges_type, set_other_charges_type] = useState('absolute')
	const [ other_charges_value, set_other_charges_value] = useState(0)
    const {t} = useTranslation()

	const onclick_handle_close = () =>{
		handleclose()
		set_other_charges_type('absolute')
		set_other_charges_value(0)
	}

	const onclick_update = () =>{
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {  
			window.Module.set_other_charges(other_charges_value ? parseFloat(other_charges_value) : 0, other_charges_type ? (other_charges_type) : 'absolute');
			update_modal_json();
			onclick_handle_close()
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	useEffect(() => {
		if (open) {
			if (other_charges_type != other_charges_type_modal) {
				set_other_charges_type(other_charges_type_modal)
			}
			if (other_charges_value != other_charges_value_modal) {
				set_other_charges_value(other_charges_value_modal)
			}
		}
	}, [other_charges_type_modal, other_charges_value_modal, open]);

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onclick_handle_close}>
			<IModalHeader toggle={onclick_handle_close}>Edit Row Price</IModalHeader>
			<IModalBody style={{ maxHeight: '600px', overflow: 'auto' }}>
				<div className='mb-3'>
					<div className='mb-1'>{t(`Other Charges Type `)}</div>
					<Input style={{ fontSize: '12px' }} onChange={e => set_other_charges_type(e.target.value)} value={other_charges_type} type='select'>
						<option value={'absolute'}>Absolute</option>
						<option value={'percentage'}>Percentage</option>
					</Input>
				</div>
				<div>
					<div className='mb-1'>{t(`Other Charges Value : `)}</div>
					<Input style={{ fontSize: '12px' }} onChange={e => set_other_charges_value(e.target.value)} value={other_charges_value} type='number' />
				</div>
			</IModalBody>
			<IModalFooter>
				<Button onClick={onclick_handle_close} className='secondary_button_default' type="button">Cancel</Button>
				<Button onClick={onclick_update} className='primary_button_default' type="button">Update</Button>
			</IModalFooter>
		</Modal>
	)

}

const Checkbox_input = ({unit, style}) => {

	const [checkbox_value, set_checkbox_value] = useState(false)

	useEffect(() => {
		if(unit){
			set_checkbox_value(unit.includes?true:false)
		}
	},[unit])
	
	// useEffect(() => {
	// 	console.log(checkbox_value)
	// 	window.Module.set_show_in_includes(checkbox_value,unit.id);
	// },[checkbox_value])

	const set_includes = (value) => {

		window.Module.set_show_in_includes(!checkbox_value,unit.id);

		set_checkbox_value(!checkbox_value)
	}

	return(
		<Input style={{...style, position:'unset', margin:0}} type='checkbox' checked={checkbox_value?true:''}  onChange={set_includes} />
	)
}
const Price_details_modal = ({  modal_type,update_modal_json , handle_close,  modal_json, set_page_loader }) => {

	const styles={
		td_style:{
			width:"100%",
			minHeight:'50px',
			heigth:'auto',
			overflowWrap: 'anywhere',
			display:'flex',
			alignItems:'center',
			padding:"0px 5px"
		},
		th_style:{
			width:"100%",
			padding:"0px 5px"		
		}
	}

	const [ show_edit_other_charges, set_show_edit_other_charges ] = useState(false);

	const open_edit_other_charges_modal = () => {
		set_show_edit_other_charges(true)
	}

	const close_edit_other_charges_modal = () => {
		set_show_edit_other_charges(false)
	}

	const onClick_handleclose = () => {
		handle_close()
	}

	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type=="price_details"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>{modal_json && modal_json.name}</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={onClick_handleclose}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white, padding:'30px 20px', fontSize:'13px' }}>					
					<Row>
						<Col>
							<table style={{width:'100%'}}>
								<thead>
								<tr style={{height:'auto', width:'100%', display:'flex' , alignItems:'center', borderBottom:"2px solid #ddd",}}>
									<th style={{...styles.th_style,flex:1,}}>#</th>
									{/* <th style={{...styles.th_style,flex:3}}><span>Id</span></th> */}
									<th style={{...styles.th_style,flex:2}}><span>Type</span></th>
									<th style={{...styles.th_style,flex:3}}>Name</th>
									<th style={{...styles.th_style,flex:3}}>Description</th>
									<th style={{...styles.th_style,flex:2}}><span>Count</span></th>
									<th style={{...styles.th_style,flex:2}}><span>Dimension<br /><span style={{whiteSpace:'nowrap'}}>(W * H * Th)</span></span></th>
									<th style={{...styles.th_style,flex:1}}>Shows in includes</th>
									<th style={{...styles.th_style,flex:2, textAlign:'right'}}><span>Price per Unit <br /><span style={{whiteSpace:'nowrap'}}>(in {modal_json && modal_json.currency?modal_json.currency:'INR'})</span></span></th>
									<th style={{...styles.th_style,flex:2, textAlign:'right'}}><span>Price <br /><span style={{whiteSpace:'nowrap'}}>(in {modal_json && modal_json.currency?modal_json.currency:'INR'})</span></span></th>
								</tr>
								</thead>
								<tbody style={{display:"table",width:"100%", minHeight:'300px', maxHeight:"500px", overflow:"auto"}}>
									{
										modal_json && modal_json.units && modal_json.units.length>0 ? modal_json.units.map((unit,idx) => (
											<tr key={idx} style={{width:"100%", display:'flex' , alignItems:'center', backgroundColor:(idx%2==0)?'#f2f2f2':'', borderBottom:'1px solid #ddd'}}>
												<td style={{...styles.td_style,flex:1}}><span>{idx+1}</span></td>
												{/* <td style={{...styles.td_style,flex:3}}><span>{unit.id?unit.id:"-"}</span></td> */}
												<td style={{...styles.td_style,flex:2}}><span>{unit.type?unit.type:"-"}</span></td>
												<td style={{...styles.td_style,flex:3}}><span>{unit.name?unit.name:"-"}</span></td>
												<td style={{...styles.td_style,flex:3}}><span>{unit.description?unit.description:"-"}</span></td>
												<td style={{...styles.td_style,flex:2}}><span>{unit.count?unit.count:"-"}</span></td>
												<td style={{...styles.td_style,flex:2}}>
													<span>
														{(unit.width && unit.width != -1)?unit.width:"-"} * {(unit.length && unit.length != -1)?unit.length:"-"} * {(unit.height && unit.height != -1) ?unit.height:"-"}
													</span>
												</td>
												<td style={{...styles.td_style,flex:1}}><span><Checkbox_input unit={unit}/></span></td>
												<td style={{...styles.td_style,flex:2, justifyContent:'flex-end'}}><span>{unit.price_per_unit?unit.price_per_unit:"0"}&nbsp;{unit.unit_type?(unit.unit_type == "per unit"?unit.unit_type:"per "+unit.unit_type):""}</span></td>
												<td style={{...styles.td_style,flex:2, justifyContent:'flex-end'}}><span>{unit.price?unit.price:"0"}</span></td>
											</tr>
									)):''}
								</tbody>
							</table>
						</Col>  
					</Row>
					<Row>
						<Col>
							<Edit_other_charges_modal open={show_edit_other_charges} update_modal_json = {update_modal_json} handleclose={close_edit_other_charges_modal} set_page_loader={set_page_loader} other_charges_type_modal={modal_json && modal_json.other_charges_type ? modal_json.other_charges_type : 'absolute'} other_charges_value_modal={modal_json && modal_json.other_charges_value ? modal_json.other_charges_value : 0}/>
							<div style={{paddingTop:"5px", textAlign:'center'}}>Current Price (in {modal_json && modal_json.currency?modal_json.currency:'INR'}) : {modal_json&&modal_json.current_price?modal_json.current_price:'-'}</div>
							<div style={{paddingTop:"5px", textAlign:'center'}}>Other Charges (in {modal_json && modal_json.currency?modal_json.currency:'INR'}): {modal_json&&modal_json.other_charges?modal_json.other_charges:'-'} <span onClick={open_edit_other_charges_modal} style={{marginLeft:'10px', cursor:"pointer", color:COLORS.mainpage_locale}}>EDIT</span></div>
							<div style={{paddingTop:"5px", textAlign:'center'}}>Final Price (in {modal_json && modal_json.currency?modal_json.currency:'INR'}): {modal_json&&modal_json.final_price?modal_json.final_price:'-'}</div>
						</Col>  
					</Row>
				</CardBody>
			</Card>
		</Modal>
	)    
}

const Edit_Sku_price = ({open, handleclose, selected_sku, update_modal_json, allow_single_edit, set_page_loader}) =>{

	const styles={
		dropdown_style:{
			padding:"0px 12px",
			fontSize:"12px",
			// maxWidth:'200px',
			borderRadius:'4px',
			height:"32px",
			marginLeft:"12px",
			minWidth: '0px',
			flex: 1
		},
		inputtext_style:{
			padding:"0px 12px",
			fontSize:"12px",
			// maxWidth:'100px',
			borderRadius:'4px',
			height:"32px",
			marginLeft:"12px",
			minWidth: '0px',
			flex: 1	
		},
		// inputtext_style:{
		// 	padding:"2px",
		// 	fontSize:"10px",
		// 	maxWidth:'100px',
		// 	borderRadius:'0px',
		// 	maxHeight:"20px",
		// 	marginLeft:"10px"		
		// },
	}

	const [ selected_sales_channel_id, set_selected_sales_channel_id] = useState('')
	const [ selected_price_type_id, set_selected_price_type_id] = useState('')
	const [ selected_sales_channel_details, set_selected_sales_channel_details] = useState('')
	const [ selected_price_type_details, set_selected_price_type_details] = useState('')
	const [ total_price, set_total_price] = useState('')
	const [ price_unit, set_price_unit] = useState('')
	const [ unit_price, set_unit_price] = useState('')
	const [ sku_sales_channel, set_sku_sales_channel] = useState({})
	const [ sku_price_type_options, set_sku_price_type_options] = useState({})
	const [ addon, set_addon] = useState('')
	const [ percentage, set_percentage] = useState('')

	const onclick_handle_close = () =>{
		handleclose()
		set_selected_sales_channel_id('')
		set_selected_sales_channel_details('')
		set_total_price('')
		set_sku_sales_channel([])
		set_addon('')
		set_percentage('')
	}

	const onclick_update = () =>{
		var modified_price = total_price;
		modified_price = modified_price.replace(/[a-zA-Z\s_]/g, '');
		var final_json={};
		final_json.action = "edit";
		if(selected_sales_channel_id!='custom'){
			final_json.active_sales_id = selected_sales_channel_id
			final_json.active_price_id = (selected_price_type_id && selected_price_type_id.length && selected_price_type_id != '' ? selected_price_type_id : 'notset')
			final_json.addon = Number(addon)
			final_json.percentage = Number(percentage)
			if(allow_single_edit){
				final_json.sku_ids = [selected_sku.sku_id],
				final_json.modified_price = Number(modified_price),
				final_json.display_unit = selected_sku.unit_type
			}else{
				final_json.sku_ids = selected_sku.sku_id
			}
		}else{
			final_json.sku_ids = [selected_sku.sku_id],
			final_json.active_sales_id = selected_sales_channel_id,
			final_json.active_price_id = 'notset',
			final_json.unit_price = Number(unit_price),
			final_json.display_unit = price_unit
		}

		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {  
			window.Module.set_overridden_sku_price(JSON.stringify(final_json));
			update_modal_json();
			onclick_handle_close()
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	const onclick_reset = ()=>{
		var final_json={};
		final_json.action = "reset";
		if(allow_single_edit){
			final_json.sku_ids = [selected_sku.sku_id]
		}else{
			final_json.sku_ids = selected_sku.sku_id
		}
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {  
			window.Module.set_overridden_sku_price(JSON.stringify(final_json));
			update_modal_json();
			onclick_handle_close()
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	const onChange_selected_sc = (sales_channel_id) => {
		set_selected_sales_channel_id(sales_channel_id)
	}
	const onChange_selected_price_type = (price_type_id) => {
		set_selected_price_type_id(price_type_id)
	}

	const fn_change_total = () => {
		//apply loader if needed
		//write code
		// console.log(selected_sku)
		// var total_price_dummy=selected_sku.price
		if(selected_price_type_details){
			// var total_price_dummy=calculate_unit_display_price(selected_sales_channel_details.price.price,selected_sales_channel_details.price.display_unit);
			// var total_value_split = total_price_dummy?total_price_dummy.split(' '):[];
			var total_value=(Number(selected_price_type_details.price) + Number(addon) + Number(selected_price_type_details.price)*Number(percentage)/100) + (selected_price_type_details.display_unit==='per_unit'||selected_price_type_details.display_unit==='per unit'?' ':' per ') + selected_price_type_details.display_unit;
			set_total_price(total_value)
		}
	}

	useEffect(() => {
		if(addon!=null || percentage!=null){
			fn_change_total()
		}
	},[addon, percentage])
	
	useEffect(() => {
		if(selected_sku){
			// console.log('write update fn here .')
			set_sku_sales_channel(selected_sku.sales_channels && selected_sku.sales_channels.length ? selected_sku.sales_channels:[])
			set_addon(selected_sku.add_on?selected_sku.add_on:0)
			set_percentage(selected_sku.percentage?selected_sku.percentage:0)
		}
	},[selected_sku])
	
	useEffect(() => {
		if(allow_single_edit){
			if(selected_sku.active_sales_channel_index!='-1' && sku_sales_channel && sku_sales_channel.length){
				set_selected_sales_channel_id(sku_sales_channel[selected_sku.active_sales_channel_index].id)
			}else{
				set_selected_sales_channel_id('custom')
			}
		}else{
			set_selected_sales_channel_id(selected_sku.sales_channels && selected_sku.sales_channels.length?selected_sku.sales_channels[0].id:'')
		}
	},[sku_sales_channel])
	
	useEffect(() => {
		if(allow_single_edit){
			if(selected_sales_channel_id!='custom'){
				var x_sc_details = sku_sales_channel && sku_sales_channel.length ? sku_sales_channel.find(x=> x.id === selected_sales_channel_id):{}
				set_selected_sales_channel_details(x_sc_details)
			}else{
				var x_custom = sku_sales_channel.find(x=>x.id=='custom')
				set_selected_sales_channel_details(x_custom)
			}
		}else{
			set_selected_sales_channel_details(selected_sku.sales_channels && selected_sku.sales_channels.length?selected_sku.sales_channels[0]:'')
		}
		console.log("selected_sales_channel_id ",selected_sales_channel_id)
	},[selected_sales_channel_id])

	useEffect(() => {
		if(selected_sales_channel_details){
			set_sku_price_type_options(selected_sales_channel_details && selected_sales_channel_details.available_price_types && selected_sales_channel_details.available_price_types.length ? selected_sales_channel_details.available_price_types : {})
		}
		var new_price_type_id = '';
		if(selected_sales_channel_details && selected_sales_channel_details.price && selected_sales_channel_details.price.id){
			new_price_type_id = selected_sales_channel_details.price.id
			if(selected_sales_channel_id!='custom'){
				// set_total_price(total_value)
			}else{
				set_unit_price(selected_sales_channel_details.price.price)
				set_price_unit(selected_sales_channel_details.price.display_unit)
			}
		}else if(selected_sales_channel_details && selected_sales_channel_details.available_price_types && selected_sales_channel_details.available_price_types.length){
			new_price_type_id = selected_sales_channel_details.available_price_types[0].id
		}
		set_selected_price_type_id(new_price_type_id)

	},[selected_sales_channel_details])

	useEffect(() => {
		var x_pt_details = sku_price_type_options && sku_price_type_options.length ? sku_price_type_options.find(x=> x.id === selected_price_type_id):{}
		set_selected_price_type_details(x_pt_details)
	},[sku_price_type_options, selected_price_type_id])

	useEffect(() => {
		if(selected_price_type_details){
			var total_value=(Number(selected_price_type_details.price) + Number(addon) + Number(selected_price_type_details.price)*Number(percentage)/100) + (selected_price_type_details.display_unit==='per_unit'||selected_price_type_details.display_unit==='per unit'?' ':' per ') + selected_price_type_details.display_unit;
			set_total_price(total_value)
		}
	},[selected_price_type_details])


	return(
		// <Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onclick_handle_close}>
		// 	<Card className="bg-secondary shadow border-0" style={{fontSize:"13px"}}>
		// 		<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
		// 			<div>
		// 				<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Edit SKU Price</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={onclick_handle_close}>×</span></span></h5>
		// 			</div>
		// 		</CardHeader>
		// 		<CardBody style = {{backgroundColor: COLORS.white, padding:'30px 20px' , fontSize:"12px", fontWeight:500,}}>
		// 			<Row>
		// 				<Col>
		// 					<div style={{display:"flex", alignItems:'center', margin:'5px 0px'}}>
		// 						<span style={{width:'120px', display:'flex', justifyContent:"space-between"}}><span>Sales Channel</span><span>: &nbsp;</span></span>
		// 						<Input value={selected_sales_channel_id} onChange={(e) =>onChange_selected_sc(e.target.value)} style={styles.dropdown_style} type='select'>
		// 							{
		// 								sku_sales_channel && sku_sales_channel.length ? sku_sales_channel.map((sales_ch, idx) => 
		// 									<option key={idx} value={sales_ch.id}>{sales_ch.name}</option>
		// 								):''
		// 							}
		// 						</Input>
		// 					</div>
		// 				</Col>  
		// 			</Row>
		// 			{
		// 				selected_sales_channel_id!='custom' && allow_single_edit?
		// 				<Row>
		// 					<Col>
		// 						<div style={{display:"flex", alignItems:'center', margin:'5px 0px'}}>
		// 							<span style={{width:'120px', display:'flex', justifyContent:"space-between"}}><span>Price Type</span><span>: &nbsp;</span></span>
		// 							<Input value={selected_price_type_id} onChange={(e) =>onChange_selected_price_type(e.target.value)} style={styles.dropdown_style} type='select'>
		// 								{
		// 									sku_price_type_options && sku_price_type_options.length ? sku_price_type_options.map((price_type, idx) => 
		// 										<option key={idx} value={price_type.id}>{price_type.name}</option>
		// 									):''
		// 								}
		// 							</Input>
		// 						</div>
		// 					</Col>  
		// 				</Row>
		// 				:''
		// 			}
		// 			{
		// 				selected_sales_channel_id!='custom' && allow_single_edit?
		// 				<Row>
		// 					<Col>
		// 						<div style={{display:"flex", alignItems:'center', margin:'5px 0px'}}>
		// 							<span style={{width:'120px', display:'flex', justifyContent:"space-between"}}><span>Price</span><span>: &nbsp;</span></span>
		// 							<span style={{marginLeft:'10px'}}>{selected_price_type_details ? selected_price_type_details.price + ((selected_price_type_details.display_unit==='per_unit'||selected_price_type_details.display_unit==='per unit'||[null, 'constructed price'].includes(selected_price_type_details.price))?' ':' per ') + selected_price_type_details.display_unit :'-'}</span>
		// 						</div>
		// 					</Col>  
		// 				</Row>
		// 				:''
		// 			}
		// 			{
		// 				selected_sales_channel_id!='custom' && (selected_price_type_details && !([null, 'constructed price'].includes(selected_price_type_details.price)))?
		// 				<Row>
		// 					<Col style={{ margin:'5px 0px'}}>
		// 						<div style={{width:'120px', display:'flex', justifyContent:"space-between"}}><span>Price Mark Up</span><span>: &nbsp;</span></div>
		// 						<div style={{display:"flex", alignItems:'center', margin:'5px 0px 5px 30px'}}>
		// 							<span style={{width:'90px', display:'flex', alignItems:'center', justifyContent:"space-between"}}><span>Percentage</span><span>: &nbsp;</span></span>
		// 							<Input value={percentage} onChange={(e) => set_percentage(e.target.value)} style={styles.inputtext_style} type='number'/>
		// 						</div>
		// 						<div style={{display:"flex", alignItems:'center', margin:'5px 0px 5px 30px'}}>
		// 							<span style={{width:'90px', display:'flex', alignItems:'center', justifyContent:"space-between"}}><span>Addon</span><span>: &nbsp;</span></span>
		// 							<Input value={addon} onChange={(e) => set_addon(e.target.value)} style={styles.inputtext_style} type='number'/>
		// 						</div>
		// 					</Col>  
		// 				</Row>
		// 				:""
		// 			}
					
						
		// 			{
		// 				selected_sales_channel_id!='custom' && allow_single_edit && (selected_price_type_details && !([null, 'constructed price'].includes(selected_price_type_details.price)))?
		// 				<Row>
		// 					<Col>
		// 						<div style={{display:"flex", alignItems:'center', margin:'5px 0px', fontWeight:'bold'}}>
		// 						<span style={{width:'120px', display:'flex', justifyContent:"space-between"}}><span>Total Price</span><span>: &nbsp;</span></span>
		// 							<span style={{marginLeft:'10px'}}>{total_price?total_price:''}</span>
		// 						</div>
		// 					</Col>  
		// 				</Row>
		// 				:''
		// 			}{
		// 				selected_sales_channel_id==='custom'?
		// 					<React.Fragment>
		// 						<Row>
		// 							<Col>
		// 								<div style={{display:"flex", alignItems:'center', marginTop:'15px'}}>
		// 								<span style={{width:'120px', display:'flex', justifyContent:"space-between"}}><span>Unit</span><span>: &nbsp;</span></span>
		// 									<Input value={price_unit} onChange={e=>set_price_unit(e.target.value)} style={styles.inputtext_style} type='select'>
		// 										{
		// 											selected_sales_channel_details && selected_sales_channel_details.display_unit_options && selected_sales_channel_details.display_unit_options.length ? selected_sales_channel_details.display_unit_options.map((unit, idx) => 
		// 												<option key={idx} value={unit}>{unit}</option>
		// 											):[]
		// 										}
		// 									</Input>
		// 								</div>
		// 							</Col>  
		// 						</Row>
		// 						<Row>
		// 							<Col>
		// 								<div style={{display:"flex", alignItems:'center', margin:'15px 0px', fontWeight:'bold'}}>
		// 								<span style={{width:'120px', display:'flex', justifyContent:"space-between"}}><span>Unit Price</span><span>: &nbsp;</span></span>
		// 									<Input value={unit_price} onChange={e=>set_unit_price(e.target.value)} style={styles.inputtext_style} type='number'/>
		// 								</div>
		// 							</Col>  
		// 						</Row>
		// 					</React.Fragment>
		// 				:''
		// 			}
					
		// 			<div style={{paddingTop:"20px", fontSize:'12px'}}>
		// 				<Button onClick={onclick_handle_close} className='white_button'type="button">Cancel</Button>
		// 				<Button onClick={onclick_update} className='blue_button' type="button">Update</Button>
		// 				<Button onClick={onclick_reset} className='blue_button' type="button">Reset</Button>
		// 			</div>
		// 		</CardBody>
		// 	</Card>
        // </Modal>
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onclick_handle_close}>
			<IModalHeader toggle={onclick_handle_close}>Edit SKU Price</IModalHeader>
			<IModalBody>
				<div className='flex_property mb-3'>
					<span style={{ width: '120px', display: 'flex', justifyContent: "space-between" }}><span>Sales Channel</span><span>: &nbsp;</span></span>
					<Input value={selected_sales_channel_id} onChange={(e) => onChange_selected_sc(e.target.value)} style={styles.dropdown_style} type='select'>
						{
							sku_sales_channel && sku_sales_channel.length ? sku_sales_channel.map((sales_ch, idx) =>
								<option key={idx} value={sales_ch.id}>{sales_ch.name}</option>
							) : ''
						}
					</Input>
				</div>
				{
					selected_sales_channel_id != 'custom' && allow_single_edit ?
						<div className='flex_property mb-3'>
							<span style={{ width: '120px', display: 'flex', justifyContent: "space-between" }}><span>Price Type</span><span>: &nbsp;</span></span>
							<Input value={selected_price_type_id} onChange={(e) => onChange_selected_price_type(e.target.value)} style={styles.dropdown_style} type='select'>
								{
									sku_price_type_options && sku_price_type_options.length ? sku_price_type_options.map((price_type, idx) =>
										<option key={idx} value={price_type.id}>{price_type.name}</option>
									) : ''
								}
							</Input>
						</div>
						: ''
				}
				{
					selected_sales_channel_id != 'custom' && allow_single_edit ?
						<div className='flex_property mb-3'>
							<span style={{ width: '120px', display: 'flex', justifyContent: "space-between" }}><span>Price</span><span>: &nbsp;</span></span>
							<span style={{ marginLeft: '10px' }}>{selected_price_type_details ? selected_price_type_details.price + ((selected_price_type_details.display_unit === 'per_unit' || selected_price_type_details.display_unit === 'per unit' || [null, 'constructed price'].includes(selected_price_type_details.price)) ? ' ' : ' per ') + selected_price_type_details.display_unit : '-'}</span>
						</div>
						: ''
				}
				{
					selected_sales_channel_id != 'custom' && (selected_price_type_details && !([null, 'constructed price'].includes(selected_price_type_details.price))) ?
						<div className='mb-3'>
							<div className='mb-2' style={{ width: '120px', display: 'flex', justifyContent: "space-between" }}><span>Price Mark Up</span><span>: &nbsp;</span></div>
							<div className='flex_property mb-3 ml-4'>
								<span style={{ width: '96px', display: 'flex', alignItems: 'center', justifyContent: "space-between" }}><span>Percentage</span><span>: &nbsp;</span></span>
								<Input value={percentage} onChange={(e) => set_percentage(e.target.value)} style={styles.inputtext_style} type='number' />
							</div>
							<div className='flex_property ml-4'>
								<span style={{ width: '96px', display: 'flex', alignItems: 'center', justifyContent: "space-between" }}><span>Addon</span><span>: &nbsp;</span></span>
								<Input value={addon} onChange={(e) => set_addon(e.target.value)} style={styles.inputtext_style} type='number' />
							</div>
						</div>
						: ""
				}


				{
					selected_sales_channel_id != 'custom' && allow_single_edit && (selected_price_type_details && !([null, 'constructed price'].includes(selected_price_type_details.price))) ?
						<div className='flex_property' style={{ fontWeight: 'bold' }}>
							<span style={{ width: '120px', display: 'flex', justifyContent: "space-between" }}><span>Total Price</span><span>: &nbsp;</span></span>
							<span style={{ marginLeft: '12px' }}>{total_price ? total_price : ''}</span>
						</div>
						: ''
				}{
					selected_sales_channel_id === 'custom' ?
						<React.Fragment>
							<div className='flex_property mb-3'>
								<span style={{ width: '120px', display: 'flex', justifyContent: "space-between" }}><span>Unit</span><span>: &nbsp;</span></span>
								<Input value={price_unit} onChange={e => set_price_unit(e.target.value)} style={styles.inputtext_style} type='select'>
									{
										selected_sales_channel_details && selected_sales_channel_details.display_unit_options && selected_sales_channel_details.display_unit_options.length ? selected_sales_channel_details.display_unit_options.map((unit, idx) =>
											<option key={idx} value={unit}>{unit}</option>
										) : []
									}
								</Input>
							</div>
							<div className='flex_property mb-3' style={{ fontWeight: 'bold' }}>
								<span style={{ width: '120px', display: 'flex', justifyContent: "space-between" }}><span>Unit Price</span><span>: &nbsp;</span></span>
								<Input value={unit_price} onChange={e => set_unit_price(e.target.value)} style={styles.inputtext_style} type='number' />
							</div>
						</React.Fragment>
						: ''
				}
			</IModalBody>
			<IModalFooter>
				<Button onClick={onclick_reset} style={{ marginRight: 'auto' }} className='red_button_default' type="button">Reset</Button>
				<Button onClick={onclick_handle_close} className='secondary_button_default' type="button">Cancel</Button>
				<Button onClick={onclick_update} className='primary_button_default' type="button">Update</Button>
			</IModalFooter>
		</Modal>
	)

}

const Unique_price_single_layer = ({is_price_and_unit_available, single_unit, idx, modal_json_copy, set_modal_json_copy}) => {

	const styles={
		td_style:{
			width:"100%",
			minHeight:'50px',
			heigth:'auto',
			overflowWrap: 'anywhere',
			display:'flex',
			alignItems:'center',
			padding:"0px 5px"
		},
		th_style:{
			width:"100%",
			padding:"0px 5px"		
		}
	}

	const [checkbox_value, set_checkbox_value] = useState(false)

	useEffect(() => {
		if(single_unit){
			set_checkbox_value(single_unit.checked)
		}
	},[single_unit])

	useEffect(() => {
		// console.log(checkbox_value)
		// single_unit.checked=checkbox_value;
		if(modal_json_copy && modal_json_copy.length){
			var x_copy = JSON.parse(JSON.stringify(modal_json_copy))
			x_copy.map((x,item) => {
				if(x.sku_id===single_unit.sku_id){
					x.checked=checkbox_value;
				}
			})
			set_modal_json_copy(x_copy)
		}
	},[checkbox_value])

	return(
		// <tr style={{width:"100%", display:'flex' , alignItems:'center', backgroundColor:(single_unit.deprecated ? 'rgb(240, 101, 111)' :((single_unit.changed_from_sku_meta_data && single_unit.changed_from_sku_meta_data == true) ? "#34ebdb" : ((single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && single_unit.sales_channels[single_unit.active_sales_channel_index].price.is_pricing_system_mismatch)?'rgb(240, 101, 111)':((idx%2==0)?'#f2f2f2':'')))), borderBottom:'1px solid #ddd'}}>
		// 	<td style={{...styles.td_style,flex:1, minHeight:"35px",paddingLeft:'10px'}}>
		// 		<div className='checkbox_background_hover_before'><Input className="checkbox_background_hover" style={{position:'unset', margin:0, cursor:'pointer'}} title='Select' type='checkbox' checked={checkbox_value}  onChange={() => set_checkbox_value(!checkbox_value)} /></div>
		// 	</td>
		// 	<td style={{...styles.td_style,flex:1, minHeight:"35px"}}>{idx+1}</td>
		// 	{/* <td style={{...styles.td_style,flex:2, minHeight:"35px"}}>{single_unit.sku_id?single_unit.sku_id:"-"}</td> */}
		// 	<td style={{...styles.td_style,flex:5, minHeight:"35px"}}>{single_unit.name?single_unit.name:"-"}</td>
		// 	<td style={{...styles.td_style,flex:3, minHeight:"35px"}}>{single_unit.type?single_unit.type:"-"}</td>
		// 	<td style={{...styles.td_style,flex:3, minHeight:"35px"}}>{single_unit.category?single_unit.category:"-"}</td>
		// 	<td style={{...styles.td_style,flex:3, minHeight:"35px"}}>{single_unit.sub_category?single_unit.sub_category:"-"}</td>
		// 	<td style={{...styles.td_style,flex:3, minHeight:"35px", color:single_unit.is_original_sales_channel?'':'#ff3333'}}>{single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] ?single_unit.sales_channels[single_unit.active_sales_channel_index].name:"-"}</td>
		// 	<td style={{...styles.td_style,flex:4, minHeight:"35px"}}>{single_unit.tags && single_unit.tags.length?single_unit.tags.join(", "):"-"}</td>
		// 	<td style={{...styles.td_style,flex:4, minHeight:"35px"}}>{single_unit.present_in && single_unit.present_in.length?single_unit.present_in.join(", "):"-"}</td>
		// 	{is_price_and_unit_available?(<td style={{...styles.td_style,flex:2, minHeight:"35px", color:single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && !(single_unit.sales_channels[single_unit.active_sales_channel_index].price.price_type || single_unit.sales_channels[single_unit.active_sales_channel_index].price.is_original_price_type==true)?'#ff3333':''}}>{single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && single_unit.sales_channels[single_unit.active_sales_channel_index].price.price_type?single_unit.sales_channels[single_unit.active_sales_channel_index].price.price_type:"-"}</td>):''}
		// 	{is_price_and_unit_available?(<td style={{...styles.td_style,flex:2, minHeight:"35px"}}>{single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && single_unit.sales_channels[single_unit.active_sales_channel_index].price.price?single_unit.sales_channels[single_unit.active_sales_channel_index].price.price:"-"}</td>):''}
		// 	{is_price_and_unit_available?(<td style={{...styles.td_style,flex:2, minHeight:"35px"}}>{single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && single_unit.sales_channels[single_unit.active_sales_channel_index].price.display_unit?single_unit.sales_channels[single_unit.active_sales_channel_index].price.display_unit:"-"}</td>):''}
		// </tr>
		<ITableRow style={{background:(single_unit.deprecated ? '#ffe6e6' :((single_unit.changed_from_sku_meta_data && single_unit.changed_from_sku_meta_data == true) ? "#34ebdb" : ((single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && single_unit.sales_channels[single_unit.active_sales_channel_index].price.is_pricing_system_mismatch)?'#ffe6e6':'')))}}>
			<ITD>
				<Checkbox title='Select' checked={checkbox_value}  onClick={() => set_checkbox_value(!checkbox_value)} />
				{/* <div className='checkbox_background_hover_before'><Input className="checkbox_background_hover" style={{position:'unset', margin:0, cursor:'pointer'}} title='Select' type='checkbox' checked={checkbox_value}  onChange={() => set_checkbox_value(!checkbox_value)} /></div> */}
			</ITD>
			<ITD >{idx+1}</ITD>
			{/* <ITD >{single_unit.sku_id?single_unit.sku_id:"-"}</ITD> */}
			<ITD >{single_unit.name?single_unit.name:"-"}</ITD>
			<ITD >{single_unit.sku_sr_no?single_unit.sku_sr_no:"-"}</ITD>
			<ITD >{single_unit.type?single_unit.type:"-"}</ITD>
			<ITD >{single_unit.category?single_unit.category:"-"}</ITD>
			<ITD >{single_unit.sub_category?single_unit.sub_category:"-"}</ITD>
			<ITD style={{color:single_unit.is_original_sales_channel?'':'#ff3333'}}>{single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] ?single_unit.sales_channels[single_unit.active_sales_channel_index].name:"-"}</ITD>
			<ITD >{single_unit.tags && single_unit.tags.length?single_unit.tags.join(", "):"-"}</ITD>
			<ITD >{single_unit.present_in && single_unit.present_in.length?single_unit.present_in.join(", "):"-"}</ITD>
			{is_price_and_unit_available?(<ITD style={{color:single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && !(single_unit.sales_channels[single_unit.active_sales_channel_index].price.price_type || single_unit.sales_channels[single_unit.active_sales_channel_index].price.is_original_price_type==true)?'#ff3333':''}}>{single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && single_unit.sales_channels[single_unit.active_sales_channel_index].price.price_type?single_unit.sales_channels[single_unit.active_sales_channel_index].price.price_type:"-"}</ITD>):''}
			{is_price_and_unit_available?(<ITD >{single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && single_unit.sales_channels[single_unit.active_sales_channel_index].price.price?single_unit.sales_channels[single_unit.active_sales_channel_index].price.price:"-"}</ITD>):''}
			{is_price_and_unit_available?(<ITD >{single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && single_unit.sales_channels[single_unit.active_sales_channel_index].price.display_unit?single_unit.sales_channels[single_unit.active_sales_channel_index].price.display_unit:"-"}</ITD>):''}
			{is_price_and_unit_available?(<ITD >{single_unit.price_source?single_unit.price_source:"-"}</ITD>):''}
		</ITableRow>
	)
}

const Sku_Difference_Modal = ({handle_close, single_json}) => {

	const styles={
		td_style:{
			width:"100%",
			minHeight:'50px',
			heigth:'auto',
			overflowWrap: 'anywhere',
			display:'flex',
			alignItems:'center',
			padding:"0px 5px"
		},
		th_style:{
			width:"100%",
			padding:"0px 5px"		
		}
	}

	return(
		// <Modal className="modal-dialog-centered" size="xl" isOpen={single_json && Object.keys(single_json).length>0}>
		// 	<Card className="bg-secondary shadow border-0">
		// 		<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
		// 			<div>
		// 				<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>{single_json.sku_name?single_json.sku_name:""}</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
		// 			</div>
		// 		</CardHeader>
		// 		<CardBody style = {{backgroundColor: COLORS.white, padding:'30px 20px', fontSize:'13px',minHeight:'300px', maxHeight:"600px", overflow:"auto" }}>					
		// 			<Row>
		// 				<Col>
		// 					<table style={{width:'100%'}}>
		// 						<thead>
		// 							<tr style={{height:'auto', width:'100%', display:'flex' , alignItems:'center', borderBottom:"2px solid #ddd",}}>
		// 								<th style={{...styles.th_style, padding:"0px 5px",flex:3}}>Field</th>
		// 									<th style={{...styles.th_style, padding:"0px 5px",flex:5}}>Old Value</th>
		// 									<th style={{...styles.th_style, padding:"0px 5px",flex:5}}>New Value</th>
		// 							</tr>
		// 						</thead>
		// 						<tbody style={{display:"table",width:"100%"}}>
		// 							{
		// 								single_json && single_json.sku_data_change && single_json.sku_data_change.map((single_unit,idx) => (
		// 									<tr style={{width:"100%", display:'flex' , alignItems:'center', backgroundColor:((idx%2==0)?'#f2f2f2':''), borderBottom:'1px solid #ddd'}}>
		// 										<td style={{...styles.td_style,flex:3, minHeight:"35px"}}>{single_unit.field?single_unit.field:""}</td>
		// 										<td style={{...styles.td_style,flex:5, minHeight:"35px"}}>{single_unit.old_value?single_unit.old_value:"-"}</td>
		// 										<td style={{...styles.td_style,flex:5, minHeight:"35px"}}>{single_unit.new_value?single_unit.new_value:"-"}</td>
		// 									</tr>
		// 								))
		// 							}
		// 						</tbody>
		// 					</table>
		// 				</Col>  
		// 			</Row>
		// 		</CardBody>
		// 	</Card>
		// </Modal>
		<Modal className="modal-dialog-centered" size="xl" isOpen={single_json && Object.keys(single_json).length > 0} toggle={handle_close}>
			<IModalHeader toggle={handle_close}>{single_json.sku_name ? single_json.sku_name : "-"}</IModalHeader>
			<IModalBody style={{height: '600px', overflow: 'auto'}}>
				<ITable coloumnSeparated={true} rowSeparated={true} striped={true} hover={true} style_container={{ border: '1px solid var(--light-border-color)', maxHeight: '568px', overflow: 'auto' }}>
					<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px' }}>
						<ITH>Field</ITH>
						<ITH>Old Value</ITH>
						<ITH>New Value</ITH>
					</ITableHeader>
					<ITableBody>
						{
							single_json && single_json.sku_data_change && single_json.sku_data_change.map((single_unit, idx) => (
								<ITableRow style={{height: '50px'}}>
									<ITD>{single_unit.field ? single_unit.field : ""}</ITD>
									<ITD>{single_unit.old_value ? single_unit.old_value : "-"}</ITD>
									<ITD>{single_unit.new_value ? single_unit.new_value : "-"}</ITD>
								</ITableRow>
							))
						}
					</ITableBody>
				</ITable>
			</IModalBody>
		</Modal>
	)
}


const Show_Single_Sku_Modal = ({handle_close, single_json}) => {
	const styles={
		td_style:{
			width:"100%",
			minHeight:'50px',
			heigth:'auto',
			overflowWrap: 'anywhere',
			display:'flex',
			alignItems:'center',
			padding:"0px 5px"
		},
		th_style:{
			width:"100%",
			padding:"0px 5px"		
		}
	}

	return(
		// <Modal className="modal-dialog-centered" size="xl" isOpen={single_json && Object.keys(single_json).length>0}>
		// 	<Card className="bg-secondary shadow border-0">
		// 		<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
		// 			<div>
		// 				<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>{single_json.sku_name?single_json.sku_name:""}</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
		// 			</div>
		// 		</CardHeader>
		// 		<CardBody style = {{backgroundColor: COLORS.white, padding:'30px 20px', fontSize:'13px',minHeight:'300px', maxHeight:"600px", overflow:"auto" }}>					
		// 			<Row>
		// 				<Col>
		// 					<table style={{width:'100%'}}>
		// 						<thead>
		// 							<tr style={{height:'auto', width:'100%', display:'flex' , alignItems:'center', borderBottom:"2px solid #ddd",}}>
		// 								<th style={{...styles.th_style, padding:"0px 5px",flex:5}}>Field</th>
		// 									<th style={{...styles.th_style, padding:"0px 5px",flex:8}}>Value</th>
		// 							</tr>
		// 						</thead>
		// 						<tbody style={{display:"table",width:"100%"}}>
		// 							{
		// 								single_json && single_json.sku_data && single_json.sku_data.map((single_unit,idx) => (
		// 									<tr style={{width:"100%", display:'flex' , alignItems:'center', backgroundColor:((idx%2==0)?'#f2f2f2':''), borderBottom:'1px solid #ddd'}}>
		// 										<td style={{...styles.td_style,flex:5, minHeight:"35px"}}>{single_unit.field?single_unit.field:""}</td>
		// 										<td style={{...styles.td_style,flex:8, minHeight:"35px"}}>{single_unit.value?single_unit.value:"-"}</td>
		// 									</tr>
		// 								))
		// 							}
		// 						</tbody>
		// 					</table>
		// 				</Col>  
		// 			</Row>
		// 		</CardBody>
		// 	</Card>
		// </Modal>
		<Modal className="modal-dialog-centered" size="xl" isOpen={single_json && Object.keys(single_json).length > 0} toggle={handle_close}>
			<IModalHeader toggle={handle_close}>{single_json.sku_name ? single_json.sku_name : "-"}</IModalHeader>
			<IModalBody>
				<ITable rowSeparated={true} striped={true} hover={true} style={{ tableLayout: 'fixed' }} style_container={{ border: '1px solid var(--light-border-color)', height: '500px', overflow: 'auto' }}>
					<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px' }}>
						<ITH>Field</ITH>
						<ITH>Value</ITH>
					</ITableHeader>
					<ITableBody>
						{
							single_json && single_json.sku_data && single_json.sku_data.map((single_unit, idx) => (
								<ITableRow key={idx}>
									<ITD>{single_unit.field ? single_unit.field : ""}</ITD>
									<ITD>{single_unit.value ? single_unit.value : "-"}</ITD>
								</ITableRow>
							))
						}
					</ITableBody>
				</ITable>
			</IModalBody>
		</Modal>
	)
}

const Show_Sku_Details_Cache_modal = ({modal_type, modal_json, handle_close, set_page_loader, update_modal_json}) => {

	const [single_json, set_single_json] = useState({});
	const [data, set_data] = useState([]);
	const [copy_modal_json, set_copy_modal_json] = useState([]);

	const onclick_handle_close = () => {
		set_data([])
		set_single_json({})
		set_copy_modal_json([])
		handle_close()
	}

	const styles={
		td_style:{
			width:"100%",
			minHeight:'50px',
			heigth:'auto',
			overflowWrap: 'anywhere',
			display:'flex',
			alignItems:'center',
			padding:"0px 5px"
		},
		th_style:{
			width:"100%",
			padding:"0px 5px"		
		}
	}

	useEffect(()=>{
		if(modal_type == "show_sku_details_cache_modal" && modal_json){
			let modal_json_copy = (JSON.parse(JSON.stringify(modal_json)));
			set_copy_modal_json(modal_json_copy)
			if(Array.isArray(modal_json_copy)){
				set_data(modal_json_copy.sort((a,b) => generic_sort(a,b,'sku_name',1)));
			}
		}
	},[modal_json, modal_type])

	const onclick_show_sku_differences = (sku_id) => {
		// set_show_sku_difference_modal(true);
		let sku_json = data.find((obj)=>obj.sku_id == sku_id)
		set_single_json(sku_json)

	}

	const on_handle_close_sku_difference_modal = () =>{
		// set_show_sku_difference_modal(false);
		set_single_json({})
	}

	const onclick_all_select_remove_checkbox = (value) => {
		let temp_json = [...data]
		temp_json.map(el => el.remove_checked = value)
		set_data(temp_json)
	}

	const onclick_all_select_refresh_checkbox = (value) => {
		let temp_json = [...data]
		temp_json.map(el => el.refresh_checked = value)
		set_data(temp_json)
	}

	const onclick_remove_checkbox = (sku_id) => {
		let temp_json = [...data]
		let index = temp_json.findIndex(el => el.sku_id == sku_id)
		if(index != -1){
			temp_json[index].remove_checked = !temp_json[index].remove_checked
			set_data(temp_json)
		}
	}

	const onclick_refresh_checkbox = (sku_id) => {
		let temp_json = [...data]
		let index = temp_json.findIndex(el => el.sku_id == sku_id)
		if(index != -1){
			temp_json[index].refresh_checked = !temp_json[index].refresh_checked
			set_data(temp_json)
		}
	}

	const on_click_refresh = () => {
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {
			let sku_array = data.filter((obj) => obj.refresh_checked == true)
			console.log("Smit refresh sku array", sku_array)
			let obj = {}
			obj["show_pricing_quotation"] = false
			obj["ids"] = sku_array  
			window.Module.refresh_specific_skus_cache(JSON.stringify(obj));
			handle_close();
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	const on_click_remove = () => {
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {
			let sku_array = data.filter((obj) => obj.remove_checked == true)
			console.log("Smit remove sku array", sku_array)  
			window.Module.remove_specific_skus_cache(JSON.stringify(sku_array));
			handle_close();
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	return(
		// <Modal className="modal-dialog-centered" size="xl" isOpen={modal_type=="show_sku_details_cache_modal"}>
		// 	<Card className="bg-secondary shadow border-0">
		// 		<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
		// 			<div>
		// 				<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Sku Details Cache</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
		// 			</div>
		// 		</CardHeader>
		// 		<CardBody style = {{backgroundColor: COLORS.white, padding:'30px 20px', fontSize:'13px',minHeight:'300px', maxHeight:"600px", overflow:"auto" }}>					
		// 			<Row>
		// 				<Col>
		// 					<table style={{width:'100%'}}>
		// 						<thead>
		// 						<tr style={{height:'auto', width:'100%', display:'flex' , alignItems:'center', borderBottom:"2px solid #ddd",}}>
					
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:1}}>Sl.No</th>
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:8}}>Sku Name</th>
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:3}}>Details</th>
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:1,paddingLeft:'10px'}}>
		// 								Remove
		// 								<div className='checkbox_background_hover_before'>
		// 								{
		// 									data && data.length && data.filter(x => x.remove_checked).length===data.length?
		// 									<i onClick={()=>onclick_all_select_remove_checkbox(false)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='fa fa-check-square-o checkbox_background_hover' />
		// 									:
		// 									data && data.length && data.filter(x => x.remove_checked).length===0?
		// 										<i onClick={() =>onclick_all_select_remove_checkbox(true)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='fa fa-square-o checkbox_background_hover' />
		// 										:
		// 										<i onClick={() =>onclick_all_select_remove_checkbox(true)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='fa fa-minus-square-o checkbox_background_hover' />
		// 								}
		// 								</div>
		// 							</th>
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:1,paddingLeft:'10px'}}>
		// 								Refresh
		// 								<div className='checkbox_background_hover_before'>
		// 								{
		// 									data && data.length && data.filter(x => x.refresh_checked).length===data.length?
		// 									<i onClick={()=>onclick_all_select_refresh_checkbox(false)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='fa fa-check-square-o checkbox_background_hover' />
		// 									:
		// 									data && data.length && data.filter(x => x.refresh_checked).length===0?
		// 										<i onClick={() =>onclick_all_select_refresh_checkbox(true)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='fa fa-square-o checkbox_background_hover' />
		// 										:
		// 										<i onClick={() =>onclick_all_select_refresh_checkbox(true)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='fa fa-minus-square-o checkbox_background_hover' />
		// 								}
		// 								</div>
		// 							</th>
		// 						</tr>
		// 						</thead>
		// 						<tbody style={{display:"table",width:"100%"}}>
		// 							{
		// 								data && data.map((single_unit,idx) => (
		// 									<tr key={idx} style={{width:"100%", display:'flex' , alignItems:'center', backgroundColor:(single_unit&&single_unit.sku_deprecated?'rgb(240, 101, 111)':((idx%2==0)?'#f2f2f2':'')), borderBottom:'1px solid #ddd'}}>
												
		// 										<td style={{...styles.td_style,flex:1, minHeight:"35px"}}>{idx+1}</td>
		// 										<td style={{...styles.td_style,flex:8, minHeight:"35px"}}>{single_unit&&single_unit.sku_name?single_unit.sku_name:"-"}</td>
		// 										<td style={{...styles.td_style,flex:3, minHeight:"35px"}}><Button onClick={() => {onclick_show_sku_differences(single_unit&&single_unit.sku_id?single_unit.sku_id:"")}} className='blue_button'>Details</Button></td>
		// 										<td style={{...styles.td_style,flex:1, minHeight:"35px",paddingLeft:'10px'}}>
		// 											<div className='checkbox_background_hover_before'><Input className="checkbox_background_hover" style={{position:'unset', margin:0, cursor:'pointer', accentColor:'red'}} title='Select' type='checkbox' checked={single_unit.remove_checked}  onChange={() => onclick_remove_checkbox(single_unit.sku_id)} /></div>
		// 										</td>
		// 										<td style={{...styles.td_style,flex:1, minHeight:"35px",paddingLeft:'10px'}}>
		// 											<div className='checkbox_background_hover_before'><Input className="checkbox_background_hover" style={{position:'unset', margin:0, cursor:'pointer', accentColor: 'lightgreen'}} title='Select' type='checkbox' checked={single_unit.refresh_checked}  onChange={() => onclick_refresh_checkbox(single_unit.sku_id)} /></div>
		// 										</td>
		// 									</tr>
		// 								))
		// 							}
		// 						</tbody>
		// 					</table>
		// 				</Col>  
		// 			</Row>
		// 		</CardBody>
		// 		<CardFooter style={{textAlign:'center', backgroundColor:'white', display:'flex', justifyContent: 'space-evenly'}}>
		// 			<Button onClick={on_click_remove} className='blue_button'>Remove</Button>
		// 			<Button onClick={on_click_refresh} className='blue_button'>Refresh</Button>
		// 		</CardFooter>
		// 	</Card>
		// 	<Show_Single_Sku_Modal handle_close={on_handle_close_sku_difference_modal} single_json = {single_json}/>
		// </Modal>
		<React.Fragment>
			<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type == "show_sku_details_cache_modal"}>
				<IModalHeader toggle={onclick_handle_close}>SKU Details Cache</IModalHeader>
				<IModalBody style={{ height: '600px', overflow: 'auto' }}>
					<ISearch list={copy_modal_json} set_list={set_data} search_parameters={['sku_name']} placeholder={'Search by SKU Name'} style_outer={{ margin: '0px 0px 16px 0px' }} />
					<ITable coloumnSeparated={true} rowSeparated={true} striped={true} hover={true} style_container={{ border: '1px solid var(--light-border-color)', maxHeight: '520px', overflow: 'auto' }}>
						<colgroup>
							<col style={{ width: "5%" }}></col>
							<col style={{ width: "60%" }}></col>
							<col style={{ width: "15%" }}></col>
							<col style={{ width: "10%" }}></col>
							<col style={{ width: "10%" }}></col>
						</colgroup>
						<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px' }}>
							<ITH>Sl.No</ITH>
							<ITH>Sku Name</ITH>
							<ITH>Details</ITH>
							<ITH>
								<div className='flex'>
									Remove
									<div className='checkbox_background_hover_before'>
										{
											data && data.length && data.filter(x => x.remove_checked).length === data.length ?
												<i onClick={() => onclick_all_select_remove_checkbox(false)} style={{ fontSize: '16px', cursor: "pointer" }} title='Select' className='fa fa-check-square-o checkbox_background_hover' />
												:
												data && data.length && data.filter(x => x.remove_checked).length === 0 ?
													<i onClick={() => onclick_all_select_remove_checkbox(true)} style={{ fontSize: '16px', cursor: "pointer" }} title='Select' className='fa fa-square-o checkbox_background_hover' />
													:
													<i onClick={() => onclick_all_select_remove_checkbox(true)} style={{ fontSize: '16px', cursor: "pointer" }} title='Select' className='fa fa-minus-square-o checkbox_background_hover' />

										}
									</div>
								</div>
							</ITH>
							<ITH>
								<div className='flex'>
									Refresh
									<div className='checkbox_background_hover_before'>
										{
											data && data.length && data.filter(x => x.refresh_checked).length === data.length ?
												<i onClick={() => onclick_all_select_refresh_checkbox(false)} style={{ fontSize: '16px', cursor: "pointer" }} title='Select' className='fa fa-check-square-o checkbox_background_hover' />
												:
												data && data.length && data.filter(x => x.refresh_checked).length === 0 ?
													<i onClick={() => onclick_all_select_refresh_checkbox(true)} style={{ fontSize: '16px', cursor: "pointer" }} title='Select' className='fa fa-square-o checkbox_background_hover' />
													:
													<i onClick={() => onclick_all_select_refresh_checkbox(true)} style={{ fontSize: '16px', cursor: "pointer" }} title='Select' className='fa fa-minus-square-o checkbox_background_hover' />
										}
									</div>
								</div>
							</ITH>
						</ITableHeader>
						<ITableBody>
							{
								data && data.map((single_unit, idx) => (
									<ITableRow error_in_row={single_unit && single_unit.sku_deprecated}>
										<ITD >{idx + 1}</ITD>
										<ITD >{single_unit && single_unit.sku_name ? single_unit.sku_name : "-"}</ITD>
										<ITD ><Button onClick={() => { onclick_show_sku_differences(single_unit && single_unit.sku_id ? single_unit.sku_id : "") }} className='primary_button_default'>Details</Button></ITD>
										<ITD>
											<div className='checkbox_background_hover_before'><Input className="checkbox_background_hover" style={{ position: 'unset', margin: 0, cursor: 'pointer', accentColor: 'rgba(255, 82, 71, 1)' }} title='Select' type='checkbox' checked={single_unit.remove_checked} onChange={() => onclick_remove_checkbox(single_unit.sku_id)} /></div>
										</ITD>
										<ITD>
											<div className='checkbox_background_hover_before'><Input className="checkbox_background_hover" style={{ position: 'unset', margin: 0, cursor: 'pointer', accentColor: 'lightgreen' }} title='Select' type='checkbox' checked={single_unit.refresh_checked} onChange={() => onclick_refresh_checkbox(single_unit.sku_id)} /></div>
										</ITD>
									</ITableRow>
								))
							}
						</ITableBody>
					</ITable>
				</IModalBody>
				<IModalFooter>
					<Button onClick={on_click_remove} className='secondary_button_default'>Remove</Button>
					<Button onClick={on_click_refresh} className='primary_button_default'>Refresh</Button>
				</IModalFooter>
			</Modal>
			<Show_Single_Sku_Modal handle_close={on_handle_close_sku_difference_modal} single_json={single_json} />
		</React.Fragment>
	)

}

const Admin_Cache_Differences_modal = ({modal_type, modal_json, handle_close, set_page_loader, update_modal_json, handle_ui_response, update_view}) => {

	// const [ show_sku_difference_modal, set_show_sku_difference_modal] = useState(false);
	const [single_json, set_single_json] = useState({});
	const [sku_data, set_sku_data] = useState([]);
	const [rule_data, set_rule_data] = useState([]);
	const [tabs , set_tabs] = useState(1);

	const [sku_data_display, set_sku_data_display] = useState([]);
	const [rule_data_display, set_rule_data_display] = useState([]);

	const onclick_handle_close = () => {
		set_single_json({})
		set_sku_data([])
		set_rule_data([])
		set_rule_data_display([])
		set_sku_data_display([])
		handle_close()
	}

	const styles={
		td_style:{
			width:"100%",
			minHeight:'50px',
			heigth:'auto',
			overflowWrap: 'anywhere',
			display:'flex',
			alignItems:'center',
			padding:"0px 5px"
		},
		th_style:{
			width:"100%",
			padding:"0px 5px"		
		}
	}

	useEffect(()=>{
		if(modal_type == "admin_cache_differences_modal" && modal_json && modal_json.sku_cache && modal_json.rules_cache){
			let sorted_sku_data = JSON.parse(JSON.stringify(modal_json.sku_cache))
			sorted_sku_data.sort((a,b) => generic_sort(a, b, 'sku_name', 1))
			set_sku_data(sorted_sku_data);
			set_sku_data_display(sorted_sku_data);
			let sorted_rule_data = JSON.parse(JSON.stringify(modal_json.rules_cache))
			sorted_rule_data.sort((a,b) => generic_sort(a, b, 'rule_name', 1))
			set_rule_data(sorted_rule_data)
			set_rule_data_display(sorted_rule_data)
		}
	},[modal_json, modal_type])

	const onclick_show_sku_differences = (sku_id) => {
		// set_show_sku_difference_modal(true);
		let sku_json = sku_data.find((obj)=>obj.sku_id == sku_id)
		set_single_json(sku_json)

	}

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	}; 

	const on_handle_close_sku_difference_modal = () =>{
		// set_show_sku_difference_modal(false);
		set_single_json({})
	}

	const onclick_refresh = () => {
		set_page_loader({
			show: true,
			text: 'Please wait'
		}); 
		// handle_close();
		setTimeout(async() => {
			var ui_response = window.Module.refresh_admin_cache_modal("");
			// handle_ui_response(ui_response);
			update_modal_json();
			update_view()
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	return(
		// <Modal className="modal-dialog-centered" size="xl" isOpen={modal_type=="admin_cache_differences_modal"}>
		// 	<div style={{height:'50px', padding:'0px 20px', borderBottom:'1px solid #d9d9d9', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
		// 		<div>Admin Cache Differences</div>
		// 		<div onClick={handle_close}><i style={{fontSize:'16px',cursor:'pointer'}} className='fa fa-times'/></div>	
		// 	</div>
		// 	<div className="nav-wrapper">
		// 		<Nav 
		// 			style={{display:'flex', justifyContent:'space-evenly', padding:"10px 0px"}}
		// 			className="flex-md-row"
		// 			id="tabs-icons-text" 
		// 			pills
		// 			role="tablist"
		// 		>
		// 			<NavItem>
		// 				<NavLink
		// 					aria-selected={tabs === 1}
		// 					className={classnames("mb-sm-3 mb-md-0 tab_pills", {
		// 						active: tabs === 1
		// 					})}
		// 					onClick={e => toggleNavs(e, 1)}
		// 					role="tab"
		// 					style={{cursor:'pointer', fontSize:'14px'}}
		// 				>
		// 					Sku Cache
		// 				</NavLink>
		// 			</NavItem>
		// 			<NavItem>
		// 				<NavLink
		// 					aria-selected={tabs === 2}
		// 					className={classnames("mb-sm-3 mb-md-0 tab_pills", {
		// 							active: tabs === 2
		// 					})}
		// 					onClick={e => toggleNavs(e, 2)}
		// 					role="tab"
		// 					style={{cursor:'pointer', fontSize:'14px'}}
		// 				>
		// 					Rules Cache
		// 				</NavLink>
		// 			</NavItem>
		// 		</Nav>
		// 	</div>

		// 	<TabContent activeTab={"tabs" + tabs} style={{height:'calc(100% - 160px)', overflowY:'auto', padding: '20px'}}>
		// 		<TabPane tabId="tabs1">
		// 			<div style={{display:'flex', alignItems:'center', justifyContent:'space-evenly'}}>
		// 			{/* <Row>
		// 				<Col> */}
		// 				{sku_data && sku_data.length ?(
		// 					<table style={{width:'100%'}}>
		// 						<thead>
		// 						<tr style={{height:'auto', width:'100%', display:'flex' , alignItems:'center', borderBottom:"2px solid #ddd",}}>
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:1}}>Sl.No</th>
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:8}}>Sku Name</th>
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:3}}>Details</th>
		// 						</tr>
		// 						</thead>
		// 						<tbody style={{display:"table",width:"100%"}}>
		// 							{
		// 								sku_data.map((single_unit,idx) => (
		// 									<tr key={idx} style={{width:"100%", display:'flex' , alignItems:'center', backgroundColor:(single_unit.sku_deprecated?'rgb(240, 101, 111)':((idx%2==0)?'#f2f2f2':'')), borderBottom:'1px solid #ddd'}}>
		// 										<td style={{...styles.td_style,flex:1, minHeight:"35px"}}>{idx+1}</td>
		// 										<td style={{...styles.td_style,flex:8, minHeight:"35px"}}>{single_unit&&single_unit.sku_name?single_unit.sku_name:"-"}</td>
		// 										<td style={{...styles.td_style,flex:3, minHeight:"35px"}}><Button onClick={() => {onclick_show_sku_differences(single_unit&&single_unit.sku_id?single_unit.sku_id:"")}} className='blue_button'>Details</Button></td>
		// 									</tr>
		// 								))
		// 							}
		// 						</tbody>
		// 					</table>) : 'No Differences'
		// 					}
		// 				{/* </Col>  
		// 			</Row> */}
		// 			</div>
		// 		</TabPane>
		// 		<TabPane tabId="tabs2">
		// 			<div style={{display:'flex', alignItems:'center', justifyContent:'space-evenly'}}>
		// 			{/* <Row>
		// 				<Col> */}
		// 				{
		// 				rule_data && rule_data.length ?(
		// 					<table style={{width:'100%'}}>
		// 						<thead>
		// 						<tr style={{height:'auto', width:'100%', display:'flex' , alignItems:'center', borderBottom:"2px solid #ddd",}}>
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:1}}>Sl. No</th>
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:6}}>Rule Name</th>
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:5}}>Rule Type</th>
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:3}}>Status</th>
		// 						</tr>
		// 						</thead>
		// 						<tbody style={{display:"table",width:"100%"}}>
		// 							{
		// 								rule_data && rule_data.map((single_unit,idx) => (
		// 									<tr key={idx} style={{width:"100%", display:'flex' , alignItems:'center', backgroundColor:((idx%2==0)?'#f2f2f2':''), borderBottom:'1px solid #ddd'}}>
		// 										<td style={{...styles.td_style,flex:1, minHeight:"35px"}}>{idx+1}</td>
		// 										<td style={{...styles.td_style,flex:6, minHeight:"35px"}}>{single_unit&&single_unit.rule_name?single_unit.rule_name:"-"}</td>
		// 										<td style={{...styles.td_style,flex:5, minHeight:"35px"}}>{single_unit&&single_unit.rule_type?single_unit.rule_type:"-"}</td>
		// 										<td style={{...styles.td_style,flex:3, minHeight:"35px"}}>{single_unit&&single_unit.rule_status?single_unit.rule_status:"-"}</td>
		// 									</tr>
		// 								))
		// 							}
		// 						</tbody>
		// 					</table>) : 'No Differences'
		// 					}
		// 				{/* </Col>  
		// 			</Row> */}
		// 			</div>
		// 		</TabPane>
		// 	</TabContent>
		// 	<div style={{height:'50px', padding:'0px 20px', borderTop:'1px solid #d9d9d9', display:'flex', alignItems:'center', justifyContent:'space-evenly'}}>
		// 		<Button onClick={onclick_refresh} className='blue_button'>Continue</Button>
		// 	</div>
		// 	<Sku_Difference_Modal handle_close={on_handle_close_sku_difference_modal} single_json = {single_json}/>
		// </Modal>

		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type == "admin_cache_differences_modal"}>
			<IModalHeader toggle={onclick_handle_close}>Admin Cache Differences</IModalHeader>
			<IModalBody style={{height: '600px', overflow: 'auto'}}>
				<div className='flex_property mb-3 pr-2'>
					<UncontrolledINavbar tabs={['SKU Cache', 'Rules Cache']} set_active_tab={set_tabs} style_container={{ width: 'calc(100% - 200px)', margin: '0px' }} />
					<ISearch list={tabs == 0 ? sku_data : rule_data} set_list={tabs == 0 ? set_sku_data_display : set_rule_data_display} search_parameters={tabs == 0 ? ['sku_name'] : ['rule_name']} style_outer={{ width: '200px', margin: '0px' }} />
				</div>

				<TabContent activeTab={"tabs" + tabs} className='px-2'>
					<TabPane tabId="tabs0">
						{
							sku_data_display && sku_data_display.length ? (
								<ITable rowSeparated={true} hover={true} striped={true} style_container={{ maxHeight: '520px', overflow: 'auto', border: '1px solid var(--light-border-color)' }}>
									<colgroup>
										<col style={{ width: "5%" }}></col>
										<col style={{ width: "80%" }}></col>
										<col style={{ width: "15%" }}></col>
									</colgroup>
									<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px' }}>
										<ITH>Sl.No</ITH>
										<ITH>Sku Name</ITH>
										<ITH>Details</ITH>
									</ITableHeader>
									<ITableBody>
										{
											sku_data_display.map((single_unit, idx) => (
												<ITableRow key={idx}>
													<ITD>{idx + 1}</ITD>
													<ITD>{single_unit && single_unit.sku_name ? single_unit.sku_name : "-"}</ITD>
													<ITD><Button onClick={() => { onclick_show_sku_differences(single_unit && single_unit.sku_id ? single_unit.sku_id : "") }} className='primary_button_default'>Details</Button></ITD>
												</ITableRow>
											))
										}
									</ITableBody>
								</ITable>) : 'No Differences'
						}
					</TabPane>
					<TabPane tabId="tabs1">
						{
							rule_data_display && rule_data_display.length ? (
								<ITable rowSeparated={true} hover={true} striped={true} style_container={{ maxHeight: '520px', overflow: 'auto', border: '1px solid var(--light-border-color)' }}>
									<colgroup>
										<col style={{ width: "5%" }}></col>
										<col style={{ width: "65%" }}></col>
										<col style={{ width: "15%" }}></col>
										<col style={{ width: "15%" }}></col>
									</colgroup>
									<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px' }}>
										<ITH>Sl. No</ITH>
										<ITH>Rule Name</ITH>
										<ITH>Rule Type</ITH>
										<ITH>Status</ITH>
									</ITableHeader>
									<ITableBody>
										{
											rule_data_display && rule_data_display.map((single_unit, idx) => (
												<ITableRow key={idx} style={{height: '50px'}}>
													<ITD>{idx + 1}</ITD>
													<ITD>{single_unit && single_unit.rule_name ? single_unit.rule_name : "-"}</ITD>
													<ITD style={{textTransform: 'capitalize'}}>{single_unit && single_unit.rule_type ? single_unit.rule_type.replace('_', ' ') : "-"}</ITD>
													<ITD>{single_unit && single_unit.rule_status ? single_unit.rule_status : "-"}</ITD>
												</ITableRow>
											))
										}
									</ITableBody>
								</ITable>) : 'No Differences'
						}
					</TabPane>
				</TabContent>
			</IModalBody>
			<IModalFooter>
				<Button onClick={onclick_refresh} className='primary_button_default'>Continue</Button>
			</IModalFooter>
			<Sku_Difference_Modal handle_close={on_handle_close_sku_difference_modal} single_json={single_json} />
		</Modal>
	)
}

const FilterModal = ({open, handle_close, all_array, selected_types, set_selected_types, selected_sales_channels, set_selected_sales_channels, selected_tags, set_selected_tags, selected_price_fields, set_selected_price_fields, selected_categories, set_selected_categories, selected_sub_categories, set_selected_sub_categories, apply_filters}) => {
	const all_types = all_array['types']
	const all_sales_channels = all_array['sales_channels']
	const all_tags = all_array['tags']
	const all_price_fields = all_array['price_fields']
	const all_categories = all_array['categories']
	const all_sub_categories = all_array['sub_categories']

	const reset_filters = () => {
		set_selected_types([])
		set_selected_sales_channels([])
		set_selected_tags([])
		set_selected_price_fields([])
		set_selected_categories([])
		set_selected_sub_categories([])
	}

	return(
		// <Modal className="modal-dialog-centered" isOpen = {open} toggle = {handle_close} style={{maxHeight:'300px'}}>
		// 	<CardHeader style={{margin:0, padding:0}}>
		// 		<Col className='flex_property' style={{justifyContent:'space-between', padding:"0px 20px", height:'50px', color:'#22272e' }}>
		// 			<span style={{fontFamily:'Source_Sans_Pro-SemiBold'}}>Filters</span>
		// 			<span><i style={{cursor:'pointer'}} onClick={handle_close} className='fa fa-times'/></span>
		// 		</Col>
		// 	</CardHeader>
		// 	<CardBody style={{maxHeight:'265px', padding:0, overflow:'auto'}}>
		// 		<div style={{borderTop:'1px solid #f2f2f2'}}></div>
		// 		<Row style={{margin:0}}>
		// 			<Col style={{padding:"10px 20px", width:'50px', display:'flex', flexDirection:'column'}}>
		// 				<label style={{fontSize:'12px', marginBottom:"2px", color:"#627386"}}>Type</label>
		// 				<Multiselect1
		// 					style={{backgroundColor:'white'}}
		// 					options={all_types && all_types.length ? JSON.parse(JSON.stringify(all_types)):[]} 
		// 					selectedValues={selected_types}
		// 					onSelect={(selectedList) => set_selected_types(selectedList)}
		// 					onRemove={(selectedList) => set_selected_types(selectedList)}
		// 					displayValue="id" // Property name
		// 					id='select_type'
		// 					placeholder= 'Select Type'
		// 				/>
		// 			</Col>
		// 		</Row>
		// 		<Row style={{margin:0}}>
		// 			<Col style={{padding:"10px 20px", width:'50px', display:'flex', flexDirection:'column'}}>
		// 				<label style={{fontSize:'12px', marginBottom:"2px", color:"#627386"}}>Sales Channels</label>
		// 				<Multiselect1
		// 					style={{backgroundColor:'white'}}
		// 					options={all_sales_channels && all_sales_channels.length ? JSON.parse(JSON.stringify(all_sales_channels)):[]} 
		// 					selectedValues={selected_sales_channels}
		// 					onSelect={(selectedList) => set_selected_sales_channels(selectedList)}
		// 					onRemove={(selectedList) => set_selected_sales_channels(selectedList)}
		// 					displayValue="id" // Property name
		// 					id='select_sales_channels'
		// 					placeholder= 'Select Sales Channels'
		// 				/>
		// 			</Col>
		// 		</Row>
		// 		<Row style={{margin:0}}>
		// 			<Col style={{padding:"10px 20px", width:'50px', display:'flex', flexDirection:'column'}}>
		// 				<label style={{fontSize:'12px', marginBottom:"2px", color:"#627386"}}>Tags</label>
		// 				<Multiselect1
		// 					style={{backgroundColor:'white'}}
		// 					options={all_tags && all_tags.length ? JSON.parse(JSON.stringify(all_tags)):[]} 
		// 					selectedValues={selected_tags}
		// 					onSelect={(selectedList) => set_selected_tags(selectedList)}
		// 					onRemove={(selectedList) => set_selected_tags(selectedList)}
		// 					displayValue="id" // Property name
		// 					id='select_tags'
		// 					placeholder= 'Select Tags'
		// 				/>
		// 			</Col>
		// 		</Row>
		// 		<Row style={{margin:0}}>
		// 			<Col style={{padding:"10px 20px", width:'50px', display:'flex', flexDirection:'column'}}>
		// 				<label style={{fontSize:'12px', marginBottom:"2px", color:"#627386"}}>Price Fields</label>
		// 				<Multiselect1
		// 					style={{backgroundColor:'white'}}
		// 					options={all_price_fields && all_price_fields.length ? JSON.parse(JSON.stringify(all_price_fields)):[]} 
		// 					selectedValues={selected_price_fields}
		// 					onSelect={(selectedList) => set_selected_price_fields(selectedList)}
		// 					onRemove={(selectedList) => set_selected_price_fields(selectedList)}
		// 					displayValue="id" // Property name
		// 					id='select_price_fields'
		// 					placeholder= 'Select Price Fields'
		// 				/>
		// 			</Col>
		// 		</Row>
		// 		<Row style={{margin:0}}>
		// 			<Col style={{padding:"10px 20px", width:'50px', display:'flex', flexDirection:'column'}}>
		// 				<label style={{fontSize:'12px', marginBottom:"2px", color:"#627386"}}>Categories</label>
		// 				<Multiselect1
		// 					style={{backgroundColor:'white'}}
		// 					options={all_categories && all_categories.length ? JSON.parse(JSON.stringify(all_categories)):[]} 
		// 					selectedValues={selected_categories}
		// 					onSelect={(selectedList) => set_selected_categories(selectedList)}
		// 					onRemove={(selectedList) => set_selected_categories(selectedList)}
		// 					displayValue="id" // Property name
		// 					id='select_categories'
		// 					placeholder= 'Select Categories'
		// 				/>
		// 			</Col>
		// 		</Row>
		// 		<Row style={{margin:0, marginBottom:'20px'}}>
		// 			<Col style={{padding:"10px 20px", width:'50px', display:'flex', flexDirection:'column'}}>
		// 				<label style={{fontSize:'12px', marginBottom:"2px", color:"#627386"}}>Sub-Categories</label>
		// 				<Multiselect1
		// 					style={{backgroundColor:'white'}}
		// 					options={all_sub_categories && all_sub_categories.length ? JSON.parse(JSON.stringify(all_sub_categories)):[]} 
		// 					selectedValues={selected_sub_categories}
		// 					onSelect={(selectedList) => set_selected_sub_categories(selectedList)}
		// 					onRemove={(selectedList) => set_selected_sub_categories(selectedList)}
		// 					displayValue="id" // Property name
		// 					id='select_sub_categories'
		// 					placeholder= 'Select Sub-Categories'
		// 				/>
		// 			</Col>
		// 		</Row>
		// 	</CardBody>
		// 	<CardFooter className='global_modal_footer'>
		// 		<Button className='blue_button' style={{marginRight:'10px'}} onClick={reset_filters}>Reset</Button>
		// 		<Button className='blue_button' onClick={() => {apply_filters(); handle_close()}}>Apply</Button>
		// 	</CardFooter>
		// </Modal>
		<Modal className="modal-dialog-centered" isOpen={open} toggle={handle_close} style={{ maxHeight: '300px' }}>
			<IModalHeader toggle={handle_close}>Filters</IModalHeader>
			<IModalBody style={{ maxHeight: '300px', overflow: 'auto' }}>
				<div className='mb-3'>

					<label style={{ fontSize: '12px', marginBottom: "2px", color: "#627386" }}>Type</label>
					<Multiselect1
						style={{ backgroundColor: 'white' }}
						options={all_types && all_types.length ? JSON.parse(JSON.stringify(all_types)) : []}
						selectedValues={selected_types}
						onSelect={(selectedList) => set_selected_types(selectedList)}
						onRemove={(selectedList) => set_selected_types(selectedList)}
						displayValue="id" // Property name
						id='select_type'
						placeholder='Select Type'
					/>
				</div>
				<div className='mb-3'>

					<label style={{ fontSize: '12px', marginBottom: "2px", color: "#627386" }}>Sales Channels</label>
					<Multiselect1
						style={{ backgroundColor: 'white' }}
						options={all_sales_channels && all_sales_channels.length ? JSON.parse(JSON.stringify(all_sales_channels)) : []}
						selectedValues={selected_sales_channels}
						onSelect={(selectedList) => set_selected_sales_channels(selectedList)}
						onRemove={(selectedList) => set_selected_sales_channels(selectedList)}
						displayValue="id" // Property name
						id='select_sales_channels'
						placeholder='Select Sales Channels'
					/>
				</div>
				<div className='mb-3'>

					<label style={{ fontSize: '12px', marginBottom: "2px", color: "#627386" }}>Tags</label>
					<Multiselect1
						style={{ backgroundColor: 'white' }}
						options={all_tags && all_tags.length ? JSON.parse(JSON.stringify(all_tags)) : []}
						selectedValues={selected_tags}
						onSelect={(selectedList) => set_selected_tags(selectedList)}
						onRemove={(selectedList) => set_selected_tags(selectedList)}
						displayValue="id" // Property name
						id='select_tags'
						placeholder='Select Tags'
					/>
				</div>
				<div className='mb-3'>

					<label style={{ fontSize: '12px', marginBottom: "2px", color: "#627386" }}>Price Fields</label>
					<Multiselect1
						style={{ backgroundColor: 'white' }}
						options={all_price_fields && all_price_fields.length ? JSON.parse(JSON.stringify(all_price_fields)) : []}
						selectedValues={selected_price_fields}
						onSelect={(selectedList) => set_selected_price_fields(selectedList)}
						onRemove={(selectedList) => set_selected_price_fields(selectedList)}
						displayValue="id" // Property name
						id='select_price_fields'
						placeholder='Select Price Fields'
					/>
				</div>
				<div className='mb-3'>

					<label style={{ fontSize: '12px', marginBottom: "2px", color: "#627386" }}>Categories</label>
					<Multiselect1
						style={{ backgroundColor: 'white' }}
						options={all_categories && all_categories.length ? JSON.parse(JSON.stringify(all_categories)) : []}
						selectedValues={selected_categories}
						onSelect={(selectedList) => set_selected_categories(selectedList)}
						onRemove={(selectedList) => set_selected_categories(selectedList)}
						displayValue="id" // Property name
						id='select_categories'
						placeholder='Select Categories'
					/>
				</div>
				<div className='mb-3'>

					<label style={{ fontSize: '12px', marginBottom: "2px", color: "#627386" }}>Sub-Categories</label>
					<Multiselect1
						style={{ backgroundColor: 'white' }}
						options={all_sub_categories && all_sub_categories.length ? JSON.parse(JSON.stringify(all_sub_categories)) : []}
						selectedValues={selected_sub_categories}
						onSelect={(selectedList) => set_selected_sub_categories(selectedList)}
						onRemove={(selectedList) => set_selected_sub_categories(selectedList)}
						displayValue="id" // Property name
						id='select_sub_categories'
						placeholder='Select Sub-Categories'
					/>
				</div>
			</IModalBody>
			<IModalFooter>
				<Button className='secondary_button_default' onClick={reset_filters}>Reset</Button>
				<Button className='primary_button_default' onClick={() => { apply_filters(); handle_close() }}>Apply</Button>
			</IModalFooter>
		</Modal>
	)
}

const Unique_Price_details_modal = ({ modal_type, handle_close,  modal_json, update_modal_json, set_page_loader, handle_ui_response }) => {

	const [ modal_json_copy, set_modal_json_copy ] = useState([]);
	const [compare_sku_price_modal_json, set_compare_sku_price_modal_json] = useState([]);
	const [ show_edit_sku_price_modal, set_show_edit_sku_price_modal ] = useState(false);
	const [ show_compare_sku_price_modal, set_show_compare_sku_price_modal ] = useState(false);
	const [ selected_sku, set_selected_sku ] = useState({});
	const [ allow_single_edit, set_allow_single_edit ] = useState(false);
	const [ all_select_flag, set_all_select_flag ] = useState(false);
	const [ is_price_and_unit_available, set_is_price_and_unit_available ] = useState(true);
	const [search_string, set_search_string] = useState();
	const [selected_types, set_selected_types] = useState();
	const [selected_sales_channels, set_selected_sales_channels] = useState();
	const [selected_tags, set_selected_tags] = useState();
	const [selected_price_fields, set_selected_price_fields] = useState();
	const [selected_categories, set_selected_categories] = useState();
	const [selected_sub_categories, set_selected_sub_categories] = useState();
	const [show_filter_modal, set_show_filter_modal] = useState(false);
	const [filters_toggle, set_filters_toggle] = useState();
	const [sorted_by_name, set_sorted_by_name] = useState();
	const [sorted_by_type, set_sorted_by_type] = useState();
	const [sorted_by_category, set_sorted_by_category] = useState();
	const [sorted_by_sub_category, set_sorted_by_sub_category] = useState();
	const [sorted_by_sales_channel, set_sorted_by_sales_channel] = useState();
	const [sorted_by_price_field, set_sorted_by_price_field] = useState();
	const [sorted_by_sku_sr_no, set_sorted_by_sku_sr_no] = useState();
	const alert = useAlert();
    const {t} = useTranslation()

	const get_all = (modal_json) => {
		let temp_all_types = new Set()
		let temp_all_sales_channels = new Set()
		let temp_all_tags = new Set()
		let temp_all_price_fields = new Set()
		let temp_all_categories = new Set()
		let temp_all_sub_categories = new Set()
		if(modal_json && modal_json.units && modal_json.units.length){
			for(let i = 0; i < modal_json.units.length; i++){
				if(modal_json.units[i]['type']){
					temp_all_types.add(modal_json.units[i]['type'])
				}
				if(modal_json.units[i]['active_sales_channel_name']){
					temp_all_sales_channels.add(modal_json.units[i]['active_sales_channel_name'])
				}
				if(modal_json.units[i]['tags'] && modal_json.units[i]['tags'].length){
					modal_json.units[i]['tags'].map(tag => {
						temp_all_tags.add(tag)
					})
				}
				if(modal_json.units[i]['sales_channels'] && modal_json.units[i]['sales_channels'].length){
					let index = modal_json.units[i]['active_sales_channel_index']
					let active_sales_channel = modal_json.units[i]['sales_channels'][index]
					if(active_sales_channel && active_sales_channel.price) temp_all_price_fields.add(active_sales_channel.price.price_type)
				}
				if(modal_json.units[i]['category']){
					temp_all_categories.add(modal_json.units[i]['category'])
				}
				if(modal_json.units[i]['sub_category']){
					temp_all_sub_categories.add(modal_json.units[i]['sub_category'])
				}
			}
		}
		temp_all_types = [...temp_all_types] 
		temp_all_types = temp_all_types && temp_all_types.length ? temp_all_types.map(x => {return {id: x}}) : []

		temp_all_sales_channels = [...temp_all_sales_channels] 
		temp_all_sales_channels = temp_all_sales_channels && temp_all_sales_channels.length ? temp_all_sales_channels.map(x => {return {id: x}}) : []

		temp_all_tags = [...temp_all_tags] 
		temp_all_tags = temp_all_tags && temp_all_tags.length ? temp_all_tags.map(x => {return {id: x}}) : []

		temp_all_price_fields = [...temp_all_price_fields] 
		temp_all_price_fields = temp_all_price_fields && temp_all_price_fields.length ? temp_all_price_fields.map(x => {return {id: x}}) : []

		temp_all_categories = [...temp_all_categories] 
		temp_all_categories = temp_all_categories && temp_all_categories.length ? temp_all_categories.map(x => {return {id: x}}) : []

		temp_all_sub_categories = [...temp_all_sub_categories] 
		temp_all_sub_categories = temp_all_sub_categories && temp_all_sub_categories.length ? temp_all_sub_categories.map(x => {return {id: x}}) : []

		return {types: temp_all_types, sales_channels: temp_all_sales_channels, tags: temp_all_tags, price_fields: temp_all_price_fields, categories: temp_all_categories, sub_categories: temp_all_sub_categories}
	}

	const all_array = useMemo(() => get_all(modal_json), [modal_json])

	const onclick_price_compare = async ()=> {
		let response = await window.Promisify(window.Module.compare_sku_price_data(""));
		if(response){
			let response_json = JSON.parse(response);
			if(response_json && response_json.toast){
				handle_ui_response(response);
			}else if(response_json && response_json.data){
				set_show_compare_sku_price_modal(true);
				set_compare_sku_price_modal_json(response_json);
			}
		}
	}

	const onclick_download_csv = async() => {
		let response = await window.Promisify(window.Module.toolbar_router("print_price_settings_quotation"));
		if(response){
			let response_json = JSON.parse(response);
			if(response_json){
				handle_ui_response(response);
			}
		}
	}
	
	const onclick_edit = () => {
		var checked_skus = modal_json_copy.filter(x=>x.checked)
		var x_allow = checked_skus && checked_skus.length===1?true:false
		set_allow_single_edit(x_allow);
		if(checked_skus && checked_skus.length){
			if(x_allow){
				set_selected_sku(checked_skus[0]);
				set_show_edit_sku_price_modal(true)
			}else{
				if(checked_skus && checked_skus.length>1){
					var result_sc = checked_skus[0].sales_channels && checked_skus[0].sales_channels.length ? checked_skus[0].sales_channels.map(x=>x):[]
					if(result_sc && !result_sc.length){
						alert.info('No Sales Channel in common')
					}else{
						var x_result = checked_skus.map((single_unit)=>{
							var single_sc_ids = single_unit.sales_channels && single_unit.sales_channels.length ?single_unit.sales_channels.map(x=>x.id):[]
							result_sc = result_sc.filter(x=>single_sc_ids.includes(x.id))
						})
						var result_sc_final = result_sc && result_sc.length ? result_sc.filter(x=> x.id!='custom'):[]	
						set_selected_sku({sku_id:checked_skus.map(x=>x.sku_id),sales_channels:result_sc_final, percentage:0, add_on:0});
						result_sc_final && result_sc_final.length ? set_show_edit_sku_price_modal(true) : alert.info('No Sales Channel in common')
					}
				}
			}
		}else{
			alert.error('Please Select the SKU')
		}
	}

	useEffect(() => {
		if(modal_json && modal_type=="unique_price_details"){
			set_modal_json_copy(modal_json.units?JSON.parse(JSON.stringify(modal_json.units)):[])
			set_is_price_and_unit_available(window.Module.is_feature_enabled("price_and_unit_pricing_quotation"));
		}
	},[modal_json, modal_type])


	const open_edit_sku_price_modal = (sku) => {
		set_show_edit_sku_price_modal(true)
	}

	const close_edit_sku_price_modal = () => {
		set_show_edit_sku_price_modal(false)
		set_selected_sku({})
		set_allow_single_edit(false)
	}

	const close_compare_sku_price_modal = () => {
		set_show_compare_sku_price_modal(false)
	}

	const onClick_handleclose = () => {
		handle_close()
	}

	const onclick_all_select_checkbox = (value) => {
		var modal_json_temp = JSON.parse(JSON.stringify(modal_json_copy));
		set_all_select_flag(value)
		if(value){
			modal_json_temp.map(x => {
				x.checked=true;
			})
		}else{
			modal_json_temp.map(x => {
				x.checked=false;
			})
			set_modal_json_copy(modal_json_copy.map(x => ({ ...x, checked: true })))

		}
		set_modal_json_copy(modal_json_temp)
	}


	// const onclick_all_select_checkbox = () => {
	// 	var x_temp = all_select_flag
	// 	var modal_json_temp = [...modal_json_copy];
	// 	set_all_select_flag(!x_temp)
	// 	if(x_temp){
	// 		set_modal_json_copy(modal_json_copy.map(x => ({ ...x, checked: false })))

	// 		// modal_json_temp.map(x => {
	// 		// 	x.checked=false;
	// 		// })
	// 	}else{
	// 		// modal_json_temp.map(x => {
	// 		// 	x.checked=true;
	// 		// })
	// 		set_modal_json_copy(modal_json_copy.map(x => ({ ...x, checked: true })))

	// 	}
	// }

	const apply_filters = () => {
		filter_price_setting()
		set_filters_toggle(true)
	}

	const filter_price_setting = () => {
		let lower_search_string = search_string ? search_string.toLowerCase():'';
		if(modal_json && modal_json.units && modal_json.units.length){
			let filtered_items = JSON.parse(JSON.stringify(modal_json.units))
			filtered_items = filtered_items.filter(item => (item.name && item.name.toLowerCase().includes(lower_search_string)) || (item.type && item.type.toLowerCase().includes(lower_search_string)))
			// set_modal_json_copy(filtered_items)
			if(filters_toggle){
				if(selected_types && selected_types.length){
					let types = selected_types && selected_types.length ? selected_types.map(x => x.id) : []
					filtered_items = filtered_items.filter(item => types.includes(item.type))
				}
				if(selected_sales_channels && selected_sales_channels.length){
					let sales_channels = selected_sales_channels && selected_sales_channels.length ? selected_sales_channels.map(x => x.id) : []
					filtered_items = filtered_items.filter(item => sales_channels.includes(item.active_sales_channel_name))
				}
				if(selected_tags && selected_tags.length){
					let temp_tags = selected_tags && selected_tags.length ? selected_tags.map(x => x.id) : []
					filtered_items = filtered_items.filter(item => {
						if(item.tags && item.tags.length){
							for(let i=0; i<item.tags.length; i++){
								if(temp_tags.includes(item.tags[i])) return true
							}
						}
						return false
					} )
				}
				if(selected_price_fields && selected_price_fields.length){
					let temp_price_fields = selected_price_fields && selected_price_fields.length ? selected_price_fields.map(x => x.id) : []
					filtered_items = filtered_items.filter(item => {
						let index = item.active_sales_channel_index
						if(temp_price_fields.includes(item.sales_channels[index].price.price_type)) return true
						return false
					})
				}
				if(selected_categories && selected_categories.length){
					let categories = selected_categories && selected_categories.length ? selected_categories.map(x => x.id) : []
					filtered_items = filtered_items.filter(item => categories.includes(item.category))
				}
				if(selected_sub_categories && selected_sub_categories.length){
					let sub_categories = selected_sub_categories && selected_sub_categories.length ? selected_sub_categories.map(x => x.id) : []
					filtered_items = filtered_items.filter(item => sub_categories.includes(item.sub_category))
				}
			}
			set_modal_json_copy(filtered_items)
		}
	}

	const sort_by = (key) => {
		if(key === 'name'){
			let a = JSON.parse(JSON.stringify(modal_json_copy))
			a = a && a.length ? a.map(x => {return {...x, custom_field: x.name}}) : []
			if(sorted_by_name){
				a.sort(custom_sort)
				set_sorted_by_name(false)
			}else{
				a.sort(desc_custom_sort)
				set_sorted_by_name(true)
			}
			set_modal_json_copy(a)
		}

		if(key === 'type'){
			let a = JSON.parse(JSON.stringify(modal_json_copy))
			a = a && a.length ? a.map(x => {return {...x, custom_field: x.type}}) : []
			if(sorted_by_type){
				a.sort(custom_sort)
				set_sorted_by_type(false)
			}else{
				a.sort(desc_custom_sort)
				set_sorted_by_type(true)
			}
			set_modal_json_copy(a)
		}
		if(key === 'category'){
			let a = JSON.parse(JSON.stringify(modal_json_copy))
			a = a && a.length ? a.map(x => {return {...x, custom_field: x.category}}) : []
			if(sorted_by_category){
				a.sort(custom_sort)
				set_sorted_by_category(false)
			}else{
				a.sort(desc_custom_sort)
				set_sorted_by_category(true)
			}
			set_modal_json_copy(a)
		}
		if(key === 'sub_category'){
			let a = JSON.parse(JSON.stringify(modal_json_copy))
			a = a && a.length ? a.map(x => {return {...x, custom_field: x.sub_category}}) : []
			if(sorted_by_sub_category){
				a.sort(custom_sort)
				set_sorted_by_sub_category(false)
			}else{
				a.sort(desc_custom_sort)
				set_sorted_by_sub_category(true)
			}
			set_modal_json_copy(a)
		}
		if(key === 'sales_channel'){
			let a = JSON.parse(JSON.stringify(modal_json_copy))
			a = a && a.length ? a.map(x => {return {...x, custom_field: x.active_sales_channel_name}}) : []
			if(sorted_by_sales_channel){
				a.sort(custom_sort)
				set_sorted_by_sales_channel(false)
			}else{
				a.sort(desc_custom_sort)
				set_sorted_by_sales_channel(true)
			}
			set_modal_json_copy(a)
		}
		if(key === 'price_field'){
			let a = JSON.parse(JSON.stringify(modal_json_copy))
			a = a && a.length ? a.map(x => {return {...x, custom_field: x.sales_channels && x.sales_channels[x.active_sales_channel_index] && x.sales_channels[x.active_sales_channel_index].price && x.sales_channels[x.active_sales_channel_index].price.price_type ? x.sales_channels[x.active_sales_channel_index].price.price_type : ''}}) : []
			if(sorted_by_price_field){
				a.sort(custom_sort)
				set_sorted_by_price_field(false)
			}else{
				a.sort(desc_custom_sort)
				set_sorted_by_price_field(true)
			}
			set_modal_json_copy(a)
		}
		if(key === 'sku_sr_no'){
			let a = JSON.parse(JSON.stringify(modal_json_copy))
			a = a && a.length ? a.map(x => {return {...x, custom_field: x.sku_sr_no}}) : []
			// if(sorted_by_sku_sr_no){
			// 	a.sort(custom_sort)
			// 	set_sorted_by_sku_sr_no(false)
			// }else{
			// 	a.sort(desc_custom_sort)
			// 	set_sorted_by_sku_sr_no(true)
			// }
			a.sort((a,b) => generic_sort(Number(a),Number(b),'sku_sr_no', sorted_by_sku_sr_no?1:-1, 'number'))
			set_sorted_by_sku_sr_no(!sorted_by_sku_sr_no)
			set_modal_json_copy(a)
		}
	}

	useEffect(() => {
		filter_price_setting()
	}, [search_string, filters_toggle]);

	return(
		<Modal className="modal-dialog-centered" style={{maxWidth: '90vw'}} isOpen={modal_type=="unique_price_details"}>
				<IModalHeader toggle={onClick_handleclose}>Price Settings</IModalHeader>
				<IModalBody style={{maxHeight: '600px', overflow: 'auto'}}>
					<div className='flex_property mb-3' style={{justifyContent:'space-between'}}>
						<div style={{fontWeight: 500}}>{t(`Viewing all Pricing`)}</div>
						<div className='flex_property'>
						<div className='flex_property mr-3' style={{width:'350px', height:"32px", border:'1px solid var(--light-border-color)', borderRadius:'4px', background: 'white'}}>
							<i className='fa fa-search' style={{marginLeft:'8px', color:'#AEB1BA'}}/>
							<Input type='text' value={search_string} onChange={(e) => {set_search_string(e.target.value)}} style={{border:0, height:'30px', maxWidth:"350px", fontSize:'14px'}} placeholder='Search for Name, Type '/>
						</div>
						<div className='flex_property' style={{border:'1px solid var(--light-border-color)', borderRadius:'4px', padding:'5px 6px'}}>
							<div onClick={() => set_show_filter_modal(true)} style={{marginRight:'8px', marginLeft:'2px', cursor:'pointer', color:"#0078ff"}}>{t(`Filters`)}</div>
							<Switch 
								checked = {filters_toggle} 
								onChange = {() => set_filters_toggle(!filters_toggle)} 
								uncheckedIcon={false}
								checkedIcon={false}
								offColor='#e6e6e6'
								offHandleColor='#808080'
								onColor='#23C16B'
								onfHandleColor='#b3d1ff'
								height={15}
								width={30}	
							/>
						</div>
						</div>
					</div>				
							<ITable  coloumnSeparated={true} rowSeparated={true} hover={true} striped={true} style_container={{ border: '1px solid var(--light-border-color)', height: '500px', overflow: 'auto'}}>
							{
								is_price_and_unit_available ?
									<colgroup>
										<col style={{ width: "4" }}></col>
										<col style={{ width: "4%" }}></col>
										<col style={{ width: "15%" }}></col>
										<col style={{ width: "5%" }}></col>
										<col style={{ width: "10%" }}></col>
										<col style={{ width: "8%" }}></col>
										<col style={{ width: "8%" }}></col>
										<col style={{ width: "8%" }}></col>
										<col style={{ width: "15%" }}></col>
										<col style={{ width: "5%" }}></col>
										{is_price_and_unit_available ? <col style={{ width: "5%" }}></col> : ''}
										{is_price_and_unit_available ? <col style={{ width: "8%" }}></col> : ''}
										{is_price_and_unit_available ? <col style={{ width: "5%" }}></col> : ''}
									</colgroup>
									:
									<colgroup>
										<col style={{ width: "5" }}></col>
										<col style={{ width: "5%" }}></col>
										<col style={{ width: "20%" }}></col>
										<col style={{ width: "5%" }}></col>
										<col style={{ width: "10%" }}></col>
										<col style={{ width: "10%" }}></col>
										<col style={{ width: "10%" }}></col>
										<col style={{ width: "10%" }}></col>
										<col style={{ width: "20%" }}></col>
										<col style={{ width: "5%" }}></col>
									</colgroup>

								}
								<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px', zIndex: 2}}>
									<ITH>
										<div className='checkbox_background_hover_before'>
										{
											modal_json_copy && modal_json_copy.length && modal_json_copy.filter(x => x.checked).length===modal_json_copy.length?
											<i onClick={()=>onclick_all_select_checkbox(false)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='fa fa-check-square-o checkbox_background_hover' />
											:
											modal_json_copy && modal_json_copy.length && modal_json_copy.filter(x => x.checked).length===0?
												<i onClick={() =>onclick_all_select_checkbox(true)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='fa fa-square-o checkbox_background_hover' />
												:
												<i onClick={() =>onclick_all_select_checkbox(true)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='fa fa-minus-square-o checkbox_background_hover' />
										}
										</div>
										{/* <Input style={{position:'unset', margin:0, marginRight:'20px'}} type='checkbox' checked={all_select_flag}  onChange={onclick_all_select_checkbox} /> */}
									</ITH>
									<ITH>Sl.No</ITH>
									{/* <ITH><span>Id</span></ITH> */}
									<ITH>Name <i onClick={() => sort_by('name')} style={{marginLeft:'2px', cursor:'pointer'}} className='fa fa-sort' /></ITH>
									<ITH>SKU Sl.No <i onClick={() => sort_by('sku_sr_no')} style={{marginLeft:'2px', cursor:'pointer'}} className='fa fa-sort' /></ITH>
									<ITH><span>Type <i onClick={() => sort_by('type')} style={{marginLeft:'2px', cursor:'pointer'}} className='fa fa-sort' /></span></ITH>
									<ITH><span>Category <i onClick={() => sort_by('category')} style={{marginLeft:'2px', cursor:'pointer'}} className='fa fa-sort' /></span></ITH>
									<ITH><span>Sub-Category <i onClick={() => sort_by('sub_category')} style={{marginLeft:'2px', cursor:'pointer'}} className='fa fa-sort' /></span></ITH>
									<ITH><span>Sales Channel <i onClick={() => sort_by('sales_channel')} style={{marginLeft:'2px', cursor:'pointer'}} className='fa fa-sort' /></span></ITH>
									<ITH><span>Tags</span></ITH>
									<ITH><span>Present In</span></ITH>
									{is_price_and_unit_available?(<ITH><span>Price Field <i onClick={() => sort_by('price_field')} style={{marginLeft:'2px', cursor:'pointer'}} className='fa fa-sort' /></span></ITH>):''}
									{is_price_and_unit_available?(<ITH><span>Price<span style={{}}>({modal_json && modal_json.currency?modal_json.currency:'INR'})</span></span></ITH>):''}
									{is_price_and_unit_available?(<ITH><span>Unit</span></ITH>):''}
									{is_price_and_unit_available?(<ITH><span>Price Source</span></ITH>):''}
								</ITableHeader>
								<ITableBody>
									{
										modal_json_copy.map((single_unit,idx) => (
											<Unique_price_single_layer idx={idx} key={idx} single_unit={single_unit} is_price_and_unit_available={is_price_and_unit_available} set_modal_json_copy={set_modal_json_copy} modal_json_copy={modal_json_copy} />
										))
									}
								</ITableBody>
							</ITable>
				</IModalBody>
				<IModalFooter>
					<Button onClick={onclick_download_csv} className='secondary_button_default'>Download CSV</Button>
					<Button onClick={onclick_price_compare} className='secondary_button_default'>Check Price Version</Button>
					<Button onClick={onclick_edit} className='primary_button_default'>Edit</Button>
				</IModalFooter>
			<Edit_Sku_price open={show_edit_sku_price_modal} handleclose={close_edit_sku_price_modal} update_modal_json={update_modal_json} selected_sku={selected_sku} allow_single_edit={allow_single_edit} set_page_loader={set_page_loader} />
			<Compare_Sku_Price_modal open={show_compare_sku_price_modal} modal_json = {compare_sku_price_modal_json} set_modal_json = {set_compare_sku_price_modal_json} handle_close={close_compare_sku_price_modal} set_page_loader={set_page_loader} />
			<FilterModal 
				open={show_filter_modal} 
				handle_close={() => {set_show_filter_modal(false)}} 
				all_array = {all_array} 
				selected_types = {selected_types} 
				set_selected_types={set_selected_types}
				selected_sales_channels = {selected_sales_channels}
				set_selected_sales_channels = {set_selected_sales_channels}
				selected_tags = {selected_tags}
				set_selected_tags = {set_selected_tags}
				selected_price_fields = {selected_price_fields}
				set_selected_price_fields = {set_selected_price_fields}
				selected_categories = {selected_categories}
				set_selected_categories = {set_selected_categories}
				selected_sub_categories = {selected_sub_categories}
				set_selected_sub_categories = {set_selected_sub_categories}
				apply_filters = {apply_filters}
			/>
		</Modal>
	)    
}

const Applied_Rule_Single_Layer = ({single_unit, idx, modal_json_copy, set_modal_json_copy, selected_rule_json, set_selected_rule_json}) => {

	const styles={
		td_style:{
			width:"100%",
			minHeight:'50px',
			heigth:'auto',
			overflowWrap: 'anywhere',
			display:'flex',
			alignItems:'center',
			padding:"0px 5px"
		},
		th_style:{
			width:"100%",
			padding:"0px 5px"		
		}
	}

	const [checkbox_value, set_checkbox_value] = useState(false)

	useEffect(() => {
		if(single_unit){
			set_checkbox_value(single_unit.checked)
		}
	},[single_unit])

	useEffect(() => {
		// console.log(checkbox_value)
		// single_unit.checked=checkbox_value;
		if(modal_json_copy && modal_json_copy.length){
			var x_copy = JSON.parse(JSON.stringify(modal_json_copy))
			x_copy.map((x,item) => {
				x.checked=false;
				if(x.id===single_unit.id){
					x.checked=checkbox_value;
					set_selected_rule_json(x);
					console.log(selected_rule_json);
				}
			})
			set_modal_json_copy(x_copy)
		}
	},[checkbox_value])

	return(
		<tr style={{width:"100%", display:'flex' , alignItems:'center', backgroundColor:single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && single_unit.sales_channels[single_unit.active_sales_channel_index].price.is_pricing_system_mismatch?'#ffdcda':((idx%2==0)?'#f2f2f2':''), borderBottom:'1px solid #ddd'}}>
			<td style={{...styles.td_style,flex:1, minHeight:"35px",paddingLeft:'10px'}}>
				<div className='checkbox_background_hover_before'><Input className="checkbox_background_hover" style={{position:'unset', margin:0, cursor:'pointer'}} title='Select' type='checkbox' checked={checkbox_value}  onChange={() => set_checkbox_value(!checkbox_value)} /></div>
			</td>
			<td style={{...styles.td_style,flex:1, minHeight:"35px"}}>{idx+1}</td>
			<td style={{...styles.td_style,flex:4, minHeight:"35px"}}>{single_unit.rule_name?single_unit.rule_name:"-"}</td>
			<td style={{...styles.td_style,flex:2, minHeight:"35px"}}>{single_unit.rule_description?single_unit.rule_description:"-"}</td>
		</tr>
	)
}

const Single_item = ({open, single_sku, si_no, idx1, update_rule_json, delete_sku_from_rule_json, update_offset, delete_offset_array, categories, add_new_offset_array, set_page_loader}) => {
	
	// useEffect(() => {
	// 	console.log('single_sku', single_sku)
	// }, [single_sku]);

	const [sub_categories, set_sub_categories] = useState([]);
	const [groups, set_groups] = useState([]);
	const [skus, set_skus] = useState([]);
    const {t} = useTranslation()

	const fetch_sub_categories = (category_id) => {
		set_page_loader({
			show: true,
			text: 'Fetching Sub Categories...'
		})
		// window.Promisify(window.Module.API.get_active_sku_sub_categories_all(category_id,false,"")).then(o => {o = JSON.parse(o);set_sub_categories(o && o.data ? o.data : [])})
		window.Promisify(window.Module.fetch_sku_sub_categories_wrapper(category_id,false,"")).then(o => {o = JSON.parse(o);set_sub_categories(o ? o : [])})
		set_page_loader({
			show: false,
		})
	}

	const set_groups_fn = (subcat_id) => {
		let subcat = sub_categories.find(o => o.id === subcat_id)
		set_groups(subcat && subcat.sku_group ? subcat.sku_group : [])
	}

	const set_skus_fn = (group_id) => {
		let group = groups.find(o => o.id === group_id)
		set_skus(group && group.sku ? group.sku : [])
	}

	// useEffect(() => {
	// 	if(single_sku){
	// 		fetch_sub_categories(single_sku.category)
	// 		set_groups_fn(single_sku.sub_category)
	// 		set_skus_fn(single_sku.group)
	// 		console.log('groups', groups, single_sku)
	// 		console.log('skus', skus)
	// 	}
	// }, []);

	useEffect(() => {
		if(single_sku.category) fetch_sub_categories(single_sku.category)
	}, [single_sku.category]);

	useEffect(() => {
		if(single_sku.sub_category) set_groups_fn(single_sku.sub_category)
	}, [single_sku.sub_category, sub_categories]);

	useEffect(() => {
		if(single_sku.group) set_skus_fn(single_sku.group)
	}, [single_sku.group, groups]);
	
	return(
		<div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
			<div style={{display:'flex', alignItems:'center', marginTop:'12px'}}>
				<div style={{width:'4%', textAlign:'center'}}>{si_no+1}.</div>
				<div style={{width:'16%'}}>
					<Input type='select' value={single_sku.division} onChange={(e) => update_rule_json(idx1, 'division', e.target.value)} style={{height:'32px', fontSize:'12px', width:'90%'}}>
						<option value='none'>None</option>
						<option value='hardware'>Hardware</option>
					</Input>
				</div>
				<div style={{width:'16%'}}>
					<Input type='select' value={single_sku.category} onChange={(e) => {update_rule_json(idx1, 'category', e.target.value)}} style={{height:'32px', fontSize:'12px', width:'90%'}}>
						<option value='none'>None</option>
					{
						categories && categories.length ? categories.map((single_category, idx) => (
							<option key={idx} value={single_category.id}>{single_category.name}</option>
						)):""
					}
					</Input>
				</div>
				<div style={{width:'16%'}}>
					<Input type='select' value={single_sku.sub_category} onChange={(e) => {update_rule_json(idx1, 'sub_category', e.target.value)}} style={{height:'32px', fontSize:'12px', width:'90%'}}>
						<option value='none'>None</option>
					{
						sub_categories && sub_categories.length ? sub_categories.map((single_sub_category, idx) => (
							<option key = {idx} value={single_sub_category.id}>{single_sub_category.name}</option>
						)):""
					}
					</Input>
				</div>
				<div style={{width:'16%'}}>
					<Input type='select' value={single_sku.group} onChange={(e) => {update_rule_json(idx1, 'group', e.target.value)}} style={{height:'32px', fontSize:'12px', width:'90%'}}>
						<option value='none'>None</option>
					{
						groups && groups.length ? groups.map((single_group, idx) => (
							<option key = {idx} value={single_group.id}>{single_group.name}</option>
						)):""
					}
					</Input>
				</div>
				<div style={{width:'16%'}}>
					{/* <Input type='select' value={single_sku.sku} onChange={(e) => update_rule_json(idx1, 'sku', e.target.value)} style={{height:'32px', fontSize:'12px', width:'90%'}}>
						<option value='none'>None</option>
					{
						skus && skus.length ? skus.map((single_sku, idx) => (
							<option key ={idx} value={single_sku.id}>{single_sku.name}</option>
						)):""
					}
					</Input> */}
					<Multiselect1
						style={{ backgroundColor: 'white', fontSize:'12px', width:'90%' }}
						options={skus && skus.length ? (skus.map((single_sku, idx) => (
							{id: single_sku.id, name: single_sku.name}
						))):[]}
						selectedValues={single_sku.sku && single_sku.sku !== 'none' ? [{id: single_sku.sku}] : []}
						onSelect={(selectedList) => update_rule_json(idx1, 'sku', selectedList && selectedList.length ? selectedList[0].id : 'none')}
						onRemove={(selectedList) => update_rule_json(idx1, 'sku', 'none')}
						placeholder='Select Sku'
						selectionLimit={1}
						displayValue={"name"}
					/>

				</div>
				<div style={{width:'12%',}}><Input type='number' value={(single_sku.offset_values && single_sku.offset_values.length) ? (single_sku.offset_values ? single_sku.offset_values.length : '0') : single_sku.quantity} onChange={(single_sku.offset_values && single_sku.offset_values.length) ? '' : (e) => {update_rule_json(idx1, 'quantity', e.target.value)}} disabled={single_sku.offset_values && single_sku.offset_values.length} style={{height:'32px', fontSize:'12px', width:'90%'}}/></div>
				<div style={{width:'4%', color:'red', fontSize:'16px', cursor:'pointer', display:'flex', justifyContent:'flex-end'}}><i className='fa fa-trash' onClick={() => delete_sku_from_rule_json(idx1)}/></div>
			</div>
			{single_sku.offset_values && single_sku.offset_values.length ?
			<div style={{backgroundColor: 'rgb(239 240 242)', alignSelf: 'center', width: 'fit-content', padding:'20px', maxHeight:'212px', overflow:'auto'}}>
				<div>{t(`Offset Values:`)}</div>
				<div style={{display:'flex', alignItems:'center', gap: '12px', marginTop:'12px'}}>
					<div style={{flex: 1}}></div>
					{
						single_sku.offset_values && single_sku.offset_values.length && single_sku.offset_values[0] && single_sku.offset_values[0].length ? single_sku.offset_values[0].map(single_offset => (
								<div style={{flex: 3}}>{single_offset.id}</div>
						)) : ""
					}
					<div style={{flex: 1}}></div>
				</div>
			{
				single_sku.offset_values && single_sku.offset_values.length ? single_sku.offset_values.map((single_offset_array, idx2) => (
					<div key={idx2} style={{display:'flex', alignItems:'center', gap: '12px', marginTop: idx2?'12px':'2px'}}>
						{/* {idx2==0 && single_offset_array && single_offset_array.length ? 
							<div>{single_offset_array[0].id} </div> : ""} */}
						<div style={{flex: 1}}>{idx2+1}.</div>
					{
						single_offset_array && single_offset_array.length ? single_offset_array.map((single_offset, idx3) => (
							(single_offset.type == 'double')?
							<div style={{flex: 3}}>
								<Input key={idx3} type='text' placeholder={'offset-'+(idx3+1)} defaultValue={single_offset.value} onChange={(e) => update_offset(idx1, idx2, idx3, e.target.value)} style={{height:'28px', fontSize:'12px', border:'1px solid #ced4da'}}/>
							</div>
							:
							<div style={{flex: 3}}>
								<Input key={idx3} type='select' placeholder={'offset-'+(idx3+1)} defaultValue={single_offset.value} onChange={(e) => update_offset(idx1, idx2, idx3, e.target.value)} style={{height:'28px', fontSize:'12px', border:'1px solid #ced4da'}}>
									{/* <option value='none'>None</option> */}
									{
										single_offset.options && single_offset.options.length ? single_offset.options.map((option, o_idx) => (
											<option key={o_idx} value={option[0].value}>{option[0].name}</option>
										)):""
									}
								</Input>
							</div>
							
						)):''
					}
					<i style={{fontSize:'14px', flex: 1, display:'flex', justifyContent:'flex-end', color:'red', cursor:'pointer'}} className='fa fa-trash' onClick={() => delete_offset_array(idx1, idx2)}/>
					</div>
				)):''
			}
			<div onClick={() => add_new_offset_array(idx1)} style={{color:COLORS.blue6, marginTop:'16px', fontWeight:'500', cursor:'pointer'}}>+ Add</div>
			</div> : ''}
			
		</div>
	)
}

const Override_Rule_Modal = ({open,update_modal_json, handle_close, original_rule_json, set_page_loader}) => {

	const [rule_json, set_rule_json] = useState({});
	const [categories, set_categories] = useState([]);
    const {t} = useTranslation()

	// useEffect(() => {
	// 	console.log('rule_json', rule_json);
	// }, [rule_json])

	const onclick_handleclose = () => {
		set_rule_json({})
		handle_close()
	}

	const add_new_sku = (id) => {
		let a = JSON.parse(JSON.stringify(rule_json))
		let len = 0;
		if(!a.sku_array){
			a.sku_array = []
		}else{
			len = a.sku_array.length
		}
		if(id == 'default_action')
			a.sku_array.push({'offset_values':[], 'default_action': true, sku_array_index: len})
		else if(id)
			a.sku_array.push({'offset_values':[], 'rule_input_value_id': id, sku_array_index: len})
		else
			a.sku_array.push({'offset_values':[], sku_array_index: len})
		set_rule_json(a)
	}

	const change_rule_activation = (e) => {
		console.log(e.target.value)
		let a = JSON.parse(JSON.stringify(rule_json))
		a.rule_activation = e.target.value
		console.log(a)
		set_rule_json(a)
	}

	const update_rule_json = (id, key, value) => {
		let a = JSON.parse(JSON.stringify(rule_json))
		a.sku_array[id][key] = value
		set_rule_json(a)

		if(key == 'division'){

		}
	}

	const delete_sku_from_rule_json = (id) => {
		let a = JSON.parse(JSON.stringify(rule_json))
		a.sku_array.splice(id, 1)
		reset_array_index(a.sku_array)
		set_rule_json(a)
	}

	const add_new_offset_array = (id) => {
		// console.log("KPP adding new offset arr", id)
		let a = JSON.parse(JSON.stringify(rule_json))
		if(a.sku_array && a.sku_array.length && a.sku_array[0] && a.sku_array[0]['offset_values'] && a.sku_array[0]['offset_values'].length){
			let new_offset_arr = JSON.parse(JSON.stringify(a.sku_array[0]['offset_values'][0]))
			let temp = new_offset_arr.map(new_offset => {
				if(new_offset.type == "select")
					new_offset.value = "notset"
				else
					new_offset.value = ""
			})
			a.sku_array[id]['offset_values'].push(new_offset_arr)
			a.sku_array[id]['quantity'] = a.sku_array[id]['offset_values'].length.toString()
		}
		else
			a.sku_array[id]['offset_values'].push([])
		set_rule_json(a)
	}

	const update_offset = (id1, id2, id3, value) => {
		let a = JSON.parse(JSON.stringify(rule_json))
		a.sku_array[id1]['offset_values'][id2][id3].value = value
		set_rule_json(a)
	}

	const delete_offset_array = (id1, id2) => {
		let a = JSON.parse(JSON.stringify(rule_json))
		let b = a.sku_array[id1]['offset_values']
		b.splice(id2, 1)
		a.sku_array[id1]['quantity'] = a.sku_array[id1]['offset_values'].length.toString()
		set_rule_json(a)
	}

	const onclick_update = () => {
		onclick_handleclose()
		window.Module.override_selected_rule(JSON.stringify(rule_json));
		update_modal_json()
		// window.Module.check_and_override_rules_applied("");
	}

	const onclick_reset = () => {
		console.log("reset")
		let clone_original = JSON.parse(JSON.stringify(original_rule_json))
		reset_array_index(clone_original && clone_original.sku_array)
		set_rule_json(clone_original);
		onclick_handleclose()
		window.Module.reset_selected_rule(JSON.stringify(rule_json));
		update_modal_json()
	}

	const reset_array_index = (sku_array) => {
		if(sku_array && Array.isArray(sku_array)){
			for(let i in sku_array){
				sku_array[i].sku_array_index = i;
			}
		}
	}

	useEffect(() => {
		if(open && original_rule_json) {
			let clone_original = JSON.parse(JSON.stringify(original_rule_json))
			reset_array_index(clone_original && clone_original.sku_array)
			set_rule_json(clone_original)
		}
		// set_rule_json({
		// 	rule_name: 'Override rule name',
		// 	rule_description: 'Override rule description',
		// 	rule_activation: 'true',
		// 	sku_array:[
		// 		{division: 'hardware', category:'', sub_category:'', group:'', sku:'', quantity:'1', offset_values:[[0,0,0]]}
		// 	]
		// })
		// console.log('set_rule_json')
		// console.log('rule_json', rule_json)
	}, [open, original_rule_json]);

	useEffect(() => {
		if(open){
			// window.Promisify(window.Module.API.get_active_divisions("")).then(o => {o = JSON.parse(o);set_categories(o && o.data && o.data.hardware ? o.data.hardware.sku_categories : [])})
			window.Promisify(window.Module.fetch_division_wrapper(false)).then(o => {o = JSON.parse(o);set_categories(o && o.hardware ? o.hardware.sku_categories : [])})
		}
	}, [open]);


	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={open} onClose={onclick_handleclose}>
			<CardHeader className='global_modal_header'>
				<span>Override Rule</span>
				<i onClick={onclick_handleclose} style={{cursor:'pointer'}} className='fa fa-times'/>
			</CardHeader>
			<CardBody style={{height:'400px', padding:'20px 40px', overflow:'auto', fontSize:'12px'}}>
				<Row style={{marginBottom:'40px', backgroundColor:'white'}}>
					<Col xs={5}>
						<div>
							<div style={{color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>{t(`Rule Name *`)}</div>
							<Input disabled id='hw_rule_name' style={{fontSize:'12px', width:'100%',heightor:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder= {rule_json.rule_name} type="text"  />
						</div>
					</Col>	
					<Col xs={5}>
						<div>
							<div style={{color:'#425A70', letterSpacing: '-0.04px', lineHeight: '22px'}}>{t(`Rule Description*`)}</div>
							<Input disabled id='hw_rule_description' style={{width:'100%',height:'32px', fontSize:'12px', color:'#435A6F', backgroundColor: '#FFFFFF', borderRadius: '3px'}} placeholder={rule_json.rule_description} type="text" />
						</div>
					</Col>	
					<Col xs={2}>
						<div>
							<div style={{letterSpacing: '-0.04px', lineHeight: '22px'}}>{t(`Rule Activation*`)}</div>
							<Input style={{height:'100%',overflowY:'auto',fontSize:'12px',}} type="select"
								value={rule_json.rule_activation} onChange={change_rule_activation}
							>
								<option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} value='true'>True</option>
								<option style={{listStyle: 'none', textTransform:'capitalize',color:'black'}} value='false'>False</option>
							</Input>
						</div>
					</Col>	
				</Row>
				<div style={{backgroundColor:'white'}}>
					<div style={{display: 'flex', alignItems: 'center', gap: '20px', marginTop: '16px'}}>

					</div>
					{/* <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'16px'}}>
						<div style={{flex:'1'}}><span></span></div>
						<div style={{flex:'4'}}><span>{t(`Division`)}</span></div>
						<div style={{flex:'4'}}><span>{t(`Category`)}</span></div>
						<div style={{flex:'4'}}><span>{t(`Sub Category`)}</span></div>
						<div style={{flex:'4'}}><span>{t(`Group`)}</span></div>
						<div style={{flex:'4'}}><span>{t(`SKU`)}</span></div>
						<div style={{flex:'3'}}><span>{t(`Quantity`)}</span></div>
						<div style={{flex:'1'}}></div>
					</div> */}
					{/* {
						rule_json.sku_array && rule_json.sku_array.length ? rule_json.sku_array.map((single_sku, idx1) => (
						<Single_item
							open = {open}
							single_sku = {single_sku}
							idx1 = {idx1}
							update_rule_json = {update_rule_json}
							delete_sku_from_rule_json = {delete_sku_from_rule_json}
							update_offset = {update_offset}
							delete_offset_array = {delete_offset_array}
							add_new_offset_array = {add_new_offset_array}
							categories = {categories}
							set_page_loader = {set_page_loader}
							key = {idx1}
						/>
						)):''
					} */}
					{
						rule_json.rule_input_values && Object.keys(rule_json.rule_input_values).map(item => (
						<div>
							<div style={{paddingBottom: '10px', paddingTop: '20px', display: 'flex', gap: '20px', alignItems: 'center'}}>
								{
									rule_json.rule_input_values && rule_json.rule_input_values[item] && Object.keys(rule_json.rule_input_values[item].value).map(input_key => (
										<div>
											<div className='mb-2'>{rule_json.rule_input_values[item].value[input_key].name}</div>
											<Input disabled={true} type='select' style={{height:'32px',overflowY:'auto',fontSize:'12px', width: 'fit-content'}} value={rule_json.rule_input_values[item].value[input_key].value}>
												{rule_json.rule_input_values[item].value[input_key].valid_values && rule_json.rule_input_values[item].value[input_key].valid_values.map(value_option => (
													<option value={value_option}>{value_option}</option>
												))}
											</Input>
										</div>
									))
								}
							</div>
							<div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'16px'}}>
								<div style={{flex:'1'}}><span></span></div>
								<div style={{flex:'4'}}><span>Division</span></div>
								<div style={{flex:'4'}}><span>Category</span></div>
								<div style={{flex:'4'}}><span>Sub Category</span></div>
								<div style={{flex:'4'}}><span>Group</span></div>
								<div style={{flex:'4'}}><span>SKU</span></div>
								<div style={{flex:'3'}}><span>Quantity</span></div>
								<div style={{flex:'1'}}></div>
							</div>
							{rule_json.sku_array.filter(rule_item => rule_item.rule_input_value_id == item) && rule_json.sku_array.filter(rule_item => rule_item.rule_input_value_id == item).length ? 
								rule_json.sku_array.filter(rule_item => rule_item.rule_input_value_id == item).map((single_sku, idx1) => (
									single_sku.rule_input_value_id != item ? '' :
									<Single_item
										open = {open}
										single_sku = {single_sku}
										si_no = {idx1}
										idx1 = {/* rule_json.sku_array.findIndex(sku_unit => sku_unit.id == single_sku.id) */ single_sku.sku_array_index}
										update_rule_json = {update_rule_json}
										delete_sku_from_rule_json = {delete_sku_from_rule_json}
										update_offset = {update_offset}
										delete_offset_array = {delete_offset_array}
										add_new_offset_array = {add_new_offset_array}
										categories = {categories}
										set_page_loader = {set_page_loader}
										key = {idx1}
									/>
									)):''}
							<div onClick={(e) => add_new_sku(item)} style={{color:COLORS.blue6, marginTop:'16px', fontWeight:'500', cursor:'pointer'}}>
								+ Add SKU
							</div>
						</div>
						))
					}
					{
						<div>
						{rule_json.sku_array && rule_json.sku_array.filter(rule_item => rule_item.default_action) && rule_json.sku_array.filter(rule_item => rule_item.default_action).length ? 
							<div>
							<div style={{paddingBottom: '10px', paddingTop: '20px', fontWeight: 'bold'}}>Default Designer Input Condition</div>
							<div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'16px'}}>
							<div style={{flex:'1'}}><span></span></div>
							<div style={{flex:'4'}}><span>Division</span></div>
							<div style={{flex:'4'}}><span>Category</span></div>
							<div style={{flex:'4'}}><span>Sub Category</span></div>
							<div style={{flex:'4'}}><span>Group</span></div>
							<div style={{flex:'4'}}><span>SKU</span></div>
							<div style={{flex:'3'}}><span>Quantity</span></div>
							<div style={{flex:'1'}}></div>
							</div>
							{rule_json.sku_array.filter(rule_item => rule_item.default_action).map((single_sku, idx1) => (
								!single_sku.default_action ? '' :
								<Single_item
									open = {open}
									single_sku = {single_sku}
									si_no = {idx1}
									idx1 = {/* rule_json.sku_array.findIndex(sku_unit => sku_unit.id == single_sku.id) */ single_sku.sku_array_index}
									update_rule_json = {update_rule_json}
									delete_sku_from_rule_json = {delete_sku_from_rule_json}
									update_offset = {update_offset}
									delete_offset_array = {delete_offset_array}
									add_new_offset_array = {add_new_offset_array}
									categories = {categories}
									set_page_loader = {set_page_loader}
									key = {idx1}
								/>
								))}
							<div onClick={(e) => add_new_sku('default_action')} style={{color:COLORS.blue6, marginTop:'16px', fontWeight:'500', cursor:'pointer'}}>
							+ Add SKU
							</div>
							</div>:''}
						</div>
					}
					{
						<div>
						{rule_json.sku_array && rule_json.sku_array.filter(rule_item => !rule_item.rule_input_value_id && !rule_item.default_action) && rule_json.sku_array.filter(rule_item => !rule_item.rule_input_value_id && !rule_item.default_action).length ? 
							<div>
							<div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'16px'}}>
							<div style={{flex:'1'}}><span></span></div>
							<div style={{flex:'4'}}><span>Division</span></div>
							<div style={{flex:'4'}}><span>Category</span></div>
							<div style={{flex:'4'}}><span>Sub Category</span></div>
							<div style={{flex:'4'}}><span>Group</span></div>
							<div style={{flex:'4'}}><span>SKU</span></div>
							<div style={{flex:'3'}}><span>Quantity</span></div>
							<div style={{flex:'1'}}></div>
							</div>
							{rule_json.sku_array.filter(rule_item => !rule_item.rule_input_value_id && !rule_item.default_action).map((single_sku, idx1) => (
								single_sku.rule_input_value_id || single_sku.default_action ? '' :
								<Single_item
									open = {open}
									single_sku = {single_sku}
									si_no = {idx1}
									idx1 = {/* rule_json.sku_array.findIndex(sku_unit => sku_unit.id == single_sku.id) */single_sku.sku_array_index}
									update_rule_json = {update_rule_json}
									delete_sku_from_rule_json = {delete_sku_from_rule_json}
									update_offset = {update_offset}
									delete_offset_array = {delete_offset_array}
									add_new_offset_array = {add_new_offset_array}
									categories = {categories}
									set_page_loader = {set_page_loader}
									key = {idx1}
								/>
								))}
							<div onClick={(e) => add_new_sku(false)} style={{color:COLORS.blue6, marginTop:'16px', fontWeight:'500', cursor:'pointer'}}>
							+ Add SKU
							</div>
							</div>:''}
						</div>
					}
					{/* <div onClick={add_new_sku} style={{color:COLORS.blue6, marginTop:'16px', fontWeight:'500', cursor:'pointer'}}>
						+ Add SKU
					</div> */}
				</div>
			</CardBody>
			<CardFooter className='global_modal_footer'>
				<Button className='white_button' onClick={onclick_handleclose}>Cancel</Button>
				<Button className='blue_button' onClick= {onclick_reset}>Reset</Button>
				<Button className='blue_button' style={{marginLeft:'8px'}} onClick={onclick_update}>Update</Button>
			</CardFooter>
		</Modal>
	)
}

const Rules_Applied_Modal = ({modal_type, handle_close,  modal_json, update_modal_json, set_page_loader, handle_ui_response }) => {

	const [ modal_json_copy, set_modal_json_copy ] = useState([]);
	const [ show_edit_sku_price_modal, set_show_edit_sku_price_modal ] = useState(false);
	const [ selected_sku, set_selected_sku ] = useState({});
	const [ allow_single_edit, set_allow_single_edit ] = useState(false);
	const [show_override_rule_modal, set_show_override_rule_modal] = useState();
	const [selected_rule_json, set_selected_rule_json] = useState([]);
	const alert = useAlert();
	
	useEffect(() => {
		if(modal_type=="check_and_override_rules_applied" && modal_json){
			set_modal_json_copy(modal_json.rules?JSON.parse(JSON.stringify(modal_json.rules)):[])
			set_selected_rule_json(modal_json.rules && modal_json.rules.length ? JSON.parse(JSON.stringify(modal_json.rules))[0] : '')
		}
	},[modal_json, modal_type])

	const open_edit_sku_price_modal = (sku) => {
		set_show_edit_sku_price_modal(true)
	}

	const close_edit_sku_price_modal = () => {
		set_show_edit_sku_price_modal(false)
		set_selected_sku({})
		set_allow_single_edit(false)
	}

	const onClick_handleclose = () => {
		handle_close()
	}

	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type=="check_and_override_rules_applied"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Rule Status</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={onClick_handleclose}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white, padding:'30px 20px', fontSize:'13px',minHeight:'300px', maxHeight:"600px", overflow:"auto" }}>					
					<Row>
						<Col>
							<table style={{width:'100%'}}>
								<thead>
								<tr style={{height:'auto', width:'100%', display:'flex' , alignItems:'center', borderBottom:"2px solid #ddd",}}>
									<th style={{...styles.th_style, padding:"0px 5px",flex:1,paddingLeft:'10px'}}>
									</th>
									<th style={{...styles.th_style, padding:"0px 5px",flex:1}}>Sl.No</th>
									{/* <th style={{...styles.th_style, padding:"0px 5px",flex:2}}><span>Id</span></th> */}
									<th style={{...styles.th_style, padding:"0px 5px",flex:6}}>Name</th>
									<th style={{...styles.th_style, padding:"0px 5px",flex:6}}><span>Description</span></th>
									<th style={{...styles.th_style, padding:"0px 5px",flex:3}}><span>Status</span></th>
								</tr>
								</thead>
								<tbody style={{display:"table",width:"100%"}}>
									{
										modal_json_copy.map((single_unit,idx) => (
											// <Applied_Rule_Single_Layer idx={idx} key={idx} single_unit={single_unit} set_modal_json_copy={set_modal_json_copy} modal_json_copy={modal_json_copy} selected_rule_json={selected_rule_json} set_selected_rule_json={set_selected_rule_json}/>
											<div className='flex_property'>
												<tr style={{width:"100%", display:'flex' , alignItems:'center', backgroundColor:single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && single_unit.sales_channels[single_unit.active_sales_channel_index].price.is_pricing_system_mismatch?'#ffdcda':((idx%2==0)?'#f2f2f2':''), borderBottom:'1px solid #ddd'}}>
													<td style={{...styles.td_style,flex:1,minHeight:'35px'}}>
													<Input checked={selected_rule_json.id===single_unit.id?true:false} onClick={()=> set_selected_rule_json(single_unit)} key={idx} style={{ margin:'0px 10px', cursor:'pointer'}} type='radio' />
													</td>
													<td style={{...styles.td_style,flex:1, minHeight:"35px"}}>{idx+1}</td>
													<td style={{...styles.td_style,flex:6, minHeight:"35px"}}>{single_unit.rule_name?single_unit.rule_name:"-"}</td>
													<td style={{...styles.td_style,flex:6, minHeight:"35px"}}>{single_unit.rule_description?single_unit.rule_description:"-"}</td>
													<td style={{...styles.td_style,flex:3, minHeight:"35px"}}>{single_unit.status?single_unit.status:"-"}</td>
												</tr>
											</div>
										))
									}
								</tbody>
							</table>
						</Col>  
					</Row>
				</CardBody>
				<CardFooter style={{textAlign:'right', backgroundColor:'white'}}>
					{selected_rule_json ? <Button onClick={() => {set_show_override_rule_modal(true)}} className='blue_button'>Override</Button> : ''}
				</CardFooter>
			</Card>
			<Override_Rule_Modal open={show_override_rule_modal} update_modal_json={update_modal_json} handle_close={() => set_show_override_rule_modal(false)} original_rule_json = {selected_rule_json} set_page_loader={set_page_loader}/>
			{/* <Edit_Sku_price open={show_edit_sku_price_modal} handleclose={close_edit_sku_price_modal} update_modal_json={update_modal_json} selected_sku={selected_sku} allow_single_edit={allow_single_edit} set_page_loader={set_page_loader} /> */}
		</Modal>
	)    
}

const Compare_sku_price_single_layer = ({single_unit, idx, set_modal_json, modal_json}) => {
	const styles={
		td_style:{
			width:"100%",
			minHeight:'50px',
			heigth:'auto',
			overflowWrap: 'anywhere',
			display:'flex',
			alignItems:'center',
			padding:"0px 5px"
		},
		th_style:{
			width:"100%",
			padding:"0px 5px"		
		}
	}

	const onclick_checkbox = (sku_id) => {
		let temp_json = {...modal_json}
		let index = temp_json.data.findIndex(el => el.sku_id == sku_id)
		if(index != -1){
			temp_json.data[index].checked = !temp_json.data[index].checked
			set_modal_json(temp_json)
		}
		/*set_modal_json(curr => curr.data.map(obj => {
			if(obj.sku_id == sku_id){
				return {
					...obj, 
					checked:!obj.checked
				}
			}
			return obj;
		}))*/
	}
	

	return(
		// <tr style={{width:"100%", display:'flex' , alignItems:'center', backgroundColor:single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && single_unit.sales_channels[single_unit.active_sales_channel_index].price.is_pricing_system_mismatch?'#ffdcda':((idx%2==0)?'#f2f2f2':''), borderBottom:'1px solid #ddd'}}>
		// 	<td style={{...styles.td_style,flex:1, minHeight:"35px",paddingLeft:'10px'}}>
		// 		<div className='checkbox_background_hover_before'><Input className="checkbox_background_hover" style={{position:'unset', margin:0, cursor:'pointer'}} title='Select' type='checkbox' checked={single_unit.checked}  onChange={() => onclick_checkbox(single_unit.sku_id)} /></div>
		// 	</td>
		// 	<td style={{...styles.td_style,flex:1, minHeight:"35px"}}>{idx+1}</td>
		// 	<td style={{...styles.td_style,flex:4, minHeight:"35px"}}>{single_unit.name?single_unit.name:"-"}</td>
		// 	{/* <td style={{...styles.td_style,flex:3, minHeight:"35px"}}>{single_unit.sales_channel_name?single_unit.sales_channel_name:"-"}</td> */}
		// 	{/* <td style={{...styles.td_style,flex:3, minHeight:"35px"}}>{single_unit.price_type_name?single_unit.price_type_name:"-"}</td> */}
		// 	<td style={{...styles.td_style,flex:3, minHeight:"35px"}}>{single_unit.sku_price_old ?single_unit.sku_price_old:"-"}</td>
		// 	<td style={{...styles.td_style,flex:3, minHeight:"35px"}}>{single_unit.sku_price_new ?single_unit.sku_price_new:"-"}</td>
		// 	{/* <td style={{...styles.td_style,flex:2, minHeight:"35px", color: single_unit.changed && single_unit.changed == true?"#ffdcda":""}}>{single_unit.changed?(single_unit.changed == true?"Yes":"No"):"-"}</td> */}
		// </tr>
		<ITableRow style={{backgroundColor:single_unit.sales_channels && single_unit.sales_channels.length && single_unit.sales_channels[single_unit.active_sales_channel_index] && single_unit.sales_channels[single_unit.active_sales_channel_index].price && single_unit.sales_channels[single_unit.active_sales_channel_index].price.is_pricing_system_mismatch?'#ffdcda':''}}>
			<ITD>
				<div className='checkbox_background_hover_before'><Input className="checkbox_background_hover" style={{position:'unset', margin:0, cursor:'pointer'}} title='Select' type='checkbox' checked={single_unit.checked}  onChange={() => onclick_checkbox(single_unit.sku_id)} /></div>
			</ITD>
			<ITD>{idx+1}</ITD>
			<ITD>{single_unit.name?single_unit.name:"-"}</ITD>
			{/* <ITD>{single_unit.sales_channel_name?single_unit.sales_channel_name:"-"}</ITD> */}
			{/* <ITD>{single_unit.price_type_name?single_unit.price_type_name:"-"}</ITD> */}
			<ITD>{single_unit.sku_price_old ?single_unit.sku_price_old:"-"}</ITD>
			<ITD>{single_unit.sku_price_new ?single_unit.sku_price_new:"-"}</ITD>
			{/* <ITD style={{color: single_unit.changed && single_unit.changed == true?"#ffdcda":""}}>{single_unit.changed?(single_unit.changed == true?"Yes":"No"):"-"}</ITD> */}
		</ITableRow>
	)
}

const Compare_Sku_Price_modal = ({open, modal_json, handle_close, set_page_loader, set_modal_json}) => {

	const onClick_handleclose = () => {
		handle_close()
	}

	const onclick_refresh_skus = () => {
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {
			let sku_array = modal_json.data.filter((obj) => obj.checked == true)
			console.log("Smit sku array", sku_array)  
			let obj = {}
			obj["ids"] = sku_array
			obj["show_pricing_quotation"] = true
			window.Module.refresh_specific_skus_cache(JSON.stringify(obj));
			handle_close();
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	const onclick_all_select_checkbox = (value) => {
		let temp_json = {...modal_json}
		temp_json.data.map(el => el.checked = value)
		set_modal_json(temp_json)
	}

	return (
		// <Modal className="modal-dialog-centered" size="lg" isOpen={open}>
		// 	<Card className="bg-secondary shadow border-0">
		// 		<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.white}} >
		// 			<div>
		// 				<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Check SKU Price Mismatch</span><span><Button onClick={onClick_handleclose}>x</Button></span></h5>
		// 			</div>
		// 		</CardHeader>
		// 		<CardBody style = {{backgroundColor: COLORS.white, padding:'30px 20px', fontSize:'13px',minHeight:'300px', maxHeight:"600px", overflow:"auto" }}>					
		// 			<Row>
		// 				<Col>
		// 					<table style={{width:'100%'}}>
		// 						<thead>
		// 						<tr style={{height:'auto', width:'100%', display:'flex' , alignItems:'center', borderBottom:"2px solid #ddd"}}>
		// 						 <th style={{...styles.th_style, padding:"0px 5px",flex:1,paddingLeft:'10px'}}>
		// 								<div className='checkbox_background_hover_before'>
		// 								{
		// 									modal_json && modal_json.data && modal_json.data.length && modal_json.data.filter(x => x.checked).length===modal_json.data.length?
		// 									<i onClick={()=>onclick_all_select_checkbox(false)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='fa fa-check-square-o checkbox_background_hover' />
		// 									:
		// 									modal_json && modal_json.data && modal_json.data.length && modal_json.data.filter(x => x.checked).length===0?
		// 										<i onClick={() =>onclick_all_select_checkbox(true)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='fa fa-square-o checkbox_background_hover' />
		// 										:
		// 										<i onClick={() =>onclick_all_select_checkbox(true)} style={{fontSize:'16px', cursor:"pointer"}} title='Select' className='fa fa-minus-square-o checkbox_background_hover' />
		// 								}
		// 								</div>
		// 							</th>
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:1}}>Sl.No</th> 
		// 							{/* <th style={{...styles.th_style, padding:"0px 5px",flex:2}}><span>Id</span></th> */}
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:4}}>Name</th>
		// 							{/* <th style={{...styles.th_style, padding:"0px 5px",flex:3}}><span>Sales Channel</span></th> */}
		// 							{/* <th style={{...styles.th_style, padding:"0px 5px",flex:3}}><span>Price Type</span></th> */}
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:3}}><span>Old Price<br /><span style={{whiteSpace:'nowrap'}}>(in {modal_json && modal_json.currency?modal_json.currency:'INR'})</span></span></th>
		// 							<th style={{...styles.th_style, padding:"0px 5px",flex:3}}><span>New Price<br /><span style={{whiteSpace:'nowrap'}}>(in {modal_json && modal_json.currency?modal_json.currency:'INR'})</span></span></th>
		// 							{/* <th style={{...styles.th_style, padding:"0px 5px",flex:2}}><span>Mismatch Status</span></th> */}
		// 						</tr>
		// 						</thead>
		// 						<tbody style={{display:"table",width:"100%"}}>
		// 							{
		// 								modal_json && modal_json.data && modal_json.data.map((single_unit,idx) => (
		// 									<Compare_sku_price_single_layer idx={idx} key={idx} single_unit={single_unit} set_modal_json = {set_modal_json} modal_json = {modal_json}/>
		// 								))
		// 							}
		// 						</tbody>
		// 					</table>
		// 				</Col>  
		// 			</Row>
		// 		</CardBody>
		// 		<CardFooter style={{textAlign:'right', backgroundColor:'white'}}>
		// 			<Button onClick={onclick_refresh_skus} className='blue_button'>Refresh Skus</Button>
		// 		</CardFooter>
		// 	</Card>
		// </Modal>
		<Modal className="modal-dialog-centered" size="lg" isOpen={open}>
			<Card className="bg-secondary shadow border-0">
				<IModalHeader toggle={onClick_handleclose}>Check SKU Price Mismatch</IModalHeader>
				<IModalBody style={{ maxHeight: "600px", overflow: "auto" }}>
					<ITable coloumnSeparated={true} rowSeparated={true} striped={true} style_container={{ border: '1px solid var(--light-border-color)', maxHeight: '568px', overflow: 'auto', whiteSpace: 'nowrap' }}>
						<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px' }}>
							<ITH>
								<div className='checkbox_background_hover_before'>
									{
										modal_json && modal_json.data && modal_json.data.length && modal_json.data.filter(x => x.checked).length === modal_json.data.length ?
											<i onClick={() => onclick_all_select_checkbox(false)} style={{ fontSize: '16px', cursor: "pointer" }} title='Select' className='fa fa-check-square-o checkbox_background_hover' />
											:
											modal_json && modal_json.data && modal_json.data.length && modal_json.data.filter(x => x.checked).length === 0 ?
												<i onClick={() => onclick_all_select_checkbox(true)} style={{ fontSize: '16px', cursor: "pointer" }} title='Select' className='fa fa-square-o checkbox_background_hover' />
												:
												<i onClick={() => onclick_all_select_checkbox(true)} style={{ fontSize: '16px', cursor: "pointer" }} title='Select' className='fa fa-minus-square-o checkbox_background_hover' />
									}
								</div>
							</ITH>
							<ITH>Sl.No</ITH>
							{/* <ITH><span>Id</span></ITH> */}
							<ITH>Name</ITH>
							{/* <ITH><span>Sales Channel</span></ITH> */}
							{/* <ITH><span>Price Type</span></ITH> */}
							<ITH><span>Old Price<br /><span style={{ whiteSpace: 'nowrap' }}>(in {modal_json && modal_json.currency ? modal_json.currency : 'INR'})</span></span></ITH>
							<ITH><span>New Price<br /><span style={{ whiteSpace: 'nowrap' }}>(in {modal_json && modal_json.currency ? modal_json.currency : 'INR'})</span></span></ITH>
							{/* <ITH><span>Mismatch Status</span></ITH> */}
						</ITableHeader>
						<ITableBody>
							{
								modal_json && modal_json.data && modal_json.data.map((single_unit, idx) => (
									<Compare_sku_price_single_layer idx={idx} key={idx} single_unit={single_unit} set_modal_json={set_modal_json} modal_json={modal_json} />
								))
							}
						</ITableBody>
					</ITable>
				</IModalBody>
				<IModalFooter>
					<Button onClick={onclick_refresh_skus} className='primary_button_default'>Refresh Skus</Button>
				</IModalFooter>
			</Card>
		</Modal>
	)
}

const Selected_sku_modal = ({modal_json, modal_type, handle_close, handle_ui_response}) => {

	const onclick_sku_image = (id, name, type_id) => {
		//write you code
		console.log(id);
		var response;

		if(name == 'shutter'){
			response = window.Module.replace_shutter_carousel_open(id,type_id);
		}else if(name == 'handle'){
			response = window.Module.replace_handle_carousel_open(id,type_id);
		}else if(name == 'tandem_box' || name == 'basket'){
			response = window.Module.replace_drawer_accessories_carousel_open(id, type_id);
		}
		
		handle_ui_response(response);
	}

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="selected_sku_modal"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Selected SKU's</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white, maxHeight:"500px", overflowY:'auto' }} className="px-lg-5 py-lg-5">
					<Row>
					{
						modal_json && modal_json.length && modal_json.map((item,idx) => (
							<Col key={idx} xs={4}>
								<Card className='on_hover_blur_image' style={{height:'160px', width:'100%', marginBottom:'20px', textAlign:'center', position:'relative'}}>
									<span className='x1' style={{margin:'auto'}}>
										<div className='x2' onClick={() =>onclick_sku_image(item.id,item.name,item.type_id)} style={{height:'160px', width:'100%', backgroundColor:'black', display:"none", alignItems:'center', justifyContent:"center", zIndex:10,color:"white", fontSize:"14px", position:'absolute', top:0, left:0, opacity:.5}}>Click to Change&nbsp;{item&& item.name?item.name:''}</div>
										<img alt='test'  style={{maxHeight:'100%', maxWidth:'100%', display:'pointer', position:'absolute', top:0, left:0,}} src={item.display_pic && item.display_pic.image?(item.display_pic.image):''}/>
									</span>
								</Card>
							</Col>
						))
					}	  
					</Row>
				</CardBody>
			</Card>
		</Modal>
	);
}

const DWG_single_layer = ({single_layer}) => {

	const [checkbox_value, set_checkbox_value] = useState(false)

	useEffect(() => {
		if(single_layer){
			set_checkbox_value(single_layer.visible)
		}
	},[single_layer])

	useEffect(() => {
		single_layer.visible=checkbox_value;
	},[checkbox_value])

	return(
		<div style={{marginBottom:'8px', display:"flex", alignItems:"center"}}>
			<Input style={{position:'unset', margin:0, marginRight:'20px'}} type='checkbox' checked={checkbox_value?true:false}  onChange={() => set_checkbox_value(!checkbox_value)} />
			<span style={{textTransform:'capitalize'}}>{single_layer.name}</span>
		</div>
	)
}

const DWG_layer_modal = ({modal_json, modal_type, handle_close, handle_ui_response}) => {

	const [ dummy_modal_json, set_dummy_modal_json ] = useState([]);

	useEffect(() =>{
		if(modal_type == "dwg_layer_modal" && modal_json && modal_json.dwg_layers && modal_json.dwg_layers.length){
			set_dummy_modal_json(modal_json)
		}
	},[modal_json, modal_type])

	const onclick_apply = () => {
		window.Module.update_dwg_layers(JSON.stringify(dummy_modal_json));
		handle_close();
	}

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={modal_type=="dwg_layer_modal"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>DWG Layers for {dummy_modal_json.floor_name}</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white, minHeight:"300px", maxHeight:"500px", overflowY:'auto', textAlign:'center', padding:'30px' }}>
					{
						dummy_modal_json && dummy_modal_json.dwg_layers && dummy_modal_json.dwg_layers.length ? dummy_modal_json.dwg_layers.map((single_layer,idx) => (
							<DWG_single_layer key={idx} single_layer={single_layer} />
						))
						:
						<div>
							No DWG layers found. 
						</div>
					}	  	
				</CardBody>
				<CardFooter style={{backgroundColor:'white', textAlign:"right"}}>
					<Button onClick={onclick_apply} className='blue_button'>Apply</Button>
				</CardFooter>
			</Card>
		</Modal>
	);
}

const AFW_struct_modal = ({modal_json, modal_type, handle_close, handle_ui_response, update_modal_json, set_page_loader}) => {

	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type==="include_in_quotation_modal" ||modal_type==="show_at_top_level_quotation_modal"}>
			<AFW_Structure modal_json={modal_json} modal_type={modal_type} set_page_loader={set_page_loader} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} />
		</Modal>
	);
}

const Checkbox_input_for_restore_modal = ({unit,col,reRenderModal}) => {

	const [checkbox_value, set_checkbox_value] = useState(false)

	useEffect(() => {
		if(unit){
			set_checkbox_value(unit[col.id])
		}
	},[unit])

	useEffect(() => {
		unit[col.id]=checkbox_value
		reRenderModal()
	},[checkbox_value])

	return(
		<Checkbox checked={checkbox_value}  onChange={() => set_checkbox_value(!checkbox_value)} />
	)
}

// Common Modal for Form Data
const CommonInputFormModal = ({modal_type, modal_json, handle_close, handle_ui_response}) => {
	
	const [formData, setFormData] = useState({});
	const [formFieldType, setFormFieldType] = useState({});

	const handleChange = (e, fieldName) => {
		setFormData({ ...formData, [fieldName]: e.target.value });
	};

	const handleChangeCheckbox = (e, fieldName) => {
		let current_status = formData[fieldName];
		setFormData({ ...formData, [fieldName]: current_status ? false : true});
	}

	const santizeValue = (field) => {
		if(formFieldType[field.id] == 'number'){
			return Number(formData[field.id])
		}
		return formData[field.id]
	}

	const handleSubmit = async(e) => {
		e.preventDefault();
		// Your form submission logic here
		// console.log('Form Data:', formData);
		if(modal_json && modal_json.route){
			for(let i in modal_json.body){
				let field = modal_json.body[i]
				formData[field.id] = santizeValue(field)
			}
			let resp = await window.Promisify(window.Module[modal_json.route](JSON.stringify(formData)))
			handle_ui_response(resp)

		}
	};

	const handleButtonSubmit = async(e, button) => {
		e.preventDefault();
		// Your form submission logic  here
		// console.log('Form Data:', formData);
		if(button && button.route){
			for(let i in modal_json.body){
				let field = modal_json.body[i]
				formData[field.id] = santizeValue(field)
			}
			let resp = await window.Promisify(window.Module[button.route](JSON.stringify(formData)))
			handle_ui_response(resp)

		}
	};

	useEffect(() => {
		if(modal_json && modal_json.body && Array.isArray(modal_json.body)){
			let initialState = {};
			let initialMap = {}
			for(let i in modal_json.body){
				let field = modal_json.body[i]
				initialState[field.id] = field.value
				initialMap[field.id] = field.type
			}
			setFormData(initialState)
			setFormFieldType(initialMap)
		}
	}, [modal_json]);

	const GetFooterForSelectInput = ({field}) => {
		let selectedOptionFooter = field.options && field.options.find(option => option.id === formData[field.id]) && field.options.find(option => option.id === formData[field.id]).footer
		
		return (
			selectedOptionFooter ? <div className='my-2' style={{color: COLORS.gray5}}>{selectedOptionFooter}</div> : '' 
		)
	}


	return (
		<Modal className='modal-dialog-centered' size='md' isOpen={modal_type === 'input_form_modal'}>
			<IModalHeader toggle={handle_close}>{modal_json && modal_json.header ? modal_json.header : 'Enter the following Information'}</IModalHeader>
			<IModalBody style={{padding: '0px'}}>
				{/* <form onSubmit={handleSubmit}> */}
					<div style={{padding: '16px 32px'}}>
				{
					modal_json && modal_json.body && Array.isArray(modal_json.body) ? modal_json.body.map((field, idx) => (
						<div key={field.name} className='mb-3'>
							<label htmlFor={field.name} style={{display: field.type == 'checkbox'?'none':'block', marginBottom: '0.5rem'}}>{field.name}</label>
							{
								field.type === 'text' ? (
									<Input
										type="text"
										id={field.name}
										name={field.name}
										value={formData[field.id]}
										placeholder={field.placeholder || `Enter ${field.name}`}
										onChange={(e) => handleChange(e, field.id)}
									/>
								) : field.type === 'number' ? (
									<Input
										type="number"
										id={field.name}
										name={field.name}
										value={formData[field.id]}
										placeholder={field.placeholder || `Enter ${field.name}`}
										onChange={(e) => handleChange(e, field.id)}
									/>
								) : field.type === 'select' ? (
									<React.Fragment>
										<Input
											type='select'
											id={field.name}
											name={field.name}
											value={formData[field.id]}
											onChange={(e) => handleChange(e, field.id)}
										>
											{field.options &&
												field.options.map((option) => (
													<option key={option.id} value={option.id}>
														{option.name}
													</option>
												))}
										</Input>
										<GetFooterForSelectInput field={field}/>
									</React.Fragment>
									
								) : field.type == 'checkbox' ? (
									<React.Fragment>
										<div style={{display:'flex', alignItems: 'center', gap: '6px'}}>
											<Input
												style={{position: 'unset', marginTop: 'unset', marginLeft: 'unset'}}
												type='checkbox'
												id={field.name}
												name={field.name}
												checked={formData[field.id]}
                                                onChange={(e) => {handleChangeCheckbox(e, field.id)}}
											/>
											<Label style={{fontSize: '12px', marginBottom: 0}}>
											{field.name}
											</Label>
										</div>
									</React.Fragment>
								) : null
							}
							{field && field.footer ? <div className='my-2' style={{color: COLORS.gray5}}>{field.footer}</div> : ''}
						</div>
					))
					:''
				}
				{/*{modal_json && modal_json.footer ? <div className='my-2' style={{color: COLORS.gray5}}>{modal_json.footer}</div> : ''}  */}
				</div>
				<IModalFooter>
					{
					modal_json && modal_json.footer && modal_json.footer.length > 0 ?
					 modal_json.footer.map(button => (
						<Button className={(button.type == undefined || button.type == '' || button.type == 'primary_button_default')?'primary_button_default':button.type} onClick={(e) => handleButtonSubmit(e, button)}>{button.name}</Button>
					 ))
					: <Button type="submit" className='primary_button_default ml-auto' onClick={handleSubmit}>Proceed</Button>
					}
				</IModalFooter>
				{/* </form> */}
			</IModalBody>
		</Modal>
	)
}

// Common Modal for Tablular Data

const CommonTabularDataModal = ({ modal_type, modal_json, handle_close, handle_ui_response, update_modal_json }) => {
	const [dummy_modal_json_map, set_dummy_modal_json_map] = useState({});
	const [tabs, set_tabs] = useState([]);
	const [activeTab, setActiveTab] = useState('');
	const [dummy, set_dummy] = useState({});

	const reRenderModal = () => set_dummy({})

	const onChangeActiveTab = (activeTabId) => {
		setActiveTab(activeTabId)
	}

	const handleButtonSubmit = async(e, button) => {
		e.preventDefault();
		// Your form submission logic here
		// console.log('Form Data:', formData);
		// console.log("data during submission of CommonTabularDataModal", dummy_modal_json_map[activeTab])
		if(button && button.route){
			let resp = await window.Promisify(window.Module[button.route](JSON.stringify(dummy_modal_json_map[activeTab])))
			handle_ui_response(resp)
			update_modal_json()
		}
	};

	const is_indeterminate = (col_id) => {
		let checked_count = dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].units && dummy_modal_json_map[activeTab].units.length > 0 && dummy_modal_json_map[activeTab].units.filter(unit => unit[col_id]).length
		if(dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].units && checked_count && (checked_count <  dummy_modal_json_map[activeTab].units.length)){
			return true
		}

		return false
	}

	const checkAll = (col_id) => {
		let checked_count = dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].units && dummy_modal_json_map[activeTab].units.length > 0 && dummy_modal_json_map[activeTab].units.filter(unit => unit[col_id]).length
		if(dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].units && checked_count && (checked_count === dummy_modal_json_map[activeTab].units.length)){
			return true
		}

		return false
	}

	const onCheckAllChange = (event, col_id) => {
		let temp = dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].units && dummy_modal_json_map[activeTab].units.length > 0 && dummy_modal_json_map[activeTab].units.map(unit => {
			if(event.target.checked){
				unit[col_id] = true
			}else{
				unit[col_id] = false
			}
		})

		set_dummy_modal_json_map(JSON.parse(JSON.stringify(dummy_modal_json_map)))
		// reRenderModal()
	}

	const onclick_handle_close = () => {
		set_dummy_modal_json_map({})
		handle_close()
	}

	useEffect(() => {
		if(modal_json && modal_json.tabs){
			let tab_to_table_map = {}
			let tabs_info = modal_json.tabs.map((tab) => {
				if(tab.id){
					tab_to_table_map[tab.id] = tab
				}
				return {id: tab.id, name: tab.name}
			})
			if(tabs_info && tabs_info.length){
				setActiveTab(tabs_info[0].id)
			}
			set_tabs(tabs_info)
			set_dummy_modal_json_map(tab_to_table_map)
		}
	}, [modal_json]);

	return (
		<Modal isOpen={modal_type === 'common_tablular_data'} size='xl' centered>
			<IModalHeader toggle={onclick_handle_close}>{modal_json && modal_json.modal_heading}</IModalHeader>
			<IModalBody style={{height: '600px', overflow: 'auto'}}>
				<INavBar className='mb-3' tabs={tabs} activeTab={activeTab} handleTabClick={onChangeActiveTab} />
				<ITable coloumnSeparated={true} rowSeparated={true} hover={true} style_container={{ border: '1px solid var(--light-border-color)', maxHeight: '500px', overflow: 'auto', whiteSpace: 'nowrap' }}>
					<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px', zIndex: 1 }}>
						{
							dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].col && dummy_modal_json_map[activeTab].col.length > 0 ?
								dummy_modal_json_map[activeTab].col.map((col, idx) =>
									<ITH key={idx} style={{ textTransform: "capitalize" }}><div className='flex_property'>{col.type === 'checkbox' ? <Checkbox className='mr-2' indeterminate={is_indeterminate(col.id)} checked={checkAll(col.id)} onClick={(e) => { onCheckAllChange(e, col.id) }}></Checkbox> : ''}<div>{col.name}</div></div></ITH>
								)
								: <ITH>No Items</ITH>
						}
					</ITableHeader>
					<ITableBody>
						{
							dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].units && dummy_modal_json_map[activeTab].units.length > 0 && dummy_modal_json_map[activeTab].units.map((unit, idx) => (
								<ITableRow key={idx}>
									{
										dummy_modal_json_map[activeTab].col && dummy_modal_json_map[activeTab].col.length > 0 &&
										dummy_modal_json_map[activeTab].col.map((col, idx) =>
											<ITD key={idx}>
												{col.type === 'checkbox' ? <Checkbox_input_for_restore_modal unit={unit} col={col} reRenderModal={reRenderModal} /> : ''}
												{col.type === 'number' || col.type === 'text' ? unit[col.id] : ''}
												{col.type === 'image' ? <img style={{ color: COLORS.white, maxHeight: '100%', maxWidth: '100%', height: "auto", width: '130px' }} title={unit[col.id] ? unit[col.id] : ''} src={unit[col.id] ? unit[col.id] : "-"} /> : ''}
												{col.type === 'multi_data' ?
													<React.Fragment>
														{
															unit[col.id] && unit[col.id].length && unit[col.id].map((x, idx) => (
																<div key={idx}>{x}</div>
															))
														}
													</React.Fragment>
													: ''
												}
											</ITD>
										)
									}
								</ITableRow>
							))
						}
					</ITableBody>
				</ITable>
			</IModalBody>
			<IModalFooter>
				{
					dummy_modal_json_map[activeTab] && dummy_modal_json_map[activeTab].action_buttons && dummy_modal_json_map[activeTab].action_buttons.length > 0 ?
						dummy_modal_json_map[activeTab].action_buttons.map(button => (
							<Button key={button.id} className={(button.type == undefined || button.type == '' || button.type == 'primary') ? 'primary_button_default' : (button.type + '_button_default')} onClick={(e) => handleButtonSubmit(e, button)}>{button.name}</Button>
						))
						:''
				}
			</IModalFooter>
		</Modal>
	)
}

const ReleaseSection = ({ release, defaultOpen, selected_feature, all_releases, release_to_feature_data, onChangeSelectedFeature }) => {
	const [ open, set_open ] = useState(defaultOpen);
	const toggle = () => set_open((prev) => !prev)

	return (
		<>
			{
				all_releases && all_releases[release] && all_releases[release].id ?
					<div className='inf-mb-4'>
						<div className='flex_property'>
							<div className='inf-py-1 inf-mb-2 cp flex_property' /* id={all_releases && all_releases[release] && all_releases[release].id} */ onClick={toggle} style={{ fontSize: '14px', gap: '8px', color: COLORS.gray5 }}>
								<img height={'10px'} src={!open ? '/resources/icons/triangle-right.svg' : '/resources/icons/triangle-down.svg'} />
								<div>{all_releases && all_releases[release] && all_releases[release].userReleaseDate ? all_releases[release].userReleaseDate.split('/').join(' ') : '-'}</div>
							</div>
						</div>
						<Collapse isOpen={open}>
							{
								release_to_feature_data && release_to_feature_data[release] && release_to_feature_data[release].feature_data && release_to_feature_data[release].feature_data.length ? release_to_feature_data[release].feature_data.map((feature, idx) => (
									<li className='inf-py-1 inf-mb-1 cp lines1_elipsis release_notes_feature_heading' style={{ fontSize: '12px', color: (idx == selected_feature.index && release == selected_feature.release) ? '#0078ff' : '#676878' }} onClick={() => onChangeSelectedFeature(release, idx)} title={feature.heading}> {feature.heading} </li>
								))
									: ''
							}
						</Collapse>
					</div>
					: ''
			}
		</>
	)
}

const ReleaseNotesModal = ({ modal_type, modal_json, handle_close, update_view, handle_ui_response }) => {
	// Define the state variables here, use us for template
	const [ available_releases, set_available_releases ] = useState([]);
	const [ all_releases, set_all_releases ] = useState({});
	// const [ selected_release, set_selected_release ] = useState('');
	// const [ selected_feature_index, set_selected_feature_index ] = useState(0);
	const [ selected_feature, set_selected_feature ] = useState({
		release: '',
		index: 0
	});
	const [ release_to_feature_data, set_release_to_feature_data ] = useState({});
	const [ loader, set_loader ] = useStateCallback(false);
	
	// Define functions here, use fin or afin for template

	const onclick_handle_close = () => {
		handle_close()
		set_available_releases([])
		// set_selected_release('')
		set_selected_feature({
			release: '',
			index: 0
		})
	}

	// const onChangeRelease = (value) => {
	// 	// set_loader(true, () => set_selected_release(e.target.value, () => set_loader(false)))
	// 	set_loader(true, () => set_selected_release(value))
	// 	// set_loader(true)
	// 	// set_selected_release(e.target.value)
	// }

	const onChangeSelectedFeature = (release, idx) => {
		// set_loader(true, () => set_selected_feature_index(idx, () => set_loader(false)))
		
		try{
			send_analytics({category: "Release Note clicked", feature_heading: release_to_feature_data[release].feature_data[idx].heading})
		}catch(err){
			console.error("Could not send analytics", err)
		}

		set_loader(true, () => set_selected_feature({release:release , index:idx}))
		// set_loader(true)
		// set_selected_feature_index(idx)
	}

	// Defines useEffects here, use uef for template
	useEffect(() => {
		set_loader(false)
	}, [selected_feature]);

	// useEffect(() => {
	// 	set_loader(false)
	// 	set_selected_feature_index(0)
	// }, [selected_release]);

	useEffect(() => {
		if(modal_type === 'release_notes_modal' && modal_json){
			set_all_releases(modal_json.all_releases)
			set_release_to_feature_data(modal_json.release_to_feature_map)
			let releases = modal_json.release_to_feature_map && typeof(modal_json.release_to_feature_map) === "object" ? (Object.keys(modal_json.release_to_feature_map)) : []
			releases.sort((a,b) => dateSort(modal_json.all_releases && modal_json.all_releases[a] ? modal_json.all_releases[a] : {} ,modal_json.all_releases && modal_json.all_releases[b] ? modal_json.all_releases[b] : {},"releaseDate"))
			if(releases && releases.length){
				// set_selected_release(releases[0])
				set_selected_feature({release: releases[0], index: 0})
			}
			set_available_releases(releases)
		}
	}, [modal_json]);

	return(
		<Modal isOpen={modal_type === 'release_notes_modal'} size='xl' centered toggle={onclick_handle_close} contentClassName='unsetModalContent' style={{maxWidth: '90vw'}}>
			<IModalBody style={{borderRadius: '16px', padding: '32px'}}>
				<div className='flex_between inf-mb-3'>
					<h5 style={{margin: 0}}>{`New Updates! 🎉`}</h5>
					<div className='flex_property'>
						{/* <Input type='select' value={selected_release} onChange={(e) => onChangeRelease(e.target.value)} style={{width: 'fit-content', fontSize: '12px'}}>
							{available_releases && available_releases.length ? available_releases.map(release => (
								<option key={release} value={release}>{all_releases && all_releases[release] && all_releases[release].userReleaseDate}</option>
							)): ''}
						</Input> */}
						<img className='inf-ml-4' onClick={onclick_handle_close} style={{cursor:'pointer'}} height={'16px'} width={'16px'} src={'/resources/icons/modal_cross.svg'}></img>
					</div>
				</div>
				<div style={{display: 'flex', height: '75vh', fontSize: '16px'}}>
					<div className='inf-mr-2 inf-pr-2' style={{width: '208px', height: '100%', overflow: 'auto'}}>
						{
							available_releases && available_releases.length ? available_releases.map((release, idx) => (
								<ReleaseSection key={idx} defaultOpen={idx < 2} release={release} selected_feature={selected_feature} all_releases={all_releases} release_to_feature_data={release_to_feature_data} onChangeSelectedFeature={onChangeSelectedFeature} />
							))
								: ''
						}
					</div>
					<div className='inf_loading_background' style={{flex: 1, minWidth: 0, overflow: 'hidden', position: 'relative'}}>
						<iframe /* onLoad={(e) => e.target.contentWindow.scrollTo(0, 290)} */ style={{ transform: 'translateY(-290px)', border: 'none', width: '100%', height: 'calc(100% + 290px)', display: loader ? 'none' : '' }} key={(release_to_feature_data && release_to_feature_data[selected_feature.release] && release_to_feature_data[selected_feature.release].feature_data && release_to_feature_data[selected_feature.release].feature_data[selected_feature.index] && release_to_feature_data[selected_feature.release].feature_data[selected_feature.index].help_center_url) + selected_feature.index} src={release_to_feature_data && release_to_feature_data[selected_feature.release] && release_to_feature_data[selected_feature.release].feature_data && release_to_feature_data[selected_feature.release].feature_data[selected_feature.index] && release_to_feature_data[selected_feature.release].feature_data[selected_feature.index].help_center_url} /* src={`https://localdesign.infurnia.com/a.html?target=${release_to_feature_data && release_to_feature_data[selected_release] && release_to_feature_data[selected_release].feature_data && release_to_feature_data[selected_release].feature_data[selected_feature_index] && release_to_feature_data[selected_release].feature_data[selected_feature_index].help_center_url}`} */></iframe>
						{/* <div style={{width: '160px', height: '200px', backgroundColor: 'white', position: 'absolute', bottom: '200px', right: '16px', zIndex: '2', pointerEvents: 'none'}}></div> */}
					</div>
				</div>
			</IModalBody>
		</Modal>
	)
}

const Single_item_for_cutlist_pref = ({item, input_array, set_input_array}) => {

	const [name_editing_mode, set_name_editing_mode] = useState(false);
	const [id, set_id] = useState('')
    const [mode, set_mode] = useState('')
    const [add_edit_custom_field_modal, set_add_edit_custom_field_modal] = useState(false)
	const [custom_name, set_custom_name] = useState('')

	// TODO - name_editing_mode not used. Cleanup
	useEffect(() => {
		if(name_editing_mode){
			let input_name  = document.getElementById('edit_cutlist_pref_name')
			if(input_name){
				input_name.focus()
				input_name.select(0, input_name.value.length)
			}
		}
	}, [name_editing_mode]);

	const checkbox_onchange = (item) => {
		// var cutlist_pref = JSON.parse(JSON.stringify(input_array));
		var field = input_array.find(o => o.id === item.id);

		if(field){
			field.visibility = !field.visibility;
		}
		set_input_array(JSON.parse(JSON.stringify(input_array)));
	}

	const rename_cutlist_pref = (name) => {
		// var cutlist_pref = JSON.parse(JSON.stringify(input_array));
		var field = input_array.find(o => o.id === item.id);

		if(field){
			field.custom_name = name;
		}
		set_input_array(JSON.parse(JSON.stringify(input_array)));
	}

	useEffect(() => {
		set_custom_name(item.custom_name ? item.custom_name : item.name)
	}, [item.custom_name])

    return(
        <div style={{height:'35px', display:'flex', alignItems:'center', width:'100%'}}>
			<div style={{marginRight:'15px', display:'flex', alignItems:'center'}}><i style={{marginRight:'1px'}} className='fa fa-ellipsis-v'/><i className='fa fa-ellipsis-v'/></div>
			<div style={{display:'flex', alignItems:'center', width:'100%'}}>
				<div style={{textTransform:'capitalize', fontSize:'14px', flex:1, minWidth: '10px', flexWrap: 'wrap'}}>{item.name}</div>
				<Input id={'edit_cutlist_pref_name' + item.id} spellCheck={false} className='focus_input mr-2 flex_property' style={{fontSize: '12px', height: '24px', flex: 1, minWidth: '10px'}} value={custom_name} onChange={(e) => {set_custom_name(e.target.value)}} onBlur={(e) => { rename_cutlist_pref(e.target.value); set_name_editing_mode(false) }} onKeyDown={(e) => { if (e.key === 'Enter') {rename_cutlist_pref(e.target.value); set_name_editing_mode(false)} else if (e.key == 'Escape') { set_name_editing_mode(false) } }} />
				<div style={{display: 'flex', justifyContent: 'flex-end', flex: 1, alignItems: 'center'}}>
				{item.hasOwnProperty('formula') || custom_field_ids_cutlist.includes(item.id) ? 
                <i style={{cursor:'pointer', color: "#1070CA" }} onClick={() => {set_id(item.id); set_mode('edit'); set_add_edit_custom_field_modal(true);}} className='fa fa-edit pl-2 pr-2 pt-2 pb-2 mr-1'/> : ''}
					<Switch 
					onChange={() => {checkbox_onchange(item)}} 
					checked={item.visibility}
					uncheckedIcon={false}
					checkedIcon={false}
					offColor='#e6e6e6'
					offHandleColor='#808080'
					onColor='#0066ff'
					onfHandleColor='#b3d1ff'
					height={15}
					width={30}	
					/>
				</div>
				{/* <Input checked={item.checked} onChange={() => {checkbox_onchange(item.id)}} type='checkbox' style={{cursor:'pointer', position:'unset', margin:0, marginRight: '15px'}} /> */}
			</div>
			<Add_Edit_Custom_Field_Modal set_custom_name={set_custom_name} item={item} fields={input_array} set_fields={set_input_array} open1={add_edit_custom_field_modal} handle_close1={() => {set_mode(''); set_id(''); set_add_edit_custom_field_modal(false);}} mode={mode} id={id} custom_cutlist_fields_arr={input_array.filter(item => item.hasOwnProperty('formula') || custom_field_ids_cutlist.includes(item.id))}/>
		</div>
    )
}

const Help_modal = ({open2, close_help_modal}) => {
    const {t} = useTranslation()
    return (
        <Modal size='m' isOpen={open2} toggle={close_help_modal}>
            <IModalHeader toggle={close_help_modal}>Help</IModalHeader>
            <IModalBody>
                <div>
                    <div style={{paddingBottom: '5px', fontWeight: 'bold'}}>{t(`Syntax for Formula:`)}</div>
                    <div style={{paddingBottom: '10px'}}><span style={{color: '#6A1B9A'}}>{"<Variable1>"}</span> <span style={{color: '#0000FF'}}>+</span>  <span style={{color: '#008080'}}>'constant'</span> <span style={{color: '#0000FF'}}>+</span>  <span style={{color: '#6A1B9A'}}>{"<Variable2>"}</span> + ...</div>
                    <div style={{marginBottom: '5px', paddingBottom: '15px'}}>{t(`Variables are Infurnia defined fields and can be selected from suggestions given when entering formula. Constants are user-defined values and should be enclosed in single or double quotes`)}</div>
                    <div style={{display: 'flex', alignSelf: 'center', gap: '10px'}}>
                        <div style={{flex: 1, alignSelf:'start', fontWeight: 'bold'}}>
                            Example: 
                        </div>
                        <div style={{paddingLeft: '10px'}}>
                            <div><span style={{color: '#6A1B9A'}}>core_material_sku_name</span> <span style={{color: '#0000FF'}}>+</span> <span style={{color: '#008080'}}>'_'</span> <span style={{color: '#0000FF'}}>+</span> </div> 
                            <div><span style={{color: '#6A1B9A'}}>internal_finish_sku_name</span> <span style={{color: '#0000FF'}}>+</span> <span style={{color: '#008080'}}>'_'</span> <span style={{color: '#0000FF'}}>+</span> <span style={{color: '#6A1B9A'}}>external_finish_model_no</span> <span style={{color: '#0000FF'}}>+</span> </div> 
                            <div><span style={{color: '#6A1B9A'}}>internal_finish_sku_name</span></div> 
                        </div>
                    </div>
                </div>
            </IModalBody>
            <IModalFooter>
                <Button className="blue_button" onClick={close_help_modal}>OK</Button>
            </IModalFooter>
        </Modal>
    )
}

const Add_Edit_Custom_Field_Modal = ({set_custom_name, item, fields, set_fields, open1, handle_close1, mode = 'edit', custom_cutlist_fields_arr, id = ''}) => {
    const [field_id, set_field_id] = useState('')
    const [display_name, set_display_name] = useState('')
    const [formula, set_formula] = useState('')
    const [custom_cutlist_fields, set_custom_cutlist_fields] = useState({})
    const [help_modal, set_help_modal] = useState(false)
    const [preventBlur, set_preventBlur] = useState({is_valid: false});
    const alert = useAlert()
    const [display_suggestions, set_display_suggestions] = useState(false);
    const {t} = useTranslation()

    useEffect(() => {
        let originalObject = {};
        let temp = JSON.parse(JSON.stringify(custom_cutlist_fields_arr))
        let garb1 = temp.map(obj => {
            let { id, ...rest } = obj;
            originalObject[id] = rest;
        });
        set_custom_cutlist_fields(originalObject)
    }, [custom_cutlist_fields_arr])

    const close_modal = () => {
        handle_close1()
        set_field_id('')
        set_display_name('')
        set_formula('')
    }

    const close_help_modal = () => {
        set_help_modal(false);
    }

    function isAlphabet(character) {
        const regex = /^[A-Za-z]$/
        return regex.test(character)
    }

    function isAlphanumeric(character) {
        const regex = /^[a-zA-Z0-9]$/
        return regex.test(character)
    }

    function isSpecialSymbol(character) {
        return character == '_' || character == ':' || character == '$'
    }
    
    const isValidVariableName = (name) => {
        if(name.trim().length == 0) 
            return false
        if(!(isAlphabet(name[0]) || isSpecialSymbol(name[0])))
            return false
        for(let i = 1; i < name.length; i++){
            if(!(isAlphanumeric(name[i]) || isSpecialSymbol(name[i])))
                return false
        }
        return true
    }

	function splitIgnoringQuotes(inputString) {
		const parts = [];
		let currentPart = '';
		let singleQuotes = false;
		for (let i = 0; i < inputString.length; i++) {
			const char = inputString[i];
			if (char === "'" || char === '"') {
					singleQuotes = !singleQuotes;
				currentPart += char;
			} else if (char === '+' && !singleQuotes) {
				parts.push(currentPart.trim());
				currentPart = '';
			} else {
				currentPart += char;
			}
		}
		parts.push(currentPart.trim());
		return parts;
	}

	const validateString = (exp) => {
		let cnt = 0;
		for(let i=0; i<exp.length; i++){
			if(exp[i] == "'" || exp[i] == '"')
				cnt++;
		}
		return cnt == 2;
	}


    const validate = (exp) => {
		if(exp == '' || exp.length == 0) return true
        let operands = splitIgnoringQuotes(exp)
        for(let i=0; i<operands.length; i++){
            let temp = operands[i].trim()
            if(temp.length > 1 && (temp[0] == '\'' || temp[0] == '\"') && (temp[temp.length - 1] == '\'' || temp[temp.length - 1] == '\"') && validateString(temp))
                continue;
            else if(isValidVariableName(temp) && custom_cutlist_variables.includes(temp))
                continue;
            return false;
        }
        return true
    }

    function replaceSingleQuotes(str) {
        return str.replace(/'/g, "\\'");
    }

    function replaceEscapedQuotes(str) {
        return str.replace(/\\'/g, "'");
    }
    function replaceDoubleQuotesWithSingleQuotes(str) {
        return str.replace(/"/g, "'");
    }
    

    // function requestBody() {
    //     return [{designing_preference_id: fields[id]['formula']['id'], value: replaceDoubleQuotesWithSingleQuotes(formula)}, {designing_preference_id: fields[id]['custom_name']['id'], value: display_name ? display_name : (id ? (custom_cutlist_fields[id]["name"] ? custom_cutlist_fields[id]["name"] : id) : id)}]
    // }

    const submit_fields = async () => {
        try {
			const temp_fields = JSON.parse(JSON.stringify(fields))
			const cust_field = temp_fields.find(item => item.id == id)
			if(cust_field){
				cust_field.custom_name = display_name ? display_name : (id ? (custom_cutlist_fields[id]["custom_name"] ? custom_cutlist_fields[id]["custom_name"] : '') : id)
				cust_field.formula = replaceDoubleQuotesWithSingleQuotes(formula)
			}
			set_fields(temp_fields)
			set_custom_name(display_name ? display_name : (id ? (custom_cutlist_fields[id]["custom_name"] ? custom_cutlist_fields[id]["custom_name"] : '') : id));
			handle_close1();
        } catch(err) {
            console.log(err);
        }
    }

    const handle_submit = async () => {
        if(!validate(formula)){
            alert.error('Formula is not valid')
            return
        }
        submit_fields(); 
        // console.log(requestBody())
    }

    useEffect(() => {
        if(open1){
            set_formula(id ? (custom_cutlist_fields[id]["formula"] ? custom_cutlist_fields[id]["formula"] : '')  : '')
        }
    }, [open1])


    const onMouseDownSuggestion = (event, suggestion) => {
		preventBlur.is_valid = true; 
		event.stopPropagation(); 
		handleSuggestionClick(suggestion)
        set_display_suggestions(false)
	}

    const handleSuggestionClick = (suggestion) => {
		if(document.getElementById('formula_input')){
			// document.getElementById('formula_input').value = value + suggestion
            set_formula(formula.substring(0, formula.length - getSuffix(formula).length) + suggestion);
		}
		// set_suggestions([]);
	};

    function getSuffix(str) {
        let suffix = '';
        for (let i = str.length - 1; i >= 0; i--) {
            const char = str[i];
            if (char === '+' || /\s/.test(char)) {
                break; // Stop traversing when encountering '+' or whitespace
            }
            suffix = char + suffix; // Prepend the character to the suffix
        }
        return suffix;
    }

    const inputOnBlur = (event) => {
		if (preventBlur.is_valid) {
			event.preventDefault(); 
			event.target.focus()
			const inputLength = event.target.value.length;
    		event.target.setSelectionRange(inputLength, inputLength);
			preventBlur.is_valid = false
            set_display_suggestions(true)
		} else {
            set_display_suggestions(false)
		}
	}

	const handleFocus = (event) => {
        if((formula == '' || formula.length==0))
            set_display_suggestions(true)
    }

    useEffect(() => {
		if(formula)
        	set_display_suggestions(custom_cutlist_variables.filter(item => item.includes(getSuffix(formula)) && item != getSuffix(formula)).length > 0)
        // console.log("suffix", getSuffix(formula), custom_cutlist_variables, custom_cutlist_variables.filter(item => item.includes(getSuffix(formula)) && item != getSuffix(formula)).length > 0)
    }, [formula])

	useEffect(() => {
		if(open1)
			set_display_name(item.custom_name ? item.custom_name : item.name)
	}, [item.custom_name, open1])


    return (
        <Modal size='m' isOpen={open1} toggle={close_modal}>
            <IModalHeader toggle={close_modal}>Edit {id ? (custom_cutlist_fields[id]["name"] ? custom_cutlist_fields[id]["name"] : id) : id}</IModalHeader>
            <IModalBody>
                {/* <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '15px'}}>
                    <div>Default Name</div>
                    <Input disabled style={{opacity: '50%', maxWidth: '60%'}} type='text' value={id ? (custom_cutlist_fields[id]["name"] ? custom_cutlist_fields[id]["name"] : id) : id} />
                </div> */}
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '15px'}}>
                    <div>{t(`Display Name`)}</div>
                    <Input style={{maxWidth: '60%'}} type='text' value={display_name} onChange={e => set_display_name(e.target.value)} />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative'}}>
                    <div>{t(`Formula`)}</div>
                    <Input id='formula_input' style={{maxWidth: '60%', border: validate(formula)? '' : '1px solid red'}} placeholder='Enter a formula' type='text' value={id ? formula : ''} onChange={e => {set_formula(e.target.value)}} onBlur={inputOnBlur} onFocus={handleFocus}/>
                    {custom_cutlist_variables && custom_cutlist_variables.filter(item => item.includes(getSuffix(formula)) && item != getSuffix(formula)).length > 0 && (
                    <div className="suggestion-list-cutlist" id="suggestion-list-cutlist" style={{display: display_suggestions ? '' : 'none', zIndex: 3, background: 'white'}}>
                        {custom_cutlist_variables.filter(item => item.includes(getSuffix(formula)) && item != getSuffix(formula)).map((suggestion, index) => (
                            <div className='single-suggestion-cutlist' key={index} onMouseDown={(event) => {onMouseDownSuggestion(event, suggestion)}} /*onClick={() => {handleSuggestionClick(suggestion)}}*/>
                                {suggestion}
                            </div>
                        ))}
                    </div>
                )}
                    {/* <TextInput style={{maxWidth: '60%', border: validate(formula)? '' : '1px solid red'}} options={custom_cutlist_variables} trigger={["+"]} placeholder='Enter a formula' type='text' value={id ? formula : ''} onChange={e => set_formula(e.target.value)}/> */}
                </div>
            <Help_modal open2={help_modal} close_help_modal={close_help_modal}/>
            </IModalBody>
            <IModalFooter>
                <Button className="blue_button" onClick={() => {set_help_modal(true)}}>Help</Button>
                <Button className="blue_button" onClick={handle_submit}>OK</Button>
            </IModalFooter>
        </Modal>
    )
}

const Boolean_switcher = ({init, onchange_callback, title, isLast=false}) => {

	const [isChecked, set_isChecked] = useState(false)

	useEffect(() => {
		set_isChecked(init);
	}, [])

	return (
	<div style={{fontWeight: 'bold', fontSize: '14px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: isLast ? '' : '1px solid lightgrey', padding: '8px 20px'}}>
		<div>{title}</div>
		<div style={{minHeight: '32px', display: 'flex', alignItems: 'center'}}>
			<Switch 
			onChange={() => {onchange_callback(); set_isChecked(!isChecked)}} 
			checked={isChecked}
			uncheckedIcon={false}
			checkedIcon={false}
			offColor='#e6e6e6'
			offHandleColor='#808080'
			onColor='#0066ff'
			onfHandleColor='#b3d1ff'
			height={15}
			width={30}	
			/>
		</div>
	</div>
	)
}

const Select_option = ({init, options, onchange_callback, title, isLast=false}) => {
	const [sel_val, set_sel_val] = useState('');

	useEffect(() => {
		set_sel_val(init);
	}, [])

	return (
		<div style={{fontWeight: 'bold', fontSize: '14px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: isLast?'':'1px solid lightgrey', padding: '8px 20px'}}>
			<div>{title}</div>
			<div className='createcard_input_container' style={{height: '32px', minWidth: '20%', }}>
			<select value={sel_val} onChange={(e) => {onchange_callback(e.target.value); set_sel_val(e.target.val)}} style={{width: '100%', height: '100%', border: 'none', outline: 'none'}}>
				{options.map(option => (
				<option value={option.value}>{option.name}</option>
				))}
			</select>
			</div>
		</div>
	)
}

const Cutlist_pref_modal_leaf = ({cutlist_fields, dummy_modal_json, show_cutlist_pref_modal, close_cutlist_pref_modal, handle_ui_response, modal_type, modal_json, handle_close, update_view, key_name, name}) => {
	// const [dummy_modal_json, set_dummy_modal_json] = useState()
	const [fields, set_fields] = useState([])
	const [fields_json, set_fields_json] = useState({})
	const [dim_options, set_dim_options] = useState([{value: '1_mm', name: '1_mm'}, {value: '0.01_mm', name: '0.01_mm'}, {value: '0.1_mm', name: '0.1_mm'}])
    const {t} = useTranslation()

	const replaceAndCapitalize = (s) => {
		return s.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
	}

	useEffect(() => {
		set_fields( Object.entries(cutlist_fields.fields).map(([id, values]) => ({ id, ...values })).sort((a, b) => a.order - b.order))
	}, [])

	useEffect(() =>{
		if(fields){
			let temp = JSON.parse(JSON.stringify(fields))
			let garb = temp.map((item, idx) => item.order = idx + 1)
			let originalObject = {};
			let garb1 = temp.map(obj => {
				let { id, ...rest } = obj;
				originalObject[id] = rest;
			});
			cutlist_fields.fields = originalObject
		}
		
	}, [fields])

	return (
		<UncontrolledCollapse header={name} defaultState={false} style_header={{backgroundColor: COLORS.gray3, fontSize: '14px', borderBottom: '1px solid lightgrey', textTransform: 'capitalize'}}>
			<div style={{display: 'flex', flexDirection: 'column'}}>
				<UncontrolledCollapse header={"Fields"} defaultState={false} style_header={{backgroundColor: COLORS.gray3, fontSize: '14px', borderBottom: '1px solid lightgrey'}}>
				<IModalBody style={{height: '600px', overflowY: 'auto'}}>
					<div style={{display:'flex', alignItems:'center', width:'100%', height: '40px'}}>
								{/* <div style={{backgroundColor: 'white', paddingRight:'15px', display:'flex', alignItems:'center'}}></div> */}
						<div style={{paddingLeft: '28px', backgroundColor: 'white', display:'flex', alignItems:'center', width:'100%', height: '25px'}}>
							<div style={{textTransform:'capitalize', fontWeight: 'bold', fontSize:'14px', flex:1, minWidth: '10px', flexWrap: 'wrap'}}>{t(`Default Name`)}</div>
							<div style={{fontSize: '14px', fontWeight: 'bold', height: '21px', flex: 1, minWidth: '10px'}}>{t(`Name`)}</div>
							<div style={{display: 'flex', fontWeight: 'bold', justifyContent: 'flex-end', flex: 1, paddingRight: '12px'}}>
								Visibility
							</div>
						{/* <Input checked={item.checked} onChange={() => {checkbox_onchange(item.id)}} type='checkbox' style={{cursor:'pointer', position:'unset', margin:0, marginRight:'15px'}} /> */}
						</div>
					</div>
					<div style={{ width: '100%', height: 'calc(100% - 40px)', overflowY: 'auto'}}>
						<DragDropComponent style={{paddingBottom: '3px'}} items_from_backened={fields} set_items_from_backened={set_fields} modal_name='cutlist_pref' Single_component={Single_item_for_cutlist_pref} />
					</div>
				</IModalBody>
				</UncontrolledCollapse>
				{Object.keys(cutlist_fields).map((item, idx)  => (
					item  == 'dimension_accuracy' ?
					<Select_option init={cutlist_fields[item]} options={dim_options} onchange_callback={val => cutlist_fields[item] = val} title={replaceAndCapitalize(item)} isLast={idx==Object.keys(cutlist_fields).length-1}/> :
					['include_project_information', 'include_store_information'].includes(item) ?
					<Boolean_switcher init={cutlist_fields[item]} onchange_callback={() => cutlist_fields[item] = !cutlist_fields[item]} title={replaceAndCapitalize(item)} isLast={idx==Object.keys(cutlist_fields).length-1}/> : ''
				))
				}
			</div>
		</UncontrolledCollapse>
	)
}

const Cutlist_pref_modal_parent = ({parentPreference, dummy_modal_json, show_cutlist_pref_modal, close_cutlist_pref_modal, handle_ui_response, modal_type, modal_json, handle_close, update_view, key_name='', isCollapsable = true}) => {
	// const [cutlist_arr, set_cutlist_arr] = useState([])

	return (<UncontrolledCollapse header={key_name} defaultState={(key_name=='Cutlist Preferences')?true:false} style_header={{backgroundColor: COLORS.gray3, fontSize: '14px', borderBottom: '1px solid lightgrey'}}>
					{
						parentPreference && Object.keys(parentPreference) ? Object.keys(parentPreference).map((preference) => (
							parentPreference[preference].fields ?
								<Cutlist_pref_modal_leaf cutlist_fields={parentPreference[preference]} dummy_modal_json={dummy_modal_json} name={preference} key_name={key_name ? key_name + '>' + preference : preference} />
								:
								<Cutlist_pref_modal_parent parentPreference={parentPreference[preference]} dummy_modal_json={dummy_modal_json} name={preference} key_name={key_name ? key_name + '>' + preference : preference} />
						))
							: ''
					}
			</UncontrolledCollapse>
	)
}

const Cutlist_pref_modal = ({handle_ui_response, modal_type, modal_json, handle_close, update_view, submit_for_delete_confirm}) => {
	const [dummy_modal_json, set_dummy_modal_json] = useState('')
	const [root_fields, set_root_fields] = useState('')

	useEffect(() => {
		// console.log("kp2", modal_json)
		if(modal_type == "cutlist_pref_modal" && modal_json && modal_json["cutlist_preferences"]){
			set_dummy_modal_json(JSON.parse(JSON.stringify(modal_json)))
			try{
				set_root_fields(JSON.parse(JSON.stringify(modal_json["cutlist_preferences"])))
			}
			catch(err){
				console.error(err)
			}
		}
	}, [modal_json, modal_type])

	const on_submit = async () => {
		var resp = await window.Promisify(window.Module[modal_json.route](JSON.stringify(root_fields)));
		handle_ui_response(resp);
		handle_on_close();

		// set_fields_json(originalObject)
		// close_cutlist_pref_modal();

	}

	const on_refresh = async () => {
		try{
			let resp = await window.Promisify(window.Module["refresh_cutlist_settings_from_admin"](''))
			handle_ui_response(resp)
			handle_close()
		}catch(err){
			console.log(err)
		}
	}

	const handle_on_close = () => {
		set_dummy_modal_json('')
		set_root_fields('')
		handle_close()
	}

	return (
		<Modal size='xl' isOpen={modal_type == 'cutlist_pref_modal'}>
			<IModalHeader toggle={() => {submit_for_delete_confirm(handle_on_close, () => {}, 'Any changes made will be lost. Are you sure you want to proceed?', {name: 'Proceed'});}}>
				Cutlist Settings
			</IModalHeader>

			<IModalBody style={{height: '600px', overflow: 'auto'}}>
				<Cutlist_pref_modal_parent parentPreference={root_fields} dummy_modal_json={dummy_modal_json} key_name='Cutlist Preferences'/>
			</IModalBody>

			<IModalFooter>
				<Button onClick={() => {on_refresh()}} className='secondary_button_default mr-auto'>Refresh From Admin</Button>
				<Button onClick={() => {on_submit()}} className='primary_button_default'>Save Preferences</Button>
			</IModalFooter>
		</Modal>
	)
}

const Hide_Restore_modal = ({  handle_ui_response, modal_type, handle_close, modal_json, update_view }) => {

	const styles={
		td_style:{
			width:"100%",
			height:'133px',
			overflowWrap: 'anywhere',
			display:'flex',
			alignItems:'center',
			justifyContent:'center',
			padding:"0px 5px",
			border:'1px solid black',
			margin:'auto',
		},
		th_style:{
			width:"100%",
			padding:"0px 5px",
			height:'50px',
			border:'1px solid black',
			display:'flex',
			alignItems:'center',
			justifyContent:"center"
		},
		multiple_entry:{
			padding:"5px",
			fontSize:"12px"
		}
	}

	const [ dummy_modal_json, set_dummy_modal_json ] = useState([]);

	useEffect(() =>{
		if((modal_type==="pricing_quotation_hide_restore_modal" || modal_type==="boq_hide_restore_modal" || modal_type == "cut_list_modal") && modal_json){
			set_dummy_modal_json(modal_json)
		}
	},[modal_json, modal_type])

	const onclick_apply_manufacturing = async () => {
		onclick_handle_close()
		var selected_value_ids=[]
		if(dummy_modal_json && dummy_modal_json.units && dummy_modal_json.units.length){
			selected_value_ids = dummy_modal_json.units.filter(x=>x.restore).map(x=>x.id)
		}
		if(modal_type == "cut_list_modal"){
			var response = await window.Promisify(window.Module.manufacturing_cutlist_csv(""));
			handle_ui_response(response);
		}else{
			window.Module.restore_hidden_element(JSON.stringify(selected_value_ids),modal_type)
		}
	}

	const cutlist_onclick_apply = async (e, button) => {
		onclick_handle_close()
		let resp = await window.Promisify(window.Module[button.route](JSON.stringify(modal_json)))
		handle_ui_response(resp)
	}	

	const onclick_apply = async () => {
		onclick_handle_close()
		var selected_value_ids=[]
		if(dummy_modal_json && dummy_modal_json.units && dummy_modal_json.units.length){
			selected_value_ids = dummy_modal_json.units.filter(x=>x.restore).map(x=>x.id)
		}
		if(modal_type == "cut_list_modal"){
			var response = await window.Promisify(window.Module.cutListExportCsv(JSON.stringify(modal_json)));
			handle_ui_response(response);
		}else{
			window.Module.restore_hidden_element(JSON.stringify(selected_value_ids),modal_type)
		}
	}

	const onclick_handle_close = () =>{
		set_dummy_modal_json();
		handle_close()
	}

	const onclick_generate_board_layout = () => {
		onclick_handle_close()
		if(window.debug_mode){
			window.add_debug_log("enter_board_layout_mode(\"" + JSON.stringify(modal_json).replace(/"/g, '\\"') + "\");");
		}
		window.Module.enter_board_layout_mode(JSON.stringify(modal_json));
		update_view()
	}

	const onclick_open_cutlist_preferences = async () => {
		var resp = await window.Promisify(window.Module.open_cutlist_preferences_modal(""))
		handle_ui_response(resp)
	}

	const is_indeterminate = (col_id) => {
		let checked_count = dummy_modal_json && dummy_modal_json.units && dummy_modal_json.units.length > 0 && dummy_modal_json.units.filter(unit => unit[col_id]).length
		if(dummy_modal_json && dummy_modal_json.units && checked_count && (checked_count <  dummy_modal_json.units.length)){
			return true
		}

		return false
	}

	const checkAll = (col_id) => {
		let checked_count = dummy_modal_json && dummy_modal_json.units && dummy_modal_json.units.length > 0 && dummy_modal_json.units.filter(unit => unit[col_id]).length
		if(dummy_modal_json && dummy_modal_json.units && checked_count && (checked_count === dummy_modal_json.units.length)){
			return true
		}

		return false
	}

	const onCheckAllChange = (event, col_id) => {
		let temp = dummy_modal_json && dummy_modal_json.units && dummy_modal_json.units.length > 0 && dummy_modal_json.units.map(unit => {
			if(event.target.checked){
				unit[col_id] = true
			}else{
				unit[col_id] = false
			}
		})

		set_dummy_modal_json(JSON.parse(JSON.stringify(dummy_modal_json)))
	}

	const reRenderModal = () => {
		set_dummy_modal_json(JSON.parse(JSON.stringify(dummy_modal_json)))
	}

	const handleCoreLibFn = async(route, form) => {
		let resp = await window.Promisify(window.Module[route](form))
		handle_ui_response(resp)
	}

	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type === "pricing_quotation_hide_restore_modal" || modal_type === "boq_hide_restore_modal" || modal_type == "cut_list_modal"}>
			<IModalHeader toggle={onclick_handle_close}>{modal_type === 'cut_list_modal' ? 'Cutlist Modal' : 'Hidden Elements'}</IModalHeader>
			<IModalBody style={{ maxHeight: '600px', overflow: "auto" }}>
				{
					dummy_modal_json && dummy_modal_json.table_type ?
					 <div className='mb-2' style={{display: 'flex', alignItems: 'center'}}>
						<div className='mr-3'>{dummy_modal_json.table_type.name}</div>
						<Input type={dummy_modal_json.table_type.type} defaultValue={dummy_modal_json.table_type.value} onChange={(e) => {dummy_modal_json.table_type.route ? handleCoreLibFn(dummy_modal_json.table_type.route, e.target.value) : null}} style={{fontSize: '14px', flex: 1, minWidth: '40px'}}>
							{
								dummy_modal_json.table_type.type === 'select' && dummy_modal_json.table_type.options ? dummy_modal_json.table_type.options.map((option, idx) => (
									<option key={idx} value={option.id}> {option.name} </option>
								))
								:''
							}
						</Input>
					 </div>

					:''
				}
				<ITable coloumnSeparated={true} rowSeparated={true} hover={true} style_container={{ border: '1px solid var(--light-border-color)', maxHeight: '568px', overflow: 'auto', whiteSpace: 'nowrap' }}>
					<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px', zIndex: 1 }}>
						{
							dummy_modal_json && dummy_modal_json.col && dummy_modal_json.col.length > 0 ?
								dummy_modal_json.col.map((col, idx) =>
									<ITH key={idx} style={{ textTransform: "capitalize" }}><div className='flex_property'>{col.type === 'checkbox' ? <Checkbox className='mr-2' indeterminate={is_indeterminate(col.id)} checked={checkAll(col.id)} onClick={(e) => {onCheckAllChange(e, col.id)}}></Checkbox> : ''}<div>{col.name}</div></div></ITH>
								)
								: <ITH>No Items</ITH>
						}
					</ITableHeader>
					<ITableBody>
						{
							dummy_modal_json && dummy_modal_json.units && dummy_modal_json.units.length > 0 && dummy_modal_json.units.map((unit, idx) => (
								<ITableRow key={idx}>
									{
										dummy_modal_json.col && dummy_modal_json.col.length > 0 &&
										dummy_modal_json.col.map((col, idx) =>
											<ITD key={idx}>
												{col.type === 'checkbox' ? <Checkbox_input_for_restore_modal unit={unit} col={col} reRenderModal={reRenderModal}/> : ''}
												{col.type === 'number' || col.type === 'text' ? unit[col.id] : ''}
												{col.type === 'image' ? <img style={{ color: COLORS.white, maxHeight: '100%', maxWidth: '100%', height: "auto", width: '130px' }} title={unit[col.id] ? unit[col.id] : ''} src={unit[col.id] ? unit[col.id] : "-"} /> : ''}
												{col.type === 'multi_data' ?
													<React.Fragment>
														{
															unit[col.id] && unit[col.id].length && unit[col.id].map((x, idx) => (
																<div key={idx}>{x}</div>
															))
														}
													</React.Fragment>
													: ''
												}
											</ITD>
										)
									}
								</ITableRow>
							))
						}
					</ITableBody>
				</ITable>
			</IModalBody>
			<IModalFooter>
				{/* {modal_type === 'cut_list_modal' ? <Button onClick={onclick_apply_manufacturing} className='primary_button_default'>{modal_type === 'cut_list_modal' ? 'Download Biesse CSV' : ''}</Button> : ''} */}
				{modal_json && modal_json.footer && modal_json.footer.length > 0 && modal_type === 'cut_list_modal' ?
					 modal_json.footer.map(button => (
						<Button className={(button.type == undefined || button.type == '' || button.type == 'primary_button_default')?'primary_button_default':button.type} onClick={(e) => cutlist_onclick_apply(e, button)}>{button.name}</Button>
					 )) :
				<>
					{modal_type === 'cut_list_modal' ? <Button onClick={onclick_open_cutlist_preferences} className='primary_button_default'>Cutlist Settings</Button> : ''}
					{modal_type === 'cut_list_modal' ? <Button onClick={onclick_generate_board_layout} className='primary_button_default'>Generate Board Layout</Button> : ''}
					<Button onClick={onclick_apply} className='primary_button_default'>{modal_type === 'cut_list_modal' ? 'Download CSV' : 'Restore'}</Button>
				</>
				}
			</IModalFooter>
		</Modal>
	)    
}

const Single_Category  = ({ sku_category, user, modal_json}) => {	

	const styles = {
		text_elipsis: {
			 whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
		},
	}
	const [ is_category_selected, set_is_category_selected ] = useState(false);

	useEffect(() => {
		set_is_category_selected(sku_category.checked);
	}, [ sku_category ])

	const onclick_checkbox = () => {
		set_is_category_selected(!is_category_selected)
		sku_category.checked=!sku_category.checked;
	}

	return(
		<Col xs={12} sm={6} md={4} lg={3} xl={3} style={{padding:'10px', height:'100%'}}>
             <div style={{padding:'10px', height:'auto', border: '1px solid #D5DAE0', fontSize:'12px', backgroundColor: '#F8F9FA', borderRadius: '4px 4px 0 0', display:'flex', alignItems:'center' }}>
				 <Input style={{margin:"0px"}} checked={is_category_selected} onChange={onclick_checkbox} type='checkbox'/>
                 <span style={{textTransform:'capitalize',paddingLeft:'24px', ...styles.text_elipsis}}>{sku_category&&sku_category.name ? sku_category.name : ''}</span>
             </div>
 		</Col>
	)
}

const Single_division = ({ division, user, modal_json}) => {
    
	const [	show_collapse, set_show_collapse] = useState(false)
	const [ categories, set_categories ] = useState([]);
    const {t} = useTranslation()

	const open_close_collapse = (division) => {
		if(division){
			set_show_collapse(!show_collapse)
			set_categories(division.categories)
		}
	}

    return(
        <div>
			<div onClick={()=> open_close_collapse(division)} style={{height:'50px', padding:'0px 20px', display:'flex', alignItems:'center', justifyContent:'space-between', backgroundColor: '#FAFAFA', boxShadow: 'inset 0 -1px 0 0 #E4E7EB', cursor: 'pointer',color:'#234361'}}>
				<span style={{textTransform:'capitalize'}}>{division.name}</span>
				<i className={show_collapse?'fa fa-caret-up':'fa fa-caret-down'}/>
			</div>
			<div >
				<Row style={{ display:show_collapse?'flex':'none',alignItems:'center',margin:0, width:'100%'}}>
				{
					categories && categories.length ? categories.map((sku_category, idx) => (
						<Single_Category key={idx} sku_category={sku_category} user={user} modal_json={modal_json}/>
					)):
					<div style={{padding:'20px'}}>{t(`No Category`)}</div>
				}
				</Row>
			</div>
		</div>
    )
}

const BOQ_schedule_modal = ({ modal_json, modal_type ,user, set_page_loader, handle_close, is_filter_modal, update_modal_json, handle_ui_response}) => {
	
	//State variables
	const alert = useAlert();
	const [selected_category, set_selected_category] = useState([]);
	const [modal_json_modified, set_modal_json_modified] = useState([]);

	//Member functions

	//Effects

	const on_click_handleclose = () => {
		handle_close()
	}

	useEffect(()=>{
		if(modal_json && modal_json.length && modal_type == "boq_schedule"){
			var x_mod = modal_json && modal_json.length ?modal_json.reduce((final,elem) => [...final, ...elem.categories.map(y=>y)], []):[];
			var x_sel = modal_json && modal_json.length ?modal_json.reduce((final,elem) => [...final, ...elem.categories.filter(y=>y.checked)], []):[];
			set_modal_json_modified(x_mod)
			set_selected_category(x_sel)
		}
	},[modal_json, modal_type])

	const onclick_apply_changes = () => {
		var sel_ids = selected_category.map(x=>x.id)
		var cat_array = modal_json && modal_json.length ?modal_json.reduce((final,elem) => [...final, ...elem.categories.map(y=>y)], []):[];
		var result = cat_array.map(x => {
			if(sel_ids.includes(x.id)){
				x.checked=true;
			}else{
				x.checked=false;
			}
			return x;
		})
		window.Module.show_boq_fields_modal(JSON.stringify(result))
		update_modal_json()
	}

	const onselect_category_checkbox = (selectedList) => {
		// console.log(modal_json_modified)
		set_selected_category(selectedList)
	}

	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type=="boq_schedule"}>
			<IModalHeader toggle={on_click_handleclose}>Select Schedule</IModalHeader>
			<IModalBody style={{ height:'600px', overflow: 'auto'}}>	
				<Multiselect
					groupBy='division_id'
					options={modal_json_modified} 
					selectedValues={selected_category} 
					onSelect={onselect_category_checkbox} 
					onRemove={onselect_category_checkbox} 
					displayValue="name" // Property name 
					showCheckbox
					closeOnSelect={false}
					showArrow={true}
					closeIcon='cancel'
					style={{
						chips: {
						  background: '#0078ff',
						  borderRadius: '4px'
						},
						multiselectContainer: {
						  display: 'block'
						},
						searchBox: {
						  borderRadius: '0px'
						},
						groupHeading: {
							textTransform: 'capitalize',
							fontSize: '16px',
							fontWeight: 500,
						}
					}}
				/>
			</IModalBody>
			<IModalFooter>
				<Button onClick={onclick_apply_changes} className='primary_button_default' >Apply</Button>		
			</IModalFooter>
		</Modal>

	)
}

const Single_item_for_quotation_setting = ({ item, checkbox_onchange }) => {
    return(
        <div className='px-3 cp' onClick={(e) => {e.stopPropagation(); checkbox_onchange(item.id)}} style={{height:'50px', display:'flex', alignItems:'center', backgroundColor: '#FFFFFF', boxShadow: 'inset 0 -1px 0 0 #E4E7EB'}}>
			<Checkbox checked={item.checked} className='mr-3'/>
			<div style={{textTransform:'capitalize'}}>{item.display_name}</div>
		</div>
    )
}
const Single_item_for_quotation_setting_tab_only = ({ item, input_array, set_input_array}) => {

	const checkbox_onchange_for_fields = (item) => {
		var fields_copy = JSON.parse(JSON.stringify(input_array));
		var field = fields_copy.find(o => o.id === item.id);

		if(field){
			field.checked = !field.checked;
		}
		set_input_array(fields_copy);
	}

    return(
        <div style={{height:'35px', display:'flex', alignItems:'center', width:'100%'}}>
			<div style={{marginRight:'15px', display:'flex', alignItems:'center'}}><i style={{marginRight:'1px'}} className='fa fa-ellipsis-v'/><i className='fa fa-ellipsis-v'/></div>
			<div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
				<div style={{textTransform:'capitalize', fontSize:'14px'}}>{item.display_name}</div>
				<Switch 
					onChange={() => {checkbox_onchange_for_fields(item)}} 
					checked={item.checked}
					uncheckedIcon={false}
					checkedIcon={false}
					offColor='#e6e6e6'
					offHandleColor='#808080'
					onColor='#0066ff'
					onfHandleColor='#b3d1ff'
					height={15}
					width={30}	
				/>
				{/* <Input checked={item.checked} onChange={() => {checkbox_onchange(item.id)}} type='checkbox' style={{cursor:'pointer', position:'unset', margin:0, marginRight:'15px'}} /> */}
			</div>
		</div>
    )
}

const Quotation_settings_modal = ({  modal_type, handle_close,  modal_json, update_modal_json,handle_ui_response, set_page_loader}) => {

	const [tabs , set_tabs] = useState(1);
	const [exclude_fields , set_exclude_fields] = useState([]);
	const [display_exclude_fields , set_display_exclude_fields] = useState([]);
	const [show_top_fields , set_show_top_fields] = useState([]);
	const [display_show_top_fields , set_display_show_top_fields] = useState([]);
	const [available_price_types , set_available_price_types] = useState([]);
	const [active_price_type_id , set_active_price_type_id] = useState("");
	const [available_sales_channels, set_available_sales_channels] = useState([]);
	const [active_sales_channel_id, set_active_sales_channel_id] = useState("");
	const [allow_all_pricing_sales_channel_id, set_allow_all_pricing_sales_channel_id] = useState("");
	const [enable_sales_channel_editing, set_enable_sales_channel_editing] = useState(false);
	const [sales_channel_message, set_sales_channel_message] = useState("");
	// const [is_cabinet_accessories_always_added , set_is_cabinet_accessories_always_added] = useState(true);
	const [fields , set_fields] = useState([]);

	const [pricing_settings_exclude_in_quotation, set_pricing_settings_exclude_in_quotation] = useState(true);
	const [pricing_settings_show_on_top, set_pricing_settings_show_on_top] = useState(true);
	const [pricing_settings_other_settings, set_pricing_settings_other_settings] = useState(true);
	

	const [left_margin , set_left_margin] = useState(0);
	const [right_margin , set_right_margin] = useState(0);
	const [bottom_margin , set_bottom_margin] = useState(0);
	const [top_margin , set_top_margin] = useState(0);
	const [print_pdf_orientation, set_print_pdf_orientation] = useState('landscape');
	const [pricing_quotation_template,set_pricing_quotation_template] = useState('default');
	const [separate_tax_quotation, set_separate_tax_quotation] = useState(false);

	const [company_name, set_company_name] = useState('');
	const [quotation_name, set_quotation_name] = useState(false);
	const [org_logo, set_org_logo] = useState(false);
	const [store_logo, set_store_logo] = useState(false);
	const [quotation_id, set_quotation_id] = useState(false);
	const [client_details, set_client_details] = useState(false);
    const {t} = useTranslation()


	useEffect(() => {
		if(modal_type == "quotation_settings_modal"){
			if(modal_json && modal_json.exclude_json && modal_json.show_on_top_json){
				set_pricing_settings_exclude_in_quotation(modal_json.feature_flags.pricing_settings_exclude_in_quotation);
				set_pricing_settings_show_on_top(modal_json.feature_flags.pricing_settings_show_on_top);
				set_pricing_settings_other_settings(modal_json.feature_flags.pricing_settings_other_settings);

				set_exclude_fields(JSON.parse(JSON.stringify(modal_json.exclude_json)));
				// set_display_exclude_fields(JSON.parse(JSON.stringify(modal_json.exclude_json)));
				set_show_top_fields(JSON.parse(JSON.stringify(modal_json.show_on_top_json)));
				// set_display_show_top_fields(JSON.parse(JSON.stringify(modal_json.show_on_top_json)));
				set_fields(modal_json.fields)
				if(modal_json.other_settings.left_margin != undefined){
					set_left_margin(modal_json.other_settings.left_margin)
				}
				if(modal_json.other_settings.right_margin != undefined){
					set_right_margin(modal_json.other_settings.right_margin)
				}
				if(modal_json.other_settings.bottom_margin != undefined){
					set_bottom_margin(modal_json.other_settings.bottom_margin)
				}
				if(modal_json.other_settings.top_margin != undefined){
					set_top_margin(modal_json.other_settings.top_margin)
				}
				if(modal_json.other_settings.print_pdf_orientation){
					set_print_pdf_orientation(modal_json.other_settings.print_pdf_orientation)
				}
				if(modal_json.other_settings.separate_tax_quotation){
					set_separate_tax_quotation(modal_json.other_settings.separate_tax_quotation)
				}
				if(modal_json.other_settings.fields){
					if(modal_json.other_settings.fields.quotation_name){
						set_quotation_name(modal_json.other_settings.fields.quotation_name)
					}
					if(modal_json.other_settings.fields.company_name){
						set_company_name(modal_json.other_settings.fields.company_name)
					}
					if(modal_json.other_settings.fields.org_logo){
						set_org_logo(modal_json.other_settings.fields.org_logo)
					}
					if(modal_json.other_settings.fields.store_logo){
						set_store_logo(modal_json.other_settings.fields.store_logo)
					}
					if(modal_json.other_settings.fields.quotation_id){
						set_quotation_id(modal_json.other_settings.fields.quotation_id)
					}
					if(modal_json.other_settings.fields.client_details){
						set_client_details(modal_json.other_settings.fields.client_details)
					}
				}
				if(modal_json.other_settings.pricing_quotation_template){
					set_pricing_quotation_template(modal_json.other_settings.pricing_quotation_template)
				}
				set_available_price_types(modal_json.available_price_types);

				if(modal_json.active_price_type_id && modal_json.available_price_types.map(o=> o.id).includes(modal_json.active_price_type_id)){
					set_active_price_type_id(modal_json.active_price_type_id);
				}else if(modal_json.available_price_types.length){
					set_active_price_type_id(modal_json.available_price_types[0].id);
				}else{
					set_active_price_type_id("");
				}
				console.log(modal_json)
				set_available_sales_channels(modal_json.available_sales_channels);
				if(modal_json.allow_all_pricing_sales_channel_id && modal_json.available_sales_channels.map(o=>o.id).includes(modal_json.allow_all_pricing_sales_channel_id)){
					set_allow_all_pricing_sales_channel_id(modal_json.allow_all_pricing_sales_channel_id);
				}else if(modal_json.available_sales_channels.length){
					set_allow_all_pricing_sales_channel_id(modal_json.available_sales_channels[0].id);
				}else{
					set_allow_all_pricing_sales_channel_id("");
				}
				set_active_sales_channel_id(modal_json.active_sales_channel_id);
				set_sales_channel_message(modal_json.sales_channel_message);
				set_enable_sales_channel_editing(modal_json.enable_sales_channel_editing);
			}else{
				set_pricing_settings_exclude_in_quotation(true);
				set_pricing_settings_show_on_top(true);
				set_pricing_settings_other_settings(true);

				set_exclude_fields([]);
				set_display_exclude_fields([]);
				set_show_top_fields([]);
				set_display_show_top_fields([])
				// set_is_cabinet_accessories_always_added(true)
				set_fields([])
				set_available_price_types([]);
				set_active_price_type_id("");
				set_left_margin(0)
				set_right_margin(0)
				set_bottom_margin(0)
				set_top_margin(0)
				set_print_pdf_orientation('landscape')
				set_separate_tax_quotation(false)
				set_quotation_name('')
				set_company_name(false)
				set_org_logo(false)
				set_store_logo(false)
				set_quotation_id(false)
				set_client_details(false)
				set_available_sales_channels([]);
				set_active_sales_channel_id("");
				set_allow_all_pricing_sales_channel_id("");
				set_sales_channel_message("");
				set_enable_sales_channel_editing(false);
			}
		}
	}, [modal_json, modal_type])
	
	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	}; 

	const checkbox_onchange_exclude = (id) => {
		var old_exclude_fields = JSON.parse(JSON.stringify(exclude_fields));

		var field = old_exclude_fields.find(o => o.id == id);

		if(field){
			field.checked = !field.checked;
		}

		set_exclude_fields(old_exclude_fields);
	}

	const checkbox_onchange_show_top = (id) => {
		var old_show_top_fields = JSON.parse(JSON.stringify(show_top_fields));

		var field = old_show_top_fields.find(o => o.id == id);

		if(field){
			field.checked = !field.checked;
		}

		set_show_top_fields(old_show_top_fields);
	}

	// const checkbox_onchange_for_fields = (item) => {
	// 	var fields_copy = JSON.parse(JSON.stringify(fields));
	// 	var field = fields_copy.find(o => o.id === item);

	// 	if(field){
	// 		field.checked = !field.checked;
	// 	}
	// 	set_fields(fields_copy);
	// }

	const onclick_apply = () => {
		var final_json = {
			exclude_fields: exclude_fields,
			show_top_fields: show_top_fields,
			other_settings: {
				left_margin: left_margin,
				right_margin: right_margin,
				bottom_margin: bottom_margin,
				top_margin: top_margin,
				print_pdf_orientation: print_pdf_orientation,
				separate_tax_quotation: separate_tax_quotation,
				pricing_quotation_template: pricing_quotation_template,
				fields:{
					quotation_name: quotation_name,
					company_name: company_name,
					org_logo: org_logo,
					store_logo: store_logo,
					quotation_id: quotation_id,
					client_details: client_details
				}
			},
			fields:fields,
			active_price_type_id: active_price_type_id,
			allow_all_pricing_sales_channel_id: allow_all_pricing_sales_channel_id,
		}


		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {  
			console.log(final_json)
			window.Module.quotation_settings_apply(JSON.stringify(final_json));
			onclick_close();
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	const onclick_close = () => {
		set_exclude_fields([])
		set_display_exclude_fields([])
		set_show_top_fields([])
		set_display_show_top_fields([])
		set_tabs(1)
		handle_close()
	}

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type == "quotation_settings_modal"}>
			<IModalHeader toggle={handle_close}>Settings</IModalHeader>
			<IModalBody style={{height: '600px', overflow: 'auto'}}>
				<UncontrolledINavbar tabs={['Exclude in Quotation', 'Show on Top', 'Other Settings', 'Pricing Settings']} set_active_tab={set_tabs}></UncontrolledINavbar>
				<ISearch key={tabs === '0' ? 'search_exclude_in_quotation' : tabs === 1 ? 'search_show_on_top' : 'search_hidden'} style_outer={{display: tabs === 0 || tabs === 1 ? '' : 'none', margin: '0px 0px 16px 0px'}} list={tabs === 0 ? exclude_fields : show_top_fields} set_list={tabs === 0 ? set_display_exclude_fields : set_display_show_top_fields} search_parameters={['display_name']}></ISearch>
				<TabContent activeTab={"tabs" + tabs} style={{ height: pricing_settings_exclude_in_quotation || pricing_settings_show_on_top ? 'calc(100% - 120px)' : 'calc(100% - 80px)', overflowY: 'auto' }}>
					{pricing_settings_exclude_in_quotation ? (<TabPane  style={{ maxHeight: 'calc(100%)', overflowY: 'auto' }} tabId="tabs0">
						<div>
							{
								display_exclude_fields.map((item, idx) =>
									<Single_item_for_quotation_setting key={idx} item={item} checkbox_onchange={checkbox_onchange_exclude} />
								)
							}
						</div>
					</TabPane>) : ''}

					{pricing_settings_show_on_top ? (<TabPane  style={{ maxHeight: 'calc(100%)', overflowY: 'auto' }} className="full-height" tabId="tabs1">
						<div>
							{
								display_show_top_fields.map((item, idx) =>
									<Single_item_for_quotation_setting key={idx} item={item} checkbox_onchange={checkbox_onchange_show_top} />
								)
							}
						</div>
					</TabPane>) : ''}
					{
						pricing_settings_other_settings ?
							(<TabPane  style={{ maxHeight: 'calc(100%)', overflowY: 'auto' }} className="full-height" tabId="tabs2">
								<div style={{ width: '100%' }}>
									<div style={{ display: 'flex' }}>
										<div className='p-2' style={{ width: '100%', backgroundColor: 'white' }}>
											<div className='mb-2'  style={{ textAlign: 'left', fontSize: '16px' }}>{t(`Page Margins (in mm)`)}</div>
											<div className='px-2 mb-2 flex_property justify-content-between'>
												<div style={{ textAlign: "left" }}>{t(`Left Margin`)}</div>
												<div>
													<Input style={{ width: 100 }} value={left_margin} onChange={(e) => set_left_margin(Number(e.target.value))} type="number" />
												</div>
											</div>
											<div className='px-2 mb-2 flex_property justify-content-between'>
												<div style={{ textAlign: "left" }}>{t(`Right Margin`)}</div>
												<div>
													<Input style={{ width: 100 }} value={right_margin} onChange={(e) => set_right_margin(Number(e.target.value))} type="number" />
												</div>
											</div>
											<div className='px-2 mb-2 flex_property justify-content-between'>
												<div style={{ textAlign: "left" }}>{t(`Bottom Margin`)}</div>
												<div>
													<Input style={{ width: 100 }} value={bottom_margin} onChange={(e) => set_bottom_margin(Number(e.target.value))} type="number" />
												</div>
											</div>
											<div className='px-2 mb-2 flex_property justify-content-between'>
												<div style={{ textAlign: "left" }}>{t(`Top Margin`)}</div>
												<div>
													<Input style={{ width: 100 }} value={top_margin} onChange={(e) => set_top_margin(Number(e.target.value))} type="number" />
												</div>
											</div>

										</div>
									</div>
								</div>
								<div className='flex_property m-3' style={{ justifyContent: 'space-between' }}>
									<div>{t(`Print PDF Orientation`)}</div>
									<Input type='select' style={{ width: '300px', fontSize: '14px', height: '32px' }} value={print_pdf_orientation} onChange={(e) => set_print_pdf_orientation(e.target.value)}>
										{/* <option value=''>Select Orientation</option> */}
										<option value='landscape'>Landscape</option>
										<option value='portrait'>Portrait</option>
									</Input>
								</div>
								<div className='flex_property m-3' style={{ justifyContent: 'space-between' }}>
									<div>{t(`Seperate Tax Quotation`)}</div>
									<Input type='select' style={{ width: '300px', fontSize: '14px', height: '32px' }} value={separate_tax_quotation == true ? 'true' : 'false'} onChange={(e) => set_separate_tax_quotation(e.target.value == 'true' ? true : false)}>
										{/* <option value=''>Select Orientation</option> */}
										<option value='false'>False</option>
										<option value='true'>True</option>
									</Input>
								</div>
								<div className='flex_property m-3' style={{ justifyContent: 'space-between' }}>
									<div>{t(`Pricing Quotation Template`)}</div>
									<Input type='select' style={{ width: '300px', fontSize: '14px', height: '32px' }} value={pricing_quotation_template} onChange={(e) => set_pricing_quotation_template(e.target.value)}>
										{/* <option value=''>Select Orientation</option> */}
										<option value='default'>Default</option>
										<option value='show_woodwork_separately'>Show Woodwork Separately</option>
										<option value='roomwise_segregation'>Roomwise Segregation</option>
									</Input>
								</div>
								{/* ---------------------------------------------------------------------- */}
								<div className='p-2 mb-3' style={{ width: '100%', backgroundColor: 'white' }}>
									<div className='flex_property m-3' style={{ textAlign: 'left', fontSize: '16px' }}>
										Fields
									</div>
									<div className='flex_property m-3' style={{ justifyContent: 'space-between' }}>
										<div>{t(`Quotation Name`)}</div>
										<Input type='text' style={{ width: '300px', fontSize: '14px', height: '32px' }} value={quotation_name} onChange={(e) => set_quotation_name(e.target.value)}></Input>
									</div>
									<div className='flex_property m-3' style={{ justifyContent: 'space-between' }}>
										<div>{t(`Company Name`)}</div>
										<Input type='select' style={{ width: '300px', fontSize: '14px', height: '32px' }} value={company_name == true ? 'true' : 'false'} onChange={(e) => set_company_name(e.target.value == 'true' ? true : false)}>
											{/* <option value=''>Select Orientation</option> */}
											<option value='false'>False</option>
											<option value='true'>True</option>
										</Input>
									</div><div className='flex_property m-3' style={{ justifyContent: 'space-between' }}>
										<div>{t(`Org Logo`)}</div>
										<Input type='select' style={{ width: '300px', fontSize: '14px', height: '32px' }} value={org_logo == true ? 'true' : 'false'} onChange={(e) => set_org_logo(e.target.value == 'true' ? true : false)}>
											{/* <option value=''>Select Orientation</option> */}
											<option value='false'>False</option>
											<option value='true'>True</option>
										</Input>
									</div><div className='flex_property m-3' style={{ justifyContent: 'space-between' }}>
										<div>{t(`Store Logo`)}</div>
										<Input type='select' style={{ width: '300px', fontSize: '14px', height: '32px' }} value={store_logo == true ? 'true' : 'false'} onChange={(e) => set_store_logo(e.target.value == 'true' ? true : false)}>
											{/* <option value=''>Select Orientation</option> */}
											<option value='false'>False</option>
											<option value='true'>True</option>
										</Input>
									</div><div className='flex_property m-3' style={{ justifyContent: 'space-between' }}>
										<div>{t(`Quotation Id`)}</div>
										<Input type='select' style={{ width: '300px', fontSize: '14px', height: '32px' }} value={quotation_id == true ? 'true' : 'false'} onChange={(e) => set_quotation_id(e.target.value == 'true' ? true : false)}>
											{/* <option value=''>Select Orientation</option> */}
											<option value='false'>False</option>
											<option value='true'>True</option>
										</Input>
									</div><div className='flex_property m-3' style={{ justifyContent: 'space-between' }}>
										<div>{t(`Client Details`)}</div>
										<Input type='select' style={{ width: '300px', fontSize: '14px', height: '32px' }} value={client_details == true ? 'true' : 'false'} onChange={(e) => set_client_details(e.target.value == 'true' ? true : false)}>
											{/* <option value=''>Select Orientation</option> */}
											<option value='false'>False</option>
											<option value='true'>True</option>
										</Input>
									</div>
								</div>
								{/* ---------------------------------------------------------------------- */}
								<div style={{ width: '100%' }}>
									<DragDropComponent items_from_backened={fields} set_items_from_backened={set_fields} modal_name='pricing_quotation' Single_component={Single_item_for_quotation_setting_tab_only} />
									{/* {
										fields && fields.length ? fields.map((item, idx) =>
											<Single_item_for_quotation_setting_tab_only key={idx} item={item} checkbox_onchange={checkbox_onchange_for_fields} />
										) : ''
									} */}
								</div>
							</TabPane>)
							: ''
					}
					<TabPane  style={{ maxHeight: 'calc(100%)', overflowY: 'auto' }} className="full-height" tabId="tabs3">
						<div>
							<div className='flex_property p-3 mb-3 justify-content-between' style={{backgroundColor: 'white'}}>
								<div>{t(`Active Price Type`)}</div>
								<div>
									<Input style={{ ...styles_modals.input_styles_2, width: 'fit-content' }} value={active_price_type_id} onChange={(e) => { set_active_price_type_id(e.target.value) }} type="select">
										{available_price_types.map(o => (
											<option value={o.id}>{o.name}</option>
										))}
									</Input>
								</div>
							</div>
							<div className='p-3' style={{backgroundColor: 'white'}}>
								<div className='mb-3' style={{fontWeight: 500}}>{sales_channel_message}</div>
								<div className='flex_property justify-content-between'>
									<div>{t(`Pricing Sales Channel`)}</div>
									<div>
										{
											enable_sales_channel_editing ?
												<Input style={{...styles_modals.input_styles_2, width: 'fit-content'}} value={allow_all_pricing_sales_channel_id} onChange={(e) => { set_allow_all_pricing_sales_channel_id(e.target.value) }} type="select">
													{available_sales_channels.map(o => (
														<option value={o.id}>{o.name}</option>
													))}
												</Input>
												:
												<Input style={{...styles_modals.input_styles_2, width: 'fit-content'}} value={active_sales_channel_id} disabled type="select">
													{available_sales_channels.map(o => (
														<option value={o.id}>{o.name}</option>
													))}
												</Input>
										}
									</div>
								</div>
							</div>
						</div>
					</TabPane>
				</TabContent>
			</IModalBody>
			<IModalFooter>
				<Button className='secondary_button_default' onClick={handle_close}>Cancel</Button>
				<Button className='primary_button_default' onClick={onclick_apply}>Apply</Button>
			</IModalFooter>
		</Modal>
	)    
}

const Create_single_parameter = ({parameter, on_change, update_view, metric_system, approximation_mm, approximation_ft,handle_ui_response, mmToFeetParts, approxMM}) => {
	const get_dimension = (id) => {
		if(metric_system == 'mm'){
			return Number(document.getElementById(id).value);
		}else if(metric_system == 'ft'){
			var ft_val = Number(document.getElementById(id + "_ft").value);
			var in_val = Number(document.getElementById(id + "_in").value);
			var parts_val = Number(document.getElementById(id + "_parts").value);

			var ft_inch_only =  Number(ft_val*304.8)+(in_val*(304.8/12));
			if(approximation_ft == '1/8_inch'){
				var fraction =  (parts_val*(25.4/8));
			}else if(approximation_ft == '1/8_inch'){
				var fraction =  (parts_val*(25.4/16));
			}

			return Number(ft_inch_only) + Number(fraction);
		}
	}
	  
	const set_value = (new_value,key) => {
		if(["number","integer","distance","area","volume"].includes(parameter.type)){
			new_value = Number(new_value);
		}else if (parameter.type == "color"){
			// new_value = Number(new_value);
		}

		if(key){
			var temp = new_value;
			new_value = JSON.parse(JSON.stringify(parameter));
			new_value.value[key] = temp;
		}
		
		on_change(parameter.identifier,new_value)
	}

	const type_fn = () => {
		if(parameter && parameter.type){
			if(parameter.type=='number'){
				return(
					<div className='number_input commom_style'>
						<span className='common_label_style'>{parameter.label}</span>
						<input type='number' placeholder='Enter value' value={parameter.value} onChange={(e) => set_value(e.target.value)} className='common_input_style' />
					</div>
				)
			}
			else if(parameter.type=='integer'){
				return(
					<div className='integer_input commom_style'>
						<span className='common_label_style'>{parameter.label}</span>
						<input type='number' placeholder='Enter value' value={parameter.value} onChange={(e) => set_value(e.target.value)} className='common_input_style' />
					</div>	
				)
			}
			else if(parameter.type=='string'){
				return(
					<div className='string_input commom_style'>
						<span className='common_label_style'>{parameter.label}</span>
						<input type='string' placeholder='Enter value' value={parameter.value} onChange={(e) => set_value(e.target.value)} className='common_input_style' />
					</div>	
				)
			}
			else if(parameter.type=='bool'){
				return(
					<div className='bool_input commom_style'>
						<input type='checkbox' value={parameter.value} onChange={(e) => set_value(e.target.value)} className='common_input_style' />
						<span className='common_label_style'>{parameter.label}</span>
					</div>	
				)
			}

			else if(parameter.type=='distance'){
				return(
					<div className='distance_input commom_style'>
						<span className='common_label_style'>{parameter.label}</span>
						<input type='number' placeholder='Enter value' value={parameter.value} onChange={(e) => set_value(e.target.value)} className='common_input_style' />
					</div>	
				)
			}

			else if(parameter.type=='area'){
				return(
					<div className='area_input commom_style'>
						<span className='common_label_style'>{parameter.label}</span>
						<input type='number' placeholder='Enter value' value={parameter.value} onChange={(e) => set_value(e.target.value)} className='common_input_style' />
					</div>	
				)
			}
			else if(parameter.type=='volume'){
				return(
					<div className='volume_input commom_style'>
						<span className='common_label_style'>{parameter.label}</span>
						<input type='number' placeholder='Enter value' value={parameter.value} onChange={(e) => set_value(e.target.value)} className='common_input_style' />
					</div>
				)
			}
			
			else if(parameter.type=='string_enum'){
				return(
					<div className='string_enum_input commom_style'>
						<span className='common_label_style'>{parameter.label}</span>
						<select className='common_input_style' onChange={(e) => set_value(e.target.value)} >
							{parameter.string_enum && parameter.string_enum.map(o => (
								<option value={o.value}>{o.label}</option>
							))}
						</select>
					</div>
				)
			}
			else if(parameter.type=='vector2'){
				return(
					<div className='vector2_input commom_style'>
						<span className='common_label_style'>{parameter.label}</span>
						<span style={{display:'flex', justifyContent:'space-evenly'}}>
							<input type='number' placeholder='Enter value' value={parameter.value.x} onChange={(e) => set_value(e.target.value,"x")} className='common_input_style' />&nbsp;x&nbsp;
							<input type='number' placeholder='Enter value' value={parameter.value.y} onChange={(e) => set_value(e.target.value,"y")} className='common_input_style' />&nbsp;x&nbsp;						
						</span>
					</div>	
				)
			}
			else if(parameter.type=='vector3'){
				return(
					<div className='vector3_input commom_style'>
						<span className='common_label_style'>{parameter.label}</span>
						<span style={{display:'flex', justifyContent:'space-evenly'}}>
							<input type='number' placeholder='Enter value' value={parameter.value.x} onChange={(e) => set_value(e.target.value,"x")} className='common_input_style' />&nbsp;x&nbsp;
							<input type='number' placeholder='Enter value' value={parameter.value.y} onChange={(e) => set_value(e.target.value,"y")} className='common_input_style' />&nbsp;x&nbsp;
							<input type='number' placeholder='Enter value' value={parameter.value.z} onChange={(e) => set_value(e.target.value,"z")} className='common_input_style' />
						</span>
					</div>	
				)
			}
			else if(parameter.type=='color'){
				return(
					<div className='color_input commom_style'>
						<span className='common_label_style'>{parameter.label}</span>
						<input type='color' placeholder='Enter value' value={parameter.value} onChange={(e) => set_value(e.target.value)} className='common_input_style' />
					</div>	
				)
			}
			else if(parameter.type=='image'){
				return(
					<div className='image_input commom_style'>
						<span className='common_label_style'>{parameter.label}</span>
						<input type='file' placeholder='Enter value' value={parameter.value} onChange={(e) => set_value(e.target.value)} className='common_input_style' />
					</div>	
				)
			}
			else{
				return(<div></div>)
			}
		}	
	}

	return( 
		// <div style={{width: '50%', overflowX: 'hidden', paddingBottom:'10px', textTransform:'capitalize'}}>
		// 	{type_fn()}
		// </div>
		<Col xs={6} style={{overflowX: 'hidden', paddingBottom:'10px', textTransform:'capitalize'}}>
			{type_fn()}
		</Col>
	)
}

const All_parameter_modal = ({modal_type, modal_json, handle_close, update_modal_json, handle_ui_response, metric_system, approximation_ft, approximation_mm, mmToFeetParts, approxMM}) => {
	const [ parameters, set_parameters ] = useState([]);
	const [ element_id, set_element_id ] = useState("");
	const [ title, set_title ] = useState("");

	useEffect(() => {
		if(modal_type == "all_parameter_modal" && modal_json){
			var params = JSON.parse(JSON.stringify(Object.keys(modal_json.parameters).map(o => ({...modal_json.parameters[o],identifier: o})).filter(o => o.exposed))).sort((a,b) => {
				if(a.type < b.type) { return -1; }
				if(a.type > b.type) { return 1; }
				return 0;
			});
			set_parameters(params)
			set_title(modal_json.title)
			set_element_id(modal_json.element_id)
		}
	},[modal_json, modal_type])

	const onclick_apply = async () => {
		var final_json = {}
		parameters.map(o => {
			final_json[o.identifier] = JSON.parse(JSON.stringify(o));
			delete final_json[o.identifier].identifier;
		});

		final_json = {
			parameters: final_json,
			element_id	
		}

		var ui_response = await window.Promisify(window.Module.apply_parameter_changes( JSON.stringify(final_json) ));
		handle_ui_response(ui_response);
	}

	const onclick_handle_close = () => {
		handle_close()
	}

	const parameter_onchange = (identifier, new_value) => {
		var params = JSON.parse(JSON.stringify(parameters));
		if(params.find(o => o.identifier == identifier)){
			params.find(o => o.identifier == identifier).value = new_value;
			set_parameters(params);
		}
	}

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="all_parameter_modal"}>
			<div style={{height:'500px'}}>
				<div style={{height:'50px', padding:'0px 20px', borderBottom:'1px solid #d9d9d9', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
					<div>{title}</div>
					<div onClick={onclick_handle_close}><i style={{fontSize:'16px',cursor:'pointer'}} className='fa fa-times'/></div>	
				</div>
				<div style={{height:'calc(100% - 120px)', overflowY:'auto', padding:'30px', backgroundColor:'#E6E9EE'}}>
					<Row>
					{
						parameters.map((elem, idx) =>
						( 
							<Create_single_parameter handle_ui_response={handle_ui_response} key={idx} metric_system={metric_system} approximation_mm={approximation_mm} approximation_ft={approximation_ft} mmToFeetParts={mmToFeetParts} approxMM={approxMM} update_modal_json={update_modal_json} on_change={parameter_onchange} parameter={elem} />
						))
					}
					</Row>
				</div>
				<div style={{height:'70px', padding:'0px 20px', borderTop:'1px solid #d9d9d9', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
					<Button className='white_button' onClick={onclick_handle_close} style={{marginRight:"10px"}} >Cancel</Button>
					<Button className='blue_button' onClick={onclick_apply} >Apply</Button>
				</div>		  
			</div>
		</Modal>
	   
	);
}

const Outputs_Level_Selector = ({  modal_type, handle_close,  modal_json, update_view, update_modal_json,handle_ui_response, set_page_loader}) => {
	const [json_copy , set_json_copy] = useState('');
	const [selected_building , set_selected_building] = useState('');
	const [selected_building_details , set_selected_building_details] = useState('');
	const [selected_floor , set_selected_floor] = useState('');
	const [selected_floor_details , set_selected_floor_details] = useState('');
	const [selected_room , set_selected_room] = useState('');

	const alert = useAlert();
    const {t} = useTranslation()

	useEffect(() => {
		if(modal_json && modal_type === "outputs_level_selector" ){
			var json_copy_1 = JSON.parse(JSON.stringify(modal_json));
			var active_level_id = json_copy_1.active_level_id;
			if(json_copy_1.buildings.length){
				var active_level_find = find_active_level_in_data(json_copy_1.buildings, active_level_id);
				if(active_level_find){
					set_selected_building(active_level_find.building?active_level_find.building.id:'');
					set_selected_building_details(active_level_find.building?active_level_find.building:'');
					set_selected_floor(active_level_find.floor?active_level_find.floor.id:'');
					set_selected_floor_details(active_level_find.floor?active_level_find.floor:'');
					set_selected_room(active_level_find.room?active_level_find.room.id:'');
				}else{
					set_selected_building('');
					set_selected_building_details('');
					set_selected_floor('');
					set_selected_floor_details('');
					set_selected_room('');
				}
			}

			set_json_copy(json_copy_1);
		}
	}, [modal_json, modal_type])

	const select_building = (id) => {
		if(!id){
			set_selected_building('');
			set_selected_building_details('');
			set_selected_floor('');
			set_selected_floor_details('');
			set_selected_room('');
		}else{
			set_selected_building(id);
			set_selected_building_details(json_copy.buildings.find(o => o.id == id));
			set_selected_floor('');
			set_selected_floor_details('');
			set_selected_room('');
		}
	}

	const select_floor = (id) => {
		if(!id){
			set_selected_floor('');
			set_selected_floor_details('');
			set_selected_room('');
		}else{
			set_selected_floor(id);
			set_selected_floor_details(selected_building_details.floors.find(o => o.id == id));
			set_selected_room('');
		}
	}

	const select_room = (id) => {
		if(!id){
			set_selected_room('');
		}else{
			set_selected_room(id);
		}
	}

	const find_active_level_in_data = (levels_data, active_level_id) => {
		var building_find = levels_data.find(o => o.id == active_level_id);

		if(building_find){
			return {building: building_find, type: "building"};
		}else{
			var building_find_for_floor_id = levels_data.find(o => {
				return o.floors.find(p => p.id == active_level_id);
			});

			if(building_find_for_floor_id){
				return {
					building: building_find_for_floor_id,
					floor: building_find_for_floor_id.floors.find(o => o.id == active_level_id),
					type: "floor"
				};
			}else{
				var building_find_for_room_id = levels_data.find(o => {
					return o.floors.find(p => {
						return p.rooms.find(q => q.id == active_level_id);
					});
				});

				if(building_find_for_room_id){
					var floor_find_for_room_id = building_find_for_room_id.floors.find(o => o.rooms.find(p => p.id == active_level_id))
					return {
						building: building_find_for_room_id,
						floor: floor_find_for_room_id,
						room: floor_find_for_room_id.rooms.find(o => o.id == active_level_id),
						type: "room"
					};
				}
			}
		}

		return null;
	}

	const get_current_level_name = () => {
		if(selected_room){
			if(selected_floor_details && selected_floor_details.rooms){
				return selected_floor_details.rooms.find(o => o.id == selected_room).name;
			}
		}else if(selected_floor){
			if(selected_building_details && selected_building_details.floors){
				return selected_building_details.floors.find(o => o.id == selected_floor).name;
			}
		}else if(selected_building){
			if(json_copy && json_copy.buildings){
				return json_copy.buildings.find(o => o.id == selected_building).name;
			}
		}else{
			return "World";
		}
	}
	
	
	const onclick_apply = async () => {
		var final_json= {}
		if(selected_room){
			final_json.level_id = selected_room
			final_json.level = "room"
		}else if(selected_floor){
			final_json.level_id = selected_floor
			final_json.level = "floor"
		}else if(selected_building){
			final_json.level_id = selected_building
			final_json.level = "building"
		}else{
			final_json.level_id = ""
			final_json.level = "world"
		}
		final_json.output_id = modal_json.output_id?modal_json.output_id:"";
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {  
			onclick_close();
			window.Module.set_viewing_level_for_outputs(JSON.stringify(final_json));
			
			var id = "after_set_viewing_level$"+final_json.output_id;
			var promise_resp;
			try{
				promise_resp = window.Module.toolbar_router(id);
			}catch(err){
				err.constructor.name.includes("Error") != true?err=new Error(err):null;
				err.name = "Captured error in Toolbar onclick error at " + id + " : " + err.name;
				console.error(err);
				window.sentry_capture_exception("",err);
				alert.error("Something went wrong")
				set_page_loader({
					show: false,
					text: 'Please wait'
				});
				return;
			}
	
			var ui_response = await window.Promisify(promise_resp);
			handle_ui_response(ui_response);
			update_view();
	
			// if(id == "production_details_$pricing_quotation" || id == "production_details_$cut_list_floorplanner"){
				set_page_loader({
					show: false,
					text: 'Please wait'
				});
			// }
		},0)

		
	}

	const onclick_close = () => {
		set_selected_building('');
		set_selected_building_details('');
		set_selected_floor('');
		set_selected_floor_details('');
		set_selected_room('');
		set_json_copy(null);
		handle_close()
	}

	return(
		// <Modal className="modal-dialog-centered" size="md" isOpen={modal_type=="outputs_level_selector"}>
		// 	<div style={{ fontSize:'12px'}}>
		// 		<div style={{height:'50px', padding:'0px 20px', borderBottom:'1px solid #d9d9d9', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
		// 			<div>{modal_json && modal_json.title?modal_json.title:''}</div>
		// 			<div onClick={onclick_close}><i style={{fontSize:'16px',cursor:'pointer'}} className='fa fa-times'/></div>	
		// 		</div>
				
		// 		<div style={{padding:'20px 20px 0px 20px', marginBottom:'20px'}}>
		// 			<div>Select Building</div>
		// 			<Input style={{fontSize:'12px'}} id="building_select_pq_level" type='select' value = {selected_building} onChange={(e)=> select_building(e.target.value)}>
		// 				<option style={{textTransform:'capitalize'}} value={""}>Select Building</option>
		// 				{
		// 					json_copy && json_copy.buildings && json_copy.buildings.length ? json_copy.buildings.map((item, idx) => 
		// 						<option style={{textTransform:'capitalize'}} key={idx} value={item.id}>{item.name}</option>
		// 					):''
		// 				}
		// 			</Input>
		// 		</div>
		// 		{
		// 			selected_building_details?
		// 			<div style={{marginBottom:'20px', padding:'0px 20px'}}>
		// 				<div>Select Floor</div>
		// 				<Input style={{fontSize:'12px'}} id="floor_select_pq_level" type='select' value = {selected_floor} onChange={(e)=> select_floor(e.target.value)}>
		// 					<option style={{textTransform:'capitalize'}} value={""}>Select Floor</option>
		// 					{
		// 						selected_building_details && selected_building_details.floors && selected_building_details.floors.length ? selected_building_details.floors.map((item, idx) => 
		// 							<option style={{textTransform:'capitalize'}} key={idx} value={item.id}>{item.name}</option>
		// 						):''
		// 					}
		// 				</Input> 
		// 			</div>
		// 			:''
		// 		}
		// 		{
		// 			selected_floor_details?
		// 			<div style={{marginBottom:'20px', padding:'0px 20px'}}>
		// 				<div>Select Room</div>
		// 				<Input style={{fontSize:'12px'}} id="room_select_pq_level" type='select' value = {selected_room} onChange={(e)=> select_room(e.target.value)}>
		// 					<option style={{textTransform:'capitalize'}} value={""}>Select Room</option>
		// 					{
		// 						selected_floor_details && selected_floor_details.rooms && selected_floor_details.rooms.length ? selected_floor_details.rooms.map((item, idx) => 
		// 							<option style={{textTransform:'capitalize'}} key={idx} value={item.id}>{item.name}</option>
		// 						):''
		// 					}
		// 				</Input> 
		// 			</div>
		// 			:''
		// 		}
				
		// 		<div style={{height:'50px', padding:'0px 20px', borderTop:'1px solid #d9d9d9', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
		// 			<Button className='blue_button' onClick={onclick_apply} >{"Continue (" + get_current_level_name() + ")"}</Button>
		// 		</div>	
		// 	</div>	
		// </Modal>
		<Modal className="modal-dialog-centered" size="md" isOpen={modal_type == "outputs_level_selector"}>
			<IModalHeader toggle={onclick_close}>{modal_json && modal_json.title ? modal_json.title : ''}</IModalHeader>
			<IModalBody style={{ minHeight: '280px', maxHeight: '600px', overflow: 'auto' }}>
				<div className='mb-3 px-2'>
					<div className='mb-1' style={{fontWeight: 500}}>{t(`Select Building`)}</div>
					<Input style={{ fontSize: '12px' }} id="building_select_pq_level" type='select' value={selected_building} onChange={(e) => select_building(e.target.value)}>
						<option style={{ textTransform: 'capitalize' }} value={""}>Select Building</option>
						{
							json_copy && json_copy.buildings && json_copy.buildings.length ? json_copy.buildings.map((item, idx) =>
								<option style={{ textTransform: 'capitalize' }} key={idx} value={item.id}>{item.name}</option>
							) : ''
						}
					</Input>
				</div>
				{
					selected_building_details ?
						<div className='mb-3 px-2'>
							<div className='mb-1' style={{fontWeight: 500}}>{t(`Select Floor`)}</div>
							<Input style={{ fontSize: '12px' }} id="floor_select_pq_level" type='select' value={selected_floor} onChange={(e) => select_floor(e.target.value)}>
								<option style={{ textTransform: 'capitalize' }} value={""}>Select Floor</option>
								{
									selected_building_details && selected_building_details.floors && selected_building_details.floors.length ? selected_building_details.floors.map((item, idx) =>
										<option style={{ textTransform: 'capitalize' }} key={idx} value={item.id}>{item.name}</option>
									) : ''
								}
							</Input>
						</div>
						: ''
				}
				{
					selected_floor_details ?
						<div className='mb-3 px-2'>
							<div className='mb-1' style={{fontWeight: 500}}>{t(`Select Room`)}</div>
							<Input style={{ fontSize: '12px' }} id="room_select_pq_level" type='select' value={selected_room} onChange={(e) => select_room(e.target.value)}>
								<option style={{ textTransform: 'capitalize' }} value={""}>Select Room</option>
								{
									selected_floor_details && selected_floor_details.rooms && selected_floor_details.rooms.length ? selected_floor_details.rooms.map((item, idx) =>
										<option style={{ textTransform: 'capitalize' }} key={idx} value={item.id}>{item.name}</option>
									) : ''
								}
							</Input>
						</div>
						: ''
				}
			</IModalBody>
			<IModalFooter>
				<Button className='primary_button_default' onClick={onclick_apply} >{"Continue (" + get_current_level_name() + ")"}</Button>
			</IModalFooter>
		</Modal>
	)    
}


const Building_floor_modal = ({  modal_type, handle_close,  modal_json, update_modal_json,handle_ui_response, set_page_loader}) => {

	const [json_copy , set_json_copy] = useState([]);
	const [selected_building , set_selected_building] = useState('');
	const [selected_building_details , set_selected_building_details] = useState('');
	const [selected_floor , set_selected_floor] = useState('');
	const [selected_floor_details , set_selected_floor_details] = useState('');
    const {t} = useTranslation()

	useEffect(() => {
		if(modal_json && modal_type === "building_floor_modal" ){
			var json_copy_1 = JSON.parse(JSON.stringify(modal_json));
			set_json_copy(json_copy_1)
		}else{
			set_json_copy([])
		}
	}, [modal_json, modal_type])

	useEffect(() => {
		if(json_copy && json_copy.length){
			set_selected_building(json_copy[0].id)
		}else{
			set_selected_building('')
		}
	}, [json_copy])

	useEffect(() => {
		if(selected_building){
			var x_build = json_copy.find(x => x.id===selected_building)
			set_selected_building_details(x_build)
		}else{
			set_selected_building_details('')
		}
	}, [selected_building])

	useEffect(() => {
		if(selected_building_details && selected_building_details.floors && selected_building_details.floors.length){
			set_selected_floor(selected_building_details.floors[0].id)
		}else{
			set_selected_floor('')
		}
	}, [selected_building_details])

	useEffect(() => {
		if(selected_floor && selected_building_details){
			var x_floor = selected_building_details.floors.find(x => x.id===selected_floor)
			set_selected_floor_details(x_floor)
		}else{
			set_selected_floor_details('')
		}
	}, [selected_floor])
	
	
	const onclick_apply = () => {
		
		var final_json= {
			selected_building:selected_building,
			selected_floor:selected_floor,
		}
		window.Module.add_room_schedule_to_presentation(JSON.stringify(final_json));
		onclick_close();
	}

	const onclick_close = () => {
		set_selected_building('');
		set_selected_building_details('');
		set_selected_floor('');
		set_selected_floor_details('');
		handle_close()
	}

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={modal_type=="building_floor_modal"}>
			<div style={{ fontSize:'12px'}}>
				<div style={{height:'50px', padding:'0px 20px', borderBottom:'1px solid #d9d9d9', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
					<div>{t(`Building & Floor`)}</div>
					<div onClick={onclick_close}><i style={{fontSize:'16px',cursor:'pointer'}} className='fa fa-times'/></div>	
				</div>
				
				<div style={{padding:'20px 20px 0px 20px', marginBottom:'20px'}}>
					<div>{t(`Select Building`)}</div>
					<Input style={{fontSize:'12px'}} id="delete_shift_select" type='select' value = {selected_building} onChange={(e)=> set_selected_building(e.target.value)}>
						{
							modal_json && modal_json.length ? modal_json.map((item, idx) => 
								<option style={{textTransform:'capitalize'}} key={idx} value={item.id}>{item.name}</option>
							):''
						}
					</Input>
				</div>
				{
					selected_building_details?
					<div style={{marginBottom:'20px', padding:'0px 20px'}}>
						<div>{t(`Select Floor`)}</div>
						<Input style={{fontSize:'12px'}} id="delete_shift_select" type='select' value = {selected_floor} onChange={(e)=> set_selected_floor(e.target.value)}>
							{
								selected_building_details && selected_building_details.floors && selected_building_details.floors.length ? selected_building_details.floors.map((item, idx) => 
									<option style={{textTransform:'capitalize'}} key={idx} value={item.id}>{item.name}</option>
								):''
							}
						</Input> 
					</div>
					:''
				}
				
				<div style={{height:'50px', padding:'0px 20px', borderTop:'1px solid #d9d9d9', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
					<Button className='white_button' onClick={handle_close} style={{marginRight:"10px"}} >Cancel</Button>
					<Button className='blue_button' onClick={onclick_apply} >Apply</Button>
				</div>	
			</div>	
		</Modal>
	)    
}

const Checkbox_input_for_cabinet_modal = ({unit, style, unit_id}) => {

	const [checkbox_value, set_checkbox_value] = useState(false)

	useEffect(() => {
		if(unit){
			set_checkbox_value(unit)
		}
	},[unit])
	
	// useEffect(() => {
	// 	window.Module.set_show_in_includes(checkbox_value,unit.id);
	// },[checkbox_value])

	const set_includes = (value) => {
		window.Module.set_show_in_includes(!checkbox_value,unit_id);
		set_checkbox_value(!checkbox_value)
	}

	return(
		<Input style={{...style, position:'unset', margin:0}} type='checkbox' checked={checkbox_value?true:''}  onChange={set_includes} />
	)
}

const Single_table_for_cabinet_pricing = ({ handle_ui_response, item_details, item_name, handle_close, update_view }) => {

	return(
		// <div style={{ marginBottom:'15px'}}>
		// 	<div style={{fontWeight:700, textTransform:'capitalize'}}>{item_name?item_name:'No Name'}</div>
		// 	<table className='border_table'style={{width:'100%'}}>
		// 		<thead>
		// 		<tr>
		// 			<th className='border_th padding_th' style={{textTransform:"capitalize"}}>Sl.no.</th>
		// 			{
		// 				item_details && item_details.heading && item_details.heading.length>0 ?
		// 					item_details.heading.map((head,idx) => 
		// 						<th key={idx} className='border_th padding_th' style={{textTransform:"capitalize"}}><span>{head}</span></th>
		// 					)
		// 				:<th>No Items</th>
		// 			}
		// 		</tr>
		// 		</thead>
		// 		<tbody>
		// 		{
		// 			item_details && item_details.data && item_details.data.length>0 && item_details.data.map((units,idx1) => (
		// 				<tr key={idx1}>
		// 					<td className='border_td'>{idx1+1}</td>
		// 					{
		// 						units && units.length>0 &&units.map((unit_item,idx2) => 
		// 							<td key={idx2} className='border_td'>
		// 								{Array.isArray(unit_item)?
		// 									<div style={{width:"100%",textAlign:'left'}}>
		// 										{
		// 											unit_item && unit_item.length && unit_item.map((x, idx3)=>(
		// 												<div key={idx3} >{x}</div>
		// 											))
		// 										}
		// 									</div>
		// 									:
		// 									''
		// 								}
		// 								{
		// 									typeof unit_item == "boolean"?
		// 										<Checkbox_input_for_cabinet_modal unit={unit_item} unit_id={item_details.ids[idx1]}/>
		// 										:''
		// 								}
		// 								{
		// 									!Array.isArray(unit_item) && typeof unit_item != "boolean"?
		// 										<div style={{width:"100%",textAlign:'left'}}>{unit_item}</div>
		// 									:''
		// 								}
		// 							</td>
		// 						)
		// 					}
		// 				</tr>
		// 			))
		// 		}
		// 		</tbody>
		// 	</table>
		// </div>
		<div className='mb-3'>
			<div className='mb-2' style={{fontWeight:700, textTransform:'capitalize', fontSize: '16px'}}>{item_name?item_name:'No Name'}</div>
			<ITable rowSeparated={true} coloumnSeparated={true} style_container={{border: '1px solid var(--light-border-color)', maxHeight: '400px', overflow: 'auto'}}>
				<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px' }}>
					<ITH style={{textTransform:"capitalize"}}>Sl.no.</ITH>
					{
						item_details && item_details.heading && item_details.heading.length>0 ?
							item_details.heading.map((head,idx) => 
								<ITH key={idx} style={{textTransform:"capitalize"}}><span>{head}</span></ITH>
							)
						:<ITH>No Items</ITH>
					}
				</ITableHeader>
				<ITableBody style={{verticalAlign: 'baseline'}}>
				{
					item_details && item_details.data && item_details.data.length>0 && item_details.data.map((units,idx1) => (
						<ITableRow key={idx1}>
							<ITD>{idx1+1}</ITD>
							{
								units && units.length>0 &&units.map((unit_item,idx2) => 
									<ITD key={idx2}>
										{Array.isArray(unit_item)?
											<div style={{width:"100%",textAlign:'left'}}>
												{
													unit_item && unit_item.length && unit_item.map((x, idx3)=>(
														<div key={idx3} >{x}</div>
													))
												}
											</div>
											:
											''
										}
										{
											typeof unit_item == "boolean"?
												<Checkbox_input_for_cabinet_modal unit={unit_item} unit_id={item_details.ids[idx1]}/>
												:''
										}
										{
											!Array.isArray(unit_item) && typeof unit_item != "boolean"?
												<div style={{width:"100%",textAlign:'left'}}>{unit_item}</div>
											:''
										}
									</ITD>
								)
							}
						</ITableRow>
					))
				}
				</ITableBody>
			</ITable>
		</div>
	)    
}

const Cabinet_price_details_modal = ({  handle_ui_response, modal_type, handle_close, modal_json, update_view, update_modal_json,  set_page_loader  }) => {

	const [ dummy_modal_json, set_dummy_modal_json ] = useState([]);
	const [ show_edit_other_charges, set_show_edit_other_charges ] = useState(false);

	var cabinet_items_array = ['Cabinets', 'User Components', 'carcass', 'shutter', 'Custom Panel', 'hardware', 'accessories', 'others', 'cbu', 'units']

	const open_edit_other_charges_modal = () => {
		set_show_edit_other_charges(true)
	}

	const close_edit_other_charges_modal = () => {
		set_show_edit_other_charges(false)
	}


	useEffect(() =>{
		if(modal_type == "cabinet_price_details_modal" && modal_json){
			set_dummy_modal_json(JSON.parse(JSON.stringify(modal_json)));
		}
	},[modal_json, modal_type])

	const onclick_handle_close = () =>{
		// set_dummy_modal_json();
		handle_close()
	}
	

	return(
		// <Modal className="modal-dialog-centered" size="xl" isOpen={modal_type==="cabinet_price_details_modal"}>
		// 	<Card className="bg-secondary shadow border-0">
		// 		<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
		// 			<div>
		// 				<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>{modal_json && modal_json.name}</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={onclick_handle_close}>×</span></span></h5>
		// 			</div>
		// 		</CardHeader>
		// 		<CardBody style = {{backgroundColor: COLORS.white, padding:'30px 20px', fontSize:'13px', maxHeight:'500px', overflow:"auto"}}>					
		// 			<Row>
		// 				{dummy_modal_json && dummy_modal_json.overide_margin_details ? 
		// 					<Col><strong>Note: </strong>Overriden Tax = {(dummy_modal_json.overide_margin_details.overriden_tax ? dummy_modal_json.overide_margin_details.overriden_tax : 0)}% Overriden Margin = {(dummy_modal_json.overide_margin_details.overriden_margin ? dummy_modal_json.overide_margin_details.overriden_margin : 0)}% Used for all constituent skus</Col>
		// 					:
		// 					''
		// 				}
		// 			</Row>
		// 			<Row>
		// 				<Col>	
		// 					{
		// 						cabinet_items_array && cabinet_items_array.length>0 &&
		// 						cabinet_items_array.map((item,idx) => 
		// 								<div key={idx}>
		// 									{dummy_modal_json && dummy_modal_json[item] && dummy_modal_json[item].heading && dummy_modal_json[item].heading.length?<Single_table_for_cabinet_pricing  item_details={dummy_modal_json[item]} item_name={item}/>:''}
		// 								</div>
		// 							)
		// 					}				
		// 				</Col>  
		// 			</Row>
		// 		</CardBody>
		// 		<CardFooter style={{backgroundColor:'white',width:'100%', fontSize:'14px', fontWeight:500}}>
		// 			<Edit_other_charges_modal open={show_edit_other_charges} update_modal_json = {update_modal_json} handleclose={close_edit_other_charges_modal}  set_page_loader={set_page_loader}  />
		// 			<div style={{paddingTop:"5px", textAlign:'center'}}>Current Price (in {modal_json && modal_json.currency?modal_json.currency:'INR'}) : {dummy_modal_json&&dummy_modal_json.current_price?dummy_modal_json.current_price:'-'}</div>
		// 			<div style={{paddingTop:"5px", textAlign:'center'}}>Other Charges (in {modal_json && modal_json.currency?modal_json.currency:'INR'}): {dummy_modal_json&&dummy_modal_json.other_charges?dummy_modal_json.other_charges:'-'} <span onClick={open_edit_other_charges_modal} style={{marginLeft:'10px', cursor:"pointer", color:COLORS.mainpage_locale}}>EDIT</span></div>
		// 			<div style={{paddingTop:"5px", textAlign:'center', paddingBottom:'5px'}}>Final Price (in {modal_json && modal_json.currency?modal_json.currency:'INR'}): {dummy_modal_json&&dummy_modal_json.final_price?dummy_modal_json.final_price:'-'}</div>
		// 		</CardFooter> 
		// 	</Card>
		// </Modal>
		<Modal className="modal-dialog-centered" size="xl" style={{maxWidth:'90vw'}} isOpen={modal_type === "cabinet_price_details_modal"}>
			<IModalHeader toggle={onclick_handle_close}>{modal_json && modal_json.name}</IModalHeader>
			<IModalBody style={{ maxHeight: '600px', overflow: "auto" }}>
				{
					dummy_modal_json && dummy_modal_json.description ?
						<div className='mb-3' style={{fontWeight: 600}}>{dummy_modal_json.description}</div>
						:
						''
				}
				{
					dummy_modal_json && dummy_modal_json.overide_margin_details ?
						<div className='mb-3'><strong>Note: </strong>Overriden Tax = {(dummy_modal_json.overide_margin_details.overriden_tax ? dummy_modal_json.overide_margin_details.overriden_tax : 0)}% Overriden Margin = {(dummy_modal_json.overide_margin_details.overriden_margin ? dummy_modal_json.overide_margin_details.overriden_margin : 0)}% Used for all constituent skus</div>
						:
						''
				}
				{
					cabinet_items_array && cabinet_items_array.length > 0 &&
					cabinet_items_array.map((item, idx) =>
						<div key={idx}>
							{dummy_modal_json && dummy_modal_json[item] && dummy_modal_json[item].heading && dummy_modal_json[item].heading.length ? <Single_table_for_cabinet_pricing item_details={dummy_modal_json[item]} item_name={item} /> : ''}
						</div>
					)
				}
			</IModalBody>
			<IModalFooter style={{justifyContent: 'space-between'}}>
				<div>Current Price (in {modal_json && modal_json.currency ? modal_json.currency : 'INR'}) : {dummy_modal_json && dummy_modal_json.current_price ? dummy_modal_json.current_price : '-'}</div>
				<div>Other Charges (in {modal_json && modal_json.currency ? modal_json.currency : 'INR'}): {dummy_modal_json && dummy_modal_json.other_charges ? dummy_modal_json.other_charges : '-'} <span onClick={open_edit_other_charges_modal} style={{ marginLeft: '10px', cursor: "pointer", color: COLORS.blue6 }}>EDIT</span></div>
				<div>Apportioned Charges (in {modal_json && modal_json.currency ? modal_json.currency : 'INR'}): {dummy_modal_json && dummy_modal_json.apportioned_charges ? dummy_modal_json.apportioned_charges : '-'}</div>
				<div>Final Price (in {modal_json && modal_json.currency ? modal_json.currency : 'INR'}): {dummy_modal_json && dummy_modal_json.final_price ? dummy_modal_json.final_price : '-'}</div>
			</IModalFooter>
			<Edit_other_charges_modal open={show_edit_other_charges} update_modal_json={update_modal_json} handleclose={close_edit_other_charges_modal} set_page_loader={set_page_loader}  other_charges_type_modal={dummy_modal_json && dummy_modal_json.other_charges_type ? dummy_modal_json.other_charges_type : 'absolute'} other_charges_value_modal={dummy_modal_json && dummy_modal_json.other_charges_value ? dummy_modal_json.other_charges_value : 0}/>
		</Modal>
	)    
}

const ApportionedChargeSingleRow = ({ index, service, set_service_charges_list, service_charges_list, set_table_key }) => {
	const [selected_charge_type, set_selected_charge_type] = useState('');
	const [charge_value, set_charge_value] = useState(0);
	const [tax, set_tax] = useState(0);

	const delete_row = () => {
		let t = JSON.parse(JSON.stringify(service_charges_list))
		t.splice(index, 1)
		set_service_charges_list(t)
		set_table_key(Date.now() + Math.random())
	}

	const duplicate_row = () => {
		let t = JSON.parse(JSON.stringify(service_charges_list))
		let duplicate_charge = JSON.parse(JSON.stringify(service))
		t.splice(index, 0, duplicate_charge)
		set_service_charges_list(t)
		set_table_key(Date.now() + Math.random())
	}

	useEffect(() => {

		if (service.charge_type) {
			set_selected_charge_type(service.charge_type)
		}else{
			set_selected_charge_type('absolute')
		}

		if (service.tax) {
			set_tax(service.tax)
		}

		if (service.charge_value) {
			set_charge_value(service.charge_value)
		}

	}, []);

	useEffect(() => {
		if (selected_charge_type) {
			service.charge_type = selected_charge_type;
		}
		service.tax = tax;
		service.charge_value = charge_value;
		
		// set_service_charges_list(JSON.parse(JSON.stringify(service_charges_list)))
	}, [selected_charge_type, tax, charge_value]);

	

	return (
		<ITableRow>
			<ITD>{index + 1}</ITD>
			<ITD>
				<Input type='select' value={selected_charge_type} style={{ ...styles_modals.input_styles_none_2 }} onChange={(e) => { set_selected_charge_type(e.target.value) }}>
					<option value={'absolute'}>{"Absolute"}</option>
					<option value={'percentage'}>{"Percentage"}</option>
				</Input>
			</ITD>

			<ITD style={{ position: 'relative' }}>
				{
					<React.Fragment>
						<Input type='number' min={0} value={String(charge_value)} style={{ ...styles_modals.input_styles_none_2, paddingRight: '24px' }} onChange={(e) => { set_charge_value(Number(e.target.value ? e.target.value : 0)) }} />
						{selected_charge_type === 'percentage' ? <span className="percentage-symbol">%</span> : ''}
					</React.Fragment>
				}
			</ITD>
			<ITD>
				{<Input type='number' min={0} value={String(tax)} onChange={(e) => { set_tax(Number(e.target.value ? e.target.value : '')) }} style={{ ...styles_modals.input_styles_none_2 }} />}
			</ITD>
			<ITD style={{ fontWeight: 700, fontSize: 16, textAlign: 'center' }}>
				<UncontrolledDropdown style={{ position: 'unset' }}>
					<DropdownToggle style={{ background: 'unset', boxShadow: 'unset', border: 'unset', position: 'unset', cursor: 'pointer', padding: 'unset', color: 'black' }}>
						...
					</DropdownToggle>
					<DropdownMenu style={{ padding: '4px' }}>
						<DropdownItem onClick={duplicate_row}>Clone Row</DropdownItem>
						<DropdownItem onClick={delete_row}>Delete Row</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			</ITD>
		</ITableRow>
	)
}

const Discount_and_other_charges = ({modal_type, modal_json, handle_close, update_modal_json, handle_ui_response, set_page_loader}) =>{
	const [ discount_value, set_discount_value] = useState(0)
	const [ other_charges_value, set_other_charges_value] = useState(0)
	const [ discount_type, set_discount_type] = useState('percentage')
	const [ other_charges_type, set_other_charges_type] = useState('absolute')
	const [apportioned_charges, set_apportioned_charges] = useState([]);

	const [ is_discount_available, set_is_discount_available] = useState(true)
	const [ is_other_charges_available, set_is_other_charges_available] = useState(true)
	const [ is_apportioned_charges_available, set_is_apportioned_charges_available] = useState(true)
	const [advanced_discounts_enabled, set_advanced_discounts_enabled] = useState(false);
	const [advanced_discount_wood_works, set_advanced_discount_wood_works] = useState([]);
	const [advanced_discount_others, set_advanced_discount_others] = useState([]);
	const [table_key, set_table_key] = useState("initial_table_key");
	const [tabs, set_tabs] = useState(0);
	const alert = useAlert()
    const {t} = useTranslation()

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	}

	const add_row = (variable, set_variable) => {
		if(variable && variable.length){
			for(let i = 0; i < variable.length; i++){
				if(!variable[i].name){
					alert.error('Name must not be empty')
					return
				}
			}
		}
		
		let a = JSON.parse(JSON.stringify(variable))
		a.push({name:'Discount'+(variable.length+1), type: (a && a.length && a[0].type ? a[0].type :'absolute'), value:0})
		set_variable(a)
	}

	const add_row_apportioned_charges = (variable, set_variable) => {
		let a = JSON.parse(JSON.stringify(variable))
		a.push({charge_type: 'absolute', charge_value:0, tax: 0})
		set_variable(a)
	}
	
	const update_advanced_discount_values = (advanced_discount_values, set_advanced_discount_values, idx, key, value) => {
		let a = JSON.parse(JSON.stringify(advanced_discount_values))
		a[idx][key] = value
		set_advanced_discount_values(a)
	}

	const update_discount_type = (advanced_discount_values, set_advanced_discount_values, type, value) => {
		let a = JSON.parse(JSON.stringify(advanced_discount_values))
		a.map(x => x[type] = value)
		set_advanced_discount_values(a)
	}

	const delete_row = (variable, set_variable, idx) => {
		let a = JSON.parse(JSON.stringify(variable))
		a.splice(idx, 1)
		set_variable(a)
	}

	useEffect(()=>{
		if(modal_json && modal_type == "discount_and_other_charges"){
			set_discount_type(modal_json.discount_type?modal_json.discount_type:'percentage')
			set_discount_value(modal_json.discount?modal_json.discount:0)
			// set_absolute_discount_value(modal_json.absolute_discount?modal_json.absolute_discount:0)
			// set_percentage_discount_value(modal_json.percentage_discount?modal_json.percentage_discount:0)
			set_other_charges_type(modal_json.other_charges_type?modal_json.other_charges_type:'absolute')
			set_other_charges_value(modal_json.other_charges?modal_json.other_charges:0)

			set_apportioned_charges(modal_json.apportioned_charges ? modal_json.apportioned_charges : [])

			set_is_discount_available(window.Module.is_feature_enabled("pricing_discount"));
			set_is_other_charges_available(window.Module.is_feature_enabled("pricing_other_charges"));
			set_is_apportioned_charges_available(window.Module.is_feature_enabled("apportioned_charges"));
			set_advanced_discounts_enabled(modal_json.advanced_discounts_enabled?modal_json.advanced_discounts_enabled:false);

			if(modal_json.advanced_discounts && modal_json.advanced_discounts.wood_works && modal_json.advanced_discounts.wood_works.length) set_advanced_discount_wood_works(modal_json.advanced_discounts.wood_works)
			if(modal_json.advanced_discounts && modal_json.advanced_discounts.others && modal_json.advanced_discounts.others.length) set_advanced_discount_others(modal_json.advanced_discounts.others)
		}
	},[modal_json, modal_type])

	const onclick_handle_close = () =>{
		handle_close()
		set_discount_value(0)
		set_discount_type('percentage')
		set_other_charges_type('absolute')
		set_other_charges_value(0)
		set_is_discount_available(true)
		set_is_other_charges_available(true)
		set_advanced_discounts_enabled(false)
		set_advanced_discount_wood_works([])
		set_advanced_discount_others([])
		set_tabs(0)
	}

	const onclick_update = () =>{
		if(Number(discount_value)>=0 && Number(other_charges_value)>=0){
			set_page_loader({
				show: true,
				text: 'Please wait'
			});
			setTimeout(async() => {
				let advanced_discounts = {}
				advanced_discounts["wood_works"] = advanced_discount_wood_works && advanced_discount_wood_works.length ? advanced_discount_wood_works.map(x => {x.value = parseFloat(x.value); return x}) : [];  
				advanced_discounts["others"] = advanced_discount_others && advanced_discount_others.length ? advanced_discount_others.map(x => {x.value = parseFloat(x.value); return x}) : [];
				try{
					await window.Promisify(window.Module.quotation_apply_discounts_and_other_charges(parseFloat(discount_value),discount_type,parseFloat(other_charges_value),other_charges_type, JSON.stringify(advanced_discounts), JSON.stringify(apportioned_charges)));
					update_modal_json();
					onclick_handle_close()
				}catch(err){
					if(err && typeof err === 'string'){
						handle_ui_response(err)
					}
				}
				
				set_page_loader({
					show: false,
					text: 'Please wait'
				});	
			},0)

		}else{
			//show alert for positive number
			console.log('Negative numbers are not allowed')
		}
	}

	const AdvancedDiscountTable = (advanced_discount_values, set_advanced_discount_values) => {
		return(
			<table style={{width:'100%'}}>
				<thead style={{width:'100%'}}>
					<tr className='tr_style' style={{width:'100%'}}>
						<th className='th_style flex_property' style={{flex:2}}><div>{t(`Discount Name`)}</div></th>
						<th className='th_style flex_property' style={{flex:2}}><div>{t(`Discount Type`)}</div></th>
						<th className='th_style flex_property' style={{flex:3}}><div>{t(`Discount Value`)}</div></th>
						<th className='th_style flex_property' style={{flex:1}}><div></div></th>
					</tr>
				</thead>
				<tbody style={{width:'100%'}}>
				{
					advanced_discount_values && advanced_discount_values.length ? advanced_discount_values.map((single_item, idx) => (
						<tr className='tr_style display_onhover' key={idx} style={{width:'100%'}}>
							<td className='td_style flex_center' style={{flex:2}}>
								<Input className='standard_input' style={{fontSize:'12px', border:0}} type='text' value={single_item.name} onChange={(e) => update_advanced_discount_values(advanced_discount_values, set_advanced_discount_values, idx, 'name', e.target.value)} />
							</td>
							<td className='td_style flex_center' style={{flex:2}}>
								{
									idx == 0 ?
										<Input className='standard_input' style={{ fontSize: '12px', border: 0 }} type='select' value={single_item.type} onChange={(e) => update_discount_type(advanced_discount_values, set_advanced_discount_values, 'type', e.target.value)}>
											<option value={'absolute'}>Absolute</option>
											<option value={'percentage'}>Percentage (%)</option>
										</Input>
										:
										<Input className='standard_input' style={{ fontSize: '12px', border: 0 }} type='text' value={single_item.type == 'percentage' ? 'Percentage (%)' : 'Absolute'} readOnly={idx != 0}></Input>
								}
							</td>
							<td className='td_style flex_center' style={{flex:3}}>
								<Input type='number' className='standard_input' style={{fontSize:'12px', border:0}} value={single_item.value} onChange={(e) => (Number(e.target.value)<0 || (single_item.type==='percentage' && Number(e.target.value)>100)) ? alert.error('Please enter a value from 0 to 100') : update_advanced_discount_values(advanced_discount_values, set_advanced_discount_values, idx, 'value', e.target.value)}/>
							</td>
							<td className='td_style flex_center x1' style={{flex:1}}>
								<i className='fa fa-trash invisible1 cp' style={{fontSize:'14px', color:'red'}} onClick={() => delete_row(advanced_discount_values, set_advanced_discount_values, idx)}/>
							</td>
						</tr>
					)):''
				}
				<div onClick={() => add_row(advanced_discount_values, set_advanced_discount_values)} className='flex_property m-3' style={{color:'#2688F7', fontWeight:'700', fontSize:'12px', cursor:'pointer'}}>
					+ Add Row
				</div>
				</tbody>
			</table>
		)
	}

	return(
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type==="discount_and_other_charges"}>
			{/* <Card className="bg-secondary border-0" style={{fontSize:"12px"}}> */}
				{/* <CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color, fontSize:"16px", fontWeight:600}} >
						<div style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}>
							<span>Discounts and Other Charges</span>
							<span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={onclick_handle_close}>×</span>
						</div>
				</CardHeader> */}
				<IModalHeader toggle={onclick_handle_close}>{advanced_discounts_enabled ? "Discounts" : "Discounts and Other Charges"}</IModalHeader>
				<IModalBody style={{maxHeight: '600px', overflow: 'auto'}}>
					<div className='mr-2 mb-2' style={{fontSize:'14px'}}>{t(`Discounts : `)}</div>
					{/* {is_discount_available?(<Row className='mb-4'>
						<Col className='flex_property'>
							<div className='mr-2'>Use Discount Type : </div>
							<div className='lines1_elipsis flex_center' key={1} onClick={() => set_advanced_discounts_enabled(false)} style={!advanced_discounts_enabled ? {width:'20%', borderTopLeftRadius:'5px', borderBottomLeftRadius:'5px', border:'1px solid', borderColor: COLORS.blue6, padding:'4px', cursor:'pointer', fontWeight:700, color:COLORS.blue6 } : {width:'20%', borderTopLeftRadius:'5px', borderBottomLeftRadius:'5px', border:'1px solid', borderColor:'#C5C7CF', borderRightColor:'transparent', padding:'4px', cursor:'pointer', backgroundColor:COLORS.gray2 }} title={'Normal Discounts'}>
								Normal Discounts
							</div>
							<div className='lines1_elipsis flex_center' key={2} onClick={() => set_advanced_discounts_enabled(true)} style={advanced_discounts_enabled? {width:'20%', borderTopRightRadius:'5px', borderBottomRightRadius:'5px', border:'1px solid', borderColor: COLORS.blue6, padding:'4px', cursor:'pointer', fontWeight:700, color:COLORS.blue6 } : {width:'20%', borderTopRightRadius:'5px', borderBottomRightRadius:'5px', border:'1px solid', borderColor:'#C5C7CF', borderLeftColor:'transparent', padding:'4px', cursor:'pointer', backgroundColor:COLORS.gray2 }} title={'Advanced Discounts'}>
								Advanced Discounts
							</div>
						</Col>  
					</Row>):''} */}
					{
						advanced_discounts_enabled && is_discount_available?
						<React.Fragment>
							<div style={{border:'1px solid rgba(0,0,0,.1)', width:'96%', margin:'0px 16px'}}>
								<Nav
									style={{display:'flex', alignItems:'center', fontSize:'12px', backgroundColor:'white', padding:'2px 0px 2px 0px', position:'sticky', top:'0', zIndex:'1'}}
									className="flex-md-row m-1 ml-2"
									id="tabs-icons-text" 
									pills
									role="tablist"
								>
									<NavItem>
										<NavLink
											aria-selected={tabs === 0}
											style = {{color:tabs === 0 ? '#1070CA' : '#435A6F', backgroundColor:tabs === 0 ? 'rgba(16, 112, 202, 0.09)' : 'transparent', marginLeft:tabs===1?'':''}}
											className={classnames("mb-sm-3 mb-md-0", {
												active: tabs === 0
											})}
											onClick={e => toggleNavs(e, 0)}
											href="#pablo"
											role="tab"
										>
											<span>For Woodworks</span>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											aria-selected={tabs === 1}
											style = {{color:tabs === 1 ? '#1070CA' : '#435A6F', backgroundColor:tabs === 1 ? 'rgba(16, 112, 202, 0.09)' : 'transparent'}}
											className={classnames("mb-sm-3 mb-md-0", {
												active: tabs === 1
											})}
											onClick={e => toggleNavs(e, 1)}
											href="#pablo"
											role="tab"
										>
											<span>For other items</span>
										</NavLink>
									</NavItem>
								</Nav>
								<hr style={{margin:0}} />
								<TabContent activeTab={"tabs" + tabs}>
									<TabPane tabId={'tabs0'} style={{maxHeight:'300px', overflow:'auto'}}>
										{AdvancedDiscountTable(advanced_discount_wood_works, set_advanced_discount_wood_works)}
									</TabPane>
									<TabPane tabId={'tabs1'} style={{maxHeight:'300px', overflow:'auto'}}>
										{AdvancedDiscountTable(advanced_discount_others, set_advanced_discount_others)}
									</TabPane>
								</TabContent>
							</div>
						</React.Fragment>
						:(
							is_discount_available?(
								<React.Fragment>
									<Row style={{margin:0, marginBottom:"12px"}}> 
										<Col>
											<div style={{fontWeight:600}}>{t(`Select Discount Type`)}</div>
											<Input style={{fontSize:'12px'}} 
												onChange={e => set_discount_type(e.target.value)} 
												value={discount_type} 
												type='select' 
											>
												<option value='percentage'>Percentage</option>
												{window.Module.is_feature_enabled("pricing_absolute_discount") == true && <option value='absolute'>Absolute</option>}
											</Input>
										</Col>  
									</Row>
									<Row style={{margin:0, marginBottom:"5px"}}> 
										<Col>
											<div style={{fontWeight:600}}>{discount_type==='percentage'?'Percentage discount(in %) :':'Absolute discount(in ' + (modal_json && modal_json.currency?modal_json.currency:'INR') + ') :' }</div>
											<Input style={{fontSize:'12px'}} onChange={e => set_discount_value(e.target.value)} value={discount_value?discount_value:0} type='number' />
										</Col>  
									</Row>
								</React.Fragment>
							):''
						)
					}
					{is_other_charges_available && !advanced_discounts_enabled?(<Row style={{marginTop:'20px', marginBottom:'16px'}}>
						<Col>
							<div style={{fontSize:'14px'}}>{t(`Other Charges : `)}</div>
						</Col>  
					</Row>):''}
					{is_other_charges_available && !advanced_discounts_enabled?(<Row style={{margin:0, marginBottom:'12px'}}> 
						<Col>
							<div style={{fontWeight:600}}>{t(`Select Charge Type`)}</div>
							<Input style={{fontSize:'12px'}} 
								onChange={e => set_other_charges_type(e.target.value)} 
								value={other_charges_type} 
								type='select' 
							>
								<option value='percentage'>Percentage</option>
								<option value='absolute'>Absolute</option>
							</Input>
						</Col>  
					</Row>):''}
					{is_other_charges_available && !advanced_discounts_enabled?(<Row style={{margin:0, marginBottom:"5px"}}> 
						<Col>
							<div style={{fontWeight:600}}>{other_charges_type==='percentage'?'Percentage Charge(in %) :':'Absolute Charge(in ' + (modal_json && modal_json.currency?modal_json.currency:'INR') + ') :' }</div>
							<Input style={{fontSize:'12px'}} onChange={e => set_other_charges_value(e.target.value)} value={other_charges_value?other_charges_value:0} type='number' />
						</Col>  
					</Row>):''}

					{
						is_apportioned_charges_available ?
							<React.Fragment>
								<Row style={{ marginTop: '20px', marginBottom: '16px' }}>
									<Col>
										<div style={{ fontSize: '14px' }}>{t(`Apportioned Charges : `)}</div>
									</Col>
								</Row>
								<Row style={{ margin: '0px', marginBottom: '5px' }}>
									<Col>
										<ITable key={table_key}>
											<ITableHeader>
												<ITH>S.No</ITH>
												<ITH>Charge Type</ITH>
												<ITH>Charge Value</ITH>
												<ITH>Tax</ITH>
												<ITH>Actions</ITH>
											</ITableHeader>
											<ITableBody>
												{
													apportioned_charges && apportioned_charges.length ? apportioned_charges.map((charge, idx) => (
														<ApportionedChargeSingleRow service={charge} index={idx} service_charges_list={apportioned_charges} set_service_charges_list={set_apportioned_charges} set_table_key={set_table_key}/>
													))
														:
														''
												}
											</ITableBody>
										</ITable>
									</Col>
								</Row>
								<div className='flex_property m-3' style={{ color: '#2688F7', fontWeight: '700', fontSize: '12px'}}>
									<div className='p-2 cp' onClick={() => add_row_apportioned_charges(apportioned_charges, set_apportioned_charges)} >+ Add Row</div>
								</div>
							</React.Fragment>
							: ''
					}
				</IModalBody>
			{/* </Card> */}
			<IModalFooter>
				<Button onClick={onclick_handle_close} className = "secondary_button_default"type="button">Cancel</Button>
				<Button onClick={onclick_update} className='primary_button_default' type="button">Apply</Button>
			</IModalFooter>
        </Modal>
	)

}

const ButtonsArray = ({button_list, icon_on_click, ...props}) => {
	return(
		<div className='flex_property panel_button_array' {...props}>
			{
				button_list && button_list.length ? button_list.map((item, idx) => (
					item.type == "switch" ?
						<Switch key={idx} disabled={item.disabled} style={{ color: 'green' }} size='small' checked={item.value == "Off" ? false : true} onClick={(checked, e) => { e.stopPropagation(); icon_on_click({ route: item.onclick, form: { id: item.name } }) /*window.Module[item.onclick](JSON.stringify({id: item.name}))*/ }} />
						: item.type == 'label' ?
							<div key={idx} style={{ cursor: 'pointer', fontSize: '12px', color: COLORS.black, textTransform: 'capitalize', fontWeight: 400 }}>{item.name}</div>
							: item.icon == "+" ?
								<span key={idx} title={item.disabled_message ? item.disabled_message : ''} className={item.disabled ? 'panel_disabled' : 'button_panel'} style={{ fontSize: '12px', color: COLORS.blue6, textTransform: 'capitalize', display: 'flex' }} onClick={(e) => { icon_on_click({ route: item.onclick, form: { id: item.name } }) /*; window.Module[item.onclick](JSON.stringify({}))*/ }}><img style={{ marginRight: '4px' }} height='12px' src='/resources/panel_icons_new/blue_add.svg'></img> Add </span>
								:
								<img key={idx} src={item.icon} title={item.disabled_message ? item.disabled_message : ''} className={item.disabled ? 'panel_disabled' : 'button_panel'} style={{ height: '16px' }} onClick={(e) => { e.stopPropagation(); icon_on_click({ route: item.onclick, form: { id: item.name } }) /*; window.Module[item.onclick](JSON.stringify({id: item.name}))*/ }} />
				))
					: ''
			}
		</div>
	)
}

const ImageMapSelect = ({json_item, parent_item, handle_ui_response, update_view, common_on_click}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen(!dropdownOpen);

	const [value, set_value] = useState('');
	const [img_src, set_img_src] = useState('');
	const [options, set_options] = useState([]);

	useEffect(() => {
		let url;
		if(json_item){
			set_value(json_item.default_value)
			if(json_item.icon !== img_src && json_item.icon){
				url = (json_item.icon.includes('FS') ? get_image_url_from_FS(json_item.icon) : json_item.icon)
				set_img_src(url)
			}
		}
		return () => {
			if(json_item.icon && json_item.icon.includes('FS') && url){
				URL.revokeObjectURL(url)
			}
		}
	}, [json_item.default_value, json_item.icon]);

	useEffect(() => {
		let options_local;
		if(json_item.options){
			options_local = (json_item.options.map(option => {
				let option_copy = JSON.parse(JSON.stringify(option))
				option_copy.icon = option_copy.icon && option_copy.icon.includes('FS') ? get_image_url_from_FS(option_copy.icon) : option_copy.icon
				return option_copy
			}))
			set_options(options_local)
		}

		return () => {
			if(json_item.options && options_local){
				for(let i in json_item.options){
					if(json_item.options[i].icon && json_item.options[i].icon.includes('FS') && options_local[i] && options_local[i].icon){
						URL.revokeObjectURL(options_local[i].icon)
					}
				}
			}
		}
	}, [json_item.options]);
	
	return (
		<div className='px-2' style={{ paddingTop: '15px', paddingBottom: '14px', borderBottom: '1px solid #E6E9EE' }}>
			<input style={{display: 'none'}} value={value} id={json_item.id}/>
			<div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
				{json_item.image_placement === 'inline_left' ? <img className='mr-2' src={img_src} height={'32px'} style={{maxWidth:'64px', objectFit: 'cover', border: '1px solid ' + COLORS.gray9, borderRadius: '2px'}}></img> : ''}
				<Dropdown className='class_position_unset' isOpen={dropdownOpen} style={{ all: 'unset', listStyle: 'none', flex: 1, minWidth: '40px' }} toggle={json_item && json_item.disabled ? null : toggle}>
					<DropdownToggle style={{ display: 'flex', flexFlow: 'row', backgroundColor: 'white', cursor: 'pointer', color: 'black', fontSize: '12px', padding: '4px 12px', border: '1px solid  ' + COLORS.panel_item_borders, alignItems: 'center', width: '100%', boxShadow: 'none' }}>
						<div style={{ display: 'flex', flexFlow: 'row', fontSize: '12px', alignItems: 'center', flex: 1, minWidth: 0, overflow: 'auto'}}>
							<div title={value && json_item.options && json_item.options.find(x => x.value == value) ? json_item.options.find(x => x.value == value).name : ''} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>{value && json_item.options && json_item.options.find(x => x.value == value) ? json_item.options.find(x => x.value == value).name : ''}</div>
						</div>
						<span><i className='fa fa-caret-down' /></span>
					</DropdownToggle>
					<DropdownMenu className="image_map_select_dropdown">
						{options && options.map((option, idx) => (
							<DropdownItem title={option.name} style={{ listStyle: 'none', padding: '4px' }} onClick={() => {set_value(option.value); common_on_click(option.value, parent_item, json_item);}} key={idx}>
								<div className='flex_property'>
									<img className='mr-2'  height={'40px'} width={'80px'} style={{objectFit: 'cover'}} src={option.icon} ></img>
									<div className='lines1_elipsis'>{option.name}</div>
								</div>
							</DropdownItem>
						))}
					</DropdownMenu>
				</Dropdown>
				{
					json_item.buttons && json_item.buttons.length ? <ButtonsArray button_list={json_item.buttons} icon_on_click={()=>{}} style={{marginLeft: '8px'}}/> : ''
				}
			</div>
			{!json_item.image_placement ? <img className='mt-3' src={img_src} width={'100%'} style={{border: '1px solid ' + COLORS.gray9, borderRadius: '2px'}}></img> : ''}
		</div>
	)
}

const Slider = ({json_item, parent_item, handle_ui_response, update_view, slider_type, isLast, common_on_click}) => {
	const [value, setValue] = useState(0);
	const alert = useAlert()

	const handleChange = (event) => {
		// common_onchange_handler()
		let val = event.target.value ? parseFloat(event.target.value) : 0
		if(val >= (json_item.min || 0) && val <= (json_item.max || 100)){
			setValue(String(val));
		}
		else{
			alert.error("Enter a valid value")
		}
	};

	useEffect(() => {
		if(json_item.mixed){
			setValue('')
		}
		else if(json_item.default_value){
			setValue(json_item.default_value)
		}else{
			setValue(0)
		}
	}, [json_item.default_value]);

	return (
		<Card style={{ border:'0px solid', borderWidth: slider_type?'0px':'0px 0px 1px', borderBottom: (slider_type || isLast)?'0px solid':'1px solid #E6E9EE'/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }}  className={json_item.disabled?'panel_disabled':'II----------II'}>
			<CardBody style={{ padding:'15px',/* paddingTop:index ? 0 : 15,*/ backgroundColor:COLORS.panel_card_item}} >
			<Row>
				<Col style={{fontSize:12,alignItems:"center", cursor:"default", paddingLeft:'8px', marginBottom:'12px', fontWeight:500, color:COLORS.panel_group_heading, display: json_item.onchange_route ? 'flex' : 'none'}}><span title={json_item.name == 'Ambient Intensity (%)' ? 'Intensity (%)' : json_item.name} className='lines1_elipsis' >{json_item.name == 'Ambient Intensity (%)' ? 'Intensity (%)' : json_item.name}</span></Col>
			</Row>
			<Row style={{/*marginBottom:'15px'*/}}>
				<Col xs={slider_type?12:8} style={{fontSize:14,display:json_item.onchange_route ? 'flex' : 'none',alignItems:"center", padding:'0px 8px'}}>
					{/* <div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15}}> */}
						{/* <div className='createcard_input_container' style={{display:json_item.onchange_route ? 'flex' : 'none', width:'100%', border:'1px solid ' + COLORS.panel_item_borders, padding:'0px 4px'}}> */}
							{/* <div style={{flex:1, color:json_item.onchange_route ? '#FF5247' : COLORS.panel_group_heading, fontWeight: json_item.onchange_route ? 400 : 700, fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center'}}>{json_item.name}</div> */}
							<div style={{width:'100%'}}>
								<div className='flex_property'>
									<div style={{borderRight:'2px solid #C5C7CF', height:'6px', borderRadius:'4px'}}/>
									<input
										type="range"
										min={json_item.min ? json_item.min : 0}
										max={json_item.max ? json_item.max : 100}
										step={1}
										value={value}
										onChange={handleChange}
										onMouseUp={(e) => common_on_click(e.target.value, parent_item, json_item)}
										className='slider'
									/>
									<div style={{borderRight:'2px solid #C5C7CF', height:'6px', borderRadius:'4px'}}/>
								</div>
								{slider_type ?
								<div style={{ fontSize:'12px', display:'flex', alignItems:'end', justifyContent:'space-between', width:'100%', marginTop:'4px'}}>
								<span>{'left'}</span>
								<span>{'right'}</span>
								</div>:
								<div style={{ fontSize:'12px', display:'flex', alignItems:'end', justifyContent:'space-between', width:'100%', marginTop:'4px'}}>
									<span>{json_item.min ? json_item.min : 0}</span>
									<span>{json_item.max ? json_item.max : 100}</span>
								</div>}
							</div>
						{/* </div> */}
					{/* </div> */}
				</Col>
				{
				<Col xs={4} style={{fontSize:12,display: slider_type?'none':'flex',alignItems:"center", justifyContent:'flex-end', padding:'0px 8px'}}>
					{/* <div style={{ paddingRight: '8px', paddingLeft: '8px', marginLeft: -15, marginRight: -15 }}> */}
						{<div className='createcard_input_container' style={{ display: json_item.onchange_route ? 'flex' : 'none', width: '100%', border: '1px solid ' + COLORS.panel_item_borders, padding: '0px 4px' }}>
							<input key={"key_" + json_item.id} id={json_item.id} value={value} onChange={(e) => {handleChange(e);}} onBlur={(e) => {common_on_click(e.target.value, parent_item, json_item)}} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } else if (e.key == 'Escape') { e.target.value = json_item.mixed ? '' : (json_item.default_value || 0); e.target.blur() } }} type="number" style={{ ...styles_modals.input_styles, border: 'none', flex: 5, height: '22px', outline: 'none', display: json_item.onchange_route ? 'block' : 'none' }} placeholder={json_item.mixed ? "Mixed" : ''}/>
						</div>}
					{/* </div> */}
				</Col>}
			</Row>
			</CardBody>
		</Card>
	)
}

const ColorPicker = ({json_item, parent_item, handle_close, is_grouped, configuration_type, handle_ui_response, update_view, common_on_click, styles_container, styles, styles_body}) => {

	const handleSelectInputChange = (event) => {
		event.target.blur();
	}

	useEffect(() => {
		window.addEventListener('change', handleSelectInputChange);
		return () => {
			window.removeEventListener('change', handleSelectInputChange);
		}
	}, []);

	return(
		<Card style={{ border:'0px solid', ...styles_container/*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/}} className={json_item.disabled?'panel_disabled CP----------CP':'CP----------CP'}>
			<CardBody style={{ padding:'22px 15px', backgroundColor:COLORS.panel_card_item, ...styles}} >
				{
					is_grouped ? 
						<div style={{maxWidth: '100%'}}>
							<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15, display: 'flex'}}>
								<div style={{display:'flex', width:'100%', padding:'0px 0px'}}>
									<div style={{color: json_item.color ? json_item.color : COLORS.gray6 , fontSize:'12px', fontWeight:500}} title={json_item.name == 'Ambient Color' ? 'Color' : json_item.name}>{json_item.name == 'Ambient Color' ? 'Color' : json_item.name}</div>
									{/* <Input type="text" onChange={common_onchange_handler} onBlur={on_click} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=approxMM(json_item.default_value) || 0 ; e.target.blur()}}} defaultValue={approxMM(json_item.default_value)||0} key={"key_" + json_item.id} id={json_item.id} style={{...styles_panel.input_styles, border:'none', flex:5, height:'24px'}} placeholder="0"/> */}
								</div>
							</div>
							<div style={{paddingRight:'8px', paddingLeft:'8px', marginLeft: -15, marginRight: -15, ...styles_body}}>
								<div className='createcard_input_container' style={{display:'flex', width:'100%', border:'1px solid ' + COLORS.panel_item_borders, padding:'0px 4px'}}>
									{/* <div style={{flex:1, color: json_item.color ? json_item.color : COLORS.gray5 , fontSize:'12px', display: configuration_type == 'group_by_2' || configuration_type == 'names_above' ? 'none' : 'flex', alignItems:'center', justifyContent:'center'}}>{json_item.name ? json_item.name[0] : ''}</div> */}
										<React.Fragment>
											<Input type="color" onChange={() => {}} onBlur={(e) => {common_on_click(e.target.value, parent_item, json_item)}} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=(json_item.default_value) ; e.target.blur()}}} defaultValue={(json_item.mixed ? '' : json_item.default_value)} key={"key_color" + json_item.id} id={json_item.id} style={{border:'none', flex: json_item.mixed ? 'unset' : 5, height:'24px', padding:0, width: json_item.mixed ? '24px': '100%'}}/>
											{json_item.mixed ? <div className='flex_property' style={{border:'none', flex:5, height:'24px', padding:0}} onClick={() => {}}>Mixed</div> : ''}
										</React.Fragment>
								</div>
							</div>
						</div>
					:
						<Row>
							<Col xs={6} style={{fontSize:'12px', display:"flex", alignItems:"center", cursor:"default", paddingLeft:'8px', paddingRight:'8px'}}><span title={json_item.name}>{json_item.name}</span></Col>
							<Col xs={6} style={{display:"flex", alignItems:"center", justifyContent:'flex-start', paddingLeft:'8px', paddingRight:'8px'}}>
								<FormGroup className='createcard_input_container' style={{margin:0, width:'100%', border: '1px solid ' + COLORS.panel_item_borders, display: 'flex'}}>
									<React.Fragment>
										<Input id={json_item.id} key={"key_color_"+json_item.id} onChange={() => {}} onBlur={(e) => {common_on_click(e.target.value, parent_item, json_item)}} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=(json_item.default_value) ; e.target.blur()}}} defaultValue={json_item.mixed ? '' : json_item.default_value} style={{ padding: '0px', height: '30px', width: '100%', border:'none', width: json_item.mixed ? '24px': '100%'}} type="color" />
										{/* {json_item.mixed ? <div style={{ padding: '0px', height: '30px', width: '100%', border:'none' }} onClick={() => document.getElementById(json_item.id).click()}>Mixed</div> : ''} */}
										{json_item.mixed ? <div className='flex_property' style={{border:'none', flex:5, height:'24px', padding:0}} onClick={() => {document.getElementById(json_item.id).focus(); document.getElementById(json_item.id).click();}}>Mixed</div> : ''}
									</React.Fragment>
								</FormGroup>
							</Col>
						</Row>
				}
			</CardBody>
		</Card>
		// <KelvinColorPicker/>
	)
}

const Text_Input = ({json_item, parent_item, handle_close, set_page_loader, handle_ui_response, update_view, is_grouped, configuration_type, common_on_click, text_input_type, styles, styles_body, styles_container}) => {

	const formatName = (name) => {
		if(name == 'Hours') return 'H'
		if(name == 'Minutes') return "Min"
		return name
	}

	return (
	<Card key={json_item.id} style={{ border:'0px solid', ...styles_container /*borderBottom:'2px solid', borderBottomColor:COLORS.panel_item_separation*/ }}  className={json_item.disabled?'panel_disabled_opacity':'TI----------TI'}>
		<CardBody style={{ padding:'14px', paddingTop: '6px', backgroundColor:COLORS.panel_card_item, ...styles_body}} >
			{
				<>
					{text_input_type != 2 ?
					<Row style={{fontSize:12,display:'flex',alignItems:"center", cursor:"default", paddingLeft:'8px', paddingRight:'8px', fontFamily: 'DM Sans', color: '#676878', fontWeight: 500}}>
						<span title={formatName(json_item.name)}>{formatName(json_item.name)}</span>
					</Row> : ''}
					<Row style={{fontSize:12,display:'flex',alignItems:"center", paddingLeft:'8px', paddingRight:'8px', ...styles}}>
							{text_input_type == 2 ? 
							<div className='createcard_input_container' style={{display:json_item.onchange_route ? 'flex' : 'none', width:'100%', border:'1px solid ' + COLORS.panel_item_borders, padding:'0px 4px', alignItems: 'center', gap: '4px'}}>
							<div style={{flex:1, color:json_item.onchange_route ? COLORS.panel_group_heading : '#FF5247', fontWeight: json_item.onchange_route ? 400 : 700, fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center'}}>{formatName(json_item.name)}</div>
							<input key={"key_"+json_item.id} onBlur={(e) => {json_item.onchange_route ? common_on_click(e.target.value, parent_item, json_item) : null}} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=json_item.mixed ? '' : ((json_item.default_value) || '') ; e.target.blur()}}} defaultValue={json_item.mixed ? '' : ((json_item.default_value)||'')} type="text"  id={json_item.id} style={{...styles_modals.input_styles, border:'none', outline: 'none', height: '24px', width: '100%'}} placeholder={json_item.mixed ? "Mixed" : "0"}/>
							</div> :
							<div className='createcard_input_container' style={{border: '1px solid ' + COLORS.panel_item_borders}}>
								<Input disabled={json_item.disabled} key={"key_"+json_item.id} onBlur={(e) => {json_item.onchange_route ? common_on_click(e.target.value, parent_item, json_item) : null}} onKeyDown={(e) => {if(e.key === 'Enter'){e.target.blur()}else if(e.key == 'Escape'){e.target.value=json_item.mixed ? '' : ((json_item.default_value) || '') ; e.target.blur()}}} defaultValue={json_item.mixed ? '' : ((json_item.default_value)||'')} type="text"  id={json_item.id} style={{...styles_modals.input_styles, border:'none', height: '100%', width: '100%'}} placeholder={json_item.mixed ? "Mixed" : "0"}/>
							</div>}
					</Row>
				</>
			}
		</CardBody>
	</Card>
	)
}

const DropDown = ({json_item, parent_item, sunlight_json, sunlight_parent_json, is_grouped, put_header, handle_ui_response, update_view, common_on_click}) => {

	const panelItemWidth = (parent_item, item) => {
		if(!parent_item || !(parent_item.submenu) || parent_item.submenu.length <= 1)
			return '100%'
		if (parent_item.configuration_type == 'group_by_2' && (item.type == 'text_input' || item.type == 'toggle' || item.type == 'select_input' || item.type == 'colorpicker')) {
			return '50%'
		} else if (item.type == 'text_input' || item.type == 'colorpicker' || (item.type == 'select_input')) {
			return '33.3%'
		}
		return '100%'
	}

	return (
		<>
		{put_header ?
		<div style={{width: '100%', color: '#676878', fontWeight: '500', fontFamily: 'DM Sans', textAlign: 'left', padding: '10px 0 0 8px'}}>{json_item.name}</div>
		: json_item.name == 'Ambient Lighting' ? 
		<div className='lines1_elipsis flex_center' key={1} onClick={() => {}} style={{borderRadius: '6px', padding:'4px', fontWeight:400, width: '100%', height: '6%', margin: '12px 12px 0 12px', backgroundColor: '#E6E9EE'}} title={json_item.name}>
		{json_item.icon ? <img className='mr-2' src={json_item.icon}></img> : ''}
		{json_item.name}
		</div>
		: ''}
		{json_item && json_item.submenu && json_item.submenu.length ? json_item.submenu.map((sub_item, idx) => (
			<>
			{sub_item.name == 'Hours' ? 
				<ColorPicker styles_container={{width: '50%', borderBottom: '1px solid #E6E9EE'}} styles_body={{paddingRight: '16px'}} styles={{paddingTop: 0, paddingBottom: '8px'}} json_item={sunlight_json} parent_item={sunlight_parent_json} is_grouped={is_grouped} handle_ui_response={handle_ui_response} update_view={update_view} common_on_click={common_on_click}/>
				 : ''}
			<div style={{width: ['Minutes', 'Hours'].includes(sub_item.name) ? '50%' : panelItemWidth(json_item, sub_item), borderBottom: sub_item.type == 'text_input' || sub_item.type == 'colorpicker' ?'1px solid':'', borderColor: sub_item.type == 'text_input' || sub_item.type == 'colorpicker' ?'#E6E9EE':'', display: sub_item.name == 'Minutes' ? 'none' : ''}}>
				{sub_item.type == "image_map_select" ?
				<ImageMapSelect json_item={sub_item} parent_item={json_item} handle_ui_response={handle_ui_response} update_view={update_view} common_on_click={common_on_click}/>
				: sub_item.type == 'slider' ?
				<Slider json_item={sub_item} parent_item={json_item} handle_ui_response={handle_ui_response} update_view={update_view} common_on_click={common_on_click} isLast={idx == json_item.submenu.length - 1 && parent_item.name == 'Sunlight'}/>
				: sub_item.type == 'text_input' && sub_item.name == 'Hours' ?
				<div>
					<div style={{color: COLORS.gray6 , fontSize:'12px', fontWeight:500, textAlign: 'left'}}>Time</div>
					<div style={{display: 'flex', marginRight: '8px'}}>
					<Text_Input styles_body={{paddingTop: '0px', paddingBottom: '8px'}} styles={{paddingRight: idx==json_item.submenu.length-1 ? '' : 0, paddingLeft: idx ? 0 : ''}} json_item={sub_item} is_grouped={is_grouped} parent_item={json_item} handle_ui_response={handle_ui_response} update_view={update_view} common_on_click={common_on_click} text_input_type={2}/>
					{json_item && json_item.submenu && json_item.submenu.length && json_item.submenu.find(item => item.name == 'Minutes') ?
					<Text_Input styles_body={{paddingTop: '0px', paddingBottom: '8px'}} styles={{paddingRight: (idx+1)==json_item.submenu.length-1 ? '' : 0, paddingLeft: (idx+1) ? 0 : ''}} json_item={json_item.submenu.find(item => item.name == 'Minutes')} is_grouped={is_grouped} parent_item={json_item} handle_ui_response={handle_ui_response} update_view={update_view} common_on_click={common_on_click} text_input_type={2}/> : ''}
					</div>
				</div>
				: sub_item.type == 'text_input' && !['Hours', 'Minutes'].includes(sub_item.name) ?
				<Text_Input styles={{paddingRight: idx==json_item.submenu.length-1 ? '' : 0, paddingLeft: idx ? 0 : ''}} json_item={sub_item} is_grouped={is_grouped} parent_item={json_item} handle_ui_response={handle_ui_response} update_view={update_view} common_on_click={common_on_click} text_input_type={2}/>
				: sub_item.type == 'dropdown' ?
				<DropDown json_item={sub_item} parent_item={json_item} is_grouped={sub_item.group_child_inputs} put_header={json_item.configuration_type == "group_by_2" && json_item.submenu.length > 1} handle_ui_response={handle_ui_response} update_view={update_view} common_on_click={common_on_click}/>
				: sub_item.type == 'colorpicker' && sub_item.name == 'Ambient Color' ?
				<ColorPicker json_item={sub_item} parent_item={json_item} is_grouped={is_grouped} handle_ui_response={handle_ui_response} update_view={update_view} common_on_click={common_on_click}/>
				: sub_item.type == 'slider_noshow' ?
				<Slider json_item={sub_item} parent_item={json_item} slider_type={true} handle_ui_response={handle_ui_response} update_view={update_view} common_on_click={common_on_click}/>
				:''}
			</div>
			</>
		)) : ''}
		</>
	)
}

const RTX_Render = ({  modal_type, modal_json, handle_close, set_page_loader, handle_ui_response, update_view, update_modal_json }) => {

	const [dummy_modal_json, set_dummy_modal_json] = useState({})
    const {t} = useTranslation()
	const [render_preview_args, set_render_preview_args] = useState(new Array(10))
	const [fov, set_fov] = useState(45)
	// const modal_json_new = JSON.parse(JSON.stringify(modal_json))

	const styles = {
		button_for_render:{
			backgroundColor:'#009688',
			color:'white',
			width:'100%',
			boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
		}
	}
	const alert = useAlert();

	const [aspect_ratios, set_aspect_ratios] = useState({});
	const [active_view_name, set_active_view_name] = useState('');
	const [active_tab, set_active_tab] = useState(0);
	const [render_preview_ready, set_render_preview_ready] = useState(false);
	const iframeRef = useRef(null);
	const [is_loading, set_is_loading] = useState(true);
	const [init_render_preview, set_init_render_preview] = useState(false);
	const [args_changed, set_args_changed] = useState(false)

	const [sunlight_json, set_sunlight_json] = useState('')
	const [sunlight_parent_json, set_sunlight_parent_json] = useState('')

	const quality_to_name = {
		'HD': 'HD',
		'Full_HD': 'FHD',
		'4K': '4K',
		'C_4K': 'HD 360',
		'C_6K': 'FHD 360',
		'C_8K': '4K 360'
	}

	useEffect(() => {
		window.addEventListener('message', handleMessage);
		return () => {
		  window.removeEventListener('message', handleMessage);
		};
	  }, []);
	
	  const handleMessage = (event) => {
		if(event.data === 'Loading Done') {
			set_is_loading(false);
		}
		if(event.data === 'Loading Started') {
			set_is_loading(true);
		}
	  };

	useEffect(() => {
		if(!init_render_preview && !is_loading){
			set_init_render_preview(true)
		}
	}, [is_loading])

	const common_on_click = async(val, parent_item, json_item) => {
		// console.log("KP common_on_click", val, parent_item, json_item)
		var form = {
			id: parent_item.id
		}
		if(parent_item && parent_item.submenu){
			for (var i = 0; i < parent_item.submenu.length; i++) {
				if(parent_item.submenu[i].id == json_item.id){
					if(document.getElementById(parent_item.submenu[i].id)){
						if(json_item.type == 'slider' || json_item.type == 'slider_noshow'){
							parse_json(json_item, Number(val));
							form[parent_item.submenu[i].id] = Number(val);
						}
						else{
							parse_json(json_item, val);
							form[parent_item.submenu[i].id] = val;
						}
					}
				}
				else if(parent_item.submenu[i].type == "text_input"){
					if(document.getElementById(parent_item.submenu[i].id)){
					form[parent_item.submenu[i].id] = document.getElementById(parent_item.submenu[i].id).value;
					}
				}
				else if(parent_item.submenu[i].type == "image_map_select"){
					if(document.getElementById(parent_item.submenu[i].id)){
						form[parent_item.submenu[i].id] = document.getElementById(parent_item.submenu[i].id).value;
					}
				}else if(parent_item.submenu[i].type == 'slider'){
					if(document.getElementById(parent_item.submenu[i].id)){
						form[parent_item.submenu[i].id] = Number(document.getElementById(parent_item.submenu[i].id).value);
					}
				} else if(parent_item.submenu[i].type == 'slider_noshow'){
					if(document.getElementById(parent_item.submenu[i].id)){
						form[parent_item.submenu[i].id] = Number(document.getElementById(parent_item.submenu[i].id).value);
					}
				} else if(parent_item.submenu[i].type == "colorpicker"){
					if(document.getElementById(parent_item.submenu[i].id)){
						form[parent_item.submenu[i].id] = document.getElementById(parent_item.submenu[i].id).value;
					}
				}
			}
		}
		form['from_modal'] = true
		// console.log("KP common_on_click", val, json_item, form)
		var promise_resp;
		try{
			promise_resp = window.Module[json_item.onchange_route](JSON.stringify(form));
			set_args_changed(true);

		} catch(err){
			err.name = "Captured error in Render Preview Modal, onclick error at " + json_item.onchange_route + ", value=" + JSON.stringify(form) + " : " + err.name
			console.error(err);
			window.sentry_capture_exception("",err);
		}	
		var ui_response = await window.Promisify(promise_resp);
		handle_ui_response(ui_response);
		update_view()
	}

	const switch_render_tab = (new_tab_id) => {
		var analytics_data = {}
		if (new_tab_id == 0){
			analytics_data.category = "Camera Preview Clicked";
		}else if (new_tab_id == 1){
			analytics_data.category = "Render Preview Clicked";
		}
		analytics_data.route = "modal"
		send_analytics(analytics_data,true);

		if((!render_preview_ready) && new_tab_id == 1){
			window.Module.trigger_render_preview_gltf();

			var current_env_map = window.Module.get_current_env_map() + "_compressed.hdr";
			if(window['render_preview_env_map_rotation'] == undefined){
				window['render_preview_env_map_rotation'] = 0;
			}

			window.live_render_settings = {
				environment_map: render_preview_args[0],
				environment_intensity: render_preview_args[1],
				environment_map_horizontal_rotation: render_preview_args[2],
				time_minutes: render_preview_args[4],
				time_hours: render_preview_args[3],
                compass_rotation: render_preview_args[7],
                phi_rotation: 0.0,
                sun_intensity: render_preview_args[5],
                sun_color: render_preview_args[6],
				ambient_light_color: render_preview_args[9],
				ambient_light_intensity: render_preview_args[8]
			}

			set_page_loader({
				show: true,
				text: 'Please wait'
			});	

			var render_preview_interval = setInterval(async () => {
				var resp = await window.Promisify(window.Module.get_await_core_status("render_preview_gltf_execution"));
				var resp_json = JSON.parse(resp);
				console.log(resp_json["status"] + " status")
				if(resp_json["status"] == "execution_done"){
					clearInterval(render_preview_interval);
					if(resp_json["response_code"]=="gltf_success"){
						set_render_preview_ready(true);
					}else if(resp_json["response_code"]=="gltf_error"){
						alert.error("Could not generate render preview, Please try again later");
					}

					set_page_loader({
						show: false,
						text: 'Please wait'
					});	
				}
			},100)
		}
		set_active_tab(new_tab_id);
	}

	const get_aspect_ratios = () => {
		set_aspect_ratios(JSON.parse(window.Module.get_aspect_ratios()))
		// console.log(JSON.parse(window.Module.get_aspect_ratios()) , "list of aspect ratios")
	}

	const get_active_aspect_ratio_in_number = () => {
		if(aspect_ratios && aspect_ratios.active && aspect_ratios.active.split(":").length==2){
			var split = aspect_ratios.active.split(":");
			return Number(split[0])/Number(split[1]);
		}else{
			return 16/9;
		}
	}

	const clear_render_preview = () => {
		window.Module.clear_preview_gltf_is_ready();
		set_render_preview_ready(false);
		set_init_render_preview(false);
	}

	const set_aspect_ratio = (ratio) => {
		// window.Module.set_aspect_ratio(ratio)
		var analytics_data = {}
		analytics_data.category = "Render Aspect Ratio Changed";
		analytics_data.route = "modal"
		send_analytics(analytics_data,true);
		clear_render_preview();

		let new_ratio = {}
		new_ratio.aspect_ratio_type_name = ratio
		let resp = window.Module[aspect_ratios.route](JSON.stringify(new_ratio));
		handle_ui_response(JSON.stringify({update_camera_preview: true}))
		get_aspect_ratios()
	}

	const get_fov = () => {
		set_fov(window.Module.get_fov())
	}

	
	const submit_fov = (final_fov) => {
		if(!isNaN(Number(final_fov)) && Number(final_fov) <= 150 && Number(final_fov) >= 30){
			window.Module.set_fov(Number(final_fov));
			clear_render_preview();
			handle_ui_response(JSON.stringify({update_camera_preview: true}))
		}else{
			get_fov();
			alert.error("Invalid input. FOV needs to be between 30 and 150");
		}
	}

	const final_on_click = async (quality) => {
		var analytics_data = {}
		analytics_data.category = "Render Quality Button Clicked";
		analytics_data.route = "modal"
		send_analytics(analytics_data,true);

		set_page_loader({
            show: true,
            text: 'Queueing your render request'
        },async () => {
			//await dummy_tick(0);
			var render_fired = false
			window.rtx_save_response_in_progress = true;
			window.Module.component_save_for_rtx_render("");
			var rtx_fire_interval = setInterval(() => {
				if(window.rtx_save_response_in_progress == false && render_fired == false){
					render_fired = true;
					clearInterval(rtx_fire_interval);
					setTimeout(() => {
						window.Promisify(window.Module.fire_rtx_render(quality))
						.then(response => {
							set_page_loader({
								show: false,
								text: ''
							})
							var response_json = JSON.parse(response);
							console.log(response_json);
							if(response_json.toast.type == "success"){
								alert.success(`${quality_to_name[quality]} Render added to the queue. Check Gallery under the Render tab.`)
								var render_fire_interval = setInterval(() => {
									window.Promisify(window.Module.monitorProgressRTXForCompletion(JSON.stringify([response_json.data.id]) ) )
									.then(response => {
										var monitor_status = JSON.parse(response);
										console.log("RESPONSE: ", monitor_status)
										if(monitor_status && monitor_status == "success"){
											clearInterval(render_fire_interval);
											var image2 = window.Module.API.get_assets_path()+"/rendering/outputs/"+response_json.data.id+".jpg";
											var alertArg = [`${quality_to_name[quality]} Render is now complete. Check Gallery under the Render tab.`,{"name":"View Image","route":"new_window_link","arg":image2},{"name":"Open post processing","route":"open_post_processing_modal","arg":image2}]
											alert.success(alertArg,{timeout:10000})
											// alert.success(`Your ${quality} render is now complete. You can find it under Render Menu->Rendered Images`)
											return;
										}
									}).catch(err => {
										window.sentry_capture_exception("",err);
									});
								}, 5000);
							}else{
								alert.error('Render Queueing failed, Please try again later ')
							}
							handle_close();
							
						}).catch(error => {
							set_page_loader({
								show: false,
								text: ''
							})
							alert.error(`Your ${quality} render has failed. Please contact your admin`)
						});	
					}, 0);
				}
			},100);
		})
	}

	const get_url = () => {
		if(modal_json && modal_json.preview=="preview.png" && window.canvas_2d.images["preview.png"]){
			return window.canvas_2d.images["preview.png"].toDataURL()
		}
		return '';
	}

	const switchToCameraMode = () => {
        var active_design = window.Module.get_active_design();
		active_design.set_camera_positioning_mode(true)
		handle_close()
		update_view()
		var analytics_data = {}
		analytics_data.path = "Through render modal";
		analytics_data.category = "Camera Positioning View Entered";
		send_analytics(analytics_data,true);
		active_design.delete();

	}

	const onclick_handle_close = () => {
		set_is_loading(true)
		set_init_render_preview(false)
		// write analytics here
		var analytics_data = {}
		analytics_data.category = "Render Modal Closed";
		analytics_data.route = "modal"
		send_analytics(analytics_data,true);

		handle_close()
	}

	const update_render_preview = (envMap, environment_intensity, theta_rotation, time_hours, time_minutes, sun_intensity, color, compass_rotation, ambient_light, ambient_color, phi_rotation=0) => {
		// console.log('kppp envMap, environment_intensity, theta_rotation, time_hours, time_minutes, sun_intensity, color, compass_rotation', envMap, environment_intensity, theta_rotation, time_hours, time_minutes, sun_intensity, color, compass_rotation)
		const iframeWindow = iframeRef.current.contentWindow;
		if(iframeWindow && iframeWindow.updateEnvMap_wrapper) {
			iframeWindow.updateEnvMap_wrapper(envMap, environment_intensity/100.0, time_hours, time_minutes, compass_rotation, theta_rotation, phi_rotation, sun_intensity/50.0, color, ambient_color, ambient_light);
		}
	}

	const parse_json = (current_json, val, parent_json='') => {
		if(current_json.type == 'slider'){
			if(current_json.name == 'Map Intensity')
				render_preview_args[1] = Number(val?val:current_json.default_value)
			else if(current_json.name == 'Rotation')
				render_preview_args[2] = Number(val?val:current_json.default_value)
			else if(current_json.name == 'Intensity (%)')
				render_preview_args[5] = Number(val?val:current_json.default_value)
			else if(current_json.name == 'Sunlight Rotation')
				render_preview_args[7] = Number(val?val:current_json.default_value)
			else if(current_json.name == 'Ambient Intensity (%)')
				render_preview_args[8] = Number(val?val:current_json.default_value)

		}
		if(current_json.type == 'text_input'){
			if(current_json.name == 'Hours')
				render_preview_args[3] = Number(val?val:current_json.default_value)
			else if(current_json.name == 'Minutes')
				render_preview_args[4] = Number(val?val:current_json.default_value)
		}
		if(current_json.type == 'image_map_select'){
			render_preview_args[0] = (val?val:current_json.default_value)
		}
		if(current_json.type == 'slider_noshow'){
			if(current_json.name == 'Map Intensity')
				render_preview_args[1] = Number(val?val:current_json.default_value)
			else if(current_json.name == 'Rotation')
				render_preview_args[2] = Number(val?val:current_json.default_value)
			else if(current_json.name == 'Intensity (%)')
				render_preview_args[5] = Number(val?val:current_json.default_value)
			else if(current_json.name == 'Sunlight Rotation')
				render_preview_args[7] = Number(val?val:current_json.default_value)
		}
		if(current_json.type == 'colorpicker'){
			if(current_json.name == 'Ambient Color')
				render_preview_args[9] = (val?val:current_json.default_value)
			else{
				set_sunlight_json(current_json)
				set_sunlight_parent_json(parent_json)
				render_preview_args[6] = (val?val:current_json.default_value)
			}
		}
		if(current_json.type == 'dropdown'){
			current_json && current_json.submenu && current_json.submenu.length && current_json.submenu.map(item => {
				parse_json(item, '', current_json);
				return item;
			})
		}	
	}

	useEffect(() => {
		set_dummy_modal_json(modal_json)
	}, [modal_json])

	// useEffect(() => {
	// 	if(modal_json && modal_json.render_menu){
	// 		let temp = JSON.parse(JSON.stringify(modal_json.render_menu))
	// 		temp.length && temp.map(json_item => {
	// 			parse_json(json_item);
	// 			return json_item;
	// 		})
	// 	}
	// }, [])

	useEffect(() => {
		if(active_tab != 1){
			if(dummy_modal_json && dummy_modal_json.render_menu){
				let temp = JSON.parse(JSON.stringify(dummy_modal_json.render_menu))
				temp.length && temp.map(json_item => {
					parse_json(json_item);
					return json_item;
				})
			}
			// console.log("KP USEFFECT", render_preview_args)
		}
	}, [dummy_modal_json, active_tab])

	useEffect(() => {
		if(dummy_modal_json && dummy_modal_json.render_menu && iframeRef && iframeRef.current){
			// console.log("KPP dummy_modal", dummy_modal_json)
			let temp = JSON.parse(JSON.stringify(dummy_modal_json.render_menu))
			temp.length && temp.map(json_item => {
				parse_json(json_item);
				return json_item;
			})
			// call graphics function here
			// update_render_preview(...render_preview_args)
		}
	}, [dummy_modal_json, iframeRef])

	useEffect(()=>{
		if(render_preview_ready){
			set_render_preview_ready(false);
		}
		if(modal_type == "rtx_render"){
			get_aspect_ratios()
			get_fov()
			set_active_view_name(window.Module.get_active_view_name())
		}
	},[modal_type])

	const get_preview_image_styling = () => (
		{width:get_active_aspect_ratio_in_number()>1.65?"100%":"unset", height:get_active_aspect_ratio_in_number()>1.65?"unset":"100%", maxWidth:"100%", maxHeight:"100%", aspectRatio: String(get_active_aspect_ratio_in_number())}
	)

	return(
		// <Modal className="modal-dialog-centered" size={window.Module.is_feature_enabled("tours_360")?"xl":"md"} isOpen={modal_type=="rtx_render"}>
		// 	<Card className="bg-secondary shadow border-0">
		// 		<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
		// 			<div>
		// 				<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Render Image</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span></span></h5>
		// 			</div>
		// 		</CardHeader>
		// 		<CardBody style = {{backgroundColor: COLORS.white , padding:'30px'}}>
					
		// 			<Row>
		// 				<Col xs={window.Module.is_feature_enabled("tours_360")?2:4}>
		// 					<Button style={styles.button_for_render} onClick={() =>final_on_click('HD')}>HD</Button>
		// 				</Col>
		// 				<Col xs={window.Module.is_feature_enabled("tours_360")?2:4}>
		// 					<Button style={styles.button_for_render} onClick={() =>final_on_click('Full_HD')}>Full HD</Button>
		// 				</Col>
		// 				<Col xs={window.Module.is_feature_enabled("tours_360")?2:4}>
		// 					<Button style={styles.button_for_render} onClick={() =>final_on_click('4K')}>4K</Button>
		// 				</Col>
		// 				{window.Module.is_feature_enabled("tours_360")?
		// 				<React.Fragment>
		// 					<Col xs={2}>
		// 						<Button style={styles.button_for_render} onClick={() =>final_on_click('C_4K')}>HD 360</Button>
		// 					</Col>
		// 					<Col xs={2}>
		// 						<Button style={styles.button_for_render} onClick={() =>final_on_click('C_6K')}>Full HD 360</Button>
		// 					</Col>
		// 					<Col xs={2}>
		// 						<Button style={styles.button_for_render} onClick={() =>final_on_click('C_8K')}>4K 360</Button>
		// 					</Col> 
		// 				</React.Fragment>
		// 				:""}
		// 			</Row>
							
		// 		</CardBody>
		// 	</Card>
		// </Modal>16:9
		<Modal className="modal-dialog-centered" size={"xl"} isOpen={modal_type=="rtx_render"}>
			<IModalHeader toggle={onclick_handle_close}>Fire Render</IModalHeader>
			<IModalBody style={{maxHeight: '600px', minHeight: '520px', overflow: 'auto'}}>
				{/* <UncontrolledINavbar tabs={['']} set_active_tab={switch_render_tab} active_color={COLORS.blue5} style_tabs={{justifyContent: 'center', gap: '16px'}}/> */}
				{/* todo - enable render preview when ready */}
				<UncontrolledINavbar tabs={['Basic 3D View (Instant)', 'Render Preview (10 - 20sec)']} set_active_tab={switch_render_tab} active_border_bottom={true} active_color={COLORS.blue5} style_tabs={{justifyContent: 'center', gap: '16px'}}/>
				<TabContent activeTab={"tabs" + active_tab}>
					<TabPane tabId={'tabs0'}>
						<div className='flex_property justify-content-around px-xl-5 px-lg-4 px-1'>
							{/* <div>Pre Render Preview</div> */}
							{/* <div onClick={switchToCameraMode} style={{ color: '#0078ff', cursor: 'pointer', fontWeight: 500 }}>Adjust camera position</div> */}
						</div>
						<div className='mb-3' style={{ textAlign: 'center', height: '400px' }}>
							<img src={get_url()} height={'100%'}></img>
						</div>
						<div className='flex_property justify-content-around px-xl-5 px-lg-4 px-1'>
							{/* <div>Camera of {active_view_name}</div> */}
							<div onClick={switchToCameraMode} style={{ color: '#0078ff', cursor: 'pointer', fontWeight: 500 }}>{t(`Adjust camera position`)}</div>
							<div className='flex_property' style={{gap:'8px'}}>
								FoV :
								<div style={{width:'50%'}}>
									<div className='flex_property'>
										<div style={{borderRight:'2px solid #C5C7CF', height:'6px', borderRadius:'4px'}}/>
										<input
											type="range"
											min={30}
											max={150}
											step={1}
											value={fov}
											onChange={(e)=>set_fov(e.target.value)}
											onMouseUp={(e)=>submit_fov(e.target.value)}
											className='slider'
										/>
										<div style={{borderRight:'2px solid #C5C7CF', height:'6px', borderRadius:'4px'}}/>
									</div>
									<div style={{ fontSize:'12px', display:'flex', alignItems:'end', justifyContent:'space-between', width:'100%', marginTop:'4px'}}>
										<span>{30}</span>
										<span>{150}</span>
									</div>
								</div>
								<Input
									value={fov}
									step={'1'}
									onChange={(e) => set_fov(e.target.value)}
									onBlur={(e) => submit_fov(e.target.value)}
									onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } }}
									style={{ ...styles_modals.input_styles_2, width: '60px' }}
									type='number'
								/>
							</div>
							<div className='flex_property'>
								Aspect Ratio :
								<UncontrolledDropdown>
									<DropdownToggle style={{ fontWeight: 500, padding: 'unset', background: 'transparent', border: 'unset', boxShadow: 'unset', color: 'black' }}><div className='flex_property'><div className='mr-2'>{aspect_ratios.active ? aspect_ratios.active : '16:9'}</div> <i className='fa fa-caret-down'></i></div></DropdownToggle>
									<DropdownMenu>
										{
											aspect_ratios && aspect_ratios.ratios ? aspect_ratios.ratios.map((ratio, idx) => (
												<DropdownItem onClick={() => { set_aspect_ratio(ratio.value) }} key={idx}>{ratio.value}</DropdownItem>
											))
												: ''
										}
									</DropdownMenu>
								</UncontrolledDropdown>
							</div>
						</div>
					</TabPane>
					<TabPane tabId={'tabs1'}>
						{/* <div style={{ textAlign: 'center', height: '400px', width:((get_render_preview_aspect_ratio()*400) + "px"), margin: 'auto' }}>
							{
								render_preview_ready ?
									(
										<iframe style={{ height: "100%", width: "100%", border: 'none' }} src={window.Module.API.react_app_path + "/instant_render/index.html"} credentialless>

										</iframe>
									)
									: ''
							}
						</div> */}
						{/* <div style={{display: 'flex', textAlign: 'center', height: '20px', width: '100%', margin: 'auto'}}>
								{
								render_preview_ready ?
									(
										<>
											<div className='gpu_cpu_info' style={{height: '100%', minWidth: '60%', border: 'none'}}>
											Might take few sec based on on-device CPU / GPU
											</div>
											{dummy_modal_json && dummy_modal_json.render_menu ?
											<div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'stretch'}}>

											</div>:''}
										</>
									) : ''
								}
						</div> */}
						<div style={{display: 'flex', textAlign: 'center', height: '400px', width: '100%', margin: 'auto'}}>
							{
								render_preview_ready ?
									(
										<>
											<div style={{position: 'relative', height: '100%', minWidth: '60%', border: 'none',display:"flex",alignItems:"center",justifyContent:"center"}}>
												<div style={{position: 'absolute', width: '100%', height: '100%', backgroundColor: 'black', opacity: 0.2, zIndex: 100, display: is_loading?'flex':'none', alignItems: 'center', justifyContent: 'center'}}>
													<div style={{color: 'white'}}>
														Please wait Rendered preview is processing...
													</div>
												</div>
												<img src={get_url()} style={{...(get_preview_image_styling()) , position:"absolute", border: 'none', display: (!init_render_preview && is_loading)?'':'none', zIndex: 99}} />
												<div className={is_loading?'in_progress_style':'preview_done_style'} style={{position: 'absolute', height: '24px', bottom: '-32px', right: '0px'}}>
													{is_loading?'':<img src={'/resources/icons/preview_done.svg'}/>}
													<span style={{paddingLeft: is_loading?'0px':'4px'}}>{is_loading?'In progress..':'Preview Ready'}</span>
													<span title={(is_loading) ? 'Preview is loading...' : (!args_changed) ? 'Preview is upto date. Change some controls to regenerate preview' : ''} onClick={() => {if(!(is_loading || !args_changed)){set_args_changed(false); update_render_preview(...render_preview_args); send_analytics({category: "Render Preview Refresh clicked", ...window.live_render_settings})}}} style={{marginLeft: '16px', cursor: (is_loading || !args_changed)?'':'pointer', color: (is_loading || !args_changed) ? '#A7A8B2' : '#0078ff', textDecoration: 'underline', opacity: (is_loading || !args_changed)?0.5:1}}>Refresh preview</span>
												</div>
												<div className='gpu_cpu_info' style={{position: 'absolute', height: '24px', bottom: '-30px', left: '0px'}}>
												Might take few sec based on on-device CPU / GPU
												</div>
												<div id="test_div" style={{...(get_preview_image_styling()),visibility:is_loading?"hidden":"visible"}}>
													<iframe id={'render_preview_iframe'} ref={iframeRef} style={{height: "100%", width: '100%', border: 'none'}} src={window.Module.API.react_app_path + "/instant_render/index.html"} credentialless>
													</iframe>
												</div>
											</div>
											{dummy_modal_json && dummy_modal_json.render_menu ?
											<div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'stretch', border: '1px solid #C5C7CF', borderRight: 'unset', backgroundColor: 'white', opacity: is_loading?'0.4':'', pointerEvents: is_loading?'none':'', overflow: 'auto'}}>
												{
													dummy_modal_json && dummy_modal_json.render_menu && dummy_modal_json.render_menu.map((item, idx) => (
														<div style={{flex: 1}}>
														<div style={{display: 'flex', flex:1, flexDirection: 'row', justifyContent: 'start', flexWrap: 'wrap', borderRight: idx == dummy_modal_json.render_menu.length - 1 ? '1px solid #C5C7CF' : '', borderLeft: idx == dummy_modal_json.render_menu.length - 1 ? '1px solid #C5C7CF' : ''}}>
															<div className='lines1_elipsis flex_center' key={1} onClick={() => {}} style={{borderRadius: '6px', padding:'4px', fontWeight:400, width: '100%', height: '6%', margin: '12px 12px 0 12px', backgroundColor: '#E6E9EE'}} title={item.name}>
																{item.icon ? <img className='mr-2' src={item.icon}></img> : ''}
																{item.name}
															</div>
															{item.submenu.map((comp, idx) => (
																comp.type == "image_map_select" ?
																<ImageMapSelect json_item={comp} parent_item={item} handle_ui_response={handle_ui_response} update_view={update_view} isLast={idx == item.submenu.length - 1} common_on_click={common_on_click}/>
																: comp.type == 'slider' ?
																<Slider json_item={comp} parent_item={item} handle_ui_response={handle_ui_response} update_view={update_view} isLast={idx == item.submenu.length - 1} common_on_click={common_on_click}/>
																: comp.type == 'text_input' ?
																<Text_Input styles={{paddingRight: idx==item.submenu.length-1 ? '' : 0, paddingLeft: idx ? 0 : ''}} json_item={comp} parent_item={item} handle_ui_response={handle_ui_response} update_view={update_view} isLast={idx == item.submenu.length - 1} common_on_click={common_on_click} text_input_type={2}/>
																: comp.type == 'dropdown' ?
																<DropDown json_item={comp} parent_item={item} is_grouped={comp.group_child_inputs} sunlight_json={sunlight_json} sunlight_parent_json={sunlight_parent_json} put_header={comp.configuration_type == "group_by_2" && comp.submenu.length > 1} handle_ui_response={handle_ui_response} update_view={update_view} isLast={idx == item.submenu.length - 1} common_on_click={common_on_click}/>
																: comp.type == 'colorpicker' ?
																<ColorPicker json_item={comp} parent_item={item} handle_ui_response={handle_ui_response} update_view={update_view} isLast={idx == item.submenu.length - 1} common_on_click={common_on_click}/>
																: comp.type == 'slider_noshow' ?
																<Slider json_item={comp} slider_type={true} parent_item={item} handle_ui_response={handle_ui_response} update_view={update_view} isLast={idx == item.submenu.length - 1} common_on_click={common_on_click}/>
																:''
															))}
														</div>
														</div>
													))
												}
											</div>
											: ''}
										</>
									)
									:
									(
										<div>
										</div>
									)
							}
						</div>
					</TabPane>
				</TabContent>
			</IModalBody>
			<IModalFooter style={{ display: 'block', paddingTop: '16px', paddingBottom: '16px', height: 'auto' }}>
				<div className='fire_render_footer' style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', width: '100%', gap: '16px' }}>
					<div className='fire_render_button'>
						<Button className='secondary_button_default' onClick={() => final_on_click('HD')}><b>HD</b>&nbsp;Render</Button>
					</div>
					<div className='fire_render_button'>
						<Button className='secondary_button_default' onClick={() => final_on_click('Full_HD')}><b>FHD</b>&nbsp;Render</Button>
					</div>
					<div className='fire_render_button'>
						<Button className='secondary_button_default' onClick={() => final_on_click('4K')}><b>4K</b>&nbsp;Render</Button>
					</div>
					{
						window.Module.is_feature_enabled("tours_360")?
							<React.Fragment>
								<div className='fire_render_button'>
									<Button className='secondary_button_default' onClick={() => final_on_click('C_4K')}><b>HD 360</b>&nbsp;Render</Button>
								</div>
								<div className='fire_render_button'>
									<Button className='secondary_button_default' onClick={() => final_on_click('C_6K')}><b>FHD 360</b>&nbsp;Render</Button>
								</div>
								<div className='fire_render_button'>
									<Button className='secondary_button_default' onClick={() => final_on_click('C_8K')}><b>4K 360</b>&nbsp;Render</Button>
								</div>
							</React.Fragment>
							: ''
					}
				</div>

			</IModalFooter>
		</Modal>
	)    
}

// const Render_Images_card_for_rtx = ({render_item, update_modal_json, render_id, src, button, timestamp_start, timestamp_end, progress, status, submit_for_delete_confirm, handle_ui_response, type, name, etaSec, created_at}) => {

	
const Render_Image_card_Modal = ({open, idx, tabs, activeTab, render_id, type, src, render_images_list, render_image_id, render_image_src, toggle, get_panorama_base_url, open_post_processing_modal, delete_render, download_image, submit_for_delete_confirm, refireRender}) => {
	const [curr_index, set_curr_index] = useState(idx);
	const alert = useAlert()
	// useEffect(() => {
	// 	console.log("KPP tabs are", tabs);
	// }, [tabs])
	return (
		<Modal className="modal-dialog-centered" size="xl" isOpen={open}>
			<IModalHeader style={{justifyContent: 'unset'}} style_children={{flex: 1, display: 'flex', justifyContent: 'space-between'}} toggle={toggle}>
				<div style={{paddingLeft: '26px', fontWeight: '400', fontSize: '16px'}}>{render_images_list && render_images_list[curr_index] && (render_images_list[curr_index].name ? render_images_list[curr_index].name : render_images_list[curr_index].created_at)}</div>
				<div style={{display: 'flex', gap: '16px', alignItems: 'center', paddingRight: '16px'}}>
					<div style={{fontWeight: '400', fontSize: '16px', color: '#A7A8B2'}}>{(curr_index+1) + "/" + render_images_list.length + " in " + (tabs && tabs.length && tabs.find(item => item.id == activeTab) && tabs.find(item => item.id == activeTab).name) + " Output"}</div>
					<img src='/resources/icons/share_link_icon.svg' style={{cursor:'pointer', height: '16px', width: '16px', cursor: 'pointer'}} onClick={() => {navigator.clipboard.writeText(type=='tours' ? (get_panorama_base_url() + render_images_list[curr_index].id) : render_image_src[curr_index]); alert.success('Link copied to clipboard')}}/>
					<a href={type=='tours' ? (get_panorama_base_url() + render_images_list[curr_index].id) : render_image_src[curr_index]} style={{ maxHeight: '100%', maxWidth: '100%', marginTop: '-2px', cursor: 'pointer' }} target='blank'>
						<img height='16px' width='16px' src="/resources/nav_icons_new/newtab.svg" />
					</a>
				</div>
			</IModalHeader>
			<IModalBody>
				<ImageCarousel onIndexChangeCallback={(changedIdx) => set_curr_index(changedIdx)} show_on_hover={false} initalIndex={idx} images={render_images_list && render_images_list.length && render_images_list.map((item,item_idx) => type=='tours' ? (get_panorama_base_url() + item.id) : render_image_src[item_idx])} icon_styles={{color: 'black'}} carousel_navigation_button_styles={{background: '#F5F6F8'}}/>
			</IModalBody>
			<IModalFooter>
				{
					type == 'tours' ?
					<div style={{flex: 1, paddingLeft: '24px', paddingRight: '26px'}} className={`flex_property ${(render_images_list[curr_index] && render_images_list[curr_index].status) == 'ongoing'||(render_images_list[curr_index] && render_images_list[curr_index].status) == "initialized" ? 'justify-content-between' : 'justify-content-end'}`}>
						<div>{(render_images_list[curr_index] && render_images_list[curr_index].name) ? render_images_list[curr_index].name : '360 Tour'}</div>
					</div>:
					<div style={{color: '#676878', flex: 1, paddingLeft: '26px', paddingRight: '24px'}} className={`flex_property ${(render_images_list[curr_index] && render_images_list[curr_index].status) == 'ongoing'||(render_images_list[curr_index] && render_images_list[curr_index].status) == "initialized" ? (render_images_list && render_images_list[curr_index] && render_images_list[curr_index].progress ? render_images_list[curr_index].progress : '') || !(render_images_list[curr_index] && render_images_list[curr_index].etaSec) ? 'justify-content-center' : 'justify-content-between' : 'justify-content-between'}`}>
						{
							(render_images_list[curr_index] && render_images_list[curr_index].status) == 'ongoing'||(render_images_list[curr_index] && render_images_list[curr_index].status) == "initialized" ? '' :
								<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
									<UncontrolledDropdown>
										<DropdownToggle outline style={{ outline: 'unset', padding: 'unset', border: 'unset', boxShadow: 'unset', display: 'flex', gap: '6px', alignItems: 'center', background: 'transparent', padding: 0, color: 'var(--inf-primary-blue)'}}>
											<div>More Options</div>
											<i className='fa fa-caret-down' style={{fontSize: '16px', color: '#A7A8B2'}}/>
										</DropdownToggle>
										<DropdownMenu style={{ marginBottom: '4px' }}>
											<DropdownItem onClick={() => {navigator.clipboard.writeText(type=='tours' ? (get_panorama_base_url() + render_images_list[curr_index].id) : render_image_src[curr_index]); alert.success('Link copied to clipboard')}}>Copy URL</DropdownItem>
											<DropdownItem onClick={(e) => download_image(render_images_list[curr_index].id, render_image_src[curr_index])}>Download</DropdownItem>
											<DropdownItem onClick={(e) => open_post_processing_modal(render_images_list && render_image_src[curr_index])}>Edit Image</DropdownItem>
											<DropdownItem divider></DropdownItem>
											<DropdownItem style={{ color: '#FF5247' }} onClick={() => submit_for_delete_confirm(() => {delete_render(render_images_list && (render_images_list.length > curr_index) && render_images_list[curr_index].id); toggle();}, () => { }, 'Do you want to delete this Render permanently?')}>Delete</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
									<div style={{display: 'flex', gap: '16px', fontSize: '12px'}}>
										<div style={{color: '#A7A8B2'}}>Refire same render in:</div>
										{tabs && tabs.length && tabs.filter(tab_item => (type != 'tours') ? (tab_item.id != 'tours') : true).length && tabs.filter(tab_item => (type != 'tours') ? (tab_item.id != 'tours') : true).map(item => 
										<div style={{color: '#0078FF', cursor: 'pointer'}} onClick={() => refireRender(render_images_list && render_images_list[curr_index] && render_images_list[curr_index].id, item.id)}>{item.name}</div>)}
									</div>
									<div onClick={(e) => download_image(render_images_list[curr_index].id, render_image_src[curr_index])} style={{color: 'var(--inf-primary-blue', cursor: 'pointer'}}>Download Image (jpg)</div>
								</div>
						}
					</div>
				}
			</IModalFooter>
		</Modal>
	)
}

const RenderInfoModal = ({fields, open, toggle}) => {

	const render_info_layout = [
		{name: 'Render Name', type: 'text'},
		{name: 'Time Stamp', type: 'text'},
		{name: 'Aspect Ratio', type: 'text'},
		{name: 'Quality', type: 'text'},
		{type: 'border'},
		{name: 'Env. map name', type: 'text'},
		{name: 'Env. map intensity', type: 'slider'},
		{name: 'Env. map rotation', type: 'slider', units: 'deg'},
		{type: 'border'},
		{name: 'Time of Day', type: 'text', display_name: 'Time of the day'},
		{name: 'Sun Rotation', type: 'slider', display_name: 'Sunlight Rotation', units: 'deg'},
	]

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={open}>
			<IModalHeader toggle={toggle}>
				Render Info
			</IModalHeader>
			<IModalBody style={{padding: '24px'}}>
				<Card>
					<div style={{padding: '24px'}}>
						{
							render_info_layout.map(item => (
								item.type == 'text' ? 
								<div style={{display: 'flex', width: '100%', margin: '16px 0'}}>
									<div style={{flex: 1, color: '#A7A8B2'}}>{item.display_name ? item.display_name : item.name}</div>
									<div style={{flex: 1}}>{item.name == 'Aspect Ratio' ? (fields && fields[item.name].includes(1.77) ? "16 : 9" : fields && fields[item.name].includes("1.33") ? "4 : 3" : fields && fields[item.name].includes("1") ? "1 : 1" : fields && fields[item.name] ? fields[item.name] : '-') : fields && fields[item.name] ? fields[item.name] : '-'}</div>
								</div>
								: item.type == 'border' ?
								<div style={{borderBottom: '1px solid #C5C7CF', height: '1px', margin: '16px 0'}}></div>
								: item.type == 'slider' ?
								<div style={{display: 'flex', width: '100%', alignItems: 'center', margin: '16px 0'}}>
									<div style={{flex: 1, color: '#A7A8B2'}}>{item.display_name ? item.display_name : item.name}</div>
									<div className='flex_property' style={{flex: 1}}>
										<div className='flex_property' style={{flex: 8, opacity: 0.5}}>
										<div style={{borderRight:'2px solid #C5C7CF', height:'6px', borderRadius:'4px'}}/>
										<input
											type="range"
											min={item.min ? item.min : 0}
											max={item.max ? item.max : 100}
											step={1}
											value={fields && fields[item.name]}
											style={{pointerEvents: 'none'}}
											className='slider'
										/>
										<div style={{borderRight:'2px solid #C5C7CF', height:'6px', borderRadius:'4px'}}/>
										</div>
										<div style={{paddingLeft: '16px', flex: 1}}>{fields && fields[item.name]}</div>
									</div>
								</div>
								 : ''
							))
						}
					</div>
				</Card>
			</IModalBody>
			<IModalFooter>
				<Button className='white_button' style={{borderRadius: '4px'}} onClick={toggle}>Close</Button>
			</IModalFooter>
		</Modal>
	)
}

const Render_Images_card_for_rtx = ({update_modal_json, render_item, idx, tabs, activeTab, render_id, render_image_id, src, render_images_list, render_image_src, button, timestamp_start, timestamp_end, progress, status, submit_for_delete_confirm, handle_ui_response, type, name, etaSec, created_at}) => {
    const {t} = useTranslation()
	const [open_carousal, set_open_carousal] = useState(false)
	const [show_rename, set_show_rename] = useState(false);
	const [triggered_by, set_triggered_by] = useState({key: 'init'});
	const [open_render_info, set_open_render_info] = useState(false)
	const [render_options_menu, set_render_options_menu] = useState([
        {
            name: 'View render info',
			label: <div style={{color: '#676878'}} onClick={(e) => open_render_info_modal()}>View render info</div>,
			key: '0',
        },
		{
			name: 'Rename',
			label: <div style={{color: '#676878'}} onClick={() => open_rename_box()}>Rename</div>,
			color: '#676878',
			key: '1',
		},
        {
            name: 'Download image (jpg)',
			label: <div style={{color: '#676878'}} onClick={(e) => download_image(render_id, src)}>Download image (jpg)</div>,
			color: '#676878',
			key: '2',
        },
        {
            name: 'Post processing',
			label: <div style={{color: '#676878'}} onClick={(e) => open_post_processing_modal(src)}>Post processing</div>,
			color: '#676878',
			key: '3',
        },
        {
            name: 'Re-fire render',
			label: <div style={{color: '#676878'}}>Re-fire render</div>,
			children: tabs && tabs.length && tabs.filter(tab_item => (type != 'tours') ? (tab_item.id != 'tours') : true).length && tabs.filter(tab_item => (type != 'tours') ? (tab_item.id != 'tours') : true).map((item,idx) => (
				{
					label: <div onClick={() => refireRender(render_id, item.id)} style={{color: '#676878', minWidth: '120px'}}>{item.name}</div>,
					key: "4-" + idx
				}
			)),
			color: '#676878',
			key: '4',
        },
        {
            name: 'Delete Render',
			label: <div style={{color: '#FF5247'}} onClick={() => submit_for_delete_confirm(() => delete_render(render_id), () => { }, 'Do you want to delete this Render permanently?')}>Delete Render</div>,
			color: '#FF5247',
			key: '5',
        },
    ])

	useEffect(() => {
		set_render_options_menu([
			{
				name: 'View render info',
				label: <div style={{color: '#676878'}} onClick={(e) => open_render_info_modal()}>View render info</div>,
				key: '0',
			},
			{
				name: 'Rename',
				label: <div style={{color: '#676878'}} onClick={() => open_rename_box()}>Rename</div>,
				color: '#676878',
				key: '1',
			},
			{
				name: 'Download image (jpg)',
				label: <div style={{color: '#676878'}} onClick={(e) => download_image(render_id, src)}>Download image (jpg)</div>,
				color: '#676878',
				key: '2',
			},
			{
				name: 'Post processing',
				label: <div style={{color: '#676878'}} onClick={(e) => open_post_processing_modal(src)}>Post processing</div>,
				color: '#676878',
				key: '3',
			},
			{
				name: 'Re-fire render',
				label: <div style={{color: '#676878'}}>Re-fire render</div>,
				children: tabs && tabs.length && tabs.filter(tab_item => (type != 'tours') ? (tab_item.id != 'tours') : true).length && tabs.filter(tab_item => (type != 'tours') ? (tab_item.id != 'tours') : true).map((item,idx) => (
					{
						label: <div onClick={() => refireRender(render_id, item.id)} style={{color: '#676878', minWidth: '120px'}}>{item.name}</div>,
						key: "4-" + idx
					}
				)),
				color: '#676878',
				key: '4',
			},
			{
				name: 'Delete Render',
				label: <div style={{color: '#FF5247'}} onClick={() => submit_for_delete_confirm(() => delete_render(render_id), () => { }, 'Do you want to delete this Render permanently?')}>Delete Render</div>,
				color: '#FF5247',
				key: '5',
			},
		])
	}, [src])

    // const [workbench_menu, set_workbench_menu] = useState([])

    // useEffect(() => {
    //     if(design_views){
    //         let temp = render_options_menu.filter(item => item.view_type != 'workbench').map((item, idx) => {
    //             return {
    //                 key: String(idx+2),
    //                 label: <div className='view_type_menu' style={{display: "flex", gap: '10px', alignItems: 'center'}}> <img src={item.icon} height='20px' width='20px' style={{height: item.view_type=='3d'?'16px':''}}/> <div>{item.name + ' (' + String(design_views.filter(view => view.type && view.type.includes(item.view_type)).length) + ')'}</div></div>,
    //                 children: design_views.filter(view => view.type && view.type.includes(item.view_type)).map((filtered_view, view_idx) => {return {view_value: filtered_view, label: <Tooltip placement='right' title={filtered_view.name} mouseEnterDelay={0.5}><div onClick={(e) => {if(!filtered_view.open) {open_view(filtered_view);} switch_view_and_scroll_to_it(filtered_view); }} style={{display: 'flex', gap: '10px', maxWidth: '200px'}}><img src={filtered_view.locked?'/resources/view_bar_icons/locked_state.svg':'/resources/view_bar_icons/unlocked_state.svg'}></img> <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{filtered_view.name}</div> </div></Tooltip>, key: String(idx+2) + '-' + String(view_idx+1)}}),
    //                 disabled: design_views.filter(view => view.type && view.type.includes(item.view_type)).length ? false : true
    //             }
    //         })
    //         temp.unshift({
    //             key: '1',
    //             type: 'group',
    //             label: <div style={{margin: '-16px -16px 0 -16px'}}><div style={{ textAlign: 'center', background: '#788394', padding: '6px 0', color: 'white', borderRadius: '4px 4px 0 0'}}>View Manager</div></div>,
    //             children: [{key: '1-1', label: <div style={{display: "flex", alignItems: 'center', gap: '10px', marginLeft: '-9px'}}> <img src={render_options_menu.find(item => item.view_type == 'workbench').icon} height={'60%'} /> <div onClick={(e) => switch_free_view(e)}>{render_options_menu.find(item => item.view_type == 'workbench').name}</div></div>,}]
    //         });
    //         set_workbench_menu(temp);
    //     }
    // }, [render_options_menu, design_views])

	const alert =  useAlert()

	useEffect(() => {
		if(show_rename){
			// set_render_name((name && (name != render_id)) ? name : formatDateTime(created_at))
			let input_element = document.getElementById(render_id + '_input_box');
			input_element.value = (name && (formatDateTime(name) != formatDateTime(created_at) || formatDateTime(name) == 'Invalid Date')) ? name : formatDateTime(created_at)
			if(input_element){
				input_element.focus();
				input_element.setSelectionRange(0, input_element.value.length);
			}
		}
	}, [show_rename])

	// useEffect(() => {
	// 	if(triggered_by.key == 'init'){
	// 		let dom = document.getElementById(render_id + '_input_box')
	// 		if(dom){
	// 			dom.focus()
	// 			const inputLength = dom.value && dom.value.length;
	// 			dom.setSelectionRange(0, inputLength)
	// 		}
	// 	}
	// }, [render_name])

	const submit_render_name = async(name) => {
		set_show_rename(false);
		triggered_by.key = 'init'
		if(!name){
			alert.error('Render Name cannot be empty')
			return;
		}
		let resp = await window.Promisify(window.Module.change_render_name(render_id, name));
		handle_ui_response(resp);

	}

	const open_rename_box = () => {
		set_show_rename(true)
	}

	const open_render_info_modal = () => {
		set_open_render_info(true);

	}

	const get_panorama_base_url = () => {
		var url = "360tour.infurnia.com/";
		if(!window.location.origin.includes("infurnia.com")){
			url = "360tour." + window.location.origin.split('.')[1] + "." + window.location.origin.split('.')[2] + "/"
		}

		if(window.Module.API.application_env == "stage"){
			url = ("https://stage") + url;
		}else if(window.Module.API.application_env == "preprod"){
			url = ("https://preprod") + url;
		}else if(window.Module.API.application_env == "production"){
			url = ("https://") + url;
		}else{
			url = ("https://") + url;
		}
		
		return url;
	}
	
	const delete_render = (render_id) => {
		window.Promisify(window.Module.delete_render_rtx(render_id))
		.then(() => {
			update_modal_json();
		})
	}

	const refireRender = async(current_render_id, id) => {
		let resp = await window.Promisify(window.Module.refire_rtx_render(current_render_id, id));
		handle_ui_response(resp);
	}

	const open_post_processing_modal = async(src) => {
		let resp = await window.Promisify(window.Module.open_post_processing_modal(src))
		handle_ui_response(resp)
		var analytics_data = {}
		analytics_data.category = "Edit Render Image";
		analytics_data.route = "modal"
		send_analytics(analytics_data,true);
	}

	// const download_image = () => {
	// 	let image = document.getElementById(render_id + '_imgsrc')
	// 	if(image){
	// 		image.click()
	// 	}
	// }

	const  download_image = async(render_id, src) => {

		// Replace 'example.txt' with the filename you want to download
		var filename = `${render_id}.jpg`;

		// Replace 'Hello, World!' with the content you want to save in the file

		// Create a Blob from the file content
		let resp = await fetch(src)

		var blob = await resp.blob()

		// Create a URL for the Blob
		var url = URL.createObjectURL(blob);

		// Create a temporary anchor element
		var downloadLink = document.createElement('a');
		downloadLink.href = url;
		// console.log(url);
		downloadLink.download = filename; // Set the filename for download

		// Append the anchor element to the body and click it
		document.body.appendChild(downloadLink);
		downloadLink.click();

		// Clean up by revoking the URL and removing the anchor element
		URL.revokeObjectURL(url);
		document.body.removeChild(downloadLink);

		var analytics_data = {}
		analytics_data.category = "Download Render Image (Raw Image)";
		send_analytics(analytics_data,true);
	}

	function formatTime(totalSeconds) {
		if (totalSeconds >= 0) {
			const hours = Math.floor(totalSeconds / 3600);
			const minutes = Math.floor((totalSeconds % 3600) / 60);
			const seconds = totalSeconds % 60;

			const formattedTime = [hours > 0 ? hours + 'hr' : '', minutes > 0 ? minutes + 'min' : '', seconds + 's']
				.filter(Boolean) // Remove empty strings
				.join(' ');

			return formattedTime;
		}

		return 'N/A'
	}
	  

	return(
		<React.Fragment>
			<Render_Image_card_Modal 
				open={open_carousal} 
				render_images_list={render_images_list} 
				idx={idx} 
				activeTab={activeTab}
				tabs={tabs}
				toggle={() => set_open_carousal(!open_carousal)} 
				src={src} type={type} 
				render_id={render_id} 
				render_image_id={render_image_id} 
				render_image_src={render_image_src} 
				get_panorama_base_url={get_panorama_base_url} 
				open_post_processing_modal={open_post_processing_modal} 
				delete_render={delete_render} 
				download_image={download_image} 
				refireRender={refireRender}
				submit_for_delete_confirm={submit_for_delete_confirm}
			/>

			<RenderInfoModal 
				open={open_render_info}
				toggle={() => set_open_render_info(!open_render_info)}
				fields={render_item && render_item.fields}
			/>

			<Card className='render-card'>
				{
					src == 'rendering' ?
						<div style={{ width: '100%', aspectRatio: '1280/719', fontWeight: 500, fontSize: '20px', color: 'white', background: 'black' }} className='flex_center'>{t(`Rendering`)}</div>
						:
						status == "ongoing" || status == 'initialized' ?
							<div style={{ maxHeight: '100%', maxWidth: '100%', display: 'pointer', aspectRatio: '1280/719', background: `center / contain no-repeat url("${src}")` }}>
								<div style={{ background: 'black', opacity: '0.5', height: '100%', width: '100%' }}></div>
							</div>
							:
							type == 'tours' ?
							<a href={type == 'tours' ? get_panorama_base_url() + render_id : src} style={{ maxHeight: '100%', maxWidth: '100%' }} target='blank'>
								<img style={{ maxHeight: '100%', maxWidth: '100%', display: 'pointer', aspectRatio: '1280/719' }} src={src} crossOrigin="" />
							</a> :
							<div onClick={() => set_open_carousal(true)} style={{ maxHeight: '100%', maxWidth: '100%' }}>
								<img style={{ maxHeight: '100%', maxWidth: '100%', display: 'pointer', aspectRatio: '1280/719' }} src={src} crossOrigin="" />
							</div>
				}
				{/* <a href={src} style={{display:'none'}} download={render_id} id={render_id + '_imgsrc'} target='_blank'/> */}
				{
					(status == "ongoing" || status == 'initialized') && progress != 100 ? (
						status == 'initialized' ?
						<div><Progress style={{height: '4px', borderRadius: 0}} color="warning" value={100} /></div>
						:
						<div><Progress style={{height: '4px', borderRadius: 0}} value={progress ? progress : 2} /></div>
					) 
					:
					<div style={{height: '4px'}}></div>
				}
				{

					type == 'tours' ?
					<div style={{height: '40px', marginRight: 'auto'}} className={`flex_property ${status == 'ongoing'||status == "initialized" ? 'justify-content-between' : 'justify-content-end'} px-3 pb-1`}>
						<div>{name ? name : '360 Tour'}</div>
					</div>
						:
					// <div className={`flex_property ${status == 'ongoing'||status == "initialized" ?  'justify-content-center' : 'justify-content-end'} px-3 pb-3`}>
					<div style={{height: '36px', color: '#676878'}} className={`flex_property ${status == 'ongoing'||status == "initialized" ?  'justify-content-between' : 'justify-content-between'} px-3`}>
						{/* {status == 'ongoing'||status == "initialized" ? <div>(In Progress)</div> : ''} */}
						{/* {status == 'ongoing'||status == "initialized" ? progress || !etaSec ? <div>{progress ? "In Progress" : "In Queue"}</div> : <React.Fragment><div>In Queue</div><div style={{color: COLORS.gray4}}>ETA: {formatTime(etaSec)}</div></React.Fragment> : ''} */}
						{status == "initialized" ? <React.Fragment><div>In Queue</div><div style={{color: COLORS.gray4}}>ETA: {formatTime(etaSec)}</div></React.Fragment> : status == 'ongoing' ? <div>In Progress</div> : ''}
						{
							status == 'ongoing'||status == "initialized" ? '' :
								<div className='flex_property' style={{justifyContent: 'space-between', flex: 1, width: '100%'}}>
									<div style={{flex: 1, maxWidth: '72%'}}>
										<div className='px-2' style={{display: 'flex', gap:'12px', color: '#676878', fontWeight: '700', alignItems: 'center'}}>
											{!show_rename ?
											<>
											<div style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '75%'}}>{(name && (formatDateTime(name) != formatDateTime(created_at) || formatDateTime(name) == 'Invalid Date')) ? name : formatDateTime(created_at)}</div>
											<img className='render-pencil' style={{height: '20px', width: '20px', padding: '4px', cursor: 'pointer'}} onClick={() => open_rename_box()} src='/resources/icons/pencil.svg' />
											</> :
											<div className='createcard_input_container'>
												<input id={render_id+'_input_box'} style={{border: 'none', outline: 'none'}} onBlur={(e) => submit_render_name(e.target.value)} onKeyDown={(e) => {if(e.key == 'Enter') e.target.blur()}}/>
											</div>}
										</div>
										<div style={{color: '#C5C7CF', display: 'none'}} className='px-2'>{formatDateTime(created_at)}</div>
									</div>
								<AntDropdown
									menu={{items:render_options_menu}} trigger={['click']}
									// style={{height: '100%'}}
								>
									<a style={{cursor: 'pointer'}} onClick={(e) => e.preventDefault()} >
										<img src='/resources/icons/options.svg'></img>
									</a>
								</AntDropdown>
									{/* <UncontrolledDropdown>
										<DropdownToggle outline style={{ outline: 'unset', padding: 'unset', border: 'unset', boxShadow: 'unset', display: 'flex', alignItems: 'center', background: 'transparent', padding: '8px' }}><img src='/resources/icons/options.svg'></img></DropdownToggle>
										<DropdownMenu style={{ marginBottom: '4px' }}>
											<DropdownItem onClick={(e) => open_render_info_modal()}>View render info</DropdownItem>
											<DropdownItem onClick={(e) => download_image(render_id, src)}>Download</DropdownItem>
											<DropdownItem onClick={(e) => open_post_processing_modal(src)}>Edit Image</DropdownItem>
											<DropdownItem onClick={() => {
												window.open(type == 'tours' ? get_panorama_base_url() + render_id : src, '_blank');
												// set_open_carousal(true);
												var analytics_data = {};
												analytics_data.category = "View Render Image";
												analytics_data.route = "modal"
												send_analytics(analytics_data,true);
											}}>View Image</DropdownItem>
											<DropdownItem onClick={() => open_rename_box()}>Rename render</DropdownItem>
											<DropdownItem>
												<UncontrolledDropdown>
													<DropdownToggle style={{ outline: 'unset', padding: 'unset', border: 'unset', boxShadow: 'unset', display: 'flex', alignItems: 'center', background: 'transparent', padding: '8px' }}>
														<div style={{display: 'flex', justifyContent: 'space-between', color: 'black'}}>
															<div>Re-fire render</div>
														</div>
													</DropdownToggle>
													<DropdownMenu>
														{tabs && tabs.length && tabs.map(item => (
															<DropdownItem onClick={() => refireRender(render_id, item.id)}>{item.name}</DropdownItem>
														))}
													</DropdownMenu>
												</UncontrolledDropdown>
												
											</DropdownItem>
											<DropdownItem divider></DropdownItem>
											<DropdownItem style={{ color: '#FF5247' }} onClick={() => submit_for_delete_confirm(() => delete_render(render_id), () => { }, 'Do you want to delete this Render permanently?')}>Delete</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown> */}
								</div>
						}
					</div>
				}
				
				
				{/* <div style={{textAlign:'center', width:'100%', marginBottom:'10px' }}>
				{
					render_id ? (
						<div style={{textAlign:'center', marginBottom:'10px', fontSize:'12px'}}>{render_id}</div>
					):''
				}
				{
					button ? (
						<Button onClick ={() => submit_for_delete_confirm(()=>delete_render(), ()=>{}, 'Do you want to delete this Render permanently?')} style={{textAlign:'center', backgroundColor:'#e60000', height:'23px', color:'white', padding:'0px 15px', fontSize:'10px', border:0, borderRadius:0}}>Delete</Button>
					):''
				}
				{
					status=="ongoing" && progress != 100 && (
						<div><div style={{height:'20px', width:'100%', paddingBottom:'10px'}}><Progress color="success" value={progress?progress:"0"} /></div></div>
					)
				}
				</div> */}
			</Card>
		</React.Fragment>	
	);
}

const RTX_Baking_Modal =  ({  modal_type, modal_json, handle_close, update_modal_json, handle_ui_response, set_page_loader, submit_for_delete_confirm}) => {
	// const [ polling_interval, set_polling_interval ] = useState(null);
	// const [ latest_bake, set_latest_bake ] = useState(null);
	const [all_bakes, set_all_bakes] = useState([]);
	const alert = useAlert()
    const {t} = useTranslation()

	const polling_function = () => {
		if(all_bakes && all_bakes.length){
			var renders_that_need_poll = [];
			if(all_bakes && all_bakes.length){
				all_bakes.map(single_bake => {
					if(single_bake.status === 'ongoing' || single_bake.status === 'initialized'){
						renders_that_need_poll.push(single_bake);
					}
				})
			}
			if(modal_json && renders_that_need_poll.length>0 && modal_type == "baking_modal"){
				renders_that_need_poll = renders_that_need_poll.map(o => o.id);
				window.Promisify( window.Module.monitorProgressRTX( JSON.stringify(renders_that_need_poll) ) )
				.then(response => {
					var active_design = window.Module.get_active_design();
					var active_view = active_design.active_view;
					modal_json = JSON.parse(active_view.get_modal_menu());
					update_modal_json();
					active_design.delete();
					active_view.delete();
				}).catch(err => {
					window.sentry_capture_exception("",err);
				});
			}
		}
	}

	useEffect(() => {
		let polling_interval
		if(modal_type == "baking_modal" && modal_json){
			console.log('modal json',modal_json)
			// set_latest_bake(get_latest_bake());
			set_all_bakes(modal_json && modal_json.rendered_images)
			if(!polling_interval){
				polling_interval = (setInterval(polling_function,5000));
			}
		}else{
			if(polling_interval){
				clearInterval(polling_interval);
				// set_polling_interval(null);
			}
			// var x =modal_json && modal_json.length? modal_json.sort(time_sort):[]; 
		}

		return () => {
			if(polling_interval){
				clearInterval(polling_interval);
				// set_polling_interval(null);
			}
		}
	},[modal_type, modal_json])

	const is_design_baked = (single_bake) => {
		if(single_bake && single_bake.status == "completed"){
			return true;
		}

		return false;
	}


	const get_latest_bake = () => {
		if(modal_json && modal_json.rendered_images && modal_json.rendered_images.length){
			var sorted_json = modal_json.rendered_images.sort((a,b) => (- new Date(b.created_at) + new Date(a.created_at)));
			return sorted_json[0];
		}
		return null;
	}

	const is_design_bake_broken = (single_bake) => {
		if(single_bake && single_bake.status == "failed"){
			return true;
		}

		return false;
	}

	const fire_baking = () => {
		set_page_loader({
            show: true,
            text: 'Queueing your 3D Render request'
        },async () => {
			//await dummy_tick(0);
			setTimeout(() => {
				window.Promisify(window.Module.fire_bake_render("HD"))
				.then(response => {
					set_page_loader({
						show: false,
						text: ''
					})
					var response_json = JSON.parse(response);
					console.log(response_json);
					if(response_json.toast.type == "success"){
						alert.success('Render Queued, Please check 3D Renders Menu ');
						window.Module.show_baking_modal("\"\"");
						update_modal_json();
					}else{
						alert.error('3D Render Queueing failed, Please try again later ')
					}
					handle_close();
					
				}).catch(error => {
					set_page_loader({
						show: false,
						text: ''
					})
					alert.error(`Your 3D Render request has failed. Please contact your admin`)
				});	
			}, 0);
		})
	}

	const is_baking_delete_allowed = (single_bake) => {
		if(single_bake && single_bake.status!="ongoing" && single_bake.status!="initialized"){
			return true;
		}
	}

	const is_baking = (single_bake) => {
		if(single_bake && (single_bake.status=="ongoing"|| single_bake.status=="initialized")){
			return true
		}
		return false;
	}

	const delete_baking = (single_bake) => {
		submit_for_delete_confirm(()=>{
			window.Promisify(window.Module.delete_bake_rtx(single_bake.id))
			.then(() => {
				update_modal_json();
			})
		}, ()=>{}, 'Do you want to remove the current 3D render?')
	}

	const get_design_bake_link = (single_bake) => {
		if(is_design_baked(single_bake)){
			return (window.Module.API.react_app_path + "/bake-view/" + single_bake.design_version_id);
		}

		return "";
	}


	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={modal_type=="baking_modal"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color, fontSize:'16px', display:'flex',justifyContent:'space-between', alignItems:'center', fontWeight:600}} >	
					<span>3D Renders</span>
					<span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white, minHeight:'250px', maxHeight:'500px', overflow:'auto', padding:'10px 20px 20px', textAlign: 'center'}}>
						{/* {is_design_baked()?(<Col xs={12} style={{color:'#23C16B', textAlign:'center', marginBottom:'16px'}}><i className="fa fa-check-circle"></i>&nbsp;The design is baked! <div style={{color:COLORS.gray9, marginTop:'8px'}}>Copy <span className='underline_onhover' onClick={() => {navigator.clipboard.writeText('')}} style={{color:COLORS.blue6}}>design link</span> to share the baked design</div></Col>):''} */}
						{/* {get_design_bake_link()?(<Col xs={12} style={{textAlign:'center', marginBottom:'16px'}}><a href={get_design_bake_link()} target='black'>Click here</a> to view the last baked version in 3D <div style={{color:COLORS.gray9, marginTop:'8px'}}>Copy <span className='underline_onhover' onClick={() => {navigator.clipboard.writeText('')}} style={{color:COLORS.blue6}}>design link</span> to share the baked design</div></Col>):''} */}
						{/* {is_design_bake_broken()?(<Col xs={12} style={{color:'#FF5247', textAlign:'center', marginBottom:'16px'}}><i className="fa fa-times-circle"></i>&nbsp;An error occured while baking the design!  <div>Please try baking again</div></Col>):''}
						{!latest_bake?(
							<Col xs={12} style={{textAlign:'center', color:'#A7A8B3', marginBottom:'16px'}} >Bake the design to see realistic lighting in VR</Col>
						):''}
						{
							latest_bake && (latest_bake.status=="ongoing" || latest_bake.status=="initialized") && latest_bake.progress != 100 ?(
								<Col xs={12} style={{textAlign:'center'}} >
									<div style={{marginBottom:'16px'}}>Please wait while we Bake the design</div>
									<div className='loader' style={{height:'4px', width:'100%'}}></div>
									<Progress style={{height:'8px'}} value = {latest_bake.progress>=0 ? latest_bake.progress : '0'} />
								</Col>
							):''
						}
						<br />
						{is_baking_trigger_allowed()?(
							<Col xs={12} style={{textAlign:'center'}}>
								{is_baking_delete_allowed()?(<Button onClick={delete_baking} style={{marginRight:'10px'}} className='white_button_auto'>Remove Baking</Button>):''}
								<Button onClick={fire_baking} className='blue_button' >{is_design_baked()?'Rebake Scene':'Start Baking'}</Button>
							</Col>
						):''} */}
						<div className='flex_property' style={{justifyContent:'space-between'}}>
							<div>{t(`Viewing 3D Renders`)}</div>
							<div><Button className='blue_button' onClick={fire_baking}>{t(`Start 3D Render`)}</Button></div>
						</div>
						<div style={{backgroundColor:'#F5F6F8', marginTop:'10px', fontSize:'14px', padding:'8px', textAlign:'left'}}>
							{
								all_bakes && all_bakes.length ? all_bakes.map((single_bake, idx) => (
									<div className='flex_property' style={{backgroundColor:'white', borderRadius: '6px', margin:'12px 8px', height:'40px'}}>
										<div style={{flex:'1', marginLeft:'16px'}}>{idx+1}.</div>
										{/* <div style={{flex:'4'}}>{is_baking(single_bake) ? 'Rendering' : ''}</div> */}
										<div style={{flex:'11', color:'#0078ff', cursor:'pointer', marginLeft:'4px'}}>
										{
											is_design_baked(single_bake)?
											<div><a href={get_design_bake_link(single_bake)} target='black'>{get_design_bake_link(single_bake).length > 55 ? get_design_bake_link(single_bake).substring(0,55)+'...' : get_design_bake_link(single_bake)}</a></div>
											:(
												is_design_bake_broken(single_bake) ? 
												<div style={{color:'red'}}>{t(`An error occured while rendering the design!`)}</div>
												:
												<div>
													<Progress style={{height:'8px', maxWidth:'90%'}} value = {single_bake.progress>=0 ? single_bake.progress : '0'} />
												</div>
											)
										}
										</div>
										<div style={{flex:'4', marginLeft:'24px'}}>{timeSince(single_bake.created_at)}</div>
										{/* <div style={{flex:'3', color:'#0078ff', cursor:'pointer'}}>{is_baking(single_bake) ? 'Cancel' : ''}</div> */}
										<div style={{flex:"2"}}>
										{
											is_baking_delete_allowed(single_bake)?
											<React.Fragment>
												<i className='fa fa-clone' style={{cursor:'pointer', marginRight:"16px"}} onClick={() => {navigator.clipboard.writeText(get_design_bake_link(single_bake)); alert.success('Link copied to clipboard')}}/>
												<i className='fa fa-trash' style={{cursor:'pointer', color:'red', marginRight:"10px"}} onClick={() => {delete_baking(single_bake)}}/>
											</React.Fragment>:""
										}
										</div>
									</div>	
								)):<div className='flex_center'>{"No 3D renders found !"}</div>
							}
						</div>
				</CardBody>
			</Card>
		</Modal>
	)
}

const RTX_Rendered_images = ({  modal_type, modal_json, handle_close, update_modal_json, handle_ui_response, set_page_loader, submit_for_delete_confirm}) => {

	const [tabs , set_tabs] = useState(1);
	const [create_tour_modal, set_create_tour_modal] = useState(false);
	// const [ polling_interval, set_polling_interval ] = useState(null);
	const [activeTab, setActiveTab] = useState('HD');
	const [initialized_render_preview, set_initialized_render_preview] = useState(false);
	const [render_preview_map, set_render_preview_map] = useState({});
    const {t} = useTranslation()

	const tabs_list_1 = [
		{
			id: 'HD',
			name: 'HD'
		},
		{
			id: 'Full_HD',
			name: 'FHD'
		},
		{
			id: '4K',
			name: '4K'
		},
		{
			id: 'C_4K',
			name: 'HD 360'
		},
		{
			id: 'C_6K',
			name: 'FHD 360'
		},
		{
			id: 'C_8K',
			name: '4K 360'
		},
		{
			id: 'tours',
			name: '360 Tour'
		},
	]

	const tabs_list_2 = [
		{
			id: 'HD',
			name: 'HD'
		},
		{
			id: 'Full_HD',
			name: 'FHD'
		},
		{
			id: '4K',
			name: '4K'
		},
	]

	const toggleNavs = (e, index) => {
		e.preventDefault();
		set_tabs(index)
	};

	const handleTabChange = (id) => {
		setActiveTab(id)
	}

	const onclick_handle_close = () => {
		set_initialized_render_preview(false)
		// if (render_preview_map) {
		// 	for (let i in render_preview_map) {
		// 		if (render_preview_map[i] && render_preview_map[i] !== 'rendering') {
		// 			URL.revokeObjectURL(render_preview_map[i])
		// 		}
		// 	}
		// }
		
		set_render_preview_map({})
		handle_close()
	}

	const polling_function = () => {
		var renders_that_need_poll = []
		if(modal_json && modal_json.rendered_images){
			renders_that_need_poll = modal_json.rendered_images.filter(o => (o.status == "ongoing" || o.status == "initialized"));
		}
		if(modal_json && renders_that_need_poll.length>0 && modal_type == "rtx_images_modal"){
			renders_that_need_poll = renders_that_need_poll.map(o => o.id);
			window.Promisify( window.Module.monitorProgressRTX( JSON.stringify(renders_that_need_poll) ) )
			.then(response => {
				var active_design = window.Module.get_active_design();
				var active_view = active_design.active_view;
				modal_json = JSON.parse(active_view.get_modal_menu());
				update_modal_json();
				active_design.delete();
				active_view.delete();
			}).catch(err => {
				window.sentry_capture_exception("",err);
			});
		}/*else{
			clearInterval(polling_interval);
			set_polling_interval(null);
			return;
		}*/
	}

	const get_valid_renders = () => {
		if(modal_type == "rtx_images_modal" && modal_json && modal_json.rendered_images){
			return modal_json.rendered_images.filter(o => (o.quality == "C_4K" || o.quality == "C_6K" || o.quality == "C_8K"))
		}
		return [];
	}

	useEffect(() => {
		let temp_preview_map, polling_interval
		if(modal_type == "rtx_images_modal" && modal_json && modal_json.rendered_images){
			// set_create_tour_modal(false);
			// if(!polling_interval){
				// set_polling_interval(setInterval(polling_function,5000));
			// }
			polling_interval = (setInterval(polling_function,5000));
			
			if (!initialized_render_preview) {
				temp_preview_map = {}
				for (let i in modal_json.rendered_images) {
					let item = modal_json.rendered_images[i]
					if (item.status === "ongoing" || item.status == "initialized") {
						temp_preview_map[item.id] = get_image_url_from_FS(item.src_local) ? get_image_url_from_FS(item.src_local) : 'rendering'
					}
				}

				set_render_preview_map(temp_preview_map)
				set_initialized_render_preview(true)
			}
		}else{
			if(polling_interval){
				clearInterval(polling_interval);
				// set_polling_interval(null);
			}
			// var x =modal_json && modal_json.length? modal_json.sort(time_sort):[]; 
		}

		return () => {
			if (temp_preview_map) {
				for (let i in temp_preview_map) {
					if (temp_preview_map[i] && temp_preview_map[i] !== 'rendering') {
						URL.revokeObjectURL(temp_preview_map[i])
					}
				}
			}
			if(polling_interval){
				clearInterval(polling_interval);
				// set_polling_interval(null);
			}
		}
	},[modal_type, modal_json])

	const modify_src = src => {
		if(!src){
			return '';
		}

		var new_src = src;
		// new_src = new_src.split('render_images')[1];
		new_src = window.Module.API.get_assets_path() + "/"+ new_src;
		return new_src;
	}

	const close_create_tour_modal = () => {
		set_create_tour_modal(false);
	}

	// const get_image_url_from_FS = (preview) => {
	// 	if(preview && window.FS.root.contents[preview]){
	// 		const imageBlob = new Blob([window.FS.root.contents[preview].contents]);

	// 		// Create a URL for the Blob
	// 		let url = (URL.createObjectURL(imageBlob));
	// 		console.log(url)
	// 		return url
	// 	}
	// 	return 'rendering';
	// }

	const get_panorama_base_url = () => {
		var url = "360tour.infurnia.com/";
		if(!window.location.origin.includes("infurnia.com")){
			url = "360tour." + window.location.origin.split('.')[1] + "." + window.location.origin.split('.')[2] + "/"
		}

		if(window.Module.API.application_env == "stage"){
			url = ("https://stage") + url;
		}else if(window.Module.API.application_env == "preprod"){
			url = ("https://preprod") + url;
		}else if(window.Module.API.application_env == "production"){
			url = ("https://") + url;
		}else{
			url = ("https://") + url;
		}
		
		return url;
	}

	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type=="rtx_images_modal"}>
			<Create_Tour_Modal open={create_tour_modal} modify_src={modify_src} valid_renders={get_valid_renders()} handle_ui_response={handle_ui_response} handle_close={close_create_tour_modal} set_page_loader={set_page_loader} update_modal_json={update_modal_json}/>
			<IModalHeader toggle={onclick_handle_close}>Render Outputs</IModalHeader>
			<IModalBody style={{height: '500px', overflow: 'auto'}}>
				<SidebarLayout tabs={window.Module.is_feature_enabled("tours_360") ? tabs_list_1 : tabs_list_2} handleTabClick={handleTabChange} activeTab={activeTab} style_sidebar={{margin: '-16px 0px -16px -16px', backgroundColor: 'white', minHeight: '320px'}}>
					<div className='render-card-container'>
						{
							activeTab == 'tours' ? 
							<div style={{width: '100%', display:'flex', justifyContent:'end'}}>
								<div style={{cursor:'pointer', color: '#0078ff'}}  onClick={() => {set_create_tour_modal(true);}}>{t(`Create a 360 Tour`)}</div>
							</div>
							:''
						}
						{
							activeTab != 'tours' && modal_json && modal_json.rendered_images && modal_json.rendered_images.length && modal_json.rendered_images.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at))).filter(o => o.quality == activeTab).length ? modal_json.rendered_images.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at))).filter(o => o.quality == activeTab).map((item, idx) => (
								<Render_Images_card_for_rtx
									key={idx}
									render_item={item}
									idx={idx}
									activeTab={activeTab}
									tabs={window.Module.is_feature_enabled("tours_360") ? tabs_list_1 : tabs_list_2}
									update_modal_json={update_modal_json}
									render_id={item.id}
									src={
										item.status === "ongoing" || item.status == "initialized" ? render_preview_map[item.id] ? render_preview_map[item.id] : 'rendering'
											:
											item.status === "failed" ? '/resources/images/render_failed.png' : modify_src(item.src)}
									render_images_list={modal_json.rendered_images.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at))).filter(o => o.quality == activeTab)}
									render_image_src={modal_json.rendered_images.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at))).filter(o => o.quality == activeTab).map(item1 => {return (item1.status === "ongoing" || item1.status == "initialized") ? (render_preview_map[item1.id] ? render_preview_map[item1.id] : 'rendering')
									:
									(item1.status === "failed" ? '/resources/images/render_failed.png' : modify_src(item1.src))})}
									render_image_id={modal_json.rendered_images.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at))).filter(o => o.quality == activeTab).map(item => item.id)}
									button={item.status != "ongoing" && item.status != "initialized"}
									timestamp_start={item.timestamp_start ? item.timestamp_start : ''}
									timestamp_end={item.timestamp_end ? item.timestamp_end : ''}
									progress={item.progress ? item.progress : ''}
									status={item.status}
									submit_for_delete_confirm={submit_for_delete_confirm}
									handle_ui_response={handle_ui_response}
									etaSec={item.etaSec}
									name={item.name}
									created_at={item.created_at}
								/>
							))
								:
								activeTab == 'tours' && modal_json && modal_json.tours && modal_json.tours.length ? modal_json.tours.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at))).map((item, idx) => (
									<Render_Images_card_for_rtx
										key={idx}
										render_item={item}
										idx={idx}
										activeTab={activeTab}
										tabs={window.Module.is_feature_enabled("tours_360") ? tabs_list_1 : tabs_list_2}
										update_modal_json={update_modal_json}
										render_id={item.id}
										src={
											item.status === "ongoing" || item.status == "initialized" ? 'rendering'
												:
												item.status === "failed" ? '/resources/images/render_failed.png' : item.src}
										render_images_list={modal_json.tours.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at)))}
										render_image_src={modal_json.tours.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at))).map(item1 => {item1.status === "ongoing" || item1.status == "initialized" ? 'rendering'
												:
												item1.status === "failed" ? '/resources/images/render_failed.png' : item1.src})}
										render_image_id={modal_json.tours.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at))).map(item => item.id)}
										button={item.status != "ongoing" && item.status != "initialized"}
										timestamp_start={item.timestamp_start ? item.timestamp_start : ''}
										timestamp_end={item.timestamp_end ? item.timestamp_end : ''}
										progress={item.progress ? item.progress : ''}
										status={item.status}
										submit_for_delete_confirm={submit_for_delete_confirm}
										handle_ui_response={handle_ui_response}
										type={'tours'}
										name={item.name}
										etaSec={item.etaSec}
										created_at={item.created_at}
									/>
								))
									:

									<Col xs={12}>
										No Rendered Images to show.
									</Col>
						}
					</div>
				</SidebarLayout>
			</IModalBody>
		</Modal>
	)    
}

const Create_Tour_Modal =  ({open, valid_renders, modify_src, handle_ui_response, handle_close, set_page_loader, update_modal_json}) => {
	const [tour_name , set_tour_name] = useState("");
	const [selected_renders, set_selected_renders] = useState([]);
	const alert = useAlert()
    const {t} = useTranslation()

	const is_checked = (render_id) => {
		if(selected_renders.find(o => o == render_id)){
			return true;
		}

		return false;
	}

	const change_selection = (render_id) => {
		var selected_renders_copy = JSON.parse(JSON.stringify(selected_renders));
		if(is_checked(render_id)){
			selected_renders_copy = selected_renders_copy.filter(o => o != render_id);
		}else{
			selected_renders_copy.push(render_id);
		}

		set_selected_renders(selected_renders_copy);
	}

	const create_tour = async () => {
		if(!selected_renders.length){
			alert.info('Please select atleast one Render')
			return
		}
		set_page_loader({
			show: true,
			text: 'Please Wait'
		});

		setTimeout(async() => {
			var form = {
				tour_name: tour_name,
				render_ids: selected_renders
			}
			var ui_response = await window.Promisify(window.Module.create_360_tour(JSON.stringify(form)));

			handle_ui_response(ui_response);

			handle_close();

			set_page_loader({
				show: false,
				text: 'Please Wait'
			})
		},0)
	}

	useEffect(() => {
		if(open){
			set_tour_name("");
			set_selected_renders([])
		}
	},[open])

	return (
		<Modal className="modal-dialog-centered" size="lg" isOpen={open}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color, fontSize:'16px', display:'flex',justifyContent:'space-between', alignItems:'center', fontWeight:600}} >	
					<span>Create 360 Tour</span>
					<span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={handle_close}>×</span>
				</CardHeader>
				<CardBody style = {{backgroundColor: COLORS.white, maxHeight:'500px', overflow:'auto', padding:'10px 20px 20px'}}>
					<Row>
						{valid_renders && valid_renders.length ? valid_renders.map((o, idx) => (
							<Col xs={4} style={{marginBottom:10, padding:'10px'}}>
								<Input checked={is_checked(o.id)} onChange={() => {change_selection(o.id)}} type='checkbox' style={{cursor:'pointer', position:'absolute', left:40, top:10}} />
								<img style={{maxHeight:'100%', maxWidth:'100%', display:'pointer'}} src={modify_src(o.src)} crossOrigin="" />
							</Col>
						)) : <div style={{padding:'0px 14px 10px', fontSize:'14px'}}>{t(`There are no 360 renders available`)}</div>}
						<Col xs={12} className='flex_center' style={{textAlign:'center'}}>
							<Input type="text" style={{...styles_modals.input_styles, width:'90%', marginRight:'10px', paddingLeft:'8px'}} placeholder="Tour Name..." onChange={(e) => {set_tour_name(e.target.value)}} value={tour_name}/>
							<Button className='blue_button' onClick={create_tour} >Create</Button>
						</Col>
					</Row>
				</CardBody>
			</Card>
			{/* <IModalHeader toggle={handle_close}>Create 360 Tour</IModalHeader>
			<IModalBody>

			</IModalBody>
			<IModalFooter>

			</IModalFooter> */}
		</Modal>
	)

}

const Confirm_voilation_modal = ({modal_type, open, handle_ui_response, update_view, handle_close, onclick_i_understand, set_page_loader, update_modal_json}) => {

    const {t} = useTranslation()
	const onclick_handle_close = () => {
		handle_close()
	} 


	return(
		// <Modal className="modal-dialog-centered" size="md" isOpen={open}>
		// 	<Card className="bg-secondary shadow border-0" style={{fontSize:"12px"}}>
		// 		<CardBody style = {{backgroundColor: COLORS.white, padding:'30px 30px 20px' }}>
		// 			<Row style={{marginBottom:'8px'}}>
		// 				<Col>
		// 				{modal_type == "price_issue_modal"?
		// 					<div style={{fontWeight:600}}>There are pending issues with Pricing Quotation that can lead to incorrect pricing. Are you sure want to continue?</div>
		// 					:
		// 					<div style={{fontWeight:600}}>There are pending rule violations. Are you sure you want to go to design outputs without resolving them?</div>
		// 				}
		// 				</Col>  
		// 			</Row>
		// 		</CardBody>
		// 		<CardFooter style={{backgroundColor:'white', display:'flex', justifyContent:'flex-end'}}>
		// 			<Button onClick={onclick_handle_close} style={{marginRight:'10px'}} className='blue_button' >Cancel</Button>
		// 			<Button onClick={onclick_i_understand} className={modal_type=="price_issue_modal"?'white_button_auto':'blue_button'} styles ={{width:"auto"}} >I understand the risk</Button>
		// 		</CardFooter>
		// 	</Card>
        // </Modal>
		<Modal className="modal-dialog-centered" size="md" isOpen={open}>
			<IModalBody style={{backgroundColor: 'white', borderRadius: '4px', padding: '36px 16px'}}>
				{modal_type == "price_issue_modal" ?
					<div style={{ fontWeight: 600, textAlign: 'center' }} className='mb-4'>{t(`There are pending issues with Pricing Quotation that can lead to incorrect pricing. Are you sure want to continue?`)}</div>
					:
					<div style={{ fontWeight: 600, textAlign: 'center' }} className='mb-4'>{t(`There are pending rule violations. Are you sure you want to go to design outputs without resolving them?`)}</div>
				}
			<div className='flex_center'>
				<Button onClick={onclick_handle_close} className='secondary_button_default mr-3' >Cancel</Button>
				<Button onClick={onclick_i_understand} className={'red_button_default'} styles={{ width: "auto" }} >I understand the risk</Button>
			</div>
			</IModalBody>
		</Modal>
	)
}

const Rules_voilation_modal = ({ modal_type, modal_json, handle_close, update_modal_json, handle_ui_response, set_page_loader,update_view }) => {

	const styles={
		td_style:{
			width:"100%",
			minHeight:'50px',
			heigth:'auto',
			overflowWrap: 'anywhere',
			display:'flex',
			alignItems:'center',
			padding:"0px 5px"
		},
		th_style:{
			width:"100%",
			padding:"0px 5px"		
		}
	}

	const alert = useAlert();

	const [show_confirm_voilation_modal, set_show_confirm_voilation_modal] = useState(false)

	const onclick_i_understand = async () => {
		close_confirm_voilation_modal()
		set_page_loader({
			show: true,
			text: 'Please Wait'
		});

		setTimeout(async() => {
			if(modal_json.next_modal && modal_json.next_modal != ""){
				// window.Module[modal_json.next_modal]("")
				var promise_resp;
				try{
					if(window.debug_mode){
						window.add_debug_log(modal_json.next_modal + "(\"" + modal_json.next_modal_arg + "\");");
					}
					promise_resp = window.Module[modal_json.next_modal](modal_json.next_modal_arg)
				}catch(err){
					console.error(err);
					alert.error("Something went wrong");
					set_page_loader({
						show: false,
						text: 'Please Wait'
					})
					return;
				}

				var ui_response = await window.Promisify(promise_resp);
				handle_ui_response(ui_response);
				update_modal_json();
				update_view()
				
				set_page_loader({
					show: false,
					text: 'Please Wait'
				})
			}
		},0)

		//write your code here
	}

	const continue_onclick = () => {
		if(modal_json.next_modal == "run_catalogue_constraints_violations_and_show_modal_wrapper"){
			onclick_i_understand();
		}else{
			open_confirm_voilation_modal();
		}
	}
	const back_onclick = () => {
		set_page_loader({
			show: true,
			text: 'Please Wait'
		});
		setTimeout(async() => {
			if(modal_json){
				if(window.debug_mode){
					window.add_debug_log("previous_show_catalogue_constraints_violations_modal(\"" + JSON.stringify(modal_json).replace(/"/g, '\\"') + "\");");
				}
				var ui_response = await window.Promisify(window.Module["previous_show_catalogue_constraints_violations_modal"](JSON.stringify(modal_json)));
				handle_ui_response(ui_response);
				update_modal_json();
				update_view()
				
				set_page_loader({
					show: false,
					text: 'Please Wait'
				})
			}
		},0);
	}

	const open_confirm_voilation_modal = () => {
		set_show_confirm_voilation_modal(true)
	}
	const close_confirm_voilation_modal = () => {
		set_show_confirm_voilation_modal(false)
	}

	const onclick_handle_close = () => {
		handle_close()
	}

	return(
		// <Modal className="modal-dialog-centered" size="xl" isOpen={modal_type=="rules_violation_modal"}>
		// 	<Card className="bg-secondary shadow border-0">
		// 		<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color, fontSize:'16px', display:'flex',justifyContent:'space-between', alignItems:'center', fontWeight:600}} >	
		// 			<span>{modal_json && modal_json.title}</span>
		// 			<span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={onclick_handle_close}>×</span>
		// 		</CardHeader>
		// 		<CardBody style = {{backgroundColor: COLORS.white, padding:'30px 20px', fontSize:'13px' }}>					
					
		// 					<table style={{width:'100%'}}>
		// 						<thead>
		// 							<tr style={{height:'auto', width:'100%', display:'flex' , alignItems:'center', borderBottom:"2px solid #ddd",}}>
		// 								<th style={{...styles.th_style,flex:1,}}>Sl.No</th>
		// 								<th style={{...styles.th_style,flex:4}}><span>Cabinet Sku Name</span></th>
		// 								<th style={{...styles.th_style,flex:4}}>Annotation</th>
		// 								<th style={{...styles.th_style,flex:4}}>Violated Rule Name</th>
		// 								<th style={{...styles.th_style,flex:7}}><span>Violated Rule Description</span></th>
		// 							</tr>
		// 						</thead>
		// 						<tbody style={{display:"table",width:"100%", minHeight:'300px', maxHeight:"500px", overflow:"auto"}}>
		// 								{modal_json && modal_json.rules_violation_list && modal_json.rules_violation_list.length ? modal_json.rules_violation_list.map((single_unit,idx) => (
		// 									<tr key={idx} style={{width:"100%", display:'flex' , alignItems:'center', backgroundColor:(idx%2==0)?'#f2f2f2':'', borderBottom:'1px solid #ddd'}}>
		// 										<td style={{...styles.td_style,flex:1}}><span>{idx+1}</span></td>
		// 										<td style={{...styles.td_style,flex:4}} title={single_unit.cabinet_sku_name?single_unit.cabinet_sku_name:"-"}><span>{single_unit.cabinet_sku_name?single_unit.cabinet_sku_name:"-"}</span></td>
		// 										<td style={{...styles.td_style,flex:4}} title={single_unit.annotation?single_unit.annotation:"-"}><span>{single_unit.annotation?single_unit.annotation:"-"}</span></td>
		// 										<td style={{...styles.td_style,flex:4}} title={single_unit.violated_rule_name?single_unit.violated_rule_name:"-"}><span>{single_unit.violated_rule_name?single_unit.violated_rule_name:"-"}</span></td>
		// 										<td style={{...styles.td_style,flex:7}} title={single_unit.violated_rule_description?single_unit.violated_rule_description:"-"}><span>{single_unit.violated_rule_description?single_unit.violated_rule_description:"-"}</span></td>
		// 									</tr>
		// 								))
		// 								:
		// 								<tr>
		// 									No Violations
		// 								</tr>
		// 							}
		// 						</tbody>
		// 					</table>
		// 		</CardBody>
		// 		{
		// 			modal_json && (modal_json.show_continue || modal_json.next_modal == 'run_catalogue_constraints_violations_and_show_modal_wrapper')?
		// 			<CardFooter style={{textAlign:'center', backgroundColor:'white', display:'flex', justifyContent: 'space-evenly'}}>
		// 				<Button onClick={back_onclick} className='blue_button' disabled = {(modal_json && (modal_json.min_index == modal_json.prev_violation_count || modal_json.prev_violation_count == 0)? true : false)} style={{height:'30px'}} >Back</Button>
		// 				<Button onClick={continue_onclick} className='white_button_auto' style={{height:'30px'}} >Continue</Button>
		// 			</CardFooter>
		// 			:
		// 			<CardFooter style={{textAlign:'center', backgroundColor:'white', display:'flex', justifyContent: 'space-evenly'}}>
		// 				<Button onClick={back_onclick} className='blue_button' disabled = {(modal_json && (modal_json.min_index == modal_json.prev_violation_count || modal_json.prev_violation_count == 0)? true : false)} style={{height:'30px'}} >Back</Button>
		// 			</CardFooter>
		// 		}
		// 	</Card>
		// 	<Confirm_voilation_modal modal_type ={modal_type} open={show_confirm_voilation_modal} handle_ui_response={handle_ui_response} update_view={update_view} handle_close={close_confirm_voilation_modal} onclick_i_understand={onclick_i_understand} set_page_loader={set_page_loader} update_modal_json={update_modal_json}/>
		// </Modal>
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type == "rules_violation_modal"}>
			<IModalHeader toggle={onclick_handle_close}>{modal_json && modal_json.title}</IModalHeader>
			<IModalBody style={{maxHeight: '600px', overflow: 'auto' }}>

				<ITable rowSeparated={true} coloumnSeparated={true} striped={true} style_container={{ maxHeight: "500px", overflow: "auto", border: '1px solid var(--light-border-color)' }}>
					<colgroup>
						<col style={{ width: "10%" }}></col>
						<col style={{ width: "20%" }}></col>
						<col style={{ width: "10%" }}></col>
						<col style={{ width: "20%" }}></col>
						<col style={{ width: "40%" }}></col>
					</colgroup>
					<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px' }}>
						<ITH>{modal_json && modal_json.header1 ? modal_json.header1: "Sl.No"}</ITH>
						<ITH><span>{modal_json && modal_json.header2 ? modal_json.header2: "Cabinet Sku Name"}</span></ITH>
						<ITH>{modal_json && modal_json.header3 ? modal_json.header3:"Annotation"}</ITH>
						<ITH>{modal_json && modal_json.header4 ? modal_json.header4:"Violated Rule Name"}</ITH>
						<ITH><span>{modal_json && modal_json.header5 ? modal_json.header5:"Violated Rule Description"}</span></ITH>
					</ITableHeader>
					<ITableBody>
						{modal_json && modal_json.rules_violation_list && modal_json.rules_violation_list.length ? modal_json.rules_violation_list.map((single_unit, idx) => (
							<ITableRow key={idx}>
								<ITD><span>{idx + 1}</span></ITD>
								<ITD title={single_unit.cabinet_sku_name ? single_unit.cabinet_sku_name : "-"}><span>{single_unit.cabinet_sku_name ? single_unit.cabinet_sku_name : "-"}</span></ITD>
								<ITD title={single_unit.annotation ? single_unit.annotation : "-"}><span>{single_unit.annotation ? single_unit.annotation : "-"}</span></ITD>
								<ITD title={single_unit.violated_rule_name ? single_unit.violated_rule_name : "-"}><span>{single_unit.violated_rule_name ? single_unit.violated_rule_name : "-"}</span></ITD>
								<ITD title={single_unit.violated_rule_description ? single_unit.violated_rule_description : "-"}><span>{single_unit.violated_rule_description ? single_unit.violated_rule_description : "-"}</span></ITD>
							</ITableRow>
						))
							:
							<ITableRow>
								No Violations
							</ITableRow>
						}
					</ITableBody>
				</ITable>
			</IModalBody>
			{
				modal_json && (modal_json.show_continue || modal_json.next_modal == 'run_catalogue_constraints_violations_and_show_modal_wrapper') ?
					<IModalFooter>
						{!(modal_json && (modal_json.min_index == modal_json.prev_violation_count || modal_json.prev_violation_count == 0)) ? <Button onClick={back_onclick} className='secondary_button_default' style={{ height: '30px' }} >Back</Button> : ''}
						<Button onClick={continue_onclick} className='primary_button_default' style={{ height: '30px' }} >Continue</Button>
					</IModalFooter>
					:
					<IModalFooter>
						{!(modal_json && (modal_json.min_index == modal_json.prev_violation_count || modal_json.prev_violation_count == 0)) ? <Button onClick={back_onclick} className='secondary_button_default' style={{ height: '30px' }} >Back</Button> : ''}
					</IModalFooter>
			}
			<Confirm_voilation_modal modal_type={modal_type} open={show_confirm_voilation_modal} handle_ui_response={handle_ui_response} update_view={update_view} handle_close={close_confirm_voilation_modal} onclick_i_understand={onclick_i_understand} set_page_loader={set_page_loader} update_modal_json={update_modal_json} />
		</Modal>
	)    
}

const Price_issue_modal = ({ modal_type, modal_json, handle_close, update_modal_json, handle_ui_response, set_page_loader,update_view }) => {

	const styles={
		td_style:{
			width:"100%",
			minHeight:'50px',
			heigth:'auto',
			overflowWrap: 'anywhere',
			display:'flex',
			alignItems:'center',
			padding:"0px 5px"
		},
		th_style:{
			width:"100%",
			padding:"0px 5px"		
		}
	}

	const [show_confirm_price_issue_modal, set_show_confirm_price_issue_modal] = useState(false)

	const onclick_i_understand = async () => {
		close_confirm_price_issue_modal()
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {  
			update_modal_json();
			onclick_handle_close()
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)

		//write your code here
	}

	const continue_onclick = () => {
		open_confirm_price_issue_modal();
	}

	const open_confirm_price_issue_modal = () => {
		set_show_confirm_price_issue_modal(true)
	}
	const close_confirm_price_issue_modal = () => {
		set_show_confirm_price_issue_modal(false)
	}

	const onclick_handle_close = () => {
		handle_close()
	}

	return(
		// <Modal className="modal-dialog-centered" size="xl" isOpen={modal_type=="price_issue_modal"}>
		// 	<Card className="bg-secondary shadow border-0">
		// 		<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
		// 			<div>
		// 				<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>Pricing Issues</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={onclick_handle_close}>×</span></span></h5>
		// 			</div>
		// 		</CardHeader>
		// 		<CardBody style = {{backgroundColor: COLORS.white, padding:'30px 20px', fontSize:'13px' }}>					
					
		// 					<table style={{width:'100%'}}>
		// 						<thead>
		// 							<tr style={{height:'auto', width:'100%', display:'flex' , alignItems:'center', borderBottom:"2px solid #ddd",}}>
		// 								<th style={{...styles.th_style,flex:1,}}>Sl.No</th>
		// 								<th style={{...styles.th_style,flex:4}}><span>Name</span></th>
		// 								<th style={{...styles.th_style,flex:4}}>Type</th>
		// 								<th style={{...styles.th_style,flex:7}}><span>Error Name</span></th>
		// 								<th style={{...styles.th_style,flex:7}}><span>Additional Description</span> </th>
		// 							</tr>
		// 						</thead>
		// 						<tbody style={{display:"table",width:"100%", minHeight:'300px', maxHeight:"500px", overflow:"auto"}}>
		// 								{modal_json && modal_json.price_issue_list && modal_json.price_issue_list.length ? modal_json.price_issue_list.map((single_unit,idx) => (
		// 									<tr key={idx} style={{width:"100%", display:'flex' , alignItems:'center', backgroundColor:(idx%2==0)?'#f2f2f2':'', borderBottom:'1px solid #ddd'}}>
		// 										<td style={{...styles.td_style,flex:1}}><span>{idx+1}</span></td>
		// 										<td style={{...styles.td_style,flex:4}} title={single_unit.name?single_unit.name:"-"}><span>{single_unit.name?single_unit.name:"-"}</span></td>
		// 										<td style={{...styles.td_style,flex:4}} title={single_unit.type?single_unit.type:"-"}><span>{single_unit.type?single_unit.type:"-"}</span></td>
		// 										<td style={{...styles.td_style,flex:7}} title={single_unit.error_name?single_unit.error_name:"-"}><span>{single_unit.error_name?single_unit.error_name:"-"}</span></td>
		// 										<td style={{...styles.td_style,flex:7}} title={single_unit.additional_description?single_unit.additional_description:"-"}><span>{single_unit.additional_description?single_unit.additional_description:"-"}</span></td>
												
		// 									</tr>
		// 								))
		// 								:
		// 								<tr>
		// 									No Error
		// 								</tr>
		// 							}
		// 						</tbody>
		// 					</table>
		// 		</CardBody>
		// 		<CardFooter style={{backgroundColor:'white', display:'flex', justifyContent:'flex-end'}}>
		// 			<Button onClick={continue_onclick} className='white_button_auto'>Continue</Button>
		// 		</CardFooter>
		// 	</Card>
		// 	<Confirm_voilation_modal modal_type={modal_type} open={show_confirm_price_issue_modal} handle_ui_response={handle_ui_response} update_view={update_view} handle_close={close_confirm_price_issue_modal} onclick_i_understand={onclick_i_understand} set_page_loader={set_page_loader} update_modal_json={update_modal_json}/>
		// </Modal>
		
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type == "price_issue_modal"}>
			<IModalHeader toggle={onclick_handle_close}>Pricing Issues</IModalHeader>
			<IModalBody style={{ maxHeight: '600px', overflow: 'auto' }}>
				<ITable coloumnSeparated={true} rowSeparated={true} striped={true} hover={true} style_container={{ border: '1px solid var(--light-border-color)', maxHeight: '568px', overflow: 'auto' }}>
					<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px' }}>
						<ITH>Sl.No</ITH>
						<ITH>Name</ITH>
						<ITH>Type</ITH>
						<ITH>Error Name</ITH>
						<ITH>Additional Description</ITH>
					</ITableHeader>
					<ITableBody>
						{modal_json && modal_json.price_issue_list && modal_json.price_issue_list.length ? modal_json.price_issue_list.map((single_unit, idx) => (
							<ITableRow key={idx}>
								<ITD>{idx + 1}</ITD>
								<ITD title={single_unit.name ? single_unit.name : "-"}>{single_unit.name ? single_unit.name : "-"}</ITD>
								<ITD title={single_unit.type ? single_unit.type : "-"}>{single_unit.type ? single_unit.type : "-"}</ITD>
								<ITD title={single_unit.error_name ? single_unit.error_name : "-"}>{single_unit.error_name ? single_unit.error_name : "-"}</ITD>
								<ITD title={single_unit.additional_description ? single_unit.additional_description : "-"}>{single_unit.additional_description ? single_unit.additional_description : "-"}</ITD>
							</ITableRow>
						))
							:
							<ITableRow>
								No Error
							</ITableRow>
						}
					</ITableBody>
				</ITable>
			</IModalBody>
			<IModalFooter>
				<Button onClick={continue_onclick} className='secondary_button_default'>Continue</Button>
			</IModalFooter>
			<Confirm_voilation_modal modal_type={modal_type} open={show_confirm_price_issue_modal} handle_ui_response={handle_ui_response} update_view={update_view} handle_close={close_confirm_price_issue_modal} onclick_i_understand={onclick_i_understand} set_page_loader={set_page_loader} update_modal_json={update_modal_json} />
		</Modal>
	)    
}
const Outputs_Blocked = ({ modal_type, modal_json, handle_close, handle_ui_response, set_page_loader}) => {
	const onclick_handle_close = () => {
		handle_close()
	}
	const onclick_exemption = () => {
		set_page_loader({
			show: true,
			text: 'Please Wait'
		});
		setTimeout(async() => {
			if(modal_json){
				var ui_response = await window.Promisify(window.Module["request_for_design_branch_violation"](""));
				handle_ui_response(ui_response);
				handle_close()

				set_page_loader({
					show: false,
					text: 'Please Wait'
				})
			}
		},0);
	}

	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type=="outputs_blocked"}>
			<Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}} >
					<div>
						<h5 style={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0px'}}><span>{modal_json && modal_json.title ? modal_json.title : ''}</span><span><span style={{fontSize:'24px', fontWeight:'700', cursor:'pointer'}} onClick={onclick_handle_close}>×</span></span></h5>
					</div>
				</CardHeader>	
				<CardBody style = {{backgroundColor: COLORS.white, padding:'30px 20px', fontSize:'13px', maxHeight:'600px', overflow:"auto"}}>					
					<Row style={{marginBottom:'8px'}}>
						<Col>
							<div style={{fontWeight:600}}>{modal_json && modal_json.message?modal_json.message:''}</div>
						</Col>  
					</Row>	
				</CardBody>
				<CardFooter style={{backgroundColor:'white', display:'flex', justifyContent:'flex-end'}}>
					<Button onClick={onclick_handle_close} style={{marginRight:'10px'}} className='blue_button' >Ok</Button>
					<Button onClick={onclick_exemption} className='blue_button' >Request Admin for exemption</Button>
				</CardFooter>
			</Card>
		</Modal>
	)    
}

const Pricing_Error = ({ modal_type, modal_json }) => {
	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type=="pricing_errors"}>
			<Card className="bg-secondary shadow border-0">
				<CardBody style = {{backgroundColor: COLORS.white, padding:'30px 20px', fontSize:'13px', maxHeight:'600px', overflow:"auto"}}>					
				<Row style={{marginBottom:'8px'}}>
					<Col>
						<div style={{fontWeight:600}}>{modal_json && modal_json.message ?modal_json.message:''}</div>
					</Col>  
				</Row>
				</CardBody>
				<CardFooter style={{backgroundColor:'white', display:'flex', justifyContent:'flex-end'}}>
					<Button style={{height:'30px'}} className='blue_button' onClick={() => {window.location.reload(true)}}>Reload</Button>
				</CardFooter>
			</Card>
		</Modal>
	)    
}

const Presentation_Add_Image = ({modal_type, modal_json, handle_close, handle_ui_response, update_view}) => {
	const [active_tab, set_active_tab] = useState(0);
    const {t} = useTranslation()

	const onclick_handle_close = () => {
		handle_close()
		set_active_tab(0)
	}

	const handle_upload = () => {
		handle_ui_response(JSON.stringify({
			upload:{
				callback:"presentation_add_image_callback"
			}
		}));
	}

	const addImageToPresentation = (src, id) => {
		console.log(id + "id of tab");
		var params  = {
			filename: "",
			file_src: src.split('.com/')[1],
			is_render: id == "rendered_images"?true:false
		}
		
		var analytics_data = {}
		analytics_data.category = "Render added to ppt";
		send_analytics(analytics_data,true)
		window.Module.presentation_add_image_callback(JSON.stringify(params));
		onclick_handle_close();
	}

	return(
		// <Modal className="modal-dialog-centered" size="xl" isOpen={modal_type=="presentation_add_image"} toggle={onclick_handle_close}>
		// 	<ModalHeader toggle={onclick_handle_close}> Add Image </ModalHeader>
		// 	<ModalBody>
		// 		<div style={{display:'flex', justifyContent:'center', margin:'24px', flexFlow:'column', alignItems:'center'}}>
		// 			{/* <Button className='primary_button_default' onClick={onclick_upload}> Upload Image from Computer</Button> */}
		// 			<div style={{textAlign:'left'}}>
		// 				<Button onClick={handle_upload} >Upload Image from Computer</Button>
		// 			</div>
		// 		</div>
		// 		<div style={{display:'flex', width:'100%', padding:'8px 24px', overflow:'auto'}}>
		// 				{
		// 					modal_json && modal_json.length ? modal_json.map((item, idx) => (<div onClick={() => set_active_tab(idx)} style={{padding:'6px 12px', backgroundColor: idx == active_tab ? COLORS.gray2 : COLORS.gray1}}>{item.name} </div>)) : ''
		// 				}
		// 		</div>
		// 		<div style={{display:'flex', width:'100%', padding:'8px 24px', overflow:'auto', flexWrap:'wrap', maxHeight: '500px', overflow:'auto'}}>
		// 			{
		// 				modal_json && modal_json[active_tab] && modal_json[active_tab].image_src && modal_json[active_tab].image_src.length 
		// 				? 
		// 					modal_json[active_tab].image_src.map(item => (<div style={{width:'220px', height: '160px', display:'flex', alignItems: 'center', justifyContent: 'center', border:'0.5px solid black', overflow: 'hidden', margin: '16px'}}><img src={item} height='160px' style={{margin: '16px'}} onClick={() => addImageToPresentation(item, modal_json[active_tab].id)}></img></div>)) 
		// 				: 
		// 					<div>No Images Found</div>
		// 			}
		// 		</div>
		// 	</ModalBody>
		// </Modal>
		
		<Modal className="modal-dialog-centered" size="xl" isOpen={modal_type=="presentation_add_image"} toggle={onclick_handle_close}>
			<IModalHeader toggle={onclick_handle_close}> Add Image </IModalHeader>
			<IModalBody>
				<div className='flex_center my-3'>
					<Button className='primary_button_default' onClick={handle_upload} >Upload Image from Computer</Button>
				</div>

				{/* <div style={{display:'flex', width:'100%', padding:'8px 24px', overflow:'auto'}}>
						{
							modal_json && modal_json.length ? modal_json.map((item, idx) => (<div onClick={() => set_active_tab(idx)} style={{padding:'6px 12px', backgroundColor: idx == active_tab ? COLORS.gray2 : COLORS.gray1}}>{item.name} </div>)) : ''
						}
				</div> */}
				<UncontrolledINavbar tabs={modal_json} display_identifier={'name'} set_active_tab={set_active_tab}/>

				<div style={{display:'flex', width:'100%', overflow:'auto', flexWrap:'wrap', height: '400px', overflow:'auto', gap: '16px'}}>
					{
						modal_json && modal_json[active_tab] && modal_json[active_tab].image_src && modal_json[active_tab].image_src.length 
						? 
							modal_json[active_tab].image_src.map(item => (<div style={{height: '160px', display:'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', cursor: 'pointer'}} className='presentation_image_card'><img src={item} height='160px' onClick={() => addImageToPresentation(item, modal_json[active_tab].id)}></img></div>)) 
						: 
							<div style={{width: '100%', height: '100%', fontSize: '18px'}} className='flex_center'>{t(`No Images Found`)}</div>
					}
				</div>
			</IModalBody>
		</Modal> 
	)
}

const VendorList = ({ modal_type, modal_json, handle_close, update_modal_json, handle_ui_response, update_view, set_page_loader}) => {

	const [modal_json_copy, set_modal_json_copy] = useState([]);
	const [bucket, set_bucket] = useState('none');
	const [active_tab, set_active_tab] = useState('');
	const [possible_types, set_possible_types] = useState([]);
	const [bulk_carcass_selection, set_bulk_carcass_selection] = useState('none');
	const [alternate_sku_list, set_alternate_sku_list] = useState([]);
	const alert = useAlert();
    const {t} = useTranslation()

	useEffect(() => {
		if((modal_type == "sku_bucket_selector_modal" || modal_type == "hometown_vendor_info_modal" || modal_type == "carcass_vendor_info_modal") && modal_json){
			set_modal_json_copy(modal_json);
			// console.log(modal_json)
			if(modal_type == "carcass_vendor_info_modal"){
				let temp_alternate_sku_list = modal_json.rows && modal_json.rows.length ? modal_json.rows.map(x => {
					let t = {}
					t.id = x.id
					t.sku_id = x.selected_id ? x.selected_id : 'none'
					return t;
				}):[]
				console.log("temp alternate sku list", temp_alternate_sku_list);
				set_alternate_sku_list(temp_alternate_sku_list)
			}
			if(modal_type == "hometown_vendor_info_modal"){
				let possible_type_list = modal_json && modal_json.rows && modal_json.rows.length  ? modal_json.rows.reduce((final_list, current_item) => { 
					current_item.tab_id ? final_list.find(item => item.id == current_item.tab_id) ? '' : final_list.push({id:current_item.tab_id, name: current_item.tab_id.split('_').map(o => (o.length?o[0].toUpperCase()+o.substr(1):o)).join(" ")}): '';
					return final_list;
				},[]):[]
				set_possible_types(possible_type_list)
				possible_type_list.length ? set_active_tab(possible_type_list[0].id) : ''
			}
		}
	}, [modal_json, modal_type]);

	const SingleRow_VendorList = ({item , alternate_sku_list, set_alternate_sku_list, bulk_carcass_selection }) => {
		const [dropdownOpen, set_dropdownOpen] = useState(false);
		const [current_selection_alternate_sku, set_current_selection_alternate_sku] = useState('none');

		useEffect(() => {
			if(bulk_carcass_selection != 'none'){
				if(item && item.options && item.options.length){
					let t = item.options.find(x => x.variant_id == bulk_carcass_selection)
					if(t){
						set_alternate_sku(t.id)
					}
				}
			}
		}, [bulk_carcass_selection]);

		const toggle = () => {set_dropdownOpen(!dropdownOpen)}

		const set_alternate_sku = (alternate_sku_id) => {
			let t = alternate_sku_list.find(x => x.id === item.id)
			t.sku_id = alternate_sku_id;
			set_current_selection_alternate_sku(alternate_sku_id)
			set_alternate_sku_list(alternate_sku_list)
		}

		const open_carousel_for_selected_item = () => {
			set_page_loader({
				show: true,
				text: 'Please wait'
			});
			setTimeout(async() => {
				await window.Promisify(window.Module.show_filtered_carousel_for_finish_sku_id(item.id))
				update_view();
				
				set_page_loader({
					show: false,
					text: 'Please wait'
				});	
			},0)
		}		

		return(
			<tr className='tr_style' style={{backgroundColor:item.color?item.color:'#FFFFFF'}}>
				<td className='td_style flex_property' style={{flex:'1', height:'auto'}}>
					<div className='flex_property js-sb vendor_container'>
						<div className='vendor_modal_regular_text'>{item.name}</div>
						<div className='flex_property'>
							<img src={item.display_pic} height="60px" width="60px" className='vendor_list_image' />
							{modal_type != 'carcass_vendor_info_modal' ?<div style={{marginLeft: '8px', color: COLORS.blue6, cursor: 'pointer'}} onClick={open_carousel_for_selected_item}>Change</div>:''}
						</div>
					</div>
				</td>
				<td className='td_style flex_property' style={{flex:'1', height:'auto'}}>
					<div className='vendor_container flex_property js-sb'>
						<div className='vendor_modal_regular_text'>{item.present_in && item.present_in.length} SKUs</div>
						<div className='vendor_present_sku_container'>
							{
								item.present_in && item.present_in.length ? item.present_in.map((sku_name, idx) => (
									<div style={{maxWidth:'100%', whiteSpace:'pre-wrap'}}>{sku_name + (idx !== item.present_in.length - 1 ? ', ' : '')}</div>
								))
								:''
							}
						</div>
					</div>
				</td>
				{/* <td className='td_style flex_center' style={{flex:'1', height:'auto', overflow:'visible'}}>
					<div className='flex_property js-sb vendor_container'>
						<img style={{cursor:'pointer'}} src="/resources/icons/fetch_store.svg" height="20px" width="20px" onClick={open_carousel_for_selected_item}/>
					</div>
				</td> */}
				{modal_type == 'carcass_vendor_info_modal' ?
				<td className='td_style flex_center' style={{flex:'1', height:'auto', overflow:'visible', whiteSpace:'pre-wrap'}} onClick={toggle}>
					<div className='flex_property justify-content-between vendor_container'>
						{item.options && item.options.length && item.options.find(x => x.id == alternate_sku_list.find(y => y.id == item.id).sku_id).name}
						<Dropdown isOpen={dropdownOpen} toggle={(e) => {e.stopPropagation(); toggle()}} style={{position:'unset'}}>
							<DropdownToggle style={{all:'unset', cursor:'pointer'}}>
								<i className='fa fa-caret-down' />
							</DropdownToggle>
							<DropdownMenu right style={{padding:'4px', maxWidth:'350px', maxHeight:'160px', overflow:'auto'}}>
								{
									item.options && item.options.length ? item.options.map((option, idx) => (
											<DropdownItem key={idx} onClick={() => set_alternate_sku(option.id)}>
												<div className='flex_property js-sb'>
													<div className='vendor_modal_regular_text'>{option.name}</div>
													<img src={option.display_pic} height="60px" width="60px" className='vendor_list_image' />
												</div>
											</DropdownItem>
									))
									:''
								}
							</DropdownMenu>
						</Dropdown>
					</div>
				</td>
				:''}
			</tr>
		)
	}

	const onclick_handle_close = () => {
		set_modal_json_copy([])
		set_bucket('none')
		set_active_tab('shutter')
		set_bulk_carcass_selection('none')
		handle_close()
	}
	const continue_to_output = () => {
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {
			if(modal_type == 'carcass_vendor_info_modal'){
				handle_close()
				if(modal_json_copy && modal_json_copy.continue_to_output && modal_json_copy.continue_to_output.route && modal_json_copy.continue_to_output.arguments){
					modal_json_copy.continue_to_output.arguments["hometown_vendor_info_checked"] = true;
					let resp = await window.Promisify(window.Module[modal_json_copy.continue_to_output.route](JSON.stringify(modal_json_copy.continue_to_output.arguments)))
					handle_ui_response(resp)
					update_view()
				}
			}else if(modal_type == 'hometown_vendor_info_modal'){
				await window.Promisify(window.Module.show_carcass_change_modal());
				update_modal_json();
			}
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	const onclick_select_bucket = () => {
		if(bucket != '' && bucket != 'none'){
			set_page_loader({
				show: true,
				text: 'Please wait'
			});
			setTimeout(async() => {
				await window.Promisify(window.Module.apply_winner_vendor_modal(bucket));
				update_modal_json();
				
				set_page_loader({
					show: false,
					text: 'Please wait'
				});	
			},0)
		}else{
			alert.error('Please Select a Bucket to Proceed')
		}
	}

	const goback_sku_buckets = () => {
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {
			await window.Promisify(window.Module.show_winner_vendor_selection_modal());
			update_modal_json();
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}
	const onclick_carcass_apply_changes = () => {
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {
			await window.Promisify(window.Module.apply_carcass_changes_from_modal(JSON.stringify(alternate_sku_list)));
			update_modal_json();
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	const onclick_print_other_carcasses = () => {
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {
			let response = await window.Promisify(window.Module.print_other_available_carcasses(""));
			console.log(response)
			handle_ui_response(response);
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	return(
		<Modal className="DMSans" size="lg" isOpen={modal_type=="hometown_vendor_info_modal" || modal_type=="sku_bucket_selector_modal" || modal_type =="carcass_vendor_info_modal"}>
			<ModalHeader toggle={onclick_handle_close}>{modal_json_copy && modal_json_copy.title ? modal_json_copy.title : "SKU Availability Checker"}</ModalHeader>
				{
					modal_type == "carcass_vendor_info_modal"?
					<ModalBody style={{fontSize:'14px'}}>
						<div className='flex_property justify-content-center mb-3'>
							<div className='flex_property mr-3 ml-4'>
								<div style={{borderRadius:'50%', height:'24px', width:'24px', textAlign:'center', border:'1px solid #29C940'}} className='mr-3'>1</div>
								<div>{t(`Choose the bucket`)}</div>
							</div>
							<div className='flex_property mr-3 ml-4'>
								<div style={{borderRadius:'50%', height:'24px', width:'24px', textAlign:'center', border:'1px solid #29C940'}} className='mr-3'>2</div>
								<div>{t(`Replace unavailable`)}</div>
							</div>
							<div className='flex_property mr-3 ml-4'>
								<div style={{borderRadius:'50%', height:'24px', width:'24px', textAlign:'center', border:'1px solid #0078FF'}} className='mr-3'>3</div>
								<div>{t(`Replace Carcass`)}</div>
							</div>
						</div>
						<div className='flex_property justify-content-between'>
							<div>
								Please change the carcass to respective available variants.
							</div>
							<div className='flex_property mb-3'>
								<div className='mr-3'>{t(`Bulk Action`)}</div>
								<Input type='select' value={bulk_carcass_selection}  onChange={(e) => {set_bulk_carcass_selection(e.target.value)}}>
									<option value='none'>Select the variant to populate all fields</option>
									{
										modal_json && modal_json.common_variants ? modal_json.common_variants.map(((x, idx) => (
											<option key={idx} value={x.id}>{x.name}</option>
										)))
										:''
									}
								</Input>
							</div>
						</div>
						<table style={{height:'100%', overflow:'auto'}} className="text-left flex_property table_style">
							<thead style={{width:"100%", backgroundColor: '#F5F6F8'}}>
								<tr className='tr_style'>
									<th className='th_style flex_property' style={{flex:'1'}}> Current SKUs </th>
									<th className='th_style flex_property' style={{flex:'1'}}>Present in SKUs</th>
									<th className='th_style flex_property' style={{flex:'1'}}>Action(Select the Alternative SKU from the group)</th>
									{/* <th className='th_style flex_property' style={{flex:'1'}}>Replace SKU</th> */}
								</tr>
							</thead>
							<tbody style={{overflow:"auto",width:"100%", height:"250px"}}>
							{
								modal_json_copy && modal_json_copy.rows && modal_json_copy.rows.length ? modal_json_copy.rows.map((item, idx) => (
									<SingleRow_VendorList item={item}  alternate_sku_list={alternate_sku_list} set_alternate_sku_list={set_alternate_sku_list} bulk_carcass_selection={bulk_carcass_selection} key={idx} />
								))
								:''
							}
							</tbody>
						</table>	
					</ModalBody>
					:
					(modal_type == "hometown_vendor_info_modal" ?
						<ModalBody style={{fontSize:'14px'}}>
							<div className='flex_property justify-content-center mb-3'>
								<div className='flex_property mr-3 ml-4'>
									<div style={{borderRadius:'50%', height:'24px', width:'24px', textAlign:'center', border:'1px solid #29C940'}} className='mr-3'>1</div>
									<div>{t(`Choose the bucket`)}</div>
								</div>
								<div className='flex_property mr-3 ml-4'>
									<div style={{borderRadius:'50%', height:'24px', width:'24px', textAlign:'center', border:'1px solid #0078FF'}} className='mr-3'>2</div>
									<div>{t(`Replace unavailable`)}</div>
								</div>
								<div className='flex_property mr-3 ml-4'>
									<div style={{borderRadius:'50%', height:'24px', width:'24px', textAlign:'center', border:'1px solid #4D76A4'}} className='mr-3'>3</div>
									<div>{t(`Replace Carcass`)}</div>
								</div>
							</div>
							<div className='flex_property mb-3'>
								{
									possible_types && possible_types.length ? possible_types.map((type, idx) => (
										<div className={type.id == active_tab ? 'active_possible_type' : 'possible_type'} key={idx} onClick={() => {type.id == active_tab ? null : set_active_tab(type.id)}}>{type.name}</div>
									)):''
								}
							</div>
							<table style={{height:'100%', overflow:'auto'}} className="text-left flex_property table_style">
								<thead style={{width:"100%", backgroundColor: '#F5F6F8'}}>
									<tr className='tr_style'>
										<th className='th_style flex_property' style={{flex:'1'}}> Current SKUs </th>
										<th className='th_style flex_property' style={{flex:'1'}}>Present in SKUs</th>
										{/* <th className='th_style flex_property' style={{flex:'1'}}>Replace SKU</th> */}
									</tr>
								</thead>
								<tbody style={{overflow:"auto",width:"100%", height:"250px"}}>
								{
									modal_json_copy && modal_json_copy.rows && modal_json_copy.rows.length ? modal_json_copy.rows.map((item, idx) => (
										item.tab_id == active_tab ? <SingleRow_VendorList item={item} /* alternate_sku_list={alternate_sku_list} set_alternate_sku_list={set_alternate_sku_list} */ key={idx} /> : ''
									))
									:''
								}
								</tbody>
							</table>	
						</ModalBody>
					:
						<ModalBody style={{backgroundColor: '#F5F6F8', fontSize:'14px'}}>
							<div className='flex_property justify-content-center mb-3'>
								<div className='flex_property mr-3 ml-4'>
									<div style={{borderRadius:'50%', height:'24px', width:'24px', textAlign:'center', border:'1px solid #0078FF'}} className='mr-3'>1</div>
									<div>{t(`Choose the bucket`)}</div>
								</div>
								<div className='flex_property mr-3 ml-4'>
									<div style={{borderRadius:'50%', height:'24px', width:'24px', textAlign:'center', border:'1px solid #4D76A4'}} className='mr-3'>2</div>
									<div>{t(`Replace unavailable`)}</div>
								</div>
								<div className='flex_property mr-3 ml-4'>
									<div style={{borderRadius:'50%', height:'24px', width:'24px', textAlign:'center', border:'1px solid #4D76A4'}} className='mr-3'>3</div>
									<div>{t(`Replace Carcass`)}</div>
								</div>
							</div>				
							<div className='bucket_selection'>
								<div className='mb-3'>{t(`Please select the bucket of SKUs provided by the organisation`)}</div>
								<div>
									<Input style={{fontSize:'12px'}} type='select' value={bucket} onChange={(e) => set_bucket(e.target.value)}>
										<option value='none'>Select the buckets</option>
										{
											modal_json && modal_json.buckets ? Object.keys(modal_json.buckets).map((single_bucket, idx) => (
												<option value={single_bucket} key={idx}>{modal_json.buckets[single_bucket].name}</option>
											)) : ''
										}
									</Input>
								</div>
							</div>

							<div className='sku_in_bucket'>
								<div className='mb-3'>Viewing all SKUs in the bucket {modal_json && modal_json.buckets && modal_json.buckets[bucket] ? modal_json.buckets[bucket].name : ''}</div>
								<div className='sku_list_container'>
									{
										modal_json && modal_json.buckets && modal_json.buckets[bucket] && modal_json.buckets[bucket].elements ? modal_json.buckets[bucket].elements.map((sku, idx) => (
											<div className='sku_card_container' title={sku.name}>
												<img src={sku.display_pic} className='sku_image'/>
												<div className='lines1_elipsis'>{sku.name}</div>
											</div>
										))
										:''
									}
								</div>
							</div>
						</ModalBody>)
				}
			<ModalFooter>
				{modal_type == 'hometown_vendor_info_modal'? <Button className='primary_button_default' onClick={goback_sku_buckets}> Go Back to SKU Buckets </Button>:''}
				{modal_type == "carcass_vendor_info_modal" && modal_json && modal_json.show_individual_carcass_options ?<Button className='primary_button_default' onClick={onclick_print_other_carcasses}> Individual Carcass Options </Button> : ''}
				{modal_type == "carcass_vendor_info_modal" ?<Button className='primary_button_default' onClick={onclick_carcass_apply_changes}> Apply Changes </Button> : ''}
				{modal_type == "sku_bucket_selector_modal" ?<Button className='primary_button_default' onClick={onclick_select_bucket}> Next </Button>
				:<Button className='primary_button_default' disabled={modal_json_copy && modal_json_copy.continue_to_output && modal_json_copy.continue_to_output.disabled} onClick={continue_to_output}> Continue </Button>}
			</ModalFooter>
		</Modal>
	)    
}



const ShortcutsModal = ({ modal_type, handle_close, modal_json }) => {
	const [activeTab, setActiveTab] = useState(''); // Set the initial active tab based on the first tab_id
	const [searchQuery, setSearchQuery] = useState('');
	const [filtered_data, set_filtered_data] = useState([]);

	const handleTabClick = (tabId) => {
		setActiveTab(tabId);
	};

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const clear_search = () => {
        setSearchQuery('')
    }

	const on_click_handleclose = () => {
		setSearchQuery('')
		set_filtered_data([])
		handle_close()
	}

	useEffect(() => {
		if(modal_type == 'shortcuts'){
			filterData()
		}
	}, [modal_json, modal_type]);

	useEffect(() => {
		if(modal_type == 'shortcuts'){
			if(window.debug_mode){
				console.log("shortcuts-react ",modal_json)
			}
			setActiveTab(modal_json && modal_json.length ? modal_json[0].tab_id : '')
		}
	}, [modal_type, modal_json]);

	useEffect(() => {
		if(modal_type == 'shortcuts'){
			filterData()
		}
	}, [searchQuery]);

	const filterData = () => {
		let x = modal_json && modal_json.length ? modal_json.map((tab) => {
				// let a = {...tab}
				return tab.content.filter(x => x.name.toLowerCase().includes(searchQuery.toLowerCase()) || x.value.toLowerCase().includes(searchQuery.toLowerCase()))
			}
		) : []
		let a = []
		let t = x.map(item => a = [...a, ...item])
		set_filtered_data(a)
	}

	const renderTableRows = (tabContent) => {
		const rows = [];
		const contentLength = tabContent.length;

		for (let i = 0; i < contentLength; i += 2) {
			const item1 = tabContent[i];
			const item2 = i + 1 < contentLength ? tabContent[i + 1] : null;

			rows.push(
			<tr key={i} style={{borderBottom: '1px solid #e6e9ee'}}>
				<td>{item1.name}</td>
				<td style={{borderRight:'1px solid #c5c7cf'}}>{item1.value}</td>
				{item2 ?
					<React.Fragment>
						<td>{item2.name}</td>
						<td>{item2.value}</td>
					</React.Fragment>
					:''
				}
			</tr>
			);
		}

		return rows;
	};

	return (
	<Modal className="modal-dialog-centered DMSans" size='xl' isOpen={modal_type == 'shortcuts'}>
		<IModalHeader toggle={on_click_handleclose}>Shortcuts List</IModalHeader>
		<IModalBody style={{height: '550px', overflow: 'auto'}}>
			<div style={{display: 'flex'}} className='mb-3'>
				<div className='flex_property' style={{width: 'calc( 100% - 220px )', flexWrap:'wrap'}}>
				{
					modal_json && modal_json.length ? modal_json.map((tab) => (
						<div key={tab.tab_id} onClick={() => handleTabClick(tab.tab_id)} className={activeTab == tab.tab_id ? 'inf_nav_tabs_transparent active' : 'inf_nav_tabs_transparent'}>
							{/* <NavLink
								className={activeTab === tab.tab_id ? 'active' : ''}
								onClick={() => handleTabClick(tab.tab_id)}
							>
								{tab.tab_name}
							</NavLink> */}
							{
								tab.tab_name
							}
						</div>
					))
					:''
				}
				</div>
				<div style={{width:'220px', border: '1px solid #c5c7cf', backgroundColor:'white', height:'fit-content'}} className='flex_property'>
					<span><i style={{paddingLeft:'12px', paddingBottom:'3px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/></span>
					<Input style={{...styles_modals.input_styles_none, paddingLeft:'12px'}} type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
					<span className='hover_blue' style={{paddingRight:'12px', cursor:'pointer', fontSize:'10px', color:'#9aa5b5', visibility:searchQuery ? 'visible':'hidden', fontStyle:'italic'}} onClick={clear_search}>Clear</span>
				</div>
			</div>
			<TabContent activeTab={activeTab}>
			{
				modal_json && modal_json.length ? modal_json.map((tab) => (
					<TabPane key={tab.tab_id} tabId={tab.tab_id}>
					<Table style={{backgroundColor:'white', tableLayout: 'fixed'}}>
						<thead>
						<tr>
							<th className='shortcuts_table_header'>Functionality</th>
							<th className='shortcuts_table_header' style={{borderRight:'1px solid #c5c7cf'}}>Shortcut</th>
							<th className='shortcuts_table_header'>Functionality</th>
							<th className='shortcuts_table_header'>Shortcut</th>
						</tr>
						</thead>
						<tbody style={{color:'#676878'}}>
							{
								modal_type == 'shortcuts' ?
									searchQuery === ''
										? renderTableRows(tab.content ? tab.content : [])
										: renderTableRows(filtered_data)
								: ''
							}
						</tbody>
					</Table>
					</TabPane>
				))
				:''
			}
			</TabContent>
		</IModalBody>
	</Modal>
	);
};



const Download_Board_Layout = ({modal_type, modal_json, handle_close, handle_ui_response, update_view, set_page_loader}) => {

	const [selected_id, set_selected_id] = useState('');
	const [include_fulfillment_tags, set_include_fulfillment_tags] = useState(false);
    const {t} = useTranslation()
	const alert = useAlert()
	// useEffect(() => {
	// 	if(modal_json && modal_json.length){}
	// }, []);

	const onclick_handle_close = () => {
		handle_close()
		set_selected_id('')
	}

	const download_board_layout = async () => {
		set_page_loader({
			show: true,
			text: 'Downloading Files...'
		}, async() => {
			
		var source_id_for_output = selected_id;
		if(include_fulfillment_tags){
			source_id_for_output += '|fulfillment_tags'
		}
		var resp = await window.Promisify(window.Module.check_for_output_download(source_id_for_output))
		// if(include_fulfillment_tags) var resp2 = await window.Promisify(window.Module.check_for_output_download("fulfillment_tags"))
		handle_ui_response(resp)
		// handle_ui_response(resp2)
		onclick_handle_close()

		set_page_loader({
			show: false,
			text: 'Downloading Files...'
		});	
	})
	}


	return(
		<Modal className="modal-dialog-centered DMSans" size="lg" isOpen={modal_type=="download_board_layout"} toggle={onclick_handle_close}>
			<ModalHeader toggle={onclick_handle_close}> {"Download Board Layout (Pdf)"} </ModalHeader>
			<ModalBody>
				<div style={{display:'flex', width:'100%', padding:'8px 24px', overflow:'auto', flexWrap:'wrap', maxHeight: '500px', overflow:'auto', flexDirection:'column'}}>
					{
						modal_json && modal_json.length ? modal_json.map((item, idx) => (
							<div className='flex_center checkbox_list_item' onClick={() => {set_selected_id(item.id)}}> <input style={{marginRight: '6px'}} type='checkbox' readOnly checked={item.id === selected_id}></input> {item.name} </div>
						)) : ''
					}
				</div>
			</ModalBody>
			<ModalFooter style={{justifyContent:'space-between', padding:'16px'}}>
				<div className='flex_center ml-4'>
					<Checkbox checked={include_fulfillment_tags} onClick={() => set_include_fulfillment_tags(!include_fulfillment_tags)} />
					<div className='ml-2' style={{fontSize:'14px'}}>{t(`Include Panel Labels`)}</div>
				</div>
				<Button className='primary_button_default' disabled={!selected_id} onClick={download_board_layout}> Download </Button>
			</ModalFooter>
		</Modal> 
	)
}

const SingleFieldFulfillmentTag = ({item, input_array, set_input_array, is_draggable = true}) => {

	const checkbox_onchange = (item) => {
		// var cutlist_pref = JSON.parse(JSON.stringify(input_array));
		var field = input_array.find(o => o.id === item.id);

		if (field) {
			field.visibility = !field.visibility;
		}
		set_input_array(JSON.parse(JSON.stringify(input_array)));
	}

	return (
		<div style={{ height: '36px', display: 'flex', alignItems: 'center', width: '100%' }}>
			{/* <div style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}><i style={{ marginRight: '1px' }} className='fa fa-ellipsis-v' /><i className='fa fa-ellipsis-v' /></div> */}
			{is_draggable ? <img className='inf-mr-2' src='/resources/icons/drag_handle.svg'/> : ''}
			<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
				<div style={{ fontSize: '14px', flex: 1, minWidth: '10px', flexWrap: 'wrap' }}>{item.name}</div>
				<div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1, alignItems: 'center' }}>
					<Switch
						onChange={() => { checkbox_onchange(item) }}
						checked={item.visibility}
						uncheckedIcon={false}
						checkedIcon={false}
						offColor='#e6e6e6'
						offHandleColor='#808080'
						onColor='#00924f'
						onHandleColor='#ffffff'
						height={16}
						width={32}
					/>
				</div>
			</div>
		</div>
	)
}

const FulfillmentTagCustomizationModal = ({modal_type, modal_json, handle_close, handle_ui_response}) => {
	const [drag_drop_fields, set_drag_drop_fields] = useState([]);
	const [static_fields, set_static_fields] = useState([]);

	useEffect(() => {
		if(modal_type === "fulfillment_tag_customization_modal" && modal_json){
			set_drag_drop_fields(modal_json.drag_drop_fields)
			set_static_fields(modal_json.static_fields)
		}
	}, [modal_type, modal_json]);

	const updateFields = async() => {
		if(modal_json && modal_json.route){
			let body = JSON.stringify({drag_drop_fields: drag_drop_fields, static_fields: static_fields})
			let resp = await window.Promisify(window.Module[modal_json.route](body))
			handle_ui_response(resp);
			onclick_handle_close()
		}else{
			// todo - Should send to sentry?
		}
	}

	const onclick_handle_close = () => {
		handle_close();
		set_drag_drop_fields([])
		set_static_fields([])
	}

	return(
		<Modal toggle={onclick_handle_close} style={{width: '320px', marginLeft: 'calc(270px + 8px)', marginTop: 'calc(136px + 16px)', marginBottom: 0, height: 'calc(100% - 136px - 32px)'}} contentClassName='inf-height-full' size={'md'} isOpen={modal_type === "fulfillment_tag_customization_modal"}>
			<IModalHeader toggle={onclick_handle_close}>Field order and visibilty</IModalHeader>
			<IModalBody style={{flex: 1, overflow: 'auto', minHeight:'40px'}}>
				<div className='inf-font-semibold inf-mb-4'>Drag and drop to rearrange the fields</div>
				<div className='inf-border' style={{borderRadius: '2px'}}>
					<DragDropComponent items_from_backened={drag_drop_fields} set_items_from_backened={set_drag_drop_fields} modal_name='cutlist_pref' Single_component={SingleFieldFulfillmentTag} />
				</div>

				<div className='inf-border inf-mt-4 inf-p-2' style={{borderRadius: '2px', background: 'white'}}>
					{
						static_fields && static_fields.length ? static_fields.map(field => (
							<div style={{padding: '0px 12px'}}>
								<SingleFieldFulfillmentTag key={field.id} item={field} input_array={static_fields} set_input_array={set_static_fields} is_draggable={false}/>
							</div>
						))
						:''
					}
				</div>
			</IModalBody>
			<IModalFooter>
				<Button className='secondary_button_default' onClick={onclick_handle_close}>Cancel</Button>
				<Button className='primary_button_default' onClick={updateFields}>Update</Button>
			</IModalFooter>
		</Modal>
	)
}
const FulfillmentTagTemplate = ({modal_type, modal_json, handle_close, handle_ui_response}) => {
	const [template_id, set_template_id] = useState("");
	const [template_url, set_template_url] = useState("");
	const [mode_id, set_mode_id] = useState("");
	const onClickNewTemplate = async() => {
		try {
			if(modal_json && modal_json.new_template_route){
				let resp = await window.Promisify(window.Module[modal_json.new_template_route](""));
				handle_ui_response(resp)
			}
		} catch (error) {
			// todo handle error
		}
	}

	const onClickEditTemplate = async() => {
		try {
			if(modal_json && modal_json.edit_template_route && template_id){
				let resp = await window.Promisify(window.Module[modal_json.edit_template_route](template_id));
				handle_ui_response(resp)
			}
		} catch (error) {
			// todo handle error
		}
	}

	useEffect(() => {
		if(modal_json && modal_type === "fulfillment_tag_template_modal"){
			if(modal_json && modal_json.template_options && modal_json.template_options.length){
				set_template_id(modal_json.template_options[0].id);
				set_template_url(modal_json.template_options[0].url)
			}

			if(modal_json && modal_json.mode && modal_json.mode.length){
				set_mode_id(modal_json.mode[0].id);
			}
		}
	}, [modal_json, modal_type]);

	return(
		<Modal centered size={'lg'} isOpen={modal_type === "fulfillment_tag_template_modal"}>
			<IModalHeader toggle={handle_close}>Panel Label Template</IModalHeader>
			<IModalBody style={{height: '500px', overflow: 'auto'}}>
				<div className='inf-flex' style={{height: '100%', gap: "2rem"}}>
					<div style={{flex:2}}>
						<div className='flex_between inf-mb-1'>
							<div>Template Name</div>
							<Button className='primary_button_ghost' disabled={modal_json && modal_json.has_catalogue_access} onClick={onClickNewTemplate}>New Template</Button>
						</div>
						<div className='flex_property inf-border inf-mb-4'>
							<Input style={{...styles_modals.input_styles_none_2}} type='select' value={template_id} onChange={(e) => {set_template_id(e.target.value); set_template_url(e.target.getAttribute("data-url"))}}>
								{
									modal_json && modal_json.template_options ? modal_json.template_options.map((template) => (
										<option key={template.id} value={template.id} data-url={template_url}>{template.name}</option>
									))
									:''
								}
							</Input>
							<div className='inf-px-4 secondary_button_ghost' onClick={onClickEditTemplate}>Edit</div>
						</div>

						{/* todo - modal_json.mode_change_allowed - for disabled state */}
						<div className='inf-mb-1'>Mode</div>
						<Input type='select' style={{...styles_modals.input_styles_3}} value={mode_id} onChange={(e) => set_mode_id(e.target.value)}>
							{
								modal_json && modal_json.mode_options ? modal_json.mode_options.map((mode) => (
									<option key={mode.id} value={mode.id}>{mode.name}</option>
								))
									: ''
							}
						</Input>
					</div>
					<div style={{flex: 3, minWidth: '0px', display: 'flex', flexDirection: 'column'}}>
						<div className='inf-mb-3'>Template Preview</div>
						<img src={template_url} style={{flex: 1, minHeight: '0px', background: '#C5C7CF', objectFit: 'contain', padding: '1rem'}}/>
					</div>
				</div>
			</IModalBody>
			<IModalFooter>
				<Button className='secondary_button_default'>Back</Button>
				<Button className='primary_button_default'>Download</Button>
			</IModalFooter>
		</Modal>
	)
}

const FulfillmentTagTemplateSettings = ({ modal_type, modal_json, handle_close, update_view, handle_ui_response, set_page_loader, metric_system, approximation_mm, approximation_ft, approxMM, mmToFeetParts }) => {
	const [ template_id, set_template_id ] = useState('');
	const [ template_url, set_template_url ] = useState('');
	const [ static_fields, set_static_fields ] = useState([]);
	const [ drag_drop_fields, set_drag_drop_fields ] = useState([]);
	const [ panel_has_unapplied_edits, set_panel_has_unapplied_edits ] = useState(false);
	const [ panel_key, set_panel_key ] = useState("initial_panel_key");
	const [ image_key, set_image_key ] = useState("initial_image_key");

	const onClickNewTemplate = async() => {
		try {
			if(modal_json && modal_json.style_create_new_route){
				let resp = await window.Promisify(window.Module[modal_json.style_create_new_route](""));
				handle_ui_response(resp)
			}
		} catch (error) {
			// todo handle error
		}
	}

	const onChangeTemplate = async(template_id) => {
		try {
			if(modal_json && modal_json.style_on_change_route){
				let resp = await window.Promisify(window.Module[modal_json.style_on_change_route](template_id));
				handle_ui_response(resp)
			}
		} catch (error) {
			// todo handle error
		}
	}

	const onClickChooseFromCatalogue = async() => {
		try {
			if(modal_json && modal_json.style_choose_from_carousel_route){
				let resp = await window.Promisify(window.Module[modal_json.style_choose_from_carousel_route](""));
				handle_ui_response(resp)
			}
		} catch (error) {
			// todo handle error
		}
	}

	const onClickPublish = async() => {
		try {
			if(modal_json && modal_json.publish_route && template_id){
				let resp = await window.Promisify(window.Module[modal_json.publish_route](template_id));
				handle_ui_response(resp)
			}
		} catch (error) {
			// todo handle error
		}
	}

	const onClickDownload = async() => {
		try {
			if(modal_json && modal_json.download_route && template_id){
				let resp = await window.Promisify(window.Module[modal_json.download_route](template_id));
				handle_ui_response(resp)
				onclick_handle_close();
			}
		} catch (error) {
			// todo handle error
		}
	}

	const onChangeFields = async() => {
		try {
			if(modal_json && template_id){
				let resp = await window.Promisify(window.Module["update_ft_style_properties_and_update_real_time"](JSON.stringify({active_style_id: template_id, drag_drop_fields, static_fields})));
				handle_ui_response(resp)
				generate_random_image_key()
			}
		} catch (error) {
			// todo handle error
		}
	}

	const generate_random_image_key = () => {
		set_image_key("image_key_" + Date.now() + Math.random())
	}

	const onclick_handle_close = () => {
		handle_close();
	}

	useEffect(() => {
		onChangeFields()
	}, [static_fields, drag_drop_fields]);

	useEffect(() => {
		if(modal_type === "fulfillment_tag_template_settings_modal" && modal_json){
			if(modal_json.active_style_id){
				set_template_id(modal_json.active_style_id)
			}else if(modal_json.style_options && modal_json.style_options.length){
				set_template_id(modal_json.style_options[0].id)
			}

			if(modal_json.preview_image_url){
				// let url = (modal_json.preview_image_url.includes('FS') ? get_image_url_from_FS(modal_json.preview_image_url) : modal_json.preview_image_url)
				set_template_url(modal_json.preview_image_url)
			}

			if(modal_json.static_fields){
				set_static_fields(modal_json.static_fields)
			}
			
			if(modal_json.drag_drop_fields){
				set_drag_drop_fields(modal_json.drag_drop_fields)
			}

			set_panel_key(Date.now() + Math.random())
			generate_random_image_key()
		}
	}, [modal_type, modal_json]);

	return(
		<Modal centered size={'xl'} isOpen={modal_type === "fulfillment_tag_template_settings_modal"}>
			<IModalHeader toggle={onclick_handle_close}>Panel label template settings</IModalHeader>
			<IModalBody style={{height: '600px', overflow: 'auto'}}>
				<div className='inf-flex' style={{height: '100%', gap: "2rem"}}>
					<div style={{flex:1, display: 'flex', flexDirection: 'column'}}>
						<div className='flex_between inf-mb-1'>
							<div>Template Name</div>
							<Button className='primary_button_ghost' disabled={modal_json && modal_json.has_catalogue_access} onClick={onClickNewTemplate}>New Template</Button>
						</div>
						<div className='flex_property inf-border inf-mb-4'>
							<Input style={{...styles_modals.input_styles_none_2, borderRight: '1px solid var(--light-border-color)'}} type='select' value={template_id} onChange={(e) => onChangeTemplate(e.target.value)}>
								{
									modal_json && modal_json.style_options ? modal_json.style_options.map((template) => (
										<option key={template.id} value={template.id}>{template.name}</option>
									))
									:''
								}
							</Input>
							<div className='inf-px-4 flex_property' style={{whiteSpace:'nowrap', background: 'white', height: '100%', cursor:'pointer'}} onClick={onClickChooseFromCatalogue}>Choose from catalogue</div>
						</div>
						<div style={{display: 'flex', flexDirection: 'row', gap: '12px', flex: 1, minHeight: '0px'}}>
						{
							<div key={panel_key} style={{flex: 1, overflow: 'auto', background: 'white'}}>
								{
									modal_json && modal_json.properties && modal_json.properties.length ? modal_json.properties.map((json_item,idx) =>
										<Create_card
											// panel_key={panel_key}
											set_page_loader={set_page_loader}
											handle_ui_response={handle_ui_response}
											key={idx}
											metric_system={metric_system}
											approximation_mm={approximation_mm}
											approximation_ft={approximation_ft}
											parent_menu={modal_json.properties}
											update_view={update_view}
											json_item={json_item}
											set_panel_has_unapplied_edits={set_panel_has_unapplied_edits}
											approxMM={approxMM}
											panel_key={panel_key}
											mmToFeetParts={mmToFeetParts}
											onSubmitCallback={generate_random_image_key}
										/>
									)
									:''
								}
							</div>
						}
						{
							<div style={{flex: 1, overflow: 'auto', position:'relative'}}>
								<div className='inf-font-semibold inf-px-4 inf-py-2' style={{background: COLORS.gray3, borderBottom: '1px solid ' + COLORS.gray4, position:'sticky', top:0, zIndex: 1}}>Re-arrange the fields</div>
								<div className='inf-border' style={{ borderRadius: '2px' }}>
									<DragDropComponent items_from_backened={drag_drop_fields} set_items_from_backened={set_drag_drop_fields} Single_component={SingleFieldFulfillmentTag} />
								</div>

								<div className='inf-border inf-mt-4 inf-p-2' style={{ borderRadius: '2px', background: 'white' }}>
									{
										static_fields && static_fields.length ? static_fields.map(field => (
											<div style={{ padding: '0px 12px' }}>
												<SingleFieldFulfillmentTag key={field.id} item={field} input_array={static_fields} set_input_array={set_static_fields} is_draggable={false} />
											</div>
										))
											: ''
									}
								</div>
							</div>
						}
						</div>
					</div>
					<div style={{flex: 1, minWidth: '0px', display: 'flex', flexDirection: 'column'}}>
						<div className='inf-mb-3'>Template Preview</div>
						<IImg key={image_key} src={template_url} style={{flex: 1, minHeight: '0px', background: '#C5C7CF', objectFit: 'contain', padding: '1rem'}}/>
					</div>
				</div>
			</IModalBody>
			<IModalFooter>
				<Button className='secondary_button_default' onClick={onClickPublish}>Publish as Template</Button>
				<Button className='primary_button_default' onClick={onClickDownload}>Download</Button>
			</IModalFooter>
		</Modal>
	)
}

const PaymentScheduleRow = ({installment, index, installments_list, set_installments_list}) => {
	const [name, set_name] = useState(null);
	const [percentage, set_percentage] = useState(null);

	const deleteRow = () => {
		try{
			let temp_installments_list = JSON.parse(JSON.stringify(installments_list))
			temp_installments_list.splice(index, 1)
			set_installments_list(temp_installments_list)
		}catch(err){
			console.error('Could not Delete the row ', err)
		}
	}

	useEffect(() => {
		if(installment){
			if(installment.name != name){
				set_name(installment.name ? installment.name : '')
			}
			if(installment.percentage != percentage){
				set_percentage(installment.percentage ? installment.percentage : 0)
			}
		}else{
			set_name('')
			set_percentage(0)
		}
	}, [installment]);

	useEffect(() => {
		if(name != null){
			installment.name = name
		}
		if(percentage != null){
			installment.percentage = percentage
		}
		console.log('percentage installment', percentage)
	}, [name, percentage]);

	return(
		<div className='flex_property mb-2'>
			<div style={{flex: 1}} className='pr-3'>
				<Input style={{fontSize: '12px'}} value={name ? name : ''} onChange={(e) => {set_name(e.target.value)}}/>
			</div>
			<div style={{flex: 1}} className='px-3'>
				<Input style={{fontSize: '12px'}} value={percentage ? String(percentage) : '0'} onChange={(e) => {set_percentage(Number(e.target.value))}} type='number'/>
			</div>
			<div className='pl-4'>
				<img className='cp' src='/resources/nav_icons_new/delete 16px.svg' onClick={deleteRow}></img>
			</div>
		</div>
	)
}

const PaymentSchedules = ({modal_type, modal_json, handle_close, handle_ui_response, update_view, set_page_loader}) => {
	const [modal_json_copy, set_modal_json_copy] = useState('');
    const {t} = useTranslation()

	const onclick_handle_close = () => {
		handle_close()
		set_modal_json_copy('')
	}

	const addRow = () => {
		let temp_installment = {}
		temp_installment.name = `Installment${modal_json_copy && modal_json_copy.length ? modal_json_copy.length + 1 : 1}`
		temp_installment.percentage = 0
		let temp_installments_list = modal_json_copy
		temp_installments_list.push(temp_installment)
		set_modal_json_copy(JSON.parse(JSON.stringify(temp_installments_list)))
		// setTimeout(scrollBottom(), 100)
		requestAnimationFrame(scrollBottom)
	}

	const scrollBottom = () => {
		let payment_schedule_div = document.getElementById('payment_schedule_data')
		if(payment_schedule_div){
			payment_schedule_div.scrollTop = payment_schedule_div.scrollHeight
		}
	}

	const update_payment_schedules = () => {
		try{
			window.Module.apply_quotation_payment_schedule(JSON.stringify(modal_json_copy));
			onclick_handle_close()
		}catch(err){
			console.error('Failed to update payment schedules', err)
		}
	}

	useEffect(() => {
		if(modal_type == 'payment_schedule'){
			set_modal_json_copy(modal_json)
		}
	}, [modal_json, modal_type]);

	return(
		<Modal className='modal-dialog-centered' size='lg' isOpen={modal_type == 'payment_schedule'}>
			<IModalHeader toggle={onclick_handle_close}>Payment Schedule</IModalHeader>
			<IModalBody style={{height: '500px'}}>
				<div className='flex_property mb-3'>
					<div style={{ flex: 1, fontWeight: 500 }} className='pr-3'>
						Installment Name
					</div>
					<div style={{ flex: 1, fontWeight: 500 }} className='px-3'>
						Installment Percentage
					</div>
					<div className='pl-4' style={{width: '40px'}}></div>
				</div>
				<div style={{maxHeight: 'calc( 100% - 96px )', overflow: 'auto'}} id='payment_schedule_data'>
				{
					modal_json_copy && modal_json_copy.length ? modal_json_copy.map((installment, idx) => (
						<PaymentScheduleRow installment={installment} index={idx} installments_list={modal_json_copy} set_installments_list={set_modal_json_copy}/>
					))
					:
					<div>
						Payment Schedule is not configured. Get started by clicking on Add Row
					</div>
				}
				</div>
				<div className='flex_property'>
					<div className='simple_white_button mt-3' onClick={addRow}>{t(`Add Row`)}</div>
				</div>
			</IModalBody>
			<IModalFooter>
				{/* <Button className='primary_button_default'>Update</Button> */}
				<Button className='primary_button_default' onClick={update_payment_schedules}>Update</Button>
			</IModalFooter>
		</Modal>
	)
}


const Board_Settings = ({modal_type, modal_json, handle_close, handle_ui_response, update_view, set_page_loader}) => {

	const [boards, set_boards] = useState([]);

	const submit = () => {
		let final_json = {boards}
		console.log("Koushik",final_json);

		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {  
			var resp = await window.Promisify(window.Module.board_layout_settings_apply(JSON.stringify(final_json)));
			update_view()
			onclick_handle_close();
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	const update_board = (board_index, property, new_val) => {
		var boards_clone = JSON.parse(JSON.stringify(boards));

		boards_clone[board_index][property] = new_val;

		set_boards(boards_clone);
	}

	useEffect(() => {

		if(modal_type === 'board_layout_settings' && modal_json && modal_json.boards){
			//initailize the states
			set_boards(modal_json.boards)
		}
		
	}, [modal_json, modal_type]);

	const onclick_handle_close = () => {
		set_boards([])
		handle_close()
	}

	return(
		<Modal className='modal-dialog-centered' isOpen={modal_type === 'board_layout_settings'} toggle={onclick_handle_close} size='xl'>
			<IModalHeader style={{height:'60px'}} toggle={onclick_handle_close}>Board Layout Settings</IModalHeader>
			<IModalBody style={{fontSize:'14px'}}>
				<ITable coloumnSeparated={false} hover={false} style_container={{ border: '1px solid var(--light-border-color)', maxHeight: '568px', overflow: 'auto', whiteSpace: 'nowrap', fontSize:'14px' }}>
					<ITableHeader style={{ backgroundColor: 'white', position: 'sticky', top: '0px', zIndex: 1 }}>
						{
							boards && boards && boards.length > 0 ?
								['Board', 'Board Length', 'Board Width', 'Board Vertical Margin', 'Board Horizontal Margin', 'Saw Width'].map((col, idx) =>
									<ITH key={idx} style={{ textTransform: "capitalize" }}>{col}</ITH>
								)
								: <ITH>No Items</ITH>
						}
					</ITableHeader>
					<ITableBody>
						{
							boards && boards.length > 0 && boards.map((board, idx) => (
								<ITableRow key={idx}>
									<ITD className=" text-sm">{board.name}</ITD>
									<ITD>
										<Input style={{fontSize:'12px'}} className="standard_input ml-1 mr-2" value={board.board_length} onChange={(e) => update_board(idx,"board_length",Number(e.target.value))} />
									</ITD>
									<ITD>
										<Input style={{fontSize:'12px'}} className="standard_input ml-1" value={board.board_width} onChange={(e) => update_board(idx,"board_width",Number(e.target.value))} />
									</ITD>
									<ITD>
										<Input style={{fontSize:'12px'}} className="standard_input ml-1 mr-2" value={board.board_layout_vertical_margin} onChange={(e) => update_board(idx,"board_layout_vertical_margin",Number(e.target.value))} />
									</ITD>
									<ITD>
										<Input style={{fontSize:'12px'}} className="standard_input ml-1" value={board.board_layout_horizontal_margin} onChange={(e) => update_board(idx,"board_layout_horizontal_margin",Number(e.target.value))} />
									</ITD>
									<ITD>
										<Input style={{fontSize:'12px'}} className="standard_input" value={board.board_layout_saw_width} onChange={(e) => update_board(idx,"board_layout_saw_width",Number(e.target.value))} />
									</ITD>
									{/* <ITD className="">{board.sheets && board.sheets.length}</ITD> */}
								</ITableRow>
							))
						}
					</ITableBody>
				</ITable>
			</IModalBody>
			<IModalFooter style={{justifyContent:'end'}} className="pt-0"> 
				<Button onClick={onclick_handle_close} className='secondary_button_default' variant="text">Close</Button>
				<Button onClick={submit} color="blue" className='ml-2 primary_button_default'>Apply</Button>
			</IModalFooter>
		</Modal>
	)

}

const ServiceChargeSingleRow = ({services_list, charge_types_list, units_list, index, service, set_service_charges_list, service_charges_list, calculate_total, set_table_key, total_product_cost, quantity_list, quantity_cost}) => {
	const [selected_charge_type, set_selected_charge_type] = useState('');
	const [selected_service, set_selected_service] = useState('');
	const [selected_unit, set_selected_unit] = useState('');
	const [quantity, set_quantity] = useState(0);
	const [rate_per_unit, set_rate_per_unit] = useState(0);
	const [final_charge, set_final_charge] = useState(0);
	const [tax, set_tax] = useState(0);
	const [custom_service_name, set_custom_service_name] = useState('');
	const [dropdownOpen, set_dropdownOpen] = useState(false);
	const [quantity_option, set_quantity_option] = useState('custom');

	const toggle = () => {set_dropdownOpen(!dropdownOpen)}

	const service_name_by_id = (service_id) => {
		if(services_list && services_list.length){
			let s = services_list.find(x => x.id == service_id)
			// console.log(services_list, s, service_id)
			// if(s.length){
			return s && s.name ? s.name : '';
			// }
		}
		return '';
	}

	const delete_row = () => {
		let t = JSON.parse(JSON.stringify(service_charges_list))
		t.splice(index, 1)
		set_service_charges_list(t)
		calculate_total()
		set_table_key(Date.now() + Math.random())
	}

	const duplicate_row = () => {
		let t = JSON.parse(JSON.stringify(service_charges_list))
		let duplicate_charge = JSON.parse(JSON.stringify(service))
		t.splice(index, 0, duplicate_charge)
		set_service_charges_list(t)
		calculate_total()
		set_table_key(Date.now() + Math.random())
	}

	const convert_to_measurement_unit = (value_in_mm, unit) => {
		if(unit == 'feet2') return value_in_mm/(304.8*304.8)

		if(unit == 'metre2') return value_in_mm/1000000

		return value_in_mm
	}

	// useEffect(() => {
		
	// }, [selected_charge_type]);


	useEffect(() => {
		if(service.service){
			set_selected_service(service.service)
		}else if(services_list && services_list.length){
			set_selected_service(services_list[0].id)
		}

		if(service.charge_type){
			// if(service.unit){
			// 	set_selected_unit(service.unit)
			// }
			set_selected_charge_type(service.charge_type)
		}else if(charge_types_list && charge_types_list.length){
			set_selected_charge_type(charge_types_list[0].id)
		}

		// if(service.unit){
		// 	set_selected_unit(service.unit)
		// }else if(units_list && units_list.length){
		// 	set_selected_unit(units_list[0].id)
		// }

		if(service.rate_per_unit){
			set_rate_per_unit(service.rate_per_unit)
		}

		if(service.quantity){
			set_quantity(service.quantity)
		}

		if(service.final_charge){
			set_final_charge(service.final_charge)
		}

		if(service.custom_service_name){
			set_custom_service_name(service.custom_service_name)
		}
		if(service.tax){
			set_tax(service.tax)
		}
		if(service.quantity_type){
			set_quantity_option(service.quantity_type)
		}else if(quantity_list && quantity_list.length && service.charge_type == 'percentage'){
			set_quantity_option(quantity_list[0].id)
		}else{
			set_quantity_option('custom')
		}

		// console.log("inside constructor")

	}, []);

	useEffect(() => {
		if(selected_charge_type){
			if(service.charge_type == 'absolute' && selected_charge_type != 'absolute'){
				set_final_charge(rate_per_unit * quantity)
			}
			if(service.charge_type == 'percentage' && selected_charge_type != 'percentage'){
				set_quantity(0)
			}
			if(selected_charge_type == 'percentage'){
				if(quantity_option && quantity_list && quantity_list.length && quantity_list.map(x => x.id).includes(quantity_option)){
					// console.log('reached here 3', quantity_cost && quantity_option && quantity_cost[quantity_option])
					set_quantity(quantity_cost && quantity_option && quantity_cost[quantity_option])
				}else{
					// console.log('reached here 1', quantity_list, quantity_list[0].id, quantity_option)
					set_quantity_option(quantity_list && quantity_list.length && quantity_list[0].id)
				}
				// set_quantity(total_product_cost)
			}
			if(selected_charge_type == 'area_basis' && !['custom', 'total_countertop_area'].includes(quantity_option)){
				set_quantity_option('custom')
			}
			// service.service = selected_service;
			service.charge_type = selected_charge_type;
			let filtered_unit_list = units_list.filter(x => x.charge_type_id === selected_charge_type);

			let unit = filtered_unit_list.find(x => x.id == service.unit)
			if(unit){
				set_selected_unit(service.unit)
			}else{
				set_selected_unit(filtered_unit_list.length ? filtered_unit_list[0].id : '')
			}

			// console.log(service, "service1")
			set_service_charges_list(service_charges_list)
		}		
	}, [selected_charge_type]);

	useEffect(() => {
		service.quantity_type = quantity_option

		if((selected_charge_type == 'percentage') && quantity_option){
			// service.quantity_type = quantity_option
			// console.log('reached here 2 ', quantity_option, quantity_cost[quantity_option])
			set_quantity(quantity_cost && quantity_option && (quantity_cost[quantity_option] || 0))
		}

		// console.log('reached here 2 ', quantity_option, quantity_cost[quantity_option])
		if(selected_charge_type == 'area_basis' && quantity_option && quantity_option != 'custom'){
			// service.quantity_type = quantity_option
			set_quantity(quantity_cost && quantity_option && (quantity_cost[quantity_option] && convert_to_measurement_unit(quantity_cost[quantity_option], selected_unit) || 0))
		}
	}, [quantity_option]);

	useEffect(() => {
		if(selected_service){
			service.service = selected_service;
			// console.log(service, "service2")
			// set_service_charges_list(service_charges_list)
		}
		if(selected_unit){
			service.unit = selected_unit;

			if(selected_charge_type == 'area_basis' && quantity_option !== 'custom'){
				// console.log('reached here 5')
				set_quantity(quantity_cost && quantity_option && (quantity_cost[quantity_option] && convert_to_measurement_unit(quantity_cost[quantity_option], selected_unit) || 0))
			}
			// console.log(service, "service2.1")
		}

		if(selected_service || selected_unit){
			set_service_charges_list(service_charges_list)
		}
		
	}, [selected_service, selected_unit]);


	useEffect(() => {
		// if(selected_service && selected_charge_type && selected_unit){
			// service.service = selected_service;
			// service.charge_type = selected_charge_type;
			// service.unit = selected_unit;
			service.rate_per_unit = rate_per_unit;
			service.quantity = quantity;
			if(service.charge_type != 'absolute' && service.charge_type != 'percentage'){
				set_final_charge(rate_per_unit * quantity)
			}

			// console.log('reached here 4 ', quantity, rate_per_unit)
			if(service.charge_type == 'percentage'){
				set_final_charge((rate_per_unit * quantity)/100)
			}
			// service.service_charge = rate_per_unit * quantity;
			// console.log(service, "service3")
			set_service_charges_list(service_charges_list)
		// }
		
	}, [rate_per_unit, quantity]);

	useEffect(() => {
		// if(selected_service && selected_charge_type && selected_unit){
			// service.service = selected_service;
			// service.charge_type = selected_charge_type;
			// service.unit = selected_unit;
			service.tax = tax;
		// }
		
	}, [tax]);


	useEffect(() => {
		// if(selected_service && selected_charge_type && selected_unit){
			// service.service = selected_service;
			// service.charge_type = selected_charge_type;
			// service.unit = selected_unit;
			// service.rate_per_unit = rate_per_unit;
			// service.quantity = quantity;
			service.final_charge = final_charge;
			// console.log(service, "service4")
			set_service_charges_list(service_charges_list)
			calculate_total()
		// }
		
	}, [final_charge]);

	useEffect(() => {
		// if(selected_service && selected_charge_type && selected_unit){
			// service.service = selected_service;
			// service.charge_type = selected_charge_type;
			// service.unit = selected_unit;
			// service.rate_per_unit = rate_per_unit;
			// service.quantity = quantity;
			service.custom_service_name = custom_service_name;
			// console.log(service, "service5")
			set_service_charges_list(service_charges_list)
		// }
		
	}, [custom_service_name]);
	

	return(
		// <tr className='tr_style'>
		// 	<td className='td_style flex_property' style={{flex:'1'}}>{index + 1}</td>
		// 	<td className='td_style flex_property' style={{flex:'5', justifyContent:'space-between', overflow:'visible', cursor:'pointer'}} onClick={toggle}>
		// 		<div className='flex_property' style={{width:'100%'}}>
		// 			<div>
		// 				{service_name_by_id(selected_service)}
		// 			</div>
		// 			{selected_service === 'custom' ? <Input type='text' value={custom_service_name} style={{...styles_modals.input_styles, margin:'0px 8px', padding:'0px 8px'}} onClick={(e) => e.stopPropagation()} onChange={(e) => {set_custom_service_name(e.target.value)}}/> : ''}
		// 		</div>
		// 		<Dropdown isOpen={dropdownOpen} toggle={(e) => {toggle(); e.stopPropagation()}} style={{position:'unset'}}>
		// 			<DropdownToggle style={{all:'unset', padding:'4px', cursor:'pointer'}}>
		// 				<i className='fa fa-caret-down'></i>
		// 			</DropdownToggle>
		// 			<DropdownMenu right style={{padding:'4px'}}>
		// 				{
		// 					services_list.map((service_item, idx) => (
		// 						<DropdownItem key={idx} onClick={() => {set_selected_service(service_item.id)}}>{service_item.name}</DropdownItem>
		// 					))
		// 				}
		// 			</DropdownMenu>
		// 		</Dropdown>
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'3'}}>
		// 		<Input type='select' value={selected_charge_type} style={{...styles_modals.input_styles_none}} onChange={(e) => {set_selected_charge_type(e.target.value)}}>
		// 			{
		// 				charge_types_list && charge_types_list.length ? charge_types_list.map((charge_type, idx_ct) => (
		// 					<option key={idx_ct} value={charge_type.id}>{charge_type.name}</option>
		// 				)) : ''
		// 			}
		// 		</Input>
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'3'}}>
		// 		{
		// 			selected_charge_type !== 'absolute' && selected_charge_type !== 'percentage' ?
		// 				<Input type='select' value={selected_unit} style={{...styles_modals.input_styles_none}} onChange={(e) => {set_selected_unit(e.target.value)}}>
		// 					{
		// 						units_list && units_list.length ? units_list.filter(x => (x.charge_type_id == selected_charge_type)).map((unit, idx_u) => (
		// 							<option key={idx_u} value={unit.id}>{unit.name}</option>
		// 						)) : ''
		// 					}
		// 				</Input>
						
		// 			:''
		// 		}
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'2', position:'relative'}}>
		// 		{selected_charge_type !== 'absolute' ? 
		// 			<React.Fragment>
		// 				<Input type='number' disabled={selected_charge_type === 'absolute'} min={0} value={rate_per_unit} style={{...styles_modals.input_styles_none, paddingRight:'24px'}} onChange={(e) => {set_rate_per_unit(Number(e.target.value ? e.target.value : 0))}}/>
		// 				{selected_charge_type === 'percentage'?<span className="percentage-symbol">%</span> :''}
		// 			</React.Fragment>
		// 			: ''}
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'3'}}>
		// 		{
		// 			selected_charge_type !== 'absolute' && selected_charge_type !== 'percentage' ?
		// 				<React.Fragment>
		// 					<Input type='select' disabled={selected_charge_type === 'absolute'} value={quantity_option} style={{ ...styles_modals.input_styles_none, fontSize: '10px' }} onChange={(e) => { set_quantity_option(e.target.value) }}>
		// 						{/* {
		// 							quantity_list && quantity_list.length ? quantity_list.map((quantity, idx_q) => (
		// 								<option key={idx_q} value={quantity.id}>{quantity.name}</option>
		// 							)) : ''
		// 						} */}
		// 						<option value={'custom'}>{'Custom'}</option>
		// 						<option value={'countertop_area'}>{'Countertop Area'}</option>
		// 					</Input>
		// 					<Input type='number' disabled={selected_charge_type === 'absolute' || quantity_option === 'countertop_area'} min={0} value={quantity} style={{ ...styles_modals.input_styles_none }} onChange={(e) => { set_quantity(Number(e.target.value ? e.target.value : 0)) }} />
		// 				</React.Fragment>
		// 				: selected_charge_type == 'percentage' ?
		// 					<Input type='select' value={quantity_option} style={{ ...styles_modals.input_styles_none, fontSize: '10px' }} onChange={(e) => { set_quantity_option(e.target.value) }}>
		// 						{
		// 							quantity_list && quantity_list.length ? quantity_list.map((quantity, idx_q) => (
		// 								<option key={idx_q} value={quantity.id}>{quantity.name}</option>
		// 							)) : ''
		// 						}
		// 					</Input>
		// 					: ''
		// 		}
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'3'}}>
		// 		{selected_charge_type !== 'absolute' ? <Input type='number' min={0} value={final_charge} style={{...styles_modals.input_styles_none}} readOnly/> : <Input type='number' min={0} value={final_charge} onChange={(e) => {set_final_charge(Number(e.target.value ? e.target.value : ''))}} style={{...styles_modals.input_styles_none}}/> }
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'3'}}>
		// 		{<Input type='number' min={0} value={tax} onChange={(e) => {set_tax(Number(e.target.value ? e.target.value : ''))}} style={{...styles_modals.input_styles_none}}/> }
		// 	</td>
		// 	<td className='td_style flex_property' style={{flex:'1', justifyContent:'center', fontWeight:700, fontSize:16, overflow:'visible'}}>
		// 		<UncontrolledDropdown style={{position:'unset'}}>
		// 			<DropdownToggle style={{all:'unset'}} className='cp'>
		// 				...
		// 			</DropdownToggle>
		// 			<DropdownMenu style={{padding:'4px'}}>
		// 				<DropdownItem onClick={duplicate_row}>Clone Row</DropdownItem>
		// 				<DropdownItem onClick={delete_row}>Delete Row</DropdownItem>
		// 			</DropdownMenu>
		// 		</UncontrolledDropdown>
		// 	</td>
		// </tr>
		<ITableRow>
			<ITD>{index + 1}</ITD>
			<ITD style={{justifyContent:'space-between', overflow:'visible', cursor:'pointer'}} onClick={toggle}>
				<div className='flex_property'>
					<div className='flex_property' style={{flex: 1, minWidth: '0px'}}>
						<div onClick={toggle} style={{flex: 1}}>
							{service_name_by_id(selected_service)}
						</div>
						{selected_service === 'custom' ? <Input type='text' value={custom_service_name} style={{...styles_modals.input_styles, margin:'0px 8px', padding:'0px 8px'}} onClick={(e) => e.stopPropagation()} onChange={(e) => {set_custom_service_name(e.target.value)}}/> : ''}
					</div>
					<Dropdown isOpen={dropdownOpen} toggle={(e) => {toggle(); e.stopPropagation()}} style={{position:'unset'}}>
						<DropdownToggle style={{all:'unset', padding:'4px', cursor:'pointer'}}>
							<i className='fa fa-caret-down'></i>
						</DropdownToggle>
						<DropdownMenu right style={{padding:'4px'}}>
							{
								services_list.map((service_item, idx) => (
									<DropdownItem key={idx} onClick={() => {set_selected_service(service_item.id)}}>{service_item.name}</DropdownItem>
								))
							}
						</DropdownMenu>
					</Dropdown>
				</div>
			</ITD>
			<ITD>
				<Input type='select' value={selected_charge_type} style={{...styles_modals.input_styles_none_2}} onChange={(e) => {set_selected_charge_type(e.target.value)}}>
					{
						charge_types_list && charge_types_list.length ? charge_types_list.map((charge_type, idx_ct) => (
							<option key={idx_ct} value={charge_type.id}>{charge_type.name}</option>
						)) : ''
					}
				</Input>
			</ITD>
			<ITD>
				{
					selected_charge_type !== 'absolute' && selected_charge_type !== 'percentage' ?
						<Input type='select' value={selected_unit} style={{...styles_modals.input_styles_none_2}} onChange={(e) => {set_selected_unit(e.target.value)}}>
							{
								units_list && units_list.length ? units_list.filter(x => (x.charge_type_id == selected_charge_type)).map((unit, idx_u) => (
									<option key={idx_u} value={unit.id}>{unit.name}</option>
								)) : ''
							}
						</Input>
						
					:''
				}
			</ITD>
			<ITD style={{position:'relative'}}>
				{selected_charge_type !== 'absolute' ? 
					<React.Fragment>
						<Input type='number' disabled={selected_charge_type === 'absolute'} min={0} value={String(rate_per_unit)} style={{...styles_modals.input_styles_none_2, paddingRight:'24px'}} onChange={(e) => {set_rate_per_unit(Number(e.target.value ? e.target.value : 0))}}/>
						{selected_charge_type === 'percentage'?<span className="percentage-symbol">%</span> :''}
					</React.Fragment>
					: ''}
			</ITD>
			<ITD>
				{
					selected_charge_type === 'area_basis'?
						<div className='flex_property'>
							<Input type='select' disabled={selected_charge_type === 'absolute'} value={String(quantity_option)} style={{ ...styles_modals.input_styles_none_2, fontSize: '10px' }} onChange={(e) => { set_quantity_option(e.target.value) }}>
								{/* {
									quantity_list && quantity_list.length ? quantity_list.map((quantity, idx_q) => (
										<option key={idx_q} value={quantity.id}>{quantity.name}</option>
									)) : ''
								} */}
								<option value={'custom'}>{'Custom'}</option>
								<option value={'total_countertop_area'}>{'Countertop Area'}</option>
							</Input>
							{quantity_option === 'custom' ? <Input type='number' disabled={selected_charge_type === 'absolute' || quantity_option === 'countertop_area'} min={0} value={String(quantity)} style={{ ...styles_modals.input_styles_none_2 }} onChange={(e) => { set_quantity(Number(e.target.value ? e.target.value : 0)) }} /> : ''}
						</div>
						: selected_charge_type == 'percentage' ?
							<Input type='select' value={quantity_option} style={{ ...styles_modals.input_styles_none_2, fontSize: '10px' }} onChange={(e) => { set_quantity_option(e.target.value) }}>
								{
									quantity_list && quantity_list.length ? quantity_list.map((quantity, idx_q) => (
										<option key={idx_q} value={quantity.id}>{quantity.name}</option>
									)) : ''
								}
							</Input>
							: selected_charge_type !== 'absolute' ? 
							<Input type='number' disabled={selected_charge_type === 'absolute' || quantity_option === 'countertop_area'} min={0} value={String(quantity)} style={{ ...styles_modals.input_styles_none_2 }} onChange={(e) => { set_quantity(Number(e.target.value ? e.target.value : 0)) }} />
							: ''
				}
			</ITD>
			<ITD>
				{selected_charge_type !== 'absolute' ? <Input type='number' min={0} value={final_charge} style={{...styles_modals.input_styles_none_2}} readOnly/> : <Input type='number' min={0} value={String(final_charge)} onChange={(e) => {set_final_charge(Number(e.target.value ? e.target.value : ''))}} style={{...styles_modals.input_styles_none_2}}/> }
			</ITD>
			<ITD>
				{<Input type='number' min={0} value={String(tax)} onChange={(e) => {set_tax(Number(e.target.value ? e.target.value : ''))}} style={{...styles_modals.input_styles_none_2}}/> }
			</ITD>
			<ITD style={{ fontWeight:700, fontSize:16, textAlign: 'center'}}>
				<UncontrolledDropdown style={{position:'unset'}}>
					<DropdownToggle style={{background:'unset', boxShadow: 'unset', border: 'unset', position: 'unset', cursor: 'pointer', padding: 'unset', color: 'black'}}>
						...
					</DropdownToggle>
					<DropdownMenu style={{padding:'4px'}}>
						<DropdownItem onClick={duplicate_row}>Clone Row</DropdownItem>
						<DropdownItem onClick={delete_row}>Delete Row</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			</ITD>
		</ITableRow>
	)
}

const Service_Charge = ({modal_type, modal_json, handle_close, handle_ui_response, update_view, set_page_loader}) => {

	// const [service_charge_json, set_service_charge_json] = useState([]);
	const [services_list, set_services_list] = useState([]);
	const [charge_types_list, set_charge_types_list] = useState([]);
	const [units_list, set_units_list] = useState([]);
	const [service_charges_list, set_service_charges_list] = useState([]);
	const [total, set_total] = useState(0);
	const [table_key, set_table_key] = useState('init_table_key');
	const [total_product_cost, set_total_product_cost] = useState(0);
	const [quantity_list, set_quantity_list] = useState([
		{
			id: 'total_product_cost',
			name: 'Total Product Cost',
		},
		{
			id: 'woodwork_price',
			name: 'Woodwork Price'
		},
		{
			id: 'discounted_woodwork_price',
			name: 'Discounted Woodwork Price'
		},
	]);
	const [quantity_cost, set_quantity_cost] = useState({
		'total_product_cost': 200,
		'woodwork_price': 300,
		'discounted_woodwork_price': 100
	});



	const onclick_handle_close = () => {
		set_service_charges_list([])
		set_charge_types_list([])
		set_units_list([])
		set_services_list([])
		set_total(0)
		set_table_key('init_table_key')
		handle_close()
	}

	const calculate_total = () => {
		let total_temp = 0;
		service_charges_list && service_charges_list.length ? service_charges_list.map(x => {total_temp = total_temp + x.final_charge}) : ''
		console.log(total_temp, "total")
		set_total(total_temp)
	}

	const add_row = () => { 
		let t = [...service_charges_list]
		t.push({})
		set_service_charges_list(t)
	}

	const update_services_list = () => {
		console.log("Updated services", service_charges_list);
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {  
			window.Module.apply_quotation_service_charges(JSON.stringify(service_charges_list));
			onclick_handle_close();
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	useEffect(() => {
		// Assumption modal type is service_charge and existing service charges is modal_json.service_charges
		if(modal_type === 'service_charges' && modal_json){
			modal_json.services && modal_json.services.length ? set_services_list(modal_json.services) : ''
			modal_json.units && modal_json.units.length ? set_units_list(modal_json.units) : ''
			modal_json.charge_types && modal_json.charge_types.length ? set_charge_types_list(modal_json.charge_types) : ''
			modal_json.quantity && modal_json.quantity.length ? set_quantity_list(modal_json.quantity) : ''
			modal_json.quantity_cost ? set_quantity_cost(modal_json.quantity_cost) : ''
			modal_json.data && modal_json.data.length ? set_service_charges_list(JSON.parse(JSON.stringify(modal_json.data))) : ''
			modal_json.total_product_cost ? set_total_product_cost(modal_json.total_product_cost) : ''
			calculate_total()
		}
	}, [modal_json, modal_type]);

	// useEffect(() => {
	// 	let total_temp = 0;
	// 	service_charges_list && service_charges_list.length ? service_charges_list.map(x => {total_temp = total_temp + x.service_charge}) : ''
	// 	console.log(total_temp, "total")
	// 	set_total(total_temp)
	// }, [service_charges_list]);

	return(
		<Modal className="modal-dialog-centered" style={{maxWidth: '90vw'}} isOpen={modal_type === 'service_charges'}>
			<IModalHeader  className='infurnia_modal_header'toggle={onclick_handle_close}>
				Service Charge
			</IModalHeader>
			<IModalBody style={{height: '600px', overflow: 'auto', position:'relative'}}>
				{/* <table  style={{maxHeight:'calc(600px - 52px)', overflow:'auto', marginBottom:'12px', position:'unset'}} className="text-left flex_property table_style">
					<thead style={{width:"100%"}}>
						<tr className='tr_style'>
							<th className='th_style flex_property' style={{flex:'1'}}>S.No</th>
							<th className='th_style flex_property' style={{flex:'5'}}>Service</th>
							<th className='th_style flex_property' style={{flex:'3'}}>Charge Type</th>
							<th className='th_style flex_property' style={{flex:'3'}}>Measurement Unit</th>
							<th className='th_style flex_property' style={{flex:'3'}}>Rate</th>
							<th className='th_style flex_property' style={{flex:'2'}}>Quantity</th>
							<th className='th_style flex_property' style={{flex:'3'}}>Service Charges</th>
							<th className='th_style flex_property' style={{flex:'3'}}>Tax%</th>
							<th className='th_style flex_property' style={{flex:'1'}}>Options</th>
						</tr>
					</thead>
					<tbody style={{overflow:"auto",width:"100%", height:"calc(100% - 40px)", position:'unset'}} key={table_key}>
						{
							service_charges_list && service_charges_list.length ? service_charges_list.map((single_charge, idx) => (
								<ServiceChargeSingleRow key={idx} services_list={services_list} charge_types_list={charge_types_list} units_list={units_list} index={idx} service={single_charge} set_service_charges_list={set_service_charges_list} service_charges_list={service_charges_list} calculate_total={calculate_total} set_table_key={set_table_key} total_product_cost={total_product_cost} quantity_list={quantity_list} quantity_cost={quantity_cost}/>
							))
							:''
						}
					</tbody>
				</table> */}
				<ITable rowSeparated={true} striped={true} style_container={{maxHeight:'calc(600px - 100px)', overflow:'auto', marginBottom:'12px', background: 'white'}} className="">
					<colgroup>
						<col style={{ width: "5%" }}></col>
						<col style={{ width: "20%" }}></col>
						<col style={{ width: "10%" }}></col>
						<col style={{ width: "15%" }}></col>
						<col style={{ width: "10%" }}></col>
						<col style={{ width: "15%" }}></col>
						<col style={{ width: "10%" }}></col>
						<col style={{ width: "10%" }}></col>
						<col style={{ width: "5%" }}></col>
					</colgroup>
					<ITableHeader style={{background: 'white', position: 'sticky', top: '0px'}}>
						<ITH>S.No</ITH>
						<ITH>Service</ITH>
						<ITH>Charge Type</ITH>
						<ITH>Measurement Unit</ITH>
						<ITH>Rate</ITH>
						<ITH>Quantity</ITH>
						<ITH>Service Charges</ITH>
						<ITH>Tax%</ITH>
						<ITH>Options</ITH>
					</ITableHeader>
					<ITableBody key={table_key}>
						{
							service_charges_list && service_charges_list.length ? service_charges_list.map((single_charge, idx) => (
								<ServiceChargeSingleRow key={idx} services_list={services_list} charge_types_list={charge_types_list} units_list={units_list} index={idx} service={single_charge} set_service_charges_list={set_service_charges_list} service_charges_list={service_charges_list} calculate_total={calculate_total} set_table_key={set_table_key} total_product_cost={total_product_cost} quantity_list={quantity_list} quantity_cost={quantity_cost}/>
							))
							:''
						}
					</ITableBody>
				</ITable>
				<div style={{height: '40px'}} className='flex_property js-sb'>
					<div  onClick={add_row} className='simple_white_button'>
						Add Row
					</div>
					{/* <div className='flex_property' style={{fontSize: '12px', border: '1px solid #c5c7cf', width:'272px'}}>
						<div style={{flex:2, textAlign:'center', fontWeight:700, borderRight: '1px solid #c5c7cf', padding:'8px'}}>Total: </div>
						<div style={{flex: 4, fontWeight:700, padding:'8px'}}>{total}</div>
					</div> */}
				</div>
			</IModalBody>

			<IModalFooter style={{justifyContent:'end', border:'0px'}}> 
				<Button className='primary_button_default' onClick={update_services_list}>Update</Button>
			</IModalFooter>
		</Modal>
	)
}

const BulkEditSkuStructure = ({modal_type, modal_json, handle_close, handle_ui_response}) => {
	// const checkedCabinetIdsRef = useRef(checked_cabinet_ids);

	const onclick_handleclose = (resp) => {
		if(resp){
			handle_ui_response(resp)
		}
		handle_close()
	}

	const handleMessage = (event) => {
		// Ensure the message is from a trusted source
		// if (event.origin !== 'http://trusted-origin.com') return;
		
		try{

			const data = event.data;
			console.log('checked_cabinet_ids Received data from iframe:', data, modal_json && modal_json.sku_ids);
			if(data && typeof data === 'string' && Array.isArray(JSON.parse(data))) {
				let resp = window.Module.apply_bulk_edit_on_modular_element(JSON.stringify(modal_json && modal_json.sku_ids), data)
				onclick_handleclose(resp)
				// alert.success('Bulk edit was successfull')
			}
		}catch(err){
			handleError(err)
		}
	}

	useEffect(() => {	
		window.addEventListener('message', handleMessage);
	
		return () => {
		  window.removeEventListener('message', handleMessage);
		};
	}, []);

	// useEffect(() => {
	// 	checkedCabinetIdsRef.current = checked_cabinet_ids
	// 	console.log('checked_cabinet_ids', checked_cabinet_ids)
	// }, [checked_cabinet_ids]);

	return (
		<Modal centered isOpen={modal_type === "bulk_edit_sku_structure"} toggle={onclick_handleclose} style={{maxWidth: '90%'}}>
			<IModalHeader toggle={onclick_handleclose}>Bulk Edit SKU Structure</IModalHeader>
			<IModalBody style={{padding: '0px'}}>
				<iframe style={{height: 'calc(100vh - 200px', width: '100%', border: 'none'}} src={build_path(window.Module.API.admin_path, `bulk_edit_sku_structure/?element_type=${modal_json && modal_json.element_type ? modal_json.element_type : 'cabinet'}`)} credentialless/>
			</IModalBody>
		</Modal>
	)
}

const Custom_remarks = ({modal_type, modal_json, handle_close, handle_ui_response, update_view, set_page_loader}) => {

	const [remarks, set_remarks] = useState('');

	const onclick_handle_close = () => {
		set_remarks('')
		handle_close()
	}

	const submit_remarks = () => {
		console.log("Updated remarks", remarks);
		set_page_loader({
			show: true,
			text: 'Please wait'
		});
		setTimeout(async() => {
			let arr = []
			arr.push(remarks)  
			window.Module.set_custom_remarks(JSON.stringify(arr));
			onclick_handle_close()
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	useEffect(() => {
		if(modal_json && modal_json.length){
			set_remarks(modal_json[0])
		}
	}, [modal_json]);

	return(
		<Modal className="modal-dialog-centered DMSans" size='lg' isOpen={modal_type == 'custom_remarks'}>
			<IModalHeader toggle={onclick_handle_close}>Remarks in pricing quotation</IModalHeader>
			<IModalBody className='p-4'>
				<div className='mb-3'>
					Remarks will be displayed above terms and condition in pricing quotation
				</div>
				<textarea type='text' placeholder = 'Text...' value={remarks} onChange={(e) => set_remarks(e.target.value)} style={{height:'100px', width:'100%', padding:'8px'}}/>
			</IModalBody>
			<IModalFooter>
				<Button className='secondary_button_default' onClick={onclick_handle_close}>Cancel</Button>
				<Button className='primary_button_default' onClick={() => submit_remarks()}>Add</Button>
			</IModalFooter>
		</Modal>
	)
}

const CreateWorkOrder = ({open, design_branch_id, handle_close, close_existing_workorders_modal, handle_ui_response, set_page_loader, callback_on_submit, project_client_name, project_name}) => {

	const [work_order_name, set_work_order_name] = useState();
	const [client_name, set_client_name] = useState();
	const [description, set_description] = useState();
	const [delivery_date, set_delivery_date] = useState();
	const alert = useAlert()
	
	useEffect(() => {
		console.log('design_branch_id', design_branch_id)
	},[design_branch_id])

	useEffect(() => {
		if(open && project_name) set_work_order_name(project_name)
	}, [open, project_name]);

	useEffect(() => {
		if(open && project_client_name) set_client_name(project_client_name)
	}, [open, project_client_name]);

	const onclick_create_work_order = async () => {
		if(!work_order_name){
			alert.error('Please fill Work order name')
			return
		}
		let data = {}
		console.log('Attempting Creation')
		data['name'] = work_order_name
		data['design_branch_id'] = design_branch_id
		data['client_name'] = client_name
		data['delivery_date'] = delivery_date
		data['description'] = description
		set_page_loader({show: true, text:'Creating Work Order'}, async() => {
			await sleep(50);
			var resp = await window.Promisify(window.Module.create_work_order(JSON.stringify(data)))
			set_page_loader({ show: false })
			resp = JSON.parse(resp)
			let alertArg = [resp.toast.parse ? JSON.parse(resp.toast.message) : resp.toast.message, {type: resp.toast.type}]
			if(resp.toast.timeout != undefined){
				alertArg[1].timeout = resp.toast.timeout
			}
			alert.show(...alertArg);
			onclick_handle_close()
		})
	}
	const onclick_handle_close = async () => {
		if(!callback_on_submit){
			let resp = await window.Promisify(window.Module.open_work_order_modal(JSON.stringify({'design_branch_id': design_branch_id})))
			handle_ui_response(resp)
		}else{
			callback_on_submit()
		}
		set_work_order_name('')
		set_client_name('')
		set_description('')
		set_delivery_date(undefined)
		handle_close()
	}
	return(
		<Modal className="modal-dialog-centered DMSans" size='lg' isOpen={open}>
			<IModalHeader toggle={() => {onclick_handle_close(); close_existing_workorders_modal()}}>Create Work Order</IModalHeader>
			<IModalBody className='p-4'>
				<div className='flex_property mb-4' style={{color:COLORS.gray6}}>
					Created work orders will be visible in <a style={{textDecoration:'underline'}} target='_blank' className='mx-1' href={window.Module.API.mes_app_path}>MES portal</a> for manufacturing
				</div>
				<div className='flex_property mb-4'>
					<div style={{width:'150px'}} className='mr-2'>Design Link*</div>
					<Input style={{flex:1, fontSize:'14px'}} value={window.Module.API.react_app_path+'/design/'+design_branch_id} readOnly />
				</div>
				<div className='flex_property mb-4'>
					<div style={{width:'150px'}} className='mr-2'>Work Order Name*</div>
					<Input style={{flex:1, fontSize:'14px'}} value={work_order_name} onChange={(e) => set_work_order_name(e.target.value)} />
				</div>
				<div className='flex_property mb-4'>
					<div style={{width:'150px'}} className='mr-2'>Client Name</div>
					<Input style={{flex:1, fontSize:'14px'}} value={client_name} onChange={(e) => set_client_name(e.target.value)} />
				</div>
				<div className='flex_property mb-4'>
					<div style={{width:'150px'}} className='mr-2'>Description</div>
					<Input style={{flex:1, fontSize:'14px'}} value={description} onChange={(e) => set_description(e.target.value)} />
				</div>
				<div className='flex_property mb-4'>
					<div style={{width:'150px'}} className='mr-2'>Delivery date</div>
					<DatePicker 
						style={{flex:1, fontSize:'14px'}} 
						format="YYYY-MM-DD"
						defaultValue = {delivery_date ? dayjs(delivery_date, 'YYYY-MM-DD') : null}
						onChange={(dateString) => set_delivery_date(dateString)}
					/>
				</div>
			</IModalBody>
			<IModalFooter>
				<Button className='secondary_button_default' onClick={() => {onclick_handle_close(); close_existing_workorders_modal()}}>Cancel</Button>
				<Button className='primary_button_default' onClick={() => {onclick_create_work_order()}}>Create work order</Button>
			</IModalFooter>
		</Modal>
	)
}

export const ExistingWorkOrders = ({modal_type, modal_json, handle_close, handle_ui_response, update_view, set_page_loader, project_client_name, project_name}) => {
	const [show_create_work_order, set_show_create_work_order] = useState(false);
	const [work_orders, set_work_orders] = useState(null)
	const [design_branch_id, set_design_branch_id] = useState('')

	useEffect(()=>{
		if(modal_json && modal_json.work_orders && modal_json.work_orders.length){
			set_work_orders(modal_json.work_orders)
			set_show_create_work_order(false)
		}else{
			set_show_create_work_order(true)
		}

		if(modal_json && modal_json.design_branch_id){
			set_design_branch_id(modal_json.design_branch_id)
		}
	}, [modal_json])

	const onclick_handle_close = () => {
		handle_close()
	}

	const callback_on_submit = () => {
		let resp = JSON.parse(window.Module.get_work_order_modal_json(design_branch_id))
		set_work_orders(resp.work_orders)
	}

	const close_existing_workorders_modal = () => {
		if(!(work_orders && work_orders.length)) onclick_handle_close()
	}

	return(
		<Modal className="modal-dialog-centered DMSans" size='lg' isOpen={modal_type === "existing_work_orders"}>
			<IModalHeader toggle={onclick_handle_close}>Existing Work Orders</IModalHeader>
			<IModalBody style={{maxHeight:'500px', overflow:'auto', padding:'32px'}}>
			{
				work_orders && work_orders.length ? work_orders.map((work_order, idx) => (
					<div className='flex_property mb-4'>
						<div style={{fontWeight:700, flex:3}}>{work_order.name}</div>
						<div style={{flex:2, color:'#000000D9'}}>{new Date(work_order.created_at).toString().substring(4, 15)}</div>
						<div style={{flex:1, textDecoration:'underline', justifyContent:'end'}} className='flex_property'>
							<a target='_blank' href={window.Module.API.mes_app_path + '/work_orders/modular_elements/' + work_order.id }>Open</a>
						</div>
					</div>
				)):'No Existing Work Orders'
			}
			</IModalBody>
			<IModalFooter>
				<Button className='primary_button_default' onClick={() => set_show_create_work_order(true)}>Create new work order</Button>
			</IModalFooter>
			<CreateWorkOrder open={show_create_work_order} design_branch_id={design_branch_id} handle_close={() => set_show_create_work_order(false)} close_existing_workorders_modal={close_existing_workorders_modal} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} callback_on_submit={callback_on_submit} project_client_name={project_client_name?project_client_name:(modal_json && modal_json.project_details ? modal_json.project_details.client_name : '')} project_name={project_name?project_name:(modal_json && modal_json.project_details ? modal_json.project_details.project_name : '')}/>
		</Modal>
	)
}

const Modals = ({ org_key, update_view, submit_for_delete_confirm, update_modal_json, modal_type, handle_close, modal_json, handle_ui_response,set_page_loader, presentation_sheets, metric_system, approximation_ft, approximation_mm, approxMM, mmToFeetParts, feetTomm, interface_mode, modal_mode, layerset_mode, add_layer_set, given_layerset, perspective}) => {	
	let modal_json_render_post_processing = {"properties":[{"name":"Lighting","props":[{"name":"brightness","value":68.0},{"name":"contrast","value":85.0}]}],"src":"https://staticassets.infurnia.com/rendering/outputs/a0d9bebd934e742d.jpg"}
	let modal_json_payment_schedules = [{"name": "InstallmentDefault", "percentage": 10},{"name": "InstallmentDefault", "percentage": 10},{"name": "InstallmentDefault", "percentage": 10},{"name": "InstallmentDefault", "percentage": 10},{"name": "InstallmentDefault", "percentage": 10},{"name": "InstallmentDefault", "percentage": 10},{"name": "InstallmentDefault", "percentage": 10},{"name": "InstallmentDefault", "percentage": 10},{"name": "InstallmentDefault", "percentage": 10},{"name": "InstallmentDefault", "percentage": 10},{"name": "InstallmentDefault", "percentage": 10},{"name": "InstallmentDefault", "percentage": 10},{"name": "InstallmentDefault", "percentage": 10}]
	let modal_json_subcomponent_pricing = {"sc1": {name: 'SC1', subcomponents: [{id: "subc1", name: 'SUBC1', division: 'hardware', options: [{key: '1', name: '1v'},{key: '2', name: '2v'},{key: '3', name: '3v'}]},{id: "subc1", name: 'SUBC1', division: 'kitchen', options: [{key: '1', name: '1v'},{key: '2', name: '2v'},{key: '3', name: '3v'}]},{id: "subc2", name: 'SUBC2', division: 'hardware', options: [{key: '1', name: '1v'},{key: '2', name: '2v'},{key: '3', name: '3v'}]}]}}
	let modal_json_common_table_data = {"tabs":[{"action_buttons":[{"id":"apply","name":"Apply","route":"route1"}],"col":[{"id":"apply","name":"Apply","type":"checkbox"},{"id":"panel_1_label","name":"Panel 1 Label","type":"text"},{"id":"panel_2_label","name":"Panel 2 Label","type":"text"}],"id":"to_be_applied","name":"To be Applied","units":[{"apply":false,"cabinet_id":"b08a4276-94e7-439d-","id":"e39bec01-df27-43f3-_ac600c9c-1b25-4adb-","panel_1_label":"Carcass left panel(C2.P6) - Top","panel_2_label":"Carcass right panel(C1.P7) - Top"}]},{"action_buttons":[{"id":"remove","name":"Remove","route":"route2"}],"col":[{"id":"remove","name":"Remove","type":"checkbox"},{"id":"panel_1_label","name":"Panel 1 Label","type":"text"},{"id":"panel_2_label","name":"Panel 2 Label","type":"text"}],"id":"already_present","name":"Already Present","units":[]}]}
	
	let modal_json_sheets = {
		"heading": "Download PDF of selected sheets",
		"route": "fire_multi_sheet_ppt_download",
		"sheets": [
			{
				"id": "ddc363ab-2d08-45f7-",
				"image": "ddc363ab-2d08-45f7-.png",
				"name": "Sheet 1"
			},
			{
				"id": "99b06e9c-6f0c-4fbb-",
				"image": "99b06e9c-6f0c-4fbb-.png",
				"name": "Sheet 2"
			},
			{
				"id": "786cb0dd-b9a8-43a4-",
				"image": "786cb0dd-b9a8-43a4-.png",
				"name": "Sheet 3"
			},
			{
				"id": "0b5283bd-7d57-4fdb-",
				"image": "0b5283bd-7d57-4fdb-.png",
				"name": "Sheet 4"
			},
			{
				"id": "1d2073e2-d56f-4f2a-",
				"image": "1d2073e2-d56f-4f2a-.png",
				"name": "Sheet 5"
			},
			{
				"id": "bd7ee686-a732-43bd-",
				"image": "bd7ee686-a732-43bd-.png",
				"name": "Sheet 6"
			}
		]
	}


	return(
		<React.Fragment>
			{ ["shortcuts"].includes(modal_type) ? <ShortcutsModal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} /> : "" }
			{ ["payment_schedule"].includes(modal_type) ? <PaymentSchedules modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} update_view={update_view} set_page_loader={set_page_loader} /> : "" }
			{ ["board_layout_settings"].includes(modal_type) ? <Board_Settings modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} update_view={update_view} set_page_loader={set_page_loader} /> : "" }
			{ ["download_board_layout"].includes(modal_type) ? <Download_Board_Layout modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} update_view={update_view} set_page_loader={set_page_loader} /> : "" }
			{ ["fulfillment_tag_customization_modal"].includes(modal_type) ? <FulfillmentTagCustomizationModal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_view={update_view} handle_ui_response={handle_ui_response} /> : "" }
			{ ["fulfillment_tag_template_modal"].includes(modal_type) ? <FulfillmentTagTemplate modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_view={update_view} handle_ui_response={handle_ui_response} /> : "" }
			{ ["fulfillment_tag_template_settings_modal"].includes(modal_type) ? <FulfillmentTagTemplateSettings modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_view={update_view} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} metric_system={metric_system} approximation_mm={approximation_mm} approximation_ft={approximation_ft} approxMM={approxMM} mmToFeetParts={mmToFeetParts} /> : "" }
			{ ["service_charges"].includes(modal_type) ? <Service_Charge modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} update_view={update_view} set_page_loader={set_page_loader}/> : "" }
			{ ["presentation_add_image"].includes(modal_type) ? <Presentation_Add_Image modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} update_view={update_view} /> : "" }
			{ ["hometown_vendor_info_modal", "sku_bucket_selector_modal", "carcass_vendor_info_modal"].includes(modal_type) ? <VendorList modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} update_view={update_view} set_page_loader={set_page_loader}/> : "" }
			{ ["pricing_errors"].includes(modal_type) ? <Pricing_Error modal_type={modal_type} modal_json={modal_json} /> : "" }
			{ ["generic_input"].includes(modal_type) ? <Generic_input modal_type={modal_type} modal_json={modal_json || {}} handle_close={handle_close} /> : "" }
			{ ["background_scale_input"].includes(modal_type) ? <Background_Scale_Input modal_type={modal_type} modal_json={modal_json || {}} handle_close={handle_close} metric_system = {metric_system} approxMM={approxMM} mmToFeetParts={mmToFeetParts} feetTomm={feetTomm} approximation_mm={approximation_mm} approximation_ft={approximation_ft}/> : "" }
			{ ["seller_save_preview"].includes(modal_type) ? <Seller_Preview_Modal modal_type={modal_type} modal_json={modal_json || {}} handle_close={handle_close}  handle_ui_response={handle_ui_response} /> : "" }
			{ ["confirm_modal"].includes(modal_type) ? <Confirm_Modal modal_type={modal_type} modal_json={modal_json || {}} handle_close={handle_close}  handle_ui_response={handle_ui_response} /> : "" }
			{ ["two_button_modal"].includes(modal_type) ? <Two_Button_Modal modal_type={modal_type} modal_json={modal_json || {}} handle_close={handle_close}  handle_ui_response={handle_ui_response} /> : "" }
			{ ["download_sheets"].includes(modal_type) ? <DownloadSheets modal_type={modal_type} modal_json={modal_json} set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} update_view={update_view} handle_close={handle_close} /> : "" }
			{ ["publish_template"].includes(modal_type) ? <Publish_Sheet modal_type={modal_type} modal_json={modal_json} set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} update_view={update_view} handle_close={handle_close} /> : "" }
			{ ["upload_3d_model"].includes(modal_type) ? <Upload3dModelToDesign modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} set_page_loader={set_page_loader} update_view={update_view} handle_ui_response={handle_ui_response} /> : "" }
			{ ["comments_modal"].includes(modal_type) ? <Comments_Modal modal_type={modal_type} modal_json={modal_json} set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} update_view={update_view} handle_close={handle_close} /> : "" }
			{ ["automation_history"].includes(modal_type) ? <Automation_History modal_type={modal_type} modal_json={modal_json} set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} update_view={update_view} handle_close={handle_close} /> : "" }
			{ ["copy_paste_different_floor_modal"].includes(modal_type) ? <Copy_Paste_Modal modal_type={modal_type} modal_json={modal_json} set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} update_view={update_view} handle_close={handle_close} /> : "" }
			{ ["fixed_length"].includes(modal_type) ? <Fixed_Length_Input modal_type={modal_type} modal_json={modal_json}  handle_ui_response={handle_ui_response} handle_close={handle_close} metric_system={metric_system} approximation_ft={approximation_ft} interface_mode={interface_mode} update_view={update_view} perspective = {perspective}/> : "" }
			{ ["share_branch_modal_new"].includes(modal_type) ? <Share_Branch_Modal_New org_key={org_key} modal_type={modal_type} modal_json={modal_json || {}} handle_close={handle_close} handle_ui_response={handle_ui_response}/> : "" }
			{ ["remote_view"].includes(modal_type) ? <Remote_View org_key={org_key} modal_type={modal_type} modal_json={modal_json || {}} handle_close={handle_close} /> : "" }
			{ ["add_tile"].includes(modal_type) ? <Add_tile modal_type={modal_type} modal_json={modal_json || {}}  handle_close={handle_close} /> : "" }
			{ ["change_tile_size"].includes(modal_type) ? <Change_tile_size modal_type={modal_type} modal_json={modal_json || {}}  handle_close={handle_close} /> : "" }
			{ ["version_modal"].includes(modal_type) ? <Version_modal modal_type={modal_type} modal_json={modal_json || {}}  handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} org_key={org_key} /> : "" }
			{ ["design_revert_modal"].includes(modal_type) ? <Design_Revert_Modal modal_type={modal_type} modal_json={modal_json || {}}  handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} submit_for_delete_confirm={submit_for_delete_confirm} org_key={org_key} /> : "" }
			{ ["horizontal_section_modal"].includes(modal_type) ? <HorizontalSectionModal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} /> : "" }
			{ ["composites"].includes(modal_type) ? <Composites_modal modal_type={modal_type} modal_json={modal_json} update_modal_json={update_modal_json} handle_close={handle_close} /> : "" }
			{ ["user_name_input"].includes(modal_type) ? <GetUserNameModal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} /> : "" }
			{ ["edit_material"].includes(modal_type) ? <Materials_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_view = {update_view} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} update_modal_json={update_modal_json} submit_for_delete_confirm={submit_for_delete_confirm}/> : "" }
			{ ["add_parameters_modal"].includes(modal_type) ? <Parameters_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_view={update_view} handle_ui_response={handle_ui_response}/> : "" }
			{ ["shortcut_list"].includes(modal_type) ? <Shortcut_List modal_type={modal_type} modal_json={modal_json}  handle_close={handle_close} /> : "" }
			{ ["edit_elevation_view_name"].includes(modal_type) ? <Edit_elevation_view_Modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_view={update_view} handle_ui_response={handle_ui_response} /> : "" }
			{ ["render_post_processing"].includes(modal_type) ? <RenderPostProcessing modal_type={modal_type}  handle_close={handle_close} modal_json={modal_json} handle_ui_response={handle_ui_response}/> : "" }
			{ ["render"].includes(modal_type) ? <Render modal_type={modal_type}  handle_close={handle_close} set_page_loader={set_page_loader} handle_ui_response={handle_ui_response}/> : "" }
			{ ["layer_set_modal"].includes(modal_type) ? <Layer_set_modal modal_type={modal_type} update_modal_json={update_modal_json} modal_json={modal_json}  handle_close={handle_close} /> : "" }
			{ ["create_layer_modal"].includes(modal_type) ? <Create_Layer_modal modal_type={modal_type} handle_close={handle_close} modal_mode = {modal_mode}/> : "" }
			{ ["create_layerset_modal"].includes(modal_type) ? <Create_Copy_LayerSet_modal modal_type={modal_type} handle_close={handle_close} modal_mode={modal_mode} layerset_mode={layerset_mode} given_layerset={given_layerset}/> : "" }
			{ ["rendered_images"].includes(modal_type) ? <Rendered_images modal_type={modal_type} modal_json={modal_json} submit_for_delete_confirm={submit_for_delete_confirm} update_modal_json={update_modal_json} handle_close={handle_close}/> : "" }
			{ ["merge_conflict"].includes(modal_type) ? <Merge_Conflict_modal modal_type={modal_type} modal_json={modal_json} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} handle_close={handle_close} /> : "" }
			{ ["cam_settings"].includes(modal_type) ? <CamSettingsModal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_view={update_view} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} submit_for_delete_confirm={submit_for_delete_confirm}/> : "" }
			{ ["snap_settings"].includes(modal_type) ? <Snap_Settings modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} /> : "" }
			{ ["sheet_input_box"].includes(modal_type) ? <Sheet_input_box modal_type={modal_type} modal_json={modal_json} handle_close={handle_close}  interface_mode={interface_mode} /> : "" }
			{ ["pd_modal"].includes(modal_type) ? <Add_to_presentation_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} /> : "" }
			{ ["presentation_select_page"].includes(modal_type) ? <Presentation_Select_Page modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} update_view={update_view} set_page_loader={set_page_loader} /> : "" }
			{ ["presentation_select_view"].includes(modal_type) ? <Presentation_Select_View modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} update_view={update_view} set_page_loader={set_page_loader} /> : "" }
			{ ["page_template"].includes(modal_type) ? <Choose_Page_Template modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} /> : "" }
			{ ["price_details"].includes(modal_type) ? <Price_details_modal modal_type={modal_type} update_modal_json={update_modal_json} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} /> : "" }
			{ ["cabinet_price_details_modal"].includes(modal_type) ? <Cabinet_price_details_modal modal_type={modal_type} update_modal_json={update_modal_json} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response}  set_page_loader={set_page_loader}  /> : "" }
			{ ["selected_sku_modal"].includes(modal_type) ? <Selected_sku_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} /> : "" }
			{ ["unique_price_details"].includes(modal_type) ? <Unique_Price_details_modal modal_type={modal_type} modal_json={modal_json} update_modal_json={update_modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} /> : "" }
			{ ["check_and_override_rules_applied"].includes(modal_type) ? <Rules_Applied_Modal modal_type={modal_type} modal_json={modal_json} update_modal_json={update_modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} /> : "" }
 			{ ["show_sku_details_cache_modal"].includes(modal_type) ? <Show_Sku_Details_Cache_modal modal_type = {modal_type} modal_json ={modal_json} handle_close ={handle_close} set_page_loader ={set_page_loader} update_modal_json={update_modal_json} /> : "" }
			{ ["admin_cache_differences_modal"].includes(modal_type) ? <Admin_Cache_Differences_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} set_page_loader={set_page_loader} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} update_view={update_view}/> : "" }
			{ ["include_in_quotation_modal", "show_at_top_level_quotation_modal"].includes(modal_type) ? <AFW_struct_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} update_modal_json={update_modal_json} /> : "" }
			{ ["input_form_modal"].includes(modal_type) ? <CommonInputFormModal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response}/> : "" }
			{ ["common_tablular_data"].includes(modal_type) ? <CommonTabularDataModal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} update_modal_json={update_modal_json}/> : "" }
			{ ["release_notes_modal"].includes(modal_type) ? <ReleaseNotesModal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_view={update_view} handle_ui_response={handle_ui_response} /> : "" }
			{ ["cutlist_pref_modal"].includes(modal_type) ? <Cutlist_pref_modal handle_ui_response={handle_ui_response} modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_view={update_view} submit_for_delete_confirm={submit_for_delete_confirm}/> : "" }
			{ ["pricing_quotation_hide_restore_modal", "boq_hide_restore_modal", "cut_list_modal"].includes(modal_type) ? <Hide_Restore_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} update_view={update_view} /> : "" }
			{ ["dwg_layer_modal"].includes(modal_type) ? <DWG_layer_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} /> : "" }
			{ ["boq_schedule"].includes(modal_type) ? <BOQ_schedule_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader}/> : "" }
			{ ["boq_properties", "edit_boq_properties"].includes(modal_type) ? <BOQ_properties_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} update_view={update_view}/> : "" }
			{ ["quotation_settings_modal"].includes(modal_type) ? <Quotation_settings_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader}/> : "" }
			{ ["subcomponent_pricing"].includes(modal_type) ? <SubcomponentPricingDetailsUpdateModal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} set_page_loader={set_page_loader}/> : "" }
			{ ["refresh_admin_cache"].includes(modal_type) ? <Refresh_admin_cache_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader}/> : "" }
			{ ["download_cam_outputs_modal"].includes(modal_type) ? <Download_CAM_Outputs_Modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader}/> : "" }
			{ ["all_parameter_modal"].includes(modal_type) ? <All_parameter_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} mmToFeetParts={mmToFeetParts} approxMM={approxMM} set_page_loader={set_page_loader}/> : "" }
			{ ["discount_and_other_charges"].includes(modal_type) ? <Discount_and_other_charges modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader}/> : "" }
			{ ["rules_violation_modal"].includes(modal_type) ? <Rules_voilation_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} update_view={update_view}/> : "" }
			{ ["price_issue_modal"].includes(modal_type) ? <Price_issue_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} update_view={update_view}/> : "" }
			{ ["outputs_blocked"].includes(modal_type) ? <Outputs_Blocked modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader}/> : "" }
			{ ["rtx_render"].includes(modal_type) ? <RTX_Render modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} set_page_loader={set_page_loader} handle_ui_response={handle_ui_response} update_view={update_view} update_modal_json={update_modal_json}/> : "" }
			{ ["rtx_images_modal"].includes(modal_type) ? <RTX_Rendered_images modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} submit_for_delete_confirm={submit_for_delete_confirm}/> : "" }
			{ ["baking_modal"].includes(modal_type) ? <RTX_Baking_Modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} submit_for_delete_confirm={submit_for_delete_confirm}/> : "" }
			{ ["outputs_level_selector"].includes(modal_type) ? <Outputs_Level_Selector modal_type={modal_type} modal_json={modal_json} update_view={update_view} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader}/> : "" }
			{ ["building_floor_modal"].includes(modal_type) ? <Building_floor_modal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_modal_json={update_modal_json} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader}/> : "" }
			{ ["bulk_edit_sku_structure"].includes(modal_type) ? <BulkEditSkuStructure modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_view={update_view} handle_ui_response={handle_ui_response} />  : "" }
			{ ["custom_remarks"].includes(modal_type) ? <Custom_remarks modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader}/> : "" }
			{ ["missing_tools_modal"].includes(modal_type) ? <MissingToolsModal modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_view={update_view} handle_ui_response={handle_ui_response} />  : "" }
			{ ["existing_work_orders"].includes(modal_type) ? <ExistingWorkOrders modal_type={modal_type} modal_json={modal_json} handle_close={handle_close} update_view={update_view} handle_ui_response={handle_ui_response} set_page_loader={set_page_loader} /> : ""}
		</React.Fragment>
	)    
}

export default Modals;